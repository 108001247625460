import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import throttle from 'lodash/throttle';
import { Loader } from '@noloco/components/src';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { UPDATE } from '../../../../constants/actionTypes';
import { FIELD_LEVEL_PERMISSIONS } from '../../../../constants/features';
import { DataType } from '../../../../models/DataTypes';
import { RecordValue } from '../../../../models/Record';
import { getMutationQueryString } from '../../../../queries/project';
import useScopeUser from '../../../../utils/hooks/useScopeUser';
import { fieldPermissions } from '../../../../utils/permissions';
import Checkbox from '../../../Checkbox';

interface ChecklistBoxProps {
  checklistFieldName: string;
  checklistFieldValue: boolean;
  dataType: DataType;
  projectName: string;
  record: Record<string, RecordValue>;
}

const ChecklistBox = ({
  checklistFieldName,
  checklistFieldValue,
  dataType,
  projectName,
  record,
}: ChecklistBoxProps) => {
  const user = useScopeUser();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(UPDATE, dataType.name, dataType.fields, {
        id: true,
        uuid: true,
        [checklistFieldName]: true,
      })}
    `,
    [dataType.name, dataType.fields, checklistFieldName],
  );

  const queryOptionsObj = useMemo(
    () => ({ context: { projectQuery: true, projectName: projectName } }),
    [projectName],
  );

  const [updateDataItem, { loading }] = useMutation(
    updateQueryString,
    queryOptionsObj,
  );

  const throttledUpdateDataItem = useMemo(
    () => throttle(updateDataItem, 250),
    [updateDataItem],
  );

  const canUpdate = useMemo(() => {
    if (dataType.readOnly) {
      return false;
    }

    const field = dataType.fields.getByName(checklistFieldName);

    if (!field) {
      return false;
    }

    const permissions = fieldPermissions(
      field,
      fieldPermissionsEnabled && dataType.permissionsEnabled,
      dataType.permissions,
      user,
    );

    return permissions.update;
  }, [dataType, fieldPermissionsEnabled, user, checklistFieldName]);

  const handleChecklistValueChange = useCallback(
    ({ target: { checked } }: { target: { checked: boolean } }) => {
      if (checklistFieldName && canUpdate) {
        throttledUpdateDataItem({
          variables: { id: record.id, [checklistFieldName]: checked },
        });
      }
    },
    [checklistFieldName, record, throttledUpdateDataItem, canUpdate],
  );

  return (
    <div className="absolute left-0 top-0 z-20 flex h-full w-12 items-start justify-center pt-7">
      {loading ? (
        <Loader className="max-h-5 max-w-5" />
      ) : (
        <Checkbox
          disabled={!canUpdate}
          onChange={handleChecklistValueChange}
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            event.stopPropagation()
          }
          rounded={true}
          size={MD}
          value={checklistFieldValue}
        />
      )}
    </div>
  );
};

export default ChecklistBox;
