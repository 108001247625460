import React, { forwardRef, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import shortId from 'shortid';
import Guide from '@noloco/ui/src/components/Guide';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { ActionButtonExecutionType } from '../../../constants/actionButtons';
import { ActionType } from '../../../constants/actionTypes';
import { RECORD_ACTION_BUTTONS } from '../../../constants/buildMode';
import { ACTION_BUTTON } from '../../../constants/draggableItemTypes';
import Icon from '../../../elements/Icon';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  ElementPath,
  ScanActionType,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import useItemListEditor from '../../../utils/hooks/useItemListEditor';
import { getText } from '../../../utils/lang';
import { WithDropable } from '../../withDnD';
import BuildModeSection from '../BuildModeSection';
import BuildModeActionButtonEditor from './BuildModeActionButtonEditor';

const LANG_KEY = 'elements.VIEW';

export interface BuildModeActionButtonsEditorProps {
  actionButtons: ActionButton[];
  actionOptions?: ActionType[];
  availableExecutions?: ActionButtonExecutionType[];
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  defaultExecution?: ActionButtonExecutionType;
  display?: 'collection' | 'record';
  elementPath: ElementPath;
  label?: string;
  project: Project;
  sectionPropPath?: ElementPath;
  updateProperty: UpdatePropertyCallback;
  scanActionsAvailable?: ScanActionType[];
}

const BuildModeActionButtonsEditor = forwardRef(
  (
    {
      actionButtons,
      actionOptions,
      availableExecutions,
      dataType,
      debouncedUpdateProperty,
      defaultExecution,
      display,
      elementPath,
      label,
      project,
      sectionPropPath,
      updateProperty,
      scanActionsAvailable,
    }: BuildModeActionButtonsEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [activeListItem, setActiveListItem] = useState<number | null>(null);
    const [popoutOpen, setPopoutOpen] = useState(false);

    const createNewActionButton = () => ({
      id: shortId.generate(),
      buttonText: getText(
        { index: actionButtons.length + 1 },
        LANG_KEY,
        'actionButtons',
        'defaultButtonText',
      ),
      title: '',
      description: '',
      actions: [],
      display: display
        ? { collection: display === 'collection', record: display === 'record' }
        : undefined,
    });

    const {
      formatItem,
      draftItems: draftActionButtons,
      findItem,
      onSaveOrder,
      onRemoveItem: onRemoveActionButton,
      onCloneItem: onCloneActionButton,
      onAddNewItem: onAddNewActionButton,
      onDebounceUpdateItem: onDebounceUpdateActionButton,
      onUpdateItem: onUpdateActionButton,
    } = useItemListEditor(
      actionButtons,
      updateProperty,
      debouncedUpdateProperty,
      createNewActionButton,
      ['actionButtons'],
      false,
    );

    const listOptions = useMemo(
      () =>
        draftActionButtons.map((actionButton, index) => ({
          icon: actionButton.icon && (
            <Icon className="h-4 w-4" icon={actionButton.icon} />
          ),
          label: actionButton.buttonText,
          value: index,
        })),
      [draftActionButtons],
    );

    return (
      <BuildModeSection
        id={RECORD_ACTION_BUTTONS}
        className="border-t p-2"
        emptyState={getText('elements.VIEW.actionButtons.noActionsButtons')}
        endComponent={
          <div
            className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-gray-700 hover:opacity-100"
            onClick={onAddNewActionButton}
          >
            <IconPlus size={16} />
          </div>
        }
        guide={
          <Guide href="https://guides.noloco.io/actions/action-buttons">
            {getText(LANG_KEY, 'actionButtons.guide')}
          </Guide>
        }
        showEmptyState={draftActionButtons.length === 0}
        title={label ?? getText('elements.VIEW.actionButtons.label.default')}
      >
        <div className="mb-2 flex flex-col space-y-2 p-2" ref={ref}>
          {draftActionButtons.map((actionButton, index) => (
            <BuildModeActionButtonEditor
              actionButton={actionButton}
              actionOptions={actionOptions}
              activeListItem={activeListItem}
              availableExecutions={availableExecutions}
              dataType={dataType}
              debouncedUpdateProperty={debouncedUpdateProperty}
              defaultExecution={defaultExecution}
              display={display}
              draggable={true}
              elementPath={elementPath}
              findItem={findItem}
              index={index}
              item={formatItem(actionButton)}
              key={actionButton.id}
              listOptions={listOptions}
              onClone={onCloneActionButton}
              onDebounceUpdateActionButton={onDebounceUpdateActionButton}
              onRemove={onRemoveActionButton}
              onSaveOrder={onSaveOrder}
              onUpdateActionButton={onUpdateActionButton}
              popoutOpen={popoutOpen}
              project={project}
              sectionPropPath={sectionPropPath}
              setActiveListItem={setActiveListItem}
              setPopoutOpen={setPopoutOpen}
              updateProperty={updateProperty}
              scanActionsAvailable={scanActionsAvailable}
            />
          ))}
        </div>
      </BuildModeSection>
    );
  },
);

BuildModeActionButtonsEditor.displayName = 'BuildModeActionButtonsEditor';

export default WithDropable(BuildModeActionButtonsEditor, ACTION_BUTTON);
