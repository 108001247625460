import React, { useMemo } from 'react';
import { IconArrowBack, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import reverse from 'lodash/fp/reverse';
import { Link } from 'react-router-dom';
import { darkModeColors } from '../../../constants/darkModeColors';
import { BREADCRUMBS } from '../../../constants/elements';
import { IconValue } from '../../../models/IconValue';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useSetDocumentTitle from '../../../utils/hooks/useSetDocumentTitle';
import { getText } from '../../../utils/lang';
import Icon from '../../Icon';

interface ViewBreadcrumbsProps {
  additionalLinks?: {
    to: string;
    name: string;
  }[];
  backLink?: {
    to: string;
    qs: string;
    enabled: boolean;
    name: string;
  } | null;
  className: string;
  icon?: IconValue;
  name?: string;
  show: boolean;
  rootPathname?: string;
}

const ViewBreadcrumbs = ({
  additionalLinks = [],
  backLink,
  className,
  icon,
  name,
  show,
  rootPathname,
}: ViewBreadcrumbsProps) => {
  const documentTitle = useMemo(
    () =>
      [...reverse(additionalLinks), { name }]
        .map((additionalLink, index, links) =>
          typeof additionalLink.name === 'string' ||
          typeof additionalLink.name === 'number'
            ? `${additionalLink.name}${index !== links.length - 1 ? ' — ' : ''}`
            : null,
        )
        .filter(Boolean)
        .join(''),
    [additionalLinks, name],
  );

  const [isDarkModeEnabled] = useDarkMode();

  const textColor = isDarkModeEnabled
    ? darkModeColors.text.secondary
    : 'text-gray-600';
  const textColorHover = isDarkModeEnabled
    ? darkModeColors.text.primary
    : 'text-gray-800';

  useSetDocumentTitle(documentTitle);

  if (!show) {
    return null;
  }

  return (
    <div
      className={classNames(
        className,
        'flex-col text-sm font-medium tracking-wider',
        textColor,
      )}
    >
      {backLink && backLink.enabled && (
        <Link to={backLink.to} className="mb-3 flex items-center">
          <IconArrowBack className="mr-2 h-4 w-4 opacity-75" />
          <span className="text-xs">
            {getText(
              { pageName: backLink.name },
              'elements',
              BREADCRUMBS,
              'back',
            )}
          </span>
        </Link>
      )}
      <div className="flex w-full items-center space-x-2 overflow-hidden">
        <Link
          className={classNames(
            'flex items-center hover:underline',
            `hover:${textColorHover}`,
          )}
          to={`${rootPathname}${
            (backLink && !backLink.enabled && backLink.qs) || ''
          }`}
        >
          {icon && (
            <Icon icon={icon} className="mr-3 h-6 w-6 opacity-75" size={6} />
          )}
          <span>{name}</span>
        </Link>
        {additionalLinks.map((additionalLink: any) => (
          <div
            className="flex max-w-full items-center space-x-2 truncate sm:pr-6"
            key={additionalLink.to}
          >
            <IconChevronRight size={12} className="flex-shrink-0 opacity-75" />
            <Link
              className={classNames(
                'flex max-w-full items-center hover:underline',
                `hover:${textColorHover}`,
              )}
              to={`${rootPathname}${additionalLink.to}`}
            >
              <span className="max-w-full truncate">{additionalLink.name}</span>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

ViewBreadcrumbs.defaultProps = {
  additionalLinks: [],
};

export default ViewBreadcrumbs;
