import { useCallback } from 'react';
import { GraphQLError } from 'graphql';
import get from 'lodash/get';
import { ExternalToast, toast } from 'sonner';
import { useSonner } from 'sonner';
import { Variant } from '@noloco/components/src/constants/variants';
import {
  DANGER,
  INFO,
  PRIMARY,
  SECONDARY,
  SUCCESS,
  WARNING,
} from '@noloco/components/src/constants/variants';
import IconComponent from '../../elements/Icon';
import { NotificationIconType } from '../../models/Notification';

export const DEFAULT_TIMEOUT_MS = 5000;
export type ToastTypes =
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'loading'
  | 'default';
const TOAST_TYPES = [
  'success',
  'info',
  'warning',
  'error',
  'loading',
  'default',
] as const;

export const legacyAlertTypeResolver = (type?: Variant): ToastTypes => {
  switch (type) {
    case PRIMARY:
      return 'default';
    case SECONDARY:
      return 'default';
    case DANGER:
      return 'error';
    case WARNING:
      return 'warning';
    case INFO:
      return 'info';
    case SUCCESS:
      return 'success';
    default:
      return 'default';
  }
};

export const legacyNotificationIcon = ({
  icon,
  iconType,
}: {
  icon: string;
  iconType?: NotificationIconType;
}) => {
  if (!iconType) {
    return undefined;
  }

  return <LegacyNotificationIcon icon={icon} iconType={iconType} />;
};

export const LegacyNotificationIcon = ({
  icon,
  iconType,
}: {
  icon: string;
  iconType?: NotificationIconType;
}) => {
  if (iconType === NotificationIconType.ICON) {
    return (
      <IconComponent icon={{ name: icon }} className="h-6 w-6" size={24} />
    );
  } else if (icon) {
    return (
      <img
        alt="Notification icon"
        className="h-6 w-6 rounded-full"
        src={icon}
      />
    );
  }

  return null;
};

export const useAlert = (type: ToastTypes) =>
  useCallback(
    (message: string, options: ExternalToast & { type?: ToastTypes } = {}) => {
      const realType = options.type ?? type;

      const displayToast =
        realType === 'default' || !TOAST_TYPES.includes(realType)
          ? toast
          : toast[realType];

      return displayToast(message, {
        dismissible: true,
        duration: DEFAULT_TIMEOUT_MS,
        ...options,
      });
    },
    [type],
  );

export const useRemoveAllAlerts = () => {
  const { toasts } = useSonner();

  const removeAllAlerts = useCallback(
    () => toasts.forEach((t) => toast.dismiss(t.id)),
    [toasts],
  );

  return removeAllAlerts;
};

export const useErrorAlert = () => useAlert('error');
export const useWarningAlert = () => useAlert('warning');
export const useInfoAlert = () => useAlert('info');
export const useSuccessAlert = () => useAlert('success');
export const useLoadingAlert = () => useAlert('loading');

export const getTextFromError = (error: any) => {
  if (!error) {
    return {};
  }

  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    const errors = error.graphQLErrors as GraphQLError[];
    const gqlError = errors[0];
    const title = gqlError.extensions?.title;

    return {
      title,
      message: errors.map((er) => er.message).join('\n'),
    };
  }

  const networkError = get(error, 'networkError.result.message');

  if (networkError) {
    return { message: networkError };
  }

  if (error.message) {
    return { message: error.message };
  }

  if (Array.isArray(error)) {
    return {
      message: error
        .filter((er: any) => !!er.message)
        .map((er: any) => er.message)
        .join('\n'),
    };
  }

  return { message: String(error) };
};

export const useGraphQlErrorAlert = () => {
  const errorAlert = useAlert('error');

  return useCallback(
    (defaultTitle: any, error: any, options: ExternalToast = {}) => {
      const { title, message } = getTextFromError(error);

      return errorAlert(title || defaultTitle, {
        description: message,
        ...options,
      });
    },
    [errorAlert],
  );
};
