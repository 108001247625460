import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconBulb,
  IconBulbOff,
  IconDevices,
  IconVideoOff,
} from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useZxing } from 'react-zxing';
import Loader from '@noloco/components/src/components/loading/Loader';
import { Notice } from '@noloco/components/src/components/notice';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { Dropdown } from '@noloco/components/src/index';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useLocalStorageState from '@noloco/core/src/utils/hooks/useLocalStorageState';

const corners = [
  {
    position: 'top-0 left-0',
    border: 'border-t-8 border-l-8 sm:border-t-4 sm:border-l-4',
  },
  {
    position: 'top-0 right-0',
    border: 'border-t-8 border-r-8 sm:border-t-4 sm:border-r-4',
  },
  {
    position: 'bottom-0 left-0',
    border: 'border-b-8 border-l-8 sm:border-b-4 sm:border-l-4',
  },
  {
    position: 'bottom-0 right-0',
    border: 'border-b-8 border-r-8 sm:border-b-4 sm:border-r-4',
  },
];

const backCameraMatcher = /back|rear|environment/i;

interface BarcodeScannerProps {
  connectDeviceText: string;
  onScan: (
    result: string,
  ) => Promise<
    { success: boolean; data: any } | { success: boolean; error: any }
  >;
  successText: string;
  isDarkModeEnabled: boolean;
}

export const BarcodeScanner = ({
  connectDeviceText,
  onScan,
  successText,
  isDarkModeEnabled,
}: BarcodeScannerProps) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [devices, setDevices] = useState<{ label: string; value: string }[]>();
  const [deviceId, setDeviceId] = useLocalStorageState<string | undefined>(
    'barcodeScannerDeviceId',
    undefined,
  );

  const [isVideoPaused, setIsVideoPaused] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { sm: isSmallScreen } = useBreakpoints();

  const handleDeviceChange = useCallback(
    (newDeviceId: string) => {
      if (newDeviceId === deviceId) {
        return null;
      }

      setDeviceId(newDeviceId);
    },
    [deviceId, setDeviceId],
  );

  const showSuccessMessage = useCallback(
    () =>
      new Promise<void>((resolve) => {
        setSuccess(true);
        setIsLoading(false);
        const timer = setTimeout(() => {
          setSuccess(false);
          resolve();
        }, 1000);

        return () => clearTimeout(timer);
      }),
    [],
  );

  const debouncedHandleOnDecodeResult = useMemo(
    () =>
      debounce(async (result) => {
        setIsVideoPaused(true);
        setIsLoading(true);

        try {
          const actionResult = await onScan(result.getText());

          await new Promise((resolve) => setTimeout(resolve, 1000));

          if (actionResult?.success) {
            await showSuccessMessage();
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          console.error('Error performing onScan action', error);
          setIsLoading(false);
        } finally {
          setIsVideoPaused(false);
        }
      }, 500),
    [onScan, showSuccessMessage],
  );

  const handleOnDecodeResult = useCallback(
    (result: any) => {
      debouncedHandleOnDecodeResult(result);
    },
    [debouncedHandleOnDecodeResult],
  );

  useEffect(() => {
    const enumerateDevices = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { facingMode: 'environment' },
        });
      } catch {
        setError(true);

        return;
      }

      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices
          .sort((a, b) => {
            if (
              backCameraMatcher.test(a.label) &&
              !backCameraMatcher.test(b.label)
            ) {
              return -1;
            }

            if (
              !backCameraMatcher.test(a.label) &&
              backCameraMatcher.test(b.label)
            ) {
              return 1;
            }

            return 0;
          })
          .filter(({ kind }) => kind === 'videoinput')
          .map(({ label, deviceId }) => ({ label, value: deviceId }));

        setDevices(videoDevices);

        if (videoDevices.length === 0) {
          setError(true);
        }

        if (
          !deviceId ||
          !videoDevices.some(({ value }) => value === deviceId)
        ) {
          setDeviceId(videoDevices[0].value);
        }
      } catch {
        setError(true);
      }
    };

    enumerateDevices();
  }, [setDeviceId, deviceId]);

  const { ref, torch } = useZxing({
    deviceId,
    onDecodeResult: handleOnDecodeResult,
    paused: isVideoPaused || !deviceId,
  });

  const hasDevice = devices && devices.length > 1;

  const showOptions = torch.isAvailable && (torch.isOn || hasDevice);

  const hasBackCamera = (
    devices: { label: string; value: string }[] | undefined,
  ) => devices?.some((device) => backCameraMatcher.test(device.label));

  return (
    <div className="flex h-full w-full flex-col items-center justify-center overflow-hidden">
      <div className="relative flex h-full w-full flex-col items-center justify-center">
        <video
          className="round-lg h-full w-full object-cover sm:rounded-none"
          ref={ref}
        />
        {isLoading && (
          <div
            className={classNames(
              'absolute inset-0 flex items-center justify-center',
              {
                'text-white': isDarkModeEnabled,
              },
            )}
          >
            <Loader size={SM} />
          </div>
        )}
        {success && (
          <div className="absolute bottom-4 left-4">
            <Notice type="success" title={successText} />
          </div>
        )}
        {error ? (
          <div className="absolute inset-0 flex items-center justify-center gap-4 bg-gray-100">
            <IconVideoOff size={!isSmallScreen ? 48 : 32} />
            <p className="font-large text-base sm:text-xl">
              {connectDeviceText}
            </p>
          </div>
        ) : (
          <>
            <div
              className={classNames(
                'pointer-events-none absolute inset-0 flex items-center justify-between p-12 sm:p-8',
                {
                  hidden: isVideoPaused,
                },
              )}
            >
              <div className="relative h-full w-full">
                {corners.map((corner, index) => (
                  <div
                    key={index}
                    className={`absolute h-1/5 w-1/5 border-gray-100 ${corner.border} ${corner.position} rounded-lg sm:rounded-none`}
                  />
                ))}
              </div>
            </div>
            {showOptions && !isVideoPaused && (
              <div className="absolute bottom-4 flex w-full items-center justify-center space-x-4">
                {torch.isAvailable && (
                  <>
                    {torch.isOn ? (
                      <IconBulbOff
                        className="text-gray-100"
                        size={48}
                        onClick={() => torch.off()}
                        strokeWidth={isSmallScreen ? 1 : 1.5}
                      />
                    ) : (
                      <IconBulb
                        className="text-gray-100"
                        size={48}
                        onClick={() => torch.on()}
                        strokeWidth={isSmallScreen ? 1 : 1.5}
                      />
                    )}
                  </>
                )}
                {devices &&
                  devices.length > 1 &&
                  (!isSmallScreen || !hasBackCamera(devices)) && (
                    <Dropdown
                      className="flex items-center justify-center bg-opacity-0"
                      onChange={(value) => handleDeviceChange(String(value))}
                      options={devices}
                      size={MD}
                      value={deviceId}
                      minW={200}
                    >
                      <IconDevices
                        size={48}
                        className="text-gray-100"
                        strokeWidth={isSmallScreen ? 1 : 1.5}
                      />
                    </Dropdown>
                  )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
