import {
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client/core';
import { print } from 'graphql';
import { Client, ClientOptions, createClient } from 'graphql-sse';

// Refer to: https://the-guild.dev/graphql/sse/recipes#for-http1-aka-single-connection-mode

export class SSELink extends ApolloLink {
  private client: Client;

  constructor(options: Pick<ClientOptions, 'headers' | 'url'>) {
    super();
    this.client = createClient({
      headers: options.headers,
      url: options.url,
      singleConnection: true,
    });
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) =>
      this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        },
      ),
    );
  }
}
