import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BaseMutationOptions, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import gql from 'graphql-tag';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import upperFirst from 'lodash/upperFirst';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import shortId from 'shortid';
import { Surface } from '@noloco/components';
import { getElementPath } from '@noloco/ui/src/components/canvas/ProjectRenderer';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { BULK_UPDATE, INVITE_USER } from '../../constants/actionTypes';
import { FILE } from '../../constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '../../constants/dataTypes';
import { FORM_SECTION } from '../../constants/elements';
import { FIELD_VALIDATION_RULES } from '../../constants/features';
import { DATE as DATE_FORMAT } from '../../constants/fieldFormats';
import { SIGNATURE } from '../../constants/inputTypes';
import { MAX_BULK_SIZE } from '../../constants/mutations';
import { MANY_TO_MANY } from '../../constants/relationships';
import { CREATE, UPDATE } from '../../constants/workflowTriggerTypes';
import AutoFormCustomComponent from '../../elements/sections/forms/AutoFormCustomComponent';
import AutoFormField from '../../elements/sections/forms/AutoFormField';
import { DataField } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { DepValue } from '../../models/Element';
import {
  BaseRecord,
  CollectionConnection,
  RecordValue,
} from '../../models/Record';
import {
  FormConfigWithField,
  FormConfigWithOptionalField,
  FormFieldConfig,
  FormSectionConfig,
} from '../../models/View';
import { QueryObject, reduceFieldsToQueryObject } from '../../queries/data';
import { MutationType, getMutationQueryString } from '../../queries/project';
import { formFieldsUpdateAllRecordsSelector } from '../../selectors/formFieldsSelector';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { lookupOfArray } from '../arrays';
import { conditionsAreMet, transformDepsToQueryObject } from '../data';
import { skipPropResolvingByValueIds } from '../elementPropResolvers';
import { getAdditionalFieldsForForm } from '../fieldDependencies';
import { getFileTypeFromMimetype } from '../files';
import { getText } from '../lang';
import { getOptionByName } from '../options';
import { isMultiField, isMultiRelationship } from '../relationships';
import { RECORD_SCOPE, transformColumnarScope } from '../scope';
import useFormFieldsState, { DEBOUNCED_TYPES } from '../useFormFieldsState';
import { useErrorAlert } from './useAlerts';
import useAutoFormVariables, {
  getBulkOptimisticResponse,
} from './useAutoFormVariables';
import { useBuildDataItemRecordScope } from './useBuildDataItemRecordScope';
import { useFieldVisibilityConditions } from './useFieldVisibilityConditions';
import useMergedScope from './useMergedScope';
import usePrevious from './usePrevious';
import useSectionScopeVariables from './useSectionScopeVariables';
import { useShowUpdatingAlert } from './useShowUpdatingAlert';

const ROOT_ELEMENT_PATH = [0];

type Props = UseAutoFormConfig & {
  children: any;
};

interface Section {
  section: { fields: FormFieldConfig[]; id: string };
  config: FormSectionConfig;
}

interface CustomComponent {
  section: { fields: FormFieldConfig[]; id: string };
  config: FormSectionConfig;
}

interface UseFormContext {
  isLoading: boolean;
  hasSubmit: boolean;
  additionalRelatedFields: QueryObject;
  formFields: FormConfigWithField[];
  changedFieldsMap: Record<string, boolean>;
  hasChangedMap: Record<string, boolean>;
  onSubmit: (event: any) => Promise<void>;
  resolvedSectionsWithConditionsMet: Section[];
  fieldsWithHiddenSectionsRemoved: FormConfigWithField[];
  renderFormField: (formField: FormConfigWithField) => any;
  sections?: Section[];
  customComponents?: CustomComponent[];
}

const DEFAULT_NODE_QUERY = {
  id: true,
  uuid: true,
  createdAt: true,
  updatedAt: true,
};

const getIdValue = (val: any) => {
  if (typeof val == 'object') {
    if (val.id) {
      return String(val.id);
    } else if (Array.isArray(val) && val.length > 0) {
      // In the case there's a single relationship, and the default value returns multiple options (since it matches what the API expects, it returns an array of IDs), we'll pick the first option.
      return String(first(val));
    }
  }

  return String(val);
};

const formatDateFieldValue = (field: DataField, value: string): string => {
  const hasTime = get(field, 'typeOptions.format') !== DATE_FORMAT;
  const timeZone = get(field, 'typeOptions.timeZone');

  const dateTime = DateTime.fromISO(value, { setZone: !hasTime });
  const DateTimeZone = hasTime && !timeZone ? DateTime.local : DateTime.utc;

  return DateTimeZone(
    dateTime.year,
    dateTime.month,
    dateTime.day,
    hasTime ? dateTime.hour : 0,
    hasTime ? dateTime.minute : 0,
    hasTime ? dateTime.second : 0,
  ).toISO();
};

const formatRawValuesForForm = (field: DataField, value: RecordValue) => {
  if (isNil(value)) {
    return undefined;
  }

  if (field.type === TEXT) {
    return String(value);
  }

  if (field.type === MULTIPLE_OPTION) {
    if (typeof value === 'string') {
      return value
        .split(',')
        .filter((optionString) =>
          field.options?.find((option) => option.name === optionString),
        );
    }
  }

  if (!field.relationship && !field.relatedField) {
    if (field.type === SINGLE_OPTION) {
      const option = getOptionByName(String(value).trim(), field);

      return option ? option.name : undefined;
    }

    if (field.type === MULTIPLE_OPTION && value && !Array.isArray(value)) {
      return [value];
    }

    const isValidDate =
      field.type === DATE && value && DateTime.fromISO(value as string).isValid;

    if (isValidDate) {
      return formatDateFieldValue(field, value as string);
    }

    return value;
  }

  if (isMultiField(field)) {
    if (
      typeof value === 'object' &&
      (value as BaseRecord | CollectionConnection).edges
    ) {
      return value;
    }

    return {
      edges: Array.isArray(value)
        ? value.map((val) => ({ node: { id: String(val) } }))
        : [{ node: { id: String(value) } }],
    };
  } else if (value) {
    if (
      typeof value === 'object' &&
      (value as BaseRecord | CollectionConnection).edges
    ) {
      return first(
        (value as CollectionConnection).edges.map((edge: any) => edge.node),
      );
    }

    return { id: getIdValue(value) };
  }
};

const extractPrefilledAndDefaultValues = (resolvedFields: any) =>
  resolvedFields
    .filter(
      ({ config }: any) =>
        config.value !== undefined ||
        !isEmpty(config.defaultValue) ||
        isBoolean(config.defaultValue) ||
        isFinite(config.defaultValue),
    )
    .reduce((valueAcc: any, { field, config }: any) => {
      if (config.value !== undefined) {
        valueAcc[field.apiName] = formatRawValuesForForm(field, config.value);
      } else {
        valueAcc[field.apiName] = formatRawValuesForForm(
          field,
          config.defaultValue,
        );
      }

      return valueAcc;
    }, {});

const getUpdatedValue = (
  field: any,
  value: any,
  dataItem: any,
  draftValues: any,
  config: any = {},
) => {
  if (field.type === FILE) {
    if (!isMultiRelationship(field.relationship)) {
      if (value !== null) {
        const file = value[0];

        return {
          fileType: getFileTypeFromMimetype(file.type),
          name: file.name,
          url: value[2],
        };
      }
    } else if (
      isMultiRelationship(field.relationship) &&
      config.inputType === SIGNATURE
    ) {
      if (value !== null) {
        const valueWithId = [...value, shortId.generate()];

        return {
          edges: [
            {
              node: {
                id: valueWithId[3],
                fileType: getFileTypeFromMimetype(valueWithId[0].type),
                name: valueWithId[0].name,
                url: valueWithId[2],
              },
            },
          ],
        };
      }
    } else {
      const valueWithIds = value.map((uploadVal: any) => [
        ...uploadVal,
        shortId.generate(),
      ]);

      return {
        edges: [
          ...get(
            draftValues,
            [field.apiName, 'edges'],
            get(dataItem, [field.apiName, 'edges'], []),
          ),
          ...valueWithIds.map((file: any) => ({
            node: {
              id: file[3],
              fileType: getFileTypeFromMimetype(file[0].type),
              name: file[0].name,
              url: file[2],
            },
          })),
        ],
      };
    }
  }

  return value;
};

interface UseAutoFormConfig {
  authQuery?: boolean;
  dataType: DataType;
  depth?: number;
  fields: FormConfigWithOptionalField[];
  formatRecordScope?: (record: BaseRecord) => BaseRecord;
  customComponents?: CustomComponent[];
  inline?: boolean;
  innerClassName?: string;
  inviteEnabled?: boolean;
  Label?: any;
  mutationType: MutationType;
  newRecordStateId?: string;
  neverAllowNewRecords?: boolean;
  onAddDataItem?: (record: BaseRecord) => void;
  onError?: (error: Error) => void;
  onErrorResetFormFieldValues?: boolean;
  onFieldFailsValidation?: (field: DataField) => void;
  onFieldPassesValidation?: (field: DataField) => void;
  onLoadingChange?: (loading: boolean) => void;
  onLoadingFinish?: () => void;
  onSuccess?: (record: BaseRecord) => void;
  queryOptions?: Omit<BaseMutationOptions, 'context'>;
  readOnly?: boolean;
  recordDeps?: DepValue[];
  ReadOnlyCell?: any;
  sections?: Section[];
  skipResolvingForValueIds?: string[];
  submitOnBlur?: boolean | ((field: DataField) => boolean);
  surface?: Surface;
  transformRecordScope?: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  value: BaseRecord | null;
  bulkActionsEnabled?: boolean;
  isRowChecked?: boolean;
  selectedRows?: BaseRecord[];
}

const useAutoFormContext = ({
  authQuery,
  dataType,
  depth = 0,
  fields,
  formatRecordScope = identity,
  inline,
  innerClassName,
  inviteEnabled,
  Label,
  mutationType,
  neverAllowNewRecords,
  newRecordStateId,
  onAddDataItem = () => null,
  onError,
  onErrorResetFormFieldValues,
  onFieldFailsValidation,
  onFieldPassesValidation,
  onLoadingChange,
  onLoadingFinish,
  onSuccess,
  queryOptions,
  readOnly,
  ReadOnlyCell,
  recordDeps,
  sections,
  skipResolvingForValueIds = [],
  submitOnBlur = false,
  surface,
  transformRecordScope = identity,
  value,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows = [],
}: UseAutoFormConfig): UseFormContext => {
  const project = useSelector(projectDataSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalDataItem, setOriginalDataItem] = useState(value);
  const [hasSubmit, setHasSubmit] = useState(false);
  const errorAlert = useErrorAlert();
  const withUpdatingAlert = useShowUpdatingAlert(setIsLoading);

  const nextUpdate: React.MutableRefObject<(() => Promise<void>) | null> =
    useRef(null);
  const updateInFlight = useRef(false);

  const filterCustomComponents = (fields: FormConfigWithOptionalField[]) =>
    fields.filter(
      (field) => !field.config.isCustomComponent,
    ) as FormConfigWithField[];

  const { fields: initiallyResolvedFields } = useSectionScopeVariables(
    FORM_SECTION,
    { fields },
    project,
    ROOT_ELEMENT_PATH,
    skipPropResolvingByValueIds([RECORD_SCOPE, ...skipResolvingForValueIds]),
  );

  const filteredInitiallyResolvedFields = filterCustomComponents(
    initiallyResolvedFields,
  );

  const additionalRelatedFields = useMemo(
    () =>
      getAdditionalFieldsForForm(
        [...fields, ...(sections ?? [])],
        dataType,
        project.dataTypes,
      ),
    [dataType, fields, project.dataTypes, sections],
  );

  const [draftValues, setDraftValues, clearFormFieldValues] =
    useFormFieldsState(
      dataType.name,
      value ? value.id : newRecordStateId,
      !inline,
    );

  const changedFieldsMap = useMemo(
    () =>
      Object.keys(draftValues || {})
        .map((fieldKey) => dataType.fields.getByName(fieldKey))
        .filter(Boolean)
        .reduce((acc, field) => ({ ...acc, [field!.id]: true }), {}),
    [dataType.fields, draftValues],
  );

  const initialDataItem = useMemo(
    () => ({
      ...value,
      ...extractPrefilledAndDefaultValues(filteredInitiallyResolvedFields),
      ...draftValues,
    }),
    [value, filteredInitiallyResolvedFields, draftValues],
  );

  const buildRecordScope = useBuildDataItemRecordScope(
    formatRecordScope,
    transformRecordScope,
    dataType.name,
  );

  const initialRecordScope = useMemo(
    () => buildRecordScope(initialDataItem),
    [buildRecordScope, initialDataItem],
  );

  const initialScope = useMergedScope(initialRecordScope);

  const {
    fields: postResolvedFields,
  }: { fields: FormConfigWithOptionalField[] } = useSectionScopeVariables(
    FORM_SECTION,
    { fields },
    project,
    [0],
    initialScope,
    skipPropResolvingByValueIds(skipResolvingForValueIds),
  );

  const filteredPostResolvedFields = filterCustomComponents(postResolvedFields);

  const dataItem = useMemo(
    () => ({
      ...value,
      ...extractPrefilledAndDefaultValues(filteredPostResolvedFields),
      ...draftValues,
    }),
    [value, filteredPostResolvedFields, draftValues],
  );

  const recordScope = useMemo(
    () =>
      buildRecordScope(
        transformColumnarScope(dataItem, dataType, project.dataTypes),
      ),
    [buildRecordScope, dataItem, dataType, project.dataTypes],
  );

  const scope = useMergedScope(recordScope);

  const resolvedFieldsWithConditionsMet = useFieldVisibilityConditions(
    fields,
    project,
    recordScope,
    scope,
    // Need to remove label here because it's a react component that can't be memoized unfortunately
    // @ts-expect-error TS(2345): Argument of type '<T>(fieldObj: T) => LodashSet1x4... Remove this comment to see the full error message
    (fieldObj) => set(['config', 'label'], undefined, fieldObj),
    // Need to add label back in
    (field: any, index: any) =>
      set(['config', 'label'], get(fields, [index, 'config', 'label']), field),
  );

  const resolvedSectionsWithConditionsMet = useFieldVisibilityConditions(
    sections,
    project,
    recordScope,
    scope,
  );

  const fieldsWithHiddenSectionsRemoved = useMemo(() => {
    if (!sections) {
      return resolvedFieldsWithConditionsMet;
    }

    const allSectionFieldNamesLookup = sections.reduce(
      (fieldNamesLookup, { section }: any) => ({
        ...fieldNamesLookup,
        ...lookupOfArray(section.fields, 'name'),
      }),
      {} as Record<string, { config: FormSectionConfig }>,
    );
    const visibleSectionFieldNamesLookup =
      resolvedSectionsWithConditionsMet.reduce(
        (fieldNamesLookup, { section }) => ({
          ...fieldNamesLookup,
          ...lookupOfArray(section.fields, 'name'),
        }),
        {},
      );

    return resolvedFieldsWithConditionsMet.filter(
      ({ config }: any) =>
        !allSectionFieldNamesLookup[config.field] ||
        !!visibleSectionFieldNamesLookup[config.field],
    );
  }, [
    resolvedFieldsWithConditionsMet,
    resolvedSectionsWithConditionsMet,
    sections,
  ]);

  const isValidationEnabled = useIsFeatureEnabled(FIELD_VALIDATION_RULES);

  const isFieldConditionallyRequired = useCallback(
    (requiredConditions: any) =>
      conditionsAreMet(requiredConditions || [], scope, project),
    [project, scope],
  );

  const {
    changedFields: hasChangedMap,
    uploads,
    getQueryVariables,
    setUploads,
    setChangedFields,
  } = useAutoFormVariables(
    project,
    dataType,
    fieldsWithHiddenSectionsRemoved,
    mutationType,
    originalDataItem,
    onError,
    isValidationEnabled,
    isFieldConditionallyRequired,
    formatRecordScope,
    transformRecordScope,
    changedFieldsMap,
  );

  const isUserCreation = mutationType === CREATE && dataType.apiName === 'user';

  const baseNodeQuery = useMemo(() => {
    if (recordDeps && recordDeps.length > 0) {
      return {
        ...DEFAULT_NODE_QUERY,
        ...transformDepsToQueryObject(dataType, project.dataTypes, recordDeps),
      };
    }

    return DEFAULT_NODE_QUERY;
  }, [dataType, project.dataTypes, recordDeps]);

  const dataFieldsWithHiddenSectionsRemoved = useMemo(
    () =>
      fieldsWithHiddenSectionsRemoved
        .filter(
          (fieldConfig: FormConfigWithField) =>
            !fieldConfig.config?.isCustomComponent,
        )
        .map((fieldConfig: FormConfigWithField) => fieldConfig.field),
    [fieldsWithHiddenSectionsRemoved],
  );

  const dynamicNodeQueryObject = useMemo(
    () =>
      dataFieldsWithHiddenSectionsRemoved.reduce(
        reduceFieldsToQueryObject(project.dataTypes, {
          includeCollections: true,
          includeNestedAttachments: false,
          includeNestedFields: true,
          includeHidden: true,
        }),
        baseNodeQuery,
      ),
    [dataFieldsWithHiddenSectionsRemoved, project.dataTypes, baseNodeQuery],
  );

  const creationQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(
        isUserCreation && inviteEnabled ? INVITE_USER : CREATE,
        dataType.apiName,
        dataFieldsWithHiddenSectionsRemoved,
        dynamicNodeQueryObject,
      )}
    `,
    [
      isUserCreation,
      inviteEnabled,
      dataType.apiName,
      dataFieldsWithHiddenSectionsRemoved,
      dynamicNodeQueryObject,
    ],
  );

  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(
        UPDATE,
        dataType.apiName,
        dataFieldsWithHiddenSectionsRemoved,
        dynamicNodeQueryObject,
      )}
    `,
    [
      dataFieldsWithHiddenSectionsRemoved,
      dataType.apiName,
      dynamicNodeQueryObject,
    ],
  );
  const bulkUpdateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(
        BULK_UPDATE,
        dataType.apiName,
        dataFieldsWithHiddenSectionsRemoved,
        dynamicNodeQueryObject,
      )}
    `,
    [
      dataFieldsWithHiddenSectionsRemoved,
      dataType.apiName,
      dynamicNodeQueryObject,
    ],
  );

  const queryOptionsObj = useMemo(
    () => ({
      ...queryOptions,
      context: {
        authQuery,
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [authQuery, project.name, queryOptions],
  );

  const [createDataItem] = useMutation(creationQueryString, queryOptionsObj);
  const [updateDataItem] = useMutation(updateQueryString, queryOptionsObj);
  const [bulkUpdateDataItems] = useMutation(
    bulkUpdateQueryString,
    queryOptionsObj,
  );
  const debouncedInlineUpdateDataItem = useMemo(
    () => debounce(updateDataItem, 400),
    [updateDataItem],
  );

  const canBulkUpdate = useMemo(
    () =>
      bulkActionsEnabled &&
      isRowChecked &&
      selectedRows &&
      selectedRows.length > 1,
    [bulkActionsEnabled, isRowChecked, selectedRows],
  );

  const handleError = useCallback(
    (
      error: Error | any,
      draftUploads: any,
      changedFields: Record<number, boolean> | undefined,
      changedFieldNames: string[],
      updateTimeMs: number,
    ) => {
      // Reset state
      // @ts-expect-error TS(2345): Argument of type '{} | undefined' is not assignabl... Remove this comment to see the full error message
      setChangedFields(changedFields);
      setUploads(draftUploads);

      if (onErrorResetFormFieldValues) {
        clearFormFieldValues(changedFieldNames, updateTimeMs);
      }

      console.error(error);

      if (error.message === 'Failed to fetch') {
        captureException(error);
      }

      if (onError) {
        onError(error);
      }
    },
    [
      clearFormFieldValues,
      onError,
      onErrorResetFormFieldValues,
      setChangedFields,
      setUploads,
    ],
  );
  const bulkSaveValues = useCallback(
    async (
      draftRecords: any[],
      draftUploads: any,
      changedField?: DataField,
    ): Promise<void> => {
      if (draftRecords.length > MAX_BULK_SIZE) {
        const errorMessage = getText(
          { max: MAX_BULK_SIZE },
          'elements.VIEW.display.bulkActions.limitMaxBulkSizeError',
        );
        errorAlert(errorMessage);

        return Promise.reject(errorMessage);
      }
      const updateTimeMs = Date.now();

      setHasSubmit(true);

      let variablesArr: any[] = [];
      let optimisticValues: BaseRecord[] = [];
      let changedFields: Record<number, boolean> | undefined = {};
      draftRecords.forEach((rec: any) => {
        const {
          variables,
          optimisticValue,
          changedFields: changedFieldFromQueryVariable,
        } = getQueryVariables(rec, draftUploads, changedField, true);
        variablesArr.push(variables);

        if (optimisticValue) {
          optimisticValues.push(optimisticValue);
        }
        changedFields = changedFieldFromQueryVariable;
      });
      const optimisticResponse = getBulkOptimisticResponse(
        optimisticValues,
        dataType,
      );
      const hasNonIdMutationValues =
        variablesArr &&
        variablesArr.length > 0 &&
        variablesArr.every((variable: any) => Object.keys(variable).length > 1);

      if (!hasNonIdMutationValues) {
        return Promise.resolve();
      }

      const mutationResult = bulkUpdateDataItems({
        variables: { values: variablesArr },
        optimisticResponse,
        errorPolicy: 'all',
      });

      if (!mutationResult) {
        return Promise.reject();
      }
      const changedFieldId = Object.keys(changedFields).map((key) =>
        parseInt(key),
      )[0];

      const fieldToWipe = {
        [changedFieldId]: false,
      };
      const changedFieldName = dataType.fields.getById(changedFieldId)!.apiName;

      setChangedFields(fieldToWipe);
      setUploads({});

      return mutationResult
        .then((updatedData) => {
          let items = null;

          if (updatedData.data) {
            const updatedItems =
              updatedData.data[`bulkUpdate${upperFirst(dataType.apiName)}`];

            if (!updatedItems && updatedData.errors) {
              return handleError(
                first(updatedData.errors),
                draftUploads,
                changedFields,
                [changedFieldName],
                updateTimeMs,
              );
            }

            if (updatedItems) {
              setOriginalDataItem(
                (oldOriginalDataItem) =>
                  updatedItems.find(
                    (item: any) => item.id === oldOriginalDataItem?.id,
                  ) ?? oldOriginalDataItem,
              );

              items = updatedItems;
            }
          }

          setHasSubmit(false);

          setTimeout(() => {
            clearFormFieldValues([changedFieldName], updateTimeMs);
          }, 6000);

          if (onSuccess && items) {
            onSuccess(items);
          }
        })
        .catch((e) => {
          handleError(
            e,
            draftUploads,
            changedFields,
            [changedFieldName],
            updateTimeMs,
          );
        })
        .finally(() => {
          if (onLoadingFinish) {
            onLoadingFinish();
          }
        })
        .then((res) => res);
    },
    [
      bulkUpdateDataItems,
      clearFormFieldValues,
      dataType,
      errorAlert,
      getQueryVariables,
      handleError,
      onLoadingFinish,
      onSuccess,
      setChangedFields,
      setUploads,
    ],
  );
  const saveValues = useCallback(
    async (
      draftRecord: any,
      draftUploads: any,
      changedField?: DataField,
      isBulkUpdate?: boolean,
    ): Promise<void> => {
      const updateTimeMs = Date.now();

      if (isBulkUpdate) {
        return bulkSaveValues(draftRecord, draftUploads, changedField);
      }

      /*
       * If an update is already in flight, we need to queue the next update
       * to ensure that the next update is not lost. This is necessary because
       * the update function is async and the next update may be called before
       * the current update is finished. Notably, this can happen when the
       * update hits an upstream rate limit.
       *
       * If an update is not in flight, we set the updateInFlight flag. We use a
       * ref instead of hasSubmit to avoid race conditions.
       *
       * We don't need this for bulk updates because the bulk updates are across
       * multiple records
       */

      if (updateInFlight.current) {
        nextUpdate.current = () =>
          saveValues(draftRecord, draftUploads, changedField);

        return Promise.resolve();
      } else {
        updateInFlight.current = true;
      }

      setHasSubmit(true);
      const { variables, optimisticResponse, changedFields } =
        getQueryVariables(draftRecord, draftUploads, changedField);
      // Make sure it's not just the ID that's in the variables object
      const hasNonIdMutationValues =
        variables && Object.keys(variables).length > 1;

      if (hasNonIdMutationValues) {
        let mutation;

        if (mutationType === UPDATE) {
          if (
            inline &&
            changedField &&
            DEBOUNCED_TYPES.includes(changedField.type)
          ) {
            mutation = debouncedInlineUpdateDataItem;
          } else {
            mutation = updateDataItem;
          }
        } else {
          mutation = createDataItem;
        }
        const mutationResult = mutation({
          variables,
          optimisticResponse,
          errorPolicy: 'all',
        });

        if (!mutationResult) {
          return Promise.reject();
        }

        // @ts-expect-error TS(2769): No overload matches this call.
        const changedFieldsIds = Object.keys(changedFields).filter(
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          (changedFieldId) => changedFields[changedFieldId],
        ) as number[];
        const fieldsToWipe = changedFieldsIds.reduce(
          (acc, changedFieldId) => ({
            ...acc,
            [changedFieldId]: false,
          }),
          {},
        );
        const changedFieldNames = changedFieldsIds.map((fieldId) =>
          get(dataType.fields.getById(fieldId), 'apiName', ''),
        );

        setChangedFields(fieldsToWipe);
        setUploads({});

        return mutationResult
          .then((updatedData) => {
            let item = null;

            if (mutationType === CREATE && updatedData.data) {
              const mutationPrefix =
                isUserCreation && inviteEnabled ? 'invite' : 'create';
              const newItem =
                updatedData.data[
                  `${mutationPrefix}${upperFirst(dataType.apiName)}`
                ];

              if (!newItem && updatedData.errors) {
                return handleError(
                  first(updatedData.errors),
                  draftUploads,
                  changedFields,
                  changedFieldNames,
                  updateTimeMs,
                );
              }

              if (newItem) {
                onAddDataItem(newItem);
                item = newItem;
              }
              setOriginalDataItem({} as BaseRecord);
            } else if (updatedData.data) {
              const updatedItem =
                updatedData.data[`update${upperFirst(dataType.apiName)}`];

              if (!updatedItem && updatedData.errors) {
                return handleError(
                  first(updatedData.errors),
                  draftUploads,
                  changedFields,
                  changedFieldNames,
                  updateTimeMs,
                );
              }

              if (updatedItem) {
                setOriginalDataItem(updatedItem);
                item = updatedItem;
              }
            }

            setHasSubmit(false);

            setTimeout(() => {
              clearFormFieldValues(changedFieldNames, updateTimeMs);
            }, 6000);

            if (onSuccess && item) {
              onSuccess(item);
            }
          })
          .catch((e) => {
            handleError(
              e,
              draftUploads,
              changedFields,
              changedFieldNames,
              updateTimeMs,
            );
          })
          .finally(() => {
            if (onLoadingFinish) {
              onLoadingFinish();
            }
          })
          .then((res) => {
            if (isBulkUpdate) {
              return res;
            }

            updateInFlight.current = false;

            if (nextUpdate.current) {
              const updateFn = nextUpdate.current;
              nextUpdate.current = null;

              return updateFn();
            }

            return res;
          });
      }

      if (!isBulkUpdate) {
        updateInFlight.current = false;
      }

      return Promise.resolve();
    },
    [
      getQueryVariables,
      bulkSaveValues,
      mutationType,
      setChangedFields,
      setUploads,
      inline,
      debouncedInlineUpdateDataItem,
      updateDataItem,
      createDataItem,
      dataType.fields,
      dataType.apiName,
      onSuccess,
      isUserCreation,
      inviteEnabled,
      handleError,
      onAddDataItem,
      clearFormFieldValues,
      onLoadingFinish,
    ],
  );

  const previousLoading = usePrevious(isLoading);
  useEffect(() => {
    if (
      onLoadingChange &&
      previousLoading !== undefined &&
      previousLoading !== isLoading
    ) {
      onLoadingChange(isLoading);
    }
  }, [onLoadingChange, isLoading, previousLoading]);

  const updateAllRecords = useSelector(formFieldsUpdateAllRecordsSelector);

  const onSubmit = useCallback(
    async (event: any): Promise<void> => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (canBulkUpdate && updateAllRecords) {
        const rowsToUpdate = selectedRows.map((row) => ({
          id: row.id,
          ...draftValues,
        }));

        return withUpdatingAlert(() =>
          bulkSaveValues(rowsToUpdate, uploads, undefined),
        );
      }

      return withUpdatingAlert(() => saveValues(dataItem, uploads));
    },
    [
      canBulkUpdate,
      updateAllRecords,
      withUpdatingAlert,
      selectedRows,
      draftValues,
      bulkSaveValues,
      uploads,
      saveValues,
      dataItem,
    ],
  );

  const onValueChange = useCallback(
    (draftUploads: any) => (changedField: DataField, changedValue: any) => {
      if (submitOnBlur) {
        if (typeof submitOnBlur !== 'function' || submitOnBlur(changedField)) {
          return withUpdatingAlert(() =>
            saveValues(
              { ...dataItem, [changedField.apiName]: changedValue },
              draftUploads,
              changedField,
            ),
          );
        }
      }
    },
    [dataItem, withUpdatingAlert, saveValues, submitOnBlur],
  );

  const singleUpdateDataItemField = useCallback(
    (field: DataField, value: any) => {
      setChangedFields((currentChangedFields) => ({
        ...currentChangedFields,
        [field.id]: true,
      }));

      const config = fields.find(
        (fieldWithConfig) => fieldWithConfig.field?.id === field.id,
      )?.config;

      const updatedValue = getUpdatedValue(
        field,
        value,
        dataItem,
        draftValues,
        config,
      );

      let nextUploads = uploads;

      if (field.type === FILE) {
        if (!isMultiRelationship(field.relationship) || value === null) {
          nextUploads = set([field.apiName], value, nextUploads);
          setUploads(nextUploads);
        } else if (
          isMultiRelationship(field.relationship) &&
          (config as any).inputType === SIGNATURE
        ) {
          const valueWithId = [
            ...value,
            get(updatedValue, ['edges', 0, 'node', 'id']) ?? shortId.generate(),
          ];
          nextUploads = set([field.apiName], [valueWithId], nextUploads);
          setUploads(nextUploads);
        } else {
          const valueWithIds = value.map(
            (uploadVal: any, uploadIndex: number) => [
              ...uploadVal,
              get(updatedValue, ['edges', uploadIndex, 'node', 'id']) ??
                shortId.generate(),
            ],
          );
          nextUploads = set(
            [field.apiName],
            [...get(uploads, field.apiName, []), ...valueWithIds],
            nextUploads,
          );
          setUploads(nextUploads);
        }
      }

      setDraftValues(field, updatedValue, onValueChange(nextUploads));
    },
    [
      dataItem,
      draftValues,
      onValueChange,
      setChangedFields,
      setDraftValues,
      setUploads,
      uploads,
      fields,
    ],
  );

  const bulkUpdateDataItemField = useCallback(
    async (changedField: DataField, value: any) => {
      setChangedFields((currentChangedFields) => ({
        ...currentChangedFields,
        [changedField.id]: true,
      }));

      const updatedValue = getUpdatedValue(
        changedField,
        value,
        dataItem,
        draftValues,
      );
      const nodesToUpdate: BaseRecord[] = selectedRows.map((selectedRow) => ({
        ...selectedRow,
        ...draftValues,
        [changedField.name]: updatedValue,
      }));

      if (
        nodesToUpdate?.length > 0 &&
        submitOnBlur &&
        (typeof submitOnBlur !== 'function' || submitOnBlur(changedField))
      ) {
        return withUpdatingAlert(() =>
          bulkSaveValues(nodesToUpdate, null, changedField),
        );
      }
    },
    [
      setChangedFields,
      dataItem,
      draftValues,
      selectedRows,
      submitOnBlur,
      withUpdatingAlert,
      bulkSaveValues,
    ],
  );

  const updateDataItemField = useCallback(
    (field: DataField) => async (value: any) => {
      const isSubmitOnBlur =
        typeof submitOnBlur === 'function' ? submitOnBlur(field) : submitOnBlur;

      if (
        isSubmitOnBlur &&
        updateAllRecords &&
        ![BOOLEAN, FILE].includes(field.type)
      ) {
        return bulkUpdateDataItemField(field, value);
      }

      return singleUpdateDataItemField(field, value);
    },
    [
      submitOnBlur,
      updateAllRecords,
      singleUpdateDataItemField,
      bulkUpdateDataItemField,
    ],
  );

  const removeFiles = useCallback(
    (field: any) => (fileIds: number[]) => {
      if (field.type === FILE && field.relationship === MANY_TO_MANY) {
        setChangedFields((currentChangedFields) => ({
          ...currentChangedFields,
          [field.id]: true,
        }));

        const isUpload = get(uploads, [field.apiName], []).some((upload: any) =>
          fileIds.includes(upload[3]),
        );

        if (isUpload) {
          setUploads((currentUploads) =>
            set(
              field.apiName,
              get(currentUploads, [field.apiName], []).filter(
                (upload: any) => !fileIds.includes(upload[3]),
              ),
              currentUploads,
            ),
          );
        }

        const fileFieldKey = `${field.apiName}Id`;
        const fileIdsValue = [...get(dataItem, fileFieldKey, []), ...fileIds];

        if (!isUpload && submitOnBlur) {
          saveValues(
            { ...dataItem, [fileFieldKey]: fileIdsValue },
            uploads,
            field,
          );
        }
        const updatedValue = {
          edges: get(dataItem, [field.apiName, 'edges'], []).filter(
            (edge: any) => !fileIds.includes(edge.node.id),
          ),
        };

        // No need to include the callback for the file edges as these arent included in the mutation
        // Only the IDs are used in the mutation, but we need to set the draft values to update the UI
        setDraftValues(field, updatedValue);
      }
    },
    [
      dataItem,
      saveValues,
      setChangedFields,
      setDraftValues,
      setUploads,
      submitOnBlur,
      uploads,
    ],
  );

  const formFields = useMemo(
    () =>
      fieldsWithHiddenSectionsRemoved.filter(
        ({ config }: any) => !config.hidden,
      ),
    [fieldsWithHiddenSectionsRemoved],
  );

  const renderFormField = useCallback(
    ({ config, field }: FormConfigWithField) => {
      config.allowNewRecords = !neverAllowNewRecords;

      if (config.type) {
        return (
          <AutoFormCustomComponent
            containerSections={[]}
            dataType={dataType}
            editorMode={false}
            elementPath={ROOT_ELEMENT_PATH}
            hasSelectedElement={false}
            isEditingData={false}
            isExpanded={true}
            isRecordView={false}
            isSelected={false}
            onError={onError}
            onLoadingChange={onLoadingChange}
            project={project}
            record={{}}
            recordScope={recordScope}
            section={config}
            sectionId={config.id}
            sectionPath={getElementPath(ROOT_ELEMENT_PATH, config.id)}
            sectionWidth={config.width}
            config={config}
            field={field}
          />
        );
      }

      return (
        <AutoFormField
          additionalRelatedFields={additionalRelatedFields}
          authQuery={authQuery}
          className={innerClassName}
          config={config}
          dataItem={dataItem}
          dataType={dataType}
          dataTypes={project.dataTypes}
          depth={depth}
          disabled={config.disabled}
          field={field}
          hasChanged={hasChangedMap[field.id] || hasSubmit}
          inline={inline}
          isFieldConditionallyRequired={isFieldConditionallyRequired}
          key={field.id}
          Label={Label}
          mutationType={mutationType}
          onFieldFailsValidation={onFieldFailsValidation}
          onFieldPassesValidation={onFieldPassesValidation}
          project={project}
          readOnly={readOnly}
          ReadOnlyCell={ReadOnlyCell}
          removeFiles={removeFiles}
          scope={scope}
          surface={surface}
          updateDataItemField={updateDataItemField}
          canBulkUpdate={canBulkUpdate}
        />
      );
    },
    [
      neverAllowNewRecords,
      additionalRelatedFields,
      authQuery,
      innerClassName,
      dataItem,
      dataType,
      project,
      depth,
      hasChangedMap,
      hasSubmit,
      inline,
      isFieldConditionallyRequired,
      Label,
      mutationType,
      onFieldFailsValidation,
      onFieldPassesValidation,
      readOnly,
      ReadOnlyCell,
      removeFiles,
      scope,
      surface,
      updateDataItemField,
      canBulkUpdate,
      onError,
      onLoadingChange,
      recordScope,
    ],
  );

  return {
    isLoading,
    hasSubmit,
    additionalRelatedFields,
    formFields,
    changedFieldsMap,
    hasChangedMap,
    onSubmit,
    resolvedSectionsWithConditionsMet,
    fieldsWithHiddenSectionsRemoved,
    renderFormField,
    sections,
  };
};

const formContext = createContext<UseFormContext>({} as UseFormContext);

export const AutoFormProvider = ({ children, ...rest }: Props) => {
  const form = useAutoFormContext(rest);

  return <formContext.Provider value={form}>{children}</formContext.Provider>;
};

export const useAutoForm = () => useContext(formContext);
