import { EnumType } from 'json-to-graphql-query';
import DataTypes from '../models/DataTypes';
import { DepValue } from '../models/Element';

export const cleanEdgesNodeFromDepPath = (path: string, replaceValue = '') =>
  path.replace(/^(edges\.node\.)?/, replaceValue);

export const removeEdgesNodeFromPath = (dep: DepValue) => ({
  ...dep,
  path: cleanEdgesNodeFromDepPath(dep.path),
});

export const transformQueryArg = (key: string, value: any) => {
  switch (key) {
    case 'orderBy': {
      return { [key]: { ...value, direction: new EnumType(value.direction) } };
    }
    default: {
      return { [key]: value };
    }
  }
};

export const transformQueryArgs = (queryArgs: Record<any, any>) => {
  if (Object.keys(queryArgs).length === 0) {
    return undefined;
  }

  return Object.entries(queryArgs).reduce(
    (newArgs, [key, value]) => ({
      ...newArgs,
      ...transformQueryArg(key, value),
    }),
    {},
  );
};

/**
 * Hydrates the query data types with the data types used in the permissions
 * This is designed for `hydrateQueryDataTypesWithPermissionDataTypes` to be used recursively and should not be used directly
 * @param dataTypeName - The data type name to hydrate
 * @param dataTypes - The data types object
 * @param userRoleId
 * @param visited - Set of visited data types used to prevent infinite loops
 * @param permissionDataTypeNames
 */
const hydrateNestedPermissions = (
  dataTypeName: string,
  dataTypes: DataTypes,
  userRoleId: string | number,
  visited: Set<string>,
  permissionDataTypeNames: Set<string>,
  depth = 0,
) => {
  if (visited.has(dataTypeName)) {
    return;
  }

  visited.add(dataTypeName);
  const dataType = dataTypes.getByNameOrThrow(dataTypeName);

  if (depth > 10) {
    console.warn(
      `hydrateNestedPermissions: Exceeded max depth of 10 when hydrating ${dataTypeName}.`,
    );
  }

  if (dataType.permissionsEnabled) {
    dataType.permissions
      ?.getDataTypesUsedForRolePermissions(userRoleId, dataTypes)
      .forEach((nestedPermissionDataType) => {
        permissionDataTypeNames.add(nestedPermissionDataType);
        hydrateNestedPermissions(
          nestedPermissionDataType,
          dataTypes,
          userRoleId,
          visited,
          permissionDataTypeNames,
          depth + 1,
        );
      });
  }
};

export const hydrateQueryDataTypesWithPermissionDataTypes = (
  queryDataTypeNames: Set<string>,
  dataTypes: DataTypes,
  roleId: string | number | undefined,
) => {
  if (!roleId) {
    return Array.from(queryDataTypeNames);
  }

  const dataTypeNamesVisitedForHydration = new Set<string>();
  const dataTypeNamesToRefreshOn = new Set<string>();

  queryDataTypeNames.forEach((queryDataTypeName) => {
    dataTypeNamesVisitedForHydration.add(queryDataTypeName);
    dataTypeNamesToRefreshOn.add(queryDataTypeName);

    const dataType = dataTypes.getByNameOrThrow(queryDataTypeName);

    if (!dataType.permissionsEnabled) {
      return;
    }

    const permissionDataTypes =
      dataType.permissions?.getDataTypesUsedForRolePermissions(
        roleId,
        dataTypes,
      );
    permissionDataTypes?.forEach((permissionDataTypeName) => {
      if (!queryDataTypeNames.has(permissionDataTypeName)) {
        dataTypeNamesToRefreshOn.add(permissionDataTypeName);
        hydrateNestedPermissions(
          queryDataTypeName,
          dataTypes,
          roleId,
          dataTypeNamesVisitedForHydration,
          dataTypeNamesToRefreshOn,
        );
      }
    });
  });

  return Array.from(dataTypeNamesToRefreshOn);
};
