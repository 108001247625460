import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { CREATE } from '../../../constants/actionTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { IconValue } from '../../../models/IconValue';
import { Project } from '../../../models/Project';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import { useClearFormFieldValuesOnNavigateAway } from '../../../utils/hooks/useClearFormFieldValuesOnNavigateAway';
import useFormFields from '../../../utils/hooks/useFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getText } from '../../../utils/lang';
import { useFormRecordDeps, useOnSuccessRedirect } from '../../utils/newForm';
import FormSection from '../FormSection';
import Title from '../Title';
import { CARDS } from '../forms/AutoFormSection';
import ViewBreadcrumbs from './ViewBreadcrumbs';

export interface NewFormProps {
  className?: string;
  coverPhoto?: { src?: string };
  dataType: DataType;
  fields?: DataField[];
  icon?: IconValue;
  onClick?: () => void;
  elementPath?: ElementPath;
  name: string;
  project: Project;
  rootPathname?: string;
  scope?: Record<string, any>;
  isPublicForm?: boolean;
  onSave?: any;
  subtitle?: string;
  title?: string;
  saveButtonText?: string;
  viewRootPathname?: string;
  hideBreadcrumbs?: boolean;
  successMessage?: { icon: any; message: string };
  redirectOnSuccess?: boolean;
  hideFormOnSuccess?: boolean;
  disableFeatureCheck?: boolean;
  neverAllowNewRecords?: boolean;
}
const NewForm = forwardRef<HTMLDivElement, NewFormProps>(
  (
    {
      className,
      coverPhoto,
      dataType,
      fields,
      icon,
      onClick,
      name,
      project,
      rootPathname,
      scope,
      isPublicForm = false,
      onSave,
      subtitle,
      title,
      saveButtonText,
      viewRootPathname,
      hideBreadcrumbs,
      successMessage,
      redirectOnSuccess,
      hideFormOnSuccess,
      disableFeatureCheck = false,
      neverAllowNewRecords = false,
    },
    ref,
  ) => {
    const backLink = useBackLink(project);

    useTrackAppPage(PageTypes.NEW);

    const formFields = useFormFields(fields, dataType, project);
    const formSections = useFormSections(fields);

    useClearFormFieldValuesOnNavigateAway(dataType);

    const recordDeps = useFormRecordDeps(onSave, project);
    const onSuccessRedirect = useOnSuccessRedirect(
      project,
      dataType,
      onSave,
      scope,
      rootPathname,
      isPublicForm,
    );

    return (
      <div
        className={classNames(
          'mx-auto mt-8 w-full max-w-xl px-4 sm:mt-4',
          className,
        )}
        ref={ref}
        onClick={onClick}
        data-testid="new-record-form"
      >
        <ViewBreadcrumbs
          backLink={backLink}
          className="mb-6 flex sm:mb-3 sm:text-xs"
          additionalLinks={[
            {
              to: '/new',
              name:
                title ||
                getText(
                  { dataType: dataType.display },
                  'core.COLLECTION.form.new',
                ),
            },
          ]}
          icon={icon}
          name={name}
          show={!hideBreadcrumbs}
          rootPathname={viewRootPathname}
        />
        {(title || subtitle || coverPhoto) && (
          <Title
            subtitle={{
              hidden: !subtitle,
              value: subtitle,
            }}
            title={{
              hidden: !title,
              value: title,
            }}
            coverPhoto={{
              hidden: !coverPhoto,
              value: coverPhoto,
            }}
            className="mb-4 sm:mb-2"
          />
        )}
        <FormSection
          className="max-w-xl"
          dataType={dataType.name}
          fields={formFields}
          customComponentFormat={CARDS}
          sectionFormat={CARDS}
          sections={formSections}
          type={CREATE}
          project={project}
          recordDeps={recordDeps}
          onSuccess={redirectOnSuccess ? onSuccessRedirect : null}
          submitButton={{
            text: saveButtonText
              ? saveButtonText
              : getText('core.COLLECTION.form.save'),
            icon: { name: 'Check' },
          }}
          successMessage={successMessage}
          errorMessage={{
            message: getText('core.COLLECTION.form.invalid'),
          }}
          hideFormOnSuccess={hideFormOnSuccess}
          disableFeatureCheck={disableFeatureCheck}
          neverAllowNewRecords={neverAllowNewRecords}
        />
      </div>
    );
  },
);

NewForm.displayName = 'NewForm';
export default NewForm;
