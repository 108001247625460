export const WORKFLOW_FAILURE_TYPE = {
  ACTION_FAILED_TO_EXECUTE: 'ACTION.FAILED_TO_EXECUTE',
  INVALID_WEBHOOK_URL: 'WEBHOOK.INVALID_URL',
  INVALID_WEBHOOK_RESPONSE: 'WEBHOOK.INVALID_RESPONSE',
  INVALID_OPEN_AI_API_KEY: 'OPEN_AI.INVALID_API_KEY',
  INVALID_OPEN_AI_PROMPT: 'OPEN_AI.INVALID_PROMPT',
  DID_NOT_CALL_OPEN_AI_FUNCTION: 'OPEN_AI.DID_NOT_CALL_FUNCTION',
  COULD_NOT_PARSE_OPEN_AI_FUNCTION_ARGUMENTS:
    'OPEN_AI.COULD_NOT_PARSE_FUNCTION_ARGUMENTS',
  INVALID_EMAIL_TO: 'SEND_EMAIL.INVALID_EMAIL_TO',
  INVALID_NOTIFICATION_USERS: 'SEND_NOTIFICATION.INVALID_NOTIFICATION_USERS',
  INVALID_DATA_TYPE: 'MUTATION.INVALID_DATA_TYPE',
  INVALID_EMAIL_MESSAGE: 'SEND_EMAIL.INVALID_EMAIL_MESSAGE',
  INVALID_NOTIFICATION_MESSAGE: 'SEND_NOTIFICATION.INVALID_EMAIL_MESSAGE',
  INVALID_SLACK_CHANNELS: 'SEND_NOTIFICATION.INVALID_SLACK_CHANNELS',
  INVALID_EMAIL_SUBJECT: 'SEND_EMAIL.INVALID_EMAIL_SUBJECT',
  EMAIL_RATE_LIMIT_EXCEEDED: 'SEND_EMAIL.RATE_LIMIT_EXCEEDED',
  INVALID_RECORD_ID: 'MUTATION.INVALID_RECORD_ID',
  INVALID_USER_ID: 'MUTATION.INVALID_USER_ID',
  USER_ALREADY_ACTIVATED: 'MUTATION.USER_ALREADY_ACTIVATED',
  NO_CONFIG_FOUND: 'WORKFLOW.NO_PUBLISHED_CONFIG_FOUND',
  NO_RECORD_ITEM: 'WORKFLOW.NO_RECORD_ITEM',
  WEBHOOK_REQUEST_FAILED: 'WEBHOOK.REQUEST_FAILED',
  WORKFLOW_FAILED_TO_EXECUTE: 'WORKFLOW.FAILED_TO_EXECUTE',
  UNSUPPORTED_ACTION: 'ACTION.UNSUPPORTED_ACTION',
  INVOCATION_ID_EXISTS: 'WORKFLOW.INVOCATION_ID_EXISTS',
  RECORD_NOT_FOUND: 'MUTATION.RECORD_NOT_FOUND',
  SLACK_USER_NOT_FOUND: 'SEND_NOTIFICATION.SLACK_USER_NOT_FOUND',
  INVALID_COMMENT_TEXT: 'COMMENT.INVALID_TEXT',
  DOCUMENT_CREATION_ERROR: 'DOCS_AUTOMATOR.DOCUMENT_CREATION_ERROR',
  INVALID_DOCS_AUTOMATOR_API_KEY: 'DOCS_AUTOMATOR.INVALID_API_KEY',
  INVALID_FILTER: 'FIND_RECORD.INVALID_FILTER',
};
