import { useCallback, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  Icon,
  IconCalendar,
  IconChartBar,
  IconId,
  IconLayoutColumns,
  IconLayoutGrid,
  IconLayoutKanban,
  IconLayoutRows,
  IconLayoutSidebar,
  IconListCheck,
  IconMap,
  IconSubtask,
  IconTable,
  IconTableShare,
  IconTimeline,
  IconViewportWide,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import shortId from 'shortid';
import shortid from 'shortid';
import FeatureLockedButton from '@noloco/ui/src/components/FeatureLockedButton';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { LAYOUT_SETTINGS } from '../../../constants/buildMode';
import collectionLayouts, {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  CHECKLIST,
  COLUMNS,
  CONVERSATION,
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
  GANTT,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import {
  BOOLEAN,
  DATE,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '../../../constants/dataTypes';
import { FOLDER, VIEW } from '../../../constants/elements';
import { PREMIUM_LAYOUTS as PREMIUM_LAYOUTS_FEATURE } from '../../../constants/features';
import { ASC } from '../../../constants/orderByDirections';
import { DATABASE } from '../../../constants/scopeTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { DepValue, Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { GroupBy } from '../../../models/View';
import { isPremium } from '../../../utils/collectionLayouts';
import useHasFeatureFlag, {
  CHECKLIST_FLAG,
} from '../../../utils/hooks/useHasFeatureFlag';
import { getText } from '../../../utils/lang';
import { Page, getPagesConfig } from '../../../utils/pages';
import { getTypeOptionsOfTypeFromParent } from '../../../utils/renderedOptions';
import { withNullOption } from '../../../utils/settings';
import BuildModeSection from '../BuildModeSection';

interface BuildModeViewLayoutEditorProps {
  checklistFieldOptions?: { field: DataField; value: DepValue }[];
  dataType: DataType;
  element: Page | Element;
  hideSingleRecordOption?: boolean;
  project: Project;
  updateProperty: UpdatePropertyCallback;
}

export const LayoutIcon: Record<string, Icon> = {
  [ROWS]: IconLayoutRows,
  [CHARTS]: IconChartBar,
  [COLUMNS]: IconLayoutColumns,
  [CARDS]: IconLayoutGrid,
  [CALENDAR]: IconCalendar,
  [TIMELINE]: IconTimeline,
  [GANTT]: IconSubtask,
  [MAP]: IconMap,
  [TABLE]: IconTable,
  [PIVOT_TABLE]: IconTableShare,
  [TABLE_FULL]: IconViewportWide,
  [BOARD]: IconLayoutKanban,
  [SPLIT]: IconLayoutSidebar,
  [SINGLE_RECORD]: IconId,
  [CHECKLIST]: IconListCheck,
};

const BuildModeViewLayoutEditor = ({
  checklistFieldOptions = [],
  dataType,
  element,
  hideSingleRecordOption = false,
  project,
  updateProperty,
}: BuildModeViewLayoutEditorProps) => {
  const isChecklistEnabled = useHasFeatureFlag(CHECKLIST_FLAG);

  const {
    groupBy,
    groupBySort,
    groups,
    layout,
    parentPage,
    pivotTable = {},
  } = element.props || {};

  const layouts = useMemo(() => {
    const filteredLayouts = collectionLayouts.filter((collectionLayout) => {
      if (collectionLayout === CONVERSATION) {
        return false;
      }

      if (collectionLayout === CHECKLIST) {
        return isChecklistEnabled;
      }

      return true;
    });

    return [
      ...filteredLayouts,
      ...(hideSingleRecordOption ? [] : [SINGLE_RECORD]),
      SPLIT,
    ];
  }, [hideSingleRecordOption, isChecklistEnabled]);

  const parentPageElement = useMemo(() => {
    if (parentPage) {
      const { projectPages } = getPagesConfig(
        project.elements,
        project.settings,
      );

      return projectPages.find((el: any) => el.id === parentPage);
    }

    return null;
  }, [parentPage, project]);

  const groupByGroups: GroupBy[] = useMemo(() => {
    if (groups) {
      if (groups.length > 0) {
        return groups;
      }

      return [{ id: 'new' }];
    }

    return [
      {
        id: 'legacy',
        field: groupBy,
        sort: groupBySort,
      },
    ];
  }, [groupBy, groupBySort, groups]);

  const stateItem = useMemo(
    () =>
      dataType &&
      new StateItem({
        id: `${element.id}${element.type === VIEW ? ':VIEW' : ''}`,
        path: element.type === VIEW ? '' : 'edges.node',
        source: DATABASE,
        dataType: dataType.name,
        display: dataType.display,
      }),
    [dataType, element.id, element.type],
  );

  const groupByOptions = useMemo(() => {
    const acceptableDataTypes = [
      TEXT,
      SINGLE_OPTION,
      INTEGER,
      DATE,
      BOOLEAN,
      ...project.dataTypes.map((type: any) => type.name),
    ];

    return withNullOption(
      getTypeOptionsOfTypeFromParent(
        project.dataTypes,
        stateItem,
        acceptableDataTypes,
      ),
    );
  }, [project, stateItem]);

  const getDefaultGroupingField = useCallback(
    (layout: CollectionLayout | null = null) => {
      if (!layout) {
        const singleOptionField = groupByOptions.find(
          (groupByOption) => get(groupByOption, 'field.type') === SINGLE_OPTION,
        );

        if (singleOptionField) {
          return singleOptionField.value;
        }
      }

      const textField = groupByOptions.find(
        (groupByOption) => get(groupByOption, 'field.type') === TEXT,
      );

      if (textField) {
        return textField.value;
      }

      return null;
    },
    [groupByOptions],
  );

  const defaultChecklistField = useMemo(
    () =>
      checklistFieldOptions.find(
        (option) =>
          get(option, 'field.type') === BOOLEAN &&
          !get(option, 'field.internal', false),
      ),
    [checklistFieldOptions],
  );

  const updateLayout = useCallback(
    (layout: CollectionLayout) => {
      updateProperty(['layout'], layout);

      if (layout === BOARD && !groupByGroups[0].field) {
        updateProperty(
          ['groups'],
          [{ id: shortid.generate(), field: getDefaultGroupingField() }],
        );
      }

      if (layout === PIVOT_TABLE && !get(pivotTable, 'rowGrouping')) {
        updateProperty(['pivotTable'], {
          rowGrouping: [
            {
              field: getDefaultGroupingField(PIVOT_TABLE),
              id: shortId.generate(),
              sort: ASC,
            },
          ],
          columnGrouping: [
            {
              field: getDefaultGroupingField(),
              id: shortId.generate(),
              sort: ASC,
            },
          ],
        });
      }

      if (layout === CHECKLIST && defaultChecklistField) {
        updateProperty(['checklistField'], defaultChecklistField.value);
      }
    },
    [
      defaultChecklistField,
      getDefaultGroupingField,
      groupByGroups,
      pivotTable,
      updateProperty,
    ],
  );

  return (
    <>
      <BuildModeSection
        className="border-t px-2"
        id={LAYOUT_SETTINGS}
        sticky={true}
        title={getText('rightSidebar.editor.layout')}
      >
        <div className="mb-2 grid w-full grid-cols-4 gap-1 p-2">
          {layouts
            .filter(
              (collectionLayout) =>
                !hideSingleRecordOption ||
                [...EVENT_BASED_LAYOUTS, MAP, CHARTS, PIVOT_TABLE].includes(
                  collectionLayout,
                ) ||
                (!isPremium(collectionLayout) &&
                  collectionLayout !== TABLE_FULL),
            )
            .map((collectionLayout) => (
              <FeatureLockedButton
                checkEnabled={isPremium(collectionLayout)}
                compactBadge={true}
                data-testid={`collection-layout-${kebabCase(collectionLayout)}`}
                disabled={
                  parentPageElement &&
                  parentPageElement.type !== FOLDER &&
                  collectionLayout === SPLIT
                }
                iconClassName="absolute"
                feature={PREMIUM_LAYOUTS_FEATURE}
                is="button"
                key={collectionLayout}
                onClick={() => updateLayout(collectionLayout)}
              >
                <div
                  className={classNames(
                    'relative flex w-full flex-col items-center space-y-2 rounded-lg p-2 text-xs hover:bg-gray-700',
                    {
                      'bg-gray-900 text-gray-200': collectionLayout === layout,
                      'text-gray-400': collectionLayout !== layout,
                    },
                  )}
                >
                  <Box is={LayoutIcon[collectionLayout]} size={20} />
                  <span className="truncate">
                    {getText('elements.COLLECTION.layout', collectionLayout)}
                  </span>
                </div>
              </FeatureLockedButton>
            ))}
        </div>
      </BuildModeSection>
    </>
  );
};

export default BuildModeViewLayoutEditor;
