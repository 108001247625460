import { useCallback, useState } from 'react';
import React from 'react';
import get from 'lodash/get';
import { ProjectSettings } from '../../../models/Project';
import useBroadcastChannel from '../../../utils/hooks/useBroadcastChannel';
import useLocalStorageState from '../../../utils/hooks/useLocalStorageState';
import useShowInstallPrompt from '../../../utils/hooks/useShowInstallPrompt';
import AppInstallationModal from './AppInstallationModal';

const INSTALL_PROMPT_KEY = 'noloco.settings.showInstallPrompt';
const APP_UPDATE_CHANNEL = 'pwaUpdateChannel';

interface PWAModalsProps {
  settings: ProjectSettings;
}

const PWAModals = ({ settings }: PWAModalsProps) => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  const appUpdateAvailable = useBroadcastChannel(APP_UPDATE_CHANNEL);
  const [showInstallPrompt, setShowInstallPrompt] = useLocalStorageState(
    INSTALL_PROMPT_KEY,
    true,
  );
  const pwaEnabled = get(settings, 'pwa', false);

  const handleShowInstallPrompt = useCallback(
    (event: any) => {
      if (showInstallPrompt && pwaEnabled) {
        event.preventDefault();
        setTimeout(() => {
          setDeferredPrompt(event);
        }, 10000);
      }
    },
    [showInstallPrompt, pwaEnabled],
  );

  useShowInstallPrompt(handleShowInstallPrompt);

  if (showInstallPrompt && deferredPrompt) {
    return (
      <AppInstallationModal
        deferredPrompt={deferredPrompt}
        setDeferredPrompt={setDeferredPrompt}
        setShowInstallPrompt={setShowInstallPrompt}
      />
    );
  }

  if (appUpdateAvailable) {
    console.log('[SERVICE WORKER] Update Available');
  }

  return null;
};

export default PWAModals;
