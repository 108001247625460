import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { SwitchButton, TextInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { OPTIONS } from '@noloco/core/src/constants/buildMode';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { MULTIPLE_OPTION } from '@noloco/core/src/constants/dataTypes';
import { FORM_V2, NOTICE, SECTION } from '@noloco/core/src/constants/elements';
import { CONDITIONAL_FIELD_VISIBILITY } from '@noloco/core/src/constants/features';
import PRIMITIVE_DATA_TYPES from '@noloco/core/src/constants/primitiveDataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { FormFieldConfig } from '@noloco/core/src/models/View';
import useHasFeatureFlag, {
  ADD_CUSTOM_COMPONENT,
} from '@noloco/core/src/utils/hooks/useHasFeatureFlag';
import { getText } from '@noloco/core/src/utils/lang';
import { PermissionType } from '@noloco/core/src/utils/permissions';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import { getDataTypeOptionsOfTypes } from '@noloco/core/src/utils/renderedOptions';
import { RECORD_SCOPE } from '@noloco/core/src/utils/scope';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import FeatureLockedSwitch from '../../FeatureLockedSwitch';
import Guide from '../../Guide';
import ConditionValueEditor from '../../canvas/ConditionValueEditor';
import StringPropEditor from '../../canvas/StringPropEditor';
import ConditionsEditor from '../ConditionsEditor';
import AdvancedFormFieldEditor from './AdvancedFormFieldEditor';
import ColumnWidthEditor from './ColumnWidthEditor';
import FieldConditionsEditor, {
  getConditionFieldOptions,
} from './FieldConditionsEditor';
import FieldsListEditor, { UpdateFieldsCallback } from './FieldsListEditor';
import { EditorTabMap } from './SectionEditor';

const LANG_KEY = 'elements.VIEW';
const FORMS_LANG_KEY = 'elements.FORMS';

const defaultableAndHideableFieldFilter = (field: DataField) =>
  field.type !== FILE;

export const nullScopeOptions = [{ value: undefined, label: '---' }];

const valueContainsRecordScope = (value: any) =>
  Array.isArray(value) &&
  value.some(({ data }) => data && data.id === RECORD_SCOPE);

const removeFieldsWithDependantValue =
  (fields: FormFieldConfig[]) => (fieldOption: any) => {
    const optionConfig = fields.find(
      (config: any) => config.name === fieldOption.field.name,
    );

    const defaultValue = optionConfig && optionConfig.defaultValue;
    const hiddenValue =
      optionConfig && optionConfig.hidden && optionConfig.value;

    if (!defaultValue && !hiddenValue) {
      return true;
    }

    return (
      (!defaultValue || !valueContainsRecordScope(defaultValue)) &&
      (!hiddenValue || !valueContainsRecordScope(hiddenValue))
    );
  };

const DEFAULT_GET_DISABLED_TEXT = () => undefined;

const FIELD_WIDTHS = [4, 6, 8, 12];

export const getOptionsSetForConditionFieldOptions = (
  project: Project,
  elementPath: ElementPath,
  fieldOptions: { label: string; options: any[] },
) => {
  const options = getDataTypeOptionsOfTypes(
    project,
    elementPath,
    PRIMITIVE_DATA_TYPES,
    { includeUniqueColumnar: true, flatRawValuesOnly: true },
  );

  const formValues = {
    label: getText('elements.VIEW.fields.conditions.formValues'),
    options: fieldOptions.options,
  };

  return [formValues, ...options];
};

interface Props {
  dataType: DataType;
  elementPath: ElementPath;
  fieldFilter?: (f: DataField) => boolean;
  formFields: FormFieldConfig[];
  getDisabledText?: (field: DataField) => any | undefined;
  newFieldConfig?: Partial<FormFieldConfig>;
  onFieldsChange: UpdateFieldsCallback;
  permissionType: PermissionType;
  project: Project;
  showOnlyHiddenValueOption?: boolean;
  sticky?: boolean;
  valueFieldFilter?: (field: DataField, dataType: DataType) => boolean;
}

const FormFieldsEditor = ({
  dataType,
  elementPath,
  fieldFilter = () => true,
  formFields,
  getDisabledText = DEFAULT_GET_DISABLED_TEXT,
  newFieldConfig,
  onFieldsChange,
  permissionType,
  project,
  showOnlyHiddenValueOption = false,
  sticky = false,
  valueFieldFilter = () => true,
}: Props) => {
  const getRecordScopeOptions = useCallback(
    (formField: any) =>
      getConditionFieldOptions(dataType, project, {
        fieldFilter: (field: any, dataType: any) =>
          formField.name !== field.name && valueFieldFilter(field, dataType),
        shouldOnlyIncludeRelatedId: (field: any) => {
          const config = formFields.find(
            (fieldConfig) => fieldConfig.name === field.name,
          );

          return get(config, 'hidden', false);
        },
      }),
    [dataType, formFields, project, valueFieldFilter],
  );

  const defaultNewFieldConfig = useMemo(
    () => newFieldConfig || {},
    [newFieldConfig],
  );

  const filter = useCallback(
    (field: DataField) => {
      if (field.readOnly) {
        return false;
      }

      if (
        defaultNewFieldConfig.hidden &&
        !defaultableAndHideableFieldFilter(field)
      ) {
        return false;
      }

      return fieldFilter(field);
    },
    [defaultNewFieldConfig.hidden, fieldFilter],
  );
  const isEnabled = useIsFeatureEnabled(CONDITIONAL_FIELD_VISIBILITY);

  const addCustomComponent = useHasFeatureFlag(ADD_CUSTOM_COMPONENT);

  return (
    <FieldsListEditor
      allowFieldSections={true}
      allowFieldCustomComponents={addCustomComponent}
      dataType={dataType}
      dataTypes={project.dataTypes}
      value={formFields}
      fields={dataType.fields}
      filter={filter}
      getDisabledText={getDisabledText}
      onFieldsChange={onFieldsChange}
      getNewFieldConfig={(field: any) => ({
        ...defaultNewFieldConfig,
        label: upperFirst(field.display),
        placeholder: '',
      })}
      permissionType={permissionType}
      sticky={sticky}
    >
      {({ config, field, index, section, updateFields }: any) => {
        const { fieldOptions, userOptions } = getRecordScopeOptions(field);
        const additionalFormValueOptions = fieldOptions.options.filter(
          removeFieldsWithDependantValue(formFields),
        );

        const formScopeOptions = {
          label: getText(FORMS_LANG_KEY, 'helpText.formData.label'),
          help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
          options: fieldOptions.options,
        };

        if (config.type) {
          const Editor = EditorTabMap(config.type)[SECTION][OPTIONS];

          const getNewConditionRule = () => [
            [
              {
                field: null,
                operator: null,
                value: null,
              },
            ],
          ];

          return (
            <>
              <Editor
                dataType={dataType}
                debouncedUpdateProperty={(
                  propPath: ElementPath,
                  value: any,
                ) => {
                  updateFields([index, 'props', ...propPath], value);
                }}
                element={config}
                elementPath={elementPath}
                elementProps={config.props ?? {}}
                hideDataTypeInput={false}
                hideSingleRecordOption={true}
                isRecordView={true}
                key={elementPath}
                project={project}
                propPath={[]}
                section={config}
                sectionPropPath={elementPath}
                source={FORM_V2}
                updateProperty={(propPath: ElementPath, value: any) => {
                  updateFields([index, 'props', ...propPath], value);
                }}
                additionalScopeItems={[
                  {
                    label: getText(FORMS_LANG_KEY, 'helpText.formData.label'),
                    help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
                    options: fieldOptions.options,
                  },
                ]}
              />
              {config.type !== NOTICE && (
                <BuildModeInput label={getText(FORMS_LANG_KEY, 'width')}>
                  <ColumnWidthEditor
                    defaultValue={12}
                    onChange={(fieldWidth) =>
                      updateFields(
                        [index, 'width'],
                        fieldWidth === 12 ? undefined : fieldWidth,
                      )
                    }
                    value={config.width}
                    widths={FIELD_WIDTHS}
                  />
                </BuildModeInput>
              )}
              <BuildModeSwitchSection
                guide={
                  <Guide
                    href="https://guides.noloco.io/field-formatting/field-visibility-conditions"
                    showTooltip={true}
                    video="https://www.youtube.com/embed/4XXbXDF9ccU?si=qTqXyFs8oq4BySGv"
                  >
                    {getText(LANG_KEY, 'fields.conditions.guide')}
                  </Guide>
                }
                label={getText(LANG_KEY, 'fields.conditions.label')}
                value={!!config.conditions && isEnabled}
                onChange={(nextValue: any) => {
                  updateFields(
                    [index, 'conditions'],
                    nextValue ? getNewConditionRule() : null,
                  );
                }}
                className="mt-4"
                switchEl={
                  <FeatureLockedSwitch
                    feature={CONDITIONAL_FIELD_VISIBILITY}
                    value={!!config.conditions && isEnabled}
                    onChange={(nextValue: any) => {
                      updateFields(
                        [index, 'conditions'],
                        nextValue ? getNewConditionRule() : null,
                      );
                    }}
                    size="sm"
                  />
                }
              >
                <div className="flex max-w-full flex-col items-center justify-between px-2 py-4">
                  <ConditionsEditor
                    inline={true}
                    dataType={dataType}
                    fieldOptions={fieldOptions.options}
                    updateConditions={(path: any, value: any) =>
                      updateFields([index, 'conditions', ...path], value)
                    }
                    rules={config.conditions}
                    project={project}
                    elementPath={elementPath}
                  />
                </div>
              </BuildModeSwitchSection>
            </>
          );
        }

        return (
          <div className="flex flex-col space-y-2">
            {!section && defaultableAndHideableFieldFilter(field) && (
              <SwitchButton
                options={[
                  {
                    disabled: showOnlyHiddenValueOption,
                    label: getText(LANG_KEY, 'new.field'),
                    value: false,
                  },
                  { label: getText(LANG_KEY, 'new.hidden.label'), value: true },
                ]}
                onChange={(value) => updateFields([index, 'hidden'], value)}
                value={config.hidden || false}
              />
            )}
            {config.hidden ? (
              <div className="flex flex-col space-y-2">
                <p className="text-slate-500">
                  {getText(LANG_KEY, 'new.hidden.description')}
                </p>
                <BuildModeLabel>
                  {getText(LANG_KEY, 'new.value')}
                </BuildModeLabel>
                <ConditionValueEditor
                  additionalScopeItems={[
                    {
                      label: getText(FORMS_LANG_KEY, 'helpText.formData.label'),
                      help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
                      options: additionalFormValueOptions,
                    },
                  ]}
                  contained={true}
                  dataType={dataType}
                  field={field}
                  project={project}
                  onChange={(value: any) =>
                    updateFields([index, 'value'], value)
                  }
                  multiple={field.type === MULTIPLE_OPTION}
                  value={get(config, 'value')}
                  includeUniqueColumnar={isMultiField(field)}
                  elementPath={elementPath}
                  placeholder=""
                />
                <BuildModeInput
                  label={getText(FORMS_LANG_KEY, 'helpText.label')}
                  markdown={true}
                >
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ additionalScopeItems: { label: string; opt... Remove this comment to see the full error message
                    additionalScopeItems={[
                      {
                        label: getText(
                          FORMS_LANG_KEY,
                          'helpText.formData.label',
                        ),
                        help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
                        options: fieldOptions.options,
                      },
                    ]}
                    contained={true}
                    elementPath={elementPath}
                    multiLine={true}
                    onChange={(value: any) => {
                      updateFields([index, 'helpText'], value);
                    }}
                    placeholder={getText(
                      {
                        field: section
                          ? field.label ||
                            getText(FORMS_LANG_KEY, 'sections.placeholder')
                          : field.display,
                      },
                      FORMS_LANG_KEY,
                      'helpText.placeholder.field',
                    )}
                    project={project}
                    value={get(config, 'helpText')}
                  />
                </BuildModeInput>
              </div>
            ) : (
              <div className="flex flex-col space-y-4">
                <BuildModeInput label={getText(LANG_KEY, 'fields.label')}>
                  <TextInput
                    debounceMs={UPDATE_DEBOUNCE_MS}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) =>
                      updateFields([index, 'label'], value)
                    }
                    disabled={config.hidden}
                    value={get(config, 'label')}
                    placeholder={
                      section
                        ? getText(FORMS_LANG_KEY, 'sections.placeholder')
                        : field.display
                    }
                  />
                </BuildModeInput>
                <BuildModeInput
                  label={getText(FORMS_LANG_KEY, 'helpText.label')}
                  markdown={true}
                >
                  <StringPropEditor
                    // @ts-expect-error TS(2322): Type '{ additionalScopeItems: { label: string; opt... Remove this comment to see the full error message
                    additionalScopeItems={[
                      {
                        label: getText(
                          FORMS_LANG_KEY,
                          'helpText.formData.label',
                        ),
                        help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
                        options: fieldOptions.options,
                      },
                    ]}
                    contained={true}
                    elementPath={elementPath}
                    multiLine={true}
                    onChange={(value: any) => {
                      updateFields([index, 'helpText'], value);
                    }}
                    placeholder={getText(
                      {
                        field: section
                          ? field.label ||
                            getText(FORMS_LANG_KEY, 'sections.placeholder')
                          : field.display,
                      },
                      FORMS_LANG_KEY,
                      'helpText.placeholder.field',
                    )}
                    project={project}
                    value={get(config, 'helpText')}
                  />
                </BuildModeInput>
                {!section && (
                  <>
                    <BuildModeInput label={getText(FORMS_LANG_KEY, 'width')}>
                      <ColumnWidthEditor
                        defaultValue={12}
                        onChange={(fieldWidth) =>
                          updateFields(
                            [index, 'width'],
                            fieldWidth === 12 ? undefined : fieldWidth,
                          )
                        }
                        value={config.width}
                        widths={FIELD_WIDTHS}
                      />
                    </BuildModeInput>
                    <AdvancedFormFieldEditor
                      config={config}
                      dataType={dataType}
                      elementPath={elementPath}
                      field={field}
                      fieldOptions={formScopeOptions}
                      index={index}
                      project={project}
                      updateFields={updateFields}
                      userOptions={userOptions}
                    >
                      {defaultableAndHideableFieldFilter(field) && (
                        <BuildModeInput
                          label={getText(FORMS_LANG_KEY, 'defaultValue.label')}
                        >
                          <ConditionValueEditor
                            additionalScopeItems={[
                              ...nullScopeOptions,
                              {
                                label: getText(
                                  FORMS_LANG_KEY,
                                  'helpText.formData.label',
                                ),
                                help: getText(
                                  FORMS_LANG_KEY,
                                  'helpText.formData.help',
                                ),
                                options: additionalFormValueOptions,
                              },
                            ]}
                            contained={true}
                            dataType={dataType}
                            field={field}
                            project={project}
                            onChange={(value: any) =>
                              updateFields([index, 'defaultValue'], value)
                            }
                            includeUniqueColumnar={isMultiField(field)}
                            value={get(config, 'defaultValue', [])}
                            multiple={field.type === MULTIPLE_OPTION}
                            elementPath={elementPath}
                            placeholder={getText(
                              {
                                field: field.display,
                              },
                              FORMS_LANG_KEY,
                              'defaultValue.placeholder',
                            )}
                          />
                        </BuildModeInput>
                      )}
                    </AdvancedFormFieldEditor>
                  </>
                )}
                <FieldConditionsEditor
                  conditionFieldOptions={[
                    ...nullScopeOptions,
                    ...getOptionsSetForConditionFieldOptions(
                      project,
                      elementPath,
                      fieldOptions,
                    ),
                  ]}
                  dataType={dataType}
                  elementPath={elementPath}
                  onChange={(path: any, value: any) =>
                    updateFields([index, 'conditions', ...path], value)
                  }
                  project={project}
                  value={get(config, 'conditions')}
                />
              </div>
            )}
          </div>
        );
      }}
    </FieldsListEditor>
  );
};

export default FormFieldsEditor;
