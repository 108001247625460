import { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import shortId from 'shortid';
import {
  UpdatePropertyCallback,
  useUpdateElements,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { ElementPath } from '../../models/Element';
import { ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import { getText } from '../lang';
import useItemListEditor from './useItemListEditor';

export function useTabsEditor(
  tabs: ViewTab[],
  updateProperty: UpdatePropertyCallback,
  debouncedUpdateProperty: UpdatePropertyCallback,
  elementPath: ElementPath,
  project: Project,
) {
  const updateTabs = useCallback(
    (path: ElementPath, value: any) => updateProperty(['tabs', ...path], value),
    [updateProperty],
  );

  const debounceUpdateTabs = useCallback(
    (path: ElementPath, value: any) =>
      debouncedUpdateProperty(['tabs', ...path], value),
    [debouncedUpdateProperty],
  );

  const createNewTab = useCallback((currentTabs: ViewTab[]) => {
    if (currentTabs.length > 0) {
      return {
        id: shortId.generate(),
        title: getText(
          { count: currentTabs.length + 1 },
          'elements.VIEW.tabs',
          'newTitle',
        ),
      };
    }

    return {
      id: 'DEFAULT_TAB_ID',
      title: getText('elements.VIEW.tabs', 'main'),
    };
  }, []);

  const {
    formatItem,
    draftItems: draftTabs,
    findItem,
    onSaveOrder,
    onRemoveItem: onRemoveTab,
    onCloneItem: onCloneTab,
    onAddNewItem: onAddNewTab,
    onDebounceUpdateItem: onDebounceUpdateTab,
  } = useItemListEditor(tabs, updateTabs, debounceUpdateTabs, createNewTab);

  const [updateElements] = useUpdateElements(project);

  const sectionsElementPath = useMemo(
    () => [...elementPath, 'sections'],
    [elementPath],
  );

  const sections = useMemo(
    () => get(project, ['elements', ...sectionsElementPath], []),
    [project, sectionsElementPath],
  );

  const handleOnRemoveTab = useCallback(
    (id: string, removeSections?: boolean) => {
      if (removeSections) {
        updateElements(
          sectionsElementPath,
          sections.filter((section: { tab?: string }) => section?.tab !== id),
        );
      }

      onRemoveTab(id);
    },
    [sections, sectionsElementPath, onRemoveTab, updateElements],
  );

  return {
    formatItem,
    draftTabs,
    findItem,
    onSaveOrder,
    handleOnRemoveTab,
    onCloneTab,
    onAddNewTab,
    onDebounceUpdateTab,
  };
}
