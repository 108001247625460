import { useMemo } from 'react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FORM_SECTION } from '../../constants/elements';
import { CONDITIONAL_FIELD_VISIBILITY } from '../../constants/features';
import { Project } from '../../models/Project';
import { FormConfigWithOptionalField } from '../../models/View';
import { conditionsAreMet } from '../data';
import { isOptionType } from '../options';
import useSectionScopeVariables from './useSectionScopeVariables';

export const getVisibleOptions = (
  options: any,
  config: any,
  scope: any,
  project: Project,
) =>
  options.filter((option: any) => {
    const { hidden = false, conditions } = get(
      config,
      ['optionsConfig', option.name],
      {},
    );

    return (
      !hidden && (!conditions || conditionsAreMet(conditions, scope, project))
    );
  });

export const useFieldVisibilityConditions = (
  fields: any,
  project: any,
  recordScope: any,
  scope: any,
  preResolution = identity,
  postResolution = identity,
) => {
  const isConditionalFieldVisibilityEnabled = useIsFeatureEnabled(
    CONDITIONAL_FIELD_VISIBILITY,
  );

  const fieldsPreResolution = useMemo(
    () => (fields ? fields.map(preResolution) : []),
    [fields, preResolution],
  );

  const { fields: resolvedFields }: { fields: FormConfigWithOptionalField[] } =
    useSectionScopeVariables(
      FORM_SECTION,
      { fields: fieldsPreResolution },
      project,
      [0],
      recordScope,
    );

  const fieldsPostResolution = useMemo(
    () => resolvedFields.map(postResolution),
    [postResolution, resolvedFields],
  );

  const resolvedFieldsWithConditionsMet = useMemo(
    () =>
      fieldsPostResolution
        .filter((field: any) => {
          const config = get(field, 'config', null);

          return (
            !isConditionalFieldVisibilityEnabled ||
            (config && (!config.conditions || config.hidden)) ||
            conditionsAreMet(config && config.conditions, scope, project)
          );
        })
        .map((fieldConfig: any) => {
          const { field, config } = fieldConfig;

          if (!config.hidden && field && isOptionType(field.type)) {
            const options = getVisibleOptions(
              field.options,
              config,
              scope,
              project,
            );

            if (options.length < field.options.length) {
              return set('field.options', options, fieldConfig);
            }
          }

          return fieldConfig;
        }),
    [fieldsPostResolution, isConditionalFieldVisibilityEnabled, project, scope],
  );

  return resolvedFieldsWithConditionsMet;
};
