import React, { Suspense, lazy, useCallback, useMemo, useState } from 'react';
import {
  IconCircleNumber0,
  IconCopy,
  IconCornerRightDown,
  IconEyeCheck,
  IconEyeOff,
  IconFilePlus,
  IconPlus,
  IconSettings,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';
import { useDispatch } from 'react-redux';
import {
  ErrorText,
  Loader,
  SelectInput,
  Switch,
  TextInput,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { cloneElement } from '@noloco/ui/src/utils/elements';
import {
  UPDATE_DEBOUNCE_MS,
  useAddSibling,
  useRemoveSelected,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { SPLIT } from '../../constants/collectionLayouts';
import { darkModeColors } from '../../constants/darkModeColors';
import { DIVIDER, FOLDER, LINK, PAGE, VIEW } from '../../constants/elements';
import { URL } from '../../constants/linkTypes';
import Icon from '../../elements/Icon';
import { Element, ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import {
  setEditingPage,
  setShowAdvancedPageSettings,
} from '../../reducers/elements';
import { setSelectedElement } from '../../reducers/elements';
import useAddPage from '../../utils/hooks/useAddPage';
import { useErrorAlert } from '../../utils/hooks/useAlerts';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import { getPagesConfig } from '../../utils/pages';
import IconEditor from './IconEditor';
import SidebarItemOptions from './SidebarItemOptions';

const DEBOUNCE_TIME = 1000;
const LANG_KEY = 'elements.PAGE';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const LazyStringPropEditor = lazy(
  () => import('@noloco/ui/src/components/canvas/StringPropEditor'),
);

export const sanitiseRoutePath = (value: any) =>
  value
    .replace(/[\s&?]/g, '-')
    .replace(/[^\w\s-]/g, '')
    .toLowerCase();

interface OwnPageEditorProps {
  className?: string;
  config: any;
  element: any;
  elementPath: ElementPath;
  onSetEditing: (id: string | null) => void;
  project: Project;
  updateProperty: (...args: any[]) => any;
  debouncedUpdateProperty: (...args: any[]) => any;
  cloneable?: boolean;
}

type PageEditorProps = OwnPageEditorProps;

export const removeParentPageSettingsFromRemovedPage = (
  newPages: Element[],
  removedPage: Element,
) =>
  newPages.map((page: Element) => {
    if (get(page, 'props.parentPage') === removedPage.id) {
      return set('props.parentPage', undefined, page);
    }

    return page;
  });

const PageEditor = ({
  className,
  element,
  elementPath,
  onSetEditing,
  project,
  debouncedUpdateProperty,
  cloneable = true,
  updateProperty,
}: PageEditorProps) => {
  const dispatch = useDispatch();
  const errorAlert = useErrorAlert();
  const { replace, push } = useRouter();

  const {
    name,
    dataType,
    hide,
    link,
    routePath,
    isSubPage,
    showRecordCount,
    parentPage,
  } = get(element, 'props') ?? {};
  const [removePage] = useRemoveSelected(
    project,
    elementPath,
    // @ts-expect-error TS2345: Argument of type '(newPages: Element[], removedPage: Element) => Element[]' is not assignable to parameter of type '{  (value: T): T; (): undefined; }'.
    removeParentPageSettingsFromRemovedPage,
  );
  const [addSibling] = useAddSibling(
    project,
    elementPath,
    isSubPage ? [] : ['children'],
  );
  const onAddPage = useAddPage(project);
  const [isDarkModeEnabled] = useDarkMode();

  const isExternalLink = element.type === LINK;

  const onShowAdvancedSettings = useCallback(
    () => dispatch(setShowAdvancedPageSettings(true)),
    [dispatch],
  );

  const [localName, setLocalName] = useState(name);
  const [localRoutePath, setLocalRoutePath] = useState(routePath);

  const onEditSelectPage = useCallback(
    (id: string) => {
      dispatch(setEditingPage(id));
    },
    [dispatch],
  );

  const pages = useMemo(
    () =>
      get(
        getPagesConfig(project.elements, project.settings),
        'projectPages',
        [],
      ).filter((page: any) => ![DIVIDER, LINK].includes(page.type)),
    [project],
  );

  const parentPageElement = useMemo(
    () =>
      parentPage
        ? pages.find(({ id }: Element) => id === parentPage)
        : undefined,
    [pages, parentPage],
  );

  const clonePage = useCallback(
    (pageElement: any) => {
      const pageIndex = last(elementPath);

      const clonedPage = cloneElement(pageElement);
      addSibling(clonedPage, (pageIndex as any) + 1);
      onSetEditing(clonedPage.id);

      const clonedParentPageId = get(clonedPage, 'props.parentPage');

      dispatch(
        setSelectedElement([
          ...initial(elementPath),
          (last as any)(elementPath) + 1,
        ]),
      );

      if (clonedParentPageId) {
        if (clonedParentPageId !== parentPage) {
          push(`/${element.props.routePath}/${clonedPage.props.routePath}`);
        } else if (parentPageElement) {
          push(
            `/${parentPageElement.props.routePath}/${clonedPage.props.routePath}`,
          );
        }
      } else {
        push(`/${clonedPage.props.routePath}`);
      }
    },
    [
      addSibling,
      dispatch,
      element.props.routePath,
      elementPath,
      onSetEditing,
      parentPage,
      parentPageElement,
      push,
    ],
  );

  const onClonePage = useCallback(() => {
    clonePage(element);
  }, [clonePage, element]);

  const onClonePageAsSubPage = useCallback(() => {
    clonePage(set('props.parentPage', element.id, element));
  }, [clonePage, element]);

  const onDeletePage = () => {
    removePage();
    onSetEditing(null);
    dispatch(setSelectedElement([]));
  };

  const siblingPages = useMemo(
    () =>
      pages.filter(
        ({
          id,
          props: { parentPage: parentPageId } = { parentPage: null },
        }: any) =>
          id !== element.id &&
          ((!parentPage && !parentPageId) ||
            (parentPage && parentPage === parentPageId)),
      ),
    [element.id, pages, parentPage],
  );

  const siblingRoutePaths = siblingPages.map(
    ({ props: { routePath: siblingRoutePath = '' } = {} }) => siblingRoutePath,
  );

  const replaceRoute = useMemo(
    () => debounce((routePath) => replace(routePath), DEBOUNCE_TIME),
    [replace],
  );

  const isUniqueRoutePath = !siblingRoutePaths.includes(localRoutePath);

  const updatePageName = ({ target: { value } }: any) => {
    setLocalName(value);

    if (
      (!localName && !localRoutePath) ||
      sanitiseRoutePath(localName) === localRoutePath
    ) {
      const nextRoutePath = sanitiseRoutePath(value);

      if (!siblingRoutePaths.includes(nextRoutePath)) {
        return debouncedUpdateProperty(['name'], value);
      }
      setLocalRoutePath(nextRoutePath);
      debouncedUpdateProperty([], {
        ...element.props,
        name: value,
        routePath: nextRoutePath,
      });
      replaceRoute(nextRoutePath);
    } else {
      debouncedUpdateProperty(['name'], value);
      replaceRoute(localRoutePath);
    }
  };

  const handleRoutePathChange = ({ target: { value } }: any) => {
    const nextRoutePath = sanitiseRoutePath(value);
    setLocalRoutePath(nextRoutePath);

    if (siblingRoutePaths.includes(nextRoutePath)) {
      return errorAlert(getTranslation('uniqueUrlPath.label'));
    }
    debouncedUpdateProperty(['routePath'], nextRoutePath);
    replaceRoute(nextRoutePath);
  };

  const { pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const topLevelPages = useMemo(
    () =>
      pagesPath.length > 0
        ? get(project.elements, pagesPath, [])
        : project.elements,
    [pagesPath, project.elements],
  );

  const hasChildPages = useMemo(
    () =>
      topLevelPages.some(
        (childPage: any) => get(childPage, 'props.parentPage') === element.id,
      ),
    [element.id, topLevelPages],
  );

  const isSplitCollectionLayout = get(element, 'props.layout') === SPLIT;

  const parentOptions = useMemo(() => {
    if (element.type !== VIEW && element.type !== PAGE) {
      return [];
    }

    return [
      {
        value: null,
        label: getTranslation('parentView.none'),
      },
      ...topLevelPages
        .filter(
          ({ id, type, props }: any) =>
            type === FOLDER ||
            (element.type === VIEW &&
              id !== element.id &&
              !isSplitCollectionLayout &&
              !get(props, 'parentPage') &&
              get(props, 'dataList.dataType') ===
                get(element, 'props.dataList.dataType')),
        )
        .map((parentOption: any) => ({
          value: parentOption.id,

          label: (
            <div className="flex items-center">
              <Icon
                className="mr-2 h-6 opacity-75"
                icon={get(parentOption, 'props.icon')}
              />
              <span>{get(parentOption, 'props.name')}</span>
            </div>
          ),
        })),
    ];
  }, [element, isSplitCollectionLayout, topLevelPages]);

  if (element.type === DIVIDER) {
    return (
      <div
        className={classNames('m-1 flex flex-col', className)}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <button
          className={`flex items-center ${
            isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-500'
          } hover:${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
          }`}
          onClick={onShowAdvancedSettings}
        >
          <IconEyeCheck size={18} className="mr-2 opacity-50" />
          <span>{getTranslation('visibility')}</span>
        </button>
        <button
          className="flex items-center py-1 text-red-500 hover:text-red-700"
          onClick={onDeletePage}
        >
          <IconTrash size={18} className="mr-2 opacity-50" />
          <span>{getTranslation('remove')}</span>
        </button>
      </div>
    );
  }

  return (
    <div
      className={classNames('m-1 flex flex-col', className)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="my-2 flex flex-col justify-center">
        <label
          className={`mb-1 text-xs ${
            isDarkModeEnabled ? darkModeColors.text.primary : ''
          }`}
        >
          {getTranslation('name.label')}
        </label>
        <TextInput
          className={
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-black'
          }
          debounceMs={UPDATE_DEBOUNCE_MS}
          value={localName}
          onChange={updatePageName}
        />
      </div>
      {!isExternalLink && (
        <div className="my-2 flex flex-col justify-center">
          <div className="mb-2 flex items-center">
            <label
              className={`mr-2 text-xs ${
                isDarkModeEnabled ? darkModeColors.text.primary : ''
              }`}
            >
              {getTranslation('routePath.label')}
            </label>
          </div>
          <TextInput
            className="text-black"
            debounceMs={UPDATE_DEBOUNCE_MS}
            value={localRoutePath}
            onChange={handleRoutePathChange}
            placeholder={getTranslation('routePath.placeholder')}
          />
          {!isUniqueRoutePath && (
            <ErrorText className="mt-2">
              {getTranslation('routePath.notUnique')}
            </ErrorText>
          )}
        </div>
      )}
      {isExternalLink && get(link, 'type') === URL && (
        <div className="my-2 flex flex-col justify-center">
          <div className="mb-2 flex items-center">
            <label
              className={`mr-2 text-xs ${
                isDarkModeEnabled ? darkModeColors.text.primary : ''
              }`}
            >
              {getTranslation('externalLink.label')}
            </label>
          </div>
          <Suspense fallback={<Loader />}>
            <LazyStringPropEditor
              // @ts-expect-error Property 'project' does not exist on type 'IntrinsicAttributes & object & ExtraComponentProps'.
              project={project}
              onChange={(newLinkValue: any) =>
                debouncedUpdateProperty(['link', 'url'], newLinkValue)
              }
              value={get(link, 'url')}
              elementPath={elementPath}
              placeholder={getTranslation('externalLink.placeholder')}
              surface={LIGHT}
            />
          </Suspense>
        </div>
      )}
      {!isSubPage && (
        <div className="my-2 flex flex-col justify-center">
          <label
            className={`mb-1 text-xs ${
              isDarkModeEnabled ? darkModeColors.text.primary : ''
            }`}
          >
            {getTranslation('icon.label')}
          </label>
          <IconEditor
            clearable={true}
            placement="right"
            updateProperty={(path: ElementPath, icon: string | null) =>
              updateProperty(['icon', ...path], icon)
            }
            elementProps={element.props.icon}
            surface={LIGHT}
          />
        </div>
      )}
      {(element.type === VIEW || element.type === PAGE) && (
        <div className="mt-4 flex flex-col justify-center">
          <label
            className={`mb-1 text-xs ${
              isDarkModeEnabled ? darkModeColors.text.primary : ''
            }`}
          >
            {getTranslation('parentView.label')}
          </label>
          <SelectInput
            className={classNames({
              'opacity-50': hasChildPages,
            })}
            disabled={hasChildPages}
            options={parentOptions}
            value={get(element, 'props.parentPage', null)}
            onChange={(newParentPage: any) =>
              updateProperty(['parentPage'], newParentPage)
            }
            contained={true}
          />
        </div>
      )}
      <div className="mt-6 flex flex-col space-y-2">
        {element.type === VIEW && (
          <div
            className={`flex items-center justify-between ${
              isDarkModeEnabled
                ? darkModeColors.text.secondary
                : 'text-gray-500'
            } hover:${
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
            }`}
          >
            <IconCircleNumber0 size={18} className="mr-2 opacity-50" />

            <label>{getTranslation('showRecordCount')}</label>
            <Switch
              size="sm"
              className="ml-auto"
              onChange={(newValue: any) =>
                updateProperty(['showRecordCount'], newValue)
              }
              value={showRecordCount}
            />
          </div>
        )}
        {!isSubPage &&
          !dataType &&
          element.type !== FOLDER &&
          !isExternalLink &&
          !parentPage && (
            <button
              className={`flex items-center ${
                isDarkModeEnabled
                  ? darkModeColors.text.secondary
                  : 'text-gray-500'
              } hover:${
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-700'
              }`}
              onClick={() => updateProperty(['hide'], !hide)}
            >
              {hide ? (
                <IconEyeCheck size={18} className="mr-2 opacity-50" />
              ) : (
                <IconEyeOff size={18} className="mr-2 opacity-50" />
              )}
              <span>{getTranslation(hide ? 'show' : 'hide')}</span>
            </button>
          )}
        {!dataType &&
          cloneable &&
          element.type !== FOLDER &&
          !isExternalLink && (
            <button
              className={`flex items-center ${
                isDarkModeEnabled
                  ? darkModeColors.text.secondary
                  : 'text-gray-500'
              } hover:${
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-700'
              }`}
              onClick={onClonePage}
            >
              <IconCopy size={18} className="mr-2 opacity-50" />
              <span>{getTranslation('clone')}</span>
            </button>
          )}
        {element.type === VIEW &&
          !parentPage &&
          cloneable &&
          !isSplitCollectionLayout && (
            <button
              className={`flex items-center ${
                isDarkModeEnabled
                  ? darkModeColors.text.secondary
                  : 'text-gray-500'
              } hover:${
                isDarkModeEnabled
                  ? darkModeColors.text.primary
                  : 'text-gray-700'
              }`}
              onClick={onClonePageAsSubPage}
            >
              <IconCornerRightDown size={18} className="mr-2 opacity-50" />
              <span>{getTranslation('cloneSub')}</span>
            </button>
          )}
        {element.type === FOLDER && (
          <SidebarItemOptions
            className={`cursor-pointer ${
              isDarkModeEnabled
                ? darkModeColors.text.secondary
                : 'text-gray-500'
            } hover:${
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
            }`}
            parentPage={element}
            project={project}
            setEditingPage={onEditSelectPage}
            modules={false}
          >
            <IconPlus size={18} className="mr-2 opacity-50" />
            <span>{getTranslation('addView')}</span>
          </SidebarItemOptions>
        )}
        {element.type === FOLDER && (
          <button
            className={`flex items-center ${
              isDarkModeEnabled
                ? darkModeColors.text.secondary
                : 'text-gray-500'
            } hover:${
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
            }`}
            onClick={() => onAddPage({ props: { parentPage: element.id } })}
          >
            <IconFilePlus size={18} className="mr-2 opacity-50" />
            <span>{getTranslation('addBlankPage')}</span>
          </button>
        )}

        <button
          className={`flex items-center ${
            isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-500'
          } hover:${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-700'
          }`}
          onClick={onShowAdvancedSettings}
        >
          {element.type === FOLDER ? (
            <>
              <IconEyeCheck size={18} className="mr-2 opacity-50" />
              <span>{getTranslation('visibility')}</span>
            </>
          ) : (
            <>
              <IconSettings size={18} className="mr-2 opacity-50" />
              <span>{getTranslation('advanced')}</span>
            </>
          )}
        </button>
        <button
          className={`flex items-center py-1 text-red-500 hover:${
            isDarkModeEnabled ? 'text-red-300' : 'text-red-700'
          }`}
          onClick={onDeletePage}
        >
          <IconTrash size={18} className="mr-2 opacity-50" />
          <span>{getTranslation('remove')}</span>
        </button>
      </div>
    </div>
  );
};

export default PageEditor;
