import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import {
  CollectionLayout,
  EVENT_BASED_LAYOUTS,
} from '../../constants/collectionLayouts';
import { ViewRecordStyle, ViewRecordStyleType } from '../../models/Element';
import { Project } from '../../models/Project';
import { editorModeSelector } from '../../selectors/elementsSelectors';
import { getViewRoutePrefixForViewId } from '../urls';

interface RecordRowLinkProps {
  layout: CollectionLayout;
  project: Project;
  recordQueryString?: string;
  recordStyle?: ViewRecordStyle;
  rowLink: string | null;
  uuid?: string;
  viewRootPathname?: string;
}

const NONE = '#';

const useRecordRowLink = ({
  layout,
  project,
  recordQueryString = '',
  recordStyle = { size: MD, type: ViewRecordStyleType.PAGE },
  rowLink,
  uuid = '',
  viewRootPathname = '',
}: RecordRowLinkProps): string | null => {
  const editorMode = useSelector(editorModeSelector);

  return useMemo(() => {
    if (rowLink === NONE || EVENT_BASED_LAYOUTS.includes(layout)) {
      return null;
    }

    const routePrefix =
      recordStyle?.type === ViewRecordStyleType.PAGE || editorMode
        ? 'view'
        : 'preview';

    if (rowLink === undefined) {
      if (!viewRootPathname) {
        return null;
      }

      return `${viewRootPathname}/${routePrefix}/${uuid}${recordQueryString}`;
    }

    const customRootPathName = getViewRoutePrefixForViewId(rowLink, project);

    if (!customRootPathName) {
      return null;
    }

    return `${customRootPathName}/${routePrefix}/${uuid}${recordQueryString}`;
  }, [
    editorMode,
    layout,
    project,
    recordQueryString,
    recordStyle,
    rowLink,
    uuid,
    viewRootPathname,
  ]);
};

export default useRecordRowLink;
