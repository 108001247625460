import React, { forwardRef } from 'react';
import { ShirtSize } from '../../constants/tShirtSizes';
import { PRIMARY, Variant } from '../../constants/variants';
import { Surface } from '../../models';
import DefaultButton from '../button/Button';
import { ButtonType } from '../button/buttonTypes';
import SelectBase, { SelectOption } from '../select/SelectBase';
import { OptionValue } from '../switch/SwitchButton';

export interface DropdownProps {
  Button?: any;
  children: any;
  className?: string;
  contained?: boolean;
  direction?: 'right' | 'left' | 'top' | 'bottom';
  disabled?: boolean;
  placeholder?: string;
  searchable?: boolean;
  minW?: number;
  open?: boolean;
  options?: SelectOption[];
  onChange?: (value: OptionValue) => void;
  size?: ShirtSize;
  surface?: Surface;
  stopPropagation?: boolean;
  theme?: string;
  type?: ButtonType | 'default';
  variant?: Variant;
  value?: any;
  w?: number;
}

const Dropdown = forwardRef<any, DropdownProps>(
  (
    {
      // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'Props'.
      bg,
      // @ts-expect-error TS(2339): Property 'border' does not exist on type 'Props'.
      border,
      // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'Pro... Remove this comment to see the full error message
      borderColor,
      contained,
      Button,
      children,
      className,
      // @ts-expect-error TS(2339): Property 'closeOnOutsideClick' does not exist on t... Remove this comment to see the full error message
      closeOnOutsideClick,
      disabled,
      direction,
      // @ts-expect-error TS(2339): Property 'footer' does not exist on type 'Props'.
      footer,
      // @ts-expect-error TS(2339): Property 'hasMore' does not exist on type 'Props'.
      hasMore,
      // @ts-expect-error TS(2339): Property 'listBg' does not exist on type 'Props'.
      listBg,
      // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'Props'.
      loading,
      // @ts-expect-error TS(2339): Property 'multiple' does not exist on type 'Props'... Remove this comment to see the full error message
      multiple,
      // @ts-expect-error TS(2339): Property 'name' does not exist on type 'Props'.
      name,
      onChange,
      // @ts-expect-error TS(2339): Property 'onFetchMore' does not exist on type 'Pro... Remove this comment to see the full error message
      onFetchMore,
      // @ts-expect-error TS(2339): Property 'onOpenChange' does not exist on type 'Pr... Remove this comment to see the full error message
      onOpenChange,
      // @ts-expect-error TS(2339): Property 'onSearchChange' does not exist on type '... Remove this comment to see the full error message
      onSearchChange,
      open,
      options,
      type,
      size,
      searchable,
      surface,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'Props'.
      style,
      stopPropagation,
      value,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      minW,
      ...rest
    }: DropdownProps,
    ref,
  ) => (
    <SelectBase
      bg={bg}
      border={border}
      borderColor={borderColor}
      contained={contained}
      className={className}
      closeOnOutsideClick={closeOnOutsideClick}
      direction={direction}
      disabled={disabled}
      footer={footer}
      hasMore={hasMore}
      listBg={listBg}
      loading={loading}
      multiple={multiple}
      name={name}
      options={options}
      onChange={onChange}
      onFetchMore={onFetchMore}
      onOpenChange={onOpenChange}
      onSearchChange={onSearchChange}
      open={open}
      hideDropdownIndicator={true}
      ref={ref}
      searchable={searchable}
      surface={surface}
      stopPropagation={stopPropagation}
      size={size}
      style={style}
      validationError={validationError}
      value={value}
      minW={minW}
    >
      {() => (
        <Button className={className} type={type} style={style} {...rest}>
          {children}
        </Button>
      )}
    </SelectBase>
  ),
);

Dropdown.defaultProps = {
  className: '',
  onChange: () => null,
  Button: DefaultButton,
  direction: 'right',
  placeholder: 'Search...',
  type: 'default',
  stopPropagation: false,
  theme: 'default',
  variant: PRIMARY,
};

export default Dropdown;
