import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import DatePickerPopover, {
  formatLocalDateTimeResult,
} from '@noloco/components/src/components/input/DatePickerPopover';
import TextInput from '@noloco/components/src/components/input/TextInput';
import { ROUNDED_LARGE } from '@noloco/components/src/components/input/inputStyles';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import {
  DATE_SHORT,
  DURATION_TIME,
  TIME,
  TIME_24,
} from '../constants/dateFormatOptions';
import { isAmPmTime } from '../utils/time';

const TIME_COMMA_SEPARATOR_REGEX = /(,? \d\d?:\d\d)/;

export const formatDate = (
  start: DateTime,
  end: DateTime | null,
  isRange: boolean,
  selectTime: boolean,
  timeZone: any,
  locale: Locale,
  showTimeSelectOnly?: boolean,
) => {
  if (!start || (isRange && !end)) {
    return null;
  }
  const dateFormat = selectTime ? DateTime.DATETIME_SHORT : DateTime.DATE_SHORT;
  let formattedStart = start;

  if (selectTime) {
    if (timeZone) {
      formattedStart = start.setZone(timeZone);
    } else {
      formattedStart = start.toLocal();
    }
  }

  const formattedStartString = formattedStart
    .setLocale(locale.code as string)
    .toLocaleString(dateFormat)
    .replace(',', '');

  if (showTimeSelectOnly) {
    return start.toFormat(
      isAmPmTime(formattedStartString) ? DURATION_TIME : TIME_24,
    );
  }

  if (!isRange) {
    return formattedStartString;
  }

  let formattedEnd = end;

  if (formattedEnd && end) {
    const endDateTime = selectTime
      ? timeZone
        ? end.setZone(timeZone)
        : end.toLocal()
      : end;

    return `${formattedStartString} - ${endDateTime
      .setLocale(locale.code as string)
      .toLocaleString(dateFormat)
      .replace(',', '')}`;
  }
};

interface Props {
  clearable?: boolean;
  value?: any; // TODO: PropTypes.instanceOf(Date)
  placeholder?: React.ReactNode | string;
  footer?: JSX.Element;
  isRange?: boolean;
  onOpenChange?: (nextOpen: boolean) => void;
  placement?: string;
  style?: any; // TODO: oneOfWithDefault(inputStyles)
  selectTime?: boolean;
  showTimeSelectOnly?: boolean;
}

const DatePicker = forwardRef<any, Props>(
  (
    {
      // @ts-expect-error TS(2339): Property 'autoFocus' does not exist on type 'Props... Remove this comment to see the full error message
      autoFocus,
      // @ts-expect-error TS(2339): Property 'bg' does not exist on type 'Props'.
      bg,
      // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'Pro... Remove this comment to see the full error message
      borderColor,
      clearable,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
      disabled,
      // @ts-expect-error TS(2339): Property 'id' does not exist on type 'Props'.
      id,
      // @ts-expect-error TS(2339): Property 'inline' does not exist on type 'Props'.
      inline,
      footer,
      isRange,
      // @ts-expect-error TS(2339): Property 'onBlur' does not exist on type 'Props'.
      onBlur,
      // @ts-expect-error TS(2339): Property 'onChange' does not exist on type 'Props'... Remove this comment to see the full error message
      onChange,
      // @ts-expect-error TS(2339): Property 'open' does not exist on type 'Props'.
      open,
      onOpenChange,
      placeholder,
      placement,
      selectTime,
      style,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Props'.
      surface,
      // @ts-expect-error TS(2339): Property 'timeZone' does not exist on type 'Props'... Remove this comment to see the full error message
      timeZone,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      value,
      showTimeSelectOnly,
      ...rest
    },
    ref,
  ) => {
    const locale = useLocale();
    const [localValidationError, setLocalValidationError] = useState(false);

    const debouncedOnChange = useMemo(
      () => debounce(onChange, 600),
      [onChange],
    );

    const parseNewTextValue = useCallback(
      (value: string, includeTime: boolean) => {
        let nextValue = value;

        if (includeTime) {
          nextValue = nextValue.replace(TIME_COMMA_SEPARATOR_REGEX, ',$1');
        }

        const [date, time = ''] = nextValue.split(', ', 2);

        const timeFormat = includeTime && isAmPmTime(time) ? TIME : TIME_24;
        const dateTimeFormat = `${DATE_SHORT} ${timeFormat}`;

        let dateValue = DateTime.fromFormat(date, DATE_SHORT, {
          locale: locale.code,
        });

        if (dateValue.isValid) {
          let dateToUpdate = dateValue;
          const timeValue =
            includeTime &&
            DateTime.fromFormat(time, timeFormat, {
              locale: locale.code,
            });

          if (timeValue) {
            if (timeValue.isValid) {
              dateToUpdate = DateTime.fromFormat(
                `${date} ${time}`,
                dateTimeFormat,
                { locale: locale.code },
              );
            } else {
              setLocalValidationError(true);

              return;
            }
          }

          setLocalValidationError(false);

          return formatLocalDateTimeResult(dateToUpdate, selectTime, timeZone);
        } else {
          setLocalValidationError(true);

          return;
        }
      },
      [locale.code, selectTime, timeZone],
    );

    const onTextValueChange = useCallback(
      (event: any) => {
        let nextValue = event.target.value;

        if (!nextValue) {
          setLocalValidationError(false);

          return debouncedOnChange(null);
        }

        if (showTimeSelectOnly) {
          const duration = DateTime.fromFormat(
            nextValue,
            isAmPmTime(nextValue) ? DURATION_TIME : TIME_24,
          );

          debouncedOnChange(duration);

          return setLocalValidationError(false);
        }

        if (!isRange) {
          const dateTime = parseNewTextValue(nextValue, !!selectTime);

          if (dateTime) {
            debouncedOnChange(dateTime);
          }
        } else {
          const [start, end] = nextValue.split(' - ');
          const dateRange = {
            start: parseNewTextValue(start, !!selectTime),
            end: parseNewTextValue(end, !!selectTime),
          };

          if (dateRange.start && dateRange.end) {
            debouncedOnChange(dateRange);
          }
        }
      },
      [
        debouncedOnChange,
        isRange,
        parseNewTextValue,
        selectTime,
        showTimeSelectOnly,
      ],
    );

    return (
      <DatePickerPopover
        id={id}
        bg={bg}
        borderColor={borderColor}
        clearable={clearable}
        disabled={disabled}
        isRange={isRange}
        onBlur={onBlur}
        footer={footer}
        onChange={onChange}
        open={open}
        onOpenChange={onOpenChange}
        placement={placement}
        selectTime={selectTime}
        showTimeSelectOnly={showTimeSelectOnly}
        style={style}
        surface={surface}
        timeZone={timeZone}
        value={value}
      >
        {({ startDate, endDate, selectsRange, selectTime }: any) => (
          <TextInput
            id={`${id}-text`}
            data-testid={`${id}-text`}
            autoFocus={autoFocus}
            bg={bg}
            disabled={disabled}
            inline={inline}
            placeholder={placeholder}
            surface={surface}
            style={style}
            onBlur={onBlur}
            onChange={onTextValueChange}
            autoComplete="off"
            value={formatDate(
              startDate,
              endDate,
              selectsRange,
              selectTime,
              timeZone,
              locale,
              showTimeSelectOnly,
            )}
            valid={!localValidationError}
            validationError={validationError}
            {...rest}
            ref={ref}
          />
        )}
      </DatePickerPopover>
    );
  },
);

DatePicker.defaultProps = {
  clearable: true,
  isRange: false,
  // @ts-expect-error TS(2322): Type '{ clearable: true; isRange: false; inline: b... Remove this comment to see the full error message
  inline: false,
  style: ROUNDED_LARGE,
  selectTime: false,
  showTimeSelectOnly: false,
  theme: 'default',
};

export default DatePicker;
