import { Theme } from '@noloco/components';
import Canvas from './canvas/Canvas';
import ProjectRenderer from './canvas/ProjectRenderer';

interface CanvasRouteProps {
  nolocoTheme: Theme;
}

const CanvasRoute = ({ nolocoTheme }: CanvasRouteProps) => (
  <Canvas isPublic={false} nolocoTheme={nolocoTheme}>
    <ProjectRenderer />
  </Canvas>
);

export default CanvasRoute;
