import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { LIGHT } from '../../constants/surface';
import { getColorShade } from '../../utils';
import {
  BOTTOM_BORDER,
  CONTAINED,
  PILLS,
  ROUNDED,
  TABS,
} from './horizontalNavTypes';

// @ts-expect-error TS(7006): Parameter 'color' implicitly has an 'any' type.
export const typeStyles = (color, surface = LIGHT) => ({
  [CONTAINED]: {
    p: { x: 4, y: 2 },
    borderL: true,
    borderT: true,
    borderB: true,
    'bg-hocus': getColorShade(color, surface === LIGHT ? 100 : 800),
  },

  [ROUNDED]: {
    m: { x: 2 },
    p: { x: 4, y: 1 },
    rounded: 'full',
    'bg-hocus': getColorShade(color, surface === LIGHT ? 100 : 800),
  },

  [TABS]: {
    border: getColorShade(color, surface === LIGHT ? 300 : 600),
    borderB: true,
    p: { x: 6, y: 2 },
  },

  [BOTTOM_BORDER]: {
    className: '-mb-1',
    p: { x: 0, t: 2, b: 3 },
    font: ['medium'],
    tracking: 'wider',
    'text-hocus': getColorShade(color, surface === LIGHT ? 700 : 300),
  },

  [PILLS]: {
    m: { x: 2 },
    p: { x: 4, y: 1 },
    rounded: 'full',
    'bg-hocus': getColorShade(color, surface === LIGHT ? 100 : 800),
  },
});

// @ts-expect-error TS(7006): Parameter 'color' implicitly has an 'any' type.
const activeTypeStyles = (color, surface = LIGHT) => ({
  [CONTAINED]: {
    bg: getColorShade(color, 500),
    border: getColorShade(color, 500),
    borderR: false,
    text: 'white',
    'bg-hocus': getColorShade(color, surface === LIGHT ? 700 : 300),
  },

  [ROUNDED]: {
    bg: getColorShade(color, 500),
    text: 'white',
    'bg-hocus': getColorShade(color, surface === LIGHT ? 700 : 300),
  },

  [TABS]: {
    borderB: false,
    borderT: true,
    borderR: true,
    borderL: true,
  },

  [BOTTOM_BORDER]: {
    className: '-mb-0.5',
    border: color,
    borderB: 4,
    p: { x: 0, y: 2 },
    h: 'full',
    text: color,
  },

  [PILLS]: {
    bg: color,
    text: 'white',
    'bg-hocus': getColorShade(color, surface === LIGHT ? 700 : 300),
  },
});

interface HorizontalNavItemProps {
  active?: boolean;
  className?: string;
  type?: any; // TODO: PropTypes.oneOf(horizontalNavTypes)
  theme: {};
  children: React.ReactNode;
}

const HorizontalNavItem = forwardRef<any, HorizontalNavItemProps>(
  (
    {
      active,
      // @ts-expect-error TS(2339): Property 'bgColor' does not exist on type 'Horizon... Remove this comment to see the full error message
      bgColor,
      children,
      className,
      // @ts-expect-error TS(2339): Property 'surface' does not exist on type 'Horizon... Remove this comment to see the full error message
      surface,
      // @ts-expect-error TS(2339): Property 'is' does not exist on type 'HorizontalNa... Remove this comment to see the full error message
      is,
      // @ts-expect-error TS(2339): Property 'to' does not exist on type 'HorizontalNa... Remove this comment to see the full error message
      to,
      type,
      theme,
      ...rest
    },
    ref,
  ) => {
    const color = bgColor || (theme as any).brandColors['primary'];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const { className: styleClassName, ...styles } = typeStyles(color, surface)[
      type
    ];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const activeStyles = active ? activeTypeStyles(color, surface)[type] : {};

    return (
      <Box
        className={classNames(
          className,
          styleClassName,
          'cursor-pointer whitespace-nowrap',
        )}
        is={is}
        ref={ref}
        to={to}
        {...styles}
        {...activeStyles}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

HorizontalNavItem.defaultProps = {
  active: false,
  className: '',
  // @ts-expect-error TS(2322): Type '{ active: false; className: string; is: stri... Remove this comment to see the full error message
  is: 'a',
  type: CONTAINED,
};

export default withTheme(HorizontalNavItem);
