import React, { useCallback } from 'react';
import { IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Switch } from '@noloco/components';
import { trackOnboardingChecklistCtaClicked } from '@noloco/core/src/utils/analytics';
import { getText } from '@noloco/core/src/utils/lang';
import Guide from '../Guide';

const OnboardingTask = ({
  category,
  onEnableEditorMode,
  task,
  hasExternalUsers,
  updateSettings,
}: any) => {
  const baseLangKey = `onboardingChecklist.categories.${category}.tasks.${task.id}`;

  const onCtaPress = useCallback(() => {
    if (task.editMode) {
      onEnableEditorMode();
    }
    trackOnboardingChecklistCtaClicked(task.id);
  }, [onEnableEditorMode, task.editMode, task.id]);

  const LinkEl = task.disabled ? 'span' : Link;

  return (
    <div className="flex p-6">
      <div
        className={classNames(
          'mt-1 flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-lg text-white',
          {
            'bg-blue-400': task.complete,
            'bg-gray-600': !task.complete,
          },
        )}
      >
        <IconCheck size={14} />
      </div>
      <div className="ml-4 flex flex-grow flex-col">
        <span
          className={classNames('text-base font-medium', {
            'text-gray-400': task.complete,
            'text-white': !task.complete,
          })}
        >
          {getText(baseLangKey, 'title')}
        </span>
        {!task.switch && (
          <p
            className={classNames('mt-2 text-xs', {
              'text-gray-400': task.complete,
              'text-gray-300': !task.complete,
            })}
          >
            {getText(baseLangKey, 'description')}
          </p>
        )}
        {task.guide && (
          <Guide className="mt-4" href={task.guide}>
            {getText(baseLangKey, 'help')}
          </Guide>
        )}
      </div>
      <div className="ml-4 flex w-40 flex-shrink-0 items-start justify-end">
        {task.switch ? (
          <Switch
            onChange={(value: any) => {
              updateSettings(['onboarding', 'hasExternalUsers'], value);
            }}
            value={hasExternalUsers}
          />
        ) : (
          // @ts-expect-error ts(2322) remove to see complete error
          <LinkEl to={task.cta}>
            <Button
              className={classNames('whitespace-nowrap disabled:opacity-50', {
                'opacity-50 hover:opacity-100': task.complete,
              })}
              disabled={task.disabled}
              onClick={task.disabled ? undefined : onCtaPress}
              variant="secondary"
            >
              {getText(baseLangKey, 'cta')}
            </Button>
          </LinkEl>
        )}
      </div>
    </div>
  );
};

export default OnboardingTask;
