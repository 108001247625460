import { useCallback } from 'react';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';

export interface DragAndDropReOrderResult<T> {
  formatItem: (item: T) => { id: string };
  draftItems: T[];
  findItem: (itemId: string) => { itemForId: T | undefined; index: number };
  onSaveOrder: (oldIndex: number, droppedIndex: number) => void;
}

const useDragAndDropReOrder = <T>(
  items: T[],
  getId: (item: T) => string,
  updateItemOrder: (newOrder: T[], droppedIndex: number) => void,
  canDrop?: (items: T[]) => boolean,
  transformDroppedItem: <T>(droppedItem: T, existingItem: T) => T = identity,
): DragAndDropReOrderResult<T> => {
  const findItem = useCallback(
    (itemId: string) => {
      const itemForId = items.find((item: T) => getId(item) === itemId);

      return {
        itemForId,
        index: itemForId ? items.indexOf(itemForId) : -1,
      };
    },
    [items, getId],
  );

  const onSaveOrder = useCallback(
    (oldIndex: number, droppedIndex: number) => {
      const newFieldOrder = [...items];
      const droppedItem = transformDroppedItem(
        get(newFieldOrder, oldIndex),
        get(newFieldOrder, droppedIndex),
      );

      if (!isNil(oldIndex) && !isNil(droppedIndex)) {
        newFieldOrder.splice(oldIndex, 1);
        newFieldOrder.splice(droppedIndex, 0, droppedItem);
      }

      if (!canDrop || canDrop(newFieldOrder)) {
        updateItemOrder(newFieldOrder, droppedIndex);
      }
    },
    [canDrop, items, transformDroppedItem, updateItemOrder],
  );

  const formatItem = useCallback(
    (item: any) => ({
      id: getId(item),
    }),
    [getId],
  );

  return {
    draftItems: items,
    findItem,
    formatItem,
    onSaveOrder,
  };
};

export default useDragAndDropReOrder;
