import { Dispatch, SetStateAction, useCallback } from 'react';
import { toast } from 'sonner';
import { getText } from '../lang';

export const useShowUpdatingAlert = (
  setIsLoading: Dispatch<SetStateAction<boolean>> | undefined,
) => {
  const withUpdatingAlert = useCallback(
    (callback: () => Promise<any>, message?: string) => {
      const updatingAlert = toast.loading(
        message ?? getText('elements.VIEW.display.bulkActions.updating'),
        {
          dismissible: true,
          duration: Infinity,
          position: 'bottom-right',
        },
      );
      setIsLoading?.(true);

      return callback().finally(() => {
        // https://github.com/emilkowalski/sonner/issues/288
        // This is pretty weird. So when this is called while another callback is being executed, the second callback is instantly finished, while the first one remains active.
        setTimeout(() => {
          toast.dismiss(updatingAlert);
        }, 1);
        setIsLoading?.(false);
      });
    },
    [setIsLoading],
  );

  return withUpdatingAlert;
};
