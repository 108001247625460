import { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { Theme } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import useLocale, {
  getLocaleName,
} from '@noloco/components/src/utils/hooks/useLocale';
import {
  CHECKLIST,
  CollectionLayout,
  ROWS,
  SPLIT,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { BOOLEAN, DECIMAL, INTEGER } from '../../../constants/dataTypes';
import { FIELD_CELL, PROGRESS_BAR } from '../../../constants/elements';
import { DUE_DATE } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Project } from '../../../models/Project';
import { BaseRecord, DueDate, RecordValue } from '../../../models/Record';
import { User } from '../../../models/User';
import { FormFieldConfig } from '../../../models/View';
import { formatFieldValue } from '../../../utils/fieldValues';
import { canBeCopiedToClipboard } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getOrBuildFormFieldsConfigForType } from '../../../utils/hooks/useFormFields';
import { layoutIsTable } from '../../../utils/hooks/useIsTable';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { PermissionConfig } from '../../../utils/permissions';
import InlineAutoForm from '../forms/InlineAutoForm';
import CopyFieldValueButton from './CopyFieldValueButton';
import DueDateCell from './DueDateCell';
import ReadOnlyFieldCellValue from './ReadOnlyFieldCellValue';

export const shouldAlignRight = (layout: CollectionLayout, field: DataField) =>
  layoutIsTable(layout) && (field.type === DECIMAL || field.type === INTEGER);

export const getFormFieldConfig = (
  field: DataField,
  project: Project,
  user: User,
  fieldPermissionsEnabled: boolean,
  fieldConfig: FormFieldConfig | undefined,
): FormFieldConfig => {
  const allowNewRecords =
    fieldConfig?.allowNewRecords && (field.relationship || field.relatedField);
  const relatedType =
    allowNewRecords && project.dataTypes.getByName(field.type);

  const { fields: newRecordFields, title: newRecordTitle }: any =
    allowNewRecords && relatedType
      ? getOrBuildFormFieldsConfigForType(
          relatedType,
          project,
          user,
          fieldPermissionsEnabled,
          fieldConfig?.newRecordForm,
        )
      : {};

  const config = fieldConfig || ({} as FormFieldConfig);

  return {
    ...config,
    allowNewRecords,
    fieldObj: field,
    newRecordFields,
    newRecordTitle,
    placeholder: !field.relationship
      ? config.placeholder || ''
      : config.placeholder,
    required: config.required,
  } as FormFieldConfig & { newRecordFields: FormFieldConfig[] };
};

export const formatValue = (
  value: RecordValue,
  field: DataField,
  config: any = {},
  locale?: Locale,
) => {
  const localeName = getLocaleName();

  const formattedValue = formatFieldValue(
    value,
    field,
    config,
    localeName,
    locale,
  );

  const { typeOptions: { format } = {} } = field;

  if (format === DUE_DATE) {
    return (
      <DueDateCell config={config} field={field} value={value as DueDate} />
    );
  }

  if (formattedValue !== undefined) {
    return formattedValue;
  }

  return value;
};

interface InlineFieldAutoFormProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  dataType: DataType;
  field: DataField;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  project: Project;
  resolvedConfig: FormFieldConfig;
  scope: Record<string, any>;
  selectedRows?: BaseRecord[];
  skipResolvingForValueIds: string[];
  theme: Theme;
  transformScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  value: RecordValue;
}

const InlineFieldAutoForm = ({
  backLink,
  field,
  dataType,
  layout,
  project,
  resolvedConfig,
  scope,
  theme,
  value,
  bulkActionsEnabled,
  isRowChecked,
  selectedRows,
}: InlineFieldAutoFormProps) => (
  <InlineAutoForm
    bulkActionsEnabled={bulkActionsEnabled}
    className={classNames('w-full max-w-full', {
      'rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700':
        field.type !== BOOLEAN,
    })}
    dataType={dataType}
    elementId="id"
    field={field}
    isRowChecked={isRowChecked}
    project={project}
    ReadOnlyCell={() => (
      <ReadOnlyFieldCellValue
        backLink={backLink}
        config={resolvedConfig}
        field={field}
        layout={layout}
        project={project}
        theme={theme}
        value={value}
      />
    )}
    scope={scope}
    selectedRows={selectedRows}
    surface={LIGHT}
  />
);

interface FieldCellValueProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  config: FormFieldConfig;
  dataType: DataType;
  field: DataField;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  permissions: PermissionConfig;
  project: Project;
  record: BaseRecord;
  section?: boolean;
  selectedRows?: BaseRecord[];
  showInput?: boolean;
  skipResolvingForValueIds?: string[];
  theme: Theme;
  transformScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  value: RecordValue;
}

const FieldCellValue = ({
  backLink,
  bulkActionsEnabled,
  config,
  dataType,
  field,
  isRowChecked,
  layout,
  permissions,
  project,
  record,
  section,
  selectedRows,
  showInput,
  skipResolvingForValueIds = [],
  theme,
  transformScope = identity,
  value,
}: FieldCellValueProps) => {
  const locale = useLocale();
  const scope = useMemo(
    () =>
      transformScope(
        {
          id: { edges: { node: record } },
        },
        record,
      ),
    [transformScope, record],
  );

  const resolvedConfig = useSectionScopeVariables(
    FIELD_CELL,
    config,
    project,
    [],
    scope,
  );

  const showInlineForm = useMemo(
    () =>
      showInput &&
      permissions.update &&
      !field.readOnly &&
      ((config.elementType && config.elementType === PROGRESS_BAR) ||
        !config.elementType),
    [config.elementType, field.readOnly, permissions.update, showInput],
  );

  if (!permissions.read) {
    return null;
  }

  if (showInlineForm) {
    return (
      <InlineFieldAutoForm
        bulkActionsEnabled={bulkActionsEnabled}
        dataType={dataType}
        field={field}
        isRowChecked={isRowChecked}
        layout={layout}
        project={project}
        resolvedConfig={resolvedConfig}
        scope={scope}
        selectedRows={selectedRows}
        skipResolvingForValueIds={skipResolvingForValueIds}
        theme={theme}
        transformScope={transformScope}
        value={value}
      />
    );
  }

  const showCopyToClipboard =
    config.copyToClipboard && canBeCopiedToClipboard(field) && !isNil(value);

  return (
    <div
      className={classNames('group/field-cell flex items-center', {
        relative: showCopyToClipboard,
      })}
    >
      <ReadOnlyFieldCellValue
        backLink={backLink}
        config={resolvedConfig}
        field={field}
        layout={layout}
        project={project}
        section={section}
        theme={theme}
        value={value}
      />
      {showCopyToClipboard && (
        <CopyFieldValueButton
          layout={layout}
          value={formatValue(value, field, config, locale)}
        />
      )}
    </div>
  );
};

interface FieldCellProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  className?: string;
  config: FormFieldConfig;
  dataType: DataType;
  field: DataField;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  permissions: PermissionConfig;
  project: Project;
  record: BaseRecord;
  readOnly: boolean;
  section?: boolean;
  selectedRows?: BaseRecord[];
  showInput: boolean;
  showLabel: boolean;
  skipResolvingForValueIds?: string[];
  theme: Theme;
  transformScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  value: RecordValue;
}

const FieldCell = ({
  backLink,
  bulkActionsEnabled,
  className,
  config,
  dataType,
  field,
  isRowChecked,
  layout,
  permissions,
  project,
  readOnly,
  record,
  section = false,
  selectedRows,
  showInput,
  showLabel,
  skipResolvingForValueIds = [],
  theme,
  transformScope,
  value,
}: FieldCellProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  const textColor = useMemo(
    () => (isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-500'),
    [isDarkModeEnabled],
  );

  const alignRight = useMemo(
    () => shouldAlignRight(layout, field),
    [layout, field],
  );

  const columnWidth = useMemo(
    () =>
      config.columnWidth || (config.columnSpan ? config.columnSpan * 4 : null),
    [config],
  );

  const isGridLayout = useMemo(
    () => layout === ROWS || layout === SPLIT || layout === CHECKLIST,
    [layout],
  );

  return (
    <div
      className={classNames(className, 'w-full max-w-full', {
        'col-span-12': isGridLayout && columnWidth === 12,
        'col-span-3 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 3 ||
            (!columnWidth && (layout === ROWS || layout === CHECKLIST))),
        'col-span-4 lg:col-span-6':
          isGridLayout &&
          (columnWidth === 4 || (!columnWidth && layout === SPLIT)),
        'col-span-6': isGridLayout && columnWidth === 6,
        'col-span-8': isGridLayout && columnWidth === 8,
        'col-span-9': isGridLayout && columnWidth === 9,
        'md:col-span-12': isGridLayout,
        'mr-auto': !alignRight,
      })}
      data-testid="field-cell"
    >
      <div
        className={classNames(className, { 'w-full text-right': alignRight })}
      >
        {showLabel && config.label && !get(config, 'label.hidden') && (
          <span
            className={classNames(
              'mb-2 text-xs font-medium tracking-wider',
              textColor,
            )}
            data-testid="field-cell-label"
          >
            {get(config, 'label.value')}
          </span>
        )}
        <FieldCellValue
          backLink={backLink}
          bulkActionsEnabled={bulkActionsEnabled}
          config={config}
          dataType={dataType}
          field={field}
          isRowChecked={isRowChecked}
          layout={layout}
          permissions={permissions}
          project={project}
          record={record}
          section={section}
          selectedRows={selectedRows}
          showInput={(showInput || config.editInline) && !readOnly}
          skipResolvingForValueIds={skipResolvingForValueIds}
          theme={theme}
          transformScope={transformScope}
          value={value}
        />
      </div>
    </div>
  );
};

export default withTheme(FieldCell);
