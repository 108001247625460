import React, { forwardRef, useMemo } from 'react';
import { IconChevronRight, IconShieldLock } from '@tabler/icons-react';
import classNames from 'classnames';
import last from 'lodash/last';
import { Link } from 'react-router-dom';
import { Tooltip } from '@noloco/components';
import { FIELD_LEVEL_PERMISSIONS } from '@noloco/core/src/constants/features';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { User } from '@noloco/core/src/models/User';
import cappedMemoize from '@noloco/core/src/utils/cappedMemoize';
import useAuthWrapper from '@noloco/core/src/utils/hooks/useAuthWrapper';
import { getText } from '@noloco/core/src/utils/lang';
import { nestedFieldPermissions } from '@noloco/core/src/utils/permissions';
import { DataItemOption, flattenStateItem } from '@noloco/core/src/utils/state';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import DataFieldIcon from '../DataFieldIcon';

// @ts-expect-error TS(7023): 'findOption' implicitly has return type 'any' beca... Remove this comment to see the full error message
export const findOption = (
  options: any,
  data: any,
  buttonLabel: any[] = [],
) => {
  if (!data) {
    return { option: null };
  }

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < options.length; i++) {
    const option = options[i];

    if (option.id && data.id && data.id !== option.id) {
      continue;
    }

    if (option.path && data.path && !data.path.startsWith(option.path)) {
      continue;
    }

    if (option.options) {
      // @ts-expect-error TS(7022): 'potentialOption' implicitly has type 'any' becaus... Remove this comment to see the full error message
      const potentialOption = findOption(option.options, data, [
        ...buttonLabel,
        ...(option.heading ? [] : [option.label]),
      ]);

      if (potentialOption && potentialOption.option) {
        return potentialOption;
      }
    } else if (option.getOptions) {
      const potentialOption: any = findOption(option.getOptions(), data, [
        ...buttonLabel,
        ...(option.heading ? [] : [option.label]),
      ]);

      if (potentialOption && potentialOption.option) {
        return potentialOption;
      }
    } else if (option.value) {
      if (option.value.id === data.id && option.value.path === data.path) {
        if (option.field && option.field.name === 'id') {
          // prevent duplicate paths in the button label when it's using the ID
          return { option, buttonLabel };
        }

        if (option.value.id === 'values') {
          // Dynamic base values can simply show their label without the path
          return { option, buttonLabel: [option.label] };
        }

        return { option, buttonLabel: [...buttonLabel, option.label] };
      }
    }
  }

  return { option: null };
};

const findOptionAtPath = cappedMemoize(
  (
    dataOptions: DataItemOption[],
    data: any,
    _parentPath: (string | number)[],
  ) => findOption(dataOptions, data),
  {
    getKey: ([_dataOptions, data, parentPath]) =>
      `${flattenStateItem(data)}:${parentPath.join('.')}`,
    maxKeys: 20,
  },
);

export const DisplayWrapper = ({
  children,
  displayAsToken,
  selected = false,
  valid = true,
}: {
  children: any;
  displayAsToken: boolean;
  selected?: boolean;
  valid?: boolean;
}) => (
  <div
    className={classNames('flex items-center', {
      'my-0.5 inline-flex max-w-full cursor-pointer select-none items-center rounded-lg border-2 px-2 py-0.5':
        displayAsToken,
      'border-gray-500 bg-gray-900 text-gray-100': displayAsToken && valid,
      'ring-2 ring-inset ring-white': displayAsToken && valid && selected,
      'border-red-600 bg-red-500 text-white': displayAsToken && !valid,
    })}
    contentEditable={false}
  >
    {children}
  </div>
);

const FieldTokenDisplayWithPermissions = ({
  children,
  displayAsToken,
  field,
  dataType,
  selected,
}: {
  children: any;
  displayAsToken: boolean;
  field: DataField;
  dataType: DataType;
  selected: boolean;
}) => {
  const { user } = useAuthWrapper();
  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);
  const fieldPermissions = useMemo(
    () =>
      nestedFieldPermissions(
        field,
        dataType,
        undefined,
        undefined,
        user as User,
        fieldPermissionsEnabled,
      ),
    [dataType, field, fieldPermissionsEnabled, user],
  );

  if (!fieldPermissions.read) {
    return (
      <DisplayWrapper
        displayAsToken={displayAsToken}
        selected={selected}
        valid={false}
      >
        <div className="flex space-x-2">
          <Tooltip
            content={
              <div className="flex flex-col">
                <p>{getText('fields.permissionsWarning.read')}</p>
                <Link
                  to={`/_/data/internal/${dataType.name}/permissions`}
                  className="mt-1 font-medium text-cyan-500 hover:text-cyan-600 hover:underline"
                >
                  {getText('fields.permissionsWarning.viewPermissions')}
                </Link>
              </div>
            }
            placement="top"
            bg="white"
          >
            <span className="flex items-center">
              <IconShieldLock size={16} className="flex-shrink-0" />
            </span>
          </Tooltip>
          {children}
        </div>
      </DisplayWrapper>
    );
  }

  return (
    <DisplayWrapper
      displayAsToken={displayAsToken}
      selected={selected}
      valid={true}
    >
      {children}
    </DisplayWrapper>
  );
};

const FieldTokenDisplay = ({
  children,
  checkFieldPermissions,
  field,
  dataType,
  displayAsToken,
  selected,
}: {
  children: any;
  checkFieldPermissions: boolean;
  field: DataField | undefined;
  dataType: DataType | undefined;
  displayAsToken: boolean;
  selected: boolean;
}) => {
  if (checkFieldPermissions && field && dataType) {
    return (
      <FieldTokenDisplayWithPermissions
        displayAsToken={displayAsToken}
        selected={selected}
        field={field}
        dataType={dataType}
      >
        {children}
      </FieldTokenDisplayWithPermissions>
    );
  }

  return (
    <DisplayWrapper
      displayAsToken={displayAsToken}
      selected={selected}
      valid={true}
    >
      {children}
    </DisplayWrapper>
  );
};

interface Props {
  compact?: boolean;
  checkFieldPermissions?: boolean;
  data: any;
  dataOptions: DataItemOption[];
  displayAsToken?: boolean;
  parentPath?: (string | number)[];
  selected?: boolean;
}
const getCustomDisplayNameFromPath = (
  path: string,
  getText: (options: any, ...string: string[]) => string,
): string => {
  const LANG_KEY = 'contentEditor.values.DATE';
  const [, _custom, frame, direction, count] = path.split('.');

  return getText({ context: count }, LANG_KEY, frame, direction);
};
const ContentDisplayName = forwardRef<any, Props>(
  (
    {
      className,
      checkFieldPermissions = true,
      compact,
      data,
      dataOptions,
      displayAsToken = false,
      parentPath,
      selected,
      ...rest
    }: any,
    ref,
  ) => {
    const flattenedValue = flattenStateItem(data);
    const { option, buttonLabel } = parentPath
      ? findOptionAtPath(dataOptions, data, parentPath)
      : findOption(dataOptions, data);

    if (option && buttonLabel) {
      if (compact) {
        const items = buttonLabel;

        return (
          <FieldTokenDisplay
            checkFieldPermissions={checkFieldPermissions}
            displayAsToken={displayAsToken}
            field={option.field}
            dataType={option.dataType}
            selected={selected}
          >
            <span
              contentEditable={false}
              className={classNames(
                className,
                'flex max-w-full select-none items-center space-x-1',
              )}
              {...rest}
              ref={ref}
            >
              {option.field && (
                <DataFieldIcon
                  size={14}
                  className="flex-shrink-0 opacity-75"
                  field={option.field}
                />
              )}
              <span className="truncate">{last(items)}</span>
            </span>
          </FieldTokenDisplay>
        );
      }

      return (
        <FieldTokenDisplay
          checkFieldPermissions={checkFieldPermissions}
          displayAsToken={displayAsToken}
          field={option.field}
          dataType={option.dataType}
          selected={selected}
        >
          <span
            contentEditable={false}
            className={classNames(className, 'flex select-none flex-col')}
            {...rest}
            ref={ref}
          >
            <span className="flex flex-wrap items-center">
              {buttonLabel.map((path: any, index: any) => (
                <>
                  <span className="whitespace-nowrap text-xs">{path}</span>
                  {index !== buttonLabel.length - 1 && (
                    <IconChevronRight
                      size={12}
                      className="mx-1 flex-shrink-0"
                    />
                  )}
                </>
              ))}
            </span>
            {option.help && <span className="text-sm">{option.help}</span>}
          </span>
        </FieldTokenDisplay>
      );
    }

    if (data && data.path?.startsWith('DATE.custom')) {
      const label = getCustomDisplayNameFromPath(data.path, getText);

      return (
        <DisplayWrapper
          displayAsToken={displayAsToken}
          selected={selected}
          valid={false}
        >
          <span
            contentEditable={false}
            data-value={flattenedValue}
            {...rest}
            ref={ref}
          >
            <span className="text-xs">{label}</span>
          </span>
        </DisplayWrapper>
      );
    }

    if (data && data.id) {
      return (
        <DisplayWrapper
          displayAsToken={displayAsToken}
          selected={selected}
          valid={false}
        >
          <span
            contentEditable={false}
            data-value={flattenedValue}
            {...rest}
            ref={ref}
          >
            {getText('data.invalid')}
          </span>
        </DisplayWrapper>
      );
    }

    return (
      <DisplayWrapper
        displayAsToken={displayAsToken}
        selected={selected}
        valid={true}
      >
        <span
          contentEditable={false}
          className={classNames(className, 'select-none')}
          {...rest}
          ref={ref}
        >
          Select data
        </span>
      </DisplayWrapper>
    );
  },
);

ContentDisplayName.defaultProps = {
  compact: false,
};

export default ContentDisplayName;
