import classNames from 'classnames';
import { getText } from '../../../../utils/lang';
import { Group } from '../../Collection';

interface PivotTableRecordLayoutProps {
  group: Group;
  rows: {
    aggregate: number | null;
    formattedAggregate: any;
    key: string;
    valueKey: string | undefined;
  }[];
}

const PivotTableRecordLayout = ({
  group,
  rows,
}: PivotTableRecordLayoutProps) => (
  <tr className="relative w-full px-6 text-xs" key={group.id}>
    <td className="max-w-sm py-2 pl-6 pr-3">
      {group.label === undefined || group.label === null
        ? getText('core.COLLECTION.groups.noValue')
        : String(group.label)}
    </td>
    {rows.map(({ key, formattedAggregate }, index) => {
      const isLast = index === rows.length - 1;

      return (
        <td
          className={classNames('max-w-sm py-2 text-right', {
            'pr-6': isLast,
            'px-3': !isLast,
          })}
          key={key}
        >
          {formattedAggregate ?? 0}
        </td>
      );
    })}
  </tr>
);

export default PivotTableRecordLayout;
