import { useCallback, useMemo } from 'react';
import { IconGripVertical, IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { SECTION_LIST_ITEM } from '../../constants/draggableItemTypes';
import { CONTAINER, MARKDOWN } from '../../constants/elements';
import elementConfigs from '../../elements/elementConfig';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import {
  setHighlightedSectionPath,
  setLeftEditorSection,
  setSelectedElement,
  setSelectedSectionPath,
} from '../../reducers/elements';
import { getText } from '../../utils/lang';
import BuildModeSectionOptions from '../buildMode/BuildModeSectionOptions';
import ContainerSectionListItem from './ContainerSectionListItem';
import EditableLabel from './EditableLabel';

interface SectionListItemProps {
  active?: boolean;
  activeSection?: string;
  dataType: DataType;
  elementPath: ElementPath;
  elementType: string;
  index: number;
  project: Project;
  scrollToSection: (sectionId: string) => void;
  section: Element;
  sections: Element[];
  selectedTab?: ViewTab | null;
  sectionPath: ElementPath;
}

const reorderSections = (
  sections: Element[],
  newIndex: number,
  oldIndex: number,
): Element[] => {
  const newSectionsOrder = [...sections];
  const droppedSection = get(sections, oldIndex);

  newSectionsOrder.splice(oldIndex, 1);
  newSectionsOrder.splice(newIndex, 0, droppedSection);

  return newSectionsOrder;
};

const SectionListItem = ({
  active = false,
  activeSection,
  dataType,
  elementPath,
  elementType,
  index,
  project,
  scrollToSection,
  section,
  sectionPath,
  sections,
  selectedTab,
}: SectionListItemProps) => {
  const [updateProperty] = useUpdateProperty(elementPath, project);

  const [{ draggedSection, isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      draggedSection: monitor.getItem(),
      isDragging: monitor.isDragging(),
    }),
    item: section,
    type: SECTION_LIST_ITEM,
  });

  const [{ isOver: isAbove }, aboveDropRef] = useDrop({
    accept: SECTION_LIST_ITEM,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: () => handleDrop(),
  });

  const [{ isOver: isAboveSection }, aboveSectionDropRef] = useDrop({
    accept: SECTION_LIST_ITEM,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: () =>
      handleDrop(true, section.type === CONTAINER ? section.id : undefined),
  });

  const { Icon } = elementConfigs[section.type];
  const dispatch = useDispatch();
  const selectionDisabled = isDragging;

  const getSectionLabel = useCallback((section: Element) => {
    if (section.name && section.name.trim() !== '') {
      return section.name;
    }

    return getText(
      'elements',
      section.type,
      section.type === MARKDOWN ? 'sectionLabel' : 'label',
    );
  }, []);

  const containerSections = useMemo(() => {
    if (section.type === CONTAINER) {
      return sections
        .filter((containerSection) => containerSection.container === section.id)
        .map((containerSection) => {
          const { Icon } = elementConfigs[containerSection.type];

          return {
            container: section.id,
            Icon,
            id: containerSection.id,
            label: getSectionLabel(containerSection),
            section: containerSection,
            sectionIndex: sections.findIndex(
              (section) => section.id === containerSection.id,
            ),
            type: containerSection.type,
          };
        });
    }

    return [];
  }, [section, sections, getSectionLabel]);

  const selectSection = useCallback(
    (
      sectionIndex: any = null,
      containerSectionId: any = null,
      containerSectionType: any = null,
    ) => {
      if (!selectionDisabled) {
        dispatch(setSelectedSectionPath([sectionIndex ?? index]));
        dispatch(setHighlightedSectionPath([]));
        scrollToSection(containerSectionId ?? section.id);
        dispatch(setSelectedElement(elementPath));
        dispatch(setLeftEditorSection(containerSectionType ?? section.type));
      }
    },
    [dispatch, elementPath, index, scrollToSection, section, selectionDisabled],
  );

  const handleMouseOver = useCallback(
    (sectionIndex: number | null = null, sectionId: string | null = null) => {
      if (!selectionDisabled) {
        dispatch(setHighlightedSectionPath([sectionIndex ?? index]));
      }
      scrollToSection(sectionId ?? section.id);
    },
    [selectionDisabled, index, dispatch, section, scrollToSection],
  );

  const handleMouseOut = useCallback(() => {
    if (!selectionDisabled) {
      dispatch(setHighlightedSectionPath([]));
    }
  }, [dispatch, selectionDisabled]);

  const handleDrop = useCallback(
    (over = false, container = '', droppedOn?: string) => {
      let newOrder = [] as Element[];
      const newIndex = sections.findIndex(
        ({ id }) =>
          id === (over && container && droppedOn ? droppedOn : section.id),
      );
      const oldIndex = sections.findIndex(({ id }) => id === draggedSection.id);

      if (!over && container === '') {
        newOrder = reorderSections(
          set(oldIndex, { ...draggedSection, container: undefined }, sections),
          newIndex,
          oldIndex,
        );
      }

      if (over && container) {
        newOrder = reorderSections(
          set(oldIndex, { ...draggedSection, container }, sections),
          newIndex,
          oldIndex,
        );
      }

      updateProperty(sectionPath, newOrder);
      selectSection(newIndex);
    },
    [
      draggedSection,
      section,
      sectionPath,
      sections,
      selectSection,
      updateProperty,
    ],
  );

  return (
    <>
      <div
        className={classNames('my-0.5 h-1 w-full rounded-full', {
          'bg-slate-600': isAbove,
        })}
        ref={aboveDropRef}
      />
      <div
        className={classNames('group/parent mb-1 flex flex-col rounded-lg', {
          'bg-slate-900 bg-opacity-25 hover:bg-opacity-50':
            section.type === CONTAINER,
        })}
        key={section.id}
      >
        <div
          className={classNames(
            'group mr-auto flex h-8 w-full cursor-pointer items-center rounded-lg p-2 hover:bg-slate-600',
            { 'bg-slate-900': active },
          )}
          onMouseOut={handleMouseOut}
          onMouseOver={() => handleMouseOver()}
          onClick={() => selectSection()}
          ref={dragRef}
        >
          <div className="mr-4">
            <button
              className="hidden cursor-move group-hover:flex"
              draggable={true}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <IconGripVertical size={16} />
            </button>
            <Icon className="flex group-hover:hidden" size={16} />
          </div>
          <EditableLabel
            value={getSectionLabel(section)}
            onUpdate={(value) =>
              updateProperty([...sectionPath, index, 'name'], value)
            }
            placeholder={getText('elements', section.type, 'label')}
            active={active}
          />
        </div>
        {section.type === CONTAINER && (
          <>
            {containerSections.map(
              ({ container, Icon, id, label, section, sectionIndex, type }) => (
                <ContainerSectionListItem
                  key={id}
                  activeSection={activeSection}
                  container={container}
                  handleDrop={handleDrop}
                  handleMouseOut={handleMouseOut}
                  handleMouseOver={handleMouseOver}
                  Icon={Icon}
                  id={id}
                  label={label}
                  section={section}
                  sectionIndex={sectionIndex}
                  selectSection={selectSection}
                  type={type}
                  updateProperty={(path, value) =>
                    updateProperty(
                      [...sectionPath, sectionIndex, ...path],
                      value,
                    )
                  }
                />
              ),
            )}
            {containerSections.length === 0 && (
              <div
                className={classNames(
                  'ml-8 mr-1 flex h-1 flex-grow rounded-full',
                  { 'bg-slate-600': isAboveSection },
                )}
                ref={aboveSectionDropRef}
              />
            )}
            <BuildModeSectionOptions
              container={section.type === CONTAINER ? section.id : undefined}
              dataType={dataType}
              elementPath={elementPath}
              elementType={elementType}
              project={project}
              sections={sections}
              selectedTab={selectedTab}
            >
              <div className="my-1 ml-8 mr-1 flex h-8 flex-grow cursor-pointer items-center space-x-2 rounded-lg p-2 text-slate-400 hover:bg-slate-600">
                <IconPlus size={16} />
                <span>{getText('leftSidebar.editor.addSection')}</span>
              </div>
            </BuildModeSectionOptions>
          </>
        )}
      </div>
    </>
  );
};

export default SectionListItem;
