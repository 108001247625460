import { Operation } from '@apollo/client';
import { BASE_CORE_URI, BASE_URI } from '../constants/auth';
import { IS_PRODUCTION, IS_QA } from '../constants/env';
import SafeStorage from './SafeStorage';

const LAMBDA_ENDPOINT_URI = `https://api.${
  IS_PRODUCTION ? 'noloco' : 'nolocoqa'
}.io`;

export const USE_LAMBDA_ENDPOINT_KEY = 'noloco.lambda-endpoint';
export const USE_LAMBDA_MUTATION_ENDPOINT_KEY =
  'noloco.lambda-mutation-endpoint';
export const USE_SELECTIVE_REFRESH_BY_DATA_TYPES_KEY =
  'noloco.selective-refresh-by-data-types';

const hasLambdaLocalStorageFlag = new SafeStorage().getBoolean(
  USE_LAMBDA_ENDPOINT_KEY,
  false,
);
const hasLambdaMutationLocalStorageFlag = new SafeStorage().getBoolean(
  USE_LAMBDA_MUTATION_ENDPOINT_KEY,
  false,
);
export const USE_SELECTIVE_REFRESH_BY_DATA_TYPES = new SafeStorage().getBoolean(
  USE_SELECTIVE_REFRESH_BY_DATA_TYPES_KEY,
  false,
);

export const USE_PROJECT_LAMBDA_ENDPOINT =
  process.env.USE_LAMBDA_FOR_PROJECT_API === 'true' ||
  hasLambdaLocalStorageFlag;

export const USE_PROJECT_MUTATION_LAMBDA_ENDPOINT =
  process.env.USE_LAMBDA_FOR_PROJECT_MUTATION_API === 'true' ||
  hasLambdaMutationLocalStorageFlag;

export const USE_CORE_LAMBDA_ENDPOINT =
  process.env.USE_LAMBDA_FOR_CORE_API === 'true' || hasLambdaLocalStorageFlag;

const hasFileUploadVariable = (variables: Record<string, any>) =>
  Object.values(variables).some(
    (value) =>
      value instanceof File ||
      value instanceof Blob ||
      (Array.isArray(value) &&
        value.some((item) => item instanceof File || item instanceof Blob)),
  );

const getProjectEndpointUri = (
  projectName: string,
  {
    hasSubscription,
    hasMutation,
    hasFileUpload,
  }: {
    hasSubscription: boolean;
    hasMutation: boolean;
    hasFileUpload: boolean;
  },
) => {
  const standardEndpointUri = `${BASE_URI}/data/${projectName}`;

  if (hasSubscription || hasFileUpload || (!IS_QA && !IS_PRODUCTION)) {
    return standardEndpointUri;
  }

  if (hasMutation) {
    return USE_PROJECT_MUTATION_LAMBDA_ENDPOINT
      ? `${LAMBDA_ENDPOINT_URI}/mutation/${projectName}`
      : standardEndpointUri;
  }

  return USE_PROJECT_LAMBDA_ENDPOINT
    ? `${LAMBDA_ENDPOINT_URI}/data/${projectName}`
    : standardEndpointUri;
};

export const getOperationUri =
  (baseProjectName?: string) => (operation: Operation) => {
    const {
      projectQuery,
      projectName = baseProjectName,
      skipLambdas,
    } = operation.getContext();
    const queryOperations = new Set(
      operation.query.definitions.reduce((acc: string[], def) => {
        if (def.kind === 'OperationDefinition') {
          acc.push(def.operation);
        }

        return acc;
      }, []),
    );

    const hasSubscription = queryOperations.has('subscription');
    const hasMutation = queryOperations.has('mutation');
    const hasFileUpload =
      hasMutation && hasFileUploadVariable(operation.variables);

    if (projectQuery && projectName) {
      return getProjectEndpointUri(projectName, {
        hasSubscription,
        hasMutation,
        hasFileUpload,
      });
    }
    const skipCoreLambda =
      skipLambdas === true || hasSubscription || hasMutation;

    return USE_CORE_LAMBDA_ENDPOINT &&
      !skipCoreLambda &&
      (IS_QA || IS_PRODUCTION)
      ? `${LAMBDA_ENDPOINT_URI}/core`
      : BASE_CORE_URI;
  };
