import { createSlice } from '@reduxjs/toolkit';

const elementsSlice = createSlice({
  name: 'elements',
  initialState: {
    canvasShortcutsEnabled: false,
    clipboard: null,
    editingPage: null,
    editorMode: false,
    elements: [],
    fieldListEditorOpen: false,
    highlightedSectionPath: [],
    leftEditorSection: null,
    newElementPath: [],
    recordPreview: false,
    rightTab: null,
    selected: [],
    selectedPagePath: undefined,
    selectedSectionPath: [],
    showAdvancedPageSettings: false,
    sidebarRecordViewTitle: null,
  },
  reducers: {
    setCanvasShortcutsEnabled: (state, { payload }) => {
      state.canvasShortcutsEnabled = payload;
    },
    setEditorMode: (state, { payload }) => {
      state.editorMode = payload;
    },
    setRecordPreview: (state, { payload }) => {
      state.recordPreview = payload;
    },
    setRightTab: (state, { payload }) => {
      state.rightTab = payload;
    },
    setEditingPage: (state, { payload }) => {
      state.editingPage = payload;
    },
    setShowAdvancedPageSettings: (state, { payload }) => {
      state.showAdvancedPageSettings = payload;
    },
    setElements: (state, { payload }) => {
      state.elements = payload;
    },
    setNewElementPath: (state, { payload }) => {
      state.newElementPath = payload;
      state.selected = [];
    },
    setSelectedSectionPath: (state, { payload }) => {
      state.selectedSectionPath = payload;
    },
    setHighlightedSectionPath: (state, { payload }) => {
      state.highlightedSectionPath = payload;
    },
    setLeftEditorSection: (state, { payload }) => {
      state.leftEditorSection = payload;
    },
    setSelectedElement: (state, { payload }) => {
      state.selected = payload;
      state.newElementPath = [];
    },
    setSelectedPagePath: (state, { payload }) => {
      state.selectedPagePath = payload;
    },
    setClipboard: (state, { payload }) => {
      state.clipboard = payload;
    },
    setSidebarRecordViewTitle: (state, { payload }) => {
      state.sidebarRecordViewTitle = payload;
    },
    setIsFieldListEditorOpen: (state, { payload }) => {
      state.fieldListEditorOpen = payload;
    },
  },
});

export const {
  setCanvasShortcutsEnabled,
  setClipboard,
  setEditingPage,
  setEditorMode,
  setElements,
  setHighlightedSectionPath,
  setIsFieldListEditorOpen,
  setLeftEditorSection,
  setNewElementPath,
  setRecordPreview,
  setRightTab,
  setSelectedElement,
  setSelectedPagePath,
  setSelectedSectionPath,
  setShowAdvancedPageSettings,
  setSidebarRecordViewTitle,
} = elementsSlice.actions;

export default elementsSlice.reducer;
