import React, { useMemo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMenu } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import {
  HorizontalNav,
  HorizontalNavItem,
  Popover,
  getColorShade,
} from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getClassNameFromHeaderWidth } from '../../utils/headerWidth';
import RecordViewTabItem from './RecordViewTabItem';

const RecordViewTabs = ({
  className,
  editorMode,
  headerWidth,
  project,
  queryRecordId,
  recordId,
  recordScope,
  rootPathname,
  stickyTabs = false,
  tab,
  theme,
  visibleTabs,
}: any) => {
  const activeTab = useMemo(
    () =>
      (visibleTabs &&
        visibleTabs.find((tabItem: any) => kebabCase(tabItem.title) === tab)) ||
      first(visibleTabs),
    [tab, visibleTabs],
  );

  const primaryColor = theme.brandColors.primary;
  const [isDarkModeEnabled] = useDarkMode();

  if (isDarkModeEnabled) {
    theme.borderColors.default = darkModeColors.borders.oneLiteralColor;
    theme.borderColors.light = darkModeColors.borders.oneLiteralColor;
  }

  if (!visibleTabs || visibleTabs.length <= 1) {
    return null;
  }

  return (
    <>
      <Box
        className={classNames(
          'hidden w-full items-center justify-between border-b px-4 py-2 text-sm font-medium sm:px-0 md:flex',
          {
            [darkModeColors.borders.one]: isDarkModeEnabled,
            'absolute left-0 top-0 h-10 w-full border-gray-400 bg-white':
              stickyTabs,
            [darkModeColors.surfaces.elevation1]:
              stickyTabs && isDarkModeEnabled,
            'sm:border-b-0': !stickyTabs,
          },
        )}
        border={getColorShade(primaryColor, 500)}
        text={getColorShade(primaryColor, 500)}
        data-testid="record-view-tabs"
      >
        <span
          className={classNames('mr-2 truncate', {
            'ml-4': stickyTabs,
          })}
        >
          {activeTab.title}
        </span>
        <Popover
          content={visibleTabs.map((tabDef: any, tabIndex: any) => (
            <RecordViewTabItem
              className={`my-0.5 w-full rounded-lg px-2 py-1 text-sm text-${getColorShade(
                primaryColor,
                500,
              )} hover:${
                isDarkModeEnabled ? darkModeColors.borders.two : 'bg-gray-100'
              }`}
              editorMode={editorMode}
              is={Link}
              key={tabDef && tabDef.id}
              project={project}
              queryRecordId={queryRecordId}
              recordId={recordId}
              recordScope={recordScope}
              rootPathname={rootPathname}
              tab={tab}
              tabDef={tabDef}
              tabIndex={tabIndex}
            />
          ))}
          placement="bottom-end"
        >
          <button className={classNames({ 'mr-4': stickyTabs })}>
            <IconMenu size={16} />
          </button>
        </Popover>
      </Box>
      <div className={classNames('flex w-full md:hidden', className)}>
        <HorizontalNav
          center={false}
          className={classNames(
            'mx-auto w-full space-x-8 text-sm',
            isDarkModeEnabled ? darkModeColors.borders.two : '',
            getClassNameFromHeaderWidth(headerWidth),
          )}
          borderB={0}
          type="bottom-border"
        >
          {visibleTabs.map((tabDef: any, tabIndex: any) => (
            <RecordViewTabItem
              editorMode={editorMode}
              is={HorizontalNavItem}
              key={tabDef && tabDef.id}
              project={project}
              queryRecordId={queryRecordId}
              recordId={recordId}
              recordScope={recordScope}
              rootPathname={rootPathname}
              tab={tab}
              tabDef={tabDef}
              tabIndex={tabIndex}
            />
          ))}
        </HorizontalNav>
      </div>
    </>
  );
};

export default withTheme(RecordViewTabs);
