import { IconCheck, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Link } from 'react-router-dom';
import {
  Button,
  RatingInput,
  RelativeDateTimeTooltip,
  Theme,
  getColorShade,
} from '@noloco/components/src';
import useLocale from '@noloco/components/src/utils/hooks/useLocale';
import MarkdownText from '../../../components/MarkdownText';
import { COUNT } from '../../../constants/chartAggregations';
import {
  BOOLEAN,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '../../../constants/dataTypes';
import {
  BUTTON,
  IMAGE,
  LINK,
  MARKDOWN,
  PROGRESS_BAR,
  PROGRESS_RING,
  RELATIVE_DATE,
} from '../../../constants/elements';
import {
  CONDENSED_RELATIONSHIP,
  JSON_FORMAT,
  QR_CODE,
} from '../../../constants/fieldDisplayTypes';
import {
  EMAIL,
  IP_ADDRESS,
  PHONE_NUMBER,
  RATING,
  SLIDER,
} from '../../../constants/fieldFormats';
import { BOLD, H1, H2, H3, H4 } from '../../../constants/textTypes';
import { RecordValue } from '../../../models/Record';
import { ensureArray } from '../../../utils/arrays';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useIsTable from '../../../utils/hooks/useIsTable';
import { getText } from '../../../utils/lang';
import { getOptionByName } from '../../../utils/options';
import { isMultiField } from '../../../utils/relationships';
import { formatUrl } from '../../../utils/urls';
import Progress from '../Progress';
import DownloadableQRCode from '../view/DownloadableQRCode';
import ArrayValueBadge from './ArrayValueBadge';
import { formatValue, shouldAlignRight } from './FieldCell';
import OptionBadge from './OptionBadge';
import RelatedFieldCell from './RelatedFieldCell';

const renderLink = (
  content: string,
  href: string,
  theme: any,
  openInNewTab?: boolean,
) => {
  const primaryColor = get(theme, 'brandColorGroups.primary');

  if (first(href) === '/') {
    return (
      <Link
        to={href}
        className={`truncate text-${getColorShade(
          primaryColor,
          500,
        )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
      >
        {content}
      </Link>
    );
  }

  return (
    <a
      href={href}
      onClick={(event) => event.stopPropagation()}
      {...(openInNewTab
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {})}
      className={`truncate text-${getColorShade(
        primaryColor,
        500,
      )} hover:underline hover:text-${getColorShade(primaryColor, 600)}`}
    >
      {content}
    </a>
  );
};

const getSafeJSON = (text: string) => {
  try {
    const formattedJSON = JSON.parse(text);

    return JSON.stringify(formattedJSON, undefined, 2);
  } catch {
    return text;
  }
};

const renderElementType = (formattedValue: any, config: any, theme: Theme) => {
  if (!config.elementType) {
    return formattedValue;
  }

  if (config.elementType === BOLD) {
    return <strong className="my-0 font-semibold">{formattedValue}</strong>;
  }

  if (config.elementType === H1) {
    return (
      <h1 className="my-0 pb-2 text-3xl font-black leading-none">
        {formattedValue}
      </h1>
    );
  }

  if (config.elementType === H2) {
    return (
      <h2 className="my-0 text-2xl font-bold leading-snug">{formattedValue}</h2>
    );
  }

  if (config.elementType === H3) {
    return (
      <h3 className="my-0 text-xl font-semibold leading-normal">
        {formattedValue}
      </h3>
    );
  }

  if (config.elementType === H4) {
    return (
      <h4 className="my-0 text-lg font-semibold leading-normal">
        {formattedValue}
      </h4>
    );
  }

  if (config.elementType === JSON_FORMAT) {
    return (
      <div className="font-mono tracking-wider">
        {getSafeJSON(formattedValue)}
      </div>
    );
  }

  const linkContent =
    get(config, 'elementConfig.text') ||
    getText('elements.VIEW.fields.elementType.LINK.default');

  if (config.elementType === LINK) {
    return renderLink(
      linkContent,
      formattedValue,
      theme,
      !!get(config, 'elementConfig.newTab'),
    );
  }

  if (config.elementType === BUTTON) {
    if (first(formattedValue) === '/') {
      return (
        <Link to={formattedValue}>
          <Button className="whitespace-nowrap">{linkContent}</Button>
        </Link>
      );
    }

    return (
      <Button
        is="a"
        className="whitespace-nowrap"
        href={formattedValue}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          event.stopPropagation()
        }
        {...(get(config, 'elementConfig.newTab')
          ? { target: '_blank', rel: 'noopener noreferrer' }
          : {})}
      >
        {linkContent}
      </Button>
    );
  }

  if (config.elementType === MARKDOWN) {
    return <MarkdownText>{formattedValue}</MarkdownText>;
  }

  if (config.elementType === IMAGE) {
    return (
      <img
        src={formattedValue}
        alt={get(config, 'elementConfig.alt')}
        className="w-full overflow-hidden rounded-lg"
      />
    );
  }

  if (config.elementType === QR_CODE) {
    return <DownloadableQRCode value={formattedValue} />;
  }

  return formattedValue;
};

const ReadOnlyFieldCellValue = ({
  backLink,
  field,
  value,
  config,
  layout,
  project,
  theme,
  section,
}: any) => {
  const surface = useDarkModeSurface();
  const locale = useLocale();
  const format = get(field, 'typeOptions.format', null);
  const isTable = useIsTable(layout);

  if (!field.relationship && !field.relatedField) {
    if (isNil(value)) {
      return <span className="text-gray-500">--</span>;
    }

    if (field.multiple) {
      return (
        <div className="flex max-w-xl flex-wrap gap-1 overflow-hidden">
          {ensureArray(value).map((item: any) => {
            if (field.type === BOOLEAN) {
              return (
                <ArrayValueBadge
                  key={`${field.name}-${item}`}
                  value={item ? <IconCheck size={20} /> : <IconX size={20} />}
                />
              );
            }

            return (
              <ArrayValueBadge
                key={`${field.name}-${item}`}
                value={formatValue(item, field, config) as RecordValue}
              />
            );
          })}
        </div>
      );
    }

    if (field.type === SINGLE_OPTION) {
      const option = getOptionByName(value, field);

      if (option) {
        return <OptionBadge className="mr-auto" option={option} />;
      }
    }

    if (field.type === MULTIPLE_OPTION) {
      return (
        <div className="flex max-w-xl flex-wrap gap-1 overflow-hidden">
          {ensureArray(value).map((rawOption: any) => {
            const option = getOptionByName(rawOption, field);

            if (option) {
              return <OptionBadge key={option.name} option={option} />;
            }

            return null;
          })}
        </div>
      );
    }

    if (field.type === BOOLEAN) {
      return value ? (
        <IconCheck size={20} />
      ) : (
        <span className="text-gray-500">--</span>
      );
    }

    if (format === EMAIL) {
      return renderLink(value, `mailto:${value}`, theme);
    }

    if (field.typeOptions?.format === IP_ADDRESS) {
      return renderLink(value, `http://${value}`, theme);
    }

    if (field.typeOptions?.format === RATING) {
      return (
        <RatingInput
          disabled={true}
          maxRating={get(field.typeOptions, 'max')}
          value={value}
        />
      );
    }

    if (format === URL && !config.elementType) {
      return renderLink(value, formatUrl(value) ?? '', theme);
    }

    const formattedValue = formatValue(value, field, config, locale);

    if (format === PHONE_NUMBER && formattedValue) {
      return renderLink(
        formattedValue as string,
        `tel:${formattedValue}`,
        theme,
      );
    }

    if (
      [PROGRESS_BAR, PROGRESS_RING].includes(config.elementType) ||
      format === SLIDER
    ) {
      return (
        <Progress
          config={config}
          value={value}
          formattedMaxValue={
            formatValue(
              get(config, 'elementConfig.maxValue', 100),
              field,
              config,
            ) as number
          }
          formattedValue={formattedValue as number}
          primaryColor={get(theme, 'brandColors.primary')}
          section={section}
          typeOptions={field.typeOptions}
          layout={layout}
        />
      );
    }

    if (
      isTable &&
      (!config.elementType ||
        [MARKDOWN, LINK, BOLD, H1, H2, H3].includes(config.elementType))
    ) {
      const elementResult = renderElementType(formattedValue, config, theme);

      return (
        <div
          className={classNames('max-w-full whitespace-pre-wrap break-words', {
            'h-full': config.truncate,
            'w-full': shouldAlignRight(layout, field),
          })}
        >
          <span
            className={classNames('block', {
              'h-full truncate': config.truncate,
            })}
          >
            {elementResult}
          </span>
          <span
            aria-hidden="true"
            className="invisible block h-0 overflow-hidden whitespace-nowrap"
          >
            {elementResult}
          </span>
        </div>
      );
    }

    if (config.elementType === RELATIVE_DATE) {
      return (
        <RelativeDateTimeTooltip
          className="flex flex-shrink"
          date={value}
          surface={surface}
          format={field.typeOptions?.format}
          timeZone={field.typeOptions?.timeZone}
        />
      );
    }

    return (
      <span className="w-full truncate whitespace-pre-wrap text-balance break-words">
        {renderElementType(formattedValue, config, theme)}
      </span>
    );
  }

  if (isMultiField(field) && config.elementType === COUNT) {
    return (
      <span className="whitespace-pre-wrap break-words">
        {get(value, 'edges', []).length}
      </span>
    );
  }

  if (
    isNil(value) ||
    (isMultiField(field) && get(value, 'edges', []).length === 0)
  ) {
    return <span className="text-gray-500">--</span>;
  }

  return (
    <RelatedFieldCell
      backLink={backLink}
      condensed={
        isMultiField(field) && config.elementType === CONDENSED_RELATIONSHIP
      }
      config={config}
      field={field}
      value={value}
      project={project}
    />
  );
};

export default ReadOnlyFieldCellValue;
