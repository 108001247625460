import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Feature } from '@noloco/core/src/constants/features';
import {
  planUsageSelector,
  projectUsageSelector,
} from '@noloco/core/src/selectors/billingPlanSelectors';

export const useFeatureUsage = (feature: Feature) => {
  const planUsage = useSelector(planUsageSelector);
  const projectUsage = useSelector(projectUsageSelector);

  return get(planUsage, feature) ?? get(projectUsage, feature);
};
