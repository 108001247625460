import { useCallback, useMemo, useState } from 'react';
import { MutationHookOptions, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import {
  NOT_SETUP,
  REQUIRED,
  SETUP_REQUIRED,
  TWO_FACTOR_AUTH_PROMPT_DISMISSED,
} from '../../constants/twoFactorAuth';
import { GET_TWO_FACTOR_AUTH_STATUS_QUERY } from '../../queries/auth';
import { userSelector } from '../../selectors/userSelectors';
import { YMD_TIME_FORMAT } from '../dates';
import { downloadCsvStringAsFile } from '../files';
import { getText } from '../lang';
import { getTextFromError, useErrorAlert, useInfoAlert } from './useAlerts';
import { useUpdateUserCache } from './useAuthWrapper';
import { useExpiringLocalStorage } from './useExpiringLocalStorage';
import useIsWindows from './useIsWindows';

enum AuthSteps {
  GENERATE = 'generate',
  VERIFY = 'verify',
  GENERATE_BACKUP_CODES = 'generateBackupCodes',
}

const LANG_KEY = 'auth.twoFactorAuth';

interface UseTwoFactorAuthHookProps {
  projectName: string;
  secondFactorAuthToken?: string;
  onFinish: () => void;
  onRemove?: () => void;
  generateOTP: (secondFactorAuthToken?: string) => Promise<any>;
  generateOTPBackupCodes: () => Promise<any>;
  verifyOTP: (otp: string, secondFactorAuthToken?: string) => Promise<any>;
  verifyOTPBackupCode: (
    backupCode: string,
    secondFactorAuthToken?: string,
  ) => Promise<any>;
  removeTwoFactorAuth: ({
    token,
    userId,
  }: {
    token?: string;
    userId?: string;
  }) => Promise<Record<string, any>>;
  requiresSecondFactor?:
    | typeof REQUIRED
    | typeof SETUP_REQUIRED
    | typeof NOT_SETUP;
}

interface UseTwoFactorAuthHookReturn {
  setupStep: AuthSteps;
  setSetupStep: (step: AuthSteps) => void;
  otpAuthUrl: string | undefined;
  base32: string | undefined;
  otpValue: string;
  setOtpValue: (otpValue: string) => void;
  loading: boolean;
  errors: string[];
  statusData: any;
  statusQueryLoading: boolean;
  setErrors: (errors: string[]) => void;
  isDismissSetupExpired: boolean;
  handleGenerate: () => Promise<void>;
  handleVerify: (otp: string) => Promise<void>;
  handleGenerateBackupCodes: () => Promise<void>;
  handleRemoveTwoFactorAuth: (token: string) => Promise<void>;
  handleVerifyOTPBackupCode: (backupCode: string) => Promise<void>;
  handleDismissTwoFactorAuthSetup: () => void;
}

export const useTwoFactorAuth = ({
  projectName,
  secondFactorAuthToken,
  onFinish,
  onRemove,
  generateOTP,
  generateOTPBackupCodes,
  verifyOTP,
  verifyOTPBackupCode,
  removeTwoFactorAuth,
  requiresSecondFactor,
}: UseTwoFactorAuthHookProps): UseTwoFactorAuthHookReturn => {
  const [setupStep, setSetupStep] = useState<AuthSteps>(AuthSteps.GENERATE);
  const [otpAuthUrl, setOtpAuthUrl] = useState<string | undefined>();
  const [base32, setBase32] = useState<string | undefined>();
  const [otpValue, setOtpValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const isWindows = useIsWindows();

  const user = useSelector(userSelector);
  const updateUserCache = useUpdateUserCache();

  const infoAlert = useInfoAlert();
  const errorAlert = useErrorAlert();

  const queryOptions: MutationHookOptions = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: projectName,
        authQuery: true,
      },
      variables: { id: user?.id },
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
    }),
    [projectName, user?.id],
  );

  const { data: statusData, loading: statusQueryLoading } = useQuery(
    GET_TWO_FACTOR_AUTH_STATUS_QUERY,
    {
      ...queryOptions,
      skip: !user,
    },
  );

  const handleGenerate = useCallback(async () => {
    setLoading(true);

    return generateOTP(secondFactorAuthToken)
      .then((data) => {
        setOtpAuthUrl(data.otpAuthUrl);
        setBase32(data.base32);
      })
      .catch((errors) => {
        setErrors(errors.map((error: any) => getTextFromError(error).message));
      })
      .finally(() => setLoading(false));
  }, [generateOTP, secondFactorAuthToken]);

  const handleVerify = useCallback(
    async (otp: string) => {
      setLoading(true);

      return verifyOTP(otp, secondFactorAuthToken)
        .then((res) => {
          if (res) {
            setSetupStep(AuthSteps.GENERATE_BACKUP_CODES);
            updateUserCache?.(res);
            setOtpValue('');
          }

          if (!res) {
            throw new Error();
          }
        })
        .catch(() => setErrors([getText(LANG_KEY, 'error.otpInvalid')]))
        .finally(() => {
          setLoading(false);

          if (requiresSecondFactor === REQUIRED) {
            onFinish();
          }
        });
    },
    [
      verifyOTP,
      requiresSecondFactor,
      secondFactorAuthToken,
      updateUserCache,
      onFinish,
    ],
  );

  const handleGenerateBackupCodes = useCallback(async () => {
    setLoading(true);

    return generateOTPBackupCodes()
      .then((data) => {
        if (data.codes) {
          downloadCsvStringAsFile(
            data.codes.join('\n'),
            `backupcodes-${projectName}-${DateTime.now().toFormat(YMD_TIME_FORMAT)}`,
            isWindows,
            'txt',
          );
        }
      })
      .catch(() => setErrors([getText(LANG_KEY, 'error.generateBackupCodes')]))
      .finally(() => {
        onFinish();
        setLoading(false);
      });
  }, [generateOTPBackupCodes, projectName, isWindows, onFinish]);

  const handleVerifyOTPBackupCode = useCallback(
    async (backupCode: string) => {
      setLoading(true);

      return verifyOTPBackupCode(backupCode, secondFactorAuthToken)
        .then((user) => {
          if (user) {
            updateUserCache(user);
            onFinish();
          }
        })
        .catch(() =>
          setErrors([getText('auth.twoFactorAuth.errors.backupCodeInvalid')]),
        )
        .finally(() => setLoading(false));
    },
    [verifyOTPBackupCode, updateUserCache, secondFactorAuthToken, onFinish],
  );

  const handleRemoveTwoFactorAuth = useCallback(
    async (token: string) => {
      try {
        let response;

        if (removeTwoFactorAuth) {
          response = await removeTwoFactorAuth({ token });
        }

        if (response?.success) {
          infoAlert(getText(LANG_KEY, 'remove.success'));
          setOtpValue('');
          setSetupStep(AuthSteps.GENERATE);

          if (onRemove) {
            onRemove();
          }
        }
      } catch (error) {
        errorAlert(getText(LANG_KEY, 'remove.error'));
        console.error('Error removing 2FA:', error);
      }
    },
    [removeTwoFactorAuth, infoAlert, errorAlert, onRemove],
  );

  const { hasKeyExpired, setKeyWithExpiry } = useExpiringLocalStorage(
    TWO_FACTOR_AUTH_PROMPT_DISMISSED,
    true,
  );

  const handleDismissTwoFactorAuthSetup = useCallback(() => {
    setKeyWithExpiry(14);

    onFinish();
  }, [setKeyWithExpiry, onFinish]);

  return {
    setupStep,
    setSetupStep,
    otpAuthUrl,
    base32,
    otpValue,
    setOtpValue,
    loading,
    errors,
    statusData,
    statusQueryLoading,
    isDismissSetupExpired: hasKeyExpired,
    setErrors,
    handleGenerate,
    handleVerify,
    handleGenerateBackupCodes,
    handleVerifyOTPBackupCode,
    handleRemoveTwoFactorAuth,
    handleDismissTwoFactorAuthSetup,
  };
};
