import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import { Loader, TextInput } from '@noloco/components';
import { darkModeColors } from '../constants/darkModeColors';
import DataTypes from '../models/DataTypes';
import { setGhostUserId } from '../reducers/data';
import {
  CHANGE_USER_POPOVER_OPENED,
  CHANGE_USER_SELECTED,
  trackEvent,
} from '../utils/analytics';
import useDarkMode from '../utils/hooks/useDarkMode';
import useFetchUsers from '../utils/hooks/useFetchUsers';
import useLocalStorageState from '../utils/hooks/useLocalStorageState';
import { getText } from '../utils/lang';
import ChangeUserRow from './ChangeUserRow';

interface ChangeUserPopoverBodyProps {
  dataTypes: DataTypes;
  projectName: string;
}

const ADDITIONAL_USER_FIELDS = {
  deactivatedAt: true,
  company: {
    id: true,
    name: true,
    website: true,
  },
  role: {
    id: true,
    name: true,
    internal: true,
  },
};

const FAVOURITE_USERS_KEY = 'noloco.users.favourites';

const ChangeUserPopoverBody = ({
  dataTypes,
  projectName,
}: ChangeUserPopoverBodyProps) => {
  const dispatch = useDispatch();
  const [isDarkModeEnabled] = useDarkMode();

  const handleSelectUser = useCallback(
    (userId: any) => {
      dispatch(setGhostUserId(userId));
      trackEvent(CHANGE_USER_SELECTED);
      setFilter('');
    },
    [dispatch],
  );

  useEffect(() => {
    trackEvent(CHANGE_USER_POPOVER_OPENED);
  }, []);

  const [favouriteIds, setFavouriteUserIds] = useLocalStorageState(
    FAVOURITE_USERS_KEY,
    [],
  );

  const onToggleFavourite = useCallback(
    (userId: any) => {
      if (favouriteIds.includes(userId)) {
        setFavouriteUserIds(favouriteIds.filter((id: any) => id !== userId));
      } else {
        setFavouriteUserIds([...favouriteIds, userId]);
      }
    },
    [favouriteIds, setFavouriteUserIds],
  );

  const [filter, setFilter] = useState('');
  const debouncedSetFilter = useMemo(
    () => debounce(setFilter, 500),
    [setFilter],
  );

  const { users: favourites, loading: favouritesLoading } = useFetchUsers(
    dataTypes,
    projectName,
    undefined,
    ADDITIONAL_USER_FIELDS,
    {
      context: { authQuery: true },
      skip: favouriteIds.length === 0,
      variables: { where: { id: { in: favouriteIds } } },
    },
  );

  const { loading, users, loaderRef, pageInfo } = useFetchUsers(
    dataTypes,
    projectName,
    filter,
    ADDITIONAL_USER_FIELDS,
    {
      context: { authQuery: true },
      variables: {
        where: {
          id: {
            notIn: favouriteIds,
          },
        },
      },
    },
  );

  return (
    <div
      className={classNames(
        'relative flex h-96 w-screen max-w-xl flex-col overflow-y-auto',
        {
          [`${darkModeColors.surfaces.elevation2} ${darkModeColors.borders.three} ${darkModeColors.text.primary}`]:
            isDarkModeEnabled,
          'bg-white': !isDarkModeEnabled,
        },
      )}
    >
      <div
        className={classNames('sticky top-0 z-10 flex flex-col border-b p-4', {
          [`${darkModeColors.surfaces.elevation2} ${darkModeColors.borders.three} ${darkModeColors.text.primary}`]:
            isDarkModeEnabled,
          'bg-white': !isDarkModeEnabled,
        })}
      >
        <div className="flex items-center">
          <h2 className="whitespace-nowrap text-base">
            {getText('leftSidebar.auth.change')}
          </h2>
          <TextInput
            autoFocus={true}
            className="ml-4 w-full"
            onChange={({ target: { value } }: any) => debouncedSetFilter(value)}
            value={filter}
            placeholder={getText('leftSidebar.auth.filter')}
          />
        </div>
      </div>
      {!favouritesLoading && favourites.length > 0 && !filter && (
        <>
          <div
            className={classNames(
              'flex w-full flex-col divide-y p-4 text-base',
              {
                [`${darkModeColors.divides.one} ${darkModeColors.text.primary}`]:
                  isDarkModeEnabled,
                'divide-gray-100': !isDarkModeEnabled,
              },
            )}
          >
            {favourites.map((user: any) => (
              <ChangeUserRow
                isFavourite={true}
                onSelect={() => {
                  handleSelectUser(user.id);
                }}
                onToggleFavourite={onToggleFavourite}
                user={user}
                key={user.id}
              />
            ))}
          </div>
          <hr />
        </>
      )}
      {!loading && (
        <div
          className={classNames('flex w-full flex-col divide-y p-4 text-base', {
            [`${darkModeColors.divides.one} ${darkModeColors.text.primary}`]:
              isDarkModeEnabled,
            'divide-gray-100': !isDarkModeEnabled,
          })}
        >
          {users.map((user: any) => (
            <ChangeUserRow
              isFavourite={false}
              onSelect={() => {
                handleSelectUser(user.id);
              }}
              onToggleFavourite={onToggleFavourite}
              user={user}
              key={user.id}
            />
          ))}
          {pageInfo && pageInfo.hasNextPage && (
            <div className="flex w-full justify-center py-4" ref={loaderRef}>
              <Loader size="sm" />
            </div>
          )}
        </div>
      )}
      {loading && (
        <div className="flex w-full items-center justify-center pb-4 pt-8">
          <Loader size="sm" />
        </div>
      )}
    </div>
  );
};

export default ChangeUserPopoverBody;
