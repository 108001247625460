import React, { useCallback } from 'react';
import classNames from 'classnames';
import MessageInput from '../../../components/MessageInput';
import { DataType } from '../../../models/DataTypes';
import { Action } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import useRecordCommentInput from '../../../utils/hooks/useRecordCommentInput';
import { getText } from '../../../utils/lang';

interface AddCommentActionProps {
  action: Action;
  dataType: DataType;
  record: BaseRecord;
  project: Project;
  setIsLoading: (loading: boolean) => void;
  onNext: (actionResult?: any) => void;
}

const AddCommentAction = ({
  action,
  dataType,
  record,
  project,
  onNext,
  setIsLoading,
}: AddCommentActionProps) => {
  const { note } = action?.addComment ?? {};

  const {
    sending,
    filesToSend,
    setFilesToSend,
    commentText,
    onChange,
    onSend,
  } = useRecordCommentInput({ dataType, record, project, note });

  const handleSubmit = useCallback(
    async (event: any) => {
      setIsLoading(true);
      await onSend(event);
      setIsLoading(false);
      onNext();
    },
    [onNext, onSend, setIsLoading],
  );

  return (
    <div
      className={classNames('flex w-full flex-col', `action-${action.id}-form`)}
    >
      <form
        className={classNames(
          'my-4 flex justify-end rounded-lg dark:border-gray-700 dark:text-gray-200',
          {
            'bg-pink-100 dark:bg-pink-900': note,
            'border bg-white dark:bg-gray-900': !note,
          },
        )}
        onSubmit={handleSubmit}
      >
        <MessageInput
          allowAttachments={true}
          dataTypes={project.dataTypes}
          files={filesToSend}
          isLoading={sending}
          mentions={true}
          onChange={onChange}
          onChangeFiles={setFilesToSend}
          onSubmit={onSend}
          placeholder={getText(
            'core.COMMENT.new',
            note ? 'placeholderNote' : 'placeholderReply',
          )}
          projectName={project.name}
          showSubmit={false}
          shouldFocus={true}
          value={commentText}
        />
      </form>
    </div>
  );
};

export default AddCommentAction;
