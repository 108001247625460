import { useCallback } from 'react';

export const MAX_FORM_SUBMISSION_ATTEMPTS = 10;

const useSubmitModalForm = (querySelector: string) => {
  const submitModalForm = useCallback(
    (attempt = 0) => {
      const form = document.querySelector(`${querySelector} form`);

      if (form) {
        form.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true }),
        );
      } else if (attempt < MAX_FORM_SUBMISSION_ATTEMPTS) {
        setTimeout(
          () => {
            submitModalForm(attempt + 1);
          },
          150 * (attempt + 1),
        );
      }
    },
    [querySelector],
  );

  return {
    submitModalForm,
  };
};

export default useSubmitModalForm;
