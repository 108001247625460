import React, { memo } from 'react';
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconClock,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

const ICON_SIZE = 40;

export const StatusIcon = ({
  error,
  loading,
  pending,
  surface = DARK,
}: any) => {
  if (error) {
    return <IconAlertTriangle className="text-yellow-400" size={ICON_SIZE} />;
  }

  if (loading) {
    return (
      <Loader className={surface === LIGHT ? 'text-gray-700' : 'text-white'} />
    );
  }

  if (pending) {
    return (
      <IconClock
        className={surface === LIGHT ? 'text-gray-700' : 'text-white'}
        size={ICON_SIZE}
      />
    );
  }

  return <IconCircleCheck className="text-cyan-500" size={ICON_SIZE} />;
};

const DataSourceImportStatusSection = memo(
  ({ loading, pending, error, title, subtitle, surface = DARK }: any) => (
    <div
      className={classNames('flex w-full items-center rounded-lg border p-3', {
        'border-brand-light text-white': surface !== LIGHT,
        'border-gray-200 bg-white text-gray-700': surface === LIGHT,
      })}
    >
      <div className="flex w-16 flex-shrink-0 justify-center">
        <StatusIcon
          error={error}
          loading={loading}
          pending={pending}
          surface={surface}
        />
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-lg font-medium tracking-wider">{title}</span>
        <span className="font-gray-200 mt-1 text-sm">{subtitle}</span>
      </div>
    </div>
  ),
);

export default DataSourceImportStatusSection;
