import React from 'react';
import { InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { gitCommitHash } from '@noloco/core/src/gitCommit';
import { getDataTypesWithRelations } from '@noloco/core/src/utils/data';
import App from './components/App';
import './configureThirdPartyScripts';
import { IS_NOLOCO_DOMAIN } from './constants/env';
import getApolloClient, { getAuthLink } from './utils/apolloClient';
import getStore from './utils/store';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://aab3f9f3ee8447be8c4fc550f59632a5@o450569.ingest.sentry.io/5435147',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.01,
    release: gitCommitHash,
  });
}

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

if (window.__PROJECT__) {
  const PROJECT = window.__PROJECT__;
  PROJECT.dataTypes = getDataTypesWithRelations(PROJECT.dataTypes);

  const preloadedState = window.__REDUX_STATE__;
  preloadedState.project.data.dataTypes = PROJECT.dataTypes;

  const client = getApolloClient(
    PROJECT.name,
    cache,
    getAuthLink(PROJECT.name),
    false,
  );

  const store = getStore(preloadedState);

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <App
            client={client}
            project={PROJECT}
            isCustomDomain={!IS_NOLOCO_DOMAIN}
          />
        </Provider>
      </BrowserRouter>
    </HelmetProvider>,
  );
}
