import flagsmith from 'flagsmith';

export const LEGACY_IS_INTERNAL_FLAG: FeatureFlag = 'legacy-is-internal';

export const LEGACY_UI_MODULES: FeatureFlag = 'legacy-ui-modules';

export const COMPANY_COLLECTION_ENABLED: FeatureFlag =
  'company-collection-enabled';

export const PWA: FeatureFlag = 'pwa';

export const CORE_MAINTENANCE_MODE: FeatureFlag = 'core-maintenance-mode';

export const DISABLE_DATA_SOURCE_FIELDS: FeatureFlag =
  'disable-data-source-fields';

export const ADD_CUSTOM_COMPONENT: FeatureFlag = 'add-title-custom-component';

export const EMBED_COMPONENT_ENABLED: FeatureFlag = 'embed-component-enabled';

export const AI_GENERATION_FIELDS: FeatureFlag = 'ai-generation-fields';

export const STACKED_BAR_CHART: FeatureFlag = 'stacked-bar-chart';

export const HUBSPOT_INTEGRATION: FeatureFlag = 'hubspot-integration';

export const DYNAMIC_MULTI_SAML = 'dynamic-multi-saml';

export const TWO_FACTOR_AUTHENTICATION = 'two-factor-authentication';

export const AI_FORMULA_SUGGESTION: FeatureFlag = 'ai-formula-suggestion';

export const SPACES_FLAG: FeatureFlag = 'spaces';

export const AI_TABLE: FeatureFlag = 'ai-table';

export const CARD_SIZES: FeatureFlag = 'card-sizes';

export const CHECKLIST_FLAG: FeatureFlag = 'checklist';

export const RECORD_STYLES: FeatureFlag = 'record-styles';

export const CARD_STYLES: FeatureFlag = 'card-styles';

export type FeatureFlag =
  | 'ai-formula-suggestion'
  | 'ai-generation-fields'
  | 'ai-table'
  | 'ai-table'
  | 'ai-table'
  | 'ai-table'
  | 'card-sizes'
  | 'card-sizes'
  | 'card-styles'
  | 'checklist'
  | 'company-collection-enabled'
  | 'core-maintenance-mode'
  | 'disable-data-source-fields'
  | 'dynamic-multi-saml'
  | 'add-title-custom-component'
  | 'embed-component-enabled'
  | 'hubspot-integration'
  | 'legacy-is-internal'
  | 'legacy-ui-modules'
  | 'pivot_table'
  | 'pwa'
  | 'record-styles'
  | 'spaces'
  | 'stacked-bar-chart'
  | 'two-factor-authentication';

const useHasFeatureFlag = (feature: FeatureFlag) =>
  flagsmith.hasFeature(feature);

export default useHasFeatureFlag;
