import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import shortId from 'shortid';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import { CHART, COLLECTION } from '../../constants/elements';
import { Element, ElementPath, ViewTab } from '../../models/Element';
import { Project } from '../../models/Project';
import {
  setSelectedElement,
  setSelectedSectionPath,
} from '../../reducers/elements';
import { getDefaultPropsForSectionType } from '../sections';

const useAddSection = (
  project: Project,
  sections: Element[],
  elementPath: ElementPath,
  sectionPath: ElementPath,
  selectedTab: ViewTab | null = null,
  container?: string,
) => {
  const dispatch = useDispatch();
  const [updateProperty] = useUpdateProperty(elementPath, project);

  const addSection = useCallback(
    (elementType: any, props: any): Element => {
      const section = {
        id: shortId.generate(),
        props: getDefaultPropsForSectionType(elementType, props),
        tab: selectedTab ? selectedTab.id : null,
        type: elementType === CHART ? COLLECTION : elementType,
        ...(container ? { container } : {}),
      };

      updateProperty(sectionPath, [...sections, section]);
      dispatch(setSelectedElement(elementPath));
      dispatch(setSelectedSectionPath([sections.length]));

      return section;
    },
    [
      container,
      dispatch,
      elementPath,
      sectionPath,
      sections,
      selectedTab,
      updateProperty,
    ],
  );

  return [addSection];
};

export default useAddSection;
