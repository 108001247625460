import React, { useEffect } from 'react';
import { OUTLINE } from '@noloco/components/src/components/button/buttonTypes';
import ErrorText from '@noloco/components/src/components/form/ErrorText';
import { getText } from '../utils/lang';
import Button from './Button';
import { AuthUrl } from './sections/twoFactorAuthentication/AuthUrl';
import { GenerateOTPBackupCodes } from './sections/twoFactorAuthentication/GenerateBackupCodes';
import { OTPInput } from './sections/twoFactorAuthentication/OTPInput';

enum AuthSteps {
  GENERATE = 'generate',
  VERIFY = 'verify',
  GENERATE_BACKUP_CODES = 'generateBackupCodes',
}

interface TwoFactorAuthSetupProps {
  secondFactorAuthToken?: string;
  onFinish: () => void;
  includeButtons?: boolean;
  otpAuthUrl?: string;
  step: AuthSteps;
  handleGenerate: () => void;
  errors: string[];
  handleVerify: (otp: string) => void;
  handleGenerateBackupCodes: () => void;
  base32: string | undefined;
  otpValue: string;
  setOtpValue: (otpValue: string) => void;
  loading: boolean;
  setStep: (step: AuthSteps) => void;
  onSkip?: () => void;
  canSkip?: boolean;
}

export const TwoFactorAuthSetup = ({
  includeButtons = true,
  otpAuthUrl,
  step,
  handleGenerate,
  errors,
  handleVerify,
  handleGenerateBackupCodes,
  base32,
  otpValue,
  setOtpValue,
  loading,
  setStep,
  canSkip,
  onSkip,
}: TwoFactorAuthSetupProps) => {
  useEffect(() => {
    if (!otpAuthUrl && step === AuthSteps.GENERATE && errors.length === 0) {
      handleGenerate();
    }
  }, [step, handleGenerate, otpAuthUrl, errors]);

  return (
    <div className="space-y-3 text-center">
      {errors.length > 0 && (
        <ErrorText className="mx-auto max-w-xs break-words">
          {errors.map((error: any, idx: any) => (
            <div key={idx}>{error}</div>
          ))}
        </ErrorText>
      )}
      {step === AuthSteps.GENERATE && otpAuthUrl && (
        <div className="flex flex-col justify-center space-y-4">
          <AuthUrl
            otpAuthUrl={otpAuthUrl}
            base32={base32}
            onNext={() => setStep(AuthSteps.VERIFY)}
            includeButtons={includeButtons}
          />
          {canSkip && (
            <Button type={OUTLINE} onClick={onSkip} className="text-blue-500">
              {getText('auth.twoFactorAuth.setup.skip')}
            </Button>
          )}
        </div>
      )}
      {step === AuthSteps.VERIFY && (
        <OTPInput
          includeButtons={includeButtons}
          handleVerify={handleVerify}
          loading={loading}
          otpValue={otpValue}
          setOtpValue={setOtpValue}
        />
      )}
      {step === AuthSteps.GENERATE_BACKUP_CODES && (
        <GenerateOTPBackupCodes
          generateBackupCodes={handleGenerateBackupCodes}
          buttonText={getText('auth.twoFactorAuth.backupCodes.download')}
          includeButtons={includeButtons}
        />
      )}
    </div>
  );
};
