import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEditorMode,
  setSelectedElement,
  setSelectedSectionPath,
} from '@noloco/core/src/reducers/elements';
import { setProject } from '@noloco/core/src/reducers/project';
import {
  draftDataTypesSelector,
  projectDataSelector,
  projectNameSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  useErrorAlert,
  useGraphQlErrorAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRecordPreview from '@noloco/core/src/utils/hooks/useRecordPreview';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { PROJECT_DOCUMENT_QUERY } from '../../queries/project';
import { useSwapDraftDataTypes } from './useUpdateDataTypes';

const useToggleEditorMode = (editorMode: boolean) => {
  const { pathname, push } = useRouter();
  const projectName = useSelector(projectNameSelector);
  const dispatch = useDispatch();
  const swapDraftDataTypes = useSwapDraftDataTypes();
  const baseProject = useSelector(projectDataSelector);
  const draftDataTypes = useSelector(draftDataTypesSelector);
  const gqlErrorAlert = useGraphQlErrorAlert();
  const errorAlert = useErrorAlert();
  const [preview] = useRecordPreview();
  const [fetchProject] = usePromiseQuery(PROJECT_DOCUMENT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: projectName,
    },
  });
  const [isFetching, setIsFetching] = useState(false);

  const toggleEditorMode = useCallback(() => {
    if (editorMode) {
      dispatch(setSelectedSectionPath([]));
      dispatch(setSelectedElement([]));
      dispatch(setEditorMode(false));
    } else {
      setIsFetching(true);
      fetchProject()
        .then(({ data: projectData }) => {
          if (projectData && projectData.project) {
            const project = {
              ...baseProject,
              elements: projectData.project.elements,
              workflows: projectData.project.workflows,
              settings: projectData.project.settings,
            };
            dispatch(setProject(project));
            dispatch(setEditorMode(true));

            if (preview) {
              push(pathname.replace('/preview/', '/view/'));
            }
          } else {
            errorAlert(getText('errors.fetchProject'));
          }

          if (draftDataTypes) {
            swapDraftDataTypes();
          }
        })
        .catch((e: any) => {
          gqlErrorAlert(getText('errors.fetchProject'), e);
        })
        .finally(() => setIsFetching(false));
    }
  }, [
    baseProject,
    dispatch,
    draftDataTypes,
    editorMode,
    errorAlert,
    fetchProject,
    gqlErrorAlert,
    pathname,
    preview,
    push,
    swapDraftDataTypes,
  ]);

  return { isLoading: isFetching, toggleEditorMode };
};

export default useToggleEditorMode;
