import { useCallback, useEffect } from 'react';

const useShowInstallPrompt = (
  handleShowInstallPrompt: (showInstallPrompt: boolean) => void,
) => {
  const onBeforeInstallPrompt = useCallback(
    (event: any) => handleShowInstallPrompt(event),
    [handleShowInstallPrompt],
  );

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', onBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', onBeforeInstallPrompt);
    };
  });
};

export default useShowInstallPrompt;
