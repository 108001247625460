import React from 'react';
import classNames from 'classnames';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import Section from '../view/Section';

const AutoFormCustomComponent = ({
  config,
  project,
  elementPath,
  sectionPath,
  recordScope,
  ...rest
}: any) => {
  const resolvedProps = useSectionScopeVariables(
    config.type,
    config.props,
    project,
    [0],
    recordScope,
  );

  return (
    <div
      className={classNames('col-span-12 my-2', {
        'col-span-12': !config.width || config.className === 12,
        'col-span-8 md:col-span-12': config.width === 8,
        'col-span-6 md:col-span-12': config.width === 6,
        'col-span-4 md:col-span-12': config.width === 4,
      })}
    >
      <Section
        section={config}
        project={project}
        sectionPath={sectionPath}
        elementPath={elementPath}
        recordScope={recordScope}
        {...resolvedProps}
        {...rest}
      />
    </div>
  );
};

AutoFormCustomComponent.defaultProps = {};

export default AutoFormCustomComponent;
