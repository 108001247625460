import { useCallback, useMemo } from 'react';
import {
  IconCopy,
  IconCornerRightDown,
  IconDotsVertical,
  IconEyeCheck,
  IconLink,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { OPTIONS, VISIBILITY } from '../../constants/buildMode';
import { SPLIT } from '../../constants/collectionLayouts';
import { DIVIDER, FOLDER, LINK, VIEW } from '../../constants/elements';
import { ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import { setEditingPage } from '../../reducers/elements';
import { editingPageSelector } from '../../selectors/elementsSelectors';
import useEditorTabs from '../../utils/hooks/useEditorTabs';
import { getText } from '../../utils/lang';
import { Page } from '../../utils/pages';
import BuildModeNavigation from './BuildModeNavigation';
import BuildModeSidebarItemEditor from './sidebar/BuildModeSidebarItemEditor';

interface BuildModeOptionsMenuProps {
  debouncedUpdateProperty?: UpdatePropertyCallback;
  elementPath?: ElementPath;
  handleClone: (page?: Page) => void;
  handleRemove: () => void;
  hidden: boolean;
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  page?: Page;
  project?: Project;
  updateProperty?: UpdatePropertyCallback;
}

const LANG_KEY = 'elements.PAGE';

const BuildModeOptionsMenu = ({
  debouncedUpdateProperty,
  elementPath,
  handleClone,
  handleRemove,
  hidden,
  isOpen,
  onOpenChange,
  page,
  project,
  updateProperty,
}: BuildModeOptionsMenuProps) => {
  const { parentPage } = get(page, 'props') ?? {};
  const dispatch = useDispatch();
  const isSplitCollectionLayout = get(page, 'props.layout') === SPLIT;
  const [editorTab, setEditorTab] = useEditorTabs('sidebar.editor', OPTIONS);
  const onClonePageAsSubPage = useCallback(() => {
    if (page) {
      handleClone(set('props.parentPage', page.id, page));
    }
  }, [handleClone, page]);
  const showVisibilitySettings = useMemo(
    () =>
      page &&
      (page.type === FOLDER || page.type === DIVIDER || page.type === LINK),
    [page],
  );
  const showLinkSettings = useMemo(() => page && page.type === LINK, [page]);
  const editingPage = useSelector(editingPageSelector);
  const onSetEditingPage = useCallback(
    (page: Page) => dispatch(setEditingPage(page.id)),
    [dispatch],
  );
  const handleSettingsClick = useCallback(
    (tab: any) => {
      if (page) {
        onSetEditingPage(page);
        setEditorTab(tab);
      }
    },
    [page, onSetEditingPage, setEditorTab],
  );

  if (
    showVisibilitySettings &&
    editingPage &&
    page &&
    page.id === editingPage
  ) {
    return (
      <BuildModeSidebarItemEditor
        debouncedUpdateProperty={debouncedUpdateProperty!}
        editorTab={editorTab}
        elementPath={elementPath!}
        page={page}
        project={project!}
        setEditorTab={setEditorTab}
        showLinkSettings={!!showLinkSettings}
        updateProperty={updateProperty!}
      />
    );
  }

  return (
    <Popover
      content={
        <div
          className={classNames(
            'select-none space-y-2 text-sm text-slate-200',
            { 'w-64': page?.type === LINK || page?.type === FOLDER },
          )}
        >
          {showLinkSettings && (
            <button
              className="flex cursor-pointer items-center space-x-2 opacity-75 hover:opacity-100"
              onClick={() => handleSettingsClick(OPTIONS)}
            >
              <IconLink size={16} />
              <span>{getText(LANG_KEY, 'externalLink.editor')}</span>
            </button>
          )}
          {page && page.type === FOLDER && (
            <BuildModeNavigation
              element={page!}
              project={project!}
              updateProperty={updateProperty!}
            />
          )}
          {showVisibilitySettings && (
            <button
              className="flex cursor-pointer items-center space-x-2 opacity-75 hover:opacity-100"
              onClick={() => handleSettingsClick(VISIBILITY)}
            >
              <IconEyeCheck size={16} />
              <span>{getText('rightSidebar.visibility.label')}</span>
            </button>
          )}
          <button
            className="flex cursor-pointer items-center space-x-2 opacity-75 hover:opacity-100"
            onClick={() => handleClone()}
          >
            <IconCopy size={16} />
            <span>{getText(LANG_KEY, 'clone')}</span>
          </button>
          {page?.type === VIEW && !parentPage && !isSplitCollectionLayout && (
            <button
              className="flex cursor-pointer items-center space-x-2 opacity-75 hover:opacity-100"
              onClick={onClonePageAsSubPage}
            >
              <IconCornerRightDown size={16} />
              <span>{getText(LANG_KEY, 'cloneSub')}</span>
            </button>
          )}
          <button
            className="flex cursor-pointer items-center space-x-2 opacity-75 hover:opacity-100"
            onClick={handleRemove}
          >
            <IconTrash className="text-red-400" size={16} />
            <span>{getText(LANG_KEY, 'remove')}</span>
          </button>
        </div>
      }
      isOpen={isOpen}
      offset={[0, 16]}
      onOpenChange={onOpenChange}
      placement="right"
      surface={DARK}
    >
      <div
        className={classNames(
          'ml-1 cursor-pointer items-center rounded-md bg-slate-700 p-1 text-slate-400 hover:text-slate-200 group-hover:flex',
          { hidden },
        )}
      >
        <IconDotsVertical size={16} />
      </div>
    </Popover>
  );
};

export default BuildModeOptionsMenu;
