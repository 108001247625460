import React, { useEffect, useMemo } from 'react';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import {
  getUserIdentifier,
  useAuth,
} from '@noloco/core/src/utils/hooks/useAuth';
import { getFullName } from '@noloco/core/src/utils/user';
import { IS_PRODUCTION } from '../constants/env';

const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID ?? '13CSWB';

const FullstoryRecording = ({ projectName }: { projectName?: string }) => {
  const { user } = useAuth();

  const attributes = useMemo(() => {
    if (user) {
      const attribs = {
        email: user.email,
        createdAt_date: user.createdAt ? new Date(user.createdAt) : undefined,
        displayName: getFullName(user),
        project: projectName,
      };

      return attribs;
    }
  }, [projectName, user]);

  useEffect(() => {
    if (attributes) {
      // @ts-expect-error Full story accepts date values, but their typescript doesn't
      FullStoryAPI('identify', getUserIdentifier(user), attributes);
    }
  }, [attributes, user]);

  if (!IS_PRODUCTION || user?.email.includes('@noloco.io')) {
    return null;
  }

  return <FullStory org={FULLSTORY_ORG_ID} />;
};

export default FullstoryRecording;
