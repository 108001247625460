import React, { forwardRef, memo } from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { Project } from '../../models/Project';
import {
  shouldCheckVisibilityRules,
  shouldRenderComponent,
} from '../../utils/elementVisibility';
import { CurrentSpaceContextValue } from '../../utils/hooks/useCurrentSpace';
import useMergedScope from '../../utils/hooks/useMergedScope';
import useScopeUser from '../../utils/hooks/useScopeUser';

interface WithVisibilityRulesProps {
  className: string;
  project: Project;
  visibilityRulesScope: Record<string, any>;
}

const withVisibilityRules = (
  WrappedComponent: any,
  editorMode: any,
  visibilityRules: any,
  checkSpace = false,
  currentSpace: CurrentSpaceContextValue | null = null,
) => {
  if (
    checkSpace &&
    get(currentSpace, 'space.hideNonSpacePages', false) &&
    (!visibilityRules ||
      (visibilityRules && get(visibilityRules, 'spaces', []).length === 0))
  ) {
    return null;
  }

  if (
    !visibilityRules ||
    !shouldCheckVisibilityRules(visibilityRules, checkSpace)
  ) {
    return WrappedComponent;
  }

  const WithVisibilityRules = memo(
    forwardRef(
      (
        {
          className,
          project,
          visibilityRulesScope = {},
          ...rest
        }: WithVisibilityRulesProps,
        ref: React.Ref<HTMLElement>,
      ) => {
        if (!project) {
          throw new Error(
            `withVisibilityRules: project prop not set on ${
              WrappedComponent.displayName || 'component'
            }`,
          );
        }

        const currentUser = useScopeUser();
        const { sm: isSmScreen } = useBreakpoints();
        const scope = useMergedScope(visibilityRulesScope);
        const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
        const shouldRender = shouldRenderComponent({
          checkSpace,
          currentSpaceId: currentSpace?.id,
          currentUser,
          customRulesEnabled,
          isSmScreen,
          project,
          scope,
          visibilityRules,
        });

        if (!editorMode && !shouldRender) {
          return null;
        }

        return (
          <WrappedComponent
            {...rest}
            className={classNames(className, { 'opacity-50': !shouldRender })}
            project={project}
            ref={ref}
            scope={scope}
          />
        );
      },
    ),
  );

  WithVisibilityRules.displayName = 'WithVisibilityRules';

  return WithVisibilityRules;
};

export default withVisibilityRules;
