import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { Switch } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import Guide from '@noloco/ui/src/components/Guide';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import PopoverVisibilityRulesEditor from '@noloco/ui/src/components/editor/customerEditors/PopoverVisibilityRulesEditor';
import {
  UpdatePropertyCallback,
  useOnCloneTab,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { RECORD } from '../../../constants/actionButtons';
import {
  RECORD_COMMENTS,
  RECORD_EDIT_BUTTON,
} from '../../../constants/buildMode';
import { SINGLE_RECORD } from '../../../constants/collectionLayouts';
import { RECORD as RECORD_ELEMENT } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import { ElementPath, Section } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeHeader from '../BuildModeHeader';
import BuildModeLabel from '../BuildModeLabel';
import BuildModeNavigation from '../BuildModeNavigation';
import BuildModeSection from '../BuildModeSection';
import BuildModeSwitchSection from '../BuildModeSwitchSection';
import BuildModeTabEditor from '../BuildModeTabEditor';
import BuildModeActionButtonsEditor from '../actionButton/BuildModeActionButtonsEditor';
import BuildModeViewDataSettings from '../view/BuildModeViewDataSettings';
import BuildModeViewFilterSettings from '../view/BuildModeViewFilterSettings';
import BuildModeViewLayoutEditor from '../view/BuildModeViewLayoutEditor';
import BuildModeViewSortAndLimitSettings from '../view/BuildModeViewSortAndLimitSettings';

interface BuildModeRecordOptionsTabProps {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  updateProperty: UpdatePropertyCallback;
  updateViewProperty: UpdatePropertyCallback;
}

const COMMENTS_LANG_KEY = 'elements.VIEW.comments';
const EDIT_BUTTON_LANG_KEY = 'elements.VIEW.buttons.editButton';

const BuildModeRecordOptionsTab = ({
  dataType,
  debouncedUpdateProperty,
  element,
  elementPath,
  project,
  updateProperty,
  updateViewProperty,
}: BuildModeRecordOptionsTabProps) => {
  const {
    actionButtons = [],
    comments = {},
    sections = [],
    doneButtonText,
    editButton = {},
    editButtonText = null,
    hideEditButton = null,
    tabs = [],
  } = get(element, 'props.record', {});

  const onVisibilityRulesChange = useCallback(
    (path: ElementPath, value: any) =>
      updateProperty(['editButton', 'visibilityRules', ...path], value),
    [updateProperty],
  );

  const recordViewElementPath = useMemo(
    () => [...elementPath, 'props', 'record'],
    [elementPath],
  );

  const singleRecordLayout = useMemo(
    () => get(element, 'props.layout') === SINGLE_RECORD,
    [element],
  );

  const updateSections = useCallback(
    (newSections: Section[]) => updateProperty(['sections'], newSections),
    [updateProperty],
  );
  const cloneTabElements = useOnCloneTab(sections, updateSections);

  return (
    <>
      {singleRecordLayout && dataType && (
        <>
          <BuildModeViewDataSettings
            element={element}
            project={project}
            updateProperty={updateViewProperty}
          />
          <BuildModeViewLayoutEditor
            dataType={dataType}
            element={element}
            project={project}
            updateProperty={updateViewProperty}
          />
          <BuildModeViewFilterSettings
            dataType={dataType}
            element={element}
            elementPath={elementPath}
            project={project}
            updateProperty={updateViewProperty}
          />
          <BuildModeViewSortAndLimitSettings
            dataType={dataType}
            element={element}
            updateProperty={updateViewProperty}
          />
        </>
      )}
      <BuildModeHeader
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={recordViewElementPath}
        elementType={RECORD_ELEMENT}
        project={project}
        props={element.props.record}
        updateProperty={updateProperty}
      />
      <BuildModeSection
        className="border-t p-2"
        id={RECORD_EDIT_BUTTON}
        sticky={true}
        title={getText('elements.VIEW.edit.label')}
      >
        <div className="mb-2 p-2">
          <div className="mb-2 flex items-center justify-between">
            <BuildModeLabel>
              {getText(EDIT_BUTTON_LANG_KEY, 'show')}
            </BuildModeLabel>
            <div className="flex items-center justify-center space-x-2">
              <PopoverVisibilityRulesEditor
                alwaysHidden={!!hideEditButton}
                dataType={dataType}
                element={editButton}
                elementPath={[...elementPath, 'editButton']}
                onChange={onVisibilityRulesChange}
                placement="bottom"
                project={project}
              />
              <Switch
                size={SM}
                value={!hideEditButton}
                onChange={(value: boolean) =>
                  updateProperty(['hideEditButton'], !value)
                }
              />
            </div>
          </div>
          {!hideEditButton && (
            <div className="flex flex-col space-y-2">
              <BuildModeLabel>
                {getText(EDIT_BUTTON_LANG_KEY, 'editText')}
              </BuildModeLabel>
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ className: string; project: any; onChange:... Remove this comment to see the full error message
                className="w-full"
                elementPath={recordViewElementPath}
                onChange={(value: any) =>
                  updateProperty(['editButtonText'], value)
                }
                project={project}
                value={editButtonText}
              />
              <BuildModeLabel>
                {getText(EDIT_BUTTON_LANG_KEY, 'doneText')}
              </BuildModeLabel>
              <StringPropEditor
                // @ts-expect-error TS(2322): Type '{ className: string; project: any; onChange:... Remove this comment to see the full error message
                className="w-full"
                elementPath={recordViewElementPath}
                onChange={(value: any) =>
                  updateProperty(['doneButtonText'], value)
                }
                project={project}
                value={doneButtonText}
              />
            </div>
          )}
        </div>
      </BuildModeSection>
      <BuildModeSection
        className="border-t p-2"
        id={RECORD_COMMENTS}
        guide={
          <Guide href="https://guides.noloco.io/record-pages/record-comments">
            {getText(COMMENTS_LANG_KEY, 'guide')}
          </Guide>
        }
        sticky={true}
        title={getText(COMMENTS_LANG_KEY, 'label')}
      >
        <div className="mb-2 flex flex-col space-y-4 p-2 text-gray-400">
          <BuildModeSwitchSection
            label={getText(COMMENTS_LANG_KEY, 'show')}
            onChange={(value: boolean) =>
              updateProperty(['comments', 'show'], value)
            }
            value={comments.show}
          />
          {comments.show && (
            <>
              <BuildModeSwitchSection
                label={getText(COMMENTS_LANG_KEY, 'openByDefault')}
                onChange={(value: boolean) =>
                  updateProperty(['comments', 'openByDefault'], value)
                }
                value={comments.openByDefault}
              />
              <BuildModeSwitchSection
                label={getText(COMMENTS_LANG_KEY, 'allowAttachments')}
                onChange={(value: boolean) =>
                  updateProperty(['comments', 'allowAttachments'], value)
                }
                value={comments.allowAttachments ?? true}
              />
            </>
          )}
        </div>
      </BuildModeSection>
      <BuildModeTabEditor
        cloneTabElements={cloneTabElements}
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={recordViewElementPath}
        project={project}
        tabs={tabs}
        updateProperty={updateProperty}
      />
      <BuildModeActionButtonsEditor
        actionButtons={actionButtons}
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        display={RECORD}
        elementPath={recordViewElementPath}
        project={project}
        updateProperty={updateProperty}
      />
      {element.props.layout === SINGLE_RECORD && (
        <BuildModeNavigation
          element={element}
          project={project}
          updateProperty={updateViewProperty}
        />
      )}
    </>
  );
};

export default BuildModeRecordOptionsTab;
