import { useSelector } from 'react-redux';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../../constants/features';
import { ProjectSpace } from '../../models/Project';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { shouldRenderComponent } from '../elementVisibility';
import { getSpacesArray } from '../spaces';
import { SPACES_FLAG } from './useHasFeatureFlag';
import useHasFeatureFlag from './useHasFeatureFlag';
import useMergedScope from './useMergedScope';
import useScopeUser from './useScopeUser';

const useSpaces = ({
  bypassVisibilityRules = false,
}: {
  bypassVisibilityRules?: boolean;
} = {}): {
  id: string;
  space: ProjectSpace;
}[] => {
  const currentUser = useScopeUser();
  const project = useSelector(projectDataSelector);
  const spacesEnabled = useHasFeatureFlag(SPACES_FLAG);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const scope = useMergedScope();
  const { sm: isSmScreen } = useBreakpoints();

  if (
    spacesEnabled &&
    project.spaces &&
    Object.keys(project.spaces).length > 0
  ) {
    return getSpacesArray(project.spaces).filter(
      ({ space: { visibilityRules } }) =>
        bypassVisibilityRules ||
        shouldRenderComponent({
          currentUser,
          customRulesEnabled,
          isSmScreen,
          project,
          scope,
          visibilityRules,
        }),
    );
  }

  return [];
};

export default useSpaces;
