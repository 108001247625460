import { IconBrowser, IconLayoutSidebarRight } from '@tabler/icons-react';
import { SwitchButton } from '@noloco/components/src';
import {
  LG,
  MD,
  ShirtSize,
} from '@noloco/components/src/constants/tShirtSizes';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import {
  ElementPath,
  ViewRecordStyle,
  ViewRecordStyleType,
} from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';

interface ViewRecordStyleEditorProps {
  onChange: (path: ElementPath, value: ViewRecordStyleType | ShirtSize) => void;
  value: ViewRecordStyle;
}

const recordStyleIconMap = {
  [ViewRecordStyleType.PAGE]: IconBrowser,
  [ViewRecordStyleType.SIDE_PANEL]: IconLayoutSidebarRight,
};

const ViewRecordStyleEditor = ({
  onChange,
  value = {
    size: MD,
    type: ViewRecordStyleType.PAGE,
  },
}: ViewRecordStyleEditorProps) => (
  <>
    <BuildModeInput
      label={getText('elements.VIEW.recordStyle.label')}
      inline={true}
    >
      <SwitchButton
        onChange={(value) => onChange(['type'], value as ViewRecordStyleType)}
        options={Object.values(ViewRecordStyleType).map((style) => ({
          Icon: recordStyleIconMap[style],
          label: getText('elements.VIEW.recordStyle', style),
          showTooltip: true,
          value: style,
        }))}
        value={value.type ?? ViewRecordStyleType.PAGE}
      />
    </BuildModeInput>
    {value.type === ViewRecordStyleType.SIDE_PANEL && (
      <BuildModeInput
        label={getText('elements.VIEW.recordStyle.size')}
        inline={true}
      >
        <SwitchButton
          onChange={(value) => onChange(['size'], value as ShirtSize)}
          options={[MD, LG].map((size) => ({
            label: getText('elements.VIEW.recordStyle.sizes', size),
            value: size,
          }))}
          value={value.size ?? MD}
        />
      </BuildModeInput>
    )}
  </>
);

export default ViewRecordStyleEditor;
