import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import get from 'lodash/get';
import { CopyButton, TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import FeatureLockedSwitch from '@noloco/ui/src/components/FeatureLockedSwitch';
import Guide from '@noloco/ui/src/components/Guide';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import {
  CREATE_PUBLIC_FORM,
  DELETE_PUBLIC_FORM,
} from '@noloco/ui/src/queries/project';
import {
  UPDATE_DEBOUNCE_MS,
  UpdatePropertyCallback,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  FORM_ON_SAVE_ACTIONS,
  FORM_SAVE_BUTTON,
  PUBLIC_FORM,
} from '../../../constants/buildMode';
import { darkModeColors } from '../../../constants/darkModeColors';
import { FORM } from '../../../constants/elements';
import { FORM_SAVE_ACTIONS } from '../../../constants/features';
import { PAGE } from '../../../constants/linkTypes';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getFullDataTypeStateOptions } from '../../../utils/actions';
import { useGraphQlErrorAlert } from '../../../utils/hooks/useAlerts';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import { buildPublicFormUrl } from '../../../utils/publicForms';
import { RECORD_SCOPE } from '../../../utils/scope';
import BuildModeHeader from '../BuildModeHeader';
import BuildModeInput from '../BuildModeInput';
import BuildModeLabel from '../BuildModeLabel';
import BuildModeLinkEditor from '../BuildModeLinkEditor';
import BuildModeSection from '../BuildModeSection';
import BuildModeSwitchSection from '../BuildModeSwitchSection';

interface BuildModeFormOptionsTabProps {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  updateProperty: UpdatePropertyCallback;
}

const LANG_KEY = 'elements.VIEW';

const BuildModeFormOptionsTab = ({
  debouncedUpdateProperty,
  dataType,
  element,
  elementPath,
  project,
  updateProperty,
}: BuildModeFormOptionsTabProps) => {
  const errorAlert = useGraphQlErrorAlert();
  const [createPublicForm] = useMutation(CREATE_PUBLIC_FORM);
  const [deletePublicForm] = useMutation(DELETE_PUBLIC_FORM);

  const [loading, setLoading] = useState(false);
  const elementPublicForms = get(element, ['props', 'new', 'publicForms']);
  const onSubmitMessage = get(elementPublicForms, [0, 'onSubmitMessage']);
  const hideNavBar = get(elementPublicForms, [0, 'hideNavBar']);
  const elementHasPublicForms =
    elementPublicForms && elementPublicForms.length !== 0;
  const defaultOnSubmitMessage = getText(LANG_KEY, 'defaultOnSubmitMessage');
  const { onSave, saveButtonText = null } = element.props.new;

  const updateOnSubmitMessage = useCallback(
    (submitMessage: string) => {
      updateProperty(['publicForms', 0, 'onSubmitMessage'], submitMessage);
    },
    [updateProperty],
  );

  const handleHideNavBar = useCallback(
    (showNavBar: boolean) =>
      updateProperty(['publicForms', 0, 'hideNavBar'], !showNavBar),
    [updateProperty],
  );

  const publicFormUrl = buildPublicFormUrl(
    element.id,
    get(elementPublicForms, [0, 'referenceId']),
  );

  const additionalScopeItems = useMemo(
    () => [
      {
        label: getText(
          { dataType: dataType.display },
          LANG_KEY,
          'buttons.onSave.scope.label',
        ),
        help: getText(LANG_KEY, 'buttons.onSave.scope.help'),
        options: getFullDataTypeStateOptions(
          RECORD_SCOPE,
          dataType,
          project.dataTypes,
          project,
        ),
      },
    ],
    [dataType, project],
  );

  const createNewPublicFrom = useCallback(() => {
    const formName = `${element.props.name}-public-form`;

    setLoading(true);
    createPublicForm({
      variables: {
        projectName: project.name,
        formName: formName,
        dataTypeId: dataType.id,
        elementId: element.id,
        onSubmitMessage: defaultOnSubmitMessage,
      },
    })
      .then(({ data }) => {
        const newPublicFormData = get(data, 'createPublicForm', undefined);

        if (newPublicFormData) {
          updateProperty(['publicForms', 0], {
            name: formName,
            referenceId: newPublicFormData.referenceId,
            onSubmitMessage: defaultOnSubmitMessage,
          });
        }
      })
      .catch((error) =>
        errorAlert(getText('publicForms.failedToCreateErrorMessage'), error),
      )
      .finally(() => setLoading(false));
  }, [
    createPublicForm,
    dataType,
    defaultOnSubmitMessage,
    element,
    errorAlert,
    project,
    updateProperty,
  ]);

  const deleteCurrentPublicForm = useCallback(
    (referenceId: any) => {
      if (referenceId) {
        setLoading(true);
        deletePublicForm({
          variables: {
            projectName: project.name,
            publicFormReferenceId: referenceId,
            elementId: element.id,
            dataTypeId: dataType.id,
          },
        })
          .then(() => updateProperty(['publicForms'], []))
          .catch((error) =>
            errorAlert(
              getText('publicForms.failedToDeleteErrorMessage'),
              error,
            ),
          )
          .finally(() => setLoading(false));
      }
    },
    [errorAlert, deletePublicForm, updateProperty, project, element, dataType],
  );

  const handleEnablePublicForm = useCallback(
    () =>
      elementHasPublicForms
        ? deleteCurrentPublicForm(get(elementPublicForms, [0, 'referenceId']))
        : createNewPublicFrom(),
    [
      createNewPublicFrom,
      deleteCurrentPublicForm,
      elementHasPublicForms,
      elementPublicForms,
    ],
  );

  return (
    <>
      <BuildModeHeader
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={elementPath}
        elementType={FORM}
        project={project}
        props={element.props.new}
        showBreadcrumbs={true}
        updateProperty={updateProperty}
      />
      <BuildModeSection
        className="border-t p-2"
        id={FORM_SAVE_BUTTON}
        sticky={true}
        title={getText('rightSidebar.editor.saveButton.title')}
      >
        <div className="p-2">
          <BuildModeInput
            label={getText('rightSidebar.editor.saveButton.label')}
          >
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
              contained={true}
              elementPath={elementPath}
              onChange={(value: any) =>
                updateProperty(['saveButtonText'], value)
              }
              project={project}
              value={saveButtonText}
            />
          </BuildModeInput>
        </div>
      </BuildModeSection>
      <BuildModeSection
        className="border-t p-2"
        id={FORM_ON_SAVE_ACTIONS}
        sticky={true}
        title={getText(LANG_KEY, 'buttons.onSave.label')}
      >
        <div className="flex flex-col p-2">
          <div className="mb-4 flex w-full items-center justify-between">
            <BuildModeLabel>
              {getText(LANG_KEY, 'buttons.onSave.help')}
            </BuildModeLabel>
            <FeatureLockedSwitch
              feature={FORM_SAVE_ACTIONS}
              size="sm"
              className="ml-auto"
              onChange={(newValue: any) =>
                updateProperty(
                  ['onSave'],
                  newValue ? { navigate: { type: PAGE } } : null,
                )
              }
              value={!!onSave}
            />
          </div>
          {onSave && (
            <BuildModeLinkEditor
              updateProperty={(propPath: any, newValue: any) =>
                updateProperty(['onSave', 'navigate', ...propPath], newValue)
              }
              elementProps={onSave?.navigate ?? {}}
              debouncedUpdateProperty={(propPath: any, newValue: any) =>
                updateProperty(['onSave', 'navigate', ...propPath], newValue)
              }
              elementPath={elementPath}
              project={project}
              additionalScopeItems={additionalScopeItems}
            />
          )}
        </div>
      </BuildModeSection>
      <BuildModeSection
        className="border-t p-2"
        id={PUBLIC_FORM}
        sticky={true}
        guide={
          <Guide href="https://guides.noloco.io/public-forms/public-forms">
            {getText(LANG_KEY, 'publicFormsProductGuideLinkText')}
          </Guide>
        }
        title={getText('rightSidebar.editor.publicForm')}
      >
        <div className="space-y-2 p-2">
          <BuildModeSwitchSection
            label={getText(LANG_KEY, 'enablePublicFormToggleLabel')}
            disabled={loading}
            onChange={handleEnablePublicForm}
            value={elementHasPublicForms}
          />
          {elementHasPublicForms && (
            <div className="space-y-4">
              <BuildModeSwitchSection
                label={getText(LANG_KEY, 'enablePublicFormNav')}
                onChange={handleHideNavBar}
                value={!hideNavBar}
              />
              <div>
                <BuildModeLabel className="text-gray-400">
                  {getText(LANG_KEY, 'customOnSubmitMessageInputLabel')}
                </BuildModeLabel>
                <TextInput
                  className="group mt-2 flex w-full items-center whitespace-nowrap rounded-lg bg-gray-700 px-2 py-3 text-xs"
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={(e) => {
                    updateOnSubmitMessage(e.target.value);
                  }}
                  value={onSubmitMessage}
                  placeholder={defaultOnSubmitMessage}
                />
              </div>
              <div>
                <BuildModeLabel className="text-gray-400">
                  {getText(LANG_KEY, 'publicFormUrl')}
                </BuildModeLabel>
                <div className="group mt-2 flex w-full items-center justify-between break-all rounded-lg bg-gray-700 p-2 text-xs">
                  <a
                    className="hover:underline"
                    href={publicFormUrl}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {publicFormUrl}
                  </a>
                  <CopyButton
                    surface={DARK}
                    tooltipContent={
                      <span className={classNames(darkModeColors.text.primary)}>
                        {getText(LANG_KEY, 'urlCopied')}
                      </span>
                    }
                    value={publicFormUrl}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </BuildModeSection>
    </>
  );
};

export default BuildModeFormOptionsTab;
