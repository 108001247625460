import { forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Theme, getColorShade } from '@noloco/components';
import {
  CHECKLIST,
  CollectionLayout,
  LAYOUT_SUPPORTS_RECORD_COLORING,
  SPLIT,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { DataType } from '../../../../models/DataTypes';
import { ActionButton, DepValue } from '../../../../models/Element';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormConfigWithField } from '../../../../models/View';
import { getFieldPathFromPath } from '../../../../utils/charts';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import { getImagesFromFieldConfig } from '../../../../utils/files';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useRouter from '../../../../utils/hooks/useRouter';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import CollectionCardGallery from '../CollectionCardGallery';
import ChecklistBox from './ChecklistBox';
import RowFieldCell from './RowFieldCell';

interface RowRecordLayoutProps {
  actionButtons?: ActionButton[];
  bulkActionsEnabled?: boolean;
  checklistField?: DepValue;
  className?: string;
  dataType: DataType;
  element: Element;
  elementId: string;
  fieldConfigs: FormConfigWithField[];
  handleCheckboxChange: (checked: { target: { checked: boolean } }) => void;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  project: Project;
  record: BaseRecord;
  rowLink: string;
  scope: Record<string, any>;
  selectedRows?: BaseRecord[];
  theme: Theme;
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  showCardHeroImage?: boolean;
}

const RowRecordLayout = forwardRef<HTMLDivElement, RowRecordLayoutProps>(
  (
    {
      actionButtons,
      bulkActionsEnabled = false,
      checklistField,
      className,
      dataType,
      element,
      elementId,
      fieldConfigs,
      handleCheckboxChange,
      isRowChecked = false,
      layout,
      project,
      record,
      rowLink,
      scope,
      selectedRows = [],
      theme,
      transformRecordScope,
      showCardHeroImage,
      ...rest
    },
    ref,
  ) => {
    const {
      query: { recordId },
    } = useRouter();

    const isRecordOpen = useMemo(() => {
      if (layout === SPLIT) {
        return recordId === record.uuid;
      }
    }, [layout, record, recordId]);

    const showChecklist = useMemo(
      () => !!(layout === CHECKLIST && checklistField),
      [layout, checklistField],
    );

    const checklistFieldName = useMemo(() => {
      if (checklistField) {
        return getFieldPathFromPath(checklistField?.path!);
      }
    }, [checklistField]);

    const checklistFieldValue = useMemo(() => {
      if (!checklistField || !checklistFieldName) {
        return false;
      }

      return !!get(record, checklistFieldName);
    }, [checklistField, checklistFieldName, record]);

    const Row = rowLink ? Link : 'div';
    const [isDarkModeEnabled] = useDarkMode();
    const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
      handleCheckboxChange,
      isRowChecked,
      selectedRows,
    );

    const primaryColor = theme.brandColors.primary;

    const colorConditions = useMemo(
      () => get(element, ['props', 'recordColoring'], []),
      [element],
    );
    const conditionBasedColor = useMemo(
      () => getColorBasedOnConditions(colorConditions, scope, project),
      [colorConditions, scope, project],
    );

    const images = useMemo(
      () => getImagesFromFieldConfig(fieldConfigs, record, showCardHeroImage),
      [fieldConfigs, record, showCardHeroImage],
    );

    const showHeroImage = useMemo(
      () => showCardHeroImage && images,
      [showCardHeroImage, images],
    );

    return (
      // @ts-expect-error TS(2322): Type 'ForwardedRef<HTMLDivElement>' is not assignable to type '((string | ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement>) & (string | ((instance: HTMLDivElement | null) => void) | RefObject<...>)) | null | undefined'
      <Row
        className={classNames(
          className,
          'group relative flex w-full flex-row items-center gap-x-4 px-6 py-3 text-sm md:flex-col',
          {
            [`bg-${getColorShade(
              primaryColor,
              100,
            )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
              isRowChecked,
            'opacity-60': showChecklist && checklistFieldValue,
            [`bg-${getColorShade(primaryColor, 200)} dark:bg-${getColorShade(
              primaryColor,
              700,
            )} bg-opacity-25`]: isRecordOpen,
            [`bg-${getColorShade(
              conditionBasedColor,
              50,
            )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
              !isRecordOpen && !isDarkModeEnabled && conditionBasedColor,
            [`bg-${getColorShade(
              conditionBasedColor,
              900,
            )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
              !isRecordOpen &&
              isDarkModeEnabled &&
              conditionBasedColor &&
              LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
            [`hover:${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation2
                : 'bg-gray-100'
            } hover:bg-opacity-50`]: !isRecordOpen && !conditionBasedColor,
          },
        )}
        data-testid="collection-record"
        onClick={selectRecordOnCmdClick}
        ref={ref}
        to={rowLink}
        {...rest}
      >
        {showHeroImage && (
          <div className="h-full w-64 max-w-full">
            <CollectionCardGallery
              image={{ hidden: false }}
              images={images}
              index={0}
              layout="ROWS"
            />
          </div>
        )}
        <div
          className={classNames(
            'my-4 grid w-full flex-1 grid-cols-12 gap-x-6 gap-y-4 sm:pl-0',
            { 'pl-6': showChecklist },
          )}
        >
          {showChecklist && checklistFieldName && (
            <ChecklistBox
              checklistFieldName={checklistFieldName}
              checklistFieldValue={checklistFieldValue}
              dataType={dataType}
              projectName={project.name}
              record={record}
            />
          )}
          {fieldConfigs.map(
            (
              { field, parent, parentFieldType, config, permissions },
              index: number,
            ) => {
              if (showHeroImage && index === 0) {
                return null;
              }

              return (
                <RowFieldCell
                  bulkActionsEnabled={bulkActionsEnabled}
                  config={config}
                  dataType={parentFieldType || dataType}
                  elementId={elementId}
                  field={field}
                  isRowChecked={isRowChecked}
                  key={`${field.name}:${(parentFieldType || dataType).name}`}
                  layout={layout}
                  parent={parent}
                  permissions={permissions}
                  project={project}
                  record={record}
                  selectedRows={selectedRows}
                  transformRecordScope={transformRecordScope}
                />
              );
            },
          )}
        </div>
        {actionButtons}
      </Row>
    );
  },
);

export default withTheme(RowRecordLayout);
