import { decode, encode } from 'js-base64';
import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';
import queryString from 'query-string';
import { Project } from '../../models/Project';
import { getPages } from '../pages';
import { formatQueryString, getPageTo } from '../urls';
import useRouter from './useRouter';

export const safelyParseParentPage = (parentPage: any) => {
  try {
    return JSON.parse(decode(parentPage));
  } catch {
    return {};
  }
};

export type ReturnUseBackLink = {
  to: string;
  qs: string;
  enabled: any;
  name: any;
} | null;

export const useBackLink = (project: Project): ReturnUseBackLink => {
  const {
    query: { _parentPage },
  } = useRouter();

  if (!_parentPage) {
    return null;
  }

  const parentPages = Array.isArray(_parentPage)
    ? _parentPage
    : _parentPage.split(',');
  const lastParentPage = last(parentPages);

  const {
    elementId: parentPageId,
    recordId: recordUuid,
    tab,
    params,
    enabled,
  } = safelyParseParentPage(lastParentPage);

  const parentView = project.elements.find(
    (element: any) => element.id === parentPageId,
  );

  if (!parentView) {
    return null;
  }

  const pages = getPages(project.elements);
  const name = get(parentView, 'props.name');
  const to = `${getPageTo([parentView.id], {}, pages, {})}${
    recordUuid ? `/view/${recordUuid}` : ''
  }${tab ? `/${tab}` : ''}`;

  const qs =
    parentPages.length > 1
      ? queryString.stringify({
          ...(params ?? {}),
          _parentPage: initial(parentPages),
        })
      : params
        ? queryString.stringify(params)
        : null;

  return {
    to: `${to}${formatQueryString(qs)}`,
    qs: formatQueryString(qs),
    enabled,
    name: name,
  };
};

export const useNextBackLink = (elementId: any, enabled = true) => {
  const {
    query: { _parentPage, recordId, tab, ...params },
  } = useRouter();

  if (!elementId) {
    return null;
  }

  if (!enabled && Object.keys(params).length === 0 && !_parentPage) {
    return null;
  }

  const nextBackLink = encode(
    JSON.stringify({
      enabled,
      elementId,
      recordId,
      tab,
      params,
    }),
  );

  if (!_parentPage) {
    return [nextBackLink];
  }

  const parentPages = Array.isArray(_parentPage)
    ? _parentPage
    : _parentPage.split(',');

  return [...parentPages, nextBackLink];
};
