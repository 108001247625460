import React, { useCallback, useMemo, useState } from 'react';
import { IconEdit, IconSettings } from '@tabler/icons-react';
import { SwitchButton } from '@noloco/components';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { OPTIONS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { NEW_RECORD_BUTTON } from '@noloco/core/src/constants/elements';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { getText } from '@noloco/core/src/utils/lang';
import Drawer from '../../canvas/Drawer';
import StringPropEditor from '../../canvas/StringPropEditor';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import ViewLinkEditor from './ViewLinkEditor';

const LANG_KEY = 'elements.VIEW.buttons.newButton';

interface Props {
  dataType: DataType;
  debouncedUpdateProperty: (path: ElementPath, value: any) => void;
  elementPath: ElementPath;
  hideNewButton: boolean | undefined;
  newButton: { visibilityRules?: any };
  newButtonText: string | undefined;
  newLink: any | undefined;
  openFormInModal?: boolean;
  project: Project;
  rootDataType: DataType;
  updateProperty: (path: ElementPath, value: any) => void;
  allowOpenFormInModal: boolean;
}

const NewButtonEditor = ({
  dataType,
  debouncedUpdateProperty,
  elementPath,
  hideNewButton,
  newButton,
  newButtonText,
  newLink,
  openFormInModal,
  project,
  rootDataType,
  updateProperty,
  allowOpenFormInModal,
}: Props) => {
  const onVisibilityRulesChange = useCallback(
    (path: ElementPath, value: any) =>
      updateProperty(['newButton', 'visibilityRules', ...path], value),
    [updateProperty],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [editorTab, setEditorTab] = useEditorTabs('newButton', OPTIONS);
  const showNewButton = useMemo(
    () => dataType && !dataType.readOnly && !hideNewButton,
    [dataType, hideNewButton],
  );

  const openFormInModalValue = useMemo(
    () => Boolean(openFormInModal),
    [openFormInModal],
  );
  const openFormOptions = [
    {
      label: getText(LANG_KEY, 'openFormInModal.modal'),
      value: true,
    },
    {
      label: getText(LANG_KEY, 'openFormInModal.newPage'),
      value: false,
    },
  ];

  const onOpenFormInModalChange = useCallback(
    (value: OptionValue) => {
      updateProperty(['openFormInModal'], !!value);

      if (value) {
        updateProperty(['newLink'], undefined);
      }
    },
    [updateProperty],
  );

  const onToggleEnabled = useCallback(
    (nextEnabled: boolean) => {
      if (nextEnabled) {
        updateProperty(['newButton'], {});
        updateProperty(['hideNewButton'], false);
        updateProperty(['newButtonText'], '');
      } else {
        updateProperty(['hideNewButton'], true);
      }
    },
    [updateProperty],
  );

  const handleNewButtonClick = useCallback(
    () => (!showNewButton ? () => setIsOpen(true) : null),
    [showNewButton],
  );

  return (
    <>
      <BuildModeSwitchSection
        button={
          !hideNewButton && (
            <IconSettings
              className="cursor-pointer text-slate-500 hover:text-slate-400"
              onClick={() => setIsOpen(true)}
              size={16}
            />
          )
        }
        label={getText(LANG_KEY, 'label')}
        disabled={dataType && dataType.readOnly}
        value={showNewButton}
        showOnlyButton={true}
        onChange={onToggleEnabled}
        onClick={handleNewButtonClick}
      />
      {isOpen && showNewButton && (
        <Drawer
          Icon={IconEdit}
          onClose={() => setIsOpen(false)}
          rootSelector=".noloco-project"
          title={getText(LANG_KEY, 'label')}
          usePortal={true}
        >
          <div className="flex w-screen max-w-full flex-col">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={NEW_RECORD_BUTTON}
              isNavEnabled={false}
              setEditorTab={setEditorTab}
            />
            {editorTab === OPTIONS && (
              <div className="flex flex-col space-y-2 p-2">
                <BuildModeInput label={getText(LANG_KEY, 'buttonText')}>
                  <StringPropEditor
                    // @ts-expect-error TS(2339): Property 'contained' does not exist on type '{ chi... Remove this comment to see the full error message
                    contained={true}
                    project={project}
                    onChange={(value: any) =>
                      debouncedUpdateProperty(['newButtonText'], value)
                    }
                    elementPath={elementPath}
                    placeholder=""
                    value={newButtonText}
                  />
                </BuildModeInput>
                {!openFormInModal && (
                  <ViewLinkEditor
                    allowNone={false}
                    dataType={dataType}
                    onChange={(nextNewLink?: string) => {
                      updateProperty(['newLink'], nextNewLink);
                    }}
                    label={getText(LANG_KEY, 'newLink')}
                    project={project}
                    value={newLink}
                  />
                )}
                {allowOpenFormInModal && (
                  <BuildModeInput
                    inline={true}
                    label={getText(LANG_KEY, 'openFormInModal.label')}
                  >
                    <SwitchButton
                      className="h-8 w-full rounded-lg"
                      inverseColors={true}
                      onChange={onOpenFormInModalChange}
                      options={openFormOptions}
                      value={openFormInModalValue}
                    />
                  </BuildModeInput>
                )}
              </div>
            )}
            {editorTab === VISIBILITY && (
              <VisibilityRulesEditor
                dataType={rootDataType}
                elementPath={elementPath}
                onChange={onVisibilityRulesChange}
                project={project}
                visibilityRules={newButton.visibilityRules}
              />
            )}
          </div>
        </Drawer>
      )}
    </>
  );
};

export default NewButtonEditor;
