import { useCallback } from 'react';
import { ACTION_BUTTONS } from '../../../constants/elements';
import { BaseRecord } from '../../../models/Record';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useRouter from '../../../utils/hooks/useRouter';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import ActionButton from './ActionButton';

const RecordActionButtons = ({
  actionButtons,
  backLink,
  buttonType,
  dataType,
  editorMode,
  elementPath,
  project,
  record,
  recordScope,
  rootPathname,
}: any) => {
  const { push } = useRouter();
  const { actionButtons: parsedActionButtons } = useSectionScopeVariables(
    ACTION_BUTTONS,
    {
      actionButtons: actionButtons.map(({ actionButton }: any) => actionButton),
    },
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const handleDeleteRecord = useCallback(
    (__: BaseRecord, onNext: () => void) => {
      if (backLink && backLink.enabled) {
        push(backLink.to);
      } else {
        push(rootPathname);
      }
      onNext();
    },
    [push, backLink, rootPathname],
  );

  return parsedActionButtons.map((actionButton: any, index: any) => (
    <ActionButton
      actionButton={actionButton}
      buttonType={buttonType}
      dataType={dataType}
      editorMode={editorMode}
      index={actionButtons[index].idx}
      key={actionButton.id}
      onDeleteRecord={handleDeleteRecord}
      record={record}
      recordScope={recordScope}
      project={project}
    />
  ));
};

export default RecordActionButtons;
