import { OBJECT } from '../constants/dataTypes';
import { FORMATS_WITH_ROOT } from '../constants/objects';
import PRIMARY_FIELD_TYPES from '../constants/primaryFields';
import { DataField } from '../models/DataTypeFields';
import { DataType } from '../models/DataTypes';
import { getPrimaryField } from './fields';

export const isPrimaryField = (
  dataType: DataType,
  field: { id: DataField['id'] | null },
): boolean => {
  const primaryField = dataType.primaryField || getPrimaryField(dataType);

  return !!primaryField && primaryField.id === field.id;
};

export const hasPrimaryFieldDefined = (
  dataType: DataType,
  field: { id: DataField['id'] | null },
): boolean => {
  const primaryField = dataType.primaryField;

  return !!primaryField && primaryField.id === field.id;
};

export const isValidPrimaryFieldType = (
  field: Pick<DataField, 'typeOptions'> & { type: DataField['type'] | null },
) => {
  if (field.type && PRIMARY_FIELD_TYPES.includes(field.type)) {
    return true;
  }

  return (
    field.type === OBJECT &&
    field.typeOptions?.format &&
    FORMATS_WITH_ROOT.includes(field.typeOptions?.format)
  );
};
