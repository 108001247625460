import React, { useCallback, useMemo } from 'react';
import { IconArrowLeft, IconChevronRight } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import {
  UpdatePropertyCallback,
  useUpdateProperty,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  FIELDS,
  LeftEditorSectionOptions,
  OPTIONS,
} from '../../../constants/buildMode';
import { FORM, VIEW } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { ElementPath } from '../../../models/Element';
import { setLeftEditorSection } from '../../../reducers/elements';
import { projectDataSelector } from '../../../selectors/projectSelectors';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useEditorTabs from '../../../utils/hooks/useEditorTabs';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { Page } from '../../../utils/pages';
import { RECORD_SCOPE } from '../../../utils/scope';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import StandaloneRightBuildMode from '../StandaloneRightBuildMode';
import BuildModeFormFieldsTab from './BuildModeFormFieldsTab';
import BuildModeFormOptionsTab from './BuildModeFormOptionsTab';

export interface BuildModeFormEditorProps {
  elementPath: ElementPath;
  leftEditor: LeftEditorSectionOptions | null;
  isRecordView?: boolean;
  page: Page;
}

const DEBOUNCE_TIME = 300;
const EditorTabMap = {
  [OPTIONS]: BuildModeFormOptionsTab,
  [FIELDS]: BuildModeFormFieldsTab,
};

const BuildModeFormEditor = ({
  elementPath,
  isRecordView = false,
  leftEditor,
  page,
}: BuildModeFormEditorProps) => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const [updateProperty] = useUpdateProperty(elementPath, project);
  const updateFormProps: UpdatePropertyCallback = useCallback(
    (path: ElementPath, value: any) => updateProperty(['new', ...path], value),
    [updateProperty],
  );
  const debouncedUpdateFormProps = useMemo(
    () => debounce(updateFormProps, DEBOUNCE_TIME),
    [updateFormProps],
  );
  const [editorTab, setEditorTab] = useEditorTabs('form', OPTIONS);
  const dataType = useMemo(() => {
    const typeName = get(page.props.dataList, 'dataType');

    return typeName && project.dataTypes.getByName(typeName);
  }, [page, project.dataTypes]);

  const { new: { title = null } = {} } = useSectionScopeVariables(
    VIEW,
    { new: { title: page.props.new.title } },
    project,
    elementPath,
    {},
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const fields = useMemo(() => get(page, 'props.new.fields', []), [page]);
  const Editor = useMemo(() => EditorTabMap[editorTab], [editorTab]);

  return (
    <div className="w-full">
      <div className="sticky top-0 z-10 flex h-12 w-full items-center space-x-2 border-b border-gray-700 bg-slate-800 px-2 text-gray-300">
        {leftEditor ? (
          <IconArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => dispatch(setLeftEditorSection(null))}
          />
        ) : (
          <Icon icon={page.props.icon} className="h-5 w-5" />
        )}
        <div className="flex items-center space-x-2">
          <span>{page.props.name}</span>
          <IconChevronRight size={12} className="text-gray-400" />
          <span>{title}</span>
        </div>
      </div>
      {leftEditor ? (
        <StandaloneRightBuildMode
          dataType={dataType}
          debouncedUpdateProperty={debouncedUpdateFormProps}
          element={page}
          elementPath={elementPath}
          elementType={FORM}
          isRecordView={isRecordView}
          project={project}
          updateProperty={updateFormProps}
        />
      ) : (
        <>
          <div className="sticky top-12 z-10 w-full bg-slate-800">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={FORM}
              setEditorTab={setEditorTab}
            />
          </div>
          <Editor
            dataType={dataType}
            debouncedUpdateProperty={debouncedUpdateFormProps}
            element={page}
            elementPath={elementPath}
            fields={fields}
            project={project}
            updateProperty={updateFormProps}
          />
        </>
      )}
    </div>
  );
};

export default BuildModeFormEditor;
