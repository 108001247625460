import { useCallback } from 'react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import { Button } from '@noloco/components';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { useFieldOperatorConflicts } from '@noloco/core/src/utils/hooks/useFieldOperatorConflicts';
import { getText } from '@noloco/core/src/utils/lang';
import FilterInput from './FilterInput';
import FilterMergeWarning from './FilterMergeWarning';

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const OrFilterBranchInput = ({
  additionalScopeItems,
  branch,
  dataType,
  elementPath,
  generateAdditionalFilter,
  index,
  onChange,
  project,
  setValue,
  value,
  ValueInput,
}: any) => {
  const handleOrBranchValueChange = useCallback(
    (branchIndex: any, filterIndex: any, newValue: any) => {
      const updated = set(
        ['branches', branchIndex, 'filters', filterIndex],
        newValue,
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [onChange, setValue, value],
  );

  const handleOrBranchValueRemove = useCallback(
    (branchIndex: any, filterIndex: any) => {
      const updatedFilters = value.branches[branchIndex].filters.filter(
        (_: any, index: any) => index !== filterIndex,
      );

      const updated = set(
        ['branches', branchIndex, 'filters'],
        updatedFilters,
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [onChange, setValue, value],
  );

  const handleOrBranchValueAdded = useCallback(
    (branchIndex: any) => {
      const updated = set(
        ['branches', branchIndex, 'filters'],
        [...value.branches[branchIndex].filters, generateAdditionalFilter()],
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [generateAdditionalFilter, onChange, setValue, value],
  );

  const handleOrBranchRemoved = useCallback(
    (branchIndex: any) => {
      const newLocalValue = {
        ...value,
        branches: value.branches.filter(
          (_: any, index: any) => branchIndex !== index,
        ),
      };

      setValue(newLocalValue);
      onChange(newLocalValue);

      return newLocalValue;
    },
    [onChange, setValue, value],
  );

  const { fieldOperatorCounts, showFieldOperatorWarning } =
    useFieldOperatorConflicts(branch.filters);

  return (
    <>
      {index !== 0 && (
        <div className="mx-4 my-2 flex items-center">
          <span className="text-sm uppercase text-white">
            {getTranslation('or')}
          </span>
        </div>
      )}
      {showFieldOperatorWarning && <FilterMergeWarning />}
      <div className="bg-brand-darkest relative my-1 space-y-2 rounded-lg p-2">
        <button
          onClick={() => handleOrBranchRemoved(index)}
          className="bg-brand-light absolute right-0 top-0 -mr-2 -mt-2 flex items-center justify-center rounded-full p-1 text-white hover:bg-pink-500"
        >
          <IconTrash size={12} />
        </button>
        {branch.filters.map((branchFilter: any, filterIndex: any) => (
          <FilterInput
            additionalScopeItems={additionalScopeItems}
            dataType={dataType}
            key={branchFilter.id}
            value={branchFilter}
            onChange={(newValue: any) =>
              handleOrBranchValueChange(index, filterIndex, newValue)
            }
            onRemove={() => handleOrBranchValueRemove(index, filterIndex)}
            elementPath={[
              ...elementPath,
              'branches',
              index,
              'filters',
              filterIndex,
            ]}
            operatorValidationError={
              get(
                fieldOperatorCounts,
                [branchFilter.field, branchFilter.operator],
                0,
              ) > 1
            }
            project={project}
            ValueInput={ValueInput}
          />
        ))}
        <div className="group mt-2 flex items-center justify-between">
          <Button
            className="flex h-8 items-center"
            onClick={() => handleOrBranchValueAdded(index)}
            variant={SECONDARY}
          >
            <IconPlus className="mr-2" size={16} />
            <span>{getTranslation('addAnd')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrFilterBranchInput;
