import { ElementPropTypes } from '../../constants/elementPropTypeTypes';
import DataTypes from '../DataTypes';
import { DepValue, Element } from '../Element';

class ElementPropType {
  type: ElementPropTypes;
  default: undefined | any;
  values: undefined | any[];
  required: boolean;
  extractPropTypesDependencies?: (
    prop: Record<any, any>,
    dependencies: DepValue[],
    elementProps?: Record<any, DepValue>,
    propsShape?: Record<any, ElementPropType>,
    element?: Element,
    dataTypes?: DataTypes,
    includeSelf?: boolean,
  ) => DepValue[];
  propTransformation?: (
    props: any,
    idMap: Record<string, string>,
    {
      cloneDataItem,
    }: {
      cloneDataItem: (
        pageValue: DepValue,
        idMap: Record<string, string>,
      ) => DepValue;
    },
  ) => DepValue;
  isStyleProp: boolean;
  display: () => boolean;
  additionalSettingsPath: any[];
  rawValue: boolean;
  automaticallyResolve: boolean;
  onChange: undefined | ((undefined: any) => any);
  includeSelf: undefined | boolean;
  onlyIncludeSelf: undefined | boolean;
  includeCollections: undefined | boolean;

  constructor(type: ElementPropTypes) {
    this.type = type;
    this.default = undefined;
    this.values = [];
    this.required = true;
    this.extractPropTypesDependencies = undefined;
    this.propTransformation = undefined;
    this.isStyleProp = false;
    this.display = () => true;
    this.additionalSettingsPath = [];
    this.rawValue = false;
    this.automaticallyResolve = true;
    this.onChange = undefined;
  }

  setDefault(defaultValue: any) {
    this.default = defaultValue;

    return this;
  }

  isRequired(required: boolean) {
    this.required = required;

    return this;
  }

  setIsStyleProp(isStyleProp: boolean) {
    this.isStyleProp = isStyleProp;

    return this;
  }

  setDisplay(displayFn: (...varArgs: any[]) => any) {
    this.display = displayFn;

    return this;
  }

  validValues(values: any) {
    this.values = values;

    return this;
  }

  derive() {
    return this;
  }

  setExtractPropTypesDependencies(
    extractPropTypesDependencies: (
      prop: Record<any, any>,
      dependencies: DepValue[],
      elementProps?: Record<any, DepValue>,
      propsShape?: Record<any, ElementPropType>,
      element?: Element,
      dataTypes?: DataTypes,
      includeSelf?: boolean,
    ) => DepValue[],
  ) {
    this.extractPropTypesDependencies = extractPropTypesDependencies;

    return this;
  }

  setPropTransformation(
    propTransformation: (
      props: any,
      idMap: Record<string, string>,
      {
        cloneDataItem,
      }: {
        cloneDataItem: (
          pageValue: DepValue,
          idMap: Record<string, string>,
        ) => DepValue;
      },
    ) => DepValue,
  ) {
    this.propTransformation = propTransformation;

    return this;
  }

  setIncludeSelf(includeSelf: true) {
    this.includeSelf = includeSelf;

    return this;
  }

  setOnlyIncludeSelf(onlyIncludeSelf: boolean) {
    this.onlyIncludeSelf = onlyIncludeSelf;

    return this;
  }

  setIncludeCollections(includeCollections: boolean) {
    this.includeCollections = includeCollections;

    return this;
  }

  setUseRawValue(rawValue: any) {
    this.rawValue = rawValue;

    return this;
  }

  setOnChange(onChange: (...varArgs: any[]) => any) {
    this.onChange = onChange;

    return this;
  }

  setAutomaticallyResolve(automaticallyResolve: boolean) {
    this.automaticallyResolve = automaticallyResolve;

    return this;
  }
}

export default ElementPropType;
