import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { TEXT } from '../../../constants/dataTypes';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import { scopeSelector } from '../../../selectors/dataSelectors';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import {
  getAllowedViewRoutePrefixForDataType,
  getViewRoutePrefixForViewId,
} from '../../../utils/urls';
import RelatedCellItem from './RelatedCellItem';

const RelatedFieldCell = ({
  backLink,
  condensed,
  config,
  field,
  value,
  project,
}: any) => {
  const user = useScopeUser();
  const scope = useSelector(scopeSelector);
  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);

  const recordLinkRoot = useMemo(() => {
    if (config.elementType === TEXT) {
      return null;
    }

    if (config.rowLink) {
      return getViewRoutePrefixForViewId(config.rowLink, project);
    }

    return getAllowedViewRoutePrefixForDataType(
      field.type,
      project,
      user,
      scope,
      customRulesEnabled,
    );
  }, [
    config.elementType,
    config.rowLink,
    customRulesEnabled,
    field.type,
    project,
    scope,
    user,
  ]);

  return (
    <RelatedCellItem
      className="max-w-full"
      condensed={condensed}
      innerClassName=""
      backLink={backLink}
      field={field}
      recordLinkRoot={recordLinkRoot}
      value={value}
      dataTypes={project.dataTypes}
      elementType={config.elementType}
      fileLayout={config.fileLayout}
    />
  );
};

export default RelatedFieldCell;
