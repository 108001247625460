import { ReactNode, forwardRef, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Theme, getColorShade } from '@noloco/components/src';
import {
  CollectionLayout,
  LAYOUT_SUPPORTS_RECORD_COLORING,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { DataType } from '../../../../models/DataTypes';
import { ActionButton } from '../../../../models/Element';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormConfigWithField } from '../../../../models/View';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import BulkActionCheckboxCell from '../BulkActionCheckboxCell';
import TableFieldCell from './TableFieldCell';

interface TableRecordLayoutProps {
  actionButtons?: ActionButton[];
  bulkActionsEnabled?: boolean;
  className?: string;
  columnWidths?: Record<number, number>;
  dataType: DataType;
  element: Element;
  elementId: string;
  fieldConfigs: FormConfigWithField[];
  handleCheckboxChange: () => void;
  isRowChecked: boolean;
  layout: CollectionLayout;
  maxStickyColumnIndex: number;
  project: Project;
  record: BaseRecord;
  rowLink: string;
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  scope: Record<string, any>;
  selectedRows?: BaseRecord[];
  theme: Theme;
}

const TableRecordLayout = forwardRef<
  HTMLTableRowElement,
  TableRecordLayoutProps
>(
  (
    {
      actionButtons = [],
      bulkActionsEnabled = false,
      className,
      columnWidths = {},
      dataType,
      element,
      elementId,
      fieldConfigs,
      handleCheckboxChange,
      isRowChecked = false,
      layout,
      maxStickyColumnIndex,
      project,
      record,
      rowLink,
      scope,
      selectedRows = [],
      theme,
      transformRecordScope,
      ...rest
    },
    ref,
  ) => {
    const [isDarkModeEnabled] = useDarkMode();

    const colorConditions = useMemo(
      () => get(element, ['props', 'recordColoring'], []),
      [element],
    );
    const conditionBasedColor = useMemo(
      () => getColorBasedOnConditions(colorConditions, scope, project),
      [colorConditions, scope, project],
    );

    return (
      <tr
        className={classNames(className, `group relative w-full px-6 text-xs`, {
          [`hover:bg-opacity-50 hover:${
            isDarkModeEnabled
              ? darkModeColors.surfaces.elevation2
              : 'bg-gray-100'
          }`]: !conditionBasedColor,
          [`bg-${getColorShade(
            conditionBasedColor,
            50,
          )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
            conditionBasedColor &&
            !isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
          [`bg-${getColorShade(
            conditionBasedColor,
            900,
          )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
            conditionBasedColor &&
            isDarkModeEnabled &&
            LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
        })}
        data-testid="collection-record"
        ref={ref}
        {...rest}
      >
        {bulkActionsEnabled && (
          <BulkActionCheckboxCell
            elementId={elementId}
            handleCheckboxChange={handleCheckboxChange}
            isRowChecked={isRowChecked}
            isSticky={!isNil(maxStickyColumnIndex)}
            primaryColor={theme.brandColors.primary}
          />
        )}
        {fieldConfigs.map(
          ({ field, parent, parentFieldType, config, permissions }, index) => (
            <TableFieldCell
              bulkActionsEnabled={bulkActionsEnabled}
              config={config}
              dataType={parentFieldType || dataType}
              elementId={elementId}
              field={field}
              handleCheckboxChange={handleCheckboxChange}
              isFirst={index === 0}
              isLast={index === fieldConfigs.length - 1}
              isRowChecked={isRowChecked}
              isSticky={
                !isNil(maxStickyColumnIndex) && index <= maxStickyColumnIndex
              }
              key={`${field.name}:${(parentFieldType || dataType).name}`}
              parent={parent}
              permissions={permissions}
              project={project}
              record={record}
              rowLink={rowLink}
              selectedRows={selectedRows}
              theme={theme}
              transformRecordScope={transformRecordScope}
              width={columnWidths[index]}
            />
          ),
        )}
        {actionButtons as ReactNode}
      </tr>
    );
  },
);

export default withTheme(TableRecordLayout);
