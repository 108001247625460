import { useCallback, useMemo } from 'react';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { darkModeColors } from '../../../../constants/darkModeColors';
import {
  PivotTableHeader,
  PivotTableValue,
} from '../../../../constants/pivotTable';
import { GroupBy } from '../../../../models/View';
import { getColorByIndex } from '../../../../utils/colors';
import useDarkMode from '../../../../utils/hooks/useDarkMode';

interface PivotTableGroupHeaderProps {
  group?: GroupBy;
  columns: PivotTableHeader[];
  index?: number;
  updateProperty?: UpdatePropertyCallback;
  valueColumns?: PivotTableValue[];
}

const PivotTableGroupHeader = ({
  group,
  columns,
  index = 0,
  updateProperty,
  valueColumns = [],
}: PivotTableGroupHeaderProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  const isGroupCollapsed = useMemo(() => get(group, 'collapsed'), [group]);

  const handleToggleCollapse = useCallback(
    () =>
      updateProperty!(
        ['pivotTable', 'columnGrouping', index + 1, 'collapsed'],
        !isGroupCollapsed,
      ),
    [isGroupCollapsed, index, updateProperty],
  );

  return (
    <tr
      className={classNames(
        'group z-20 w-full flex-shrink-0 truncate border-b text-xs font-medium uppercase text-gray-600 shadow-sm',
        `${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`
            : 'bg-gray-50'
        }`,
      )}
    >
      <td
        className={classNames(
          'left-0',
          isDarkModeEnabled ? darkModeColors.surfaces.elevation1 : 'bg-gray-50',
        )}
      >
        {valueColumns.length === 0 && (
          <div className="flex items-stretch">
            <div
              className={classNames(
                'w-1 rounded',
                `bg-${getColorByIndex(index, 400)}`,
              )}
            />
            <div
              className={classNames(
                'flex items-center px-6 py-1.5',
                isDarkModeEnabled
                  ? darkModeColors.surfaces.elevation1
                  : 'bg-gray-50',
              )}
            >
              <button
                className="mr-4 text-gray-800 opacity-75 disabled:opacity-25"
                onClick={handleToggleCollapse}
              >
                {isGroupCollapsed ? (
                  <IconChevronRight
                    size={16}
                    color={
                      isDarkModeEnabled
                        ? darkModeColors.icons.primary
                        : 'currentColor'
                    }
                  />
                ) : (
                  <IconChevronDown
                    size={16}
                    color={
                      isDarkModeEnabled
                        ? darkModeColors.icons.primary
                        : 'currentColor'
                    }
                  />
                )}
              </button>
              <span
                data-testid="collection-group-header-label"
                className={
                  isDarkModeEnabled ? darkModeColors.text.secondary : ''
                }
              >
                {get(group, 'field.display', '--')}
              </span>
            </div>
          </div>
        )}
      </td>
      {columns.slice(1).map(({ labels = [], valueLabel }) => (
        <td className="whitespace-nowrap px-3 py-2 text-right">
          {valueColumns.length === 0 ? String(labels[index]) : valueLabel}
        </td>
      ))}
    </tr>
  );
};

export default PivotTableGroupHeader;
