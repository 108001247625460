import { useMemo } from 'react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import { ShortcutKeyType } from '../../../constants/shortcuts';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import ShortcutKeys from './ShortcutKeys';

interface KeyboardShortcutTooltipProps {
  buildMode?: boolean;
  children: React.ReactNode;
  delayShow?: number;
  disabled?: boolean;
  keys: ShortcutKeyType[];
  label?: string;
  offset?: number[];
  placement?: string;
}

const KeyboardShortcutTooltip = ({
  buildMode = true,
  children,
  delayShow,
  disabled = false,
  keys,
  label,
  offset = [],
  placement,
}: KeyboardShortcutTooltipProps) => {
  const { sm: isSmScreen } = useBreakpoints();
  const darkModeSurface = useDarkModeSurface();
  const surface = useMemo(
    () => (buildMode ? DARK : darkModeSurface),
    [buildMode, darkModeSurface],
  );

  return (
    <Tooltip
      content={
        <div
          className={classNames('flex items-center space-x-2', {
            'text-slate-200': surface === DARK,
          })}
        >
          {label && <span>{label}</span>}
          <ShortcutKeys buildMode={buildMode} keys={keys} surface={surface} />
        </div>
      }
      delayShow={delayShow}
      disabled={disabled || isSmScreen}
      offset={offset}
      placement={placement}
      showArrow={false}
      surface={surface}
    >
      {children}
    </Tooltip>
  );
};

export default KeyboardShortcutTooltip;
