import { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { IconMessage } from '@tabler/icons-react';
import classNames from 'classnames';
import gql from 'graphql-tag';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { useDispatch, useSelector } from 'react-redux';
import { DARK } from '@noloco/components/src/constants/surface';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import useBreakpoints, {
  breakpoints,
} from '@noloco/components/src/utils/hooks/useBreakpoints';
import useWindowSize from '@noloco/components/src/utils/hooks/useWindowSize';
import Drawer from '@noloco/ui/src/components/canvas/Drawer';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { FILE } from '../../../constants/builtInDataTypes';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { FIELD } from '../../../constants/imageTypes';
import { ESCAPE } from '../../../constants/shortcuts';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  Element,
  ElementPath,
  ViewRecordStyle,
  ViewRecordStyleType,
  ViewTab,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';
import { getPageQueryString } from '../../../queries/project';
import { setSidebarRecordViewTitle } from '../../../reducers/elements';
import { sidebarRecordViewTitleSelector } from '../../../selectors/elementsSelectors';
import {
  getDepsForField,
  transformDepsToQueryObject,
} from '../../../utils/data';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRecordPreview from '../../../utils/hooks/useRecordPreview';
import useRouter from '../../../utils/hooks/useRouter';
import { useInvalidateProjectData } from '../../../utils/hooks/useServerEvents';
import useSetQuery from '../../../utils/hooks/useSetQuery';
import useTrackAppPage, {
  PageTypes,
} from '../../../utils/hooks/useTrackAppPage';
import { getText } from '../../../utils/lang';
import { PermissionConfig } from '../../../utils/permissions';
import { getDepsForViewSections } from '../../../utils/sectionDependencies';
import { filterSections } from '../../../utils/tabs';
import { getPageLoadingScope, getPageScope } from '../../Page';
import RecordComments from './RecordComments';
import RecordViewBody from './RecordViewBody';
import { getTitleValue } from './RecordViewTitle';

interface RecordViewProps {
  actionButtons?: ActionButton[];
  className?: string;
  comments?: any;
  dataType: DataType;
  doneButtonText?: any;
  editButton?: any;
  editButtonText?: any;
  editorMode?: boolean;
  element: Element;
  elementPath: ElementPath;
  headerWidth?: number | string;
  hideBreadcrumbs?: boolean;
  hideEditButton?: boolean;
  icon?: any;
  image?: any;
  isSplitLayout?: boolean;
  name?: string;
  onClick?: () => void;
  permissions: PermissionConfig;
  project: Project;
  recordId?: string;
  recordStyle?: ViewRecordStyle;
  rootPathname: string | null;
  scope: any;
  sections?: any[];
  subtitle?: any;
  tabs?: ViewTab[];
  title?: any;
  viewRoutePrefix?: string;
}

const SCROLL_THRESHOLD = 200;
const LANG_KEY = 'core.COMMENT';

const RecordView = ({
  actionButtons = [],
  className,
  comments = {},
  dataType,
  doneButtonText,
  editButton = {},
  editButtonText,
  editorMode = false,
  element,
  elementPath,
  headerWidth,
  hideBreadcrumbs,
  hideEditButton,
  icon,
  image,
  isSplitLayout = false,
  name,
  onClick,
  permissions,
  project,
  recordId,
  recordStyle,
  rootPathname,
  scope,
  sections = [],
  subtitle,
  tabs = [],
  title,
  viewRoutePrefix,
}: RecordViewProps) => {
  const { sm: isSmScreen } = useBreakpoints();
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const surface = useDarkModeSurface();
  const recordViewTitle = useSelector(sidebarRecordViewTitleSelector);
  const [showRecordTitle, setShowRecordTitle] = useState(false);
  const { user } = useAuthWrapper();
  const backLink = useBackLink(project);
  const {
    push,
    query: {
      _comments: commentsParam,
      _commentsFooter: commentsFooterParam,
      recordId: queryRecordId,
      tab,
    },
    replaceQueryParams,
  } = useRouter();
  const [preview, toggleRecordPreview] = useRecordPreview();
  const selectedTab = useMemo(() => {
    const defaultTab = first(tabs);
    const activeTab = tabs && tabs.find((t: any) => kebabCase(t.title) === tab);

    if (activeTab === defaultTab) {
      return null;
    }

    return activeTab;
  }, [tab, tabs]);

  useTrackAppPage(PageTypes.RECORD);

  const commentsAreOpen = useMemo(
    () =>
      comments.show &&
      (preview ? commentsFooterParam : commentsParam) === 'true',
    [comments.show, commentsFooterParam, commentsParam, preview],
  );

  useEffect(() => {
    if (
      width &&
      width > breakpoints[SM] &&
      commentsParam === undefined &&
      comments.openByDefault &&
      comments.show
    ) {
      replaceQueryParams({
        [preview ? '_commentsFooter' : '_comments']: 'true',
      });
    }
  }, [width, commentsParam, comments, replaceQueryParams, preview]);

  const fieldPermissionsEnabled = useIsFeatureEnabled(FIELD_LEVEL_PERMISSIONS);

  const dependencies = useMemo(() => {
    const tabSections = filterSections(tabs, tabs, sections, selectedTab);
    const mappedSections = tabSections.map(
      (tabSection: any) => tabSection.section,
    );

    const deps = getDepsForViewSections(
      element,
      mappedSections,
      project.dataTypes,
      dataType,
      user as User,
      actionButtons,
      tabs,
      title,
      fieldPermissionsEnabled,
    );

    if (image && image.type === FIELD && image.fieldName) {
      const fileField = dataType.fields.getByName(image.fieldName);

      if (fileField && fileField.type === FILE) {
        const fileFieldDeps = getDepsForField(
          fileField,
          project.dataTypes,
          element.id,
        );
        deps.push(...fileFieldDeps);
      }
    }

    deps.push({
      id: `${element.id}:VIEW`,
      path: 'nolocoCommentsCollection.totalCount',
    });

    return deps.filter((dep: any) => dep.path);
  }, [
    actionButtons,
    dataType,
    element,
    fieldPermissionsEnabled,
    image,
    project.dataTypes,
    sections,
    selectedTab,
    tabs,
    title,
    user,
  ]);

  const pageId = `${element.id}:VIEW`;
  const elementWithUpdatedId = useMemo(
    () => set('id', pageId, element),
    [element, pageId],
  );

  const rawValue = recordId || queryRecordId;
  const variables = useMemo(
    () => ({
      uuid: rawValue,
    }),
    [rawValue],
  );

  const gqlQueryString = useMemo(
    () =>
      getPageQueryString(
        dataType.apiName,
        { uuid: rawValue },
        transformDepsToQueryObject(dataType, project.dataTypes, dependencies),
      ),
    [dataType, dependencies, project.dataTypes, rawValue],
  );

  const queryObject = useMemo(
    () => ({
      id: elementWithUpdatedId.id,
      variables,
      dataType: dataType.apiName,
      dataProperty: 'uuid',
      type: elementWithUpdatedId.type,
      query: gqlQueryString,
    }),
    [
      dataType.apiName,
      elementWithUpdatedId.id,
      elementWithUpdatedId.type,
      gqlQueryString,
      variables,
    ],
  );
  useSetQuery(queryObject);

  const { loading, error, data, refetch } = useQuery(
    gql`
      ${gqlQueryString}
    `,
    {
      variables,
      errorPolicy: 'all',
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );
  useInvalidateProjectData(refetch);

  const pageData = useMemo(
    () => get(data, dataType.apiName),
    [data, dataType.apiName],
  );

  if (error) {
    console.log(error);
  }

  const pageScope = useMemo(
    () =>
      pageData && getPageScope(false, pageData, dataType, project.dataTypes),
    [dataType, pageData, project.dataTypes],
  );
  const nextScope = useMemo(
    () => getPageLoadingScope(scope, elementWithUpdatedId.id, loading),
    [elementWithUpdatedId, loading, scope],
  );
  const recordScope = useMemo(
    () => ({
      [pageId]: {
        _dataType: dataType.name,
        ...pageScope,
      },
    }),
    [dataType.name, pageId, pageScope],
  );

  const titleField = useMemo(
    () => title && dataType && dataType.fields.getByName(title),
    [dataType, title],
  );

  const titleValue = useMemo(
    () => getTitleValue(titleField, pageScope),
    [pageScope, titleField],
  );

  const handleScrollCapture = useCallback(
    (event: any) => {
      if (isSmScreen && event.target.scrollTop >= SCROLL_THRESHOLD) {
        if (!recordViewTitle && !showRecordTitle) {
          setShowRecordTitle(true);
          dispatch(setSidebarRecordViewTitle({ title: titleValue }));
        }
      } else {
        setShowRecordTitle(false);
        dispatch(setSidebarRecordViewTitle(null));
      }
    },
    [isSmScreen, dispatch, recordViewTitle, titleValue, showRecordTitle],
  );

  const recordComments = useMemo(
    () =>
      commentsAreOpen &&
      pageScope &&
      comments.show && (
        <RecordComments
          allowAttachments={comments.allowAttachments}
          dataType={dataType}
          openByDefault={comments.openByDefault}
          project={project}
          record={pageScope}
        />
      ),
    [comments, commentsAreOpen, dataType, pageScope, project],
  );

  const commentCount = useMemo(
    () => get(pageScope, 'nolocoCommentsCollection.totalCount', 0),
    [pageScope],
  );

  const handleDrawerClose = useCallback(() => {
    toggleRecordPreview();

    if (backLink?.to) {
      push(backLink.to);
    } else if (viewRoutePrefix) {
      push(viewRoutePrefix);
    }
  }, [push, viewRoutePrefix, backLink, toggleRecordPreview]);

  const handleDrawerOpen = useCallback(
    () => toggleRecordPreview(true),
    [toggleRecordPreview],
  );

  const recordViewBody = useMemo(
    () => (
      <div
        className={classNames('w-full', className, {
          'pl-2': !!commentsParam,
          'sm:overflow-y-auto': !editorMode,
        })}
        data-testid="record-view"
        onClick={onClick}
        onScrollCapture={handleScrollCapture}
      >
        <div className="mx-auto flex w-full flex-grow flex-col items-center pb-32">
          <RecordViewBody
            actionButtons={actionButtons}
            comments={comments}
            data={pageScope}
            dataType={dataType}
            doneButtonText={doneButtonText}
            editButtonText={editButtonText}
            editButtonVisibilityRules={(editButton as any).visibilityRules}
            element={element}
            elementPath={elementPath}
            handleDrawerClose={handleDrawerClose}
            headerWidth={headerWidth}
            hideBreadcrumbs={hideBreadcrumbs}
            hideEditButton={hideEditButton}
            icon={icon}
            image={image}
            isSplitLayout={isSplitLayout}
            loading={loading}
            name={name}
            pageId={pageId}
            permissions={permissions}
            project={project}
            recordId={recordId}
            recordScope={recordScope}
            refetch={refetch}
            rootPathname={rootPathname}
            scope={nextScope}
            sections={sections}
            showRecordTitle={showRecordTitle}
            subtitle={subtitle}
            tabs={tabs}
            title={title}
            viewRoutePrefix={viewRoutePrefix}
          />
        </div>
      </div>
    ),
    [
      actionButtons,
      className,
      comments,
      commentsParam,
      dataType,
      doneButtonText,
      editButton,
      editButtonText,
      editorMode,
      element,
      elementPath,
      handleDrawerClose,
      handleScrollCapture,
      headerWidth,
      hideBreadcrumbs,
      hideEditButton,
      icon,
      image,
      isSplitLayout,
      loading,
      name,
      nextScope,
      onClick,
      pageId,
      pageScope,
      permissions,
      project,
      recordId,
      recordScope,
      refetch,
      rootPathname,
      sections,
      showRecordTitle,
      subtitle,
      tabs,
      title,
      viewRoutePrefix,
    ],
  );

  const toggleComments = useCallback(
    () =>
      replaceQueryParams({
        [preview ? '_commentsFooter' : '_comments']: recordComments
          ? undefined
          : 'true',
      }),
    [preview, recordComments, replaceQueryParams],
  );

  const footer = useMemo(
    () =>
      comments.show && (
        <div className="flex h-10 w-full items-center">
          {recordComments ? (
            cloneElement(recordComments, { recordStyle })
          ) : (
            <div
              className={classNames(
                'flex h-full w-full cursor-pointer items-center justify-between px-4 text-sm',
                {
                  'text-slate-400 hover:bg-slate-200': surface === LIGHT,
                  'text-slate-400 hover:bg-slate-800': surface === DARK,
                },
              )}
              onClick={toggleComments}
            >
              <span className="flex items-center gap-2">
                <IconMessage size={16} />
                {getText(LANG_KEY, 'new.placeholderReply')}
              </span>
              <span>
                {getText(
                  { context: commentCount },
                  LANG_KEY,
                  commentCount > 0 ? 'title' : 'empty',
                )}
              </span>
            </div>
          )}
        </div>
      ),
    [
      commentCount,
      comments.show,
      recordComments,
      recordStyle,
      surface,
      toggleComments,
    ],
  );

  useOnKeyPress(ESCAPE, toggleComments, {
    enabled:
      recordStyle?.type === ViewRecordStyleType.SIDE_PANEL &&
      !editorMode &&
      !!recordComments,
  });

  if (error || (!pageData && !loading)) {
    return (
      <div className="absolute left-0 right-0 top-0 p-8">
        <div className="w-full rounded border border-red-300 bg-red-200 p-4 text-red-900">
          {error && <pre>{String(error)}</pre>}
          {!pageData && (
            <span>
              {getText({ dataType: dataType.display }, 'errors.notFound')}
            </span>
          )}
        </div>
      </div>
    );
  }

  if (recordStyle?.type === ViewRecordStyleType.SIDE_PANEL && !editorMode) {
    return (
      <Drawer
        disableCloseOnEscape={!!recordComments}
        footer={footer}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        showHeader={false}
        size={recordStyle?.size ?? MD}
        useDarkTheme={false}
        usePortal={true}
      >
        <div className={classNames({ 'pb-20': commentsAreOpen })}>
          {recordViewBody}
        </div>
      </Drawer>
    );
  }

  return (
    <>
      {recordViewBody}
      {recordComments}
    </>
  );
};

export default RecordView;
