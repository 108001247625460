export const FILTER = 'FILTER';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SLACK_USER_NOTIFICATION = 'SLACK_USER_NOTIFICATION';
export const SLACK_CHANNEL_NOTIFICATION = 'SLACK_CHANNEL_NOTIFICATION';
export const FOR_EACH = 'FOR_EACH';
export const WEBHOOK = 'WEBHOOK';
export const FIND_RECORD = 'FIND_RECORD';
export const CREATE_RECORD = 'CREATE_RECORD';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const CREATE_NOTE = 'CREATE_NOTE';
export const OPEN_AI_CHAT_COMPLETION = 'OPEN_AI_CHAT_COMPLETION';
export const OPEN_AI_CORRECT_GRAMMAR = 'OPEN_AI_CORRECT_GRAMMAR';
export const OPEN_AI_KEYWORD_EXTRACTION = 'OPEN_AI_KEYWORD_EXTRACTION';
export const OPEN_AI_SENTIMENT_ANALYSIS = 'OPEN_AI_SENTIMENT_ANALYSIS';
export const OPEN_AI_SUMMARIZATION = 'OPEN_AI_SUMMARIZATION';
export const OPEN_AI_TEXT_COMPLETION = 'OPEN_AI_TEXT_COMPLETION';
export const DOCS_AUTOMATOR_CREATE = 'DOCS_AUTOMATOR_CREATE';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const SEND_INVITATION_EMAIL = 'SEND_INVITATION_EMAIL';

export const PREMIUM_WORKFLOW_ACTIONS: OpenAIWorkflowActionType[] = [
  OPEN_AI_CORRECT_GRAMMAR,
  OPEN_AI_KEYWORD_EXTRACTION,
  OPEN_AI_SENTIMENT_ANALYSIS,
  OPEN_AI_TEXT_COMPLETION,
];

export type DocsAutomatorWorkflowActionType = 'DOCS_AUTOMATOR_CREATE';

export type OpenAIWorkflowActionType =
  | 'OPEN_AI_CHAT_COMPLETION'
  | 'OPEN_AI_CORRECT_GRAMMAR'
  | 'OPEN_AI_KEYWORD_EXTRACTION'
  | 'OPEN_AI_SENTIMENT_ANALYSIS'
  | 'OPEN_AI_SUMMARIZATION'
  | 'OPEN_AI_TEXT_COMPLETION';

export const OPEN_AI_WORKFLOW_ACTIONS: OpenAIWorkflowActionType[] = [
  OPEN_AI_CHAT_COMPLETION,
  OPEN_AI_SUMMARIZATION,
  ...PREMIUM_WORKFLOW_ACTIONS,
];

export type SlackWorkflowActionType =
  | 'SLACK_USER_NOTIFICATION'
  | 'SLACK_CHANNEL_NOTIFICATION';
export const SLACK_WORKFLOW_ACTIONS: SlackWorkflowActionType[] = [
  SLACK_CHANNEL_NOTIFICATION,
  SLACK_USER_NOTIFICATION,
];

export type NolocoWorkflowActionType =
  | 'FILTER'
  | 'SEND_EMAIL'
  | 'SEND_NOTIFICATION'
  | 'FOR_EACH'
  | 'WEBHOOK'
  | 'FIND_RECORD'
  | 'CREATE_RECORD'
  | 'UPDATE_RECORD'
  | 'DELETE_RECORD'
  | 'CREATE_COMMENT'
  | 'CREATE_NOTE'
  | 'DEACTIVATE_USER'
  | 'SEND_INVITATION_EMAIL';

export const NOLOCO_WORKFLOW_ACTIONS = [
  SEND_EMAIL,
  SEND_NOTIFICATION,
  WEBHOOK,
  FIND_RECORD,
  CREATE_RECORD,
  UPDATE_RECORD,
  DELETE_RECORD,
  FILTER,
  FOR_EACH,
  DEACTIVATE_USER,
  SEND_INVITATION_EMAIL,
];

export const COMMENT_WORKFLOW_ACTIONS = [CREATE_COMMENT, CREATE_NOTE];

const workflowActionTypes = [
  ...OPEN_AI_WORKFLOW_ACTIONS,
  ...NOLOCO_WORKFLOW_ACTIONS,
  ...COMMENT_WORKFLOW_ACTIONS,
  ...SLACK_WORKFLOW_ACTIONS,
  DOCS_AUTOMATOR_CREATE,
];

export type WorkflowActionType =
  | NolocoWorkflowActionType
  | OpenAIWorkflowActionType
  | SlackWorkflowActionType
  | DocsAutomatorWorkflowActionType;

export default workflowActionTypes;
