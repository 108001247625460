import { memo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Theme, getColorShade } from '@noloco/components';
import { TABLE } from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { DataField } from '../../../../models/DataTypeFields';
import DataTypePermissions from '../../../../models/DataTypePermissions';
import { DataType } from '../../../../models/DataTypes';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormFieldConfig } from '../../../../models/View';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import FieldCell from '../FieldCell';

const CELL_WIDTH_OFFSET = 16;

interface TableFieldCellProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  config: FormFieldConfig;
  dataType: DataType;
  elementId: string;
  field: DataField;
  handleCheckboxChange: (checked: { target: { checked: boolean } }) => void;
  isFirst: boolean;
  isLast: boolean;
  isRowChecked?: boolean;
  isSticky: boolean;
  parent?: DataField;
  permissions?: DataTypePermissions;
  project?: Project;
  record?: BaseRecord;
  rowLink?: string;
  selectedRows?: BaseRecord[];
  theme: Theme;
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  width?: number;
}

const TableFieldCell = memo(
  ({
    backLink,
    bulkActionsEnabled,
    config,
    dataType,
    elementId,
    field,
    handleCheckboxChange,
    isFirst,
    isLast,
    isRowChecked = false,
    isSticky,
    parent,
    permissions,
    project,
    record,
    rowLink,
    selectedRows = [],
    theme,
    transformRecordScope,
    width,
  }: TableFieldCellProps) => {
    const Row = rowLink ? Link : 'div';
    const [isDarkModeEnabled] = useDarkMode();
    const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
      handleCheckboxChange,
      isRowChecked,
      selectedRows,
    );

    const primaryColor = theme.brandColors.primary;

    return (
      <td
        className={classNames('max-w-sm py-2', {
          [`bg-${getColorShade(
            primaryColor,
            100,
          )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
            isRowChecked,
          [`${bulkActionsEnabled ? 'left-10' : 'left-0'} sticky z-10 ${
            isDarkModeEnabled
              ? `${darkModeColors.surfaces.elevation1} border-r ${darkModeColors.borders.one}`
              : 'shadow-r bg-white'
          }`]: isSticky,
          'pl-6 pr-3': isFirst,
          'pr-6': isLast,
          'px-3': !isLast && !isFirst,
        })}
        style={
          width
            ? {
                width: `${width + CELL_WIDTH_OFFSET}px`,
                maxWidth: `${width + CELL_WIDTH_OFFSET}px`,
              }
            : undefined
        }
      >
        {/* @ts-expect-error TS(2322): Type '{ children: string; className: string; edito... Remove this comment to see the full error message */}
        <Row className="block" to={rowLink} onClick={selectRecordOnCmdClick}>
          <FieldCell
            backLink={backLink}
            className="block"
            showLabel={false}
            config={config}
            dataType={dataType}
            field={field}
            transformScope={transformRecordScope}
            layout={TABLE}
            permissions={permissions}
            record={record}
            value={get(record, getValuePathForFieldConfig(field, parent))}
            project={project}
            key={field.name}
            skipResolvingForValueIds={[`${elementId}:RECORD`]}
            bulkActionsEnabled={bulkActionsEnabled}
            isRowChecked={isRowChecked}
            selectedRows={selectedRows}
          />
        </Row>
      </td>
    );
  },
);

export default TableFieldCell;
