import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import { darkModeColors } from '../../constants/darkModeColors';
import useDarkMode from '../../utils/hooks/useDarkMode';
import Icon from '../Icon';
import HelpText from './HelpText';

const Highlights = forwardRef(
  (
    // @ts-expect-error TS(2339): Property 'highlights' does not exist on type '{}'.
    { highlights, className, editorMode, onClick, sectionWidth, theme },
    ref,
  ) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const [isDarkModeEnabled] = useDarkMode();

    const num = highlights.length;
    const useDynamicWidth = highlights.every(
      (highlight: any) => highlight.columnWidth === 3 || !highlight.columnWidth,
    );

    const isSmallContainer = !!sectionWidth && sectionWidth <= 6;

    const is3x = num % 3 === 0 || num % 5 === 0 || num % 7 === 0;
    const is4x = num % 4 === 0;
    const gridClasses = {
      'grid-cols-12 md:grid-cols-1': !useDynamicWidth,
      'grid-cols-1 md:grid-cols-1': useDynamicWidth && num === 1,
      'grid-cols-2 md:grid-cols-1':
        useDynamicWidth &&
        (num % 2 === 0 || (isSmallContainer && (is3x || is4x))),
      'grid-cols-3 md:grid-cols-1':
        !isSmallContainer && useDynamicWidth && is3x,
      'grid-cols-4 md:grid-cols-2':
        !isSmallContainer && useDynamicWidth && is4x,
      'lg:grid-cols-1': isSmallContainer,
    };

    return (
      <div
        className={classNames('grid w-auto gap-4', gridClasses, className)}
        onClick={onClick}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
        data-testid="highlights-section"
      >
        {highlights.map((highlight: any, index: any) => (
          <div
            className={classNames(
              'flex items-start overflow-hidden rounded-lg p-6 shadow',
              `${
                isDarkModeEnabled
                  ? `border ${darkModeColors.borders.one} ${
                      highlight.bg
                        ? `bg-${highlight.bg}-800`
                        : `${darkModeColors.surfaces.elevation1} `
                    }`
                  : `bg-${highlight.bg ? `${highlight.bg}-100` : 'white'}`
              }`,
              !useDynamicWidth ? highlight.className : null,
              `highlight highlight-${highlight.id}`,
            )}
            key={index}
          >
            {highlight.icon && highlight.icon.name && (
              <Box
                className="mr-3 mt-1 h-10 w-10 flex-shrink-0 rounded-lg p-2"
                bg={getColorShade(secondaryColor, 600)}
                text={getColorShade(secondaryColor, 100)}
              >
                <Icon
                  icon={highlight.icon}
                  size={20}
                  className="bg-opacity-75"
                />
              </Box>
            )}
            <div className="flex w-full flex-col justify-center overflow-hidden">
              {highlight.label && (
                <span
                  data-testid="highlight-label"
                  className={classNames(
                    'mb-1 truncate text-sm tracking-wider',
                    {
                      'text-white-400': isDarkModeEnabled || !highlight.bg,
                      'text-black': !isDarkModeEnabled && highlight.bg,
                    },
                  )}
                >
                  {highlight.label}
                </span>
              )}
              {highlight.helpText && <HelpText>{highlight.helpText}</HelpText>}
              <span
                data-testid="highlight-value"
                className={`break-words text-lg font-medium tracking-wider ${
                  isDarkModeEnabled
                    ? darkModeColors.text.secondary
                    : 'text-gray-900'
                }`}
              >
                {highlight.text || (editorMode ? `Highlight ${index + 1}` : '')}
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  },
);

export default withTheme(Highlights);
