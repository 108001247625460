import { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconEdit } from '@tabler/icons-react';
import { FormField, Loader, Modal } from '@noloco/components';
import { BELOW_SOLID } from '@noloco/components/src/components/form/errorPositions';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import { UPDATE_DATA_TYPE_NAME } from '../../../queries/project';
import { useUpdateDataTypeName } from '../../../utils/hooks/useUpdateDataTypes';

interface EditCollectionNameModalProps {
  dataType: DataType;
  project: Project;
  onClose: () => void;
}

const LANG_KEY = 'leftSidebar.data.edit';

const EditCollectionNameModal = ({
  dataType,
  project,
  onClose,
}: EditCollectionNameModalProps) => {
  const updateDataTypeName = useUpdateDataTypeName();
  const [displayName, setDisplayName] = useState(dataType?.display);
  const [description, setDescription] = useState(dataType.description);
  const [loading, setLoading] = useState(false);
  const [updateDataTypeNameMutation] = useMutation(UPDATE_DATA_TYPE_NAME);

  const isFormDirty = useMemo(
    () =>
      displayName !== dataType.display || description !== dataType.description,
    [displayName, description, dataType],
  );
  const isDisplayNameUnique = useMemo(
    () =>
      !project.dataTypes.some(
        (dt) => dt.display === displayName && dt.id !== dataType.id,
      ),
    [project.dataTypes, displayName, dataType.id],
  );

  const isDisplayNameValid = useMemo(
    () => !!displayName && displayName !== '' && isDisplayNameUnique,
    [displayName, isDisplayNameUnique],
  );

  const handleUpdateDataTypeName = useCallback(() => {
    setLoading(true);
    updateDataTypeNameMutation({
      variables: {
        projectName: project.name,
        dataTypeId: dataType.id,
        display: displayName,
        description,
      },
    })
      .then(({ data }) => {
        if (data.updateDataTypeName) {
          updateDataTypeName(data.updateDataTypeName);
          onClose();
        }
      })
      .finally(() => setLoading(false));
  }, [
    dataType.id,
    description,
    displayName,
    onClose,
    project.name,
    updateDataTypeName,
    updateDataTypeNameMutation,
  ]);

  return (
    <Modal
      icon={loading ? <Loader size="sm" /> : <IconEdit size={18} />}
      title={getText(LANG_KEY, 'modalTitle')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleUpdateDataTypeName}
      confirmText={loading ? <Loader size="sm" /> : getText(LANG_KEY, 'done')}
      confirmDisabled={loading || !isDisplayNameValid || !isFormDirty}
      variant={PRIMARY}
    >
      <FormField
        label={getText(LANG_KEY, 'name')}
        className="mb-4"
        errorMessage={isDisplayNameUnique ? null : getText(LANG_KEY, 'error')}
        errorType={BELOW_SOLID}
        placeholder={getText(LANG_KEY, 'namePlaceholder')}
        value={displayName}
        onChange={({ target: { value } }: any) => {
          setDisplayName(value);
        }}
        autoFocus={true}
      />
      <FormField
        inputType={'text-area'}
        label={getText(LANG_KEY, 'description')}
        placeholder={getText(LANG_KEY, 'descriptionPlaceholder')}
        value={description}
        onChange={({ target: { value } }: { target: { value: string } }) => {
          setDescription(value);
        }}
        maxLength={250}
      />
    </Modal>
  );
};

export default EditCollectionNameModal;
