import { useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { Theme, getColorShade } from '@noloco/components';
import {
  CARD,
  CardStyle,
  SIMPLE,
} from '@noloco/components/src/constants/cardStyles';
import {
  LG,
  MD,
  SM,
  ShirtSize,
  XL,
} from '@noloco/components/src/constants/tShirtSizes';
import {
  BOARD,
  CALENDAR,
  CARDS,
  COLUMNS,
  CollectionLayout,
  GANTT,
  LAYOUT_SUPPORTS_RECORD_COLORING,
  MAP,
  TIMELINE,
} from '../../../../constants/collectionLayouts';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { DataType } from '../../../../models/DataTypes';
import { ActionButton } from '../../../../models/Element';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormConfigWithField, GroupByWithField } from '../../../../models/View';
import { getColorBasedOnConditions } from '../../../../utils/colors';
import { getImagesFromFieldConfig } from '../../../../utils/files';
import useDarkMode from '../../../../utils/hooks/useDarkMode';
import useSelectRecordOnCmdClick from '../../../../utils/hooks/useSelectRecordOnCmdClick';
import { cardSizeWidthMap } from '../../Collection';
import CollectionCardGallery from '../CollectionCardGallery';
import CardFieldCell from './CardFieldCell';

interface CardRecordLayoutProps {
  actionButtons?: ActionButton[];
  bulkActionsEnabled?: boolean;
  cardSize?: ShirtSize;
  cardStyle?: CardStyle;
  children?: React.ReactNode;
  className?: string;
  dataType: DataType;
  element: Element;
  elementId: string;
  fieldConfigs: FormConfigWithField[];
  groupByFields?: GroupByWithField[];
  handleCheckboxChange: (checked: { target: { checked: boolean } }) => void;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  project: Project;
  record: BaseRecord;
  rowLink: string;
  scope: Record<string, any>;
  selectedRows?: BaseRecord[];
  showCardHeroImage: boolean;
  theme: Theme;
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
}
const cardSizeHeroImageSizeMap = {
  [SM]: 24,
  [MD]: 36,
  [LG]: 48,
  [XL]: 64,
};

const CardRecordLayout = ({
  actionButtons,
  bulkActionsEnabled = false,
  cardSize = LG,
  cardStyle = CARD,
  children,
  className,
  dataType,
  element,
  elementId,
  fieldConfigs,
  groupByFields = [],
  handleCheckboxChange,
  isRowChecked = false,
  layout,
  project,
  record,
  rowLink,
  scope,
  selectedRows = [],
  showCardHeroImage,
  theme,
  transformRecordScope,
}: CardRecordLayoutProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const selectRecordOnCmdClick = useSelectRecordOnCmdClick(
    handleCheckboxChange,
    isRowChecked,
    selectedRows,
  );

  const colorConditions = useMemo(
    () => get(element, ['props', 'recordColoring'], []),
    [element],
  );
  const conditionBasedColor = useMemo(
    () => getColorBasedOnConditions(colorConditions, scope, project),
    [colorConditions, scope, project],
  );

  const primaryColor = theme.brandColors.primary;

  const images = useMemo(
    () => getImagesFromFieldConfig(fieldConfigs, record, showCardHeroImage),
    [fieldConfigs, record, showCardHeroImage],
  );

  const Row = rowLink ? Link : 'div';

  const backgroundColor = isDarkModeEnabled
    ? darkModeColors.surfaces.elevation1
    : 'bg-white';

  const hover = `hover:${
    isDarkModeEnabled ? darkModeColors.surfaces.elevation2 : 'bg-gray-100'
  }`;

  const cardBorderColor = isDarkModeEnabled
    ? darkModeColors.borders.one
    : 'border-gray-200';

  return (
    // @ts-expect-error TS(2322): Type 'ForwardedRef<HTMLDivElement>' is not assignable to type '((string | ((instance: HTMLAnchorElement | null) => void) | RefObject<HTMLAnchorElement>) & (string | ((instance: HTMLDivElement | null) => void) | RefObject<...>)) | null | undefined'
    <Row
      to={rowLink}
      onClick={selectRecordOnCmdClick}
      className={classNames(className, 'group relative flex overflow-hidden', {
        'h-full w-full flex-col items-center sm:flex-wrap':
          layout === CARDS || layout === BOARD,
        [`relative mb-3 max-w-full flex-shrink-0 flex-wrap text-xs ${groupByFields.length > 0 ? 'w-full' : cardSizeWidthMap[cardSize]}`]:
          layout === COLUMNS,
        [`border ${cardBorderColor} rounded-lg shadow-lg`]: ![
          CALENDAR,
          TIMELINE,
          GANTT,
          MAP,
        ].includes(layout),
        'border-0 shadow-none':
          [CARDS, COLUMNS].includes(layout) && cardStyle === SIMPLE,
        'flex w-screen max-w-xs flex-col sm:max-w-full': [
          CALENDAR,
          TIMELINE,
          GANTT,
        ].includes(layout),
        [darkModeColors.text.primary]: isDarkModeEnabled,
        [backgroundColor && hover]: !conditionBasedColor,
        [`bg-${getColorShade(
          primaryColor,
          100,
        )} bg-opacity-75 dark:bg-${getColorShade(primaryColor, 900)}`]:
          isRowChecked,
        [`bg-${getColorShade(
          conditionBasedColor,
          50,
        )} hover:bg-${getColorShade(conditionBasedColor, 100)}`]:
          conditionBasedColor &&
          !isDarkModeEnabled &&
          LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
        [`bg-${getColorShade(
          conditionBasedColor,
          900,
        )} hover:bg-${getColorShade(conditionBasedColor, 800)}`]:
          conditionBasedColor &&
          isDarkModeEnabled &&
          LAYOUT_SUPPORTS_RECORD_COLORING.includes(layout),
      })}
      data-testid="collection-record"
    >
      {showCardHeroImage && images && (
        <CollectionCardGallery
          h={cardSizeHeroImageSizeMap[cardSize]}
          image={{ hidden: false }}
          images={images}
          index={0}
        />
      )}
      <div className="flex w-full flex-col space-y-4 p-4 text-xs">
        {children}
        {fieldConfigs.map(
          ({ field, parent, parentFieldType, config, permissions }, index) =>
            (index > 0 || !(showCardHeroImage && images)) && (
              <CardFieldCell
                bulkActionsEnabled={bulkActionsEnabled}
                config={config}
                dataType={parentFieldType || dataType}
                elementId={elementId}
                field={field}
                isRowChecked={isRowChecked}
                key={`${field.name}:${(parentFieldType || dataType).name}`}
                parent={parent}
                permissions={permissions}
                project={project}
                record={record}
                selectedRows={selectedRows}
                transformRecordScope={transformRecordScope}
              />
            ),
        )}
      </div>
      {actionButtons}
    </Row>
  );
};

export default withTheme(CardRecordLayout);
