import { memo } from 'react';
import get from 'lodash/get';
import { CollectionLayout } from '../../../../constants/collectionLayouts';
import { DataField } from '../../../../models/DataTypeFields';
import DataTypePermissions from '../../../../models/DataTypePermissions';
import { DataType } from '../../../../models/DataTypes';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormFieldConfig } from '../../../../models/View';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import FieldCell from '../FieldCell';

interface RowFieldCellProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  config: FormFieldConfig;
  dataType: DataType;
  elementId: string;
  field: DataField;
  isRowChecked?: boolean;
  layout: CollectionLayout;
  parent?: DataField;
  permissions?: DataTypePermissions;
  project?: Project;
  record?: BaseRecord;
  selectedRows?: BaseRecord[];
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
}

const RowFieldCell = memo(
  ({
    backLink,
    bulkActionsEnabled,
    config,
    dataType,
    elementId,
    field,
    isRowChecked,
    layout,
    parent,
    permissions,
    project,
    record,
    selectedRows = [],
    transformRecordScope,
  }: RowFieldCellProps) => (
    <FieldCell
      backLink={backLink}
      bulkActionsEnabled={bulkActionsEnabled}
      className="flex min-w-0 max-w-full flex-col"
      config={config}
      dataType={dataType}
      field={field}
      isRowChecked={isRowChecked}
      key={field.name}
      layout={layout}
      permissions={permissions}
      project={project}
      record={record}
      selectedRows={selectedRows}
      showLabel={true}
      skipResolvingForValueIds={[`${elementId}:RECORD`]}
      transformScope={transformRecordScope}
      value={get(record, getValuePathForFieldConfig(field, parent))}
    />
  ),
);

export default RowFieldCell;
