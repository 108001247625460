import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { Theme } from '../../models';
import { getColorShade } from '../../utils/colors';
import { ROUNDED_FULL } from '../button/buttonStyles';
import {
  BOLD,
  BORDERED,
  FULL_BACKGROUND,
  FULL_BACKGROUND_BORDER,
  ROUNDED,
  VerticalNavType,
} from './verticalNavTypes';

export const typeStyles = (color: string) => ({
  [FULL_BACKGROUND]: {
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(color, 600),
    text: getColorShade(color, 200),
  },
  [FULL_BACKGROUND_BORDER]: {
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(color, 600),
    text: getColorShade(color, 200),
  },
  [ROUNDED]: {
    rounded: 'lg',
    m: { x: 3, y: 0.5 },
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(color, 600),
    text: getColorShade(color, 200),
  },
  [ROUNDED_FULL]: {
    rounded: 'full',
    m: { x: 3, y: 0.5 },
    p: { x: 3, y: 0.5 },
    'bg-hocus': getColorShade(color, 600),
    text: ['xs', getColorShade(color, 200)],
  },
  [BOLD]: {
    p: { x: 3, y: 2 },
    'bg-hocus': getColorShade(color, 600),
    text: getColorShade(color, 200),
  },
  [BORDERED]: {
    p: { x: 3, y: 3 },
    borderB: 2,
    'bg-hocus': getColorShade(color, 600),
    text: getColorShade(color, 200),
  },
});

const activeTypeStyles = (color: string) => ({
  [FULL_BACKGROUND]: {
    bg: getColorShade(color, 900),
    'bg-hocus': 'gray',
    text: 'white',
  },
  [FULL_BACKGROUND_BORDER]: {
    bg: getColorShade(color, 900),
    border: 'white',
    borderL: 4,
    p: { x: 5, y: 0.5 },
    'bg-hocus': 'gray',
    text: 'white',
  },
  [ROUNDED]: {
    bg: getColorShade(color, 900),
    'bg-hocus': undefined,
    rounded: 'lg',
    text: 'white',
  },
  [ROUNDED_FULL]: {
    bg: getColorShade(color, 900),
    'bg-hocus': undefined,
    rounded: 'full',
    text: 'white',
  },
  [BOLD]: { font: 'bold', text: 'white' },
  [BORDERED]: { bg: getColorShade(color, 900), text: 'white' },
});

interface VerticalNavItemProps {
  active?: boolean;
  className?: string;
  is: string;
  onClick: () => void;
  theme: Theme;
  to: string;
  type?: VerticalNavType;
  primaryColor?: string;
  children: React.ReactNode;
}

const VerticalNavItem = forwardRef<HTMLAnchorElement, VerticalNavItemProps>(
  (
    {
      active,
      children,
      className,
      is,
      onClick,
      theme,
      to,
      type = FULL_BACKGROUND,
      primaryColor = theme.brandColorGroups.primary,
      ...rest
    },
    ref,
  ) => {
    const styles = typeStyles(primaryColor)[type];
    const activeStyles = active ? activeTypeStyles(primaryColor)[type] : {};

    return (
      <Box
        className={classNames(className, 'cursor-pointer')}
        onClick={onClick}
        ref={ref}
        is={is}
        to={to}
        {...styles}
        {...activeStyles}
        {...rest}
      >
        {children}
      </Box>
    );
  },
);

VerticalNavItem.defaultProps = {
  active: false,
  className: '',
  is: 'a',
  type: FULL_BACKGROUND,
};

export default withTheme(VerticalNavItem);
