import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  children?: React.ReactNode;
  appendSiteTitle?: boolean;
  title?: string;
  faviconUrl?: string;
  description?: string;
  openGraphTitle?: string;
  openGraphDescription?: string;
  openGraphImage?: {
    src?: string;
  };
  project: any;
}

const SEOSettings = ({
  children,
  appendSiteTitle,
  // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
  editorMode,
  title,
  description,
  openGraphTitle,
  openGraphDescription,
  openGraphImage,
  project,
}: Props) => {
  const { title: siteTitle } = project.settings || {};

  return !editorMode ? (
    <>
      <Helmet>
        {title && (
          <title>
            {title}
            {appendSiteTitle && siteTitle ? ` | ${siteTitle}` : ''}
          </title>
        )}
        {description && <meta name="description" content={description} />}

        {openGraphTitle && (
          <meta
            property="og:title"
            content={`${openGraphTitle}${
              appendSiteTitle && siteTitle ? ` | ${siteTitle}` : ''
            }`}
          />
        )}
        {openGraphDescription && (
          <meta property="og:description" content={openGraphDescription} />
        )}
        {openGraphImage && (
          <meta property="og:image" content={(openGraphImage as any).src} />
        )}
        {openGraphImage && (
          <meta name="twitter:image" content={(openGraphImage as any).src} />
        )}
        {children}
      </Helmet>
    </>
  ) : null;
};

export default SEOSettings;
