import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  FormField,
  Register as RegisterLayout,
  Theme,
  getColorShade,
} from '@noloco/components';
import { AuthLayoutType } from '@noloco/components/src/components/auth/authLayoutTypes';
import { LIGHT } from '@noloco/components/src/constants/surface';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SocialLogin from '../components/SocialLogin';
import {
  NOT_SETUP,
  REQUIRED,
  SETUP_REQUIRED,
} from '../constants/twoFactorAuth';
import { ProjectSettings } from '../models/Project';
import { User } from '../models/User';
import {
  projectIntegrationsSelector,
  projectMediaSelector,
  projectNameSelector,
} from '../selectors/projectSelectors';
import { isPasswordSignInEnabled, isTwoFactorAuthEnabled } from '../utils/auth';
import { extractErrorMessages } from '../utils/errors';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import useRouter from '../utils/hooks/useRouter';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';
import SocialOnlyLogin from './SocialOnlyLogin';
import { TwoFactorAuth } from './TwoFactorAuth';

interface Props {
  logo: any;
  settings: ProjectSettings;
  type?: AuthLayoutType;
  theme: Theme;
}

const Register = forwardRef<any, Props>(
  ({ logo, type, settings, theme }, ref) => {
    const {
      query: { redirectPath },
      push,
    } = useRouter();

    const integrations = useSelector(projectIntegrationsSelector);
    const media = useSelector(projectMediaSelector);
    const projectName = useSelector(projectNameSelector);

    const [errors, setErrors] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { register } = useAuth();

    const updateUserCache = useUpdateUserCache();

    const [requiresSecondFactor, setRequiresSecondFactor] = useState<
      typeof REQUIRED | typeof SETUP_REQUIRED | typeof NOT_SETUP | undefined
    >(undefined);
    const [secondFactorAuthToken, setSecondFactorAuthToken] = useState<
      string | undefined
    >(undefined);

    const twoFactorAuthEnabled = isTwoFactorAuthEnabled(settings);

    const googleClientId = get(integrations, 'google.signIn.clientId');

    const primaryColor = theme.brandColors.primary;

    const passwordSignUpEnabled = useMemo(
      () => isPasswordSignInEnabled(settings),
      [settings],
    );

    const handleOnSubmit = useCallback(
      (registerData: any) => {
        setErrors([]);

        if (!firstName) {
          return setErrors([
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            getText({ field: 'first name' }, 'errors.forms.required'),
          ]);
        }

        if (!lastName) {
          return setErrors([
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            getText({ field: 'last name' }, 'errors.forms.required'),
          ]);
        }

        register(
          registerData.email,
          registerData.password,
          registerData.confirmPassword,
          {
            firstName,
            lastName,
          },
        )
          .then((res: any) => {
            if (res.requiresSecondFactor) {
              setSecondFactorAuthToken(res.secondFactorAuthToken);
              setRequiresSecondFactor(res.requiresSecondFactor);

              return;
            } else {
              updateUserCache(res);

              if (twoFactorAuthEnabled) {
                return setRequiresSecondFactor(NOT_SETUP);
              } else {
                if (redirectPath) {
                  push(decodeURIComponent(redirectPath));
                } else {
                  push('/');
                }
              }
            }
          })
          .catch((error: any) => {
            const errors = extractErrorMessages(error);

            if (errors.length > 0) {
              // @ts-expect-error TS(2345): Argument of type 'String[]' is not assignable to p... Remove this comment to see the full error message
              setErrors(errors);
            }
            console.warn('ERROR', JSON.stringify(error, undefined, 2));
          });
      },
      [
        firstName,
        lastName,
        push,
        redirectPath,
        register,
        updateUserCache,
        twoFactorAuthEnabled,
      ],
    );

    const { src: logoUrl = getProjectAuthLogo(settings, media) } = logo || {};

    const onSocialLogin = (user: User) => {
      updateUserCache(user);
    };

    const loginText = (
      <span>
        <span>{getText('auth.register.or')}</span>
        <Link
          to="/login"
          className={classNames(
            'ml-1 font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
            `text-${getColorShade(
              primaryColor,
              600,
            )} hover:text-${getColorShade(primaryColor, 600)}`,
          )}
        >
          {getText('auth.register.loginLink')}
        </Link>
      </span>
    );

    if (!passwordSignUpEnabled && googleClientId) {
      return (
        <SocialOnlyLogin
          errors={errors}
          googleClientId={googleClientId}
          logoUrl={logoUrl}
          onSocialLogin={onSocialLogin}
          projectName={projectName}
          redirectPath={redirectPath}
          setErrors={setErrors}
          subTitleText={loginText}
          titleText={getText('auth.register.appTitle')}
        />
      );
    }

    return (
      <div
        className={classNames(
          'flex w-full flex-col items-center justify-center overflow-hidden bg-gray-100 text-black',
        )}
      >
        {!requiresSecondFactor && (
          <RegisterLayout
            ref={ref}
            errors={errors}
            logoUrl={logoUrl}
            showPhoneNumber={false}
            buttonText={getText('auth.register.button')}
            emailLabel={getText('auth.fields.email')}
            passwordLabel={getText('auth.fields.password')}
            confirmPasswordLabel={getText('auth.fields.confirmPassword')}
            rememberLabel={getText('auth.fields.rememberMe')}
            loginText={loginText}
            titleText={getText('auth.register.appTitle')}
            onSubmit={handleOnSubmit}
            type={type}
            footer={
              <PoweredByNoloco
                className="mx-auto flex-wrap justify-center text-gray-800"
                projectName={projectName}
                utmSource="noloco_register"
              />
            }
            errorTexts={{
              empty: getText('auth.register.validation.empty'),
              email: {
                invalid: getText('auth.register.validation.email.invalid'),
              },
              password: {
                invalid: getText('auth.register.validation.password.invalid'),
                tooShort: getText('auth.register.validation.password.tooShort'),
                tooLong: getText('auth.register.validation.password.tooLong'),
                numbers: getText('auth.register.validation.password.numbers'),
                case: getText('auth.register.validation.password.case'),
                symbol: getText('auth.register.validation.password.symbol'),
              },
              confirmPassword: {
                invalid: getText(
                  'auth.register.validation.confirmPassword.invalid',
                ),
              },
            }}
            surface={LIGHT}
            socialLogins={
              googleClientId && (
                <SocialLogin
                  clientId={googleClientId}
                  loginPath={redirectPath || '/'}
                  setErrors={setErrors}
                  onLogin={onSocialLogin}
                />
              )
            }
          >
            <div className="mb-4 flex flex-wrap">
              <div className="flex w-full flex-wrap">
                <FormField
                  className="mb-4 w-full"
                  aria-label="given-name"
                  autoComplete="given-name"
                  name="given-name"
                  type="text"
                  onChange={({ target: { value } }: any) => setFirstName(value)}
                  required
                  errorType="below-solid"
                  label={getText('auth.fields.firstName')}
                  placeholder=""
                  value={firstName}
                  surface={LIGHT}
                />
                <FormField
                  className="w-full"
                  aria-label="family-name"
                  autoComplete="family-name"
                  name="family-name"
                  type="text"
                  onChange={({ target: { value } }: any) => setLastName(value)}
                  required
                  errorType="below-solid"
                  label={getText('auth.fields.lastName')}
                  placeholder=""
                  value={lastName}
                  surface={LIGHT}
                />
              </div>
            </div>
          </RegisterLayout>
        )}
        {requiresSecondFactor && (
          <TwoFactorAuth
            requiresSecondFactor={requiresSecondFactor}
            secondFactorAuthToken={secondFactorAuthToken}
            logoUrl={logoUrl}
            projectName={projectName}
            settings={settings}
          />
        )}
      </div>
    );
  },
);

export default withTheme(Register);
