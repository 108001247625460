import { memo } from 'react';
import get from 'lodash/get';
import { CARDS } from '../../../../constants/collectionLayouts';
import { DataField } from '../../../../models/DataTypeFields';
import DataTypePermissions from '../../../../models/DataTypePermissions';
import { DataType } from '../../../../models/DataTypes';
import { Project } from '../../../../models/Project';
import { BaseRecord } from '../../../../models/Record';
import { FormFieldConfig } from '../../../../models/View';
import { getValuePathForFieldConfig } from '../../../../utils/fields';
import FieldCell from '../FieldCell';

interface CardFieldCellProps {
  backLink?: string;
  bulkActionsEnabled?: boolean;
  config?: FormFieldConfig;
  dataType?: DataType;
  elementId?: string;
  field: DataField;
  isRowChecked?: boolean;
  parent?: DataField;
  permissions?: DataTypePermissions;
  project?: Project;
  record?: BaseRecord;
  selectedRows?: BaseRecord[];
  transformRecordScope: (
    currentScope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
}

const CardFieldCell = memo(
  ({
    backLink,
    bulkActionsEnabled,
    config,
    dataType,
    elementId,
    field,
    isRowChecked,
    parent,
    permissions,
    project,
    record,
    selectedRows = [],
    transformRecordScope,
  }: CardFieldCellProps) => (
    <FieldCell
      backLink={backLink}
      className="mr-auto flex w-full flex-col"
      showLabel={true}
      config={config}
      dataType={dataType}
      field={field}
      transformScope={transformRecordScope}
      layout={CARDS}
      permissions={permissions}
      record={record}
      value={get(record, getValuePathForFieldConfig(field, parent))}
      project={project}
      key={field.name}
      skipResolvingForValueIds={[`${elementId}:RECORD`]}
      bulkActionsEnabled={bulkActionsEnabled}
      isRowChecked={isRowChecked}
      selectedRows={selectedRows}
    />
  ),
);

export default CardFieldCell;
