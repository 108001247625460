import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { LG, MD, SM, ShirtSize, XL } from '../../constants/tShirtSizes';
import useBreakpoints from '../../utils/hooks/useBreakpoints';

const FULL = 'full';

interface BaseModalProps {
  bg?: string;
  children: string | React.ReactNode;
  className?: string;
  closeOnOutsideClick?: boolean;
  contextual?: boolean;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  open?: boolean;
  rootSelector?: string;
  size?: ShirtSize | 'full';
}

const BaseModal = forwardRef(
  (
    {
      bg,
      children,
      className,
      closeOnOutsideClick = true,
      contextual = false,
      onClose,
      open = true,
      rootSelector = '#root',
      size = MD,
      ...rest
    }: BaseModalProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [localOpen, setLocalOpen] = useState(open);
    const { sm: isSmScreen } = useBreakpoints();

    useEffect(() => {
      if (open !== localOpen) {
        setLocalOpen(open);
      }
    }, [localOpen, open]);

    const handleClose = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (onClose) {
          event.stopPropagation();
          onClose(event);
        }
        setLocalOpen(false);
      },
      [onClose],
    );

    const root =
      rootSelector &&
      typeof document !== 'undefined' &&
      document.querySelector(rootSelector);

    if (!localOpen || !root) {
      return null;
    }

    if (isSmScreen) {
      return ReactDOM.createPortal(
        <div
          className={classNames(
            'fixed inset-0 z-50 overflow-y-auto',
            className,
          )}
          ref={ref}
          {...rest}
        >
          <div className="flex h-full w-full flex-col">{children}</div>
        </div>,
        root,
      );
    }

    return ReactDOM.createPortal(
      <div
        className={classNames(
          contextual ? 'absolute' : 'fixed',
          'inset-0 z-50 overflow-y-auto',
          className,
        )}
        ref={ref}
        {...rest}
      >
        <div
          className={classNames(
            'flex items-center justify-center text-center',
            {
              'min-h-screen': !contextual,
              'min-h-full': contextual,
              'px-4 sm:items-end sm:px-2 sm:pb-2 sm:pt-0': size !== FULL,
            },
          )}
        >
          <Transition
            show={true}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={classNames(
              contextual ? 'absolute' : 'fixed',
              'inset-0 transition-opacity',
            )}
          >
            <div
              className="absolute inset-0 bg-gray-700 opacity-75"
              onClick={closeOnOutsideClick ? handleClose : undefined}
            />
          </Transition>
          <Transition
            show={true}
            enter="ease-out duration-300"
            enterFrom="opacity-0 sm:translate-y-4 translate-y-0 scale-95"
            enterTo="opacity-100 sm:translate-y-0 scale-100"
            leave="transition ease-in duration-100"
            leaveFrom="ease-in duration-200"
            leaveTo="opacity-0 sm:translate-y-4 translate-y-0 scale-95"
            className={classNames(
              `flex flex-col align-bottom bg-${
                bg ?? 'white'
              } w-full transform overflow-hidden text-left shadow-xl transition-all`,
              {
                'max-h-screen-90 rounded-lg sm:my-0': size !== FULL,
                'max-w-md': size === SM,
                'max-w-xl': size === MD,
                'max-w-3xl': size === LG,
                'max-w-screen-lg': size === XL,
              },
            )}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            {children}
          </Transition>
        </div>
      </div>,
      root,
    );
  },
);

export default BaseModal;
