export const REQUIRED = 'REQUIRED';
export const ENABLED = 'ENABLED';
export const DISABLED = 'DISABLED';
export const SETUP_REQUIRED = 'SETUP_REQUIRED';
export const NOT_SETUP = 'NOT_SETUP';
export const TWO_FACTOR_AUTH_PROMPT_DISMISSED =
  'TWO_FACTOR_AUTH_PROMPT_DISMISSED';

export const twoFactorAuthOptions = [REQUIRED, ENABLED, DISABLED];

export type TwoFactorAuthOptions = 'REQUIRED' | 'ENABLED' | 'DISABLED';

export const requiresSecondFactorOptions = [SETUP_REQUIRED, REQUIRED, null];

export type RequiresSecondFactorAuthOptions =
  | 'SETUP_REQUIRED'
  | 'REQUIRED'
  | null;
