import { ForwardRefExoticComponent } from 'react';
import classNames from 'classnames';
import { LG, ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import { BOARD, COLUMNS, TABLE } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { COLLECTION_ITEM_STYLES, cardSizeWidthMap } from '../Collection';
import DraggableRecord from './DraggableRecord';

interface DraggableRowProps {
  canUpdateViaDrag: boolean;
  cardSize: ShirtSize;
  edge: any;
  group: any;
  isOver: boolean;
  isTable: boolean;
  layout: string;
  Row: ForwardRefExoticComponent<any>;
}

const DraggableRow = ({
  canUpdateViaDrag,
  cardSize = LG,
  edge,
  group,
  isOver,
  isTable,
  layout,
  Row,
}: DraggableRowProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <DraggableRecord
      className={classNames('border-b', {
        'bg-pink-50': isOver && isTable,
        'border-gray-100': !isDarkModeEnabled,
        'pb-4': layout === BOARD,
        'w-full': layout !== COLUMNS,
        [`${COLLECTION_ITEM_STYLES[TABLE](isDarkModeEnabled)}`]: isTable,
        [`flex pb-6 ${cardSizeWidthMap[cardSize]} flex-shrink-0`]:
          layout === COLUMNS,
        [darkModeColors.borders.two]: isDarkModeEnabled,
        group: isTable,
      })}
      draggable={canUpdateViaDrag}
      groupKey={group.key}
      record={edge.node}
    >
      <Row edge={edge} isLast={false} index={edge.index} record={edge.node} />
    </DraggableRecord>
  );
};

export default DraggableRow;
