import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Notice } from '@noloco/components';
import { Loader } from '@noloco/components';
import { INFO, PRIMARY } from '@noloco/components/src/constants/variants';
import { ENABLED, REQUIRED } from '../constants/twoFactorAuth';
import { TwoFactorAuthSetup } from '../elements/TwoFactorAuthSetup';
import { OTPInput } from '../elements/sections/twoFactorAuthentication';
import { dataTypesSelector } from '../selectors/projectSelectors';
import { useAuthWrapperUserFields } from '../utils/hooks/useAuthWrapper';
import { useOTPAuth } from '../utils/hooks/useOTPAuth';
import { useTwoFactorAuth } from '../utils/hooks/useTwoFactorAuth';
import { getText } from '../utils/lang';

enum ModalState {
  SETUP_REQUIRED = 'SETUP_REQUIRED',
  TWO_FACTOR_AUTH_VERIFIED = 'TWO_FACTOR_AUTH_VERIFIED',
  IS_REMOVING = 'IS_REMOVING',
  IS_SETTING_UP = 'IS_SETTING_UP',
}

interface Props {
  children?: any;
  open: boolean;
  onClose: () => void;
  projectTwoFactorAuthSetting?: typeof REQUIRED | typeof ENABLED;
  projectName: string;
}

const LANG_KEY = 'auth.twoFactorAuth';

enum AuthSteps {
  GENERATE = 'generate',
  VERIFY = 'verify',
  GENERATE_BACKUP_CODES = 'generateBackupCodes',
}

export const TwoFactorAuthModal = ({
  onClose,
  open,
  projectTwoFactorAuthSetting,
  projectName,
}: Props) => {
  const [modalState, setModalState] = useState<ModalState | undefined>(
    undefined,
  );

  const projectDataTypes = useSelector(dataTypesSelector);
  const userQueryObject = useAuthWrapperUserFields(projectDataTypes);

  const {
    generateOTP,
    verifyOTP,
    generateOTPBackupCodes,
    removeTwoFactorAuth,
    verifyOTPBackupCode,
  } = useOTPAuth({
    projectName,
    userQueryObject,
  });

  const {
    otpAuthUrl,
    base32,
    errors,
    setupStep,
    setSetupStep,
    otpValue,
    setOtpValue,
    loading,
    statusData,
    statusQueryLoading,
    handleVerify,
    handleGenerate,
    handleGenerateBackupCodes,
    handleRemoveTwoFactorAuth,
  } = useTwoFactorAuth({
    projectName,
    generateOTP,
    generateOTPBackupCodes,
    verifyOTP,
    verifyOTPBackupCode,
    removeTwoFactorAuth,
    onFinish: () => setModalState(ModalState.TWO_FACTOR_AUTH_VERIFIED),
    onRemove: () => setModalState(ModalState.SETUP_REQUIRED),
  });

  useEffect(() => {
    if (statusData && !modalState) {
      setModalState(
        statusData.currentUser.user._twoFactorAuthVerified
          ? ModalState.TWO_FACTOR_AUTH_VERIFIED
          : ModalState.SETUP_REQUIRED,
      );
    }
  }, [statusData, statusQueryLoading, modalState, setModalState]);

  const getConfirmButtonText = (state?: ModalState) => {
    switch (state) {
      case ModalState.SETUP_REQUIRED:
        return getText(LANG_KEY, 'setup.buttonText');
      case ModalState.IS_REMOVING:
        return getText(LANG_KEY, 'remove.buttonText');
      case ModalState.IS_SETTING_UP:
        if (setupStep === AuthSteps.GENERATE) {
          return 'Next';
        }

        if (setupStep === AuthSteps.VERIFY) {
          return 'Verify';
        }

        if (setupStep === AuthSteps.GENERATE_BACKUP_CODES) {
          return 'Download and Finish';
        }
        break;
      default:
        return getText(LANG_KEY, 'done');
    }
  };

  const getConfirmButtonHandler = (state?: ModalState) => {
    switch (state) {
      case ModalState.SETUP_REQUIRED:
        return () => setModalState(ModalState.IS_SETTING_UP);
      case ModalState.IS_REMOVING:
        return () => {
          handleRemoveTwoFactorAuth(otpValue);
          setModalState(ModalState.SETUP_REQUIRED);
        };
      case ModalState.IS_SETTING_UP:
        if (setupStep === AuthSteps.GENERATE) {
          return () => setSetupStep(AuthSteps.VERIFY);
        }

        if (setupStep === AuthSteps.VERIFY) {
          return () => handleVerify(otpValue);
        }

        if (setupStep === AuthSteps.GENERATE_BACKUP_CODES) {
          return handleGenerateBackupCodes;
        }
        break;
      default:
        return () => onClose();
    }
  };

  return (
    <Modal
      className="sm:bg-white sm:dark:bg-gray-800"
      onCancel={() => onClose()}
      canCancel={modalState !== ModalState.TWO_FACTOR_AUTH_VERIFIED}
      canConfirm={!(errors.length > 0)}
      cancelText={getText(LANG_KEY, 'cancel')}
      onConfirm={getConfirmButtonHandler(modalState)}
      confirmText={getConfirmButtonText(modalState)}
      title={`Manage ${getText(LANG_KEY, 'title')} `}
      open={open}
    >
      <div className="flex h-full flex-col items-center justify-center space-y-4 py-2">
        {loading ? (
          <Loader />
        ) : (
          <>
            {modalState === ModalState.SETUP_REQUIRED && (
              <div className="flex flex-col space-y-4 text-left">
                <p className="mb-2">{getText(LANG_KEY, 'setup.description')}</p>
                <p className="mb-4">
                  <strong>{getText(LANG_KEY, 'setup.why')}</strong>
                  {getText(LANG_KEY, 'setup.whyDescription')}
                </p>
                <p className="mb-4">
                  <strong>{getText(LANG_KEY, 'setup.requirements')}</strong>
                  {getText(LANG_KEY, 'setup.requirementsDescription')}
                </p>
              </div>
            )}
            {modalState === ModalState.IS_SETTING_UP && (
              <TwoFactorAuthSetup
                onFinish={() =>
                  setModalState(ModalState.TWO_FACTOR_AUTH_VERIFIED)
                }
                includeButtons={false}
                otpAuthUrl={otpAuthUrl}
                step={setupStep}
                handleGenerate={handleGenerate}
                errors={errors}
                handleVerify={handleVerify}
                handleGenerateBackupCodes={handleGenerateBackupCodes}
                base32={base32}
                otpValue={otpValue}
                setOtpValue={setOtpValue}
                loading={loading}
                setStep={setSetupStep}
              />
            )}
            {modalState === ModalState.TWO_FACTOR_AUTH_VERIFIED && (
              <>
                {projectTwoFactorAuthSetting === REQUIRED && (
                  <Notice
                    title={getText(LANG_KEY, 'required')}
                    subtitle={getText(LANG_KEY, 'remove.requiredWarning')}
                    className="mb-4"
                    type={INFO}
                  />
                )}
                {projectTwoFactorAuthSetting === ENABLED && (
                  <p className="mb-2">{getText(LANG_KEY, 'enabled')}</p>
                )}
                <Button
                  className="my-auto ml-4"
                  variant={PRIMARY}
                  size="md"
                  onClick={() => setModalState(ModalState.IS_REMOVING)}
                  disabled={projectTwoFactorAuthSetting === REQUIRED}
                >
                  {getText(LANG_KEY, 'remove.buttonText')}
                </Button>
              </>
            )}
            {modalState === ModalState.IS_REMOVING && (
              <>
                <p className="mb-4">{getText(LANG_KEY, 'remove.confirm')}</p>
                <OTPInput
                  includeButtons={false}
                  handleVerify={handleRemoveTwoFactorAuth}
                  loading={false}
                  otpValue={otpValue}
                  setOtpValue={setOtpValue}
                />
              </>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};
