import React from 'react';
import { Tooltip } from '@noloco/components/src';
import { darkModeColors } from '../../constants/darkModeColors';
import { ElementType } from '../../constants/elements';
import elementConfigs from '../../elements/elementConfig';
import { getText } from '../../utils/lang';

interface BuildModeElementItemInterface {
  element: ElementType;
  isEnabled?: (element: ElementType) => boolean;
  isVisible?: (element: ElementType) => boolean;
  onAddNewSection: (element: ElementType) => void;
}

const BuildModeElementItem = ({
  element,
  isEnabled = () => true,
  isVisible = () => true,
  onAddNewSection,
}: BuildModeElementItemInterface) => {
  const { Icon } = elementConfigs[element];
  const enabled = isEnabled(element);

  if (!isVisible(element)) {
    return null;
  }

  return (
    <Tooltip
      content={
        <span className={darkModeColors.text.primary}>
          {getText('elements', element, 'disabled')}
        </span>
      }
      disabled={enabled}
      key={element}
    >
      <button
        className="flex w-full flex-col rounded-lg bg-slate-200 p-4 text-slate-500 hover:text-slate-700 hover:shadow-lg dark:bg-slate-800 dark:text-slate-400 dark:hover:text-slate-200"
        disabled={!enabled}
        onClick={() => onAddNewSection(element)}
      >
        <div className="flex items-center space-x-4 text-left">
          <Icon className="h-4 w-4 flex-shrink-0" />
          <div className="flex flex-col space-y-1">
            <span className="text-sm">
              {getText('elements', element, 'title')}
            </span>
            <span className="text-xs">
              {getText('elements', element, 'description')}
            </span>
          </div>
        </div>
      </button>
    </Tooltip>
  );
};

export default BuildModeElementItem;
