import { useCallback } from 'react';
import { IconChevronsLeft } from '@tabler/icons-react';
import { useDispatch } from 'react-redux';
import { BasePopover } from '@noloco/components/src';
import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  BuildModeEditorTabs as BuildModeEditorTabsType,
  OPTIONS,
  VISIBILITY,
} from '../../../constants/buildMode';
import { SIDEBAR_ITEM } from '../../../constants/draggableItemTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { setEditingPage } from '../../../reducers/elements';
import { Page } from '../../../utils/pages';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import BuildModeIcon from '../BuildModeIcon';
import BuildModeLinkEditor from '../BuildModeLinkEditor';

interface BuildModeSidebarItemEditorProps {
  debouncedUpdateProperty: UpdatePropertyCallback;
  editorTab: BuildModeEditorTabsType;
  elementPath: ElementPath;
  page: Page;
  project: Project;
  setEditorTab: (editorTab: BuildModeEditorTabsType) => void;
  showLinkSettings: boolean;
  updateProperty: UpdatePropertyCallback;
}

const BuildModeSidebarItemEditor = ({
  debouncedUpdateProperty,
  editorTab,
  elementPath,
  page,
  project,
  setEditorTab,
  showLinkSettings,
  updateProperty,
}: BuildModeSidebarItemEditorProps) => {
  const dispatch = useDispatch();
  const onCloseEditingPage = useCallback(
    () => dispatch(setEditingPage(null)),
    [dispatch],
  );

  return (
    <BasePopover
      className="max-w-84 h-screen min-h-screen w-full select-none border-l border-r border-gray-700 bg-slate-800"
      content={
        <div className="h-full max-h-full w-full overflow-y-auto">
          <div className="flex h-12 items-center justify-end border-b border-slate-700 p-2">
            <BuildModeIcon
              Icon={IconChevronsLeft}
              onClick={() => onCloseEditingPage()}
            />
          </div>
          {showLinkSettings && (
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={SIDEBAR_ITEM}
              setEditorTab={setEditorTab}
            />
          )}
          {showLinkSettings && editorTab === OPTIONS && (
            <div className="flex flex-col p-2">
              <BuildModeLinkEditor
                elementProps={page.props.link || {}}
                elementPath={elementPath!}
                project={project}
                propDefinition={{ includeSelf: true }}
                updateProperty={(propPath: ElementPath, newValue: any) =>
                  updateProperty!(['link', ...propPath], newValue)
                }
                debouncedUpdateProperty={(
                  propPath: ElementPath,
                  newValue: any,
                ) => debouncedUpdateProperty!(['link', ...propPath], newValue)}
              />
            </div>
          )}
          {((showLinkSettings && editorTab === VISIBILITY) ||
            !showLinkSettings) && (
            <VisibilityRulesEditor
              elementPath={elementPath}
              selectSpaces={true}
              project={project}
              visibilityRules={page.visibilityRules}
            />
          )}
        </div>
      }
      isOpen={true}
      placement="right"
      showArrow={false}
      offset={[0, 16]}
      onOpenChange={(open: boolean) => {
        if (!open) {
          onCloseEditingPage();
        }
      }}
    >
      <div />
    </BasePopover>
  );
};

export default BuildModeSidebarItemEditor;
