import gql from 'graphql-tag';

const USERS_PER_PAGE = 100;

export const USER_TABLE_USER_FRAGMENT = `
    id
    uuid
    firstName
    lastName
    email
    invitationToken
    createdAt
    deactivatedAt
    lastActiveAt
    profilePicture {
        id
        uuid
        fileType
        url
    }
    role {
        id 
        uuid
        referenceId
        name
        internal
        dataAdmin
        builder
    }
    _twoFactorAuthVerified
`;

export const USERS_QUERY = gql`
query userTableCollection($after: String, $before: String, $where: UserWhereInput) {
  userTable(after: $after, before: $before, where: $where, first: ${USERS_PER_PAGE}, orderBy: {field: "createdAt", direction: ASC}) {
    edges {
      node {
        ${USER_TABLE_USER_FRAGMENT}
      }
    }
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
    }
  }
}
`;

export const USER_QUERY = gql`
query userTableUser($id: ID!) {
    user(id: $id) {
        ${USER_TABLE_USER_FRAGMENT}
    }
}
`;

export const DEACTIVATE_USER = `
  mutation deactivateUser($userId: ID!) {
    deactivateUser(userId: $userId) {
      ${USER_TABLE_USER_FRAGMENT}
    }
  }
`;

export const REACTIVATE_USER = `
  mutation reactivateUser($userId: ID!) {
    reactivateUser(userId: $userId) {
      ${USER_TABLE_USER_FRAGMENT}
    }
  }
`;
