import { SwitchButton } from '@noloco/components/src';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import { getText } from '@noloco/core/src/utils/lang';

interface SizeEditorProps {
  defaultSize: string;
  onChange: (size: OptionValue) => void;
  sizes: string[];
  value: OptionValue;
}

const SizeEditor = ({
  defaultSize,
  onChange,
  sizes,
  value,
}: SizeEditorProps) => (
  <SwitchButton
    className="h-8 rounded-lg"
    onChange={onChange}
    options={sizes.map((size: string) => ({
      label: getText(
        'elements.SELECT_INPUT.sizes',
        defaultSize === size ? 'default' : size,
      ),
      value: size,
    }))}
    value={value ?? defaultSize}
  />
);

export default SizeEditor;
