import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import set from 'lodash/fp/set';
import uniqBy from 'lodash/fp/uniqBy';
import get from 'lodash/get';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import {
  NOLOCO_COMMENTS_COLLECTION,
  getCommentsQuery,
} from '../../queries/comments';
import {
  HAS_COMMENT_NOTIFICATION_SUBSCRIPTION,
  MODIFY_COMMENT_NOTIFICATION_SUBSCRIPTION,
} from '../../queries/notifications';
import {
  addDataItemToCollectionCache,
  removeDataItemFromCollectionCache,
} from '../apolloCache';
import { getText } from '../lang';
import useActivePollInterval from './useActivePollInterval';
import useCacheQuery from './useCacheQuery';
import usePrevious from './usePrevious';
import useRecordCommentInput from './useRecordCommentInput';

const LIMIT = 20;

interface RecordCommentsProps {
  dataType: DataType;
  record: BaseRecord;
  project: Project;
}

const useRecordComments = ({
  dataType,
  record,
  project,
}: RecordCommentsProps) => {
  const {
    sending,
    sendNote,
    setSendNote,
    filesToSend,
    setFilesToSend,
    commentText,
    errorAlert,
    deleteComment,
    context,
    onChange,
    onSend,
  } = useRecordCommentInput({ dataType, record, project });

  const commentQuery = useMemo(
    () => getCommentsQuery(dataType, LIMIT),
    [dataType],
  );

  const {
    loading: loadingHasNotificationSubscription,
    data: { hasCommentNotificationSubscription } = {
      hasCommentNotificationSubscription: false,
    },
    refetch: refetchHasNotificationSubscription,
  } = useQuery<{ hasCommentNotificationSubscription: boolean }>(
    HAS_COMMENT_NOTIFICATION_SUBSCRIPTION,
    {
      variables: {
        dataType: dataType.apiName,
        recordId: record.id,
      },
      context,
    },
  );

  const [modifyNotificationSubscription] = useMutation(
    MODIFY_COMMENT_NOTIFICATION_SUBSCRIPTION,
  );

  const updateCommentNotificationSubscription = useMemo(
    () => (subscribed: boolean) => async () => {
      if (!loadingHasNotificationSubscription) {
        await modifyNotificationSubscription({
          variables: {
            dataType: dataType.apiName,
            recordId: record.id,
            subscribed,
          },
          context,
        });
        await refetchHasNotificationSubscription();
      }
    },
    [
      context,
      dataType.apiName,
      loadingHasNotificationSubscription,
      modifyNotificationSubscription,
      record.id,
      refetchHasNotificationSubscription,
    ],
  );

  const jitteredPollInterval = useActivePollInterval();

  const {
    client: apolloClient,
    data: recordData,
    fetchMore,
    loading,
  } = useCacheQuery(commentQuery, {
    variables: {
      uuid: record.uuid,
      after: null,
    },
    errorPolicy: 'all',
    pollInterval: jitteredPollInterval,
    context,
  });

  const cachedComments = useMemo(
    () =>
      get(
        recordData,
        [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
        [],
      ).map((edge: any) => edge.node),
    [dataType.apiName, recordData],
  );

  const cachedPageInfo = useMemo(
    () =>
      get(
        recordData,
        [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
        {},
      ),
    [dataType.apiName, recordData],
  );

  const [comments, setComments] = useState(cachedComments);
  const [pageInfo, setPageInfo] = useState(cachedPageInfo);

  const previousRecordId = usePrevious(record.id);
  const sameRecord = previousRecordId === record.id;

  const deletedIds = useRef<string[]>([]);

  useEffect(() => {
    if (!sameRecord) {
      setComments(cachedComments);
      setPageInfo(cachedPageInfo);
    } else if (cachedComments.length > comments.length) {
      setComments(
        uniqBy('id', cachedComments).filter(
          (comment: any) => !deletedIds.current.includes(comment.id),
        ),
      );
      setPageInfo(cachedPageInfo);
    } else {
      const firstCachedId = get(cachedComments, [0, 'id']);
      const firstStateId = get(comments, [0, 'id']);

      if (firstCachedId !== firstStateId) {
        setComments(
          uniqBy('id', [...cachedComments, ...comments]).filter(
            (comment: any) => !deletedIds.current.includes(comment.id),
          ),
        );
      }
    }
  }, [
    comments,
    cachedComments,
    previousRecordId,
    record.id,
    sameRecord,
    cachedPageInfo,
  ]);

  const [loaderRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: pageInfo.hasNextPage,
    onLoadMore: () => {
      fetchMore({
        variables: { uuid: record.uuid, after: pageInfo.endCursor },
        // @ts-expect-error TS(2345): Argument of type '{ variables: { uuid: any; after:... Remove this comment to see the full error message
        disabled: !pageInfo,
        updateQuery: (previousResults, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResults;
          }

          const previousComments = get(
            previousResults,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            [],
          );

          const newComments = get(
            fetchMoreResult,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            [],
          );

          const newPageInfo = get(
            fetchMoreResult,
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
            {},
          );

          const recordWithEdges = set(
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'edges'],
            uniqBy('node.id', [...previousComments, ...newComments]),
            recordData,
          );

          return set(
            [dataType.apiName, NOLOCO_COMMENTS_COLLECTION, 'pageInfo'],
            newPageInfo,
            recordWithEdges,
          );
        },
      });
    },
    disabled: false,
    rootMargin: '100px 0px 0px 0px',
  });

  const addNewCommentToCache = useCallback(
    (newCommentData: any) => {
      try {
        addDataItemToCollectionCache(
          newCommentData,
          apolloClient,
          commentQuery,
          'Comment',
          {
            uuid: record.uuid,
            after: null,
          },
          {
            collectionPathInput: `${dataType.apiName}.${NOLOCO_COMMENTS_COLLECTION}`,
          },
        );
      } catch (e) {
        console.error(e);
      }
    },
    [apolloClient, commentQuery, dataType.apiName, record.uuid],
  );

  const removeDeletedCommentFromCache = useCallback(
    (comment: any) => {
      try {
        removeDataItemFromCollectionCache(
          comment.id,
          apolloClient,
          commentQuery,
          'Comment',
          {
            uuid: record.uuid,
            after: null,
          },
          {
            collectionPathInput: `${dataType.apiName}.${NOLOCO_COMMENTS_COLLECTION}`,
          },
        );
      } catch (e) {
        console.error(e);
      }
    },
    [apolloClient, commentQuery, dataType.apiName, record.uuid],
  );

  const onCreateNewMessage = useCallback(
    async (event: Event) =>
      onSend(event).then(({ data: newCommentData }: any) => {
        if (newCommentData?.createComment) {
          addNewCommentToCache(newCommentData);
        }
      }),
    [addNewCommentToCache, onSend],
  );

  const onDeleteMessage = useCallback(
    (comment: any) => async () => {
      try {
        await deleteComment({ variables: { id: comment.id } });

        deletedIds.current.push(comment.id);

        removeDeletedCommentFromCache(comment);

        const filteredComments = comments.filter(
          ({ id }: any) => id !== comment.id,
        );

        return setComments(filteredComments);
      } catch (error) {
        errorAlert(getText('errors.generic'), error);
      }
    },
    [comments, deleteComment, errorAlert, removeDeletedCommentFromCache],
  );

  const totalCount = get(
    record,
    `${NOLOCO_COMMENTS_COLLECTION}.totalCount`,
    0,
  ) as number;

  return {
    comments,
    commentText,
    pageInfo,
    filesToSend,
    setFilesToSend,
    sendNote,
    hasCommentNotificationSubscription,
    onChange,
    loading,
    loaderRef,
    sending,
    totalCount,
    setSendNote,
    onCreateNewMessage,
    onDeleteMessage,
    updateCommentNotificationSubscription,
  };
};

export default useRecordComments;
