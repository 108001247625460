import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconSelector } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Popover, TextInput, getColorShade } from '@noloco/components/src';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { MenuStyle, SIDE_MENU, TOP_MENU } from '../constants/menuStyles';
import {
  ALT,
  ENTER,
  ESCAPE,
  KEY_DIGIT_1,
  KEY_DIGIT_2,
  KEY_DIGIT_3,
  KEY_DIGIT_4,
  KEY_DIGIT_5,
  KEY_DIGIT_6,
  KEY_DIGIT_7,
  KEY_DIGIT_8,
  KEY_DIGIT_9,
  KEY_S,
} from '../constants/shortcuts';
import { CUSTOM_PRIMARY_COLOR } from '../constants/tailwindStyles';
import { Element } from '../models/Element';
import { ProjectSpace } from '../models/Project';
import { updateCustomThemeCssVariables } from '../utils/colors';
import useAltKey from '../utils/hooks/useAltKey';
import useCurrentSpace from '../utils/hooks/useCurrentSpace';
import useDarkModeSurface from '../utils/hooks/useDarkModeSurface';
import useFuzzySearch from '../utils/hooks/useFuzzySearch';
import useOnKeyPress from '../utils/hooks/useOnKeyPress';
import useRouter from '../utils/hooks/useRouter';
import { getText } from '../utils/lang';
import { getFirstPageInSpace } from '../utils/pages';
import Icon from './Icon';
import KeyboardShortcutTooltip from './sections/view/KeyboardShortcutTooltip';

const LANG_KEY = 'settings.spaces';

interface SpaceSelectorProps {
  editorMode: boolean;
  elements: Element[];
  isNavExpanded?: boolean;
  isNavOpen?: boolean;
  menuStyle?: MenuStyle;
  primaryColor: string;
  spaces: { id: string; space: ProjectSpace }[];
}

const SpaceSelector = ({
  editorMode,
  elements,
  isNavExpanded = false,
  isNavOpen = false,
  menuStyle = SIDE_MENU,
  primaryColor,
  spaces,
}: SpaceSelectorProps) => {
  const {
    replace,
    query: { __space: spaceIdParam = null },
    replaceQueryParams,
  } = useRouter();
  const surface = useDarkModeSurface();
  const altKey = useAltKey();
  const [space, setCurrentSpaceId] = useCurrentSpace();
  const { id: currentSpaceId, space: currentSpace } = space ?? {};

  const currentSpaceIndex = useMemo(
    () => spaces.findIndex(({ id }) => id === currentSpaceId),
    [spaces, currentSpaceId],
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const fuzzy = useFuzzySearch({
    keys: ['space.name'],
    list: spaces,
  });

  const filteredSpaces = useMemo(
    () => (search ? fuzzy.search(search).map(({ item }) => item) : spaces),
    [fuzzy, search, spaces],
  );

  const handleSpaceChange = useCallback(
    (spaceId: string) => {
      setCurrentSpaceId(spaceId);
      setIsOpen(false);
      setSearch('');

      const spaceTheme = spaces.find(({ id }) => id === spaceId)?.space.theme;

      if (
        spaceTheme &&
        get(spaceTheme, 'brandColorGroups.primary') === CUSTOM_PRIMARY_COLOR &&
        spaceTheme?.customColors?.primary
      ) {
        updateCustomThemeCssVariables(spaceTheme?.customColors?.primary);
      }

      if (spaceIdParam) {
        replaceQueryParams({ __space: undefined });
      }

      // Redirect to the first page in the new space only after the space has been set in the local storage,
      // theme has been applied and query params have been cleared
      const firstPageInCurrentSpace = getFirstPageInSpace(elements, spaceId);

      if (firstPageInCurrentSpace) {
        const routePath = get(firstPageInCurrentSpace, 'props.routePath');

        if (routePath) {
          replace(`/${routePath}`);
        }
      }
    },
    [
      elements,
      replace,
      replaceQueryParams,
      setCurrentSpaceId,
      spaceIdParam,
      spaces,
    ],
  );

  useEffect(() => {
    if (currentSpace) {
      const spaceTheme = currentSpace.theme;

      if (
        get(spaceTheme, 'brandColorGroups.primary') === CUSTOM_PRIMARY_COLOR &&
        spaceTheme?.customColors?.primary
      ) {
        updateCustomThemeCssVariables(spaceTheme.customColors.primary);
      }
    }
  }, [currentSpaceId, currentSpace, spaces]);

  const handleSpaceSelect = useCallback(
    (index = 0) => handleSpaceChange(get(filteredSpaces, index)?.id),
    [filteredSpaces, handleSpaceChange],
  );

  useEffect(() => {
    if (!currentSpaceId && !space) {
      handleSpaceSelect();
    }
  }, [currentSpaceId, handleSpaceSelect, space, spaces]);

  useOnKeyPress(ESCAPE, () => setIsOpen(false), { enabled: isOpen });

  useOnKeyPress(ENTER, () => handleSpaceSelect(), { enabled: isOpen });

  useOnKeyPress(
    KEY_S,
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      setIsOpen(!isOpen);
    },
    { enabled: spaces.length > 0 && !editorMode, altKey: true },
  );

  useOnKeyPress(KEY_DIGIT_1, () => handleSpaceSelect(0), {
    enabled: spaces.length >= 1 && currentSpaceIndex !== 0 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_2, () => handleSpaceSelect(1), {
    enabled: spaces.length >= 2 && currentSpaceIndex !== 1 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_3, () => handleSpaceSelect(2), {
    enabled: spaces.length >= 3 && currentSpaceIndex !== 2 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_4, () => handleSpaceSelect(3), {
    enabled: spaces.length >= 4 && currentSpaceIndex !== 3 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_5, () => handleSpaceSelect(4), {
    enabled: spaces.length >= 5 && currentSpaceIndex !== 4 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_6, () => handleSpaceSelect(5), {
    enabled: spaces.length >= 6 && currentSpaceIndex !== 5 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_7, () => handleSpaceSelect(6), {
    enabled: spaces.length >= 7 && currentSpaceIndex !== 6 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_8, () => handleSpaceSelect(7), {
    enabled: spaces.length >= 8 && currentSpaceIndex !== 7 && !editorMode,
    altKey: true,
  });

  useOnKeyPress(KEY_DIGIT_9, () => handleSpaceSelect(8), {
    enabled: spaces.length >= 9 && currentSpaceIndex !== 8 && !editorMode,
    altKey: true,
  });

  return (
    <div
      className={classNames('flex', {
        'mr-2': menuStyle === TOP_MENU,
        'px-3': isNavOpen,
        'w-full': menuStyle === SIDE_MENU,
      })}
    >
      <Popover
        bg={surface === LIGHT ? 'white' : 'slate-700'}
        content={
          <div className="flex w-52 flex-col space-y-4 pb-6 pt-1">
            <TextInput
              autoFocus={true}
              className={classNames('w-full border-0 ring-2', {
                'bg-white ring-slate-200': surface === LIGHT,
                'bg-slate-700 ring-slate-600': surface === DARK,
              })}
              name="search-spaces"
              onChange={(e) => setSearch(e.target.value)}
              placeholder={getText(LANG_KEY, 'searchSpaces')}
              value={search}
            />
            <div className="flex flex-col space-y-2">
              {filteredSpaces.map(({ id, space: { name, icon } }) => (
                <div
                  className={classNames(
                    'flex cursor-pointer items-center space-x-2 rounded-lg px-2 py-1',
                    {
                      'bg-slate-300':
                        id === currentSpaceId && surface === LIGHT,
                      'bg-slate-800': id === currentSpaceId && surface === DARK,
                      'text-slate-200 hover:bg-slate-600': surface === DARK,
                      'text-slate-600 hover:bg-slate-200': surface === LIGHT,
                    },
                  )}
                  key={id}
                  onClick={() => handleSpaceChange(id)}
                >
                  <Icon icon={icon} className="h-4 w-4 flex-shrink-0" />
                  <span className="w-full truncate">{name}</span>
                </div>
              ))}
              {filteredSpaces.length === 0 && (
                <div className="text-center text-slate-400">
                  {getText(LANG_KEY, 'noSpacesFound')}
                </div>
              )}
            </div>
            <p
              className={classNames(
                'absolute bottom-0 left-0 flex w-full select-none items-center justify-center rounded-b-lg p-1 text-xs',
                {
                  'bg-slate-200 text-slate-600': surface === LIGHT,
                  'bg-slate-800 text-slate-400': surface === DARK,
                },
              )}
            >
              {getText({ key: altKey }, LANG_KEY, 'selectSpaceWithNumberKey')}
            </p>
          </div>
        }
        closeOnOutsideClick={true}
        isOpen={isOpen}
        offset={[0, 8]}
        onOpenChange={setIsOpen}
        placement="bottom-start"
        showArrow={false}
        trigger="none"
      >
        <div className="flex w-full items-center">
          <KeyboardShortcutTooltip
            buildMode={false}
            keys={[ALT, KEY_S]}
            label={getText(LANG_KEY, 'selectSpace')}
            offset={[0, 8]}
            placement={isNavExpanded ? 'top' : 'right'}
          >
            <button
              className={classNames(
                'group flex w-full select-none items-center rounded-lg px-3 py-2',
                `text-${getColorShade(primaryColor, 200)} hover:text-white hover:bg-${getColorShade(primaryColor, 800)} bg-${getColorShade(primaryColor, 600)} dark:bg-${getColorShade(primaryColor, 700)}`,
                {
                  'justify-between': isNavExpanded || menuStyle === TOP_MENU,
                  'justify-center': !isNavExpanded,
                  'min-w-36 max-w-48': menuStyle === TOP_MENU,
                  'text-white': isOpen,
                },
              )}
              onClick={() => setIsOpen(true)}
            >
              {currentSpace && (
                <span className="flex items-center space-x-2 truncate">
                  <Icon
                    icon={currentSpace.icon}
                    className="h-4 w-4 flex-shrink-0"
                  />
                  {(isNavExpanded || menuStyle === TOP_MENU) && (
                    <span className="w-full truncate">{currentSpace.name}</span>
                  )}
                </span>
              )}
              {!currentSpace && <span>{getText(LANG_KEY, 'selectSpace')}</span>}
              {(isNavExpanded || menuStyle === TOP_MENU) && (
                <IconSelector className="flex-shrink-0" size={16} />
              )}
            </button>
          </KeyboardShortcutTooltip>
        </div>
      </Popover>
    </div>
  );
};

export default SpaceSelector;
