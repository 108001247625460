import React, { useCallback, useMemo, useState } from 'react';
import { IconEdit, IconSettings } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Switch } from '@noloco/components/src';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { OPTIONS, VISIBILITY } from '@noloco/core/src/constants/buildMode';
import { EDIT_RELATED_RECORD_BUTTON } from '@noloco/core/src/constants/elements';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import {
  DataList,
  EditRelatedRecordButton,
} from '@noloco/core/src/models/View';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { useUpdateRelatedRecordCollection } from '@noloco/core/src/utils/hooks/useUpdateRelatedRecordCollection';
import { getText } from '@noloco/core/src/utils/lang';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import Drawer from '../../canvas/Drawer';
import StringPropEditor from '../../canvas/StringPropEditor';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import { getConditionFieldOptions } from './FieldConditionsEditor';
import RelationalFieldFormFieldsEditor from './RelationalFieldFormFieldsEditor';

const LANG_KEY = 'elements.VIEW.buttons.editRelatedRecordButton';

interface Props {
  dataType: DataType;
  config: any;
  dataList: DataList;
  enabled: boolean;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  editRelatedRecordButton: EditRelatedRecordButton;
  editRelatedRecordButtonText: string | undefined;
  editLink: any | undefined;
  unlinkButtonText: string | undefined;
  hideUnlinkRecordButton: boolean | undefined;
  hideRelatedRecordButton: boolean | undefined;
  project: Project;
  rootDataType: DataType;
  updateProperty: UpdatePropertyCallback;
  allowOpenFormInModal: boolean;
}

const EditRelatedRecordsEditor = ({
  config,
  dataList,
  dataType,
  debouncedUpdateProperty,
  elementPath,
  enabled,
  editRelatedRecordButton = {},
  editRelatedRecordButtonText,
  project,
  rootDataType,
  unlinkButtonText,
  updateProperty,
  hideUnlinkRecordButton,
  hideRelatedRecordButton,
}: Props) => {
  const onVisibilityRulesChange: UpdatePropertyCallback = useCallback(
    (path: ElementPath, value: any) =>
      updateProperty(
        ['editRelatedRecordButton', 'visibilityRules', ...path],
        value,
      ),
    [updateProperty],
  );

  const conditionFieldOptions = useMemo(
    () => getConditionFieldOptions(rootDataType, project),
    [project, rootDataType],
  );

  const { relationalDataField } = useUpdateRelatedRecordCollection(
    dataList,
    project,
    rootDataType,
    dataType,
  );

  const updateFields: UpdatePropertyCallback = useCallback(
    (path: ElementPath, value: any) => {
      updateProperty(['editRelatedRecordButton', ...path], value);
    },
    [updateProperty],
  );

  const [isOpen, setIsOpen] = useState(false);
  const [editorTab, setEditorTab] = useEditorTabs(
    'editRelatedRecordButton',
    OPTIONS,
  );

  const showEditRelatedButton = useMemo(
    () => dataType && !dataType.readOnly && !hideRelatedRecordButton,
    [dataType, hideRelatedRecordButton],
  );

  const handleOnChange = useCallback(
    (value: boolean) =>
      updateProperty(['editRelatedRecordButton'], {
        ...editRelatedRecordButton,
        show: value,
        hideAddRelatedRecordButton: !value,
        hideUnlinkButton: !value,
      }),
    [editRelatedRecordButton, updateProperty],
  );
  const handleEditRelatedButtonClick = useCallback(
    () => (!showEditRelatedButton ? () => setIsOpen(true) : null),
    [showEditRelatedButton],
  );

  return (
    <div className="mb-2 flex w-full flex-col space-y-2 rounded-lg bg-slate-900">
      <div
        className={classNames(
          'flex h-10 items-center justify-between border-dashed border-slate-600 pl-2',
          { 'border-b': enabled },
        )}
      >
        <BuildModeLabel>{getText(LANG_KEY, 'show')}</BuildModeLabel>

        <div className="flex items-center justify-between gap-x-2">
          <div className="flex items-center justify-between gap-x-2">
            <BuildModeSwitchSection
              button={
                !hideRelatedRecordButton && (
                  <IconSettings
                    className="cursor-pointer text-slate-500 hover:text-slate-400"
                    onClick={() => setIsOpen(true)}
                    size={16}
                  />
                )
              }
              disabled={dataType && dataType.readOnly}
              value={enabled}
              showOnlyButton={true}
              onChange={handleOnChange}
              onClick={handleEditRelatedButtonClick}
            />
            {isOpen && showEditRelatedButton && (
              <Drawer
                Icon={IconEdit}
                onClose={() => setIsOpen(false)}
                rootSelector=".noloco-project"
                title={getText(LANG_KEY, 'label')}
                usePortal={true}
              >
                <div className="flex w-screen max-w-full flex-col">
                  <BuildModeEditorTabs
                    editorTab={editorTab}
                    elementType={EDIT_RELATED_RECORD_BUTTON}
                    isNavEnabled={false}
                    setEditorTab={setEditorTab}
                  />
                  {editorTab === OPTIONS && (
                    <div className="flex flex-col space-y-2 p-2">
                      <BuildModeInput label={getText(LANG_KEY, 'buttonText')}>
                        <StringPropEditor
                          // @ts-expect-error TS(2339): Property 'contained' does not exist on type '{ chi... Remove this comment to see the full error message
                          contained={true}
                          project={project}
                          onChange={(value: any) =>
                            debouncedUpdateProperty(
                              ['editRelatedRecordButtonText'],
                              value,
                            )
                          }
                          elementPath={elementPath}
                          placeholder=""
                          value={editRelatedRecordButtonText}
                        />
                      </BuildModeInput>
                    </div>
                  )}
                  {editorTab === VISIBILITY && (
                    <VisibilityRulesEditor
                      dataType={rootDataType}
                      elementPath={elementPath}
                      onChange={onVisibilityRulesChange}
                      project={project}
                      visibilityRules={editRelatedRecordButton.visibilityRules}
                    />
                  )}
                </div>
              </Drawer>
            )}
          </div>
        </div>
      </div>
      {enabled && (
        <div className="flex flex-col space-y-2 p-2">
          <div className="flex items-center justify-between">
            <BuildModeLabel>
              {getText(LANG_KEY, 'switch.relatedRecord')}
            </BuildModeLabel>

            <Switch
              size={SM}
              disabled={
                (dataType && dataType.readOnly) || hideUnlinkRecordButton
              }
              value={!hideRelatedRecordButton}
              onChange={(value: boolean) =>
                updateProperty(
                  ['editRelatedRecordButton', 'hideAddRelatedRecordButton'],
                  !value,
                )
              }
            />
          </div>
          <div className="flex items-center justify-between">
            <BuildModeLabel>
              {getText(LANG_KEY, 'switch.unlinkButton')}
            </BuildModeLabel>
            <Switch
              size={SM}
              disabled={
                (dataType && dataType.readOnly) || hideRelatedRecordButton
              }
              value={!hideUnlinkRecordButton}
              onChange={(value: boolean) =>
                updateProperty(
                  ['editRelatedRecordButton', 'hideUnlinkButton'],
                  !value,
                )
              }
            />
          </div>
          <BuildModeLabel>
            {getText(LANG_KEY, 'unlinkButtonText')}
          </BuildModeLabel>
          <StringPropEditor
            // @ts-expect-error TS(2322): Type '{ contained: boolean; project: any; onChange... Remove this comment to see the full error message
            contained={true}
            project={project}
            onChange={(value: any) =>
              debouncedUpdateProperty(
                ['editRelatedRecordButton', 'unlinkButtonText'],
                value,
              )
            }
            elementPath={elementPath}
            placeholder=""
            value={unlinkButtonText}
          />
          <RelationalFieldFormFieldsEditor
            config={config}
            dataType={rootDataType}
            disableAllowNewRecords={true}
            elementPath={elementPath}
            field={relationalDataField}
            index="config"
            project={project}
            recordScopeOptions={get(
              conditionFieldOptions,
              ['fieldOptions', 'options'],
              [],
            )}
            updateFields={updateFields}
          />
        </div>
      )}
    </div>
  );
};

export default EditRelatedRecordsEditor;
