import React, { useCallback, useMemo, useState } from 'react';
import { IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { SelectInput } from '@noloco/components';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import {
  EVERY,
  IN,
  NONE,
  NOT_IN,
  SOME,
} from '@noloco/core/src/constants/operators';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { findFieldFromFilterName } from '@noloco/core/src/utils/data';
import { getText } from '@noloco/core/src/utils/lang';
import { getSubFieldsAsDataFields } from '@noloco/core/src/utils/objects';
import {
  getInputTypeForOperator,
  getOperatorsForFieldType,
} from '@noloco/core/src/utils/operator';
import { isMultiRelationship } from '@noloco/core/src/utils/relationships';
import DataFieldIcon from '../DataFieldIcon';
import ConditionValueEditor from '../canvas/ConditionValueEditor';
import ArrayFilterBranchInput from './ArrayFilterBranchInput';
import { generateAdditionalFilter } from './customerEditors/CustomFiltersEditor';

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

interface OwnFilterInputProps {
  dataType: DataType;
}

// @ts-expect-error TS(2456): Type alias 'FilterInputProps' circularly reference... Remove this comment to see the full error message
type FilterInputProps = OwnFilterInputProps & typeof FilterInput.defaultProps;

export const findFilterOption = (
  path: string[] | undefined,
  options: any[],
  depth = 0,
): any | null => {
  if (!path || path.length === 0 || options.length === 0) {
    return null;
  }

  const filterOption = options.find(({ value }) => path.join('.') === value);

  if (filterOption) {
    return filterOption;
  }

  const parentOption = options.find(({ value }) => path[depth] === value);

  if (
    !parentOption ||
    !parentOption.options ||
    parentOption.options.length < 1
  ) {
    return null;
  }

  return findFilterOption(path, parentOption.options, depth + 1);
};

// @ts-expect-error TS(7022): 'FilterInput' implicitly has type 'any' because it... Remove this comment to see the full error message
const FilterInput = ({
  additionalScopeItems = [],
  elementPath,
  project,
  onChange,
  onRemove,
  dataType,
  operatorValidationError,
  value,
  ValueInput,
}: FilterInputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const handleValueChange = useCallback(
    (property: any, newValue: any) => {
      setLocalValue((currentLocalValue: any) => {
        const nextValue = {
          ...currentLocalValue,
          [property]: newValue,
        };
        onChange(nextValue);

        return nextValue;
      });
    },
    [onChange],
  );
  const handleOperatorChange = useCallback(
    (value: any) => handleValueChange('operator', value),
    [handleValueChange],
  );

  const fieldOptions = useMemo(
    () =>
      dataType.fields.reduce((fieldOptionsAcc: any, field: any) => {
        if (field.hidden) {
          return fieldOptionsAcc;
        }

        if (
          !field.relationship &&
          !field.relatedField &&
          field.type !== OBJECT
        ) {
          return [
            ...fieldOptionsAcc,
            {
              icon: <DataFieldIcon field={field} size={14} />,
              label: field.display,
              value: field.name,
              field,
            },
          ];
        }

        if (field.relationship) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: `${field.name}Id`,
              field,
            },
          ];
        }

        if (field.relatedField) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: `${field.relatedField.reverseName}Id`,
              field,
            },
          ];
        }

        if (field.type === OBJECT) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: field.name,
              options: getSubFieldsAsDataFields(field).map((subField) => ({
                label: subField.display,
                icon: <DataFieldIcon field={subField} size={14} />,
                value: `${field.name}.${subField.name}`,
                field: subField,
              })),
            },
          ];
        }

        return fieldOptionsAcc;
      }, []),
    [dataType.fields],
  );

  const getFieldFromOptions = useCallback(
    (fieldValue: any) =>
      fieldValue && findFilterOption(fieldValue.split('.'), fieldOptions),
    [fieldOptions],
  );

  const onFieldChange = useCallback(
    (newField: any) => {
      const newFieldOption = getFieldFromOptions(newField);
      const newOperators = getOperatorsForFieldType(newFieldOption.field.type);

      const nextValue = {
        ...localValue,
        field: newField,
        operator: newFieldOption.field.multiple ? SOME : newOperators[0],
        filters: newFieldOption.field.multiple
          ? [
              generateAdditionalFilter({
                field: newField,
                operator: newOperators[0],
              }),
            ]
          : undefined,
      };
      setLocalValue(nextValue);
      onChange(nextValue);
    },
    [getFieldFromOptions, localValue, onChange],
  );

  const selectedField = useMemo(
    () => localValue.field && getFieldFromOptions(localValue.field),
    [getFieldFromOptions, localValue.field],
  );

  const operators = useMemo(() => {
    if (!selectedField) {
      return [];
    }

    if (selectedField.field.multiple) {
      return [SOME, EVERY, NONE];
    }

    return getOperatorsForFieldType(
      selectedField.field.type,
      selectedField.field.relationship,
    );
  }, [selectedField]);

  const operatorOptions = operators.map((op) => ({
    label: getText('operators', op, 'label.default'),
    value: op,
  }));

  const fieldValue = useMemo(() => {
    if (!localValue || !localValue.field) {
      return null;
    }

    return findFieldFromFilterName(localValue.field, dataType);
  }, [localValue, dataType]);

  const isArrayField = useMemo(
    () => get(selectedField, 'field.multiple', null),
    [selectedField],
  );

  const shouldRenderValueInput = useMemo(
    () => !isArrayField && !!getInputTypeForOperator(localValue.operator),
    [isArrayField, localValue],
  );

  return (
    <div
      className={classNames('flex flex-wrap items-center gap-2', {
        'bg-brand-dark rounded-lg p-2': isArrayField,
      })}
    >
      <SelectInput
        searchable={true}
        value={localValue.field}
        options={fieldOptions}
        onChange={onFieldChange}
        placeholder={getTranslation('field.placeholder')}
        contained={true}
      />
      <SelectInput
        className="-mr-4"
        value={localValue.operator}
        options={operatorOptions}
        onChange={handleOperatorChange}
        placeholder={getTranslation('operator.placeholder')}
        contained={true}
        showValidationErrorText={false}
        validationError={operatorValidationError}
      />
      <div className="ml-4 flex max-w-full flex-grow items-center">
        {shouldRenderValueInput && (
          <div className="flex w-full items-center">
            <ValueInput
              additionalScopeItems={additionalScopeItems}
              contained={true}
              dataType={dataType}
              field={fieldValue}
              project={project}
              className="w-56"
              onChange={(newResult: any) =>
                handleValueChange('result', newResult)
              }
              multiple={
                [IN, NOT_IN].includes(localValue.operator) &&
                (!selectedField ||
                  !selectedField.field.relationship ||
                  // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
                  !isMultiRelationship(!selectedField.field.relationship))
              }
              elementPath={elementPath}
              includeUniqueColumnar={
                selectedField &&
                (!!selectedField.field.relationship ||
                  !!selectedField.field.relatedField ||
                  getInputTypeForOperator(localValue.operator) === 'array')
              }
              placeholder={getTranslation('result.placeholder')}
              value={localValue.result ?? []}
            />
          </div>
        )}
        <IconTrash
          size={14}
          onClick={onRemove}
          className="my-auto ml-4 text-gray-500 hover:text-white"
        />
      </div>
      {isArrayField && (
        <div className="ml-4 flex max-w-full flex-grow items-center">
          <ArrayFilterBranchInput
            additionalScopeItems={additionalScopeItems}
            dataType={dataType}
            elementPath={elementPath}
            onChange={(newFilter: any) =>
              handleValueChange('filters', newFilter)
            }
            onEmpty={onRemove}
            project={project}
            filters={localValue.filters ?? []}
            ValueInput={ValueInput}
            selectedField={selectedField}
          />
        </div>
      )}
    </div>
  );
};

FilterInput.defaultProps = {
  additionalScopeItems: [],
  disabledFields: [],
  ValueInput: ConditionValueEditor,
};

export default FilterInput;
