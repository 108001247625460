import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import { DateTime, DateTimeUnit } from 'luxon';
import Timeline, {
  CustomMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from 'react-calendar-timeline/lib';
import 'react-calendar-timeline/lib/Timeline.css';
import { Xwrapper, useXarrow } from 'react-xarrows';
import { Views } from '@noloco/react-big-calendar';
import { TWO_WEEK } from '../../../constants/collection';
import { GANTT, ROWS } from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import { ID } from '../../../models/Element';
import {
  getFieldFromDependency,
  getFieldKey,
  getFieldReverseName,
} from '../../../utils/fields';
import { getDependencies } from '../../../utils/ganttDependencies';
import useRecordRowLink from '../../../utils/hooks/useRecordRowLink';
import useRouter from '../../../utils/hooks/useRouter';
import { cleanEdgesNodeFromDepPath } from '../../../utils/queries';
import { Group } from '../Collection';
import { Dependent, Event } from './CollectionEvents';
import CollectionGantt from './CollectionGantt';
import CollectionTimelineItem from './CollectionTimelineItem';
import CalendarToolbar from './calendar/CalendarToolbar';

type ViewConfig = Record<
  string,
  {
    unit: DateTimeUnit;
    minimumZoom: number;
    maximumZoom: number;
    dragSnap: number;
    buffer: number;
    visibleUnits: number;
  }
>;

type LabelFormats = Record<string, string>;

interface TimelineProps {
  visibleTimeStart: number;
  visibleTimeEnd: number;
  minZoom: number;
  maxZoom: number;
  dragSnap: number;
  buffer: number;
}

interface TimelineGroup {
  id: number;
  name?: string;
  display?: string;
  order?: number;
  color?: string;
  stackItems: boolean;
}

const FORWARD = 'forward';
const BACKWARD = 'backward';
const LINE_HEIGHT = 50;
const SIDEBAR_WIDTH = 150;
type Direction = typeof FORWARD | typeof BACKWARD;

const BOTH = 'both';
const DAY = 24 * 60 * 60 * 1000;

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'display',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

const defaultGroups = [{ id: 0, stackItems: true }];

const viewConfig: ViewConfig = {
  [Views.MONTH]: {
    unit: 'month',
    minimumZoom: 7 * DAY,
    maximumZoom: 30 * DAY,
    dragSnap: DAY / 2,
    buffer: 3,
    visibleUnits: 1,
  },
  [TWO_WEEK]: {
    unit: 'week',
    minimumZoom: DAY,
    maximumZoom: 14 * DAY,
    dragSnap: DAY,
    buffer: 3,
    visibleUnits: 2,
  },
  [Views.WEEK]: {
    unit: 'week',
    minimumZoom: DAY,
    maximumZoom: 7 * DAY,
    dragSnap: 60 * 60 * 1000,
    buffer: 4,
    visibleUnits: 1,
  },
  [Views.WORK_WEEK]: {
    unit: 'week',
    minimumZoom: DAY,
    maximumZoom: 5 * DAY,
    dragSnap: 60 * 60 * 1000,
    buffer: 4,
    visibleUnits: 1,
  },
  [Views.DAY]: {
    unit: 'day',
    minimumZoom: 6 * 60 * 60 * 1000,
    maximumZoom: DAY,
    dragSnap: 15 * 60 * 1000,
    buffer: 15,
    visibleUnits: 1,
  },
};

const labelFormats: LabelFormats = {
  [Views.MONTH]: 'D',
  [TWO_WEEK]: 'D ddd',
  [Views.WEEK]: 'D ddd',
  [Views.WORK_WEEK]: 'D ddd',
  [Views.DAY]: 'HH:mm',
};

const formatMap = (start: DateTime, end?: DateTime) => ({
  [Views.MONTH]: start.toLocaleString({
    month: 'long',
    year: 'numeric',
  }),
  [TWO_WEEK]: `${start.toLocaleString({
    month: 'long',
    day: '2-digit',
  })} - ${end?.toLocaleString({ month: 'long', day: '2-digit' })}`,
  [Views.WEEK]: `${start.toLocaleString({
    month: 'long',
    day: '2-digit',
  })} - ${end?.toLocaleString({ month: 'long', day: '2-digit' })}`,
  [Views.WORK_WEEK]: `${start.toLocaleString({
    month: 'long',
    day: '2-digit',
  })} - ${end?.toLocaleString({ month: 'long', day: '2-digit' })}`,
  [Views.DAY]: start.toLocaleString({
    weekday: 'long',
    month: 'short',
    day: 'numeric',
  }),
});

const getDuration = (view: string, unit: string) => {
  switch (view) {
    case Views.WORK_WEEK:
      return { day: 5 };

    case TWO_WEEK:
      return { day: 14 };

    default:
      return { [unit]: 1 };
  }
};

const GroupRenderer = ({ group }: any) => (
  <div className="truncate pl-2 text-gray-500">{group.display}</div>
);

const CollectionTimeline = ({
  canUpdateEventDates,
  currentView,
  date,
  dateEndField,
  dateStartField,
  defaultViews,
  draftEvents,
  enableDragAndDropEdit,
  enableShortcuts,
  dataType,
  ganttDependency,
  groupByField,
  groups: eventGroups,
  groupByFieldDep,
  isDarkModeEnabled,
  layout,
  loading,
  localizer,
  project,
  qsSuffix,
  Row,
  rowLink,
  recordStyle,
  setView,
  theme,
  updateEvent,
  updateEvents,
}: any) => {
  const { pushQueryParams } = useRouter();
  const updateXarrow = useXarrow();
  const defaultDate = useMemo(
    () => (date ? DateTime.fromISO(date) : DateTime.now()),
    [date],
  );
  const unit = useMemo(
    () => (currentView === Views.DAY ? 'hour' : 'day'),
    [currentView],
  );
  const labelFormat = useMemo(() => labelFormats[currentView], [currentView]);
  const ganttFieldDependency = useMemo(
    () =>
      ganttDependency &&
      getFieldFromDependency(
        ganttDependency.path.split('.'),
        dataType,
        project.dataTypes,
      ),
    [dataType, ganttDependency, project.dataTypes],
  );
  const isGanttViewEnabled = useMemo(
    () => layout === GANTT && ganttFieldDependency,
    [layout, ganttFieldDependency],
  );
  const currentViewConfig = viewConfig[currentView];

  const visibleTimeStart = defaultDate
    .startOf(currentViewConfig.unit)
    .valueOf();

  const visibleTimeEnd = DateTime.fromMillis(visibleTimeStart)
    .plus({ [currentViewConfig.unit]: currentViewConfig.visibleUnits })
    .valueOf();

  const { field: ganttField } = ganttFieldDependency ?? {};
  const [showArrows, setShowArrows] = useState<boolean>(isGanttViewEnabled);
  const [timelineProps, setTimelineProps] = useState<TimelineProps>({
    visibleTimeStart,
    visibleTimeEnd,
    minZoom: currentViewConfig.minimumZoom,
    maxZoom: currentViewConfig.maximumZoom,
    dragSnap: currentViewConfig.dragSnap,
    buffer: currentViewConfig.buffer,
  });
  const [itemSelected, setItemSelected] = useState<{
    rootPathname?: string;
  }>();
  const [selected, setSelected] = useState<string[]>([]);

  const recordRowLink = useRecordRowLink({
    layout: ROWS,
    project,
    recordStyle,
    rowLink,
    uuid: get(itemSelected, 'record.uuid'),
    viewRootPathname: itemSelected?.rootPathname,
  });

  const label = useMemo(() => {
    const start = DateTime.fromMillis(timelineProps.visibleTimeStart);
    const end = DateTime.fromMillis(timelineProps.visibleTimeEnd);

    return formatMap(start, end)[currentView];
  }, [
    currentView,
    timelineProps.visibleTimeStart,
    timelineProps.visibleTimeEnd,
  ]);

  const setTimelinePropsState = useCallback(
    (currentView: string, props: TimelineProps) => {
      const { unit, minimumZoom, maximumZoom, dragSnap, buffer } =
        viewConfig[currentView];

      const startTime = DateTime.fromMillis(props.visibleTimeStart).startOf(
        unit,
      );

      const endTime = startTime.plus(getDuration(currentView, unit));

      return {
        minZoom: minimumZoom,
        maxZoom: maximumZoom,
        visibleTimeStart: startTime.valueOf(),
        visibleTimeEnd: endTime.valueOf(),
        dragSnap,
        buffer,
      };
    },
    [],
  );

  useEffect(
    () =>
      setTimelineProps((props) => setTimelinePropsState(currentView, props)),
    [currentView, setTimelinePropsState],
  );

  const groups: TimelineGroup[] = useMemo(() => {
    if (groupByField && eventGroups.length > 0) {
      return eventGroups.map((eventGroup: Group, index: number) => ({
        id: eventGroup.id ?? 'empty',
        name: eventGroup.rawValue,
        display: eventGroup.label,
        order: index,
        color: eventGroup.color,
        stackItems: true,
      }));
    }

    return defaultGroups;
  }, [eventGroups, groupByField]);

  const isTopLevelField = useMemo(
    () =>
      groupByFieldDep &&
      cleanEdgesNodeFromDepPath(groupByFieldDep.path).split('.').length === 1,
    [groupByFieldDep],
  );

  const ganttDependencyReverseFieldName = useMemo(
    () => ganttField && (getFieldReverseName(ganttField, dataType) as string),
    [dataType, ganttField],
  );

  const events = useMemo(
    () =>
      draftEvents.map((draftEvent: Event) => {
        let predecessors: Dependent[] = [];
        let successors: Dependent[] = [];

        if (isGanttViewEnabled && ganttField) {
          const dependencies = getDependencies(
            draftEvent,
            ganttField.relationship,
            ganttField.name,
            ganttDependencyReverseFieldName,
            dateStartField.name,
            dateEndField.name,
          );
          predecessors = get(dependencies, 'predecessors', []);
          successors = get(dependencies, 'successors', []);
        }

        return {
          ...draftEvent,
          start: draftEvent.start?.getTime(),
          end: draftEvent.end?.getTime(),
          group: draftEvent.group?.id ?? (draftEvent.group ? 'empty' : 0),
          canResize: canUpdateEventDates && draftEvent.hasEnd ? BOTH : false,
          ...(isGanttViewEnabled
            ? {
                predecessors,
                successors,
              }
            : {}),
        };
      }),
    [
      draftEvents,
      isGanttViewEnabled,
      ganttDependencyReverseFieldName,
      ganttField,
      dateStartField,
      dateEndField,
      canUpdateEventDates,
    ],
  );

  const findEventById = useCallback(
    (eventId: any) => events.find((event: Event) => event.id === eventId),
    [events],
  );

  const handleItemResize = useCallback(
    (itemId: ID, time: number, direction: 'left' | 'right') => {
      const event = findEventById(itemId);
      const eventEnd = DateTime.fromMillis(event.end).toJSDate();
      const eventStart = DateTime.fromMillis(event.start).toJSDate();
      const newTime = DateTime.fromMillis(time).toJSDate();

      if (isGanttViewEnabled) {
        setShowArrows(true);
      }

      updateEvent(
        event,
        direction === 'left' ? newTime : eventStart,
        direction === 'left' ? eventEnd : newTime,
      );
    },
    [findEventById, isGanttViewEnabled, updateEvent],
  );

  const getNewStartAndEndTime = useCallback(
    (
      direction: Direction,
      startTime: number,
      endTime: number,
      interval: number,
    ) => {
      let start: number = startTime;
      let end: number = startTime;

      if (direction === FORWARD) {
        start = DateTime.fromMillis(endTime).toMillis();
        end = DateTime.fromMillis(start + interval).toMillis();
      } else {
        end = DateTime.fromMillis(startTime).toMillis();
        start = DateTime.fromMillis(end - interval).toMillis();
      }

      return {
        newStartTime: start,
        newEndTime: end,
      };
    },
    [],
  );

  const manageInvalidGanttDependencies = useCallback(
    (
      predecessors: any,
      successors: any,
      startTime: any,
      interval: any,
      eventStartTime: any,
    ) => {
      const endTime = startTime + interval;
      let invalidGanttDependencies: Event[] = [];

      const eventsBecomingInvalidDueToPredecessor = predecessors.filter(
        (predecessor: Event) => startTime < predecessor?.end,
      );
      const eventsBecomingInvalidDueToSuccessor = successors.filter(
        (successor: Event) => startTime + interval > successor?.start,
      );

      const eventsBecomingInvalid = [
        ...eventsBecomingInvalidDueToPredecessor,
        ...eventsBecomingInvalidDueToSuccessor,
      ];

      if (eventsBecomingInvalid.length > 0) {
        const direction = startTime - eventStartTime > 0 ? FORWARD : BACKWARD;

        invalidGanttDependencies = eventsBecomingInvalid
          .map((ev: Event) => {
            const event = findEventById(ev.id);
            const { predecessors = [], successors = [] } = event;
            const interval = event.end - event.start;
            const { newStartTime, newEndTime } = getNewStartAndEndTime(
              direction,
              startTime,
              endTime,
              interval,
            );

            return manageInvalidGanttDependencies(
              predecessors,
              successors,
              newStartTime,
              interval,
              event.start,
            ).concat({
              event,
              start: DateTime.fromMillis(newStartTime).toJSDate(),
              end: DateTime.fromMillis(newEndTime).toJSDate(),
            } as Event);
          })
          .reduce((acc, curr) => acc.concat(curr), []);
      }

      return invalidGanttDependencies;
    },
    [findEventById, getNewStartAndEndTime],
  );

  const getGroupValue = useCallback(
    (group: number) => {
      const eventGroup = eventGroups[group];

      if (eventGroup && eventGroup.rows.length > 0) {
        return get(first(eventGroup.rows), ['node', groupByField.field.name]);
      }

      return null;
    },
    [eventGroups, groupByField],
  );

  const handleItemMove = useCallback(
    async (itemId: ID, startTime: number, group: number) => {
      const primary = findEventById(itemId);
      const { end, predecessors = [], start, successors = [] } = primary;
      const interval = end - start;
      const newStart = DateTime.fromMillis(startTime).toJSDate();
      const newEnd = DateTime.fromMillis(startTime + interval).toJSDate();
      const toGroup = getGroupValue(group);
      let dependentsToUpdate: Event[] = [];

      if (isGanttViewEnabled) {
        setShowArrows(true);

        if (predecessors.length > 0 || successors.length > 0) {
          dependentsToUpdate = manageInvalidGanttDependencies(
            predecessors,
            successors,
            startTime,
            interval,
            primary.start,
          );
        }
      }

      setShowArrows(true);

      const fieldKey = getFieldKey(groupByField.field);
      const groupByData = groupByField && {
        color: get(groups, [group, 'color'], null),
        field: {
          [groupByField.field.name]: toGroup,
          ...(groupByField.field.relationship || groupByField.field.relatedField
            ? { [fieldKey]: toGroup?.id ?? null }
            : {}),
        },
      };

      return updateEvents([
        {
          end: newEnd,
          event: primary,
          groupBy: groupByData || null,
          start: newStart,
        },
        ...dependentsToUpdate,
      ]);
    },
    [
      findEventById,
      getGroupValue,
      groupByField,
      groups,
      isGanttViewEnabled,
      manageInvalidGanttDependencies,
      updateEvents,
    ],
  );

  const handleTimeChange = useCallback(
    (
      visibleTimeStart: number,
      visibleTimeEnd: number,
      updateScrollCanvas: (
        visibleTimeStart: number,
        visibleTimeEnd: number,
      ) => void,
    ) => {
      setTimelineProps((props) => ({
        ...props,
        visibleTimeStart,
        visibleTimeEnd,
      }));
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    },
    [setTimelineProps],
  );

  const handleItemSelect = useCallback(
    (itemId: ID) => {
      const event = findEventById(itemId);
      setItemSelected(event);
      setSelected([event.id]);
    },
    [findEventById],
  );

  const handleItemDeselect = useCallback(() => {
    setItemSelected(undefined);
    setSelected([]);
  }, []);

  const handleBoundsChange = useCallback(
    (canvasTimeStart: number, canvasTimeEnd: number) => {
      const date = (canvasTimeStart + canvasTimeEnd) / 2;
      updateXarrow();

      return pushQueryParams({
        [`_date${qsSuffix}`]: DateTime.fromMillis(date).toUTC().toISO(),
      });
    },
    [pushQueryParams, updateXarrow, qsSuffix],
  );

  const handleNavigationChange = useCallback(
    (action: string, toDate?: Date) => {
      const { visibleTimeStart, visibleTimeEnd } = timelineProps;
      const { unit, minimumZoom, maximumZoom, dragSnap, buffer } =
        viewConfig[currentView];
      const endTimeDuration = getDuration(currentView, unit);

      let timeStart = DateTime.now()
        .plus({ milliseconds: 1000 })
        .startOf('month');
      let timeEnd = timeStart.endOf('month');

      if (toDate) {
        timeStart = DateTime.fromJSDate(toDate).startOf(unit);
        timeEnd = timeStart.plus(endTimeDuration);
      } else {
        switch (action) {
          case 'NEXT':
            timeStart =
              DateTime.fromMillis(visibleTimeEnd)[
                currentView === Views.WORK_WEEK ? 'endOf' : 'startOf'
              ](unit);
            timeEnd = timeStart.plus(endTimeDuration);
            break;

          case 'PREV':
            timeEnd = DateTime.fromMillis(visibleTimeStart).startOf(unit);
            timeStart = timeEnd.minus(endTimeDuration);
            break;

          case 'TODAY':
          default:
            timeStart = DateTime.now()
              .plus({ milliseconds: 1000 })
              .startOf(unit);
            timeEnd = timeStart.plus(endTimeDuration);
            break;
        }
      }

      setTimelineProps({
        visibleTimeStart: timeStart.valueOf(),
        visibleTimeEnd: timeEnd.valueOf(),
        minZoom: minimumZoom,
        maxZoom: maximumZoom,
        dragSnap,
        buffer,
      });

      updateXarrow();

      return pushQueryParams({
        [`_date${qsSuffix}`]: timeStart.toUTC().toISO(),
      });
    },
    [currentView, timelineProps, updateXarrow, pushQueryParams, qsSuffix],
  );

  const itemRenderer = useCallback(
    ({ item, itemContext, getItemProps, getResizeProps }: any) => (
      <CollectionTimelineItem
        deselectItem={handleItemDeselect}
        getItemProps={getItemProps}
        getResizeProps={getResizeProps}
        isDarkModeEnabled={isDarkModeEnabled}
        isGanttViewEnabled={isGanttViewEnabled}
        item={item}
        itemContext={itemContext}
        primaryColor={theme.brandColorGroups.primary}
        recordRowLink={recordRowLink}
        Row={Row}
      />
    ),
    [
      handleItemDeselect,
      isDarkModeEnabled,
      isGanttViewEnabled,
      recordRowLink,
      Row,
      theme.brandColorGroups.primary,
    ],
  );

  const handleItemDrag = useCallback(() => {
    if (isGanttViewEnabled) {
      setShowArrows(false);
    }
  }, [isGanttViewEnabled, setShowArrows]);

  const Component = useMemo(
    () => (isGanttViewEnabled ? Xwrapper : 'div'),
    [isGanttViewEnabled],
  );

  useEffect(() => {
    if (!itemSelected) {
      return;
    }

    const handleClickOutside = (event: MouseEvent) => {
      const isClickInside =
        event.target &&
        Array.from(
          document
            .querySelectorAll('.timeline-event, .timeline-popover')
            .values(),
        ).some((popover) => popover.contains(event.target as Node));

      if (!isClickInside) {
        handleItemDeselect();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [itemSelected, handleItemDeselect]);

  return (
    <>
      <CalendarToolbar
        date={date ? new Date(date) : undefined}
        enableShortcuts={enableShortcuts}
        label={label}
        loading={loading}
        localizer={localizer}
        onNavigate={handleNavigationChange}
        onView={setView}
        view={currentView}
        views={defaultViews}
      />
      <Component className="flex h-full w-full flex-grow flex-col">
        {events.length > 0 && isGanttViewEnabled && (
          <CollectionGantt
            events={events}
            showArrows={showArrows}
            theme={theme}
          />
        )}
        <Timeline
          canChangeGroup={!!groupByField && isTopLevelField}
          canMove={enableDragAndDropEdit && canUpdateEventDates}
          className="flex h-full w-full select-none flex-col"
          groupRenderer={GroupRenderer}
          groups={groups}
          horizontalLineClassNamesForGroup={() =>
            isDarkModeEnabled ? ['rct-hl-dark'] : ['rct-hl-light']
          }
          itemRenderer={itemRenderer}
          items={events}
          keys={keys}
          lineHeight={LINE_HEIGHT}
          onBoundsChange={handleBoundsChange}
          onItemDrag={handleItemDrag}
          onItemMove={handleItemMove}
          onItemResize={handleItemResize}
          onItemSelect={handleItemSelect}
          onItemDeselect={handleItemDeselect}
          onTimeChange={handleTimeChange}
          selected={selected}
          sidebarWidth={groupByField ? SIDEBAR_WIDTH : 0}
          stackItems={true}
          useResizeHandle={true}
          {...timelineProps}
        >
          <TimelineHeaders
            className="sticky"
            calendarHeaderStyle={{ border: 0 }}
            style={{ border: 0 }}
          >
            <SidebarHeader>
              {({ getRootProps }: any) => (
                <div
                  className={classNames(
                    `flex items-center justify-center text-${theme.textColors.body}`,
                    {
                      [`bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`]:
                        isDarkModeEnabled,
                      [`bg-${theme.surfaceColors.light}`]: !isDarkModeEnabled,
                      'border-2': groupByField,
                    },
                  )}
                  {...getRootProps()}
                />
              )}
            </SidebarHeader>
            <DateHeader
              unit="month"
              labelFormat="MMMM, YYYY"
              intervalRenderer={({
                getIntervalProps,
                intervalContext,
              }: any) => (
                <div
                  {...getIntervalProps()}
                  className={classNames(
                    `flex h-full items-center justify-center border-2 border-r-0 text-${theme.textColors.body}`,
                    isDarkModeEnabled
                      ? `bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`
                      : `bg-${theme.surfaceColors.light}`,
                  )}
                >
                  {intervalContext.intervalText}
                </div>
              )}
            />
            <CustomMarker date={Date.now()}>
              {({ styles }: any) => (
                <div
                  style={{
                    ...styles,
                    width: '2px',
                    background: '',
                  }}
                  className={classNames(
                    `h-full bg-${theme.brandColors.primary} opacity-50`,
                  )}
                />
              )}
            </CustomMarker>
            <DateHeader
              unit={unit}
              labelFormat={labelFormat}
              intervalRenderer={({
                getIntervalProps,
                intervalContext,
              }: any) => (
                <div
                  {...getIntervalProps()}
                  className={classNames(
                    `flex h-full items-center border-b-2 pl-2 text-center text-xs text-${theme.textColors.body}`,
                    isDarkModeEnabled
                      ? `bg-${theme.surfaceColors.dark} ${darkModeColors.borders.two}`
                      : `bg-${theme.surfaceColors.light}`,
                  )}
                >
                  {intervalContext.intervalText}
                </div>
              )}
            />
          </TimelineHeaders>
        </Timeline>
      </Component>
    </>
  );
};

export default CollectionTimeline;
