import { useCallback, useMemo, useRef, useState } from 'react';
import SafeStorage from '../SafeStorage';

const useLocalStorageState = <T = any>(
  localStorageKey: string,
  defaultValue: any,
) => {
  const safeStorage = useMemo(() => new SafeStorage<T>(), []);
  const [value, setValue] = useState<T>(
    safeStorage.getJson(localStorageKey, defaultValue),
  );
  const cacheVal = useRef<T>(value);

  const handleSetValue = useCallback(
    (newValue: T | ((prevValue: T) => T)) => {
      if (newValue instanceof Function) {
        cacheVal.current = newValue(cacheVal.current);
      } else {
        cacheVal.current = newValue;
      }

      setValue(cacheVal.current);
      safeStorage.setJson(localStorageKey, cacheVal.current);
    },
    [localStorageKey, safeStorage],
  );

  return [value, handleSetValue] as const;
};

export default useLocalStorageState;
