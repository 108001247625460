import { useMemo } from 'react';
import kebabCase from 'lodash/kebabCase';
import { Link } from 'react-router-dom';
import withVisibilityRules from '../../../components/canvas/withVisibilityRules';
import { ViewTab } from '../../../models/Element';
import { Project } from '../../../models/Project';
import useRecordPreview from '../../../utils/hooks/useRecordPreview';
import useRouter from '../../../utils/hooks/useRouter';
import TabBody from './TabBody';

const getTabLink = (
  rootPathname: any,
  recordId: any,
  queryRecordId: any,
  tabPath: any,
  search: any,
  preview = false,
) =>
  `${rootPathname}${
    recordId ? '' : `/${preview ? 'preview' : 'view'}/${queryRecordId}`
  }/${tabPath}${search}`;

const scrollToTop = () =>
  document.getElementById('inner-project-canvas')?.scrollTo(0, 0);

interface RecordViewTabItemProps {
  className?: string;
  editorMode: string;
  is: React.ElementType;
  project: Project;
  queryRecordId: string;
  recordId: string;
  recordScope: Record<string, any>;
  rootPathname: string;
  tab: string;
  tabDef: ViewTab;
  tabIndex: number;
}

const RecordViewTabItem = ({
  className,
  editorMode,
  is,
  project,
  queryRecordId,
  recordId,
  recordScope,
  rootPathname,
  tab,
  tabDef,
  tabIndex,
  ...rest
}: RecordViewTabItemProps) => {
  const { location } = useRouter();
  const [preview] = useRecordPreview();

  const tabPath = kebabCase(tabDef.title);

  const NavItemWithVisibilityRules = useMemo(
    () => withVisibilityRules(is, editorMode, tabDef.visibilityRules),
    [is, editorMode, tabDef.visibilityRules],
  );

  const to = useMemo(
    () =>
      getTabLink(
        rootPathname,
        recordId,
        queryRecordId,
        tabPath,
        location.search,
        preview,
      ),
    [rootPathname, recordId, queryRecordId, tabPath, location, preview],
  );

  if (!tabDef || !tabDef.title) {
    return null;
  }

  return (
    <NavItemWithVisibilityRules
      active={tabPath === tab || (!tab && tabIndex === 0)}
      className={className}
      is={Link}
      onClick={scrollToTop}
      project={project}
      to={to}
      visibilityRulesScope={recordScope}
      {...rest}
    >
      <TabBody icon={tabDef.icon}>{tabDef.title}</TabBody>
    </NavItemWithVisibilityRules>
  );
};

export default RecordViewTabItem;
