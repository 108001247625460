import React from 'react';
import { useHistory } from 'react-router';
import {
  NOT_SETUP,
  REQUIRED,
  SETUP_REQUIRED,
} from '../constants/twoFactorAuth';
import { ProjectSettings } from '../models/Project';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import { useTwoFactorAuth } from '../utils/hooks/useTwoFactorAuth';
import { getText } from '../utils/lang';
import { TwoFactorAuthLogin } from './TwoFactorAuthLogin';
import { TwoFactorAuthSetup } from './TwoFactorAuthSetup';

interface TwoFactorAuthProps {
  projectName: string;
  settings: ProjectSettings;
  requiresSecondFactor:
    | typeof REQUIRED
    | typeof SETUP_REQUIRED
    | typeof NOT_SETUP;
  secondFactorAuthToken?: string;
  logoUrl?: string;
  redirectPath?: string;
}
export const TwoFactorAuth = ({
  projectName,
  requiresSecondFactor,
  secondFactorAuthToken,
  logoUrl,
  redirectPath,
}: TwoFactorAuthProps) => {
  const {
    generateOTP,
    generateOTPBackupCodes,
    verifyOTP,
    verifyOTPBackupCode,
    removeTwoFactorAuth,
  } = useAuth();
  const { push } = useHistory();

  const {
    setupStep,
    setSetupStep,
    otpAuthUrl,
    base32,
    errors,
    otpValue,
    setOtpValue,
    loading,
    handleVerify,
    handleVerifyOTPBackupCode,
    handleGenerate,
    handleGenerateBackupCodes,
    handleDismissTwoFactorAuthSetup,
  } = useTwoFactorAuth({
    secondFactorAuthToken,
    projectName,
    onFinish: () => push('/'),
    generateOTP,
    generateOTPBackupCodes,
    verifyOTP,
    verifyOTPBackupCode,
    removeTwoFactorAuth,
    requiresSecondFactor,
  });

  const updateUserCache = useUpdateUserCache();

  return (
    <div className="flex w-full flex-col items-center justify-center overflow-hidden bg-gray-100">
      <div className="flex w-full justify-center px-6 py-7 sm:px-0">
        <div className="w-full max-w-sm">
          {logoUrl && (
            <img className="mx-auto h-10 w-auto" src={logoUrl} alt="logo" />
          )}
          <h2 className="mt-4 text-center text-xl font-extrabold leading-9 text-gray-900">
            {getText(
              'auth.twoFactorAuth',
              `${requiresSecondFactor === REQUIRED ? 'title' : 'setup.title'}`,
            )}
          </h2>
          <p className="mt-1.5 text-center text-sm leading-5 text-gray-600 sm:px-2">
            {getText('auth.twoFactorAuth.setup.subtitle')}
          </p>
          <div className="mx-2 mt-6 flex flex-col items-center overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
            {requiresSecondFactor === SETUP_REQUIRED ||
            requiresSecondFactor === NOT_SETUP ? (
              <TwoFactorAuthSetup
                secondFactorAuthToken={secondFactorAuthToken}
                onFinish={() => push('/')}
                includeButtons={true}
                otpAuthUrl={otpAuthUrl}
                step={setupStep}
                handleGenerate={handleGenerate}
                errors={errors}
                handleVerify={handleVerify}
                handleGenerateBackupCodes={handleGenerateBackupCodes}
                base32={base32}
                otpValue={otpValue}
                setOtpValue={setOtpValue}
                loading={loading}
                setStep={setSetupStep}
                canSkip={requiresSecondFactor === NOT_SETUP}
                onSkip={handleDismissTwoFactorAuthSetup}
              />
            ) : (
              <TwoFactorAuthLogin
                loading={loading}
                errors={errors}
                secondFactorAuthToken={secondFactorAuthToken}
                redirectPath={redirectPath}
                updateUserCache={updateUserCache}
                handleVerify={handleVerify}
                setOtpValue={setOtpValue}
                otpValue={otpValue}
                handleVerifyOTPBackupCode={handleVerifyOTPBackupCode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
