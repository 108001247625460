import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextInput as TailwindTextInput,
  getTailwindClassNames,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconEye, IconEyeOff, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { LIGHT } from '../../constants/surface';
import { XS } from '../../constants/tShirtSizes';
import { Surface, Theme } from '../../models';
import { AdvancedSpacing, Spacing } from '../../models/spacing';
import { validationBorder } from '../../utils';
import ErrorText from '../form/ErrorText';
import { Loader } from '../loading';
import { InputStyle, ROUNDED_LARGE } from './inputStyles';
import themeStyles from './inputTheme';

export const getInputStyles = ({
  bg,
  theme,
  m,
  disabled,
  surface,
  style,
}: any) => {
  const { surface: themeSurface = 'default' } = theme.textInput || {};
  const surfaceValue = surface || themeSurface;
  const bgValue = bg || theme.surfaceColors[surfaceValue];
  const borderColorValue = theme.borderColors[surfaceValue];
  const textColor = theme.textColors.on[surfaceValue] || theme.textColors.body;
  const disabledBg = surfaceValue === LIGHT ? 'gray-100' : bgValue;

  return {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...themeStyles(borderColorValue)[style],
    bg: disabled ? disabledBg : bgValue,
    m: { b: 0, ...m },
    text: textColor,
  };
};

export interface TextInputProps {
  autoComplete?: 'off' | boolean;
  autoFocus?: boolean;
  bg?: string;
  border?: string | string[] | number | (string | boolean)[] | boolean;
  className?: string;
  clearable?: boolean;
  debounceMs?: number;
  disabled?: boolean;
  h?: number;
  icon?: JSX.Element | undefined;
  id?: string;
  inline?: boolean;
  loading?: boolean;
  m?: Spacing;
  name?: string;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  p?: AdvancedSpacing;
  placeholder?: React.ReactNode | string;
  readOnly?: boolean;
  required?: boolean;
  rows?: number;
  style?: InputStyle;
  styleObj?: any;
  surface?: Surface;
  text?: string[];
  type?: 'text' | 'password' | 'number';
  valid?: boolean;
  validationError?: any;
  value: string | number | null | undefined;
}

type TextInputPropsWithTheme = TextInputProps & { theme: Theme };

export const TextInput = forwardRef<any, TextInputPropsWithTheme>(
  (
    {
      bg,
      className,
      clearable,
      debounceMs,
      disabled,
      h,
      icon,
      inline,
      loading = false,
      m,
      onChange = () => null,
      p,
      readOnly,
      style,
      styleObj,
      surface,
      theme,
      type,
      valid,
      validationError,
      value,
      ...rest
    },
    ref,
  ) => {
    const isPassword = useMemo(() => type === 'password', [type]);
    const [localValue, setLocalValue] = useState(value);
    const [reveal, setReveal] = useState(!isPassword);
    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const onInputChange = useMemo(
      () =>
        debounceMs && debounceMs > 0
          ? debounce(onChange, debounceMs)
          : onChange,
      [debounceMs, onChange],
    );

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onInputChange(event);
        setLocalValue(event.target.value);
      },
      [onInputChange],
    );

    const handleRevealChange = useCallback(
      (event: any) => {
        event.stopPropagation();
        setReveal(!reveal);
      },
      [reveal],
    );

    const handleClear = useCallback(() => {
      // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onInputChange({ target: { value: '' } });
      setLocalValue('');
    }, [onInputChange]);

    const typeMaybeRevealed = useMemo(
      () => (isPassword ? (reveal ? '' : type) : type),
      [isPassword, reveal, type],
    );

    const styleProps = useMemo(
      () =>
        getInputStyles({
          bg,
          theme,
          m,
          disabled: disabled,
          surface,
          style,
        }),
      [bg, disabled, m, style, surface, theme],
    );
    const padding = useMemo(() => {
      const defaulted = p ?? { x: theme.spacing.sm, y: theme.spacing.sm };

      if (icon) {
        return {
          ...defaulted,
          x: undefined,
          l: defaulted.l || Math.ceil((defaulted.x ?? 0) + 4),
          r: defaulted.l || defaulted.x,
        };
      }

      return clearable || isPassword || loading
        ? {
            ...defaulted,
            x: undefined,
            l: defaulted.l || defaulted.x,
            r: defaulted.r || Math.ceil((defaulted.x ?? 0) + 5),
          }
        : defaulted;
    }, [clearable, icon, isPassword, loading, p, theme.spacing.sm]);

    const buttonIcon = useMemo(() => {
      if (loading) {
        return <Loader size={XS} />;
      }

      if (clearable) {
        return <IconX size={18} />;
      }

      return reveal ? <IconEye size={18} /> : <IconEyeOff size={18} />;
    }, [clearable, loading, reveal]);

    return (
      <>
        <div
          className={classNames('flex w-full', {
            relative: clearable || isPassword || icon || loading,
          })}
        >
          {icon && (
            <span className="absolute left-1.5 top-auto z-30 flex h-full items-center opacity-50">
              {icon}
            </span>
          )}
          <TailwindTextInput
            className={classNames(
              className,
              'rounded-lg sm:h-10 sm:text-base',
              {
                'focus:ring-2': !inline,
                'h-8': !h,
                [`h-${h}`]: h,
              },
              validationBorder(validationError || !valid),
            )}
            disabled={disabled}
            onChange={handleOnChange}
            {...styleProps}
            readOnly={readOnly}
            type={typeMaybeRevealed}
            style={styleObj}
            text={[styleProps.text, 'sm']}
            p={padding}
            {...rest}
            opacity={100}
            value={localValue || ''}
            ref={ref}
          />
          {((clearable && localValue && String(localValue).length > 0) ||
            isPassword ||
            loading) && (
            <button
              type="button"
              className={classNames(
                getTailwindClassNames({
                  p: { r: p?.r ?? p?.x ?? theme.spacing.sm },
                  text: styleProps.text,
                }),
                'absolute right-0 top-auto z-20 h-full text-opacity-50 hover:text-opacity-100 focus:text-opacity-100',
              )}
              onClick={
                loading
                  ? undefined
                  : clearable
                    ? handleClear
                    : handleRevealChange
              }
              tabIndex={-1}
            >
              {buttonIcon}
            </button>
          )}
        </div>
        {validationError && (
          <ErrorText data-testid={`${(rest as any).id}-error`}>
            {validationError}
          </ErrorText>
        )}
      </>
    );
  },
);

TextInput.defaultProps = {
  className: '',
  style: ROUNDED_LARGE,
  readOnly: false,
  placeholder: 'Placeholder',
  valid: true,
  clearable: false,
};

export default withTheme(TextInput) as React.FC<
  TextInputProps & { ref?: React.Ref<TextInputProps> | undefined }
>;
