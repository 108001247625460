import React from 'react';
import { getTailwindClassNames } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  ARRAY,
  COMBO,
  DATA_PROP,
  GROUP,
  KEY_MAP,
  STRING,
  VARIABLE,
} from '../constants/elementPropTypeTypes';
import { CONTENT } from '../constants/elements';
import { StringPropSegment } from '../models/Element';
import { Project } from '../models/Project';
import ElementPropType from '../models/elementPropTypes/ElementPropType';
import StringType from '../models/elementPropTypes/StringPropType';
import { resolveDataValue, resolveSingleDataItem } from './data';
import { getText } from './lang';
import { transformTailwindProps } from './styles';

const resolveStyledContentItems = (
  dataValue: StringPropSegment,
  scope: Record<string, any>,
  project: Project,
  localeName?: string,
  locale?: Locale,
) =>
  !Array.isArray(dataValue)
    ? dataValue
    : dataValue
        .map((dataItem) => {
          if (dataItem.text) {
            return dataItem;
          }

          if (dataItem.data) {
            const text = resolveSingleDataItem(
              dataItem,
              scope,
              project,
              false,
              localeName,
              locale,
            );

            return {
              text,
              styleProps: dataItem.styleProps,
            };
          }

          return null;
        })
        .filter((item) => item && item.text !== undefined && item.text !== null)
        .reduce(
          (spanAcc, { text = '', styleProps = {} }, index) => [
            ...spanAcc,
            ...String(text)
              .split('\n')
              .map((textPart, partIndex) => (
                <>
                  {partIndex !== 0 && <br />}
                  {textPart && (
                    <span
                      key={`${index}-${partIndex}-${textPart}`}
                      className={classNames(
                        getTailwindClassNames(
                          transformTailwindProps(styleProps),
                        ),
                      )}
                    >
                      {textPart}
                    </span>
                  )}
                </>
              )),
          ],
          [],
        );

export const resolvePropDataValue = (
  rawValue: any,
  propPath: any,
  scope: any,
  elementType: any,
  project: any,
  rawValues: any,
  localeName?: string,
  locale?: Locale,
) =>
  elementType !== CONTENT || !propPath.includes('items')
    ? resolveDataValue(rawValue, scope, project, rawValues, localeName, locale)
    : resolveStyledContentItems(rawValue, scope, project, localeName, locale);

export const resolveDataPropDataValue = (
  rawValue: any,
  scope: Record<string, any>,
  project: Project,
  rawValues: any,
  localeName?: string,
  locale?: Locale,
) =>
  resolveSingleDataItem(
    { data: rawValue },
    scope,
    project,
    rawValues,
    localeName,
    locale,
  );

export const resolveDynamicPropValue = (
  rawValue: any,
  propKey: any,
  propDefinition: any,
  scope: any,
  element: any,
  project: any,
  elementPath: any,
  forceResolve: any,
  shouldResolveValue: any,
  localeName?: string,
  locale?: Locale,
) => {
  switch (propDefinition.type) {
    case STRING: {
      return resolvePropDataValue(
        rawValue,
        [propKey],
        scope,
        element.type,
        project,
        propDefinition.rawValue,
        localeName,
        locale,
      );
    }
    case DATA_PROP: {
      return resolveDataPropDataValue(
        rawValue,
        scope,
        project,
        true,
        localeName,
        locale,
      );
    }
    case ARRAY: {
      return rawValue && Array.isArray(rawValue)
        ? rawValue.map((arrayPropItem, itemIndex): any =>
            reduceDynamicPropValues(
              propDefinition.mergeDynamicPropsShape(
                element.props,
                get(rawValue, [itemIndex]),
                null,
                project,
              ),
              get(rawValue, [itemIndex]),
              scope,
              element,
              project,
              elementPath,
              forceResolve,
              shouldResolveValue,
              localeName,
              locale,
            ),
          )
        : rawValue;
    }
    case COMBO: {
      const resolvedComboProps: any = reduceDynamicPropValues(
        propDefinition.shape,
        rawValue || {},
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
        localeName,
        locale,
      );

      return propDefinition.resolve(
        resolvedComboProps,
        project,
        scope,
        false,
        elementPath,
      );
    }
    case KEY_MAP: {
      if (!rawValue) {
        return rawValue;
      }

      return Object.entries(rawValue).reduce((mapAcc, [key, mapValue]) => {
        const resolvedComboProps: any = reduceDynamicPropValues(
          propDefinition.shape,
          mapValue || {},
          scope,
          element,
          project,
          elementPath,
          forceResolve,
          shouldResolveValue,
          localeName,
          locale,
        );

        return {
          ...mapAcc,
          [key]: propDefinition.resolve(
            resolvedComboProps,
            project,
            scope,
            false,
            elementPath,
          ),
        };
      }, {});
    }
    case GROUP: {
      return reduceDynamicPropValues(
        propDefinition.shape,
        rawValue,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
        localeName,
        locale,
      );
    }
    case VARIABLE: {
      const val = rawValue || {};
      const resolvedValue: any = reduceDynamicPropValues(
        { value: propDefinition.propType },
        val,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
        localeName,
        locale,
      );

      const resolvedLabel: any = reduceDynamicPropValues(
        { label: new StringType() },
        val,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
        localeName,
        locale,
      );

      return {
        hidden: val.hidden,
        value:
          resolvedValue.value !== undefined
            ? resolvedValue.value
            : propDefinition.placeholder(0),
        label:
          resolvedLabel.label || getText('core.COLLECTION.vars.labelField'),
      };
    }
    default:
      return rawValue;
  }
};

// Sometimes a value that's resolved as an array, like an array of MULTI_OPTION values
// Could be confused as a raw StringProp value that is yet to be resolved
// This check ensures that we only resolve such values if some of the values in the array have
// data or text props
const isResolvedArrayValue = (rawValue: any, propDefinition: any) =>
  propDefinition.type === STRING &&
  rawValue &&
  Array.isArray(rawValue) &&
  rawValue.length > 0 &&
  !rawValue.some(
    (dataItem) =>
      typeof dataItem === 'object' &&
      (!isNil(dataItem.data) || !isNil(dataItem.text)),
  );

const defaultShouldResolveValue: (
  rawPropValue: any,
  propDefinition: ElementPropType,
) => boolean = () => true;

export const reduceDynamicPropValues = (
  propsShape: any,
  elementBaseProps: any,
  scope: any,
  element: any,
  project: any,
  elementPath: any,
  forceResolve = false,
  shouldResolveValue: (
    rawPropValue: any,
    propDefinition: ElementPropType,
  ) => boolean = defaultShouldResolveValue,
  localeName?: string,
  locale?: Locale,
) =>
  Object.entries(propsShape || {}).reduce(
    (elementProps, [propKey, propDefinition]): any => {
      const rawValue = get(elementProps, [propKey]);

      if (
        (!(propDefinition as any).automaticallyResolve && !forceResolve) ||
        (shouldResolveValue &&
          // @ts-expect-error TS(2554): Expected 0 arguments, but got 3.
          !shouldResolveValue(rawValue, propDefinition, propKey)) ||
        isResolvedArrayValue(rawValue, propDefinition)
      ) {
        return set([propKey], rawValue, elementProps);
      }

      const resolvedValue = resolveDynamicPropValue(
        (propDefinition as any).type === GROUP ? elementProps : rawValue,
        propKey,
        propDefinition,
        scope,
        element,
        project,
        elementPath,
        forceResolve,
        shouldResolveValue,
        localeName,
        locale,
      );

      if ((propDefinition as any).type === GROUP) {
        return resolvedValue;
      }

      return set([propKey], resolvedValue, elementProps);
    },
    elementBaseProps,
  );

export const skipPropResolvingByValueIds =
  (idsToSkip: string[] = []) =>
  (rawPropValue: any, propDefinition: ElementPropType) => {
    if (rawPropValue === undefined) {
      return true;
    }

    if (propDefinition.type === STRING && Array.isArray(rawPropValue)) {
      return !rawPropValue.some((dataItem) =>
        idsToSkip.includes(get(dataItem, 'data.id')),
      );
    }

    if (propDefinition.type === DATA_PROP && rawPropValue) {
      return !idsToSkip.includes(get(rawPropValue, 'id'));
    }

    return true;
  };
