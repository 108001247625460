import { KeyboardEvent, MouseEvent, useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconMessage } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Badge, Theme } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import { CMD, KEY_SQUARE_BRACKETS_CLOSE } from '../../../constants/shortcuts';
import { RecordValue } from '../../../models/Record';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRecordPreview from '../../../utils/hooks/useRecordPreview';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import KeyboardShortcutTooltip from './KeyboardShortcutTooltip';

interface RecordCommentsButtonProps {
  record: Record<string, RecordValue>;
  theme: Theme;
}

const RecordCommentsButton = ({ record, theme }: RecordCommentsButtonProps) => {
  const [isDarkModeEnabled] = useDarkMode();
  const {
    pushQueryParams,
    query: { _comments: commentsParam, _commentsFooter: commentsFooterParam },
  } = useRouter();
  const [preview] = useRecordPreview();
  const commentCount = get(
    record,
    'nolocoCommentsCollection.totalCount',
    0,
  ) as number;
  const primaryColor = theme.brandColors.primary;

  const onToggleCommentSidebar = useCallback(
    (event: KeyboardEvent<Element> | MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.preventDefault();
      }

      const commentsAreOpen =
        (preview ? commentsFooterParam : commentsParam) === 'true';

      pushQueryParams({
        [preview ? '_commentsFooter' : '_comments']: commentsAreOpen
          ? 'false'
          : 'true',
      });
    },
    [commentsParam, pushQueryParams, preview, commentsFooterParam],
  );

  useOnKeyPress(KEY_SQUARE_BRACKETS_CLOSE, onToggleCommentSidebar, {
    ctrlKey: true,
    enabled: true,
  });

  if (preview) {
    return null;
  }

  return (
    <div className="flex items-center justify-center sm:w-1/12">
      <KeyboardShortcutTooltip
        buildMode={false}
        keys={[CMD, KEY_SQUARE_BRACKETS_CLOSE]}
        label={getText('core.COMMENT.toggle.tooltip')}
        placement="left"
      >
        <button className="relative" onClick={onToggleCommentSidebar}>
          <Badge
            className="flex items-center"
            m={{ r: 0 }}
            p={{ x: 1, y: 0.5 }}
            type={commentsParam || commentsFooterParam ? 'filled' : 'outline'}
          >
            <IconMessage
              size={16}
              color={
                isDarkModeEnabled
                  ? darkModeColors.icons.primary
                  : 'currentColor'
              }
            />
          </Badge>
          {commentCount > 0 && (
            <span
              className={classNames(
                'absolute left-4 top-0 -mt-3 mr-1 flex h-5 items-center justify-center rounded-lg border px-1.5 py-0.5 text-center text-xs text-white',
                `bg-${primaryColor}`,
              )}
            >
              {commentCount}
            </span>
          )}
        </button>
      </KeyboardShortcutTooltip>
    </div>
  );
};

export default withTheme(RecordCommentsButton);
