import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import asyncThrottle from '@jcoreio/async-throttle';
import EventEmitter from 'events';
import throttle from 'lodash/throttle';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_CORE_URI, BASE_WS_URI } from '../../constants/auth';
import { getJitteredPollInterval } from '../../constants/polling';
import {
  DATA_INVALIDATION,
  NOTIFICATION,
  SCHEMA_INVALIDATION,
} from '../../constants/serverEvents';
import { Notification } from '../../models/Notification';
import { setDataTypes, setDraftDataTypes } from '../../reducers/project';
import { editorModeSelector } from '../../selectors/elementsSelectors';
import { legacyNotificationIcon, useInfoAlert } from './useAlerts';
import { useAuth } from './useAuth';
import useGetFeatureFlagValue, {
  DATA_INVALIDATION_DEFAULT_THROTTLE_MS,
} from './useGetFeatureFlagValue';
import { useIsBuilder } from './useIsBuilder';
import useIsWindowVisible from './useIsWindowVisible';
import useLocalStorageState from './useLocalStorageState';
import { useOpenUrl } from './useOpenUrl';
import { useReadNotification } from './useReadNotification';
import useRouter from './useRouter';
import useWebsocketWithHeartbeat from './useWebsocketWithHeartbeat';

interface BuilderEventContext {
  projectName: string;
}

interface UserEventContext {
  dataInvalidator: EventEmitter;
  notificationFetcher: EventEmitter;
}

interface DataInvalidationPayload {
  dataTypeName?: string;
}

const useOnSchemaInvalidationEvent = (context: BuilderEventContext) => {
  const dispatch = useDispatch();
  const { token } = useAuth();

  const { pathname } = useRouter();
  const adminRoute = useMemo(() => pathname.startsWith('/_/'), [pathname]);
  const editorMode = useSelector(editorModeSelector);

  const fetchProject = useCallback(
    async () =>
      await fetch(`${BASE_CORE_URI}/project/${context.projectName}/dataTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json()),
    [context.projectName, token],
  );

  const refreshDataTypes = useCallback(
    () =>
      fetchProject().then(({ data: projectData }) => {
        if (projectData && projectData.project) {
          if (adminRoute || editorMode) {
            dispatch(setDataTypes(projectData.project.dataTypes));
          } else {
            dispatch(setDraftDataTypes(projectData.project.dataTypes));
          }
        }
      }),
    [adminRoute, dispatch, editorMode, fetchProject],
  );

  return useMemo(
    () =>
      throttle(() => refreshDataTypes(), 1000, {
        leading: true,
        trailing: true,
      }),
    [refreshDataTypes],
  );
};

const useOnBuilderEventMessage = (context: BuilderEventContext) => {
  const onSchemaInvalidationEvent = useOnSchemaInvalidationEvent(context);

  return useCallback(
    (event: MessageEvent) => {
      try {
        const { type } = JSON.parse(event.data);

        switch (type) {
          case SCHEMA_INVALIDATION: {
            return onSchemaInvalidationEvent();
          }
          default: {
            console.error(
              '[Server Event /builder Webhook] unknown event type:',
              type,
            );
            break;
          }
        }
      } catch (e) {
        console.error('[Server Event /builder Webhook] onMessage:', e);
      }
    },
    [onSchemaInvalidationEvent],
  );
};

const useOnDataInvalidationEvent = (context: UserEventContext) => {
  const windowIsVisible = useRef(!document.hidden);
  const pendingUpdateOnWindowVisible = useRef(false);

  const onInvalidate = useCallback(
    (payload?: DataInvalidationPayload) =>
      context.dataInvalidator.emit('invalidate', payload),
    [context.dataInvalidator],
  );

  const handleInvalidationEvent = useCallback(
    (payload?: DataInvalidationPayload) => {
      if (windowIsVisible.current) {
        onInvalidate(payload);
      } else {
        pendingUpdateOnWindowVisible.current = true;
      }
    },
    [onInvalidate],
  );

  const onWindowFocusedChanged = useCallback(
    (isVisible: boolean) => {
      if (isVisible && pendingUpdateOnWindowVisible.current) {
        onInvalidate();
        pendingUpdateOnWindowVisible.current = false;
      }

      windowIsVisible.current = isVisible;
    },
    [onInvalidate],
  );

  useIsWindowVisible(onWindowFocusedChanged);

  return handleInvalidationEvent;
};

const useOnNotificationEvent = (context: UserEventContext) => {
  const infoAlert = useInfoAlert();
  const { markAsRead } = useReadNotification();
  const { push } = useRouter();
  const openUrl = useOpenUrl();

  const notificationAlert = useCallback(
    (notification: Notification) => {
      infoAlert(notification.message, {
        icon: legacyNotificationIcon({
          icon: notification.icon,
          iconType: notification.iconType,
        }),
        action: {
          label: notification.link ? 'View' : 'Dismiss',
          onClick: () => {
            markAsRead(notification);

            if (notification.link) {
              if (notification.link.startsWith('/')) {
                push(notification.link);
              } else {
                openUrl(notification.link, true);
              }
            }
          },
        },
        onDismiss: () => {
          markAsRead(notification);
        },
        duration: 10_000,
      });
    },
    [infoAlert, markAsRead, openUrl, push],
  );

  const debouncedRefetchNotifications = useMemo(
    () =>
      throttle(() => context.notificationFetcher.emit('refetch'), 1500, {
        leading: true,
        trailing: true,
      }),
    [context.notificationFetcher],
  );

  return useCallback(
    (notification: Notification) => {
      notificationAlert(notification);
      debouncedRefetchNotifications();
    },
    [debouncedRefetchNotifications, notificationAlert],
  );
};

const useOnUserEventMessage = (context: UserEventContext) => {
  const onDataInvalidationEvent = useOnDataInvalidationEvent(context);
  const onNotificationEvent = useOnNotificationEvent(context);

  const [hasPushSubscription] = useLocalStorageState(
    'noloco.push.subscribed',
    false,
  );

  return useCallback(
    (event: MessageEvent) => {
      try {
        const { type, payload } = JSON.parse(event.data);

        switch (type) {
          case DATA_INVALIDATION: {
            return onDataInvalidationEvent(payload);
          }
          case NOTIFICATION: {
            if (
              !hasPushSubscription ||
              window.Notification.permission !== 'granted'
            ) {
              return onNotificationEvent(payload);
            }
            break;
          }
          default: {
            console.error(
              '[Server Event /user Webhook] unknown event type:',
              type,
            );
            break;
          }
        }
      } catch (e) {
        console.error('[Server Event /user Webhook] onMessage:', e);
      }
    },
    [hasPushSubscription, onDataInvalidationEvent, onNotificationEvent],
  );
};

const useServerEventWebsocket = (
  projectName: string,
  path: string,
  onMessage: (event: MessageEvent) => void,
  connect = true,
) => {
  const { token } = useAuth();

  const reconnectInterval = useMemo(
    () => getJitteredPollInterval(8000, 0.8),
    [],
  );

  useWebsocketWithHeartbeat(
    `${BASE_WS_URI}/project/${projectName}/events/${path}?authToken=${token}`,
    {
      onMessage,
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: Infinity,
      reconnectInterval,
      retryOnError: true,
      onOpen: () => {
        console.info(`[Server Event /${path} Webhook] Status: connected`);
      },
      onClose: () => {
        console.info(`[Server Event /${path} Webhook] Status: disconnected`);
      },
      onError: (e) => {
        console.error(`[Server Event /${path} Webhook] Error:`, e);
      },
    },
    !!token && connect,
  );
};

const serverEventContext = createContext<
  (BuilderEventContext & UserEventContext) | undefined
>(undefined);

export const ProvideServerEvents = ({ children, projectName }: any) => {
  const { isBuilder } = useIsBuilder();

  const dataInvalidator = useMemo(() => new EventEmitter(), []);
  dataInvalidator.setMaxListeners(30);

  const notificationFetcher = useMemo(() => new EventEmitter(), []);
  notificationFetcher.setMaxListeners(30);

  const context = useMemo(
    () => ({
      dataInvalidator,
      notificationFetcher,
      projectName,
    }),
    [dataInvalidator, notificationFetcher, projectName],
  );

  const onBuilderEventMessage = useOnBuilderEventMessage(context);
  useServerEventWebsocket(
    projectName,
    'builder',
    onBuilderEventMessage,
    isBuilder ?? false,
  );

  const onUserEventMessage = useOnUserEventMessage(context);
  useServerEventWebsocket(projectName, 'user', onUserEventMessage);

  return (
    <serverEventContext.Provider value={context}>
      {children}
    </serverEventContext.Provider>
  );
};

export const useInvalidateProjectData = (
  onInvalidate: () => Promise<unknown>,
  {
    throttleMs,
    skip = false,
    dataTypesToRefreshQuery,
  }: {
    throttleMs?: number;
    skip?: boolean;
    dataTypesToRefreshQuery?: string[];
  } = {},
) => {
  const context = useContext(serverEventContext);

  const {
    query: { _fileId, _fileField },
  } = useRouter();

  const defaultThrottleMs = useGetFeatureFlagValue(
    DATA_INVALIDATION_DEFAULT_THROTTLE_MS,
    1500,
  );

  const throttledOnInvalidate = useMemo(() => {
    let handler: (payload?: DataInvalidationPayload) => Promise<unknown> =
      onInvalidate;

    /*
     * If dataTypesToTrack is provided, only invalidate if the data type that was
     * invalidated is in the list of data types to track.
     *
     * Otherwise, invalidate for all data types. We should be able to make this
     * the default behavior in the future, but want to roll it out slowly.
     */
    if (dataTypesToRefreshQuery) {
      const dataTypesToTrackSet = new Set(dataTypesToRefreshQuery);
      handler = async (payload?: DataInvalidationPayload) => {
        // Without a dataTypeName, we assume that all data types are invalidated
        if (!payload?.dataTypeName) {
          return onInvalidate();
        }

        if (dataTypesToTrackSet.has(payload.dataTypeName)) {
          return onInvalidate();
        }
      };
    }

    return asyncThrottle(handler, throttleMs ?? defaultThrottleMs);
  }, [dataTypesToRefreshQuery, defaultThrottleMs, onInvalidate, throttleMs]);

  useEffect(() => {
    if (!context) {
      return;
    }

    const { dataInvalidator } = context;

    if (!skip && !_fileId && !_fileField) {
      dataInvalidator.on('invalidate', throttledOnInvalidate);

      return () => {
        dataInvalidator.removeListener('invalidate', throttledOnInvalidate);
      };
    }

    if (skip || _fileId || _fileField) {
      dataInvalidator.removeListener('invalidate', throttledOnInvalidate);
      throttledOnInvalidate.cancel();
    }
  }, [_fileField, _fileId, context, skip, throttledOnInvalidate]);
};

export const useRefetchNotifications = (onRefetch: any) => {
  const context = useContext(serverEventContext);

  useEffect(() => {
    if (context && onRefetch) {
      const { notificationFetcher } = context;
      notificationFetcher.on('refetch', onRefetch);

      return () => {
        notificationFetcher.removeListener('refetch', onRefetch);
      };
    }
  }, [context, onRefetch]);
};
