import React, { useEffect, useMemo } from 'react';
import { TailwindThemeProvider } from '@darraghmckay/tailwind-react-ui';
import tailwindColors from 'tailwindcss/colors';
import { Theme } from '../models';

const hexToHslCss = (hex: string): string => {
  // Convert hex to RGB first
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return `${Math.round(h * 360)} ${Math.round(s * 100)}% ${Math.round(l * 100)}%`;
};

export default function ProjectThemeProvider({
  children,
  theme,
  darkMode,
}: {
  children: React.ReactNode;
  theme: Theme;
  darkMode?: boolean;
}) {
  const primaryColor = useMemo(() => {
    const primaryColor = theme.brandColorGroups.primary;

    if (primaryColor === 'nlc_primary') {
      return hexToHslCss(theme.customColors?.primary?.[500] ?? '#000000');
    }

    if (Object.keys(tailwindColors).includes(primaryColor)) {
      return hexToHslCss(
        tailwindColors[
          theme.brandColorGroups.primary as keyof typeof tailwindColors
        ][500] ?? '#000000',
      );
    }

    return hexToHslCss('#000000');
  }, [theme]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    document.documentElement.classList.toggle('light', !darkMode);
  }, [darkMode]);

  return (
    <>
      <style>
        {`
        :root {
          --primary: ${primaryColor};
        }

        .dark {
          --primary: ${primaryColor};
        }
      `}
      </style>
      <TailwindThemeProvider theme={theme}>{children}</TailwindThemeProvider>
    </>
  );
}
