import { useCallback, useMemo, useState } from 'react';
import { IconArrowNarrowRight, IconTrash } from '@tabler/icons-react';
import get from 'lodash/get';
import { Button, SelectInput, SwitchButton } from '@noloco/components';
import { LINK } from '@noloco/components/src/components/button/buttonTypes';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import tShirtSizes, { MD } from '@noloco/components/src/constants/tShirtSizes';
import { DANGER } from '@noloco/components/src/constants/variants';
import DataTypeInput from '@noloco/ui/src/components/canvas/DataTypeInput';
import CopyEditor from '@noloco/ui/src/components/editor/customerEditors/CopyEditor';
import FormFieldsEditor from '@noloco/ui/src/components/editor/customerEditors/FormFieldsEditor';
import IframeSectionEditor from '@noloco/ui/src/components/editor/customerEditors/IframeEditor';
import ViewRecordActionEditor from '@noloco/ui/src/components/editor/customerEditors/ViewRecordActionEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  ActionButtonExecutionType,
  MODAL,
  ONE_CLICK,
} from '../../../constants/actionButtons';
import {
  ADD_COMMENT,
  ActionType,
  COPY,
  CREATE,
  DELETE,
  IFRAME,
  NAVIGATE,
  ON_DEMAND,
  SCAN_BARCODE,
  UPDATE,
  VIEW,
} from '../../../constants/actionTypes';
import actionsConfig from '../../../constants/actions';
import { DECIMAL, INTEGER, TEXT } from '../../../constants/dataTypes';
import { PAGE } from '../../../constants/linkTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import {
  Action,
  Element,
  ElementPath,
  ScanActionType,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { buildActionScope } from '../../../utils/actions';
import { getText } from '../../../utils/lang';
import { isMultiField } from '../../../utils/relationships';
import { RECORD_SCOPE } from '../../../utils/scope';
import BuildModeInput from '../BuildModeInput';
import BuildModeLabel from '../BuildModeLabel';
import BuildModeLinkEditor from '../BuildModeLinkEditor';
import BuildModeSwitchSection from '../BuildModeSwitchSection';
import AddCommentActionButtonEditor from './AddCommentActionButtonEditor';

interface ActionTypeOption {
  icon: JSX.Element;
  label: React.ReactNode;
  value: ActionType;
}

interface BuildModeActionItemEditorProps {
  action: Action;
  actionIndex: number;
  actionTypeOptions: ActionTypeOption[];
  elementPath: ElementPath;
  execution: ActionButtonExecutionType;
  initialEventState: any;
  onRemoveAction: (action: Action) => void;
  parentDataType: DataType;
  previousActions: Action[];
  project: Project;
  scanActionsAvailable: ScanActionType[];
  updateActionButton: UpdatePropertyCallback;
  updateActions: UpdatePropertyCallback;
}

const getItemToMutateField = (
  actionType: any,
  dataType: any,
  project: any,
  elementPath: any,
  action: any,
  index: any,
  updateActions: any,
  additionalScopeItems: any,
) => {
  const fieldValue = get(action, ['fields', 'id']);

  return (
    <div className="my-3">
      <label className="mb-1 block">
        {getText(
          { item: dataType.display },
          'actions.types',
          actionType,
          'item',
        )}
      </label>
      <DataTypeInput
        additionalScopeItems={additionalScopeItems}
        dataType={dataType.name}
        contained={true}
        elementPath={elementPath}
        includeSelf={true}
        project={project}
        onChange={(option: any) =>
          updateActions([index, 'fields', 'id'], option)
        }
        value={fieldValue}
      />
    </div>
  );
};

const BuildModeActionItemEditor = ({
  action,
  actionIndex,
  actionTypeOptions,
  elementPath,
  execution,
  initialEventState,
  onRemoveAction,
  parentDataType,
  previousActions,
  project,
  scanActionsAvailable = [
    ScanActionType.CREATE,
    ScanActionType.UPDATE,
    ScanActionType.NAVIGATE,
  ],
  updateActionButton,
  updateActions,
}: BuildModeActionItemEditorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    barcodeField,
    field,
    multiScan = false,
    redirect,
    scanActionType,
    type,
  } = action;

  const options = useMemo(
    () => [
      ...(type !== CREATE
        ? [
            {
              label: getText('actions.options.record'),
              value: null,
            },
          ]
        : []),
      ...parentDataType.fields
        .filter(
          (field) =>
            (field.relationship || field.relatedField) &&
            (type !== CREATE || !field.readOnly) &&
            !field.hidden &&
            (!isMultiField(field) ||
              type === CREATE ||
              (type === SCAN_BARCODE &&
                scanActionType === ScanActionType.CREATE)),
        )
        .map((field) => ({
          label: (
            <div className="flex flex-wrap items-center gap-1">
              <span>{getText('actions.options.record')}</span>
              <IconArrowNarrowRight
                className="flex-shrink-0 opacity-75"
                size={14}
              />
              <span>{field.display}</span>
            </div>
          ),
          value: field.name,
        })),
    ],
    [parentDataType.fields, type, scanActionType],
  );

  const scanActionTypeOptions = useMemo(
    () =>
      scanActionsAvailable.map((type) => ({
        label: getText('actions.types', SCAN_BARCODE, 'scanActionType', type),
        value: type,
        ...(type === ScanActionType.UPDATE ? { disabled: multiScan } : {}),
      })),
    [scanActionsAvailable, multiScan],
  );

  const selectedField = useMemo(
    () => parentDataType.fields.getByName(field ? field : 'id'),
    [field, parentDataType.fields],
  );

  const selectedDataType = useMemo(() => {
    if (!selectedField || selectedField.name === 'id') {
      return parentDataType;
    }

    return project.dataTypes.getByName(selectedField.type);
  }, [selectedField, project.dataTypes, parentDataType]);

  const handleScannedValueFieldChange = useCallback(
    (value: any) => {
      if (value && selectedDataType) {
        const field = selectedDataType?.fields.getByName(value);

        if (field) {
          return updateActions([actionIndex, 'barcodeField'], {
            id: RECORD_SCOPE,
            path: field.name,
          });
        }
      }

      if (barcodeField) {
        updateActions([actionIndex, 'barcodeField'], null);
      }
    },
    [updateActions, barcodeField, actionIndex, selectedDataType],
  );

  const handleFieldChange = useCallback(
    (value: any) =>
      updateActions([actionIndex], {
        ...action,
        field: value,
        barcodeField: null,
      }),
    [updateActions, actionIndex, action],
  );

  const handleScanActionTypeChange = useCallback(
    (value: OptionValue) =>
      updateActions([actionIndex], {
        ...action,
        scanActionType: value,
        field: null,
        barcodeField: null,
      }),
    [updateActions, actionIndex, action],
  );

  const handleMultiScanChange = useCallback(
    (value: boolean) => updateActions([actionIndex, 'multiScan'], value),
    [actionIndex, updateActions],
  );

  const scannedValueFieldOptions = useMemo(
    () =>
      selectedDataType?.fields
        .filter(
          (field) =>
            !['id', 'uuid'].includes(field.name) &&
            [DECIMAL, INTEGER, TEXT].includes(field.type),
        )
        .map(({ display: label, name: value }) => ({
          label,
          value,
        })),
    [selectedDataType],
  );

  const fieldFilter = useCallback(
    (field: DataField) =>
      action.type !== SCAN_BARCODE || field.name !== barcodeField?.path,
    [action.type, barcodeField?.path],
  );

  const onDemandWorkflowOptions = useMemo(() => {
    if (type !== ON_DEMAND || !selectedField || !selectedDataType) {
      return [];
    }

    return selectedDataType.workflows
      .filter((workflow: any) => workflow.trigger === ON_DEMAND)
      .map((workflow: any) => ({
        label: workflow.name,
        value: workflow.referenceId,
      }));
  }, [selectedDataType, selectedField, type]);

  const additionalScope = buildActionScope(
    previousActions,
    project.dataTypes,
    actionsConfig,
  );

  const additionalScopeItems = [];

  if (initialEventState.length > 0) {
    additionalScopeItems.push({
      label: getText('actions.event'),
      options: initialEventState,
    });
  }

  if (additionalScope.length > 0) {
    additionalScopeItems.push({
      label: getText('actions.previousActions'),
      heading: true,
      options: additionalScope,
    });
  }

  const onClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setIsOpen(false);
    },
    [setIsOpen],
  );

  const handleRemove = useCallback(
    () => onRemoveAction(action),
    [onRemoveAction, action],
  );

  const onOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );

  const getDisabledFieldText = useCallback(
    (field: DataField) => {
      if (
        field &&
        selectedField &&
        (type === CREATE || type === UPDATE) &&
        field.id === selectedField.id
      ) {
        return getText('actions.disableReverseField');
      }

      return undefined;
    },
    [selectedField, type],
  );

  const newFieldConfig = useMemo(() => {
    if (execution === ONE_CLICK || type === SCAN_BARCODE) {
      return { hidden: true };
    }
  }, [execution, type]);

  const isScanNavigation = useMemo(
    () => type === SCAN_BARCODE && scanActionType === ScanActionType.NAVIGATE,
    [type, scanActionType],
  );

  return (
    <div
      key={action.id}
      className="relative flex flex-col rounded-lg bg-gray-900"
      onClick={onOpen}
    >
      <div className="flex flex-col space-y-4 p-2">
        <div className="space-y-2">
          <BuildModeLabel className="uppercase">
            {getText('actions.type.label')}
          </BuildModeLabel>
          <SelectInput
            className="w-full text-black"
            contained={true}
            onChange={(option: any) => {
              if (option === SCAN_BARCODE && execution !== MODAL) {
                updateActionButton(['execution'], MODAL);
              }

              updateActions([actionIndex, 'type'], option);
            }}
            options={actionTypeOptions}
            placeholder={getText('actions.type.placeholder')}
            value={type}
          />
        </div>
        {type === SCAN_BARCODE && (
          <>
            {!multiScan && (
              <BuildModeInput
                inline={false}
                label={
                  <span className="uppercase">
                    {getText(
                      'actions.types',
                      SCAN_BARCODE,
                      'scanActionType.title',
                    )}
                  </span>
                }
              >
                <SwitchButton
                  className="h-8 w-full rounded-lg"
                  inverseColors={true}
                  onChange={handleScanActionTypeChange}
                  options={scanActionTypeOptions}
                  value={scanActionType}
                />
              </BuildModeInput>
            )}
            {scanActionType === CREATE && (
              <BuildModeSwitchSection
                guide={getText(
                  'actions.types',
                  SCAN_BARCODE,
                  'multiScan.helpText',
                )}
                label={getText(
                  'actions.types',
                  SCAN_BARCODE,
                  'multiScan.title',
                )}
                onChange={handleMultiScanChange}
                value={multiScan}
              />
            )}
          </>
        )}
        {([CREATE, UPDATE, DELETE, ON_DEMAND, VIEW, ADD_COMMENT].includes(
          type,
        ) ||
          (type === SCAN_BARCODE && scanActionType !== NAVIGATE)) && (
          <div className="space-y-2">
            <BuildModeLabel className="uppercase">
              {type === SCAN_BARCODE && scanActionType
                ? getText(
                    'actions.types',
                    type,
                    'field',
                    type === SCAN_BARCODE && scanActionType
                      ? scanActionType
                      : '',
                  )
                : getText('actions.types', type, 'field')}
            </BuildModeLabel>
            <SelectInput
              className="w-full text-black"
              contained={true}
              onChange={handleFieldChange}
              options={options}
              placeholder={getText('actions.options.record')}
              value={field ? field : null}
            />
          </div>
        )}
        {type === CREATE && (
          <BuildModeSwitchSection
            label={getText('actions.types', type, 'redirect.title')}
            onChange={(value: Boolean) =>
              updateActions([actionIndex, 'redirect'], value)
            }
            value={redirect !== false}
          />
        )}
        {type === ADD_COMMENT && (
          <AddCommentActionButtonEditor
            elementPath={elementPath}
            execution={execution}
            config={action.addComment}
            onChange={(path: ElementPath, value: any) =>
              updateActions([actionIndex, 'addComment', ...path], value)
            }
            project={project}
          />
        )}
        {type === SCAN_BARCODE && scanActionType && (
          <div className="space-y-2">
            <BuildModeLabel
              className="uppercase"
              helpText={getText(
                'actions.types',
                SCAN_BARCODE,
                'addValue.helpText',
              )}
            >
              {getText('actions.types', SCAN_BARCODE, 'addValue.title')}
            </BuildModeLabel>
            <SelectInput
              className="w-full text-black"
              contained={true}
              onChange={handleScannedValueFieldChange}
              options={scannedValueFieldOptions}
              searchable={true}
              shiftRight={true}
              placeholder={getText(
                'actions.types',
                SCAN_BARCODE,
                'addValue.placeholder',
              )}
              value={barcodeField?.path}
            />
          </div>
        )}
      </div>
      {isOpen && (
        <div className="flex flex-col">
          {type === ON_DEMAND && (
            <div className="space-y-2 p-2">
              <BuildModeLabel className="uppercase">
                {getText('actions.types', ON_DEMAND, 'workflow')}
              </BuildModeLabel>
              <SelectInput
                className="w-full text-black"
                contained={true}
                value={action.workflow}
                options={onDemandWorkflowOptions}
                placeholder={getText('actions.types', ON_DEMAND, 'placeholder')}
                onChange={(option: any) =>
                  updateActions([actionIndex, 'workflow'], option)
                }
              />
            </div>
          )}
          {type === IFRAME && (
            <IframeSectionEditor
              dataType={parentDataType}
              sectionPropPath={elementPath}
              section={
                {
                  ...action,
                  props: action.iframe as Record<string, any>,
                } as Element
              }
              project={project}
              updateProperty={(path: any, value: any) =>
                updateActions([actionIndex, 'iframe', ...path], value)
              }
              debouncedUpdateProperty={(path: any, value: any) =>
                updateActions([actionIndex, 'iframe', ...path], value)
              }
            >
              <div className="mt-4 flex flex-col">
                <BuildModeInput
                  label={getText('elements', IFRAME, 'size.width')}
                >
                  <SwitchButton
                    className="h-8 w-full rounded-lg"
                    inverseColors={true}
                    onChange={(value) =>
                      updateActions([actionIndex, 'iframe', 'width'], value)
                    }
                    options={tShirtSizes.map((sizeOption) => ({
                      label: getText(
                        'elements.SELECT_INPUT.smallSizes',
                        sizeOption,
                      ),
                      value: sizeOption,
                    }))}
                    value={action.iframe?.width || MD}
                  />
                </BuildModeInput>
              </div>
            </IframeSectionEditor>
          )}
          {type === COPY && (
            <CopyEditor
              elementProps={action.copy?.value}
              debouncedUpdateProperty={(propPath: any, newValue: any) =>
                updateActions([actionIndex, 'copy', ...propPath], newValue)
              }
              elementPath={elementPath}
              project={project}
              additionalScopeItems={additionalScopeItems}
            />
          )}
          <div className="flex flex-col">
            {type &&
              selectedDataType &&
              (type === UPDATE || type === DELETE) &&
              false &&
              getItemToMutateField(
                type,
                selectedDataType,
                project,
                elementPath,
                action,
                actionIndex,
                updateActions,
                additionalScopeItems,
              )}
            {selectedDataType && type === VIEW && (
              <ViewRecordActionEditor
                action={action}
                dataType={selectedDataType}
                elementPath={elementPath}
                isRecordView={false}
                fields={action.detailsFields || []}
                project={project}
                sectionPropPath={elementPath}
                fieldsPath={[]}
                updateProperty={(path: any, value: any) => {
                  updateActions([actionIndex, ...path], value);
                }}
              />
            )}
            {selectedDataType &&
              ([UPDATE, CREATE].includes(type) ||
                (type === SCAN_BARCODE &&
                  ScanActionType &&
                  scanActionType !== ScanActionType.NAVIGATE)) && (
                <div className="max-h-screen-50 flex flex-col overflow-y-auto">
                  <FormFieldsEditor
                    dataType={selectedDataType}
                    elementPath={elementPath}
                    fieldFilter={fieldFilter}
                    formFields={get(action, 'formFields', [])}
                    getDisabledText={getDisabledFieldText}
                    newFieldConfig={newFieldConfig}
                    onFieldsChange={(path: ElementPath, value: any) =>
                      updateActions([actionIndex, 'formFields', ...path], value)
                    }
                    permissionType={type === UPDATE ? 'update' : 'create'}
                    project={project}
                    showOnlyHiddenValueOption={type === SCAN_BARCODE}
                  />
                </div>
              )}
            {(type === NAVIGATE || isScanNavigation) && (
              <div className="flex flex-col bg-gray-900 px-2 pb-2">
                <BuildModeLinkEditor
                  updateProperty={(propPath: any, newValue: any) =>
                    updateActions(
                      [actionIndex, 'navigate', ...propPath],
                      newValue,
                    )
                  }
                  elementProps={action.navigate || {}}
                  debouncedUpdateProperty={(propPath: any, newValue: any) =>
                    updateActions(
                      [actionIndex, 'navigate', ...propPath],
                      newValue,
                    )
                  }
                  elementPath={elementPath}
                  project={project}
                  additionalScopeItems={additionalScopeItems}
                  propDefinition={
                    isScanNavigation ? { includeSelf: true } : undefined
                  }
                  options={isScanNavigation ? [PAGE] : undefined}
                />
              </div>
            )}
          </div>
          <div className="flex items-center justify-between rounded-b-lg border-t border-gray-700 bg-gray-900 p-2">
            <Button onClick={onClose}>{getText('actions.done')}</Button>
            <Button onClick={handleRemove} type={LINK} variant={DANGER}>
              <IconTrash size={16} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuildModeActionItemEditor;
