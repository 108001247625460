import React, { forwardRef, useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { SelectInput, Switch, TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  DANGER,
  INFO,
  PRIMARY,
  Variant,
  WARNING,
} from '@noloco/components/src/constants/variants';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import VisibilityRulesEditor from '@noloco/ui/src/components/editor/VisibilityRulesEditor';
import ActionButtonAppearanceEditor from '@noloco/ui/src/components/editor/customerEditors/ActionButtonAppearanceEditor';
import ActionButtonExecutionEditor, {
  EXECUTION_OPTIONS,
} from '@noloco/ui/src/components/editor/customerEditors/ActionButtonExecutionEditor';
import DraggableListItem from '@noloco/ui/src/components/editor/customerEditors/DraggableListItem';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  ActionButtonExecutionType,
  MODAL,
} from '../../../constants/actionButtons';
import { CONFETTI } from '../../../constants/actionNotification';
import {
  ACTION_OPTIONS,
  ActionType,
  IFRAME,
} from '../../../constants/actionTypes';
import {
  OPTIONS,
  RECORD_ACTION_BUTTON_NOTIFICATIONS,
  RECORD_ACTION_BUTTON_SETTINGS,
  RECORD_ACTION_BUTTON_TEXT,
  VISIBILITY,
} from '../../../constants/buildMode';
import { ACTION_BUTTON } from '../../../constants/draggableItemTypes';
import { ACTION_BUTTONS } from '../../../constants/elements';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  ElementPath,
  ID,
  ScanActionType,
  StringPropSegment,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import useEditorTabs from '../../../utils/hooks/useEditorTabs';
import { getText } from '../../../utils/lang';
import IconEditor from '../../editor/IconEditor';
import { WithDraggable } from '../../withDnD';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import BuildModeInput from '../BuildModeInput';
import BuildModeSection from '../BuildModeSection';
import BuildModeSwitchSection from '../BuildModeSwitchSection';
import BuildModeActionsEditor from './BuildModeActionsEditor';

interface BuildModeActionButtonEditorProps {
  actionButton: ActionButton;
  actionOptions: ActionType[];
  activeListItem: number | null;
  availableExecutions: ActionButtonExecutionType[];
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  defaultExecution: ActionButtonExecutionType;
  display?: 'collection' | 'record';
  draggable: boolean;
  elementPath: ElementPath;
  index: number;
  isOver: boolean;
  listOptions: {
    icon?: JSX.Element;
    label?: string;
    value: string | number;
  }[];
  onClone: (id: ID, newItemId: ID, tab?: any) => void;
  onDebounceUpdateActionButton?: UpdatePropertyCallback;
  onRemove: (id: ID) => void;
  onUpdateActionButton?: UpdatePropertyCallback;
  popoutOpen: boolean;
  project: Project;
  sectionPropPath: ElementPath;
  setActiveListItem: (activeListItem: number | null) => void;
  setPopoutOpen: (popoutOpen: boolean) => void;
  updateProperty: UpdatePropertyCallback;
  scanActionsAvailable: ScanActionType[];
}

const DISPLAY_OPTIONS_DEFAULT_ENABLED = ['record'];
const LANG_KEY = 'elements.VIEW.actionButtons';

const BuildModeActionButtonEditor = forwardRef(
  (
    {
      actionButton,
      actionOptions = ACTION_OPTIONS,
      activeListItem,
      availableExecutions = EXECUTION_OPTIONS,
      dataType,
      debouncedUpdateProperty,
      defaultExecution = MODAL,
      display,
      draggable,
      elementPath,
      index,
      isOver,
      listOptions,
      onClone,
      onDebounceUpdateActionButton,
      onRemove,
      onUpdateActionButton,
      popoutOpen,
      project,
      sectionPropPath,
      setActiveListItem,
      setPopoutOpen,
      updateProperty,
      scanActionsAvailable,
    }: BuildModeActionButtonEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [editorTab, setEditorTab] = useEditorTabs(
      'record.actionButtons',
      OPTIONS,
    );
    const { buttonText } = actionButton;
    const label =
      buttonText ||
      getText({ index: index + 1 }, LANG_KEY, 'defaultButtonText');

    const execution = useMemo(
      () => actionButton.execution || defaultExecution,
      [actionButton.execution, defaultExecution],
    );

    const allowedActionOptions = useMemo(
      () =>
        actionOptions.filter(
          (actionOption: any) => execution === MODAL || actionOption !== IFRAME,
        ),
      [actionOptions, execution],
    );

    const updateActionButtons = useMemo(
      () =>
        onUpdateActionButton
          ? onUpdateActionButton
          : (path: any, value: any) =>
              updateProperty(['actionButtons', ...path], value),
      [onUpdateActionButton, updateProperty],
    );

    const debounceUpdateActionButtons = useMemo(
      () =>
        onDebounceUpdateActionButton
          ? onDebounceUpdateActionButton
          : (path: any, value: any) =>
              debouncedUpdateProperty(['actionButtons', ...path], value),
      [debouncedUpdateProperty, onDebounceUpdateActionButton],
    );

    const isDisplayed = useCallback(
      (actionButton: ActionButton, display: string) => {
        const displayValue = get(actionButton, ['display', display]);

        return (
          displayValue ||
          (displayValue !== false &&
            DISPLAY_OPTIONS_DEFAULT_ENABLED.includes(display))
        );
      },
      [],
    );

    const onChangeActionButtonDisplay = useCallback(
      (display: string, index: number) => (value: any) =>
        updateActionButtons([index, 'display', display], value),
      [updateActionButtons],
    );

    const notificationTypeOptions = useMemo(
      () =>
        [PRIMARY, INFO, WARNING, DANGER, CONFETTI].map((notificationType) => ({
          label: getText(
            LANG_KEY,
            'notifications.notificationType',
            notificationType,
          ),
          value: notificationType,
        })),
      [],
    );

    const visibilityRulesPath = useMemo(
      () => (sectionPropPath ? [...sectionPropPath, 'props'] : elementPath),
      [sectionPropPath, elementPath],
    );

    return (
      <DraggableListItem
        activeListItem={activeListItem}
        canDelete={true}
        draggable={draggable}
        extraListItemChildren={
          display && (
            <Switch
              className="ml-2"
              size="sm"
              value={isDisplayed(actionButton, display)}
              onChange={onChangeActionButtonDisplay(display, index)}
            />
          )
        }
        icon={actionButton.icon}
        id={actionButton.id}
        index={index}
        isOver={isOver}
        key={actionButton.id}
        listOptions={listOptions}
        onClone={onClone}
        onRemove={onRemove}
        popoutOpen={popoutOpen}
        ref={ref}
        setActiveListItem={setActiveListItem}
        setPopoutOpen={setPopoutOpen}
        title={label}
      >
        <>
          <div className="sticky top-0 z-10 w-full border-b border-slate-700 bg-slate-800">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={ACTION_BUTTONS}
              isNavEnabled={false}
              setEditorTab={setEditorTab}
            />
          </div>
          {editorTab === OPTIONS && (
            <div className="flex flex-col">
              <BuildModeSection
                id={RECORD_ACTION_BUTTON_TEXT}
                className="p-2"
                title={getText(LANG_KEY, 'buttonText')}
              >
                <div className="mb-2 px-2">
                  <BuildModeInput label={getText(LANG_KEY, 'buttonText')}>
                    <TextInput
                      className="w-full"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        debounceUpdateActionButtons(
                          [index, 'buttonText'],
                          event.target.value,
                        )
                      }
                      placeholder=""
                      value={actionButton.buttonText}
                    />
                  </BuildModeInput>
                </div>
              </BuildModeSection>
              <BuildModeSection
                id={RECORD_ACTION_BUTTON_SETTINGS}
                className="border-t p-2"
                title={getText(LANG_KEY, 'config')}
              >
                <div className="mb-2 space-y-2 p-2">
                  <BuildModeInput
                    inline={true}
                    label={getText(LANG_KEY, 'appearance')}
                  >
                    <ActionButtonAppearanceEditor
                      appearance={actionButton.appearance}
                      onChange={(value: any) =>
                        debounceUpdateActionButtons(
                          [index, 'appearance'],
                          value,
                        )
                      }
                    />
                  </BuildModeInput>
                  <BuildModeInput
                    inline={true}
                    label={getText(LANG_KEY, 'icon')}
                  >
                    <IconEditor
                      clearable={true}
                      elementProps={actionButton.icon as any}
                      inline={false}
                      placement="right"
                      surface={DARK}
                      updateProperty={(
                        _: ElementPath,
                        iconName: string | null,
                      ) =>
                        updateActionButtons([index, 'icon', 'name'], iconName)
                      }
                    />
                  </BuildModeInput>
                  {availableExecutions.length > 1 && (
                    <ActionButtonExecutionEditor
                      actions={actionButton.actions}
                      availableExecutions={availableExecutions}
                      execution={execution}
                      onChange={(value: ActionButtonExecutionType) =>
                        debounceUpdateActionButtons([index, 'execution'], value)
                      }
                    />
                  )}
                  {execution === MODAL && (
                    <>
                      <BuildModeInput label={getText(LANG_KEY, 'title')}>
                        <StringPropEditor
                          // @ts-expect-error TS(2322): Type '{ className: string; project: any; elementPa... Remove this comment to see the full error message
                          className="w-full"
                          project={project}
                          elementPath={elementPath}
                          value={actionButton.title}
                          onChange={(value: any) =>
                            debounceUpdateActionButtons([index, 'title'], value)
                          }
                          placeholder=""
                        />
                      </BuildModeInput>
                      <BuildModeInput
                        label={getText(LANG_KEY, 'description')}
                        markdown={true}
                      >
                        <StringPropEditor
                          // @ts-expect-error TS(2322): Type '{ className: string; project: any; elementPa... Remove this comment to see the full error message
                          className="w-full"
                          elementPath={elementPath}
                          multiLine={true}
                          onChange={(value: any) =>
                            debounceUpdateActionButtons(
                              [index, 'description'],
                              value,
                            )
                          }
                          placeholder=""
                          project={project}
                          value={actionButton.description}
                        />
                      </BuildModeInput>
                    </>
                  )}
                  <BuildModeInput
                    label={getText(LANG_KEY, 'buttonTooltip')}
                    markdown={true}
                  >
                    <StringPropEditor
                      // @ts-expect-error TS(2322): Type '{ className: string; project: any; elementPa... Remove this comment to see the full error message
                      className="w-full"
                      elementPath={elementPath}
                      multiLine={true}
                      onChange={(value: any) =>
                        debounceUpdateActionButtons([index, 'tooltip'], value)
                      }
                      placeholder=""
                      project={project}
                      value={actionButton.tooltip}
                    />
                  </BuildModeInput>
                </div>
              </BuildModeSection>
              <BuildModeSection
                id={RECORD_ACTION_BUTTON_NOTIFICATIONS}
                className="border-t p-2"
                title={getText(LANG_KEY, 'notifications.label')}
              >
                <div className="mb-2 flex flex-col space-y-2 p-2">
                  <BuildModeSwitchSection
                    label={getText(LANG_KEY, 'notifications.description')}
                    onChange={(nextNotify: boolean) =>
                      updateActionButtons(
                        [index, 'notification', 'enabled'],
                        nextNotify,
                      )
                    }
                    value={get(actionButton, 'notification.enabled', false)}
                  />
                  {get(actionButton, 'notification.enabled', false) && (
                    <div className="flex flex-col space-y-2">
                      <BuildModeInput
                        label={getText(LANG_KEY, 'notifications.type')}
                      >
                        <SelectInput
                          className="mt-1"
                          value={get(
                            actionButton,
                            ['notification', 'type'],
                            PRIMARY,
                          )}
                          options={notificationTypeOptions}
                          onChange={(type: Variant | 'confetti') =>
                            updateActionButtons(
                              [index, 'notification', 'type'],
                              type,
                            )
                          }
                        />
                      </BuildModeInput>
                      {get(actionButton, ['notification', 'type']) !==
                        CONFETTI && (
                        <BuildModeInput
                          label={getText(LANG_KEY, 'notifications.text')}
                        >
                          <StringPropEditor
                            // @ts-expect-error TS(2322): Type '{ className: string; project: any; elementPa... Remove this comment to see the full error message
                            className="mt-1 w-full"
                            project={project}
                            elementPath={elementPath}
                            value={get(actionButton, 'notification.text')}
                            onChange={(text: StringPropSegment[]) =>
                              debounceUpdateActionButtons(
                                [index, 'notification', 'text'],
                                text,
                              )
                            }
                            placeholder=""
                          />
                        </BuildModeInput>
                      )}
                    </div>
                  )}
                </div>
              </BuildModeSection>
              <BuildModeActionsEditor
                actionOptions={allowedActionOptions}
                actions={actionButton.actions}
                dataType={dataType}
                elementPath={elementPath}
                eventType="CLICK"
                execution={execution}
                onChange={(path: ElementPath, value: any) =>
                  updateActionButtons([index, 'actions', ...path], value)
                }
                project={project}
                scanActionsAvailable={scanActionsAvailable}
                updateActionButton={(path: ElementPath, value: any) =>
                  updateActionButtons([index, ...path], value)
                }
              />
            </div>
          )}
          {editorTab === VISIBILITY && (
            <VisibilityRulesEditor
              dataType={dataType}
              elementPath={[...visibilityRulesPath, 'actionButtons', index]}
              onChange={(path: any, value: any) =>
                updateActionButtons([index, 'visibilityRules', ...path], value)
              }
              project={project}
              visibilityRules={actionButton.visibilityRules}
            />
          )}
        </>
      </DraggableListItem>
    );
  },
);

BuildModeActionButtonEditor.displayName = 'BuildModeActionButtonEditor';

export default WithDraggable(BuildModeActionButtonEditor, ACTION_BUTTON);
