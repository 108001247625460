import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import {
  CALENDAR,
  GANTT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { ACTION_BUTTONS } from '../../../constants/elements';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useIsTable from '../../../utils/hooks/useIsTable';
import useRemoveRecordFromCollection from '../../../utils/hooks/useRemoveRecordFromCollection';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import { RECORD_SCOPE } from '../../../utils/scope';
import Checkbox from '../../Checkbox';
import {
  COLLECTION_ACTION_BUTTON_CELL_STYLES,
  COLLECTION_ACTION_BUTTON_STYLES,
  COLLECTION_ACTION_BUTTON_WRAPPER_STYLES,
} from '../Collection';
import ActionButton from './ActionButton';
import MobileCollectionActionButtonsWrapper from './MobileCollectionActionButtonsWrapper';
import RemoveRelatedRecordButton from './RemoveRelatedRecordButton';

const preventRecordClick = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
};

const CollectionRecordActionButtons = ({
  actionButtons,
  collectionId,
  dataList,
  dataType,
  editRelatedRecordButton,
  editorMode,
  elementPath,
  formatRecordScope,
  index,
  record,
  refetch,
  rootDataType,
  layout,
  parentScope,
  project,
  bulkActionsEnabled = false,
  isRowChecked = false,
  elementId,
  handleCheckboxChange,
}: any) => {
  const { sm: isSmScreen } = useBreakpoints();

  const removeRecordFromCollection =
    useRemoveRecordFromCollection(collectionId);
  const actionButtonClassNames = COLLECTION_ACTION_BUTTON_STYLES[layout];
  const actionButtonContainerClassNames =
    COLLECTION_ACTION_BUTTON_CELL_STYLES[layout];
  const actionButtonWrapperClassNames =
    COLLECTION_ACTION_BUTTON_WRAPPER_STYLES[layout];

  const recordScope = useMemo(
    () => ({
      ...parentScope,
      ...formatRecordScope(record),
    }),
    [formatRecordScope, record, parentScope],
  );

  const isTable = useIsTable(layout);

  const { actionButtons: parsedActionButtons } = useSectionScopeVariables(
    ACTION_BUTTONS,
    { actionButtons },
    project,
    elementPath,
    recordScope,
    skipPropResolvingByValueIds([RECORD_SCOPE]),
  );

  const handleDeleteRecord = useCallback(
    (record: any, onNext: any) => {
      removeRecordFromCollection(record.id);
      onNext();
    },
    [removeRecordFromCollection],
  );

  const Component = isTable ? 'td' : 'div';

  const renderedActionButtons = useMemo(
    () => (
      <>
        {parsedActionButtons.map((actionButton: any, index: any) => (
          <ActionButton
            key={actionButton.id}
            actionButton={actionButton}
            buttonType={isSmScreen ? 'text' : undefined}
            dataType={dataType}
            editorMode={editorMode}
            index={index}
            isCollection={true}
            project={project}
            onDeleteRecord={handleDeleteRecord}
            record={record}
            recordScope={recordScope}
            size={SM}
            actionButtonClassName={actionButtonClassNames}
          />
        ))}
        {editRelatedRecordButton.show &&
          !editRelatedRecordButton.hideUnlinkButton && (
            <div className={actionButtonClassNames} key="remove-related-record">
              <RemoveRelatedRecordButton
                buttonText={get(editRelatedRecordButton, 'unlinkButtonText')}
                buttonType={isSmScreen ? 'text' : undefined}
                dataList={dataList}
                dataType={dataType}
                project={project}
                record={record}
                refetch={refetch}
                rootDataType={rootDataType}
                scope={recordScope}
                variant="secondary"
              />
            </div>
          )}
      </>
    ),
    [
      actionButtonClassNames,
      dataList,
      dataType,
      editorMode,
      editRelatedRecordButton,
      handleDeleteRecord,
      isSmScreen,
      parsedActionButtons,
      project,
      record,
      recordScope,
      refetch,
      rootDataType,
    ],
  );

  return (
    <Component
      className={classNames(
        actionButtonContainerClassNames,
        !isSmScreen && index === 0 && isTable ? 'z-30' : 'z-20',
      )}
      onClick={preventRecordClick}
    >
      <div
        className={classNames(
          actionButtonWrapperClassNames,
          'hidden group-hover:flex sm:flex',
        )}
      >
        {isSmScreen &&
          (parsedActionButtons.length > 0 || editRelatedRecordButton.show) && (
            <MobileCollectionActionButtonsWrapper layout={layout}>
              {renderedActionButtons}
            </MobileCollectionActionButtonsWrapper>
          )}
        {bulkActionsEnabled &&
          ![TABLE, TABLE_FULL, CALENDAR, TIMELINE, GANTT].includes(layout) && (
            <div
              className={classNames(
                'flex w-8 items-center justify-center rounded-lg bg-white text-center shadow-lg sm:h-8',
                actionButtonClassNames,
              )}
            >
              <Checkbox
                className="m-2 flex h-3 w-3"
                size="sm"
                checked={isRowChecked}
                value={isRowChecked}
                elementId={elementId}
                onChange={handleCheckboxChange}
              />
            </div>
          )}
        {!isSmScreen && renderedActionButtons}
      </div>
    </Component>
  );
};

export default CollectionRecordActionButtons;
