import React, {
  forwardRef,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import { getTailwindClassNames } from '@darraghmckay/tailwind-react-ui';
import { Portal } from '@headlessui/react';
import classNames from 'classnames';
import first from 'lodash/first';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
// eslint-disable-next-line no-restricted-imports
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';

interface Props {
  bg?: string;
  content: React.ReactNode;
  className?: string;
  closeOnClick?: boolean;
  closeOnOutsideClick?: boolean;
  isOpen?: boolean;
  onOpenChange?: (...args: any[]) => any;
  offset?: number[];
  placement?: string;
  rootEl?: React.ReactNode;
  rootSelector?: string;
  trigger?: 'click' | 'hover' | 'none';
  showArrow?: boolean;
  shadow?: string | boolean;
  usePortal?: boolean;
  delayHide?: number;
  delayShow?: number;
  rounded?: boolean;
  style?: any;
  children: React.ReactNode;
}

const BasePopover = forwardRef<any, Props>(
  (
    {
      bg,
      children,
      className,
      closeOnClick,
      closeOnOutsideClick,
      delayHide,
      delayShow,
      onOpenChange,
      isOpen,
      placement,
      offset,
      // @ts-expect-error TS(2339): Property 'show' does not exist on type 'Props'.
      show,
      content,
      rounded,
      rootEl,
      rootSelector,
      showArrow,
      trigger,
      style,
      usePortal,
    },
    ref,
  ) => {
    const [localShow, setLocalShow] = useState(isOpen);
    const [isDarkModeEnabled] = useDarkMode();

    const handleOpenChange = useCallback(
      (nextOpen: any) => {
        if (trigger !== 'hover' && nextOpen && closeOnOutsideClick) {
          setLocalShow(nextOpen);
        }

        if (onOpenChange) {
          onOpenChange(nextOpen);
        }
      },
      [closeOnOutsideClick, onOpenChange, trigger],
    );

    const {
      state,
      getArrowProps,
      getTooltipProps,
      setTooltipRef,
      setTriggerRef,
      tooltipRef,
      visible,
    } = usePopperTooltip({
      closeOnOutsideClick: false,
      closeOnTriggerHidden: false,
      // @ts-expect-error TS(2322): Type '"click" | "hover" | "none" | null | undefine... Remove this comment to see the full error message
      trigger: closeOnClick ? null : trigger,
      delayShow,
      delayHide,
      // @ts-expect-error TS(2322): Type 'number[] | undefined' is not assignable to t... Remove this comment to see the full error message
      offset,
      onVisibleChange: handleOpenChange,
      interactive: trigger === 'hover',
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      placement,
      visible: isOpen || show || localShow,
    });

    const handleCloseOnClick = useCallback(() => {
      if (localShow) {
        setLocalShow(undefined);
      }
    }, [localShow]);

    useLayoutEffect(() => {
      if (trigger !== 'hover') {
        const handleOnClick = (event: any) => {
          const poppers = [
            ...document.querySelectorAll(
              '.noloco-popover:not(.noloco-popover-hover).popover-close-on-outside-click',
            ),
          ];
          const target =
            (event.composedPath && first(event.composedPath())) || event.target;

          if (target instanceof Node && poppers.length > 0) {
            // @ts-expect-error TS(2345): Argument of type 'HTMLElement | null' is not assig... Remove this comment to see the full error message
            const popperIndex = poppers.indexOf(tooltipRef);
            const clickPopperIndex = poppers.findIndex((popper) =>
              popper.contains(target),
            );

            // Close this popover IFF the click was outside ALL open poppers
            // OR it was on a popper higher up the dom tree (i.e. a parent popper)
            if (
              (clickPopperIndex < 0 || clickPopperIndex < popperIndex) &&
              popperIndex >= 0
            ) {
              setLocalShow(false);

              if (onOpenChange) {
                onOpenChange(false);
              }
            }

            if (popperIndex < 0) {
              document.body.removeEventListener('mousedown', handleOnClick);
            }
          }
        };

        if (!!tooltipRef && closeOnOutsideClick) {
          document.addEventListener('mousedown', handleOnClick);
        } else {
          document.body.removeEventListener('mousedown', handleOnClick);
        }

        return () =>
          document.body.removeEventListener('mousedown', handleOnClick);
      }
    }, [
      localShow,
      closeOnClick,
      closeOnOutsideClick,
      tooltipRef,
      onOpenChange,
      isOpen,
      trigger,
    ]);

    const statePlacement = state?.placement ?? placement;

    const twArrowClassNames = getTailwindClassNames({
      bg,
      ...(statePlacement === 'bottom' ? { roundedTl: rounded } : {}),
      ...(statePlacement === 'top' ? { roundedBr: rounded } : {}),
      ...(statePlacement === 'left' ? { roundedTr: rounded } : {}),
      ...(statePlacement === 'right' ? { roundedBl: rounded } : {}),
    });

    const triggerChild = React.cloneElement(children as any, {
      ref: setTriggerRef,
      ...(closeOnClick
        ? {
            onClick: (e) => {
              e.stopPropagation();
              setLocalShow(!localShow);
            },
          }
        : {}),
    });

    const tooltip = () => {
      const arrowProps = getArrowProps({
        'data-placement': statePlacement,
        dark: isDarkModeEnabled,
        className: classNames(
          'tooltip-arrow absolute w-4 h-4',
          {
            '-top-1.5': statePlacement && statePlacement.startsWith('bottom'),
            '-bottom-1.5': statePlacement && statePlacement.startsWith('top'),
            '-right-1.5': statePlacement && statePlacement.startsWith('left'),
            '-left-1.5': statePlacement && statePlacement.startsWith('right'),
          },
          twArrowClassNames,
        ),
      });

      return (
        <div
          data-testid="popover-tooltip"
          ref={setTooltipRef || ref}
          className={classNames(
            className,
            'noloco-popover',
            {
              'open-popover': localShow,
              'popover-close-on-outside-click': closeOnOutsideClick,
            },
            `noloco-popover-${trigger}`,
            isDarkModeEnabled ? 'dark-mode-scroll-bar' : '',
          )}
          {...getTooltipProps({
            style: {
              zIndex: 50,
              ...(style || {}),
            },
            onClick: () => {
              if (closeOnClick) {
                handleCloseOnClick();
              }
            },
          })}
        >
          {showArrow && (
            <div
              {...{
                ...arrowProps,
                style: {
                  ...arrowProps.style,
                  transform: `${arrowProps.style.transform} rotate(45deg)`,
                },
              }}
            />
          )}
          {content}
        </div>
      );
    };

    const root =
      typeof document !== 'undefined' &&
      // @ts-expect-error TS(2769): No overload matches this call.
      (rootEl || document.querySelector(rootSelector));

    return (
      <>
        {triggerChild}
        {visible &&
          (usePortal && root ? (
            // @ts-expect-error TS(2345): Argument of type 'string | number | true | ReactEl... Remove this comment to see the full error message
            <Portal>{ReactDOM.createPortal(tooltip(), root)}</Portal>
          ) : (
            tooltip()
          ))}
      </>
    );
  },
);

BasePopover.defaultProps = {
  bg: 'white',
  className: '',
  closeOnClick: false,
  closeOnOutsideClick: false,
  delayShow: 0,
  delayHide: 0,
  placement: 'top',
  offset: [-10, 8],
  trigger: 'click',
  rootEl: null,
  rounded: true,
  rootSelector: '#root',
  showArrow: false,
  shadow: true,
  usePortal: true,
};

export default BasePopover;
