import React, { memo } from 'react';
import classNames from 'classnames';
import { getText } from '../../utils/lang';

const MarkdownHelp = memo(({ className }: { className?: string }) => (
  <div className={classNames(className, 'text-sm text-gray-200')}>
    <span>{getText('hints.markdown.help')}</span>
    <a
      className="ml-1 text-cyan-500 hover:text-cyan-400"
      href="https://www.markdownguide.org/cheat-sheet/"
      target="_blank"
      rel="noreferrer noopener"
    >
      {getText('hints.markdown.name')}
    </a>
  </div>
));

export default MarkdownHelp;
