import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import first from 'lodash/first';
import { ProjectSpace } from '../../models/Project';
import { CURRENT_SPACE } from '../spaces';
import useLocalStorageState from './useLocalStorageState';
import useRouter from './useRouter';
import useSpaces from './useSpaces';

export interface CurrentSpaceContextValue {
  id: string | null;
  space: ProjectSpace | null;
}

const CurrentSpaceContext = createContext<
  [CurrentSpaceContextValue | null, (_value: string | null) => void]
>([{ id: null, space: null }, () => undefined]);

const useCurrentSpaceContext = (): [
  CurrentSpaceContextValue | null,
  (value: string | null) => void,
] => {
  const {
    query: { __space: spaceIdParam = null },
  } = useRouter();
  const spaces = useSpaces();
  const [currentSpaceId, setCurrentSpaceId] = useLocalStorageState<
    string | null
  >(CURRENT_SPACE, null);

  useEffect(() => {
    if (spaceIdParam && spaceIdParam !== currentSpaceId) {
      setCurrentSpaceId(spaceIdParam);
    }
  }, [spaceIdParam, setCurrentSpaceId, currentSpaceId]);

  const currentSpaceIdValue = useMemo(() => {
    if (spaceIdParam && spaces.find(({ id }) => id === spaceIdParam)) {
      return spaceIdParam;
    }

    return currentSpaceId ?? first(spaces)?.id ?? null;
  }, [spaces, spaceIdParam, currentSpaceId]);

  const currentSpace = useMemo(
    () => spaces.find(({ id }) => id === currentSpaceIdValue) ?? null,
    [spaces, currentSpaceIdValue],
  );

  return [currentSpace, setCurrentSpaceId];
};

interface CurrentSpaceProviderProps {
  children: ReactNode;
}

export const CurrentSpaceProvider = ({
  children,
}: CurrentSpaceProviderProps) => {
  const value = useCurrentSpaceContext();

  return (
    <CurrentSpaceContext.Provider value={value}>
      {children}
    </CurrentSpaceContext.Provider>
  );
};

const useCurrentSpace = () => useContext(CurrentSpaceContext);

export default useCurrentSpace;
