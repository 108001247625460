import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { IconArrowNarrowRight, IconCheck } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Button, FormField, Label, Notice } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { GOOGLE_SHEETS } from '@noloco/core/src/constants/dataSources';
import HelpText from '@noloco/core/src/elements/sections/HelpText';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { useRefetchIntegrations } from '@noloco/core/src/utils/hooks/useRefetchIntegrations';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import useSetDocumentTitle from '@noloco/core/src/utils/hooks/useSetDocumentTitle';
import { getText } from '@noloco/core/src/utils/lang';
import { buildGoogleDriveSearchParams } from '@noloco/core/src/utils/oauth';
import { APP_DASHBOARD_URL } from '../../constants/env';
import googleLogo from '../../img/google-logo.png';
import googleSheetsLogo from '../../img/google-sheets-logo.png';
import { VERIFY_GOOGLE_DRIVE_CONNECTION } from '../../queries/project';
import { useAddDataSource } from '../../utils/hooks/useAddDataSource';
import { openAuthorizationWindow } from '../../utils/oauth';
import Guide from '../Guide';
import DataSourceImportStatus from './DataSourceImportStatus';

const LANG_KEY = 'data.googleSheets';

const FORM_STEPS = 1;

const AddGoogleSheet = ({
  inOnboarding = false,
  project,
  onConnect,
  surface = DARK,
}: any) => {
  const {
    query: { sourceName: querySourceName },
  }: any = useRouter();
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const { user } = useAuth();

  useSetDocumentTitle(`${getText(LANG_KEY, 'title')} Google Sheets`);

  const reFetchIntegrations = useRefetchIntegrations(project.name);

  const { data: googleTokenVerify, loading: loadingTokenVerification } =
    useQuery(VERIFY_GOOGLE_DRIVE_CONNECTION, {
      variables: { projectName: project.name },
      fetchPolicy: 'no-cache',
    });

  const projectHasGoogleDriveIntegration = useMemo(
    () => !!get(project, 'integrations.google.drive', false),
    [project],
  );

  useEffect(() => {
    if (
      googleTokenVerify !== undefined &&
      googleTokenVerify.verifyGoogleDriveConnection !==
        projectHasGoogleDriveIntegration
    ) {
      // We need to refetch integrations to update the project state, since we don't know the real integration state
      void reFetchIntegrations();
    }
  }, [
    projectHasGoogleDriveIntegration,
    googleTokenVerify,
    reFetchIntegrations,
  ]);

  const [authenticationFailed, setAuthenticationFailed] = useState(false);
  const hasGoogleDriveAccount = useMemo(() => {
    if (loadingTokenVerification) {
      return false;
    }

    return (
      (googleTokenVerify?.verifyGoogleDriveConnection ||
        projectHasGoogleDriveIntegration) &&
      !authenticationFailed
    );
  }, [
    authenticationFailed,
    googleTokenVerify?.verifyGoogleDriveConnection,
    loadingTokenVerification,
    projectHasGoogleDriveIntegration,
  ]);

  const [sourceName, setSourceName] = useState(
    querySourceName ?? 'Google Sheet',
  );
  const [externalId, setExternalId] = useState<string | null>(null);

  const {
    builtPages,
    createdDataSource,
    error,
    existingDataSource,
    hasSyncedData,
    inProgressPages,
    isConnecting,
    isUpdate,
    isNameValid,
    onClickNext,
    onDataTypesSelected,
    onFinish,
    skippedPages,
    step,
  } = useAddDataSource({
    project,
    connection: { externalId },
    display: sourceName,
    type: GOOGLE_SHEETS,
    formSteps: FORM_STEPS,
    onAuthenticationFail: () => setAuthenticationFailed(true),
    inOnboarding,
    canShowTableChooser: true,
  });

  useLayoutEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTo({
        top: bottomRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [step, bottomRef]);

  useEffect(() => {
    if (existingDataSource && Object.keys(existingDataSource).length > 0) {
      setExternalId(existingDataSource.externalId);
      setSourceName(existingDataSource.display);
    }

    // We'll only listen for the message if the data source doesn't exist
    if (!existingDataSource) {
      const listener = (event: MessageEvent) => {
        if (
          event.origin === APP_DASHBOARD_URL &&
          typeof event.data === 'object' &&
          event.data.type === 'googleSheetPicked'
        ) {
          setExternalId(event.data.sheetId);
          setSourceName(event.data.sheetName);
        }
      };
      window.addEventListener('message', listener);

      return () => {
        window.removeEventListener('message', listener);
      };
    }
  }, [existingDataSource]);

  const onConnectGoogleAccount = useCallback(() => {
    openAuthorizationWindow(
      'https://accounts.google.com/o/oauth2/v2/auth',
      buildGoogleDriveSearchParams(project, user!, inOnboarding, sourceName),
      { onClose: reFetchIntegrations },
    );
  }, [project, user, inOnboarding, sourceName, reFetchIntegrations]);

  const isStepValid = useMemo(
    () => get([isNameValid && externalId], step, true),
    [isNameValid, externalId, step],
  );

  return (
    <div className="flex w-full">
      <div
        className={classNames('w-full max-w-xl p-8 text-white', {
          'bg-slate-700': !inOnboarding,
        })}
      >
        {!inOnboarding && (
          <>
            <h1 className="flex items-center text-xl">
              <span>{getText(LANG_KEY, 'title')}</span>
              <img
                src={googleSheetsLogo}
                alt="Google Sheets logo"
                className="ml-2 mr-2 h-8"
              />
              <span>Google Sheets</span>
            </h1>
            <Guide
              className="mb-8 mt-4"
              href="https://guides.noloco.io/data/google-sheets"
            >
              {getText(LANG_KEY, 'help')}
            </Guide>
          </>
        )}
        <Label
          className={classNames('mb-3 mt-6', {
            'text-gray-700': inOnboarding,
            'text-white': !inOnboarding,
          })}
        >
          {getText(LANG_KEY, 'connectAccount.label')}
        </Label>
        <Button
          variant="secondary"
          onClick={onConnectGoogleAccount}
          className="flex items-center"
          opacity={100}
        >
          <img
            src={googleLogo}
            className="mr-3 h-6 w-auto"
            alt="Sign in With Google Logo"
          />
          {getText(
            LANG_KEY,
            'connectAccount.button',
            hasGoogleDriveAccount ? 'connected' : 'connect',
          )}
          {hasGoogleDriveAccount && (
            <IconCheck className="ml-4 text-cyan-400" size={16} />
          )}
        </Button>
        {hasGoogleDriveAccount && (
          <>
            <Label
              className={classNames('mb-3 mt-6', {
                'text-gray-700': inOnboarding,
                'text-white': !inOnboarding,
              })}
            >
              {getText(LANG_KEY, 'pickSheet.label')}
              {isUpdate && (
                <HelpText>{getText(LANG_KEY, 'pickSheet.help')}</HelpText>
              )}
            </Label>
            <Button
              variant="secondary"
              onClick={() =>
                window.open(
                  `${APP_DASHBOARD_URL}/_pick-gsheet`,
                  '_blank',
                  'location=yes,height=800,width=1200,scrollbars=no,status=yes',
                )
              }
              className="flex items-center"
              opacity={100}
              disabled={step > 0}
            >
              <img
                src={googleSheetsLogo}
                className="mr-3 h-6 w-auto"
                alt="Google Sheets Logo"
              />
              {getText(
                LANG_KEY,
                'pickSheet',
                'button',
                externalId ? 'picked' : 'pick',
              )}
              {externalId && (
                <IconCheck className="ml-4 text-cyan-400" size={16} />
              )}
            </Button>
          </>
        )}
        {externalId && (
          <FormField
            className="mt-6"
            name="name"
            type="text"
            readOnly={step > 0 || isUpdate}
            disabled={step > 0 || isUpdate}
            onChange={({ target: { value } }: any) => setSourceName(value)}
            required
            errorMessage={
              !sourceName || isNameValid
                ? null
                : getText(LANG_KEY, 'name.invalid')
            }
            errorType="below-solid"
            label={getText(LANG_KEY, 'name.label')}
            help={getText(LANG_KEY, 'name.help')}
            placeholder={getText(LANG_KEY, 'name.placeholder')}
            value={sourceName}
            surface={surface}
          />
        )}
        {!isUpdate && (
          <Notice
            className="mt-6"
            type="info"
            title={getText(LANG_KEY, 'tabsWarnings')}
          />
        )}
        {!isUpdate && step < 1 && (
          <Button
            className="mt-6 flex items-center disabled:opacity-75"
            disabled={!isStepValid}
            onClick={onClickNext}
          >
            <span>{getText(LANG_KEY, 'next')}</span>
            <IconArrowNarrowRight size={16} className="ml-2 opacity-75" />
          </Button>
        )}
      </div>
      <div
        className={classNames('max-h-screen w-full overflow-y-auto', {
          'bg-slate-800': !inOnboarding,
        })}
        ref={inOnboarding ? null : bottomRef}
      >
        <div
          className={classNames('flex w-full items-center justify-center', {
            'max-h-screen-75 overflow-y-auto': inOnboarding,
          })}
          ref={inOnboarding ? bottomRef : null}
        >
          {(step >= 1 || error) && (
            <DataSourceImportStatus
              builtPages={builtPages}
              createdDataSource={createdDataSource}
              error={error}
              formSteps={FORM_STEPS}
              hasSyncedData={hasSyncedData}
              inProgressPages={inProgressPages}
              isConnecting={isConnecting}
              onDataTypesSelected={onDataTypesSelected}
              onFinish={inOnboarding ? onConnect : onFinish}
              project={project}
              skippedPages={skippedPages}
              sourceType={GOOGLE_SHEETS}
              step={step}
              inOnboarding={inOnboarding}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddGoogleSheet;
