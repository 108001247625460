import React, { forwardRef, useContext } from 'react';
import classNames from 'classnames';
import {
  OTPInput as BaseOTPInput,
  OTPInputContext as BaseOTPInputContext,
} from 'input-otp';
import { Loader } from '@noloco/components/src';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import { MD } from '@noloco/components/src/constants/tShirtSizes';
import { getText } from '../../../utils/lang';

const InputOTP = forwardRef<
  React.ElementRef<typeof BaseOTPInput>,
  React.ComponentPropsWithoutRef<typeof BaseOTPInput>
>((props, ref) => (
  <BaseOTPInput
    ref={ref}
    containerClassName="flex items-center gap-2 opacity-50 disabled:cursor-not-allowed"
    {...props}
  />
));

const InputOTPGroup = forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>((props, ref) => <div ref={ref} className="flex items-center" {...props} />);

const InputOTPSlot = forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'> & { index: number }
>(({ index, ...props }, ref) => {
  const inputOTPContext = useContext(BaseOTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={classNames(
        'relative flex h-10 w-10 items-center justify-center border-y border-r text-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md dark:border-white',
        {
          'ring-ring ring-offset-background z-10 ring-2': isActive,
        },
      )}
      {...props}
    >
      {char}
      {hasFakeCaret && (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="animate-caret-blink bg-foreground h-4 w-px duration-1000" />
        </div>
      )}
    </div>
  );
});

export const OTPInput = ({
  handleVerify,
  loading,
  otpValue,
  setOtpValue,
  includeButtons = true,
}: {
  handleVerify: (code: string) => void;
  loading: boolean;
  otpValue: string;
  setOtpValue: (value: string) => void;
  includeButtons?: boolean;
}) => {
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (otpValue.length === 6) {
      handleVerify(otpValue);
    }
  };

  return (
    <div className="space-y-6">
      {loading ? (
        <Loader size={MD} />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="flex w-full flex-col items-center"
        >
          <div className="space-y-3">
            <div className="flex justify-center">
              <InputOTP
                maxLength={6}
                value={otpValue}
                onChange={(value) => setOtpValue(value)}
              >
                <InputOTPGroup>
                  {[0, 1, 2, 3, 4, 5].map((index) => (
                    <InputOTPSlot key={index} index={index} />
                  ))}
                </InputOTPGroup>
              </InputOTP>
            </div>
            <div className="text-center">
              {getText('auth.twoFactorAuth.enterOTP')}
            </div>
          </div>
          {includeButtons && (
            <div className="w-full">
              <SubmitButton type="submit" disabed={otpValue.length !== 6}>
                {getText('auth.twoFactorAuth.submit')}
              </SubmitButton>
            </div>
          )}
        </form>
      )}
    </div>
  );
};
