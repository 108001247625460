import React, {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import gql from 'graphql-tag';
import { EnumType } from 'json-to-graphql-query';
import first from 'lodash/first';
import reverse from 'lodash/fp/reverse';
import get from 'lodash/get';
import { DateTime } from 'luxon';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Loader, TextInput, getColorShade } from '@noloco/components';
import Avatar from '../../components/Avatar';
import InternalLayoutWrapper from '../../components/InternalLayoutWrapper';
import MessageInput from '../../components/MessageInput';
import { COMPANY } from '../../constants/builtInDataTypes';
import { DATE_SHORT } from '../../constants/dateFormatOptions';
import { TIME_24_SIMPLE } from '../../constants/dateFormatOptions';
import { MANY_TO_ONE } from '../../constants/relationships';
import { ENTER } from '../../constants/shortcuts';
import MessagesIcon from '../../img/MessagesIcon';
import imagePlaceholder from '../../img/image-placeholder.png';
import { getPreviewableFieldsQueryObject } from '../../queries/data';
import {
  CREATE_MESSAGE_MUTATION,
  MESSAGE_ADDED_SUBSCRIPTION,
  MESSAGE_COLLECTION_QUERY,
  getCollectionDataQueryString,
} from '../../queries/project';
import { addDataItemToCollectionCache } from '../../utils/apolloCache';
import { useGraphQlErrorAlert } from '../../utils/hooks/useAlerts';
import useCacheQuery from '../../utils/hooks/useCacheQuery';
import useRouter from '../../utils/hooks/useRouter';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { getText } from '../../utils/lang';
import { sendNotification } from '../../utils/notifications';
import { isInternal } from '../../utils/user';
import RelatedCellItem from './collections/RelatedCellItem';
import AttachmentPreview from './messaging/AttachmentPreview';

export const isHighlighting = () =>
  // detects mouse is highlighting a text
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  window.getSelection && window.getSelection().type === 'Range';

const MESSAGE_CREATED_AT_PATH = 'messagesCollection.edges.0.node.createdAt';
const sortConversations = (conversationA: any, conversationB: any) => {
  const messageDateA = get(conversationA, MESSAGE_CREATED_AT_PATH);
  const messageDateB = get(conversationB, MESSAGE_CREATED_AT_PATH);

  if (!messageDateB && !messageDateA) {
    return conversationA.name < conversationB.name ? -1 : 1;
  }

  if (!messageDateB) {
    return -1;
  }

  if (!messageDateA) {
    return 1;
  }

  return DateTime.fromISO(messageDateA) > DateTime.fromISO(messageDateB)
    ? -1
    : 1;
};

const filterConversations = (searchText: any) => (company: any) =>
  !searchText || !searchText.trim()
    ? true
    : company.name &&
      company.name.toLowerCase().includes(searchText.toLowerCase().trim());

const URL_REGEX =
  /((http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:;%_+.~#?&//=]*))/g;
const replaceLinks = (messageText: any) =>
  messageText.replace(
    URL_REGEX,
    (__: any, firstGroup: any, protocol: any) =>
      `<a href="${
        protocol ? '' : 'http://'
      }${firstGroup}" target="_blank" rel="noreferrer noopener" class="text-blue-500 hover:underline max-w-full overflow-hidden break-all">${firstGroup}</a>`,
  );

interface MessagingProps {}

const Messaging = forwardRef<any, MessagingProps>(
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Messa... Remove this comment to see the full error message
  ({ className, clientTopMessage, project, theme }, ref) => {
    const {
      query: { conversation: queryConversationId },
      replaceQueryParams,
    } = useRouter();
    const errorAlert = useGraphQlErrorAlert();
    const secondaryColor = theme.brandColorGroups.secondary;
    const user = useScopeUser();
    const searchInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState();
    const [filesToSend, setFilesToSend] = useState([]);
    const [messageText, setMessageText] = useState('');
    const scrollRef = React.useRef<null | HTMLDivElement>(null);

    const context = {
      projectQuery: true,
      projectName: project.name,
    };

    const companyWithRelations = project.dataTypes.getByName(COMPANY);
    const userConversationId = isInternal(user)
      ? queryConversationId
      : get(user, ['company', 'id']);
    const [conversationId, setConversationId] = useState(userConversationId);

    const onClickConversation = useCallback(
      (newConversationId: any) => {
        setConversationId(newConversationId);
        replaceQueryParams({ conversation: newConversationId });
      },
      [replaceQueryParams],
    );

    const chatEnabled = !!userConversationId || isInternal(user);

    const companyFields = useMemo(() => {
      const baseFields = getPreviewableFieldsQueryObject(
        companyWithRelations.fields,
      );
      const userFields = {
        id: true,
        uuid: true,
        firstName: true,
        lastName: true,
        isInternal: true,
        profilePicture: {
          id: true,
          url: true,
        },
      };
      baseFields.messagesCollection = {
        __args: {
          first: 1,
          orderBy: { field: 'createdAt', direction: new EnumType('DESC') },
        },
        edges: {
          node: {
            id: true,
            uuid: true,
            text: true,
            createdAt: true,
            sender: userFields,
          },
        },
      };
      baseFields.usersCollection = {
        edges: {
          node: userFields,
        },
      };

      return baseFields;
    }, [companyWithRelations]);

    const messageQuery = gql`
      ${MESSAGE_COLLECTION_QUERY}
    `;
    const [createMessage, { client: apolloClient }] = useMutation(
      gql`
      ${CREATE_MESSAGE_MUTATION},
    `,
      {
        context,
      },
    );

    const {
      data: messageData,
      loading,
      fetchMore,
    } = useCacheQuery(messageQuery, {
      variables: {
        conversationId,
        after: null,
      },
      skip: !conversationId,
      context,
    });
    const conversationQuery = useMemo(
      () =>
        getCollectionDataQueryString(companyWithRelations.name, {
          edges: {
            node: companyFields,
          },
        }),
      [companyFields, companyWithRelations],
    );
    const { data: conversationsData } = useCacheQuery(
      gql`
        ${conversationQuery}
      `,
      { context, skip: !isInternal(user) },
    );

    const originalConversations = get(
      conversationsData,
      [`${companyWithRelations.name}Collection`, 'edges'],
      [],
    ).map((edge: any) => edge.node);
    const conversations = [...originalConversations].sort(sortConversations);

    const filteredConversations = conversations.filter(
      filterConversations(searchText),
    );

    const addNewMessageToCaches = useCallback(
      (newMessageData: any) => {
        const messageConversationId = String(
          newMessageData.createMessage.conversationId || conversationId,
        );

        try {
          addDataItemToCollectionCache(
            newMessageData,
            apolloClient,
            messageQuery,
            'Message',
            {
              conversationId: messageConversationId,
              after: null,
            },
            { collectionPathInput: 'messageCollection' },
          );

          if (isInternal(user)) {
            const conversationIndex = originalConversations.findIndex(
              (c: any) => String(c.id) === messageConversationId,
            );

            if (conversationIndex >= 0) {
              addDataItemToCollectionCache(
                newMessageData,
                apolloClient,
                gql`
                  ${conversationQuery}
                `,
                'Message',
                {
                  conversationId: messageConversationId,
                  after: null,
                },
                {
                  collectionPathInput: `companyCollection.edges.${conversationIndex}.node.messagesCollection`,
                },
              );
            }
          }
        } catch (e) {
          console.log(e);
        }
      },
      [
        apolloClient,
        conversationId,
        conversationQuery,
        messageQuery,
        originalConversations,
        user,
      ],
    );

    const onNewMessageSubscription = useCallback(
      ({ subscriptionData }: any) => {
        if (!subscriptionData.data) {
          return;
        }
        const newMessage = subscriptionData.data.messageAdded;

        if (!newMessage.attachment) {
          sendNotification(`New message from ${newMessage.sender.firstName}`, {
            image: get(newMessage, 'sender.profilePicture.url'),
            icon: get(newMessage, 'sender.profilePicture.url'),
            badge: get(newMessage, 'sender.profilePicture.url'),
            body: newMessage.text,
          });
        } else {
          sendNotification(`New message from ${newMessage.sender.firstName}`, {
            image: get(newMessage, 'sender.attachment.url'),
            icon: get(newMessage, 'sender.attachment.url'),
            badge: get(newMessage, 'sender.attachment.url'),
            body: '🖼',
          });
        }

        addNewMessageToCaches({ createMessage: newMessage });
      },
      [addNewMessageToCaches],
    );

    useSubscription(
      gql`
        ${MESSAGE_ADDED_SUBSCRIPTION}
      `,
      {
        context: {
          projectQuery: true,
          projectName: project.name,
        },
        variables: { projectName: project.name, vars: { conversationId } },
        onSubscriptionData: onNewMessageSubscription,
      },
    );

    useEffect(() => {
      if (!conversationId && conversations.length > 0) {
        setConversationId(conversations[0].id);
      }
    }, [conversationId, conversations]);
    const selectedConversation = conversations.find(
      (c) => c.id === conversationId,
    );
    const selectedConversationUsers =
      selectedConversation &&
      selectedConversation.usersCollection.edges.map((edge: any) => edge.node);

    const sendMessage = useCallback(
      (text: any, attachment: any) =>
        createMessage({
          variables: {
            text: text ? text.trim() : null,
            attachment,
            conversationId,
          },
        })
          .then(({ data: newMessageData }) => {
            if (newMessageData.createMessage) {
              addNewMessageToCaches(newMessageData);
            }
          })
          .catch((error) => {
            errorAlert(getText('core.MESSAGING.errors.send'), error);
          }),
      [addNewMessageToCaches, conversationId, createMessage, errorAlert],
    );

    const onSend = useCallback(
      async (event: any) => {
        event.preventDefault();

        if (!isLoading) {
          setIsLoading(true);

          const currentValue = messageText
            // @ts-expect-error TS(2550): Property 'replaceAll' does not exist on type 'stri... Remove this comment to see the full error message
            .replaceAll('<br>', '\n')
            .replaceAll('&nbsp;', ' ')
            .trim();

          if (currentValue && currentValue.trim()) {
            // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
            await sendMessage(currentValue);
          }
          setMessageText('');

          await Promise.all(
            filesToSend.map((file) => sendMessage(null, file[0])),
          );
          setFilesToSend([]);
          setIsLoading(false);
        }
      },
      [filesToSend, isLoading, messageText, sendMessage],
    );

    const messageCollection = get(messageData, 'messageCollection', {});
    const { edges: messageEdges = [], pageInfo = {} } = messageCollection;
    const messages = messageEdges.map((m: any) => m.node);

    const [loaderRef] = useInfiniteScroll({
      loading: loading,
      hasNextPage: pageInfo.hasNextPage,
      onLoadMore: () => {
        fetchMore({
          variables: { conversationId, after: pageInfo.endCursor },
          // @ts-expect-error TS(2345): Argument of type '{ variables: { conversationId: a... Remove this comment to see the full error message
          disabled: !pageInfo,
          updateQuery: (previousResults, { fetchMoreResult }) => {
            if (!fetchMoreResult) {
              return previousResults;
            }

            return {
              messageCollection: {
                ...fetchMoreResult.messageCollection,
                edges: [
                  ...previousResults.messageCollection.edges,
                  ...fetchMoreResult.messageCollection.edges,
                ],
              },
              __typename: 'MessageConnection',
            };
          },
        });
      },
      disabled: false,
      rootMargin: '100px 0px 0px 0px',
    });

    const onChange = (nextValue: any) => {
      setMessageText(nextValue);
    };

    const handleSearchKeyDown = useCallback(
      (event: any) => {
        if (event.key === ENTER) {
          const firstConversation = first(filteredConversations);

          if (firstConversation) {
            onClickConversation(firstConversation.id);
          }
        }
      },
      [filteredConversations, onClickConversation],
    );

    useLayoutEffect(
      () =>
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        }),
      [scrollRef, messages.length],
    );

    if (!chatEnabled) {
      return (
        <div className="flex h-full w-full flex-col items-center justify-center">
          <MessagesIcon className="w-58 mb-8 text-gray-300" />
          <h2 className="text-base font-medium text-gray-800">
            {getText('core.MESSAGING.disabled.title')}
          </h2>
          <p className="text-tsm mt-2 text-gray-600">
            {getText('core.MESSAGING.disabled.subtitle')}
          </p>
        </div>
      );
    }

    return (
      <InternalLayoutWrapper
        className={className}
        // @ts-expect-error ts(2339)
        sidebarContent={
          <>
            <div className="sticky top-0 border-b bg-white p-3">
              <TextInput
                border={[true, 'gray-200']}
                ref={searchInputRef}
                placeholder={getText('core.MESSAGING.search.placeholder')}
                onChange={({ target: { value } }: any) => setSearchText(value)}
                onKeyDown={handleSearchKeyDown}
                value={searchText}
              />
            </div>
            {filteredConversations.map((conversation) => (
              <div
                className={classNames(
                  'flex cursor-pointer items-center border-b border-gray-100 px-4 py-4',
                  conversation.id === conversationId
                    ? 'bg-blue-100 bg-opacity-75'
                    : 'hover:bg-gray-100',
                )}
                key={conversation.id}
                onClick={() => onClickConversation(conversation.id)}
              >
                <RelatedCellItem
                  className="max-w-full overflow-hidden text-base"
                  innerClassName="ml-4 max-w-full overflow-hidden"
                  dataTypes={project.dataTypes}
                  field={{
                    type: companyWithRelations.name,
                    relationship: MANY_TO_ONE,
                  }}
                  imagePlaceholder={imagePlaceholder}
                  size={14}
                  single={true}
                  value={conversation}
                >
                  {get(conversation, 'messagesCollection.edges.0.node.id') && (
                    <span className="truncate whitespace-nowrap text-sm text-gray-400">
                      {get(
                        conversation,
                        'messagesCollection.edges.0.node.text',
                      ) || getText('core.MESSAGING.attachment')}
                    </span>
                  )}
                </RelatedCellItem>
              </div>
            ))}
          </>
        }
        headerContent={
          selectedConversation && (
            <RelatedCellItem
              className="text-lg font-medium"
              innerClassName="ml-3"
              dataTypes={project.dataTypes}
              field={{
                type: companyWithRelations.name,
                relationship: MANY_TO_ONE,
              }}
              imagePlaceholder={imagePlaceholder}
              size={14}
              single={true}
              value={selectedConversation}
            >
              <div className="flex flex-wrap text-sm text-gray-800">
                {selectedConversationUsers.map(
                  (conversationUser: any, index: any) => (
                    <div
                      className="my-1 mr-2 flex items-center"
                      key={conversationUser.id}
                    >
                      <Avatar
                        className="mr-2 text-gray-100"
                        initialsSize="xs"
                        size={6}
                        user={conversationUser}
                      />
                      <span className="whitespace-nowrap">
                        {conversationUser.firstName} {conversationUser.lastName}
                      </span>
                      {index !== selectedConversationUsers.length - 1 && (
                        <span>,</span>
                      )}
                    </div>
                  ),
                )}
              </div>
            </RelatedCellItem>
          )
        }
        ref={ref}
      >
        {!isInternal(user) && (
          <div className="flex w-full items-center border-b bg-white px-8 py-4 shadow sm:px-4">
            <div className="mx-auto flex w-full max-w-5xl flex-wrap px-8 text-sm text-gray-800 sm:p-0">
              <span>
                {clientTopMessage || getText('core.MESSAGING.clientTopMessage')}
              </span>
            </div>
          </div>
        )}
        <div className="flex w-full flex-grow overflow-y-auto overflow-x-hidden">
          <div className="relative w-full overflow-x-hidden px-9 pt-4 text-sm sm:px-3">
            {pageInfo && pageInfo.hasNextPage && (
              <div className="flex w-full justify-center py-4" ref={loaderRef}>
                <Loader size="sm" />
              </div>
            )}
            {reverse(messages).map((message: any, index: any) => {
              if (!message.sender) {
                return null;
              }

              const showOnRight =
                isInternal(message.sender) === isInternal(user);
              const previousMessage =
                index > 0 && messages[messages.length - index];
              const diffDays = previousMessage
                ? DateTime.fromISO(message.createdAt).diff(
                    DateTime.fromISO(previousMessage.createdAt),
                    'days',
                  ).days
                : 0;
              const showDate =
                index === 0 || (previousMessage && diffDays >= 1);

              return (
                <React.Fragment key={`${message.id}-${index}`}>
                  {showDate && (
                    <div className="sticky top-0 z-10 mx-auto my-4 w-24 rounded-full bg-gray-600 px-2 py-1 text-center text-xs text-gray-200">
                      {DateTime.fromISO(message.createdAt).toLocaleString(
                        // @ts-expect-error TS(2559): Type '"D"' has no properties in common with type '... Remove this comment to see the full error message
                        DATE_SHORT,
                      )}
                    </div>
                  )}
                  <div
                    className={classNames(
                      'mx-auto mb-4 flex w-full max-w-4xl items-start',
                      {
                        'flex-row-reverse': showOnRight,
                      },
                      showOnRight ? 'pl-24 sm:pl-6' : 'pr-24 sm:pr-6',
                    )}
                    ref={scrollRef}
                  >
                    <Avatar className="mt-1" size={10} user={message.sender} />
                    <div className="mx-4 mb-1 flex flex-col overflow-hidden">
                      <div
                        className={classNames('flex items-end', {
                          'flex-row-reverse': showOnRight,
                        })}
                      >
                        <span className="font-medium">
                          {message.sender.firstName} {message.sender.lastName}
                        </span>
                        <span className="mx-4 mb-px text-xs text-gray-600">
                          {DateTime.fromISO(message.createdAt).toLocaleString(
                            // @ts-expect-error TS(2559): Type '"T"' has no properties in common with type '... Remove this comment to see the full error message
                            TIME_24_SIMPLE,
                          )}
                        </span>
                      </div>
                      {message.text && (
                        <p
                          className={classNames(
                            'max-w-full overflow-hidden whitespace-pre-wrap rounded-lg p-4 text-gray-800',
                            message.sender.id === user.id
                              ? `bg-${getColorShade(secondaryColor, 200)}`
                              : 'bg-gray-200',
                            {
                              'ml-auto': showOnRight,
                              // if it contains only 1-3 (plain) emoji
                              'text-3xl':
                                /^\p{Extended_Pictographic}{1,3}$/u.test(
                                  message.text,
                                ),
                            },
                          )}
                          dangerouslySetInnerHTML={{
                            __html: replaceLinks(message.text),
                          }}
                        />
                      )}
                      {message.attachment && (
                        <AttachmentPreview
                          bg={
                            message.sender.id === user.id
                              ? `bg-${getColorShade(secondaryColor, 200)}`
                              : 'bg-gray-200'
                          }
                          className={classNames(
                            'flex',
                            showOnRight ? 'justify-end' : 'justify-start',
                          )}
                          attachment={message.attachment}
                        />
                      )}
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            {!loading && messages.length === 0 && (
              <div className="flex h-full w-full flex-col items-center justify-center text-center">
                <MessagesIcon
                  className="w-58 mb-8"
                  text={[getColorShade(secondaryColor, '400')]}
                />
                <h2 className="text-base font-medium text-gray-800">
                  {getText('core.MESSAGING.empty.title')}
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  {getText('core.MESSAGING.empty.subtitle')}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="mx-auto w-full max-w-5xl px-8 pb-6 sm:px-3">
          <MessageInput
            className="rounded-lg border bg-white shadow-md"
            files={filesToSend}
            isLoading={isLoading}
            onChange={onChange}
            onChangeFiles={setFilesToSend}
            onSubmit={onSend}
            placeholder={getText('core.MESSAGING.placeholder')}
            richTextControls={false}
            value={messageText}
          />
        </div>
      </InternalLayoutWrapper>
    );
  },
);

const MessagingWithTheme = withTheme(Messaging);

const MessagingWrapper = forwardRef((props, ref) => {
  const user = useScopeUser();

  if (!user || !user.id) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Loader type="Bars" />
      </div>
    );
  }

  return <MessagingWithTheme {...props} ref={ref} />;
});

export default MessagingWrapper;
