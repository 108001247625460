import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import gql from 'graphql-tag';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import { Theme, dtToLocalTZMaintainWallTime } from '@noloco/components';
import { formatLocalDateTimeResult } from '@noloco/components/src/components/input/DatePickerPopover';
import useLocale, {
  useLocales,
} from '@noloco/components/src/utils/hooks/useLocale';
import { Views, dateFnsLocalizer } from '@noloco/react-big-calendar';
import { UPDATE } from '../../../constants/actionTypes';
import { TWO_WEEK } from '../../../constants/collection';
import {
  CollectionLayout,
  GANTT,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { DATE } from '../../../constants/dataTypes';
import { VIEW } from '../../../constants/elements';
import { DATE as DATE_FORMAT } from '../../../constants/fieldFormats';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { DepValue, ID, ViewRecordStyle } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { RecordEdge } from '../../../models/Record';
import { GroupByWithField } from '../../../models/View';
import { QueryObject } from '../../../queries/data';
import { getMutationQueryString } from '../../../queries/project';
import { lookupOfArray } from '../../../utils/arrays';
import { formatDisplayField } from '../../../utils/dataTypes';
import { getPrimaryField } from '../../../utils/fields';
import { getOptimisticResponse } from '../../../utils/hooks/useAutoFormVariables';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDraggableCollectionGroups from '../../../utils/hooks/useDraggableCollectionGroups';
import useRouter from '../../../utils/hooks/useRouter';
import { cleanEdgesNodeFromDepPath } from '../../../utils/queries';
import { Group } from '../Collection';
import CollectionCalendar from './CollectionCalendar';
import CollectionTimeline from './CollectionTimeline';

interface DateValues {
  end?: Date;
  endRawIso?: string | null;
  hasEnd?: boolean;
  start?: Date;
  startRawIso?: string | null;
}

export interface EventUpdate {
  event: Event;
  start: Date;
  end: Date;
  groupBy?: { field: any; color: string | null };
}

export interface Dependent {
  id: ID;
  start: number;
  end: number;
}

export interface Event {
  id: ID;
  start: Date;
  startRawIso: string;
  hasEnd: boolean;
  end: Date;
  endRawIso: string;
  title: string;
  record: any;
  rootPathname: string;
  dateStartField: DataField;
  dateEndField: DataField;
  color: string;
  index: number;
  event?: EventUpdate;
  predecessors?: Dependent[];
  successors?: Dependent[];
  itemProps?: any;
  group: Group | undefined;
}

const defaultViews = [Views.MONTH, Views.WEEK, Views.WORK_WEEK, Views.DAY];
const timelineDefaultViews = [
  Views.MONTH,
  TWO_WEEK,
  Views.WEEK,
  Views.WORK_WEEK,
  Views.DAY,
];

const getDateValue = (
  edge: RecordEdge,
  dateDep: DepValue,
  dateField: DataField | null,
): Date | null => {
  if (!dateField) {
    return null;
  }

  const cleanedDateDepPath = cleanEdgesNodeFromDepPath(dateDep.path);
  const rawDateString = get(edge, `node.${cleanedDateDepPath}`)?.toString();

  if (!rawDateString) {
    return null;
  }

  let dateTime = DateTime.fromISO(rawDateString);

  if (!dateTime.isValid) {
    return null;
  }

  const format = get(dateField, 'typeOptions.format');
  const timeZone = get(dateField, 'typeOptions.timeZone');

  if (format === DATE_FORMAT) {
    dateTime = dateTime.toUTC();
    dateTime = dtToLocalTZMaintainWallTime(dateTime, false);
  } else if (timeZone) {
    dateTime = dateTime.setZone(timeZone);
    dateTime = dtToLocalTZMaintainWallTime(dateTime, true);
  }

  return dateTime.toJSDate();
};

const getEventDateRawIso = (
  edge: RecordEdge,
  dateDep: DepValue,
): string | null => {
  if (!dateDep) {
    return null;
  }

  const cleanedDateDepPath = cleanEdgesNodeFromDepPath(dateDep.path);
  const rawDateString = get(edge, [
    'node',
    ...cleanedDateDepPath.split('.'),
  ]) as string;

  if (!rawDateString) {
    return null;
  }

  return rawDateString;
};

const getDateValues = (
  edge: RecordEdge,
  dateStartDep: DepValue,
  dateStartField: DataField,
  dateEndDep: DepValue,
  dateEndField: DataField,
): DateValues => {
  const dates: DateValues = {};
  const startDate = getDateValue(edge, dateStartDep, dateStartField);

  if (startDate) {
    dates.start = startDate;
    dates.startRawIso = getEventDateRawIso(edge, dateStartDep);

    const endDate = getDateValue(edge, dateEndDep, dateEndField);
    dates.hasEnd = !!endDate;

    if (endDate) {
      dates.end = endDate;
      dates.endRawIso = getEventDateRawIso(edge, dateEndDep);
      const typeOptionsFormat = get(dateEndField, 'typeOptions.format');

      if (typeOptionsFormat === DATE_FORMAT) {
        dates.end = DateTime.fromJSDate(endDate).endOf('day').toJSDate();
      }
    } else {
      dates.end = DateTime.fromJSDate(startDate).plus({ hours: 1 }).toJSDate();
    }
  }

  return dates;
};

type GroupRecordMap = Record<string, Group>;

const getGroupRecordMap = (
  groupByField: GroupByWithField | undefined,
  visibleGroups: Group[],
): GroupRecordMap => {
  if (!groupByField || !groupByField.dataField || visibleGroups.length === 0) {
    return {};
  }

  return visibleGroups.reduce((acc, group) => {
    group.rows?.forEach((row) => {
      acc[row.node.id] = group;
    });

    return acc;
  }, {} as GroupRecordMap);
};

const formatDateValueForUpdate = (date: Date, dateField: DataField) =>
  formatLocalDateTimeResult(
    DateTime.fromJSDate(date),
    dateField?.typeOptions?.format !== DATE_FORMAT,
    dateField?.typeOptions?.timeZone,
  ).toISO();

interface CollectionEventsProps {
  calendarView: string;
  canUpdate: boolean;
  className?: string;
  dataType: DataType;
  date: string;
  dateEnd: DepValue | undefined;
  dateEndField: DataField;
  dateStart: DepValue | undefined;
  dateStartField: DataField;
  edges: RecordEdge[];
  elementId: string;
  elementType: string;
  enableDragAndDropEdit: boolean;
  endTime: number;
  ganttDependency: DepValue;
  groupByFields: GroupByWithField[];
  isRecordView: boolean;
  layout: CollectionLayout;
  loading: boolean;
  newButtonLink: string;
  newButtonVisible: boolean;
  nodeQueryObject: QueryObject;
  project: Project;
  qsSuffix: string;
  recordStyle?: ViewRecordStyle;
  recordTitleField: string;
  rootPathname: string | null;
  Row: any;
  rowLink: string;
  startTime: number;
  theme: Theme;
}

const CollectionEvents = ({
  calendarView,
  canUpdate,
  className,
  dataType,
  date,
  dateEnd,
  dateEndField,
  dateStart,
  dateStartField,
  edges,
  elementId,
  elementType,
  enableDragAndDropEdit,
  endTime,
  ganttDependency,
  groupByFields,
  isRecordView,
  layout,
  loading,
  newButtonLink,
  newButtonVisible,
  nodeQueryObject,
  project,
  qsSuffix,
  recordTitleField,
  rootPathname,
  Row,
  rowLink,
  recordStyle,
  startTime,
  theme,
}: CollectionEventsProps) => {
  const locale = useLocale();
  const locales = useLocales();
  const [isDarkModeEnabled] = useDarkMode();

  const {
    pushQueryParams,
    query: { [`_view${qsSuffix}`]: viewParam },
  } = useRouter();

  const [view, setView] = useState<string>(viewParam);

  // This is required to "stage" changes to the view
  // because sometimes view and date changes get triggered at the same time
  useEffect(() => {
    if (viewParam !== view) {
      pushQueryParams({ [`_view${qsSuffix}`]: view });
    }
  }, [pushQueryParams, qsSuffix, view, viewParam]);

  const { visibleGroups } = useDraggableCollectionGroups({
    customFilters: [],
    dataType,
    edges,
    elementId,
    fields: [],
    groupByFields: groupByFields,
    groupOptions: {},
    hideEmptyGroups: true,
    layout,
    limitPerGroup: undefined,
    nodeQueryObject,
    enableDragAndDropEdit,
  });

  const groupByField: GroupByWithField | undefined = useMemo(
    () => (groupByFields.length > 0 ? groupByFields[0] : undefined),
    [groupByFields],
  );

  const localizer = useMemo(
    () =>
      dateFnsLocalizer({
        format: (date: any, dateFormat: any) =>
          format(date, dateFormat, { locale }),
        parse,
        startOfWeek: () => startOfWeek(new Date(), { locale }),
        getDay,
        locales,
      }),
    [locale, locales],
  );

  const titleField = useMemo(() => {
    if (!dataType) {
      return null;
    }

    if (recordTitleField) {
      const field = dataType.fields.getByName(recordTitleField);

      if (field && !field.relationship) {
        return field;
      }
    }

    return getPrimaryField(dataType);
  }, [dataType, recordTitleField]);

  const canUpdateEventDates = useMemo(
    () =>
      canUpdate &&
      !!dateStartField &&
      dateStart &&
      dateStartField.type === DATE &&
      cleanEdgesNodeFromDepPath(dateStart.path).split('.').length === 1,
    [canUpdate, dateStart, dateStartField],
  );

  const updateQueryString = useMemo(
    () => gql`
      ${getMutationQueryString(
        UPDATE,
        dataType.name,
        dataType.fields,
        nodeQueryObject || {
          id: true,
          uuid: true,
        },
      )}
    `,
    [dataType.name, dataType.fields, nodeQueryObject],
  );

  const queryOptionsObj = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    }),
    [project.name],
  );

  const [updateDataItem] = useMutation(updateQueryString, {
    fetchPolicy: 'network-only',
    ...queryOptionsObj,
  });

  const [edgesState, setEdgesState] = useState<RecordEdge[]>(edges);

  useEffect(
    () =>
      setEdgesState((prevEdgesState) => {
        // Construct a Map from node IDs to edges in prevEdgesState
        const edgeMap = lookupOfArray(prevEdgesState, 'node.id');

        // Replace existing edges with new edges, keeping existing edges if not found in new edges
        return edges.map((edge: RecordEdge) => {
          const existingEdge = edgeMap[edge.node.id];

          if (existingEdge && !isEqual(existingEdge, edge)) {
            return edge;
          }

          return existingEdge ?? edge;
        });
      }),
    [edges],
  );

  const events: Event[] = useMemo(() => {
    if (loading && edgesState.length === 0) {
      return [];
    }

    const groupMap = getGroupRecordMap(groupByField, visibleGroups);

    return edgesState
      .map((edge: RecordEdge, index: number) => ({
        id: get(edge, 'node.id') as string,
        ...getDateValues(
          edge,
          dateStart!,
          dateStartField,
          dateEnd!,
          dateEndField,
        ),
        title:
          (titleField &&
            formatDisplayField(
              titleField,
              get(edge, ['node', titleField.apiName]),
            )) ||
          get(edge, 'node.uuid'),
        record: edge.node,
        rootPathname,
        dateStartField,
        dateEndField,
        color:
          groupByField && groupByField.field && groupMap[edge.node.id]?.color,
        group: groupByField && groupByField.field && groupMap[edge.node.id],
        index,
      }))
      .filter((event) => event.id && event.start) as Event[];
  }, [
    loading,
    edgesState,
    groupByField,
    visibleGroups,
    dateStart,
    dateStartField,
    dateEnd,
    dateEndField,
    titleField,
    rootPathname,
  ]);

  const [draftEvents, setDraftEvents] = useState(events);
  useEffect(() => {
    if (!isEqual(events, draftEvents)) {
      setDraftEvents(events);
    }
  }, [draftEvents, events]);

  const getOriginalEventFromEdge = useCallback(
    ({ start, end, groupBy, event }: any) => {
      const edge = edgesState.find(
        (edge: RecordEdge) => edge.node.id === event.id,
      )?.node;

      return {
        ...edge,
        [dateStartField.apiName]: formatDateValueForUpdate(
          start,
          dateStartField,
        ),
        [dateEndField.apiName]: formatDateValueForUpdate(end, dateEndField),
        ...(groupBy ? { ...groupBy.field } : {}),
      };
    },
    [edgesState, dateStartField, dateEndField],
  );

  const updateEvents = useCallback(
    async (events: EventUpdate[]) => {
      if (!dateStartField) {
        return null;
      }

      Promise.all(
        events.map((eventUpdate) => {
          const optimisticResponse = getOptimisticResponse(
            getOriginalEventFromEdge(eventUpdate),
            {},
            dataType,
          );

          return updateDataItem({
            variables: {
              id: eventUpdate.event.id,
              [dateStartField.apiName]: formatDateValueForUpdate(
                eventUpdate.start,
                dateStartField,
              ),
              ...(dateEndField
                ? {
                    [dateEndField.apiName]: formatDateValueForUpdate(
                      eventUpdate.end,
                      dateEndField,
                    ),
                  }
                : {}),
              ...(eventUpdate.groupBy ? { ...eventUpdate.groupBy.field } : {}),
            },
            optimisticResponse,
          });
        }),
      );
    },
    [
      dataType,
      dateEndField,
      dateStartField,
      getOriginalEventFromEdge,
      updateDataItem,
    ],
  );

  const updateEvent = useCallback(
    (
      event: any,
      start: any,
      end: any,
      _groupBy?: { field: any; color: string },
    ) => updateEvents([{ event, start, end, groupBy: _groupBy }]),
    [updateEvents],
  );

  const currentView = useMemo(
    () => view || calendarView || Views.MONTH,
    [view, calendarView],
  );

  const enableShortcuts = useMemo(
    () => !loading && !isRecordView && elementType === VIEW,
    [loading, isRecordView, elementType],
  );

  if (layout === TIMELINE || layout === GANTT) {
    return (
      <CollectionTimeline
        canUpdateEventDates={canUpdateEventDates}
        currentView={currentView}
        dataType={dataType}
        date={date}
        dateEndField={dateEndField}
        dateStartField={dateStartField}
        defaultViews={timelineDefaultViews}
        draftEvents={draftEvents}
        enableDragAndDropEdit={enableDragAndDropEdit}
        enableShortcuts={enableShortcuts}
        ganttDependency={ganttDependency}
        groupByField={groupByField ? groupByField.dataField : undefined}
        groupByFieldDep={groupByField ? groupByField.field : undefined}
        groups={visibleGroups}
        isDarkModeEnabled={isDarkModeEnabled}
        layout={layout}
        loading={loading}
        localizer={localizer}
        project={project}
        qsSuffix={qsSuffix}
        recordStyle={recordStyle}
        Row={Row}
        rowLink={rowLink}
        setView={setView}
        theme={theme}
        updateEvent={updateEvent}
        updateEvents={updateEvents}
      />
    );
  }

  return (
    <CollectionCalendar
      canUpdateEventDates={canUpdateEventDates}
      className={className}
      currentView={currentView}
      date={date}
      dateEndField={dateEndField}
      dateStartField={dateStartField}
      defaultViews={defaultViews}
      draftEvents={draftEvents}
      enableDragAndDropEdit={enableDragAndDropEdit}
      endTime={endTime}
      enableShortcuts={enableShortcuts}
      loading={loading}
      localizer={localizer}
      newButtonLink={newButtonLink}
      newButtonVisible={newButtonVisible}
      project={project}
      qsSuffix={qsSuffix}
      Row={Row}
      rowLink={rowLink}
      setView={setView}
      startTime={startTime}
      updateEvent={updateEvent}
    />
  );
};

export default withTheme(CollectionEvents);
