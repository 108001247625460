import { forwardRef, useCallback, useMemo } from 'react';
import { SelectInput } from '@noloco/components/src';
import Guide from '@noloco/ui/src/components/Guide';
import ContentDisplayName from '@noloco/ui/src/components/canvas/ContentDisplayName';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { VIEW_FILTER } from '../../../constants/buildMode';
import { INTERNAL } from '../../../constants/dataSources';
import { DATABASE } from '../../../constants/scopeTypes';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import { getDataCollectionOptionsOfType } from '../../../utils/renderedOptions';
import BuildModeInput from '../BuildModeInput';
import BuildModeSection from '../BuildModeSection';
import BuildModeCustomFiltersEditor from '../filters/BuildModeCustomFiltersEditor';

interface BuildModeViewFilterSettingsProps {
  dataType: DataType;
  element: Page | Element;
  elementPath: ElementPath;
  filterOptions?: any;
  project: Project;
  updateProperty: UpdatePropertyCallback;
}

const BuildModeViewFilterSettings = ({
  dataType,
  element,
  elementPath,
  filterOptions,
  project,
  updateProperty,
}: BuildModeViewFilterSettingsProps) => {
  const { dataList = {} } = element.props || {};

  const {
    dataType: dataListDataType,
    customFilters = [],
    dataSource = INTERNAL,
    filter,
  } = useMemo(() => dataList, [dataList]);

  const collectionFilterOptions = useMemo(() => {
    if (filterOptions) {
      return filterOptions;
    }

    const defaultFilterOptions = dataType
      ? [
          {
            value: new StateItem({
              dataType: dataType.name,
              display: '',
              id: '',
              path: '',
              source: DATABASE,
            }),
            label: `${getText('elements.LIST.all')}`,
            buttonLabel: `${getText('elements.LIST.all')}`,
          },
        ]
      : [];

    return getDataCollectionOptionsOfType(
      project,
      elementPath,
      dataType.name,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
      defaultFilterOptions,
    );
  }, [dataType, elementPath, filterOptions, project]);

  // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
  const DefaultFilterItem = forwardRef(({ value }, ref) => (
    <ContentDisplayName
      dataOptions={collectionFilterOptions}
      data={value}
      parentPath={elementPath}
      ref={ref}
    />
  ));

  const selectedDataType = project.dataTypes.getByName(dataListDataType);

  const updateDataListProps: UpdatePropertyCallback = useCallback(
    (path: ElementPath, value: any) =>
      updateProperty(['dataList', ...path], value),
    [updateProperty],
  );

  const onUpdateDefaultFilter = useCallback(
    (option: any) => updateDataListProps(['filter'], option),
    [updateDataListProps],
  );

  const onUpdateCustomFilters = useCallback(
    (newCustomFilters: any) =>
      updateDataListProps(['customFilters'], newCustomFilters),
    [updateDataListProps],
  );

  return (
    <BuildModeSection
      className="border-t"
      id={VIEW_FILTER}
      sticky={true}
      title={getText('rightSidebar.editor.filter.title')}
      guide={
        <Guide
          href="https://guides.noloco.io/collections/filters"
          video="https://www.youtube.com/embed/tmZbXqCb5lU?si=ZQyAvlhFhGr55VTR"
        >
          {getText('rightSidebar.editor.filter.guide')}
        </Guide>
      }
    >
      <div className="mb-2 w-full space-y-4 p-2">
        <BuildModeInput
          inline={true}
          label={getText('rightSidebar.editor.relativeFilter')}
        >
          <SelectInput
            Button={DefaultFilterItem}
            contained={true}
            onChange={onUpdateDefaultFilter}
            options={collectionFilterOptions}
            value={filter}
          />
        </BuildModeInput>
        {dataSource === INTERNAL && selectedDataType && (
          <BuildModeCustomFiltersEditor
            customFilters={customFilters}
            elementPath={elementPath}
            onUpdateCustomFilters={onUpdateCustomFilters}
            project={project}
            selectedDataType={selectedDataType as DataType}
          />
        )}
      </div>
    </BuildModeSection>
  );
};

export default BuildModeViewFilterSettings;
