import { useCallback, useMemo, useState } from 'react';
import { IconAlertTriangle, IconEdit } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Loader, Tooltip } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import BuildModeSectionOptions from '../../../components/buildMode/BuildModeSectionOptions';
import { FILE } from '../../../constants/builtInDataTypes';
import { darkModeColors } from '../../../constants/darkModeColors';
import { RECORD, RECORD_VIEW } from '../../../constants/elements';
import { CUSTOM_VISIBILITY_RULES } from '../../../constants/features';
import { FIELD } from '../../../constants/imageTypes';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  Element,
  ElementPath,
  ViewTab,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { setIsEditingData } from '../../../reducers/data';
import {
  setLeftEditorSection,
  setSelectedSectionPath,
} from '../../../reducers/elements';
import { isEditingDataSelector } from '../../../selectors/dataSelectors';
import { editorModeSelector } from '../../../selectors/elementsSelectors';
import { shouldRenderComponent } from '../../../utils/elementVisibility';
import { getImageFilesFromFieldValues } from '../../../utils/files';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useRouter from '../../../utils/hooks/useRouter';
import useScopeUser from '../../../utils/hooks/useScopeUser';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import useSelectedTab from '../../../utils/hooks/useSelectedTab';
import { getText } from '../../../utils/lang';
import { PermissionConfig } from '../../../utils/permissions';
import MobileActionButtonsWrapper from './MobileActionButtonsWrapper';
import RecordActionButtons from './RecordActionButtons';
import RecordViewSections from './RecordViewSections';
import RecordViewTabs from './RecordViewTabs';
import RecordViewTitle from './RecordViewTitle';

const LANG_KEY = 'elements.VIEW.edit';

interface RecordViewBodyProps {
  actionButtons: ActionButton[];
  comments: any;
  data: any;
  dataType: DataType;
  doneButtonText?: string;
  editButtonText?: string;
  editButtonVisibilityRules?: any;
  element: Element;
  elementPath: ElementPath;
  handleDrawerClose: () => void;
  headerWidth?: number | string;
  hideBreadcrumbs?: boolean;
  hideEditButton?: boolean;
  icon?: any;
  image?: any;
  isSplitLayout?: boolean;
  loading: boolean;
  name?: string;
  pageId: string;
  permissions: PermissionConfig;
  project: Project;
  recordId?: string;
  recordScope: Record<string, any>;
  refetch: () => void;
  rootPathname: string | null;
  scope: Record<string, any>;
  sections: any;
  showRecordTitle?: boolean;
  subtitle?: string;
  tabs: ViewTab[];
  title: string;
  viewRoutePrefix: string | undefined;
}

const RecordViewBody = ({
  actionButtons,
  comments,
  data,
  dataType,
  doneButtonText,
  editButtonText,
  editButtonVisibilityRules,
  element,
  elementPath,
  headerWidth,
  hideBreadcrumbs,
  hideEditButton,
  icon,
  image,
  isSplitLayout,
  loading,
  name,
  pageId,
  permissions,
  project,
  recordId,
  recordScope,
  refetch,
  rootPathname,
  scope,
  sections,
  showRecordTitle = false,
  subtitle,
  tabs,
  title,
  viewRoutePrefix,
}: RecordViewBodyProps) => {
  const currentUser = useScopeUser();

  const [isDarkModeEnabled] = useDarkMode();
  const { sm: isSmScreen } = useBreakpoints();

  const dispatch = useDispatch();
  const [editSectionsLoading, setEditSectionsLoading] = useState({});
  const [sectionError, setSectionError] = useState({});
  const {
    pathname,
    location,
    query: { recordId: queryRecordId, tab },
  } = useRouter();

  const {
    image: resolvedImage,
    editButtonText: resolvedEditButtonText,
    doneButtonText: resolvedDoneButtonText,
    subtitle: resolvedSubtitle,
  } = useSectionScopeVariables(
    RECORD_VIEW,
    {
      editButtonText,
      doneButtonText,
      image,
      subtitle,
    },
    project,
    elementPath,
    recordScope,
  );

  const recordImage = useMemo(() => {
    if (!image || image.hidden) {
      return undefined;
    }

    if (image.type !== FIELD) {
      return resolvedImage;
    }

    if (image.type === FIELD && image.fieldName) {
      const fileField = dataType.fields.getByName(image.fieldName);

      if (fileField && fileField.type === FILE) {
        const fileValues = getImageFilesFromFieldValues(
          fileField,
          get(recordScope, `${element.id}:VIEW`),
        );

        return first(fileValues);
      }
    }

    return resolvedImage;
  }, [dataType.fields, element.id, image, recordScope, resolvedImage]);

  const onError = useCallback((sectionId: string) => {
    setSectionError((currentSectionsError) => ({
      ...currentSectionsError,
      [sectionId]: true,
    }));
  }, []);

  const onLoadingChange = useCallback((sectionId: any, nextLoading: any) => {
    setEditSectionsLoading((currentEditSectionsLoading) => ({
      ...currentEditSectionsLoading,
      [sectionId]: nextLoading,
    }));
    setSectionError((currentSectionsError) => ({
      ...currentSectionsError,
      [sectionId]: false,
    }));
  }, []);

  const isEditLoading = useMemo(
    () => Object.values(editSectionsLoading).some(Boolean),
    [editSectionsLoading],
  );

  const hasError = useMemo(
    () => Object.values(sectionError).some(Boolean),
    [sectionError],
  );

  const backLink = useBackLink(project);

  const editorMode = useSelector(editorModeSelector);
  const isEditingData = useSelector(isEditingDataSelector(element.id));

  const onChangeEditingData = useCallback(
    (newIsEditingData: any) => {
      dispatch(setIsEditingData({ id: element.id, value: newIsEditingData }));

      if (!newIsEditingData && refetch) {
        refetch();
      }
    },
    [dispatch, element.id, refetch],
  );

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const visibilityRulesScope = useMemo(
    () => ({ ...scope, ...recordScope }),
    [recordScope, scope],
  );
  const editButtonVisible = useMemo(
    () =>
      !hideEditButton &&
      (editorMode ||
        shouldRenderComponent({
          currentUser,
          customRulesEnabled,
          isSmScreen,
          project,
          scope: visibilityRulesScope,
          visibilityRules: editButtonVisibilityRules,
        })),
    [
      currentUser,
      customRulesEnabled,
      editButtonVisibilityRules,
      editorMode,
      hideEditButton,
      isSmScreen,
      project,
      visibilityRulesScope,
    ],
  );
  const visibleTabs = useMemo(
    () =>
      editorMode
        ? tabs
        : tabs.filter(({ visibilityRules }) =>
            shouldRenderComponent({
              currentUser,
              customRulesEnabled,
              isSmScreen,
              project,
              scope: visibilityRulesScope,
              visibilityRules,
            }),
          ),
    [
      currentUser,
      customRulesEnabled,
      editorMode,
      isSmScreen,
      project,
      tabs,
      visibilityRulesScope,
    ],
  );

  const selectedTab = useSelectedTab(tabs, visibleTabs);

  const onCloseSectionEditor = useCallback(() => {
    dispatch(setSelectedSectionPath([]));
    dispatch(setLeftEditorSection(null));
  }, [dispatch]);

  const visibleActionButtons = useMemo(
    () =>
      actionButtons.reduce(
        (
          result: { actionButton: ActionButton; index: number }[],
          actionButton: ActionButton,
          index: number,
        ) => {
          const shouldDisplay =
            get(actionButton, ['display', 'record']) !== false;

          const isVisible =
            !isSmScreen ||
            shouldRenderComponent({
              currentUser,
              customRulesEnabled,
              isSmScreen,
              project,
              scope: visibilityRulesScope,
              visibilityRules: actionButton.visibilityRules,
            });

          if (shouldDisplay && isVisible) {
            result.push({ actionButton, index });
          }

          return result;
        },
        [],
      ),
    [
      actionButtons,
      currentUser,
      customRulesEnabled,
      isSmScreen,
      project,
      visibilityRulesScope,
    ],
  );

  if (tabs.length > 1 && visibleTabs.length > 0 && !loading) {
    // If we have more than one tab, and at least one visible tab, redirect the URL to that tab
    if (!tab) {
      return (
        <Redirect
          to={`${pathname}/${kebabCase((first as any)(visibleTabs).title)}${
            location.search || ''
          }`}
        />
      );
    } else if (selectedTab && kebabCase(selectedTab.title) !== tab) {
      // If the tab in the URL doesn't match the one we're rendering, redirect to it
      const updatedPathname = pathname.replace(
        new RegExp(`${tab}$`),
        kebabCase(selectedTab.title),
      );

      return <Redirect to={`${updatedPathname}${location.search || ''}`} />;
    }
  }

  return (
    <>
      <div
        className={classNames(
          'sticky top-0 z-40 flex w-full flex-col overflow-hidden border-b sm:static',
          {
            [`${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`]:
              isDarkModeEnabled,
            'bg-white': !isDarkModeEnabled,
            'px-3': headerWidth !== 12,
            'px-6': headerWidth === 12,
          },
        )}
        onClick={editorMode ? onCloseSectionEditor : undefined}
      >
        <RecordViewTitle
          backLink={backLink}
          comments={comments}
          data={data}
          dataType={dataType}
          headerWidth={headerWidth}
          hideBreadcrumbs={hideBreadcrumbs}
          icon={icon}
          image={recordImage}
          isSplitLayout={isSplitLayout}
          loading={loading}
          name={name}
          project={project}
          rootPathname={rootPathname}
          subtitle={resolvedSubtitle}
          title={title}
          viewRoutePrefix={viewRoutePrefix}
        >
          {!isSmScreen && (
            <RecordActionButtons
              actionButtons={visibleActionButtons}
              backLink={backLink}
              dataType={dataType}
              editorMode={editorMode}
              elementPath={elementPath}
              project={project}
              record={data}
              recordScope={recordScope}
              rootPathname={rootPathname}
            />
          )}
          <div className="flex items-center justify-center">
            {editButtonVisible && permissions.update && (
              <Button
                className={classNames(
                  'flex items-center space-x-2 rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg',
                  {
                    'sm:rounded-br-none sm:rounded-tr-none':
                      visibleActionButtons && visibleActionButtons.length > 0,
                  },
                )}
                disabled={isEditLoading}
                type={isEditingData ? 'outline' : 'filled'}
                rounded={false}
                onClick={() => onChangeEditingData(!isEditingData)}
              >
                {isEditLoading ? (
                  <Loader size="xs" />
                ) : hasError && isEditingData ? (
                  <Tooltip
                    content={getText(LANG_KEY, 'unsavedChanged')}
                    placement="top-end"
                    bg="white"
                  >
                    <span>
                      <IconAlertTriangle size={18} className="opacity-75" />
                    </span>
                  </Tooltip>
                ) : (
                  <IconEdit size={14} className="opacity-75" />
                )}
                <span>
                  {isEditingData
                    ? resolvedDoneButtonText || getText(LANG_KEY, 'done')
                    : resolvedEditButtonText || getText(LANG_KEY, 'edit')}
                </span>
              </Button>
            )}
            {isSmScreen &&
              visibleActionButtons &&
              visibleActionButtons.length > 0 && (
                <MobileActionButtonsWrapper
                  newButtonVisible={editButtonVisible && permissions.update}
                  type={isEditingData ? 'outline' : 'filled'}
                >
                  <RecordActionButtons
                    actionButtons={visibleActionButtons}
                    backLink={backLink}
                    buttonType="text"
                    dataType={dataType}
                    editorMode={editorMode}
                    elementPath={elementPath}
                    project={project}
                    record={data}
                    recordScope={recordScope}
                    rootPathname={rootPathname}
                  />
                </MobileActionButtonsWrapper>
              )}
          </div>
        </RecordViewTitle>
        <RecordViewTabs
          editorMode={editorMode}
          headerWidth={headerWidth}
          project={project}
          queryRecordId={queryRecordId}
          recordId={recordId}
          recordScope={recordScope}
          rootPathname={rootPathname}
          stickyTabs={showRecordTitle}
          tab={tab}
          visibleTabs={visibleTabs}
        />
      </div>
      <div
        className="mt-6 flex w-full flex-grow flex-wrap gap-y-4 px-2"
        data-testid="record-view-body"
      >
        {!loading && (
          <RecordViewSections
            data={data}
            dataType={dataType}
            editorMode={editorMode}
            elementPath={elementPath}
            isEditingData={isEditingData}
            onError={onError}
            onLoadingChange={onLoadingChange}
            pageId={pageId}
            project={project}
            isRecordView={true}
            recordScope={recordScope}
            rootPathname={rootPathname}
            sections={sections}
            selectedTab={selectedTab}
            tabs={tabs}
            visibleTabs={visibleTabs}
          />
        )}
        {loading && (
          <div className="flex w-full items-center justify-center py-36">
            <Loader />
          </div>
        )}
      </div>
      {editorMode && (
        <BuildModeSectionOptions
          dataType={dataType}
          elementPath={elementPath}
          elementType={RECORD}
          popover={false}
          project={project}
          sections={sections}
          selectedTab={selectedTab}
        />
      )}
    </>
  );
};

export default RecordViewBody;
