import React, { useCallback } from 'react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import { PropertyPath } from 'lodash';
import set from 'lodash/fp/set';
import { FormField } from '@noloco/components';
import { KeyValue } from '@noloco/core/src/constants/keyValue';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'core.keyValues';

interface Props {
  disabled?: boolean;
  pairs: KeyValue[];
  onUpdatePairs: (pairs: KeyValue[]) => void;
}

const KeyValueEditor = ({ disabled, pairs, onUpdatePairs }: Props) => {
  const onAddNewKeyValuePair = useCallback(() => {
    onUpdatePairs([...pairs, { key: null, value: null }]);
  }, [onUpdatePairs, pairs]);

  const onRemoveKeyValuePair = useCallback(
    (index: any) => {
      onUpdatePairs(pairs.filter((__: any, i: any) => i !== index));
    },
    [onUpdatePairs, pairs],
  );

  const onUpdateKeyValuePair = useCallback(
    (index: number, path: PropertyPath, value: any) =>
      onUpdatePairs(
        pairs.map((pair: KeyValue, pairIndex: number) =>
          index !== pairIndex ? pair : set(path, value, pair),
        ),
      ),
    [onUpdatePairs, pairs],
  );

  return (
    <div className="flex flex-col text-sm">
      <div className="mb-1 flex items-center">
        <div className="w-1/2 font-medium">
          <span className="py-2">{getText(LANG_KEY, 'key')}</span>
        </div>
        <div className="flex w-1/2 items-center justify-between font-medium">
          <span className="py-2">{getText(LANG_KEY, 'value')}</span>
          <button
            className={classNames('ml-2 p-2 text-gray-300', {
              'hover:text-white': !disabled,
            })}
            disabled={disabled}
            onClick={onAddNewKeyValuePair}
          >
            <IconPlus size={16} />
          </button>
        </div>
      </div>
      {pairs.map((pair: any, pairIndex: any) => (
        <div key={pairIndex} className="mb-4 flex items-center">
          <div className="w-1/2 pr-4">
            <FormField
              className="w-full"
              disabled={disabled}
              placeholder=""
              value={pair.key}
              onChange={({ target: { value } }: any) =>
                onUpdateKeyValuePair(pairIndex, ['key'], value)
              }
            />
          </div>
          <div className="flex w-1/2 items-center">
            <FormField
              className="w-full"
              disabled={disabled}
              placeholder=""
              value={pair.value}
              onChange={({ target: { value } }: any) =>
                onUpdateKeyValuePair(pairIndex, ['value'], value)
              }
            />
            <button
              className={classNames('ml-2 p-2 text-gray-300', {
                'hover:text-white': !disabled,
              })}
              disabled={disabled}
              onClick={() => onRemoveKeyValuePair(pairIndex)}
            >
              <IconTrash size={16} />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default KeyValueEditor;
