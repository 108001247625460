import React, { forwardRef } from 'react';
import { useAddChild, useUpdateProperty } from '../../utils/hooks/projectHooks';

interface WithUpdateProjectHooksProps {
  children: React.ReactNode;
}

const WithUpdateProjectHooks = forwardRef<unknown, WithUpdateProjectHooksProps>(
  ({ children }, ref) =>
    React.Children.map(children, (child) =>
      React.cloneElement(child as React.ReactElement, {
        ref,
        useAddChild: useAddChild,
        useUpdateProperty: useUpdateProperty,
      }),
    ),
);

export default WithUpdateProjectHooks;
