import { useCallback, useMemo } from 'react';
import initial from 'lodash/initial';
import { SUGGEST_LAYOUT } from '@noloco/ui/src/queries/project';
import { useAddChild } from '@noloco/ui/src/utils/hooks/projectHooks';
import { generateViewFromDataSource } from '@noloco/ui/src/utils/layout';
import { CollectionLayout, TABLE } from '../../constants/collectionLayouts';
import { DataType } from '../../models/DataTypes';
import { Element } from '../../models/Element';
import { Project } from '../../models/Project';
import { getPagesConfig } from '../pages';
import usePromiseQuery from './usePromiseQuery';
import useRouter from './useRouter';

export const useAddView = (
  project: Project,
  parentPage?: Element,
  setIsLoading?: (loading: boolean) => void,
) => {
  const { push } = useRouter();
  const [suggestLayout] = usePromiseQuery(SUGGEST_LAYOUT);

  const { isV2, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const [addChild] = useAddChild(
    project,
    isV2 ? [] : initial(pagesPath),
    isV2 ? [] : ['children'],
  );

  const onAddView = useCallback(
    async (
      dataType: DataType,
      newPageIndex?: number,
      viewParentPage?: Element,
    ) => {
      setIsLoading?.(true);
      let suggestedLayout = TABLE as CollectionLayout;
      let layoutConfig = {};

      try {
        const { data } = await suggestLayout({
          variables: {
            projectName: project.name,
            dataTypeId: dataType.id,
          },
        });

        if (data && data.suggestLayout && data.suggestLayout.layout) {
          suggestedLayout = data.suggestLayout.layout as CollectionLayout;
          layoutConfig = data.suggestLayout;
        }
      } catch (error) {
        console.error('Error suggesting layout for', dataType.id, error);
      }

      const newPage = generateViewFromDataSource(
        dataType.display,
        dataType,
        project,
        { createdAt: new Date() },
        { userGenerated: false, createdAt: new Date() },
        suggestedLayout,
        false,
        layoutConfig,
      );

      if (parentPage || viewParentPage) {
        // @ts-expect-error TS2339: Property 'parentPage' does not exist on type
        newPage.props.parentPage = parentPage?.id ?? viewParentPage?.id;
      }

      addChild(newPage, newPageIndex);
      setTimeout(() => {
        push(
          `${
            parentPage || viewParentPage
              ? `/${(parentPage || viewParentPage)?.props.routePath}`
              : ''
          }/${newPage.props.routePath}`,
        );
      }, 500);

      setIsLoading?.(false);

      return newPage;
    },
    [addChild, parentPage, project, push, suggestLayout, setIsLoading],
  );

  return onAddView;
};
