import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataFieldOptions from '@noloco/core/src/models/DataFieldOptions';
import {
  DataField,
  DataFieldArray,
  DataFieldOption,
} from '@noloco/core/src/models/DataTypeFields';
import { DataSource, DataType } from '@noloco/core/src/models/DataTypes';
import {
  addDataField,
  addDataType,
  addDataTypes,
  removeDataField,
  removeDataSource,
  removeDataType,
  swapDraftDataTypes,
  updateDataField,
  updateDataFieldOptions,
  updateDataType,
  updateDataTypeName,
} from '@noloco/core/src/reducers/project';
import { dataTypesSelector } from '@noloco/core/src/selectors/projectSelectors';

export const useUpdateDataTypesUpdatedAt = () => {
  const dataTypes = useSelector(dataTypesSelector);

  return useCallback(() => {
    dataTypes.update();
  }, [dataTypes]);
};

export const useUpdateDataFieldOptions = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({
      dataTypeId,
      dataFieldId,
      options,
    }: {
      dataTypeId: number;
      dataFieldId: number;
      options: DataFieldOption[];
    }) => {
      dispatch(
        updateDataFieldOptions({
          dataTypeId: dataTypeId,
          dataFieldId: dataFieldId,
          options: new DataFieldOptions(options),
        }),
      );
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useAddDataField = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({
      dataTypeName,
      dataField,
    }: {
      dataTypeName: string;
      dataField: DataField;
    }) => {
      dispatch(
        addDataField({
          dataTypeName,
          dataField: DataFieldArray.formatField(dataField),
        }),
      );
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useRemoveDataField = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({
      dataTypeId,
      dataField,
    }: {
      dataTypeId: number;
      dataField: DataField;
    }) => {
      dispatch(removeDataField({ dataTypeId, dataField }));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useUpdateDataField = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({
      dataTypeId,
      dataField,
    }: {
      dataTypeId: number;
      dataField: DataField;
    }) => {
      dispatch(
        updateDataField({
          dataTypeId,
          dataField: DataFieldArray.formatField(dataField),
        }),
      );
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useAddDataType = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    (dataType: DataType) => {
      dispatch(addDataType(dataType));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useAddDataTypes = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    (dataTypes: DataType[]) => {
      dispatch(addDataTypes(dataTypes));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useRemoveDataType = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({ dataType }: { dataType: DataType }) => {
      dispatch(removeDataType({ dataType }));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useRemoveDataSource = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    ({ dataSource }: { dataSource: DataSource }) => {
      dispatch(removeDataSource({ dataSource }));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useUpdateDataType = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    (dataType: DataType) => {
      dispatch(updateDataType(dataType));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useUpdateDataTypeName = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(
    (dataType: DataType) => {
      dispatch(updateDataTypeName(dataType));
      updateDataTypes();
    },
    [dispatch, updateDataTypes],
  );
};

export const useSwapDraftDataTypes = () => {
  const updateDataTypes = useUpdateDataTypesUpdatedAt();
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(swapDraftDataTypes());
    updateDataTypes();
  }, [dispatch, updateDataTypes]);
};
