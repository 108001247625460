import {
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Portal, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import {
  LG,
  MD,
  ShirtSize,
} from '@noloco/components/src/constants/tShirtSizes';
import { ESCAPE } from '@noloco/core/src/constants/shortcuts';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import useDarkModeSurface from '@noloco/core/src/utils/hooks/useDarkModeSurface';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';

export enum DrawerSide {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface DrawerProps {
  children: ReactNode;
  className?: string;
  disableTransition?: boolean;
  disableCloseOnEscape?: boolean;
  footer?: ReactNode;
  forceCloseOnEscape?: boolean;
  header?: ReactNode;
  Icon?: (props: any) => JSX.Element;
  onClose?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | KeyboardEvent<Element>,
  ) => void;
  onOpen?: () => void;
  rootSelector?: string;
  showHeader?: boolean;
  side?: DrawerSide;
  size?: ShirtSize;
  title?: ReactNode | string;
  useDarkTheme?: boolean;
  usePortal?: boolean;
}

const TRANSITION_CONFIG = {
  left: { enterFrom: '-translate-x-full', leaveTo: '-translate-x-full' },
  right: { enterFrom: 'translate-x-full', leaveTo: 'translate-x-full' },
};

const Drawer = ({
  children,
  className,
  disableTransition,
  disableCloseOnEscape = false,
  footer,
  forceCloseOnEscape = true,
  header,
  Icon,
  onClose,
  onOpen,
  rootSelector,
  showHeader = true,
  side = DrawerSide.RIGHT,
  size,
  title,
  useDarkTheme = true,
  usePortal,
  ...rest
}: DrawerProps) => {
  const darkModeSurface = useDarkModeSurface();
  const surface = useMemo(
    () => (useDarkTheme ? DARK : darkModeSurface),
    [useDarkTheme, darkModeSurface],
  );

  const [show, setShow] = useState<boolean>(false);
  const { enterFrom, leaveTo } = useMemo(() => TRANSITION_CONFIG[side], [side]);

  useEffect(() => {
    if (!disableTransition) {
      const timer = setTimeout(() => {
        setShow(true);
        onOpen?.();
      }, 50);

      return () => clearTimeout(timer);
    }

    setShow(true);
    onOpen?.();
  }, [disableTransition, onOpen]);

  const handleClose = useCallback(
    (event: any) => {
      if (forceCloseOnEscape) {
        setShow(false);
      }
      setTimeout(() => onClose!(event), 250);
    },
    [onClose, forceCloseOnEscape],
  );

  const CloseButton = useCallback(
    () => (
      <KeyboardShortcutTooltip
        buildMode={useDarkTheme}
        keys={[ESCAPE]}
        label={getText('rightSidebar.close')}
        offset={[0, 8]}
        placement="left"
      >
        <IconX
          className="flex-shrink-0 cursor-pointer text-slate-400 opacity-60 hover:opacity-100"
          onClick={handleClose}
          size={16}
        />
      </KeyboardShortcutTooltip>
    ),
    [handleClose, useDarkTheme],
  );

  useOnKeyPress(ESCAPE, handleClose, {
    enabled: !!show && !disableCloseOnEscape,
  });

  const component = (
    <Transition
      className={classNames(
        className,
        'absolute inset-y-0 z-40 flex w-full flex-grow transform flex-col overflow-hidden shadow-2xl transition',
        {
          'border-slate-700 bg-slate-800': surface === DARK && useDarkTheme,
          'border-gray-700 bg-gray-900': surface === DARK && !useDarkTheme,
          'border-slate-200 bg-white': surface === LIGHT,
          'left-0 border-r-2': side === DrawerSide.LEFT,
          'max-w-2xl': size === LG,
          'max-w-84': !size,
          'max-w-lg': size === MD,
          'right-0 border-l-2': side === DrawerSide.RIGHT,
          'text-black': surface === LIGHT,
          'text-slate-200': surface === DARK,
        },
      )}
      enter="ease-out duration-300"
      enterFrom={disableTransition ? undefined : enterFrom}
      enterTo="translate-x-0"
      leave="ease-in duration-300"
      leaveFrom="translate-x-0"
      leaveTo={leaveTo}
      show={!!show}
      {...rest}
    >
      {showHeader && (
        <div
          className={classNames(
            'flex h-10 w-full flex-shrink-0 border-b px-2',
            {
              'border-slate-700': surface === DARK && useDarkTheme,
              'border-gray-700': surface === DARK && !useDarkTheme,
              'border-slate-200': surface === LIGHT,
            },
          )}
        >
          {header ? (
            <div className="flex h-full w-full items-center justify-between px-2">
              <div className="flex w-full items-center">{header}</div>
              <CloseButton />
            </div>
          ) : (
            <div
              className={classNames(
                'flex h-full w-full items-center justify-between space-x-2 pl-2 text-xs',
                { 'flex-row-reverse': side === DrawerSide.LEFT },
              )}
            >
              <div className="flex items-center space-x-2 text-slate-400">
                {Icon && <Icon className="h-4 w-4" />}
                {title && <span>{title}</span>}
              </div>
              <CloseButton />
            </div>
          )}
        </div>
      )}
      <div className="flex-grow overflow-auto">{children}</div>
      {footer && (
        <div
          className={classNames('flex h-10 items-center border-t', {
            'border-slate-200 bg-white': surface === LIGHT,
            'border-slate-700 bg-slate-800': surface === DARK && useDarkTheme,
            'border-gray-700 bg-gray-900': surface === DARK && !useDarkTheme,
          })}
        >
          {footer}
        </div>
      )}
    </Transition>
  );

  const root =
    typeof document !== 'undefined' &&
    document.querySelector(rootSelector ?? '#root');

  if (usePortal && root) {
    return <Portal>{ReactDOM.createPortal(component, root)}</Portal>;
  }

  return component;
};

export default Drawer;
