import { useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { type CollectionLayout } from '../../../constants/collectionLayouts';
import { formatBg } from '../../../utils/styles';

const getSampleImageUrlByIndex = (index: any) =>
  `https://picsum.photos/id/${(index % 10) + 1020}/600/600`;

interface CollectionCardGalleryProps {
  h?: number;
  image: any;
  images: any;
  index: number;
  isView?: boolean;
  layout?: CollectionLayout;
}

const CollectionCardGallery = ({
  h = 56,
  image,
  images,
  index,
  isView,
  layout,
}: CollectionCardGalleryProps) => {
  const heroImagesToShow = useMemo(() => {
    if (image.hidden) {
      return [];
    }

    if (images) {
      return images;
    }

    if (image.value && (!isView || image.value.src)) {
      return [image.value];
    }

    return [];
  }, [image.hidden, image.value, images, isView]);
  const [imageIndex, setImageIndex] = useState(0);
  const isRowLayout = layout === 'ROWS';

  const onHandlePreviousImage = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (imageIndex > 0) {
        setImageIndex(imageIndex - 1);
      }
    },
    [imageIndex],
  );

  const onHandleNextImage = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (imageIndex < heroImagesToShow.length - 1) {
        setImageIndex(imageIndex + 1);
      }
    },
    [imageIndex, heroImagesToShow.length],
  );

  if (image.hidden || !heroImagesToShow.length) {
    return null;
  }

  return (
    <Box
      is="div"
      className={classNames(
        'group mb-2 flex w-full items-center bg-cover bg-center',
        {
          'rounded-lg': isRowLayout,
          'rounded-tl-lg rounded-tr-lg': !isRowLayout,
        },
      )}
      style={{
        ...formatBg(
          get(heroImagesToShow, [imageIndex, 'src']) ||
            (isView ? '' : getSampleImageUrlByIndex(index)),
        ),
      }}
      h={h}
      title={get(heroImagesToShow, [imageIndex, 'name'])}
    >
      {heroImagesToShow.length > 0 && (
        <div className="hidden w-full items-center justify-between px-2 group-hover:flex">
          <button
            className={classNames(
              'rounded-full border border-gray-200 bg-white p-1 text-gray-700 shadow-lg',
              { invisible: imageIndex === 0 },
            )}
            onClick={onHandlePreviousImage}
          >
            <IconChevronLeft size={14} />
          </button>
          <button
            className={classNames(
              'rounded-full border border-gray-200 bg-white p-1 text-gray-700 shadow-lg',
              {
                invisible: imageIndex === heroImagesToShow.length - 1,
              },
            )}
            onClick={onHandleNextImage}
          >
            <IconChevronRight size={14} />
          </button>
        </div>
      )}
    </Box>
  );
};

export default CollectionCardGallery;
