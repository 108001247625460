import { IconArrowUpRight, IconCalendarTime } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { Button } from '@noloco/components';
import { darkModeColors } from '../../../../constants/darkModeColors';
import { getText } from '../../../../utils/lang';
import { formatValue } from '../FieldCell';

interface CollectionEventPopoverContentProps {
  event: any;
  hideOpenButton?: boolean;
  isDarkModeEnabled: boolean;
  recordRowLink: string | null;
  Row: any;
}

const stringifyValueSafe = (value: any) =>
  value === undefined || value === null ? undefined : String(value);

const CollectionEventPopoverContent = ({
  event,
  hideOpenButton = false,
  isDarkModeEnabled,
  recordRowLink,
  Row,
}: CollectionEventPopoverContentProps) => (
  <div
    className="timeline-popover flex w-full flex-col"
    onMouseDown={(e) => e.stopPropagation()}
  >
    <div className="flex flex-col">
      <Row
        edge={{ node: event.record }}
        index={event.index}
        isLast={false}
        record={event.record}
      >
        <div className="my-4 w-full">
          <h2
            className={`text-base tracking-wider ${
              isDarkModeEnabled ? darkModeColors.text.primary : 'text-gray-800'
            }`}
          >
            {event.title}
          </h2>
          <div className="mt-2 flex items-center text-xs">
            <IconCalendarTime
              className={`mr-2 ${
                isDarkModeEnabled
                  ? darkModeColors.text.secondary
                  : 'text-gray-600'
              }`}
              size={16}
              color={
                isDarkModeEnabled
                  ? darkModeColors.icons.secondary
                  : 'currentColor'
              }
            />
            <span
              className={`${
                isDarkModeEnabled
                  ? darkModeColors.text.secondary
                  : 'text-gray-600'
              }`}
            >
              {stringifyValueSafe(
                formatValue(event.startRawIso, event.dateStartField, {}),
              )}
            </span>
            {event.hasEnd && (
              <>
                <span className="mx-1">-</span>
                <span className="text-gray-600">
                  {stringifyValueSafe(
                    formatValue(event.endRawIso, event.dateEndField, {}),
                  )}
                </span>
              </>
            )}
          </div>
        </div>
      </Row>
    </div>
    {recordRowLink && event.rootPathname && !hideOpenButton && (
      <div
        className={`flex w-full items-center justify-end px-3 py-4 ${
          isDarkModeEnabled ? `${darkModeColors.surfaces.elevation1}` : ''
        } `}
      >
        <Link to={recordRowLink}>
          <Button
            className="flex items-center"
            variant="primary"
            type="outline"
            isDarkModeEnabled={isDarkModeEnabled}
          >
            <span className="mb-px block">
              {getText('elements.COLLECTION.calendar.open')}
            </span>
            <IconArrowUpRight className="ml-2 opacity-75" size={16} />
          </Button>
        </Link>
      </div>
    )}
  </div>
);

export default CollectionEventPopoverContent;
