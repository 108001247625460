import { useMemo } from 'react';
import { FormValues } from '@noloco/ui/src/components/leftSidebar/data/DataFieldForm';
import { FIELDS_WITH_TYPE_OPTIONS } from '@noloco/ui/src/components/leftSidebar/data/FieldTypeOptionsInput';
import {
  AIRTABLE,
  GOOGLE_SHEETS,
  HUBSPOT,
  INTERNAL,
  MYSQL,
  POSTGRES,
  SMART_SUITE,
  XANO,
} from '../../constants/dataSources';
import {
  DataFieldType,
  NUMERIC_DATATYPES,
  OBJECT,
  TEXT,
} from '../../constants/dataTypes';
import { VALID_TYPES_TO_CONVERT } from '../../constants/fieldConversion';
import { PHONE_NUMBER } from '../../constants/fieldFormats';
import { AI_GENERATION_OPERATION } from '../../models/DataTypeFields';
import { DataType } from '../../models/DataTypes';
import { VISIBLE_INTERNAL_TYPES } from '../dataTypes';
import { isFormulaField } from '../formulas/isFormula';
import { isOptionType } from '../options';
import {
  hasPrimaryFieldDefined,
  isValidPrimaryFieldType,
} from '../primaryFields';
import { isMultiRelationship } from '../relationships';
import { useIsBuilder } from './useIsBuilder';

export const useMutableFieldProperties = (
  dataType: DataType,
  field: FormValues,
  providedType: DataFieldType | null = null,
) => {
  const { isBuilder } = useIsBuilder();

  const isAirtableBase = dataType.source.type === AIRTABLE;
  const isSmartSuiteSolution = dataType.source.type === SMART_SUITE;
  const isInternal = dataType.source.type === INTERNAL;
  const isGoogleSheet = dataType.source.type === GOOGLE_SHEETS;
  const isHubSpot = dataType.source.type === HUBSPOT;
  const isSqlDatabase =
    dataType.source.type === POSTGRES || dataType.source.type === MYSQL;
  const isXanoWorkspace = dataType.source.type === XANO;
  const isNolocoField = field.source === INTERNAL || !field.id;
  const isAirtableField = field.source === AIRTABLE;
  const isSmartSuiteField = field.source === SMART_SUITE;
  const isXanoField = field.source === XANO;

  const fieldIsImmutable: boolean = useMemo(
    () =>
      field &&
      (!isBuilder ||
        (isAirtableBase && !isNolocoField) ||
        (isSmartSuiteSolution && !isNolocoField) ||
        field.internal ||
        !!field.relatedField),
    [field, isAirtableBase, isBuilder, isNolocoField, isSmartSuiteSolution],
  );

  const canChangeFieldDisplay = useMemo(
    () => !fieldIsImmutable && isNolocoField,
    [fieldIsImmutable, isNolocoField],
  );

  const isAiSentimentField = useMemo(
    () =>
      field.typeOptions?.aiGenerationOptions?.operation ===
      AI_GENERATION_OPERATION.SENTIMENT,
    [field.typeOptions?.aiGenerationOptions?.operation],
  );

  const canChangeFieldOptions = useMemo(
    () =>
      !fieldIsImmutable &&
      !field.lookupId &&
      !isAiSentimentField &&
      (isGoogleSheet || isNolocoField || isSqlDatabase) &&
      !isXanoField &&
      isOptionType(providedType ?? field.type),
    [
      fieldIsImmutable,
      field.lookupId,
      field.type,
      isAiSentimentField,
      isGoogleSheet,
      isNolocoField,
      isSqlDatabase,
      isXanoField,
      providedType,
    ],
  );

  const canChangeFieldOptionsColor = useMemo(
    () =>
      !fieldIsImmutable &&
      !field.lookupId &&
      !isAiSentimentField &&
      (isGoogleSheet ||
        isHubSpot ||
        isNolocoField ||
        isSqlDatabase ||
        isXanoWorkspace) &&
      isOptionType(providedType ?? field.type),
    [
      fieldIsImmutable,
      field.lookupId,
      field.type,
      isAiSentimentField,
      isGoogleSheet,
      isHubSpot,
      isNolocoField,
      isSqlDatabase,
      isXanoWorkspace,
      providedType,
    ],
  );

  const canChangeFieldType = useMemo(
    () =>
      isBuilder &&
      !field.lookupId &&
      !field.relatedField &&
      !field.rollupId &&
      !field.internal &&
      (!isAirtableField ||
        (isAirtableField &&
          (field.type === TEXT ||
            field.typeOptions?.format === PHONE_NUMBER))) &&
      !isSmartSuiteField &&
      ((isAirtableField && field.type === OBJECT) ||
        field.relationship ||
        (field.type && VALID_TYPES_TO_CONVERT.includes(field.type))),
    [
      isBuilder,
      field.lookupId,
      field.relatedField,
      field.rollupId,
      field.relationship,
      field.internal,
      field.type,
      field.typeOptions,
      isAirtableField,
      isSmartSuiteField,
    ],
  );

  const hasFieldTypeOptions = useMemo(
    () =>
      ((field.type && FIELDS_WITH_TYPE_OPTIONS.includes(field.type)) ||
        isFormulaField(field)) &&
      !field.lookupId &&
      !field.rollupId,
    [field],
  );

  const canChangeFieldTypeOptions = useMemo(
    () =>
      isBuilder &&
      !field.relatedField &&
      !field.internal &&
      hasFieldTypeOptions,
    [isBuilder, field.relatedField, field.internal, hasFieldTypeOptions],
  );

  const canChangeRollup = useMemo(
    () =>
      isBuilder &&
      !fieldIsImmutable &&
      field.rollupId &&
      !field.lookupId &&
      !field.internal &&
      !isAirtableField &&
      !isSmartSuiteField &&
      !isXanoField,
    [
      isBuilder,
      fieldIsImmutable,
      field.rollupId,
      field.lookupId,
      field.internal,
      isAirtableField,
      isSmartSuiteField,
      isXanoField,
    ],
  );

  const canChangeLookup = useMemo(
    () =>
      isBuilder &&
      !fieldIsImmutable &&
      field.lookupId &&
      !field.internal &&
      !isAirtableField &&
      !isSmartSuiteField &&
      !isXanoField,
    [
      isBuilder,
      fieldIsImmutable,
      field.lookupId,
      field.internal,
      isAirtableField,
      isSmartSuiteField,
      isXanoField,
    ],
  );

  const canChangeFieldCanBeNegative = useMemo(
    () =>
      isBuilder &&
      !fieldIsImmutable &&
      isInternal &&
      field.type &&
      NUMERIC_DATATYPES.includes(field.type),
    [isBuilder, fieldIsImmutable, isInternal, field?.type],
  );

  const canSetFieldAsPrimary = useMemo(
    () =>
      !VISIBLE_INTERNAL_TYPES.includes(dataType.name) &&
      !fieldIsImmutable &&
      (isInternal ||
        isSqlDatabase ||
        isGoogleSheet ||
        isHubSpot ||
        isXanoWorkspace) &&
      !hasPrimaryFieldDefined(dataType, field) &&
      isValidPrimaryFieldType(field),
    [
      dataType,
      fieldIsImmutable,
      isInternal,
      isSqlDatabase,
      isGoogleSheet,
      isHubSpot,
      isXanoWorkspace,
      field,
    ],
  );

  const canDeleteField = useMemo(
    () => isBuilder && !fieldIsImmutable && (isInternal || isNolocoField),
    [fieldIsImmutable, isBuilder, isInternal, isNolocoField],
  );

  const canChangeSomething = useMemo(
    () =>
      isBuilder &&
      (canChangeFieldDisplay ||
        canChangeFieldOptions ||
        canChangeFieldType ||
        canChangeFieldTypeOptions ||
        canDeleteField ||
        canSetFieldAsPrimary),
    [
      canChangeFieldDisplay,
      canChangeFieldOptions,
      canChangeFieldType,
      canChangeFieldTypeOptions,
      canDeleteField,
      canSetFieldAsPrimary,
      isBuilder,
    ],
  );

  const canChangeRelationship = useMemo(
    () => !fieldIsImmutable && !!field.relationship,
    [field.relationship, fieldIsImmutable],
  );

  //field has a field dependency on a rollup field
  const hasRollupFieldDependency = useMemo(
    () =>
      dataType.fields?.find(
        (f) => f.rollup && f.rollup.relatedField === field.apiName,
      ),
    [field.apiName, dataType.fields],
  );

  const canChangeRelationshipMulti = useMemo(
    () =>
      canChangeRelationship &&
      (!hasRollupFieldDependency || !isMultiRelationship(field.relationship)),
    [canChangeRelationship, hasRollupFieldDependency, field.relationship],
  );

  return {
    canChangeFieldDisplay,
    canChangeFieldOptions,
    canChangeFieldOptionsColor,
    canChangeFieldType,
    canChangeFieldTypeOptions,
    canChangeFieldCanBeNegative,
    canChangeSomething,
    canDeleteField,
    canSetFieldAsPrimary,
    hasFieldTypeOptions,
    fieldIsImmutable,
    canChangeRelationship,
    canChangeRollup,
    canChangeLookup,
    canChangeRelationshipMulti,
  };
};
