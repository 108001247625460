import { useCallback, useMemo } from 'react';
import {
  IconArrowBack,
  IconArrowBigUp,
  IconArrowDown,
  IconArrowUp,
  IconBackspace,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface, getColorShade } from '@noloco/components/src';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import {
  ALT,
  CMD,
  ENTER,
  ESCAPE,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_BACKSPACE,
  KEY_D,
  KEY_H,
  KEY_N,
  KEY_P,
  KEY_S,
  SHIFT,
  ShortcutKeyType,
} from '../../../constants/shortcuts';
import useAltKey from '../../../utils/hooks/useAltKey';
import useCmdKey from '../../../utils/hooks/useCmdKey';

interface ShortcutKeysProps {
  background?: boolean;
  buildMode?: boolean;
  keys: ShortcutKeyType[];
  primaryColor?: string;
  surface?: Surface;
}

const ShortcutKeys = ({
  background = true,
  buildMode = false,
  keys,
  primaryColor,
  surface = LIGHT,
}: ShortcutKeysProps) => {
  const cmdKey = useCmdKey();
  const altKey = useAltKey();

  const getKey = useCallback(
    (key: ShortcutKeyType) => {
      switch (key) {
        case CMD:
          return cmdKey;

        case SHIFT:
          return <IconArrowBigUp size={16} />;

        case KEY_BACKSPACE:
          return <IconBackspace size={16} />;

        case KEY_ARROW_DOWN:
          return <IconArrowDown size={16} />;

        case KEY_ARROW_UP:
          return <IconArrowUp size={16} />;

        case ENTER:
          return <IconArrowBack size={16} />;

        case ESCAPE:
          return (
            <span className="flex w-fit items-center justify-center px-1">
              ESC
            </span>
          );

        case KEY_D:
        case KEY_H:
        case KEY_N:
        case KEY_P:
        case KEY_S:
          return (
            <span className="flex h-4 w-4 items-center justify-center">
              {key.slice(-1)}
            </span>
          );

        case ALT:
          return (
            <span className="flex h-4 min-w-4 items-center justify-center">
              {altKey}
            </span>
          );

        default:
          return (
            <span className="flex h-4 w-4 items-center justify-center">
              {key}
            </span>
          );
      }
    },
    [cmdKey, altKey],
  );

  const backgroundColor = useMemo(
    () => `bg-${getColorShade(primaryColor, surface === DARK ? 800 : 500)}`,
    [primaryColor, surface],
  );

  const textColor = useMemo(
    () => `text-${getColorShade(primaryColor, surface === DARK ? 100 : 700)}`,
    [primaryColor, surface],
  );

  return (
    <span className="flex items-center space-x-0.5">
      {keys.map((key) => (
        <span
          className={classNames(
            'flex items-center justify-center rounded-sm p-0.5 uppercase',
            primaryColor && [backgroundColor, textColor],
            {
              ...(background && !primaryColor
                ? {
                    'bg-slate-600 text-slate-300':
                      buildMode || surface === DARK,
                    'bg-slate-200 text-slate-400': surface === LIGHT,
                  }
                : {}),
            },
          )}
          key={key}
        >
          {getKey(key)}
        </span>
      ))}
    </span>
  );
};

export default ShortcutKeys;
