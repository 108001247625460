import { useMemo } from 'react';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import {
  FormConfigWithOptionalField,
  FormFieldConfig,
  FormSectionConfig,
  Section,
} from '../../models/View';
import { getPageQueryString } from '../../queries/project';
import { ensureArray } from '../arrays';
import { getDepFieldsForForm } from '../fieldDependencies';

interface AutoFormConfig {
  dataTypeName: string;
  dataTypeWithRelations: DataType;
  fieldConfigs: FormConfigWithOptionalField[];
  itemQueryString: string;
  sectionConfigs?: Section[];
}

const useAutoFormConfig = (
  dataType: DataType,
  fields: FormFieldConfig[],
  project: Project,
  recordId: any,
  sections?: FormSectionConfig[],
): AutoFormConfig => {
  const dataTypeName = useMemo(() => dataType && dataType.name, [dataType]);
  const fieldConfigs: FormConfigWithOptionalField[] = useMemo(() => {
    if (!dataType) {
      return [];
    }

    return fields
      .map((fieldConfig: any) => {
        if (fieldConfig.isCustomComponent) {
          return {
            config: fieldConfig,
          };
        }

        const field = dataType.fields.getByName(fieldConfig.field);

        return {
          field,
          config: fieldConfig,
        };
      })
      .filter(
        (fieldConfig: any) =>
          fieldConfig &&
          (fieldConfig.field || fieldConfig.config.isCustomComponent),
      );
  }, [dataType, fields]);

  const formFieldDependencies = useMemo(
    () => getDepFieldsForForm(fieldConfigs, dataType, project.dataTypes),
    [dataType, fieldConfigs, project.dataTypes],
  );
  const itemQueryString = useMemo(
    () =>
      dataType &&
      getPageQueryString(
        dataType.name,
        { id: recordId },
        formFieldDependencies,
      ),
    [dataType, formFieldDependencies, recordId],
  );

  const sectionConfigs = useMemo(
    () =>
      sections &&
      ensureArray(sections).map(
        (sectionConfig) =>
          ({
            config: {
              conditions: sectionConfig.conditions,
              helpText: sectionConfig.helpText,
              label: sectionConfig.label,
              name: sectionConfig.name,
              placeholder: sectionConfig.placeholder,
              value: sectionConfig.value,
            },

            section: {
              fields: sectionConfig.fields,
              id: sectionConfig.id,
            },
          }) as Section,
      ),
    [sections],
  );

  return {
    dataTypeName,
    dataTypeWithRelations: dataType,
    fieldConfigs,
    itemQueryString,
    sectionConfigs,
  };
};

export default useAutoFormConfig;
