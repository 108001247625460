import React, { forwardRef, useCallback, useState } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import useScopeUser from '../utils/hooks/useScopeUser';
import { isInternal } from '../utils/user';

interface InternalLayoutWrapperProps {
  children: React.ReactNode;
  className?: string;
}

const InternalLayoutWrapper = forwardRef<unknown, InternalLayoutWrapperProps>(
  (
    {
      className,
      children,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{}'.
      onClick,
      // @ts-expect-error TS(2339): Property 'headerContent' does not exist on type '{... Remove this comment to see the full error message
      headerContent,
      // @ts-expect-error TS(2339): Property 'innerClassName' does not exist on type '... Remove this comment to see the full error message
      innerClassName,
      // @ts-expect-error TS(2339): Property 'sidebarContent' does not exist on type '... Remove this comment to see the full error message
      sidebarContent,
    },
    ref,
  ) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const user = useScopeUser();

    const handleOnClick = useCallback(
      (event: any) => {
        event.stopPropagation();

        if (onClick) {
          onClick(event);
        }
      },
      [onClick],
    );

    return (
      <div
        className={classNames(
          className,
          'absolute bottom-0 left-0 right-0 top-0 flex h-screen max-h-screen w-full flex-grow overflow-hidden bg-gray-50 sm:h-auto',
        )}
        onClick={handleOnClick}
        // @ts-expect-error TS(2322): Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
        ref={ref}
      >
        {isInternal(user) && (
          <>
            <SimpleBar
              className={classNames(
                'flex h-full w-80 flex-shrink-0 flex-col overflow-y-auto overflow-x-hidden border-l border-r bg-white sm:w-full',
                { 'sm:hidden': !showSidebar },
              )}
              onClick={() => setShowSidebar(false)}
            >
              <div className="flex flex-col">{sidebarContent}</div>
            </SimpleBar>
          </>
        )}
        <div
          className={classNames(
            'flex flex-grow flex-col overflow-x-hidden',
            {
              'border-r': isInternal(user),
            },
            innerClassName,
          )}
        >
          {isInternal(user) && (
            <div className="flex w-full items-center border-b bg-white px-6 py-4 shadow">
              <div
                className="mb-auto mr-4 mt-2 hidden p-2 text-gray-800 sm:flex"
                onClick={() => setShowSidebar(true)}
              >
                <IconArrowLeft size={16} />
              </div>
              {headerContent}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  },
);

export default InternalLayoutWrapper;
