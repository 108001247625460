import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  TailwindThemeProvider,
  withTheme,
} from '@darraghmckay/tailwind-react-ui';
import { IconPlus } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import upperFirst from 'lodash/upperFirst';
import { useDispatch } from 'react-redux';
import { clearFieldValues } from '../../../reducers/formFields';
import { addDataItemToCollectionCache } from '../../../utils/apolloCache';
import useFormFields from '../../../utils/hooks/useFormFields';
import { useFormSections } from '../../../utils/hooks/useFormSections';
import { getText } from '../../../utils/lang';
import DataItemFormModal from './DataItemFormModal';

const DataTypeRecordCreationModal = ({
  apolloClient,
  className,
  dataType,
  depth,
  disabled,
  is,
  newRecordStateId = 'NEW',
  formFields,
  onChange,
  onOpen,
  collectionQueryString,
  project,
  title,
  theme,
  ...rest
}: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const fields = useFormFields(formFields, dataType, project, {
    useUrlValues: false,
  });
  const formSections = useFormSections(formFields);

  const handleOpen = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      setIsOpen(true);

      if (onOpen) {
        onOpen();
      }
    },
    [onOpen],
  );

  const updatedTheme = useMemo(() => {
    const themeWithDarkSelectText = set(
      'selectInput.textColor',
      'text-gray-800',
      theme,
    );
    const themeWithDarkDropzoneText = set(
      'dropzone.textColor',
      'text-gray-800',
      themeWithDarkSelectText,
    );
    const themeWithDarkText = set(
      'textInput.textColor',
      'text-gray-800',
      themeWithDarkDropzoneText,
    );

    return themeWithDarkText;
  }, [theme]);

  const onClose = useCallback(() => {
    dispatch(
      clearFieldValues({
        dataTypeName: dataType.name,
        id: newRecordStateId,
        fieldNames: dataType.fields.map((field: any) => field.name),
        updateTime: Date.now(),
      }),
    );
    setIsOpen(false);
  }, [dataType.fields, dataType.name, dispatch, newRecordStateId]);

  const onAddDataItem = useCallback(
    (newDataItem: any) => {
      const creationKey = dataType.apiName === 'user' ? 'invite' : 'create';
      // @ts-expect-error TS(2554): Expected 5-6 arguments, but got 4.
      addDataItemToCollectionCache(
        {
          [`${creationKey}${upperFirst(dataType.apiName)}`]: newDataItem,
        },
        apolloClient,
        collectionQueryString,
        dataType.apiName,
      );
      onChange(newDataItem);

      onClose();
    },
    [dataType.apiName, apolloClient, collectionQueryString, onChange, onClose],
  );

  return (
    <TailwindThemeProvider theme={updatedTheme}>
      <Box
        className={classNames(
          className,
          'flex w-full items-center opacity-75 hover:opacity-100',
        )}
        disabled={disabled}
        onClick={handleOpen}
        {...rest}
        is={is}
      >
        <div className="flex w-full items-center">
          <IconPlus size={16} className="bg-opacity-50" />
          <span className="ml-2">
            {getText({ dataType: dataType.display }, 'core.SELECT_INPUT.new')}
          </span>
        </div>
      </Box>
      {isOpen && (
        <DataItemFormModal
          // @ts-expect-error TS(2322): Type '{ className: string; editorMode: boolean; fo... Remove this comment to see the full error message
          className="ml-16 sm:ml-0"
          depth={depth}
          editorMode={false}
          formFields={fields}
          formSections={formSections}
          newRecordStateId={newRecordStateId}
          id={`${dataType.id}-create`}
          onAddDataItem={onAddDataItem}
          onClose={onClose}
          dataType={dataType}
          project={project}
          title={title}
        />
      )}
    </TailwindThemeProvider>
  );
};

DataTypeRecordCreationModal.defaultProps = {
  is: 'button',
};

export default withTheme(DataTypeRecordCreationModal);
