export const AUTH_URLS = {
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot',
  JOIN_PROJECT: '/join',
};

export function isAuthPage(pathname: string) {
  return Object.values(AUTH_URLS).some((url) => pathname.startsWith(url));
}
