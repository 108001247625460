import { useCallback, useMemo } from 'react';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import last from 'lodash/last';
import FilterMergeWarning from '@noloco/ui/src/components/editor/FilterMergeWarning';
import BuildModeAndFilterInput from '../../../components/buildMode/filters/BuildModeAndFilterInput';
import { NON_RESULT_OPERATORS } from '../../../constants/operators';
import { useFieldOperatorConflicts } from '../../../utils/hooks/useFieldOperatorConflicts';
import { getText } from '../../../utils/lang';

const LANG_KEY = 'elements.LIST.filterInput';
const getTranslation = (...rest: any[]) => getText(LANG_KEY, ...rest);

const BuildModeOrFilterBranchInput = ({
  additionalScopeItems,
  branch,
  dataType,
  elementPath,
  generateAdditionalFilter,
  index,
  onChange,
  project,
  setValue,
  value,
  ValueInput,
}: any) => {
  const handleOrBranchValueChange = useCallback(
    (branchIndex: any, filterIndex: any, newValue: any) => {
      const updated = set(
        ['branches', branchIndex, 'filters', filterIndex],
        newValue,
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [onChange, setValue, value],
  );

  const handleOrBranchValueRemove = useCallback(
    (branchIndex: any, filterIndex: any) => {
      const updatedFilters = value.branches[branchIndex].filters.filter(
        (_: any, index: any) => index !== filterIndex,
      );

      const updated = set(
        ['branches', branchIndex, 'filters'],
        updatedFilters,
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [onChange, setValue, value],
  );

  const handleOrBranchValueAdded = useCallback(
    (branchIndex: any) => {
      const updated = set(
        ['branches', branchIndex, 'filters'],
        [...value.branches[branchIndex].filters, generateAdditionalFilter()],
        value,
      );

      setValue(updated);
      onChange(updated);

      return updated;
    },
    [generateAdditionalFilter, onChange, setValue, value],
  );

  const handleOrBranchRemoved = useCallback(
    (branchIndex: any) => {
      const newLocalValue = {
        ...value,
        branches: value.branches.filter(
          (_: any, index: any) => branchIndex !== index,
        ),
      };

      setValue(newLocalValue);
      onChange(newLocalValue);

      return newLocalValue;
    },
    [onChange, setValue, value],
  );

  const { fieldOperatorCounts, showFieldOperatorWarning } =
    useFieldOperatorConflicts(branch.filters);

  const isLastFilterValid = useMemo(() => {
    const lastFilter = last(branch.filters) as any;

    return (
      lastFilter &&
      lastFilter.field &&
      lastFilter.operator &&
      (NON_RESULT_OPERATORS.includes(lastFilter.operator) || lastFilter.result)
    );
  }, [branch]);

  return (
    <>
      {index !== 0 && (
        <div className="relative py-2">
          <div className="absolute inset-y-0 left-1/2 w-px -translate-x-1/2 transform border-l border-dashed border-slate-800"></div>
          <div className="relative z-10 bg-slate-700 text-center text-slate-400">
            {getTranslation('or')}
          </div>
        </div>
      )}
      {showFieldOperatorWarning && <FilterMergeWarning />}
      <div className="relative rounded-lg bg-slate-800 p-2">
        <div
          className={classNames('flex items-center justify-end space-x-2', {
            'mb-2': branch.filters.length > 0,
          })}
        >
          {(isLastFilterValid || branch.filters.length === 0) && (
            <div
              className="cursor-pointer rounded-lg p-1 hover:bg-slate-500"
              onClick={() => handleOrBranchValueAdded(index)}
            >
              <IconPlus size={12} />
            </div>
          )}
          <div
            className="cursor-pointer rounded-lg p-1 hover:bg-slate-500 hover:text-red-300"
            onClick={() => handleOrBranchRemoved(index)}
          >
            <IconTrash size={12} />
          </div>
        </div>
        {branch.filters.map((branchFilter: any, filterIndex: any) => (
          <>
            <BuildModeAndFilterInput
              additionalScopeItems={additionalScopeItems}
              canAddFilter={filterIndex === branch.filters.length - 1}
              dataType={dataType}
              key={branchFilter.id}
              value={branchFilter}
              onAddAndFilter={() => handleOrBranchValueAdded(index)}
              onChange={(newValue: any) =>
                handleOrBranchValueChange(index, filterIndex, newValue)
              }
              onRemove={() => handleOrBranchValueRemove(index, filterIndex)}
              elementPath={elementPath}
              operatorValidationError={
                get(
                  fieldOperatorCounts,
                  [branchFilter.field, branchFilter.operator],
                  0,
                ) > 1
              }
              project={project}
              ValueInput={ValueInput}
            />
            {filterIndex !== branch.filters.length - 1 && (
              <div className="relative py-2">
                <div className="absolute inset-y-0 left-1/2 w-px -translate-x-1/2 transform border-l border-dashed border-slate-700"></div>
                <div className="relative z-10 bg-slate-800 text-center text-slate-400">
                  {getText(LANG_KEY, 'addAnd')}
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default BuildModeOrFilterBranchInput;
