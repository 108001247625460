import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconSelector } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { useUpdateProject } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  HIDDEN_PAGES,
  LeftEditorSectionOptions,
  MENU_PAGES,
  SIDEBAR_EXPANDED,
} from '../../constants/buildMode';
import { SINGLE_RECORD } from '../../constants/collectionLayouts';
import { PAGE } from '../../constants/elements';
import SidebarCollapseButton from '../../elements/sections/collections/SidebarCollapseButton';
import { Project } from '../../models/Project';
import { ghostUserIdSelector } from '../../selectors/dataSelectors';
import useBoolLocalStorageState from '../../utils/hooks/useBoolLocalStorageState';
import useRouter from '../../utils/hooks/useRouter';
import { getText } from '../../utils/lang';
import { Page } from '../../utils/pages';
import { getActivePage } from '../../utils/urls';
import BuildModeEditorRouting from '../buildMode/BuildModeEditorRouting';
import BuildModeFooter from '../buildMode/BuildModeFooter';
import BuildModeIcon from '../buildMode/BuildModeIcon';
import BuildModeSection from '../buildMode/BuildModeSection';
import LeftBuildModePageEditor from '../buildMode/LeftBuildModePageEditor';
import LeftBuildModeRecordEditor from '../buildMode/LeftBuildModeRecordEditor';
import BuildModeSidebarItemOptions from '../buildMode/sidebar/BuildModeSidebarItemOptions';
import BuildModeSidebarNav from '../buildMode/sidebar/BuildModeSidebarNav';

interface LeftBuildModeEditorProps {
  editorMode: boolean;
  leftEditor: LeftEditorSectionOptions | null;
  pages: Page[];
  portalPages: Page[];
  project: Project;
}

const LeftBuildModeEditor = forwardRef(
  (
    {
      editorMode,
      leftEditor,
      pages,
      portalPages,
      project,
    }: LeftBuildModeEditorProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { pathname } = useRouter();
    const ghostUserId = useSelector(ghostUserIdSelector);
    const [updateProject, { isLoading: isUpdating }] =
      useUpdateProject(project);
    const [listExpanded, setListExpanded] = useState<boolean>(false);
    const [sidebarExpanded, setSidebarExpanded] = useBoolLocalStorageState(
      SIDEBAR_EXPANDED,
      true,
    );

    const activePage = useMemo(
      () => getActivePage(pages, pathname),
      [pages, pathname],
    );

    const isCurrentPathPageOrRecord = useMemo(() => {
      if (pathname.includes('view')) {
        return true;
      }

      return (
        get(activePage, 'type') === PAGE ||
        get(activePage, 'props.layout') === SINGLE_RECORD
      );
    }, [pathname, activePage]);

    const toggleSidebar = useCallback(
      () => setSidebarExpanded(!sidebarExpanded),
      [setSidebarExpanded, sidebarExpanded],
    );

    useEffect(() => {
      if (activePage) {
        const element = document.getElementById(
          `sidebar-item-${activePage.id}`,
        );
        element?.scrollIntoView({ block: 'center' });
      }
    }, [activePage]);

    return (
      <div
        className={classNames(
          'sidebar-editor relative h-full max-h-screen select-none bg-slate-800 pb-6 text-xs text-white sm:h-auto sm:min-h-0 sm:w-full sm:max-w-none',
          { 'w-full max-w-64': sidebarExpanded, 'w-20': !sidebarExpanded },
        )}
        ref={ref}
      >
        <SidebarCollapseButton
          buildMode={true}
          isOpen={sidebarExpanded}
          onClick={toggleSidebar}
        />
        <SimpleBar
          className={classNames(
            'flex h-full w-full flex-grow flex-col overflow-y-auto overflow-x-hidden',
            {
              'pb-12': ghostUserId,
              'pb-4': !ghostUserId,
              'w-full min-w-20 max-w-20': !sidebarExpanded,
              'w-full max-w-64': sidebarExpanded,
            },
          )}
        >
          <div
            className={classNames({
              'pb-4': sidebarExpanded,
              'pb-12': !sidebarExpanded,
            })}
          >
            {sidebarExpanded ? (
              <>
                <BuildModeSection
                  className="sticky top-0 z-10"
                  endComponent={
                    <div className="mr-4 flex items-center space-x-2">
                      <Tooltip
                        content={
                          <span className="text-slate-200">
                            {getText(
                              'leftSidebar.auth',
                              listExpanded ? 'collapseAll' : 'expandAll',
                            )}
                          </span>
                        }
                        offset={[0, 8]}
                        placement="left"
                        showArrow={false}
                        surface={DARK}
                      >
                        <BuildModeIcon
                          Icon={IconSelector}
                          onClick={() =>
                            setListExpanded((listExpanded) => !listExpanded)
                          }
                        />
                      </Tooltip>
                      <BuildModeSidebarItemOptions
                        offset={[0, 24]}
                        openByShortcut={true}
                        project={project}
                      />
                    </div>
                  }
                  id={MENU_PAGES}
                  title={getText('leftSidebar.auth.menu')}
                >
                  <BuildModeSidebarNav
                    activePage={activePage}
                    listExpanded={listExpanded}
                    portalPages={portalPages}
                    project={project}
                    updateProject={updateProject}
                  />
                </BuildModeSection>
                <BuildModeSection
                  id={HIDDEN_PAGES}
                  className={classNames('sticky top-12 z-10 border-t', {
                    'bottom-4': !isCurrentPathPageOrRecord,
                  })}
                  title={getText('leftSidebar.auth.hiddenPages')}
                >
                  <BuildModeSidebarNav
                    activePage={activePage}
                    listExpanded={listExpanded}
                    portalPages={portalPages}
                    project={project}
                    showHiddenPages={true}
                    updateProject={updateProject}
                  />
                </BuildModeSection>
              </>
            ) : (
              <>
                <div className="sticky top-0 z-10 flex h-12 items-center justify-center border-b border-slate-700 bg-slate-800">
                  <BuildModeSidebarItemOptions
                    offset={[0, 32]}
                    openByShortcut={true}
                    project={project}
                    sidebarExpanded={sidebarExpanded}
                  />
                </div>
                <BuildModeSidebarNav
                  activePage={activePage}
                  listExpanded={listExpanded}
                  portalPages={portalPages}
                  project={project}
                  sidebarExpanded={sidebarExpanded}
                  updateProject={updateProject}
                />
              </>
            )}
          </div>
          {sidebarExpanded && (
            <BuildModeEditorRouting
              leftEditor={leftEditor}
              PageEditor={LeftBuildModePageEditor}
              pages={portalPages}
              RecordEditor={LeftBuildModeRecordEditor}
            />
          )}
        </SimpleBar>
        <BuildModeFooter
          editorMode={editorMode}
          isUpdating={isUpdating}
          project={project}
          sidebarExpanded={sidebarExpanded}
          updateProject={updateProject}
        />
      </div>
    );
  },
);

export default LeftBuildModeEditor;
