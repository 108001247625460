import { forwardRef, useCallback, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import {
  IconAlertTriangle,
  IconArrowAutofitWidth,
  IconArrowDown,
  IconArrowUp,
  IconCopy,
  IconFolders,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import get from 'lodash/get';
import initial from 'lodash/initial';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Tooltip } from '@noloco/components';
import { OptionValue } from '@noloco/components/src/components/switch/SwitchButton';
import { LG, MD, XL } from '@noloco/components/src/constants/tShirtSizes';
import { cloneElement } from '@noloco/ui/src/utils/elements';
import {
  useCloneElement,
  useRemoveSelected,
  useSwapElementAtIndex,
  useUpdateElements,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { WithDraggable } from '../../../components/withDnD';
import { darkModeColors } from '../../../constants/darkModeColors';
import { SECTION as DRAGGABLE_SECTION } from '../../../constants/draggableItemTypes';
import { CONTAINER, SECTION } from '../../../constants/elements';
import {
  CMD,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_BACKSPACE,
  KEY_D,
  SHIFT,
} from '../../../constants/shortcuts';
import { Element } from '../../../models/Element';
import {
  setLeftEditorSection,
  setSelectedElement,
  setSelectedSectionPath,
} from '../../../reducers/elements';
import {
  hasSelectedElementPathSelector,
  highlightedSectionSelector,
  leftEditorSectionSelector,
  selectedSectionSelector,
} from '../../../selectors/elementsSelectors';
import { skipPropResolvingByValueIds } from '../../../utils/elementPropResolvers';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useSectionScopeVariables from '../../../utils/hooks/useSectionScopeVariables';
import isTargetAnInput from '../../../utils/isTargetAnInput';
import { getText } from '../../../utils/lang';
import { RECORD_SCOPE, transformColumnarScope } from '../../../utils/scope';
import KeyboardShortcutTooltip from './KeyboardShortcutTooltip';
import Section from './Section';

const LANG_KEY = 'core.sections.editor';

const DRAG = 'drag';
const CHANGE_TABS = 'changeTabs';
const CLONE = 'clone';
const MOVE_DOWN = 'moveDown';
const MOVE_UP = 'moveUp';
const REMOVE = 'remove';
const WIDTH = 'width';

const TOOLTIP = 'tooltip';

const ViewSection = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'backLink' does not exist on type '{}'.
      backLink,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type '{}'.
      className,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'record' does not exist on type '{}'.
      record,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
      editorMode,
      // @ts-expect-error TS(2339): Property 'draggable' does not exist on type '{}'.
      draggable,
      // @ts-expect-error TS(2339): Property 'isOver' does not exist on type '{}'.
      isOver,
      // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
      elementPath,
      // @ts-expect-error TS(2339): Property 'isRecordView' does not exist on type '{}... Remove this comment to see the full error message
      isRecordView,
      // @ts-expect-error TS(2339): Property 'isEditingData' does not exist on type '{... Remove this comment to see the full error message
      isEditingData,
      // @ts-expect-error TS(2339): Property 'onError' does not exist on type ... Remove this comment to see the full error message
      onError,
      // @ts-expect-error TS(2339): Property 'onLoadingChange' does not exist on type ... Remove this comment to see the full error message
      onLoadingChange,
      // @ts-expect-error TS(2339): Property 'rootPathname' does not exist on type '{}... Remove this comment to see the full error message
      rootPathname,
      // @ts-expect-error TS(2339): Property 'section' does not exist on type '{}'.
      section,
      // @ts-expect-error TS(2339): Property 'pageId' does not exist on type '{}'.
      pageId,
      // @ts-expect-error TS(2339): Property 'path' does not exist on type '{}'.
      path,
      // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
      index,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'tabs' does not exist on type '{}'.
      tabs,
      // @ts-expect-error TS(2339): Property 'visibleSections' does not exist on type ... Remove this comment to see the full error message
      visibleSections,
      // @ts-expect-error TS(2339): Property 'toggleSectionExpandedState' does not exist on type ... Remove this comment to see the full error message
      toggleSectionExpandedState,
      // @ts-expect-error TS(2339): Property 'isExpanded' does not exist on type ... Remove this comment to see the full error message
      isExpanded,
      // @ts-expect-error TS(2339): Property 'dragRef' does not exist on type '{}'.
      dragRef,
      // @ts-expect-error TS(2339): Property 'selectedTab' does not exist on type '{}'.
      selectedTab,
    },
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useDispatch();
    const surface = useDarkModeSurface();
    const [isDarkModeEnabled] = useDarkMode();
    const highlightedSectionPath = useSelector(highlightedSectionSelector);

    const sectionPath = useMemo(
      () =>
        isRecordView
          ? [...elementPath, 'props', 'record', 'sections', ...path]
          : [...elementPath, 'props', 'sections', ...path],
      [isRecordView, elementPath, path],
    );

    const useTabs = tabs && tabs.length > 1;

    const [updateElements] = useUpdateElements(project);

    const sectionIndexes = useMemo(() => {
      const index = visibleSections.findIndex(
        (s: any) => s.section.id === section.id,
      );
      const previous = index > 0 && get(visibleSections, [index - 1, 'index']);
      const next =
        index < visibleSections.length - 1 &&
        get(visibleSections, [index + 1, 'index']);

      return {
        index,
        previous,
        next,
      };
    }, [visibleSections, section.id]);

    const containerSections = useMemo<
      { section: Element; index: number }[]
    >(() => {
      if (section.type === CONTAINER) {
        return visibleSections.filter(
          (visibleSection: any) =>
            visibleSection.section.container === section.id,
        );
      }

      return [];
    }, [section, visibleSections]);

    const [cloneSection] = useCloneElement(project, sectionPath);

    const sections = useMemo<Element[]>(
      () => get(project, ['elements', ...initial(sectionPath)], []),
      [project, sectionPath],
    );

    const handleClone = useCallback(
      (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (section.type === CONTAINER) {
          const clonedSection = cloneElement(section);

          updateElements(initial(sectionPath), [
            ...sections,
            clonedSection,
            ...containerSections.map((containerSection) =>
              cloneElement(
                { ...containerSection.section, container: clonedSection.id },
                { [clonedSection.id]: clonedSection.id },
              ),
            ),
          ]);

          dispatch(setSelectedSectionPath([sections.length]));
          dispatch(setSelectedElement(elementPath));
        } else {
          cloneSection(elementPath);
        }
      },
      [
        cloneSection,
        containerSections,
        dispatch,
        elementPath,
        section,
        sections,
        sectionPath,
        updateElements,
      ],
    );

    const tabOptions = useMemo(
      () =>
        tabs &&
        // @ts-expect-error TS(7006): Parameter 'tab' implicitly has an 'any' type.
        tabs.map((tab, idx) => ({
          value: idx === 0 ? null : tab.id,
          label: tab.title,
        })),
      [tabs],
    );

    const widthOptions = useMemo(
      () => [
        {
          label: getText(LANG_KEY, WIDTH, 'options.default'),
          value: undefined,
        },
        ...[3, 4, 6, 8, 9, XL, ...(section.container ? [] : [LG])].map(
          (value) => ({
            label: getText(LANG_KEY, WIDTH, 'options', value),
            value,
          }),
        ),
      ],
      [section],
    );

    const removeOptions = useMemo(
      () => [
        { label: getText(LANG_KEY, REMOVE, 'options.remove'), value: false },
        { label: getText(LANG_KEY, REMOVE, 'options.removeAll'), value: true },
      ],
      [],
    );

    const onChangeTab = useCallback(
      (newTab: OptionValue) => {
        const newSections = sections.map((newSection) => {
          if (newSection.id === section.id) {
            return { ...newSection, tab: newTab };
          }

          if (
            containerSections
              .map((containerSection) => containerSection.section.id)
              .includes(newSection.id)
          ) {
            return { ...newSection, tab: newTab };
          }

          return newSection;
        });

        updateElements(initial(sectionPath), newSections);
      },
      [sections, updateElements, sectionPath, section?.id, containerSections],
    );

    const onChangeWidth = useCallback(
      (newWidth: any) =>
        updateElements([...sectionPath, 'sectionWidth'], newWidth),
      [sectionPath, updateElements],
    );

    const recordScope = useMemo(() => {
      const recordWithColumnar = transformColumnarScope(
        record,
        dataType,
        project.dataTypes,
      );

      return {
        [RECORD_SCOPE]: recordWithColumnar,
        [pageId]: recordWithColumnar,
      };
    }, [dataType, pageId, project.dataTypes, record]);

    const sectionProps = useSectionScopeVariables(
      SECTION,
      section.props,
      project,
      sectionPath,
      recordScope,
      skipPropResolvingByValueIds([`${section.id}:RECORD`]),
    );

    const [onMoveElementUp, canMoveUp] = useSwapElementAtIndex(
      project,
      sectionPath,
      sectionIndexes.previous,
    );
    const [onMoveElementDown, canMoveDown] = useSwapElementAtIndex(
      project,
      sectionPath,
      sectionIndexes.next,
    );
    const [removeSelectedElement] = useRemoveSelected(project, sectionPath);

    const handleMoveUp = useCallback(
      (e: any) => {
        e.stopPropagation();

        if (sectionIndexes.previous !== false) {
          onMoveElementUp();
          dispatch(
            setSelectedSectionPath([...initial(path), sectionIndexes.previous]),
          );
          dispatch(setSelectedElement(elementPath));
        }
      },
      [dispatch, elementPath, onMoveElementUp, path, sectionIndexes.previous],
    );

    const handleMoveDown = useCallback(
      (e: any) => {
        e.stopPropagation();

        if (sectionIndexes.next !== false) {
          onMoveElementDown();
          dispatch(
            setSelectedSectionPath([...initial(path), sectionIndexes.next]),
          );
          dispatch(setSelectedElement(elementPath));
        }
      },
      [dispatch, elementPath, onMoveElementDown, path, sectionIndexes.next],
    );

    const handleRemoveContainerSection = useCallback(
      (removeAll: OptionValue) => {
        let newSections = [] as Element[];

        if (removeAll === true) {
          newSections = sections.filter(
            (oldSection) =>
              !containerSections
                .map((containerSection) => containerSection.section.id)
                .includes(oldSection.id) && oldSection.id !== section.id,
          );
        } else {
          newSections = sections
            .map((oldSection) => {
              if (oldSection.container && oldSection.container === section.id) {
                delete oldSection.container;
              }

              return oldSection;
            })
            .filter((oldSection) => oldSection.id !== section.id);
        }

        updateElements(initial(sectionPath), newSections);
        dispatch(setSelectedSectionPath([]));
        dispatch(setLeftEditorSection(null));
      },
      [
        containerSections,
        dispatch,
        section,
        sectionPath,
        sections,
        updateElements,
      ],
    );

    const handleRemove = useCallback(
      (e: any) => {
        e.stopPropagation();

        if (!isTargetAnInput(e.target)) {
          if (section.type === CONTAINER && containerSections.length > 0) {
            return handleRemoveContainerSection(true);
          }

          removeSelectedElement();
          dispatch(setSelectedSectionPath([]));
          dispatch(setLeftEditorSection(null));
        }
      },
      [
        dispatch,
        removeSelectedElement,
        section,
        containerSections,
        handleRemoveContainerSection,
      ],
    );

    const hasSelectedElement = useSelector(hasSelectedElementPathSelector);
    const selectedSectionPath = useSelector(selectedSectionSelector);
    const leftEditor = useSelector(leftEditorSectionSelector);

    const onSelectSection = useCallback(() => {
      if (leftEditor === CONTAINER && section.type === CONTAINER) {
        return null;
      }

      dispatch(setSelectedSectionPath(path));

      if (!isRecordView) {
        dispatch(setSelectedElement(elementPath));
      }

      dispatch(setLeftEditorSection(section.type));
    }, [dispatch, elementPath, isRecordView, path, section, leftEditor]);

    const isSelected = useMemo(
      () =>
        hasSelectedElement && selectedSectionPath.join('.') === path.join('.'),
      [path, selectedSectionPath, hasSelectedElement],
    );

    const isHighlighted = useMemo(
      () =>
        highlightedSectionPath &&
        highlightedSectionPath.join('.') === path.join('.'),
      [highlightedSectionPath, path],
    );

    const fullScreen = useMemo(
      () => get(sectionProps, 'fullScreen', false),
      [sectionProps],
    );

    const isShortcutEnabled = useMemo(
      () =>
        editorMode &&
        hasSelectedElement &&
        first(selectedSectionPath) === index,
      [editorMode, hasSelectedElement, selectedSectionPath, index],
    );

    const showHoverHighlight = useMemo(
      () => editorMode && !isSelected && !fullScreen && !isOver,
      [editorMode, isSelected, fullScreen, isOver],
    );

    const showHighlight = useMemo(
      () => editorMode && isSelected && !fullScreen,
      [editorMode, isSelected, fullScreen],
    );

    useOnKeyPress(KEY_ARROW_UP, handleMoveUp, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled,
    });

    useOnKeyPress(KEY_ARROW_DOWN, handleMoveDown, {
      ctrlKey: true,
      shiftKey: true,
      enabled: isShortcutEnabled,
    });

    useOnKeyPress(KEY_D, handleClone, {
      ctrlKey: true,
      enabled: isShortcutEnabled,
      useKeyCode: true,
    });

    useOnKeyPress(KEY_BACKSPACE, handleRemove, {
      ctrlKey: true,
      enabled: isShortcutEnabled,
    });

    return (
      <div
        ref={ref}
        id={section.id}
        className={classNames(
          className,
          'relative w-full',
          {
            'px-4 py-2 sm:px-1': !fullScreen && section.type !== CONTAINER,
            'h-screen overflow-hidden': fullScreen,
            'rounded-lg hover:ring-2 hover:ring-cyan-600 hover:ring-opacity-50':
              showHoverHighlight && section.type !== CONTAINER,
            'rounded-lg ring-2 ring-cyan-600 ring-opacity-100':
              showHighlight && section.type !== CONTAINER,
            'rounded-lg ring-2 ring-cyan-600 ring-opacity-50':
              isHighlighted && !isSelected && section.type !== CONTAINER,
            'rounded-lg hover:ring-2 hover:ring-pink-600 hover:ring-opacity-50':
              showHoverHighlight && section.type === CONTAINER,
            'rounded-lg ring-2 ring-pink-600 ring-opacity-100':
              showHighlight && section.type === CONTAINER,
            'rounded-lg ring-2 ring-pink-600 ring-opacity-50':
              isHighlighted && !isSelected && section.type === CONTAINER,
            ...(section.container
              ? {
                  'col-span-12':
                    section.sectionWidth === XL || !section.sectionWidth,
                  'col-span-6 md:col-span-12': section.sectionWidth === 6,
                  'col-span-3 md:col-span-12': section.sectionWidth === 3,
                  'col-span-4 md:col-span-12': section.sectionWidth === 4,
                  'col-span-8 md:col-span-12': section.sectionWidth === 8,
                  'col-span-9 md:col-span-12': section.sectionWidth === 9,
                }
              : {
                  'mx-auto w-full':
                    (!section.sectionWidth && !fullScreen) ||
                    section.sectionWidth === LG,
                  'mx-auto max-w-full': section.sectionWidth === XL,
                  'max-w-1/2 md:max-w-full': section.sectionWidth === 6,
                  'max-w-1/4 md:max-w-full': section.sectionWidth === 3,
                  'max-w-1/3 md:max-w-full': section.sectionWidth === 4,
                  'max-w-4/6 md:max-w-full': section.sectionWidth === 8,
                  'max-w-3/4 md:max-w-full': section.sectionWidth === 9,
                }),
            'z-30': editorMode && section.type !== CONTAINER,
            'p-2': editorMode && section.type === CONTAINER,
          },
          section && section.type
            ? `section-${section.type.toLowerCase()}`
            : '',
        )}
        data-testid="record-view-section"
        onClick={editorMode ? onSelectSection : undefined}
      >
        <div
          className={classNames('w-full', {
            'mx-auto max-w-6xl': !section.sectionWidth && !fullScreen,
            'mx-auto max-w-screen-2xl': section.sectionWidth === LG,
          })}
        >
          <ErrorBoundary
            fallback={
              editorMode && (
                <>
                  <div
                    className={classNames(
                      'flex items-center justify-center rounded-lg border p-6 text-sm shadow-md',
                      {
                        [`${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one}`]:
                          isDarkModeEnabled,
                        'border-gray-200 bg-white': !isDarkModeEnabled,
                      },
                    )}
                  >
                    <IconAlertTriangle className="flex-shrink-0" size={24} />
                    <div className="ml-4 flex flex-col py-16 text-left text-gray-500 dark:text-gray-400">
                      <h3>{getText('errors.section.title')}</h3>
                      <p className="text-xs text-gray-400 dark:text-gray-500">
                        {getText('errors.section.subtitle')}
                      </p>
                    </div>
                  </div>
                  {isSelected && !fullScreen && (
                    <div className="absolute left-0 top-0 -mt-4 ml-4 flex h-8 items-center space-x-1 rounded-lg bg-cyan-600 px-1 text-white">
                      <KeyboardShortcutTooltip
                        buildMode={false}
                        key={REMOVE}
                        keys={[CMD, KEY_BACKSPACE]}
                        label={getText(LANG_KEY, REMOVE, TOOLTIP)}
                        placement="top"
                      >
                        <button
                          className="p-1 opacity-75 hover:opacity-100"
                          onClick={handleRemove}
                        >
                          <IconTrash size={14} />
                        </button>
                      </KeyboardShortcutTooltip>
                    </div>
                  )}
                </>
              )
            }
          >
            {draggable && isOver && (
              <div className="my-2 h-1.5 w-full rounded-full bg-cyan-600" />
            )}
            <Section
              {...sectionProps}
              backLink={backLink}
              containerSections={containerSections}
              dataType={dataType}
              editorMode={editorMode}
              elementPath={elementPath}
              hasSelectedElement={hasSelectedElement}
              isEditingData={isEditingData}
              isExpanded={isExpanded}
              isRecordView={isRecordView}
              isSelected={isSelected}
              onError={onError}
              onLoadingChange={onLoadingChange}
              pageId={pageId}
              project={project}
              record={record}
              recordScope={recordScope}
              rootPathname={rootPathname}
              section={section}
              sectionId={section.id}
              sectionPath={path}
              sectionWidth={section.sectionWidth}
              selectedTab={selectedTab}
              tabs={tabs}
              toggleSectionExpandedState={toggleSectionExpandedState}
              visibleSections={visibleSections}
            >
              {section.type}
            </Section>
            {editorMode && !isSelected && (
              <div className="absolute inset-x-0 inset-y-0 overflow-hidden rounded-lg" />
            )}
            {editorMode && isSelected && !fullScreen && (
              <div
                className={classNames(
                  'absolute left-0 top-0 -mt-4 ml-4 flex h-8 items-center space-x-1 rounded-lg px-1 text-white',
                  {
                    'bg-cyan-600': section.type !== CONTAINER,
                    'bg-pink-600': section.type === CONTAINER,
                  },
                )}
              >
                <div ref={dragRef}>
                  <Tooltip
                    content={
                      <span
                        className={
                          isDarkModeEnabled ? darkModeColors.text.primary : ''
                        }
                      >
                        {getText(LANG_KEY, DRAG, TOOLTIP)}
                      </span>
                    }
                    key={DRAG}
                    placement="top"
                    showArrow={false}
                    surface={surface}
                  >
                    <div
                      className="cursor-move disabled:opacity-50"
                      draggable={true}
                    >
                      <IconGripVertical size={14} />
                    </div>
                  </Tooltip>
                </div>
                <KeyboardShortcutTooltip
                  buildMode={false}
                  key={MOVE_DOWN}
                  keys={[CMD, SHIFT, KEY_ARROW_DOWN]}
                  label={getText(LANG_KEY, MOVE_DOWN, TOOLTIP)}
                  placement="top"
                >
                  <button
                    className={classNames(
                      'p-1 opacity-75 disabled:opacity-50',
                      { 'hover:opacity-100': canMoveDown },
                    )}
                    disabled={
                      !canMoveDown ||
                      sectionIndexes.index > visibleSections.length - 1
                    }
                    onClick={handleMoveDown}
                  >
                    <IconArrowDown size={14} />
                  </button>
                </KeyboardShortcutTooltip>
                <KeyboardShortcutTooltip
                  buildMode={false}
                  key={MOVE_UP}
                  placement="top"
                  label={getText(LANG_KEY, MOVE_UP, TOOLTIP)}
                  keys={[CMD, SHIFT, KEY_ARROW_UP]}
                >
                  <button
                    className={classNames(
                      'p-1 opacity-75 disabled:opacity-50',
                      { 'hover:opacity-100': canMoveUp },
                    )}
                    disabled={!canMoveUp || sectionIndexes.index === 0}
                    onClick={handleMoveUp}
                  >
                    <IconArrowUp size={14} />
                  </button>
                </KeyboardShortcutTooltip>
                <Dropdown
                  // @ts-expect-error TS(7031): Binding element 'child' implicitly has an 'any' ty... Remove this comment to see the full error message
                  Button={({ children: child }) => child}
                  className="flex items-center justify-center opacity-75 hover:opacity-100"
                  onChange={onChangeWidth}
                  options={widthOptions}
                  size={MD}
                  value={section.sectionWidth}
                  stopPropagation={true}
                >
                  <Tooltip
                    content={
                      <span
                        className={
                          isDarkModeEnabled ? darkModeColors.text.primary : ''
                        }
                      >
                        {getText(LANG_KEY, WIDTH, TOOLTIP)}
                      </span>
                    }
                    key={WIDTH}
                    placement="top"
                    showArrow={false}
                    surface={surface}
                  >
                    <div>
                      <IconArrowAutofitWidth size={14} />
                    </div>
                  </Tooltip>
                </Dropdown>
                <KeyboardShortcutTooltip
                  buildMode={false}
                  key={CLONE}
                  keys={[CMD, KEY_D]}
                  label={getText(LANG_KEY, CLONE, TOOLTIP)}
                  placement="top"
                >
                  <button
                    className="p-1 opacity-75 hover:opacity-100 disabled:opacity-50"
                    onClick={handleClone}
                  >
                    <IconCopy size={14} />
                  </button>
                </KeyboardShortcutTooltip>
                <Dropdown
                  // @ts-expect-error TS(7031): Binding element 'child' implicitly has an 'any' ty... Remove this comment to see the full error message
                  Button={({ children: child }) => child}
                  className={classNames('flex items-center justify-center', {
                    'opacity-75 hover:opacity-100': useTabs,
                    'opacity-50': !useTabs,
                  })}
                  onChange={onChangeTab}
                  disabled={!useTabs}
                  options={tabOptions}
                  size={LG}
                  stopPropagation={true}
                >
                  <Tooltip
                    content={
                      <span
                        className={
                          isDarkModeEnabled ? darkModeColors.text.primary : ''
                        }
                      >
                        {getText(LANG_KEY, CHANGE_TABS, TOOLTIP)}
                      </span>
                    }
                    key={CHANGE_TABS}
                    placement="top"
                    showArrow={false}
                    surface={surface}
                  >
                    <div>
                      <IconFolders size={14} />
                    </div>
                  </Tooltip>
                </Dropdown>
                {section.type === CONTAINER && containerSections.length > 0 ? (
                  <Dropdown
                    // @ts-expect-error TS(7031): Binding element 'child' implicitly has an 'any' ty... Remove this comment to see the full error message
                    Button={({ children: child }) => child}
                    className="flex items-center justify-center opacity-75 hover:opacity-100"
                    onChange={handleRemoveContainerSection}
                    options={removeOptions}
                    size={MD}
                    stopPropagation={true}
                  >
                    <KeyboardShortcutTooltip
                      buildMode={false}
                      key={REMOVE}
                      keys={[CMD, KEY_BACKSPACE]}
                      label={getText(LANG_KEY, REMOVE, TOOLTIP)}
                      placement="top"
                    >
                      <div className="p-1">
                        <IconTrash size={14} />
                      </div>
                    </KeyboardShortcutTooltip>
                  </Dropdown>
                ) : (
                  <KeyboardShortcutTooltip
                    buildMode={false}
                    key={REMOVE}
                    keys={[CMD, KEY_BACKSPACE]}
                    label={getText(LANG_KEY, REMOVE, TOOLTIP)}
                    placement="top"
                  >
                    <button
                      className="p-1 opacity-75 hover:opacity-100"
                      onClick={handleRemove}
                    >
                      <IconTrash size={14} />
                    </button>
                  </KeyboardShortcutTooltip>
                )}
              </div>
            )}
          </ErrorBoundary>
        </div>
      </div>
    );
  },
);

export default WithDraggable(ViewSection, DRAGGABLE_SECTION);
