import {
  ALL,
  LOGGED_IN,
  NOT_LOGGED_IN,
} from '../constants/authVisibilityRules';
import {
  ALL as ALL_DEVICES,
  DESKTOP,
  MOBILE,
} from '../constants/deviceRuleVisibilityRules';
import { CLIENT, INTERNAL } from '../constants/internalUsersVisibilityRules';
import { WITHOUT_ROLES, WITH_ROLES } from '../constants/roleVisibilityRules';
import { Condition, VisibilityRules } from '../models/Element';
import { Project } from '../models/Project';
import { ensureArray } from './arrays';
import { conditionIsTrue } from './data';
import { isInternal } from './user';

export const shouldCheckVisibilityRules = (
  visibilityRules: VisibilityRules,
  checkSpace = false,
) => {
  const {
    auth,
    customRules,
    deviceRule,
    roleRule,
    spaces = [],
    type,
  } = visibilityRules;

  return !(
    (!auth || auth === ALL) &&
    (!roleRule || roleRule === ALL) &&
    (!deviceRule || deviceRule === ALL_DEVICES) &&
    (!type || type === ALL) &&
    (!customRules || customRules.length === 0) &&
    (!checkSpace || spaces.length === 0)
  );
};

interface ShouldRenderComponentProps {
  checkSpace?: boolean;
  currentSpaceId?: string | null;
  currentUser: any;
  customRulesEnabled?: boolean;
  isSmScreen?: boolean;
  project: Project;
  scope?: Record<string, any>;
  visibilityRules?: VisibilityRules;
}

export const shouldRenderComponent = ({
  checkSpace = false,
  currentSpaceId = null,
  currentUser,
  customRulesEnabled = false,
  isSmScreen = false,
  project,
  scope = {},
  visibilityRules = {},
}: ShouldRenderComponentProps) => {
  if (!currentUser || currentUser.loading) {
    return false;
  }

  const {
    auth,
    customRules = [],
    deviceRule = ALL_DEVICES,
    roleRule,
    roles = [],
    spaces = [],
    type,
  } = visibilityRules;

  if (auth && auth !== ALL) {
    if (auth === NOT_LOGGED_IN && currentUser.id) {
      return false;
    }

    if (auth === LOGGED_IN && !currentUser.id) {
      return false;
    }
  }

  if (type && type !== ALL) {
    if (type === INTERNAL && !isInternal(currentUser)) {
      return false;
    }

    if (type === CLIENT && isInternal(currentUser)) {
      return false;
    }
  }

  if (deviceRule && deviceRule !== ALL_DEVICES) {
    if (deviceRule === MOBILE && !isSmScreen) {
      return false;
    }

    if (deviceRule === DESKTOP && isSmScreen) {
      return false;
    }
  }

  if (roleRule && roleRule !== ALL) {
    if (!currentUser.id) {
      return false;
    }

    const userRoleId = currentUser.role && currentUser.role.referenceId;

    if (roles.length > 0) {
      const hasSpecifiedRole = roles.includes(userRoleId);

      if (roleRule === WITH_ROLES) {
        if (!userRoleId || !hasSpecifiedRole) {
          return false;
        }
      }

      if (roleRule === WITHOUT_ROLES) {
        if (userRoleId && hasSpecifiedRole) {
          return false;
        }
      }
    }
  }

  if (customRulesEnabled && customRules.length > 0) {
    const ruleConditionsAreMet = customRules.some(
      (andConditions: Condition[] = []) =>
        ensureArray(andConditions).every(
          (condition: Condition) =>
            conditionIsTrue(condition, scope, project).result,
        ),
    );

    if (!ruleConditionsAreMet) {
      return false;
    }
  }

  if (
    checkSpace &&
    spaces &&
    spaces.length > 0 &&
    currentSpaceId &&
    !spaces.includes(currentSpaceId)
  ) {
    return false;
  }

  // Should always fallback to true
  return true;
};
export const isElementVisible = (
  element: any,
  project: any,
  user: any,
  scope: any,
  editorMode = false,
  customRulesEnabled: any,
  isSmScreen = false,
) =>
  editorMode ||
  !element.visibilityRules ||
  !shouldCheckVisibilityRules(element.visibilityRules) ||
  shouldRenderComponent({
    currentUser: user,
    customRulesEnabled,
    project,
    scope,
    visibilityRules: element.visibilityRules,
    isSmScreen,
  });
