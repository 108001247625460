import { useCallback, useEffect } from 'react';
import get from 'lodash/get';
import { ID } from '../../models/Element';
import { getText } from '../lang';
import useDragAndDropReOrder from './useDragAndDropReOrder';

const ID_FIELD_PATH = 'id';
const getId = (entry: any) => get(entry, ID_FIELD_PATH);

const useItemListEditor = (
  items: any = [],
  updateProperty: any,
  debouncedUpdateProperty: any,
  createNewItem: any,
  propertyPath: string[] = [],
  alwaysEnsureEntry = true,
) => {
  const updateOrder = useCallback(
    (newOrder: any) => updateProperty(propertyPath, newOrder),
    [propertyPath, updateProperty],
  );

  const { formatItem, draftItems, findItem, onSaveOrder } =
    useDragAndDropReOrder(items, getId, updateOrder);

  const onAddNewItem = useCallback(() => {
    const newItem = createNewItem(items);
    updateProperty(propertyPath, [...items, newItem]);
  }, [createNewItem, items, updateProperty, propertyPath]);

  useEffect(() => {
    if (alwaysEnsureEntry && items.length === 0) {
      updateProperty(propertyPath, [createNewItem([])]);
    }
  }, [
    alwaysEnsureEntry,
    createNewItem,
    items.length,
    propertyPath,
    updateProperty,
  ]);

  const onUpdateItem = useCallback(
    (path: any, value: any) =>
      updateProperty([...propertyPath, ...path], value),
    [propertyPath, updateProperty],
  );

  const onDebounceUpdateItem = useCallback(
    (path: any, value: any) =>
      debouncedUpdateProperty([...propertyPath, ...path], value),
    [debouncedUpdateProperty, propertyPath],
  );

  const onRemoveItem = useCallback(
    (id: ID) =>
      updateProperty(
        propertyPath,
        items.filter((item: any) => item && item.id !== id),
      ),
    [items, propertyPath, updateProperty],
  );

  const onCloneItem = useCallback(
    (id: ID, newItemId: ID, tab = false) => {
      const itemToClone = items.find((item: any) => item.id === id);
      const item = {
        ...itemToClone,
        id: newItemId,
        ...(tab
          ? {
              title: `${itemToClone.title} ${getText('elements.VIEW.clone')}`,
            }
          : {}),
      };

      updateProperty(propertyPath, [...items, item]);
    },
    [items, propertyPath, updateProperty],
  );

  return {
    formatItem,
    draftItems,
    onSaveOrder,
    onAddNewItem,
    findItem,
    onDebounceUpdateItem,
    onUpdateItem,
    onRemoveItem,
    onCloneItem,
  };
};

export default useItemListEditor;
