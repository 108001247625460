import { useCallback, useMemo, useState } from 'react';
import {
  IconEdit,
  IconGitFork,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import get from 'lodash/get';
import { Popover, SelectInput, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import DataFieldIcon from '@noloco/ui/src/components/DataFieldIcon';
import ConditionValueEditor from '@noloco/ui/src/components/canvas/ConditionValueEditor';
import ContentDisplayName from '@noloco/ui/src/components/canvas/ContentDisplayName';
import ArrayFilterBranchInput from '@noloco/ui/src/components/editor/ArrayFilterBranchInput';
import { findFilterOption } from '@noloco/ui/src/components/editor/FilterInput';
import { OBJECT, SINGLE_OPTION } from '../../../constants/dataTypes';
import {
  EVERY,
  IN,
  NONE,
  NON_RESULT_OPERATORS,
  NOT_IN,
  SOME,
} from '../../../constants/operators';
import { DataType } from '../../../models/DataTypes';
import { ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { expandDataTypes, findFieldFromFilterName } from '../../../utils/data';
import { getText } from '../../../utils/lang';
import { getSubFieldsAsDataFields } from '../../../utils/objects';
import {
  getInputTypeForOperator,
  getOperatorsForFieldType,
} from '../../../utils/operator';
import { getOptionByName } from '../../../utils/options';
import { isMultiRelationship } from '../../../utils/relationships';
import { getDataTypeOptionsOfTypes } from '../../../utils/renderedOptions';
import { generateAdditionalFilter } from './BuildModeCustomFiltersEditor';

interface BuildModeAndFilterInputProps {
  additionalScopeItems: any;
  canAddFilter: boolean;
  canAddOrFilter?: boolean;
  dataType: DataType;
  elementPath: ElementPath;
  onAddAndFilter: () => void;
  onAddOrBlock?: () => void;
  onChange: (value: any) => void;
  onRemove: () => void;
  operatorValidationError: boolean;
  project: Project;
  value: any;
  ValueInput?: any;
}

const LANG_KEY = 'elements.LIST.filterInput';
const BuildModeAndFilterInput = ({
  additionalScopeItems = [],
  canAddFilter,
  canAddOrFilter = false,
  dataType,
  elementPath,
  onAddAndFilter,
  onAddOrBlock,
  onChange,
  onRemove,
  operatorValidationError,
  project,
  value,
  ValueInput,
}: BuildModeAndFilterInputProps) => {
  const [localValue, setLocalValue] = useState(value);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const handleValueChange = useCallback(
    (property: any, newValue: any) => {
      setLocalValue((currentLocalValue: any) => {
        const nextValue = {
          ...currentLocalValue,
          [property]: newValue,
        };
        onChange(nextValue);

        return nextValue;
      });
    },
    [onChange],
  );
  const handleOperatorChange = useCallback(
    (value: any) => handleValueChange('operator', value),
    [handleValueChange],
  );

  const fieldOptions = useMemo(
    () =>
      dataType.fields.reduce((fieldOptionsAcc: any, field: any) => {
        if (field.hidden) {
          return fieldOptionsAcc;
        }

        if (
          !field.relationship &&
          !field.relatedField &&
          field.type !== OBJECT
        ) {
          return [
            ...fieldOptionsAcc,
            {
              icon: <DataFieldIcon field={field} size={14} />,
              label: field.display,
              value: field.name,
              field,
            },
          ];
        }

        if (field.relationship) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: `${field.name}Id`,
              field,
            },
          ];
        }

        if (field.relatedField) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: `${field.relatedField.reverseName}Id`,
              field,
            },
          ];
        }

        if (field.type === OBJECT) {
          return [
            ...fieldOptionsAcc,
            {
              label: field.display,
              icon: <DataFieldIcon field={field} size={14} />,
              value: field.name,
              options: getSubFieldsAsDataFields(field).map((subField) => ({
                label: subField.display,
                icon: <DataFieldIcon field={subField} size={14} />,
                value: `${field.name}.${subField.name}`,
                field: subField,
              })),
            },
          ];
        }

        return fieldOptionsAcc;
      }, []),
    [dataType.fields],
  );

  const getFieldFromOptions = useCallback(
    (fieldValue: any) =>
      fieldValue && findFilterOption(fieldValue.split('.'), fieldOptions),
    [fieldOptions],
  );

  const onFieldChange = useCallback(
    (newField: any) => {
      const newFieldOption = getFieldFromOptions(newField);
      const newOperators = getOperatorsForFieldType(newFieldOption.field.type);

      const nextValue = {
        ...localValue,
        field: newField,
        operator: newFieldOption.field.multiple ? SOME : newOperators[0],
        filters: newFieldOption.field.multiple
          ? [
              generateAdditionalFilter({
                field: newField,
                operator: newOperators[0],
              }),
            ]
          : undefined,
      };
      setLocalValue(nextValue);
      onChange(nextValue);
    },
    [getFieldFromOptions, localValue, onChange],
  );

  const selectedField = useMemo(
    () => localValue.field && getFieldFromOptions(localValue.field),
    [getFieldFromOptions, localValue.field],
  );

  const operators = useMemo(() => {
    if (!selectedField) {
      return [];
    }

    if (selectedField.field.multiple) {
      return [SOME, EVERY, NONE];
    }

    return getOperatorsForFieldType(
      selectedField.field.type,
      selectedField.field.relationship,
    );
  }, [selectedField]);

  const operatorOptions = useMemo(
    () =>
      operators.map((operator) => ({
        label: getText('operators', operator, 'label.default'),
        value: operator,
      })),
    [operators],
  );

  const fieldValue = useMemo(() => {
    if (!localValue || !localValue.field) {
      return null;
    }

    return findFieldFromFilterName(localValue.field, dataType);
  }, [localValue, dataType]);

  const isArrayField = useMemo(
    () => get(selectedField, 'field.multiple', null),
    [selectedField],
  );

  const shouldRenderValueInput = useMemo(
    () => !isArrayField && !!getInputTypeForOperator(localValue.operator),
    [isArrayField, localValue],
  );

  const isFilterValid = useMemo(
    () =>
      localValue &&
      localValue.field &&
      localValue.operator &&
      (NON_RESULT_OPERATORS.includes(localValue.operator) || localValue.result),
    [localValue],
  );

  const updatedAcceptableDataTypes = useMemo(() => {
    if (!fieldValue) {
      return [];
    }

    if (fieldValue.relationship || fieldValue.relatedField) {
      return [fieldValue.type];
    }

    return expandDataTypes(fieldValue?.type);
  }, [fieldValue]);

  const includeUniqueColumnar = useMemo(
    () =>
      selectedField &&
      (!!selectedField.field.relationship ||
        !!selectedField.field.relatedField ||
        getInputTypeForOperator(localValue.operator) === 'array'),
    [selectedField, localValue],
  );

  const dataOptions = useMemo(
    () => [
      ...additionalScopeItems,
      ...getDataTypeOptionsOfTypes(
        project,
        elementPath,
        updatedAcceptableDataTypes,
        { includeUniqueColumnar },
      ),
    ],
    [
      additionalScopeItems,
      elementPath,
      includeUniqueColumnar,
      project,
      updatedAcceptableDataTypes,
    ],
  );

  const summary = useMemo(() => {
    if (isFilterValid) {
      const fieldDisplayName = findFieldFromFilterName(
        localValue.field,
        dataType,
      )?.display;

      const operator = getText(
        'operators',
        localValue.operator,
        'label.default',
      ).toLowerCase();

      const result = localValue.result || [];
      const value = result.map((value: any, index: number) => {
        if ('text' in value) {
          return (
            <span className="text-slate-200" key={index}>
              {value.text}
            </span>
          );
        }

        const path = value.data.path;
        const id = value.data.id;

        if (fieldValue) {
          if (value.static && value.data && id === SINGLE_OPTION) {
            return getOptionByName(path.split('.')[2], fieldValue)?.display;
          }

          return (
            <ContentDisplayName
              data={value.data}
              dataOptions={dataOptions}
              parentPath={elementPath}
            />
          );
        }

        if (!NON_RESULT_OPERATORS.includes(localValue.operator)) {
          if (id === 'values') {
            return (
              <span className="mr-1 font-bold text-cyan-400" key={index}>
                {path === 'OTHER.empty'
                  ? getText('contentEditor.values.OTHER.empty.label')
                  : getText('contentEditor.values', path)}
              </span>
            );
          }

          return (
            <span className="mr-1 font-bold text-cyan-400" key={index}>
              {value.data?.display}
            </span>
          );
        }

        return null;
      });

      return (
        <div className="w-full text-balance">
          <span className="mr-1 font-bold text-cyan-400">
            {fieldDisplayName}
          </span>
          <span className="mr-1 text-slate-400">{operator}</span>
          <span>{value}</span>
        </div>
      );
    }
  }, [
    dataOptions,
    dataType,
    elementPath,
    fieldValue,
    isFilterValid,
    localValue,
  ]);

  return (
    <div className="group relative flex w-full flex-col items-center rounded-lg">
      <div className="group flex w-full items-center">
        <div className="absolute -top-2 right-2 z-20 -mt-1 hidden items-center justify-center rounded-lg bg-slate-600 shadow-2xl group-hover:flex">
          {canAddFilter && isFilterValid && (
            <Tooltip
              delayShow={250}
              placement="top"
              content={
                <span className="font-mono font-medium text-slate-200">
                  {getText(LANG_KEY, 'addCondition')}
                </span>
              }
              surface={DARK}
            >
              <div
                className="cursor-pointer rounded-lg p-2 hover:bg-slate-500"
                onClick={onAddAndFilter}
              >
                <IconPlus size={12} />
              </div>
            </Tooltip>
          )}
          {canAddFilter && canAddOrFilter && isFilterValid && (
            <Tooltip
              delayShow={250}
              placement="top"
              content={
                <span className="font-mono font-medium text-slate-200">
                  {getText(LANG_KEY, 'addConditionGroup')}
                </span>
              }
              surface={DARK}
            >
              <div
                className="cursor-pointer rounded-lg p-2 hover:bg-slate-500"
                onClick={onAddOrBlock}
              >
                <IconGitFork size={12} />
              </div>
            </Tooltip>
          )}
          <div
            className="cursor-pointer rounded-lg p-2 hover:bg-slate-500"
            onClick={() => setIsEditorOpen(true)}
          >
            <IconEdit size={12} />
          </div>
          <div
            className="cursor-pointer rounded-lg p-2 hover:bg-slate-500 hover:text-red-300"
            onClick={onRemove}
          >
            <IconTrash size={12} />
          </div>
        </div>
        <Popover
          bg="slate-900"
          border={[true, 'slate-600']}
          closeOnOutsideClick={true}
          onOpenChange={setIsEditorOpen}
          isOpen={isEditorOpen}
          trigger="none"
          content={
            <div className="max-w-lg">
              <div className="flex w-full items-center space-x-2">
                <SelectInput
                  className="w-full"
                  contained={true}
                  onChange={onFieldChange}
                  options={fieldOptions}
                  placeholder={getText(LANG_KEY, 'field.placeholder')}
                  shiftRight={true}
                  searchable={true}
                  value={localValue.field}
                />
                <SelectInput
                  className="w-full"
                  contained={true}
                  disabled={operatorOptions.length === 0}
                  onChange={handleOperatorChange}
                  options={operatorOptions}
                  placeholder={getText(LANG_KEY, 'operator.placeholder')}
                  showValidationErrorText={false}
                  validationError={operatorValidationError}
                  value={localValue.operator}
                />
              </div>
              {shouldRenderValueInput && (
                <div className="mt-2 flex w-full max-w-full flex-grow items-center space-x-2">
                  <div className="flex w-full items-center text-sm text-slate-400">
                    <ValueInput
                      additionalScopeItems={additionalScopeItems}
                      contained={true}
                      dataType={dataType}
                      field={fieldValue}
                      project={project}
                      onChange={(newResult: any) =>
                        handleValueChange('result', newResult)
                      }
                      multiple={
                        [IN, NOT_IN].includes(localValue.operator) &&
                        (!selectedField ||
                          !selectedField.field.relationship ||
                          !isMultiRelationship(
                            // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
                            !selectedField.field.relationship,
                          ))
                      }
                      elementPath={elementPath}
                      includeUniqueColumnar={includeUniqueColumnar}
                      placeholder={getText(LANG_KEY, 'result.placeholder')}
                      value={localValue.result || []}
                    />
                  </div>
                </div>
              )}
              {isArrayField && (
                <div className="ml-4 flex max-w-full flex-grow items-center">
                  <ArrayFilterBranchInput
                    additionalScopeItems={additionalScopeItems}
                    dataType={dataType}
                    elementPath={elementPath}
                    onChange={(newFilter: any) =>
                      handleValueChange('filters', newFilter)
                    }
                    onEmpty={onRemove}
                    project={project}
                    filters={localValue.filters ?? []}
                    ValueInput={ValueInput}
                    selectedField={selectedField}
                  />
                </div>
              )}
            </div>
          }
          placement="left"
          showArrow={true}
        >
          <div className="flex min-h-8 w-full items-center rounded-lg bg-slate-700 p-2 font-mono">
            {summary ?? (
              <span className="text-gray-400">
                {getText(LANG_KEY, 'addAFilter')}
              </span>
            )}
          </div>
        </Popover>
      </div>
    </div>
  );
};

BuildModeAndFilterInput.defaultProps = {
  additionalScopeItems: [],
  disabledFields: [],
  ValueInput: ConditionValueEditor,
};

export default BuildModeAndFilterInput;
