export const ALT: ShortcutKeyType = 'Alt';
export const CMD: ShortcutKeyType = 'cmd';
export const COMMA: ShortcutKeyType = ',';
export const ENTER: ShortcutKeyType = 'Enter';
export const ESCAPE: ShortcutKeyType = 'Escape';
export const KEY_2: ShortcutKeyType = '2';
export const KEY_ARROW_DOWN: ShortcutKeyType = 'ArrowDown';
export const KEY_ARROW_LEFT: ShortcutKeyType = 'ArrowLeft';
export const KEY_ARROW_RIGHT: ShortcutKeyType = 'ArrowRight';
export const KEY_ARROW_UP: ShortcutKeyType = 'ArrowUp';
export const KEY_BACK_SLASH: ShortcutKeyType = '\\';
export const KEY_BACKSPACE: ShortcutKeyType = 'Backspace';
export const KEY_D: ShortcutKeyType = 'KeyD';
export const KEY_DIGIT_1: ShortcutKeyType = 'Digit1';
export const KEY_DIGIT_2: ShortcutKeyType = 'Digit2';
export const KEY_DIGIT_3: ShortcutKeyType = 'Digit3';
export const KEY_DIGIT_4: ShortcutKeyType = 'Digit4';
export const KEY_DIGIT_5: ShortcutKeyType = 'Digit5';
export const KEY_DIGIT_6: ShortcutKeyType = 'Digit6';
export const KEY_DIGIT_7: ShortcutKeyType = 'Digit7';
export const KEY_DIGIT_8: ShortcutKeyType = 'Digit8';
export const KEY_DIGIT_9: ShortcutKeyType = 'Digit9';
export const KEY_E: ShortcutKeyType = 'e';
export const KEY_G: ShortcutKeyType = 'g';
export const KEY_H: ShortcutKeyType = 'KeyH';
export const KEY_K: ShortcutKeyType = 'k';
export const KEY_M: ShortcutKeyType = 'm';
export const KEY_N: ShortcutKeyType = 'KeyN';
export const KEY_P: ShortcutKeyType = 'KeyP';
export const KEY_QUESTION_MARK: ShortcutKeyType = '?';
export const KEY_S: ShortcutKeyType = 'KeyS';
export const KEY_SLASH: ShortcutKeyType = '/';
export const KEY_SQUARE_BRACKETS_CLOSE: ShortcutKeyType = ']';
export const KEY_T: ShortcutKeyType = 't';
export const KEY_U: ShortcutKeyType = 'u';
export const KEY_W: ShortcutKeyType = 'w';
export const KEY_X: ShortcutKeyType = 'x';
export const KEY_Z: ShortcutKeyType = 'z';
export const PERIOD: ShortcutKeyType = '.';
export const SHIFT: ShortcutKeyType = 'shift';

export type ShortcutKeyType =
  | ','
  | '?'
  | '.'
  | ']'
  | '/'
  | '\\'
  | '2'
  | 'Alt'
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ArrowUp'
  | 'Backspace'
  | 'cmd'
  | 'Digit1'
  | 'Digit2'
  | 'Digit3'
  | 'Digit4'
  | 'Digit5'
  | 'Digit6'
  | 'Digit7'
  | 'Digit8'
  | 'Digit9'
  | 'e'
  | 'Enter'
  | 'Escape'
  | 'g'
  | 'k'
  | 'KeyD'
  | 'KeyH'
  | 'KeyN'
  | 'KeyP'
  | 'KeyS'
  | 'm'
  | 'shift'
  | 't'
  | 'u'
  | 'w'
  | 'x'
  | 'z';
