import { useCallback, useEffect, useMemo } from 'react';
import {
  IconFileTypography,
  IconFiles,
  IconHeadphonesFilled,
  IconPhoto,
  IconVideo,
  TablerIconsProps,
} from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { SwitchButton } from '@noloco/components/src';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import fileTypes, {
  AUDIO,
  DOCUMENT,
  IMAGE,
  TEXT,
  VIDEO,
} from '@noloco/core/src/constants/fileTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { getText } from '@noloco/core/src/utils/lang';

interface FileTypeOptionsEditorProps {
  className?: string;
  value: any;
  onChange: (path: ElementPath, value: any) => void;
}

const fileTypeIcons: Record<string, (_props: TablerIconsProps) => JSX.Element> =
  {
    [AUDIO]: IconHeadphonesFilled,
    [IMAGE]: IconPhoto,
    [VIDEO]: IconVideo,
    [TEXT]: IconFileTypography,
    [DOCUMENT]: IconFiles,
  };

const FileTypeOptionsEditor = ({
  className,
  value,
  onChange,
}: FileTypeOptionsEditorProps) => {
  const LANG_KEY = 'elements.VIEW';
  const handleChange = useCallback(
    (value: any, option: any) => onChange([option], value),
    [onChange],
  );

  const options = useMemo(
    () =>
      fileTypes.map((option) => ({
        value: get(value, option, true),
        label: getText(LANG_KEY, 'fileType.fileTypeButtons', option),
        path: option,
        Icon: fileTypeIcons[option],
      })),
    [value],
  );

  useEffect(() => {
    const disabledFileTypesCount = fileTypes.reduce(
      (count, fileType) =>
        get(value, [fileType], true) === false ? count + 1 : count,
      0,
    );

    if (disabledFileTypesCount === fileTypes.length) {
      fileTypes.forEach((option) => handleChange(true, option));
    }
  }, [value, handleChange]);

  return (
    <div
      className={classNames(
        className,
        'mb-2 flex max-w-full flex-col space-y-2 text-white',
      )}
    >
      <SwitchButton
        onChange={(value, path) => handleChange(value, path)}
        options={options}
        value={value}
        multiple={true}
        size={XS}
      />
    </div>
  );
};

export default FileTypeOptionsEditor;
