import React, { ReactNode, createContext, useContext } from 'react';
import { ProjectSettings } from '../../models/Project';
import useBoolLocalStorageState, {
  LocalStorageBoolean,
} from './useBoolLocalStorageState';

const darkModeContext = createContext<LocalStorageBoolean>([
  false,
  () => {
    // Empty setter, will be replaced by local storage hook
  },
  () => {
    // Empty clear, will be replaced by local storage hook
  },
]);

export const useIsDarkModeDefault = (projectSettings: ProjectSettings) => {
  const mode = projectSettings.theme?.defaultThemeMode ?? 'light';

  if (mode === 'auto') {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  return mode === 'dark';
};

const useDarkModeContext = (
  projectSettings: ProjectSettings,
): LocalStorageBoolean => {
  const defaultValue = useIsDarkModeDefault(projectSettings);

  return useBoolLocalStorageState('darkMode.enabled', defaultValue);
};

export const DarkModeProvider = ({
  children,
  settings,
}: {
  children: ReactNode;
  settings: ProjectSettings;
}) => {
  const value = useDarkModeContext(settings);

  return (
    <darkModeContext.Provider value={value}>
      {children}
    </darkModeContext.Provider>
  );
};

const useDarkMode = () => useContext(darkModeContext);

export default useDarkMode;
