import React, { useMemo } from 'react';
import classNames from 'classnames';
import { DARK } from '../../constants/surface';
import { SM, ShirtSize } from '../../constants/tShirtSizes';
import { Surface } from '../../models';
import Tooltip from '../popover/Tooltip';

export type OptionValue = string | number | boolean | undefined;

type OnChangeFunction = (value: OptionValue, path?: string[]) => void;

interface SwitchButtonProps {
  className?: string;
  inverseColors?: boolean;
  surface?: Surface;
  onChange: OnChangeFunction;
  options: {
    disabled?: boolean;
    Icon?: any;
    label?: string;
    path?: string;
    showTooltip?: boolean;
    value: OptionValue;
  }[];
  size?: ShirtSize;
  fullWidth?: boolean;
  value: OptionValue;
  multiple?: boolean;
}

const SwitchButton = ({
  className,
  inverseColors = false,
  surface = DARK,
  fullWidth = true,
  onChange,
  options,
  size = SM,
  value,
  multiple = false,
}: SwitchButtonProps) => {
  const cols = useMemo(() => {
    if (options.length <= 5) {
      return options.length;
    }

    if (options.length % 3) {
      return 4;
    }

    return 3;
  }, [options]);

  return (
    <div
      className={classNames(
        className,
        'flex grid items-center justify-center gap-1 rounded p-1',
        `text-${size} grid-cols-${cols}`,
        surface === DARK
          ? {
              'text-gray-300': true,
              'bg-slate-900': !inverseColors,
              'bg-slate-700': inverseColors,
              'h-14': multiple,
              'w-full': fullWidth,
            }
          : {
              'border border-gray-200 bg-white text-gray-500': true,
            },
      )}
    >
      {options.map(
        (
          {
            disabled = false,
            Icon,
            label,
            path,
            showTooltip = false,
            value: optionValue,
          },
          index,
        ) => (
          <Tooltip
            content={<span className="text-slate-200">{label}</span>}
            delayShow={500}
            disabled={!showTooltip || !label}
            key={`${optionValue}-${index}`}
            offset={[0, 8]}
            showArrow={false}
            surface={DARK}
          >
            <div
              key={`${optionValue?.toString()}${index}`}
              onClick={
                !disabled
                  ? multiple
                    ? () => onChange(!optionValue, [path ?? ''])
                    : () => onChange(optionValue)
                  : undefined
              }
              className={classNames(
                'flex flex-col items-center justify-center rounded px-2',
                surface === DARK
                  ? {
                      'hover:bg-slate-800': !disabled,
                      'bg-slate-600': multiple
                        ? optionValue !== false && !inverseColors
                        : value === optionValue && !inverseColors,
                      'bg-slate-900': multiple
                        ? optionValue !== false && inverseColors
                        : value === optionValue && inverseColors,
                      'hover:bg-gray-200':
                        value !== optionValue && inverseColors && !disabled,
                    }
                  : {
                      'bg-gray-500 text-white hover:bg-gray-600':
                        value === optionValue,
                      'hover:bg-gray-200': value !== optionValue,
                    },
                {
                  'py-1': multiple,
                  'cursor-pointer': !disabled,
                  'cursor-not-allowed opacity-75': disabled,
                },
              )}
            >
              {Icon && <Icon size={20} />}
              {!showTooltip && label}
            </div>
          </Tooltip>
        ),
      )}
    </div>
  );
};

export default SwitchButton;
