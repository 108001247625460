import React, { useCallback, useMemo, useState } from 'react';
import { IconCheck, IconSend } from '@tabler/icons-react';
import { IconConfetti } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Tooltip } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { XS } from '@noloco/components/src/constants/tShirtSizes';
import { CREATE } from '@noloco/core/src/constants/actionTypes';
import { USER } from '@noloco/core/src/constants/builtInDataTypes';
import { TEAM_ADMIN_ROLE } from '@noloco/core/src/constants/defaultRoleReferences';
import AutoForm from '@noloco/core/src/elements/sections/forms/AutoForm';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { BaseRecord } from '@noloco/core/src/models/Record';
import { Role, User } from '@noloco/core/src/models/User';
import { clearFieldValues } from '@noloco/core/src/reducers/formFields';
import { addProjectUsers } from '@noloco/core/src/reducers/project';
import {
  dataTypesSelector,
  projectDataSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import {
  USER_INVITED_POST_PUBLISH,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { AutoFormProvider } from '@noloco/core/src/utils/hooks/useAutoForm';
import useSubmitModalForm from '@noloco/core/src/utils/hooks/useSubmitNestedForm';
import { getText } from '@noloco/core/src/utils/lang';
import useFormFieldsState from '@noloco/core/src/utils/useFormFieldsState';
import { useUserRoles } from '../../utils/hooks/useUserRoles';
import DataCell from '../dataTable/DataCell';
import UserRoleSummary from '../usersTable/UserRoleSummary';

const InviteUserSection = ({
  loading,
  setIsLoading,
}: {
  loading: boolean;
  setIsLoading: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const dataTypes = useSelector(dataTypesSelector);
  const [inviteSent, setInviteSent] = useState(false);
  const userType = useMemo(
    () => dataTypes.getByName(USER) as DataType,
    [dataTypes],
  );
  const fieldNames = useMemo(() => ['email', 'role'], []);
  const handleInviteSuccess = useCallback(
    (newUser: User) => {
      dispatch(
        clearFieldValues({
          dataTypeName: userType.name,
          id: 'NEW',
          fieldNames: fieldNames,
          updateTime: Date.now(),
        }),
      );

      setInviteSent(true);
      setTimeout(() => setInviteSent(false), 1500);
      trackEvent(USER_INVITED_POST_PUBLISH);

      if (newUser?.role?.builder) {
        dispatch(
          addProjectUsers([
            {
              createdAt: new Date().toISOString(),
              user: {
                email: newUser.email,
                id: newUser.id,
              },
            },
          ]),
        );
      }
    },
    [dispatch, userType, fieldNames],
  ) as (user: BaseRecord) => void;

  const errorAlert = useGraphQlErrorAlert();
  const project = useSelector(projectDataSelector);

  const { roles } = useUserRoles(project.name, project.dataTypes, {
    dataAdmin: true,
    builder: true,
    internal: true,
  });
  const [userValues] = useFormFieldsState(USER, undefined, false);

  const roleId = useMemo(() => get(userValues, 'role.id'), [userValues]);

  const defaultAdminRole = useMemo(() => {
    if (roleId) {
      return undefined;
    }

    return roles.find((r: Role) => r.referenceId === TEAM_ADMIN_ROLE);
  }, [roleId, roles]);

  const role = useMemo(
    () =>
      (roleId && roles.find((r: Role) => r.id === roleId)) ?? defaultAdminRole,
    [defaultAdminRole, roleId, roles],
  );

  const onError = useCallback(
    (error: Error) => {
      errorAlert(getText('userTable.form', 'error'), error);
    },
    [errorAlert],
  );

  const formFields = useMemo(
    () =>
      fieldNames.map((fieldName) => {
        const field = userType.fields.getByName(fieldName)!;

        return {
          field,
          config: {
            label: upperFirst(field.display),
            name: field.name,
            placeholder: getText(
              'toolbar.publish.publishMessage.placeholders',
              fieldName,
            ),
            defaultValue:
              fieldName === 'role' ? defaultAdminRole?.id : undefined,
          },
        };
      }),
    [fieldNames, userType.fields, defaultAdminRole?.id],
  );
  const formClassName = `edit-user-new-form`;

  const { submitModalForm: onConfirm } = useSubmitModalForm(
    `.${formClassName}`,
  );

  return (
    <>
      <div className="mt-8 flex items-center space-x-4">
        <IconConfetti size={18} />
        <p className="ml-2 text-sm font-bold">
          {getText('toolbar.publish.success')}
        </p>
      </div>
      <p className="mt-4 text-sm">
        {getText('toolbar.publish.publishMessage.invite')}
      </p>
      <div className="flex flex-col">
        <AutoFormProvider
          authQuery={true}
          fields={formFields}
          dataType={userType}
          inline={false}
          value={null}
          inviteEnabled={true}
          mutationType={CREATE}
          onLoadingChange={setIsLoading}
          onSuccess={handleInviteSuccess}
          submitOnBlur={false}
          onError={onError}
          readOnly={false}
          surface={LIGHT}
          ReadOnlyCell={DataCell}
        >
          <AutoForm
            className={classNames(
              'grid w-full grid-flow-row-dense grid-cols-[1fr_minmax(0px,_36px)] grid-rows-[auto] [&>*:nth-child(1)]:col-span-2',
              formClassName,
            )}
          >
            {() => (
              <Tooltip
                content={
                  <div className="flex items-center space-x-2">
                    <IconCheck className="flex-shrink-0 text-green-500" />
                    <span>
                      {getText('toolbar.publish.publishMessage.inviteSent')}
                    </span>
                  </div>
                }
                isOpen={inviteSent}
                trigger="none"
              >
                <button
                  className="ml-1 mt-2 flex h-8 w-9 border-spacing-1 items-center justify-center rounded-lg border-2 border-gray-200 align-middle hover:bg-slate-50"
                  disabled={loading}
                  onClick={() => onConfirm()}
                >
                  {loading ? (
                    <Loader size={XS} />
                  ) : (
                    <IconSend size={16} className="opacity-75" />
                  )}
                </button>
              </Tooltip>
            )}
          </AutoForm>
        </AutoFormProvider>
      </div>
      {role && <UserRoleSummary className="text-xs" role={role} firstName="" />}
    </>
  );
};

export default InviteUserSection;
