import { useMemo } from 'react';
import { IconSettings } from '@tabler/icons-react';
import get from 'lodash/get';
import { Popover } from '@noloco/components';
import MarkdownHelp from '@noloco/core/src/components/editor/MarkdownHelp';
import {
  MULTIPLE_OPTION,
  SINGLE_OPTION,
} from '@noloco/core/src/constants/dataTypes';
import {
  CHECKBOX,
  InputType,
  RADIO,
} from '@noloco/core/src/constants/inputTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import StringPropEditor from '../../canvas/StringPropEditor';
import FieldConditionsEditor from './FieldConditionsEditor';
import FieldOptionsEditor from './FieldOptionsEditor';
import {
  getOptionsSetForConditionFieldOptions,
  nullScopeOptions,
} from './FormFieldsEditor';

const FORMS_LANG_KEY = 'elements.FORMS';

interface OptionFieldEditorProps {
  dataType: DataType;
  elementPath: ElementPath;
  field: DataField;
  inputType?: InputType;
  onChange: (path: ElementPath, value: any) => void;
  project: Project;
  recordScopeOptions: any;
  values: any;
}

const OptionFieldEditor = ({
  dataType,
  elementPath,
  field,
  inputType,
  onChange,
  project,
  recordScopeOptions,
  values,
}: OptionFieldEditorProps) => {
  const fieldOptions = useMemo(
    () => ({
      label: dataType.display,
      options: recordScopeOptions,
    }),
    [dataType, recordScopeOptions],
  );

  return (
    <FieldOptionsEditor
      className="my-8"
      field={field}
      onChange={onChange}
      values={values}
    >
      {({ onChange: onChangeOption, value }: any) => (
        <Popover
          placement="right"
          closeOnOutsideClick={true}
          bg="brand-darkest"
          border={[true, 'brand-light']}
          showArrow={false}
          content={
            <div className="flex max-w-lg flex-col space-y-1 text-sm text-white">
              {((field.type === MULTIPLE_OPTION && inputType === CHECKBOX) ||
                (field.type === SINGLE_OPTION && inputType === RADIO)) && (
                <div className="mb-6 flex flex-col">
                  <div className="mb-1 mt-2 items-center space-y-2">
                    <label className="w-full">
                      {getText(FORMS_LANG_KEY, 'helpText.label')}
                    </label>
                    <MarkdownHelp />
                  </div>
                  <StringPropEditor // @ts-expect-error TS(2322): Type '{ additionalScopeItems: { label: string; opt... Remove this comment to see the full error message
                    additionalScopeItems={[
                      {
                        label: getText(
                          FORMS_LANG_KEY,
                          'helpText.formData.label',
                        ),
                        help: getText(FORMS_LANG_KEY, 'helpText.formData.help'),
                        options: recordScopeOptions,
                      },
                    ]}
                    contained={true}
                    elementPath={elementPath}
                    multiLine={true}
                    onChange={(val: any) => {
                      onChangeOption(['helpText'], val);
                    }}
                    placeholder={getText(
                      FORMS_LANG_KEY,
                      'helpText.placeholder.generic',
                    )}
                    project={project}
                    value={value?.helpText || ''}
                  />
                </div>
              )}
              <FieldConditionsEditor
                conditionFieldOptions={[
                  ...nullScopeOptions,
                  ...getOptionsSetForConditionFieldOptions(
                    project,
                    elementPath,
                    fieldOptions,
                  ),
                ]}
                dataType={dataType}
                elementPath={elementPath}
                onChange={(path: any, value: any) =>
                  onChangeOption(['conditions', ...path], value)
                }
                project={project}
                value={get(value, 'conditions')}
              />
            </div>
          }
        >
          <button className="mr-2 hover:text-cyan-500">
            <IconSettings size={16} />
          </button>
        </Popover>
      )}
    </FieldOptionsEditor>
  );
};
export default OptionFieldEditor;
