import { useCallback, useMemo, useState } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import { IconChevronsLeft, IconPlus, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';
import get from 'lodash/get';
import initial from 'lodash/initial';
import shortId from 'shortid';
import {
  BasePopover,
  HelpTooltip,
  Switch,
  TextInput,
} from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { getGroupedDataTypeOptions } from '@noloco/ui/src/utils/dataTypes';
import {
  useAddChild,
  useUpdateProject,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { getUniqueRoutePath } from '@noloco/ui/src/utils/layout';
import { getSidebarItemProps } from '@noloco/ui/src/utils/sidebar';
import {
  ADVANCED_OPTIONS,
  COLLECTIONS_OPTIONS,
  MODULES_OPTIONS,
} from '../../../constants/buildMode';
import { darkModeColors } from '../../../constants/darkModeColors';
import { INTERNAL } from '../../../constants/dataSources';
import {
  BILLING,
  DIVIDER,
  FILE_SHARING,
  FOLDER,
  IFRAME,
  LINK,
  MESSAGING,
  ONBOARDING_TASKS,
  PAGE,
} from '../../../constants/elements';
import { ALT, KEY_N } from '../../../constants/shortcuts';
import compoundElements from '../../../elements/compoundElements';
import elementsConfig from '../../../elements/elementConfig';
import KeyboardShortcutTooltip from '../../../elements/sections/view/KeyboardShortcutTooltip';
import { Element } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { NEW_PAGE_SAVED, trackEvent } from '../../../utils/analytics';
import useAddPage from '../../../utils/hooks/useAddPage';
import { useAddView } from '../../../utils/hooks/useAddView';
import useFuzzySearch from '../../../utils/hooks/useFuzzySearch';
import useHasFeatureFlag, {
  LEGACY_UI_MODULES,
} from '../../../utils/hooks/useHasFeatureFlag';
import useOnKeyPress from '../../../utils/hooks/useOnKeyPress';
import useRouter from '../../../utils/hooks/useRouter';
import { useShowUpdatingAlert } from '../../../utils/hooks/useShowUpdatingAlert';
import { getText } from '../../../utils/lang';
import { Page, getPagesConfig } from '../../../utils/pages';
import BuildModeIcon from '../BuildModeIcon';
import BuildModeSection from '../BuildModeSection';
import NewDraggableItem from './NewDraggableItem';

interface BuildModeSidebarItemOptionsProps {
  children?: JSX.Element;
  modules?: boolean;
  newPageIndex?: number;
  offset?: number[];
  parentPage?: Page;
  project: Project;
  openByShortcut?: boolean;
  sidebarExpanded?: boolean;
}

const LANG_KEY = 'leftSidebar.pages.newPageOptions';
const MODULES = [BILLING, FILE_SHARING, MESSAGING, ONBOARDING_TASKS];
const moduleIconNames = {
  [BILLING]: 'CreditCard',
  [FILE_SHARING]: 'Files',
  [MESSAGING]: 'Messages',
  [ONBOARDING_TASKS]: 'ListCheck',
};

const BuildModeSidebarItemOptions = ({
  children,
  modules = true,
  newPageIndex,
  offset = [],
  parentPage,
  project,
  openByShortcut = false,
  sidebarExpanded = true,
}: BuildModeSidebarItemOptionsProps) => {
  const [updateProject] = useUpdateProject(project);
  const qsSuffix = useMemo(
    () => (parentPage ? parentPage.id : ''),
    [parentPage],
  );
  const { push, query: restParams, replaceQueryParams } = useRouter();
  const [filter] = useState('');
  const [newViewOpen, setNewViewOpen] = useState(false);
  const legacyModulesEnabled = useHasFeatureFlag(LEGACY_UI_MODULES);
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [_, setIsLoading] = useState<boolean>(false);
  const withUpdatingAlert = useShowUpdatingAlert(setIsLoading);

  const {
    isV2,
    pagesPath,
    projectPages: elements,
  } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project],
  );

  const hasExternalSources = useMemo(
    () =>
      project.dataTypes.some((dataType) => dataType.source.type !== INTERNAL),
    [project.dataTypes],
  );

  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      replaceQueryParams({
        ...restParams,
        [`__new-view${qsSuffix}`]: isOpen ? 'true' : undefined,
      });
      setNewViewOpen(isOpen);
    },
    [replaceQueryParams, qsSuffix, restParams],
  );

  const modulesToShow = useMemo(
    () =>
      legacyModulesEnabled
        ? MODULES
        : MODULES.filter((module) =>
            elements.find(
              (element) => get(element, 'children.0.type') === module,
            ),
          ),
    // If we leave the deps empty for this it will only get recalculated on reload
    // This will allow you to toggle off and then back on a module that you had on
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [addChild] = useAddChild(
    project,
    isV2 ? [] : initial(pagesPath),
    isV2 ? [] : ['children'],
  );

  const onAddView = useAddView(project, parentPage, setIsLoading);

  const dataListGroups = useMemo(
    () => getGroupedDataTypeOptions(project.dataTypes),
    [project.dataTypes],
  );

  const onAddPage = useAddPage(project);

  const onAddPageFolder = useCallback(() => {
    trackEvent(NEW_PAGE_SAVED);
    const existingFolders = elements.filter(
      (element) => element.type === FOLDER,
    );
    const folderName = getText(
      { count: existingFolders.length + 1 },
      'leftSidebar.pages.folder',
    );
    const newFolder = getSidebarItemProps({
      type: FOLDER,
      name: folderName,
      routePath: getUniqueRoutePath(String(folderName), project),
    });
    addChild(newFolder, newPageIndex);

    setTimeout(() => {
      push(`/${newFolder?.props.routePath}`);
    }, 500);
  }, [addChild, elements, project, push, newPageIndex]);

  const onAddSidebarLink = useCallback(() => {
    const existingLinks = elements.filter((element) => element.type === LINK);
    const linkName = getText(
      { count: existingLinks.length + 1 },
      'leftSidebar.pages.link',
    );
    addChild(getSidebarItemProps({ name: linkName, type: LINK }), newPageIndex);
  }, [addChild, elements, newPageIndex]);

  const onAddSidebarDivider = useCallback(
    () => addChild(getSidebarItemProps({ type: DIVIDER }), newPageIndex),
    [addChild, newPageIndex],
  );

  const onAddBlankPage = useCallback(() => {
    onAddPage(
      getSidebarItemProps({ type: PAGE, parentPage: parentPage?.id }),
      newPageIndex,
    );
  }, [onAddPage, parentPage, newPageIndex]);

  const onAddIframe = useCallback(
    () =>
      onAddPage(
        getSidebarItemProps({ type: IFRAME, parentPage: parentPage?.id }),
        newPageIndex,
      ),
    [onAddPage, newPageIndex, parentPage],
  );

  const onToggleModule = useCallback(
    (module: any) => (newEnabled: any) => {
      if (newEnabled) {
        // Add module
        addChild(
          {
            type: PAGE,
            id: shortId.generate(),
            props: {
              name: getText('elements', module, 'label'),
              icon: { name: moduleIconNames[module] },
              routePath: getUniqueRoutePath(module, project),
            },
            children: [
              {
                id: shortId.generate(),
                type: module,
                props: omit(
                  ['pl', 'pr', 'mt', 'mb'],
                  compoundElements.SECTIONS.find(({ type }) => type === module)
                    ?.props,
                ),
              },
            ],
          },
          newPageIndex,
        );
      } else {
        updateProject(
          ['elements', ...pagesPath],
          elements.filter(
            (element) => get(element, 'children.0.type') !== module,
          ),
        );
      }
    },
    [addChild, elements, pagesPath, project, updateProject, newPageIndex],
  );

  const advancedOptions = useMemo(
    () => [
      {
        icon: 'Folder',
        label: 'advanced.folder',
        onClick: onAddPageFolder,
        type: FOLDER,
      },
      {
        icon: 'Link',
        label: 'advanced.link',
        onClick: onAddSidebarLink,
        type: LINK,
      },
      {
        icon: 'File',
        label: 'advanced.blank',
        onClick: onAddBlankPage,
        type: PAGE,
      },
      {
        icon: 'Code',
        label: 'advanced.iframe',
        onClick: onAddIframe,
        type: IFRAME,
      },
      {
        icon: 'LineDashed',
        label: 'advanced.divider',
        onClick: onAddSidebarDivider,
        type: DIVIDER,
      },
    ],
    [
      onAddBlankPage,
      onAddIframe,
      onAddPageFolder,
      onAddSidebarDivider,
      onAddSidebarLink,
    ],
  );

  const searchableList = useMemo(
    () =>
      dataListGroups.reduce(
        (result: any[], dataListGroup: any) =>
          result.concat(dataListGroup.options),
        [],
      ),
    [dataListGroups],
  );

  const fuzzy = useFuzzySearch({
    keys: ['label'],
    list: searchableList,
  });

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setFilteredOptions(
        fuzzy.search(event.target.value).map(({ item }) => item),
      ),
    [fuzzy],
  );

  const isOptionFiltered = useCallback(
    (label: string) =>
      filteredOptions.find((option: any) => option.label === label),
    [filteredOptions],
  );

  useOnKeyPress(KEY_N, () => setNewViewOpen(!newViewOpen), {
    enabled: openByShortcut,
    altKey: true,
  });

  return (
    <BasePopover
      className={classNames(
        'max-w-84 h-screen min-h-screen w-full select-none border-l border-r border-slate-700 bg-slate-800',
        { '-ml-2': !sidebarExpanded },
      )}
      content={
        <div className="h-full max-h-full w-full overflow-y-auto">
          <div className="flex h-12 items-center space-x-2 border-b border-slate-700 p-2">
            <TextInput
              autoFocus={true}
              onChange={handleSearch}
              placeholder={getText(LANG_KEY, 'search')}
              value={filter}
            />
            {hasExternalSources && (
              <HelpTooltip
                className="text-slate-400 hover:text-slate-200"
                placement="right"
                surface={DARK}
              >
                <div className={darkModeColors.text.primary}>
                  <h3 className="mb-2 text-sm font-medium uppercase tracking-wider">
                    {getText(LANG_KEY, 'missing.label')}
                  </h3>
                  <p className="text-xs">
                    {getText(LANG_KEY, 'missing.paragraph1')}
                    <a
                      className="text-blue-400 hover:text-blue-500"
                      href="https://guides.noloco.io/data/data-overview#choosing-which-of-your-collections-are-synced"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getText(LANG_KEY, 'missing.link')}
                    </a>
                    {getText(LANG_KEY, 'missing.paragraph2')}
                  </p>
                </div>
              </HelpTooltip>
            )}
            <BuildModeIcon
              Icon={IconChevronsLeft}
              onClick={() => onOpenChange(false)}
            />
          </div>
          <BuildModeSection
            id={COLLECTIONS_OPTIONS}
            title={getText(LANG_KEY, 'collection')}
          >
            <div className="sidebar-item-collection-options flex flex-col space-y-2 p-2">
              {dataListGroups.map(
                ({ dataSource, label, key, icon, options }) => {
                  const isGroupFiltered = filteredOptions.find(
                    (option: any) =>
                      get(option, 'dataType.source.id') ===
                      get(dataSource, 'id'),
                  );

                  if (!isGroupFiltered && filteredOptions.length > 0) {
                    return null;
                  }

                  return (
                    <div className="flex flex-col text-sm" key={key}>
                      <div className="flex items-center space-x-2 rounded-lg bg-slate-700 px-2 py-1 text-slate-200">
                        <span className="h-4 w-4">{icon}</span>
                        <span>{label}</span>
                      </div>
                      <div className="flex flex-col p-2">
                        {options.map(({ dataType, label }) => {
                          if (
                            !isOptionFiltered(label) &&
                            filteredOptions.length > 0
                          ) {
                            return null;
                          }

                          return (
                            <NewDraggableItem
                              dataType={dataType}
                              key={label}
                              newPageIndex={newPageIndex}
                              onAddView={onAddView}
                              setNewViewOpen={setNewViewOpen}
                              withUpdatingAlert={withUpdatingAlert}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          </BuildModeSection>
          {modulesToShow.length > 0 && (
            <BuildModeSection
              className="sticky bottom-12 border-t"
              enabled={modules}
              id={MODULES_OPTIONS}
              title={getText(LANG_KEY, 'modules')}
            >
              <div className="flex flex-col space-y-2 pb-4 pl-4 pr-2 text-sm">
                {modulesToShow.map((module) => {
                  const isEnabled = elements.find(
                    (element: Element) =>
                      get(element, 'children.0.type') === module,
                  );

                  return (
                    <div
                      className="flex items-center justify-between text-slate-400"
                      key={module}
                    >
                      <div className="flex items-center space-x-2">
                        <Box
                          className="h-4 w-4"
                          is={elementsConfig[module].Icon}
                        />
                        <span>{getText('elements', module, 'label')}</span>
                      </div>
                      <Switch
                        onChange={onToggleModule(module)}
                        size={SM}
                        value={isEnabled}
                      />
                    </div>
                  );
                })}
              </div>
            </BuildModeSection>
          )}
          <BuildModeSection
            className="sticky bottom-0 border-t"
            enabled={modules}
            id={ADVANCED_OPTIONS}
            title={getText(LANG_KEY, 'advanced.label')}
          >
            <div className="flex flex-col px-4 text-sm">
              {advancedOptions.map((advancedOption) => (
                <NewDraggableItem
                  item={advancedOption}
                  key={advancedOption.label}
                  setNewViewOpen={setNewViewOpen}
                />
              ))}
            </div>
          </BuildModeSection>
          <BuildModeSection
            className="sticky bottom-0 border-t"
            enabled={!modules}
            id={ADVANCED_OPTIONS}
            title={getText(LANG_KEY, 'advanced.label')}
          >
            <div className="flex flex-col space-y-2 p-4 text-sm">
              <NewDraggableItem
                item={{
                  icon: 'File',
                  label: 'advanced.blank',
                  onClick: onAddBlankPage,
                  type: PAGE,
                }}
                setNewViewOpen={setNewViewOpen}
              />
              <NewDraggableItem
                item={{
                  icon: 'Code',
                  label: 'advanced.iframe',
                  onClick: onAddIframe,
                  type: IFRAME,
                }}
                setNewViewOpen={setNewViewOpen}
              />
            </div>
          </BuildModeSection>
        </div>
      }
      isOpen={newViewOpen}
      onOpenChange={onOpenChange}
      placement="right"
      showArrow={false}
      offset={offset}
    >
      {children ? (
        children
      ) : (
        <div>
          <KeyboardShortcutTooltip
            keys={[ALT, KEY_N]}
            label={getText('leftSidebar.pages.newPageOptions.add')}
            offset={[0, 8]}
            placement="left"
          >
            <div className="flex w-full items-center justify-center">
              <BuildModeIcon Icon={newViewOpen ? IconX : IconPlus} />
            </div>
          </KeyboardShortcutTooltip>
        </div>
      )}
    </BasePopover>
  );
};

export default BuildModeSidebarItemOptions;
