import get from 'lodash/get';
import { DateTime, DateTimeOptions } from 'luxon';
import DATE_FORMATS from '../constants/dateTimeFormats';
import {
  DATE as DATE_FORMAT,
  DATE_RANGE,
  DUE_DATE,
} from '../constants/fieldFormats';
import { TimePeriod, YEAR } from '../constants/timePeriods';
import { QUARTER } from '../constants/timePeriods';
import { MONTH } from '../constants/timePeriods';
import { WEEK } from '../constants/timePeriods';
import { DataField } from '../models/DataTypeFields';
import { RecordValue } from '../models/Record';

export const YEAR_MONTH_DATE_FORMAT = 'yyyy-MM-dd';
export const YMD_TIME_FORMAT = `${YEAR_MONTH_DATE_FORMAT}-HH-mm`;

export const getDateFromValue = (
  value: any,
  options?: DateTimeOptions,
): DateTime | null => {
  for (const dateFormat of DATE_FORMATS) {
    try {
      const parsedDate = dateFormat(value, options);

      if (parsedDate.isValid) {
        return parsedDate;
      }
    } catch {
      // We don't do anything to explicitly handle
    }
  }

  return null;
};

export const formatDateValue = (
  value: RecordValue,
  field: DataField,
  config?: any,
  returnDateTime?: boolean,
): DateTime | string | null => {
  let dateValue = getDateFromValue(value);

  if (dateValue) {
    const typeOptions = get(field, 'typeOptions');
    const { format, time, timeZone } = typeOptions ?? {};

    const baseFormat =
      format === DATE_FORMAT ||
      (format === DATE_RANGE && !time) ||
      (format === DUE_DATE && !time) ||
      (config && config.format === DATE_FORMAT)
        ? DateTime.DATE_SHORT
        : DateTime.DATETIME_SHORT;

    if (format === DATE_FORMAT) {
      dateValue = dateValue.toUTC();
    }

    if (timeZone) {
      dateValue = dateValue.setZone(timeZone);
    }

    if (returnDateTime) {
      return dateValue;
    }

    return dateValue
      .toLocaleString(config?.dateFormat || baseFormat)
      .replace(',', '');
  }

  return null;
};

export const getLabelFromDate = (
  dateTime: DateTime,
  timePeriod?: TimePeriod,
) => {
  switch (timePeriod) {
    case WEEK:
      return dateTime.startOf('week').toLocaleString();

    case MONTH:
      return `${dateTime.monthLong}, ${dateTime.year}`;

    case QUARTER: {
      const quarterStart = dateTime.startOf('quarter').monthShort;
      const quarterEnd = dateTime.endOf('quarter').monthShort;

      return `${quarterStart} - ${quarterEnd}, ${dateTime.year}`;
    }
    case YEAR:
      return dateTime.year;

    default:
      return dateTime.toLocaleString();
  }
};
