import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WithDropable } from '../../../components/withDnD';
import { SECTION } from '../../../constants/draggableItemTypes';
import { Element, ElementPath } from '../../../models/Element';
import { setSelectedSectionPath } from '../../../reducers/elements';
import { hasSelectedElementPathSelector } from '../../../selectors/elementsSelectors';
import { useNextBackLink } from '../../../utils/hooks/useBacklink';
import useLocalStorageState from '../../../utils/hooks/useLocalStorageState';
import useSectionsDragAndDrop from '../../../utils/hooks/useSectionsDragAndDrop';
import { getText } from '../../../utils/lang';
import { filterSections } from '../../../utils/tabs';
import RecordViewSection from './RecordViewSection';

const RecordViewSections = ({
  data,
  dataType,
  editorMode,
  elementPath,
  isEditingData,
  onError,
  onLoadingChange,
  pageId,
  isRecordView,
  project,
  recordScope,
  rootPathname,
  sections,
  selectedTab,
  tabs,
  visibleTabs,
}: any) => {
  const hasSelectedElement = useSelector(hasSelectedElementPathSelector);

  const backLink = useNextBackLink(pageId.replace(':VIEW', ''));

  const dispatch = useDispatch();
  const onUpdateOrder = useCallback(
    (selectedSectionPath: ElementPath) => {
      dispatch(setSelectedSectionPath(selectedSectionPath));
    },
    [dispatch],
  );

  const sectionPath = useMemo(
    () => (isRecordView ? ['record', 'sections'] : ['sections']),
    [isRecordView],
  );

  const [sectionsExpandedState, setSectionsExpandedState] =
    useLocalStorageState('noloco.recordDetailSectionExpanded', {});

  const toggleSectionExpandedState = useCallback(
    (sectionId: any) => {
      const isCurrentlyExpanded = sectionsExpandedState[sectionId];
      const newState =
        isCurrentlyExpanded !== undefined ? !isCurrentlyExpanded : false;

      const updatedState = {
        ...sectionsExpandedState,
        [sectionId]: newState,
      };
      setSectionsExpandedState(updatedState);
    },
    [sectionsExpandedState, setSectionsExpandedState],
  );

  const {
    formatItem: formatSection,
    draftItems: draftSections,
    findItem: findSection,
    onSaveOrder,
    draggable,
  } = useSectionsDragAndDrop({
    sections,
    sectionPath,
    elementPath,
    onUpdateOrder,
  });

  const tabSections = useMemo(
    () => filterSections(tabs, visibleTabs, draftSections, selectedTab),
    [tabs, visibleTabs, draftSections, selectedTab],
  );

  if (visibleTabs.length === 0 && tabs.length > 1) {
    return (
      <div className="flex w-full items-center justify-center py-8">
        {getText('elements.VIEW.tabs.empty')}
      </div>
    );
  }

  return tabSections.map(
    ({ section, index }: { section: Element; index: number }) => (
      <RecordViewSection
        backLink={backLink}
        data={data}
        dataType={dataType}
        draggable={draggable(index)}
        editorMode={editorMode}
        elementPath={elementPath}
        findSection={findSection}
        formatSection={formatSection}
        hasSelectedElement={hasSelectedElement}
        index={index}
        isEditingData={isEditingData}
        isExpanded={sectionsExpandedState}
        isRecordView={isRecordView}
        key={section?.id}
        onError={onError}
        onLoadingChange={onLoadingChange}
        onSaveOrder={onSaveOrder}
        pageId={pageId}
        project={project}
        recordScope={recordScope}
        rootPathname={rootPathname}
        section={section}
        selectedTab={selectedTab}
        tabSections={tabSections}
        toggleSectionExpandedState={toggleSectionExpandedState}
        visibleTabs={visibleTabs}
      />
    ),
  );
};

export default WithDropable(RecordViewSections, SECTION);
