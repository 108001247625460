import { forwardRef, useCallback, useMemo } from 'react';
import { ErrorBoundary } from '@sentry/react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Surface } from '@noloco/components/src';
import { CardStyle } from '@noloco/components/src/constants/cardStyles';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import {
  BOARD,
  CALENDAR,
  CHECKLIST,
  COLUMNS,
  CollectionLayout,
  GANTT,
  MAP,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { FIELD_LEVEL_PERMISSIONS } from '../../../constants/features';
import { UPDATE } from '../../../constants/workflowTriggerTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import {
  ActionButton,
  DepValue,
  ViewRecordStyle,
} from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord, RecordEdge } from '../../../models/Record';
import { User } from '../../../models/User';
import {
  DataList,
  FormConfigWithField,
  GroupByWithField,
} from '../../../models/View';
import {
  getTextFromError,
  useErrorAlert,
} from '../../../utils/hooks/useAlerts';
import useAuthWrapper from '../../../utils/hooks/useAuthWrapper';
import { AutoFormProvider } from '../../../utils/hooks/useAutoForm';
import { useBuildDataItemRecordScope } from '../../../utils/hooks/useBuildDataItemRecordScope';
import { useFieldVisibilityConditions } from '../../../utils/hooks/useFieldVisibilityConditions';
import useIsTable from '../../../utils/hooks/useIsTable';
import useMergedScope from '../../../utils/hooks/useMergedScope';
import useRecordRowLink from '../../../utils/hooks/useRecordRowLink';
import { getText } from '../../../utils/lang';
import { transformColumnarScope } from '../../../utils/scope';
import { DEBOUNCED_TYPES } from '../../../utils/useFormFieldsState';
import { CARDS } from '../forms/AutoFormSection';
import CollectionRecordActionButtons from '../view/CollectionRecordActionButtons';
import { getFormFieldConfig } from './FieldCell';
import CardRecordLayout from './layouts/CardRecordLayout';
import RowRecordLayout from './layouts/RowRecordLayout';
import TableRecordLayout from './layouts/TableRecordLayout';

export const variables = {
  description: { hidden: true },
  image: { hidden: true },
  secondaryText: { hidden: true },
  title: { hidden: true },
};

const shouldSubmitOnBlur = (field: DataField) =>
  !DEBOUNCED_TYPES.includes(field.type);

const COLLECTION_RECORD_LAYOUTS = {
  [BOARD]: CardRecordLayout,
  [CALENDAR]: CardRecordLayout,
  [CARDS]: CardRecordLayout,
  [CHECKLIST]: RowRecordLayout,
  [COLUMNS]: CardRecordLayout,
  [GANTT]: CardRecordLayout,
  [MAP]: CardRecordLayout,
  [ROWS]: RowRecordLayout,
  [SPLIT]: RowRecordLayout,
  [TABLE_FULL]: TableRecordLayout,
  [TABLE]: TableRecordLayout,
  [TIMELINE]: CardRecordLayout,
};

interface CollectionRecordProps {
  'data-group-key'?: string;
  'data-index': number;
  actionButtons?: ActionButton[];
  cardSize?: ShirtSize;
  cardStyle?: CardStyle;
  checklistField?: DepValue;
  children?: React.ReactNode;
  className?: string;
  columnWidths: Record<number, number>;
  dataList: DataList;
  dataType: DataType;
  draggable: boolean;
  edge?: RecordEdge;
  editorMode: boolean;
  editRelatedRecordButton?: React.ReactNode;
  element: Element;
  elementPath: string;
  fieldConfigs: FormConfigWithField[];
  formatRecordScope: (record: BaseRecord) => Record<string, any>;
  groupByFields: GroupByWithField[];
  index: number;
  isLast: boolean;
  layout: CollectionLayout;
  loading: boolean;
  maxStickyColumnIndex: number;
  parentValuePath: string;
  project: Project;
  record: BaseRecord;
  recordQueryString: string;
  recordStyle: ViewRecordStyle;
  refetch: () => void;
  rootDataType: DataType;
  rowLink: string;
  scope: Record<string, any>;
  selectedRows: BaseRecord[];
  setSelectedRows: (
    setter: (currentValue: BaseRecord[]) => BaseRecord[],
  ) => void;
  showCardHeroImage: boolean;
  surface: Surface;
  transformRecordScope: (
    scope: Record<string, any>,
    record: BaseRecord,
  ) => Record<string, any>;
  viewRootPathname: string;
}

const CollectionRecord = forwardRef<HTMLDivElement, CollectionRecordProps>(
  (
    {
      'data-group-key': dataGroupKey,
      'data-index': dataIndex,
      actionButtons,
      cardSize,
      cardStyle,
      checklistField,
      children,
      className,
      columnWidths,
      dataList,
      dataType,
      draggable,
      editorMode,
      editRelatedRecordButton,
      element,
      elementPath,
      fieldConfigs,
      formatRecordScope,
      groupByFields,
      index,
      layout,
      maxStickyColumnIndex,
      project,
      record,
      recordQueryString,
      recordStyle,
      refetch,
      rootDataType,
      rowLink,
      scope,
      selectedRows,
      setSelectedRows,
      showCardHeroImage,
      surface,
      transformRecordScope,
      viewRootPathname,
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const errorAlert = useErrorAlert();
    const RecordLayout =
      COLLECTION_RECORD_LAYOUTS[layout] || COLLECTION_RECORD_LAYOUTS[ROWS];

    const isTable = useIsTable(layout);

    const recordRowLink = useRecordRowLink({
      layout,
      project,
      recordQueryString,
      recordStyle,
      rowLink,
      uuid: get(record, 'uuid'),
      viewRootPathname,
    });

    const onUpdateError = useCallback(
      (error: any) => {
        errorAlert(
          getText('errors.data.message'),
          getTextFromError(error).message,
        );
      },
      [errorAlert],
    );

    const recordWithColumnarData = useMemo(
      () => transformColumnarScope(record, dataType, project.dataTypes),
      [dataType, project.dataTypes, record],
    );

    const buildRecordScope = useBuildDataItemRecordScope(
      (formRecord: any) => formRecord,
      transformRecordScope,
      dataType.name,
    );

    const recordScope = useMemo(
      () => buildRecordScope(recordWithColumnarData),
      [buildRecordScope, recordWithColumnarData],
    );

    const mergedRecordScope = useMergedScope(recordScope);
    const mergedScope = useMemo(
      () => ({
        ...scope,
        ...mergedRecordScope,
      }),
      [mergedRecordScope, scope],
    );

    const resolvedFieldsWithConditionsMet = useFieldVisibilityConditions(
      fieldConfigs,
      project,
      recordScope,
      mergedScope,
    );

    const { user } = useAuthWrapper();
    const fieldPermissionsEnabled = useIsFeatureEnabled(
      FIELD_LEVEL_PERMISSIONS,
    );

    const resolvedFieldConfigs: FormConfigWithField[] = useMemo(
      () => (isTable ? fieldConfigs : resolvedFieldsWithConditionsMet),
      [isTable, fieldConfigs, resolvedFieldsWithConditionsMet],
    );

    const formFieldConfigs = useMemo(
      () =>
        resolvedFieldConfigs
          .filter(({ config }) => config.editInline)
          .map(({ field, config }) => ({
            field,
            config: {
              ...getFormFieldConfig(
                field,
                project,
                user as User,
                fieldPermissionsEnabled,
                config,
              ),
              label: undefined,
            },
          })),
      [fieldPermissionsEnabled, project, resolvedFieldConfigs, user],
    );

    const recordId = get(record, 'id');
    const bulkActionsEnabled = get(element, 'props.bulkActionsEnabled', false);
    const handleCheckboxChange = useCallback(
      ({ target: { checked } }: any) =>
        setSelectedRows((rows: BaseRecord[]) =>
          checked
            ? [...rows, record]
            : rows.filter((row) => row.id !== record.id),
        ),
      [setSelectedRows, record],
    );

    const isRowChecked = useMemo(
      () => !!selectedRows.find((row: BaseRecord) => row.id === recordId),
      [selectedRows, recordId],
    );

    const recordLayout = (
      <RecordLayout
        actionButtons={
          actionButtons && (
            <CollectionRecordActionButtons
              actionButtons={actionButtons}
              collectionId={element.id}
              dataList={dataList}
              dataType={dataType}
              editRelatedRecordButton={editRelatedRecordButton}
              editorMode={editorMode}
              elementPath={elementPath}
              formatRecordScope={formatRecordScope}
              index={index}
              record={recordWithColumnarData}
              refetch={refetch}
              rootDataType={rootDataType}
              layout={layout}
              parentScope={scope}
              project={project}
              bulkActionsEnabled={bulkActionsEnabled}
              elementId={element.id}
              isRowChecked={isRowChecked}
              handleCheckboxChange={handleCheckboxChange}
            />
          )
        }
        bulkActionsEnabled={bulkActionsEnabled}
        cardSize={cardSize}
        checklistField={checklistField}
        cardStyle={cardStyle}
        className={classNames(`record-${get(record, 'uuid')}`, className)}
        columnWidths={columnWidths}
        data-group-key={dataGroupKey}
        data-index={dataIndex}
        dataType={dataType}
        draggable={draggable}
        element={element}
        elementId={element.id}
        fieldConfigs={resolvedFieldConfigs}
        groupByFields={groupByFields}
        handleCheckboxChange={handleCheckboxChange}
        isRowChecked={isRowChecked}
        key={recordId}
        layout={layout}
        maxStickyColumnIndex={maxStickyColumnIndex}
        project={project}
        record={recordWithColumnarData}
        ref={ref}
        rowLink={recordRowLink}
        scope={mergedScope}
        selectedRows={selectedRows}
        showCardHeroImage={showCardHeroImage}
        transformRecordScope={transformRecordScope}
      >
        {children}
      </RecordLayout>
    );

    return (
      <ErrorBoundary>
        {formFieldConfigs.length > 0 ? (
          <AutoFormProvider
            innerClassName="max-w-full w-full"
            dataType={dataType}
            value={recordWithColumnarData}
            inviteEnabled={false}
            fields={formFieldConfigs}
            inline={isTable}
            onError={onUpdateError}
            submitOnBlur={shouldSubmitOnBlur}
            mutationType={UPDATE}
            surface={surface}
            bulkActionsEnabled={bulkActionsEnabled}
            isRowChecked={isRowChecked}
            selectedRows={selectedRows}
            transformRecordScope={transformRecordScope}
          >
            {recordLayout}
          </AutoFormProvider>
        ) : (
          recordLayout
        )}
      </ErrorBoundary>
    );
  },
);

export default CollectionRecord;
