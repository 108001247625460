import React, { useState } from 'react';
import ErrorText from '@noloco/components/src/components/form/ErrorText';
import { getText } from '../utils/lang';
import { BackupCodeInput } from './sections/twoFactorAuthentication/BackupCodeInput';
import { OTPInput } from './sections/twoFactorAuthentication/OTPInput';

interface TwoFactorAuthLoginProps {
  secondFactorAuthToken?: string;
  redirectPath?: string;
  updateUserCache: (user: any) => void;
  setOtpValue: (otpValue: string) => void;
  otpValue: string;
  handleVerify: (otp: string) => void;
  handleVerifyOTPBackupCode: (backupCode: string) => void;
  loading: boolean;
  errors: string[];
}

export const TwoFactorAuthLogin = ({
  loading,
  errors,
  setOtpValue,
  otpValue,
  handleVerify,
  handleVerifyOTPBackupCode,
}: TwoFactorAuthLoginProps) => {
  const [useBackupCode, setUseBackupCode] = useState(false);

  return (
    <>
      {errors.length > 0 && (
        <ErrorText>
          {errors.map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </ErrorText>
      )}
      {!useBackupCode ? (
        <div className="flex w-full flex-col gap-4">
          <OTPInput
            handleVerify={handleVerify}
            loading={loading}
            setOtpValue={setOtpValue}
            otpValue={otpValue}
          />
          <p>
            {getText('auth.twoFactorAuth.backupCodes.prompt')}
            <button
              onClick={() => setUseBackupCode(true)}
              disabled={loading}
              className="font-inherit text-md cursor-pointer border-none bg-none p-0 text-blue-500 underline disabled:opacity-75"
            >
              {getText('auth.twoFactorAuth.backupCodes.useBackupCode')}
            </button>
          </p>
        </div>
      ) : (
        <BackupCodeInput
          handleVerifyOTPBackupCode={handleVerifyOTPBackupCode}
          loading={loading}
        />
      )}
    </>
  );
};
