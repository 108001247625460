import React, { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import identity from 'lodash/identity';
import { getColorShade } from '@noloco/components';
import MarkdownText from '../../components/MarkdownText';
import withActionHandler from '../../components/canvas/withActionHandler';
import { USER } from '../../constants/builtInDataTypes';
import { BUTTON, LINK } from '../../constants/elements';
import { IS_WEBSITE_EXAMPLES } from '../../constants/env';
import { formatBg } from '../../utils/styles';
import SectionButton from './SectionButton';
import ActionButton from './view/ActionButton';

interface Props {
  image: {
    hidden: boolean;
    value?: {
      src: string;
    };
  };
  buttons: any[];
  truncateTitle?: boolean;
  children?: React.ReactNode;
}

const Title = forwardRef<any, Props>(
  (
    {
      buttons,
      // @ts-expect-error TS(2339): Property 'breadcrumbs' does not exist on type 'Pro... Remove this comment to see the full error message
      breadcrumbs,
      // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      // @ts-expect-error TS(2339): Property 'coverPhoto' does not exist on type 'Prop... Remove this comment to see the full error message
      coverPhoto,
      // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Prop... Remove this comment to see the full error message
      editorMode,
      image,
      // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'Props'... Remove this comment to see the full error message
      subtitle,
      // @ts-expect-error TS(2339): Property 'searchEnabled' does not exist on type 'P... Remove this comment to see the full error message
      searchEnabled = false,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type 'Props'.
      title,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Props'.
      onClick,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type 'Props'.
      project,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      truncateTitle,
    },
    ref,
  ) => {
    const primaryColor = theme.brandColorGroups.primary;
    const hasCoverPhoto =
      coverPhoto &&
      !coverPhoto.hidden &&
      coverPhoto.value &&
      coverPhoto.value.src;

    const TitleEl = IS_WEBSITE_EXAMPLES ? 'span' : 'h1';

    return (
      <div
        className={classNames(className, 'flex flex-col')}
        onClick={onClick}
        ref={ref}
      >
        {hasCoverPhoto && (
          <div
            className="flex h-80 w-full overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat sm:h-40"
            style={formatBg(coverPhoto.value.src)}
          />
        )}
        {breadcrumbs}
        <div
          className={classNames(
            'flex items-center justify-center sm:justify-between',
            {
              'sm:flex-col': !subtitle.hidden || searchEnabled,
              'pt-4': hasCoverPhoto,
              'mt-2': !hasCoverPhoto && !image.hidden,
            },
          )}
        >
          {!image.hidden && (
            <div
              className={classNames('mr-6 sm:mr-4', { 'ml-12': hasCoverPhoto })}
            >
              <Box
                is="div"
                className={classNames(
                  'sm:rounded-0 rounded-full bg-cover bg-center text-white',
                  {
                    'h-20 w-20 sm:h-10 sm:w-10': !hasCoverPhoto,
                    '-mt-16 h-32 w-32 border-4 border-white': hasCoverPhoto,
                  },
                )}
                bg={getColorShade(primaryColor, 100)}
                style={formatBg(
                  image.value?.src || `https://picsum.photos/id/1020/600/600`,
                )}
              >
                <span className="block h-full w-full" />
              </Box>
            </div>
          )}
          {(!title.hidden || !subtitle.hidden) && (
            <div
              className={classNames(
                'flex items-center overflow-x-hidden sm:w-full',
                { 'w-full': !children && buttons.length === 0 },
              )}
            >
              <div className="flex flex-col overflow-hidden sm:mb-2">
                {!title.hidden && (
                  <TitleEl
                    className={classNames(
                      'text-xl font-medium tracking-wider sm:overflow-visible sm:whitespace-normal sm:text-sm',
                      { truncate: truncateTitle },
                    )}
                  >
                    {title.value}
                  </TitleEl>
                )}
                {!subtitle.hidden && (
                  <MarkdownText
                    className="mt-1 sm:text-xs dark:text-gray-400"
                    small={false}
                  >
                    {subtitle.value}
                  </MarkdownText>
                )}
              </div>
            </div>
          )}
          {(children || buttons.length > 0) && (
            <div
              className={classNames(
                'ml-auto flex flex-wrap items-end justify-end gap-2 pl-4 sm:flex-nowrap sm:gap-0 sm:pl-0',
              )}
            >
              {children}
              {buttons.map((button: any, index: any) => {
                let ButtonComponent = SectionButton;

                if (
                  !editorMode &&
                  button.actions &&
                  button.actions.length > 0
                ) {
                  // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<RefAttributes<unkn... Remove this comment to see the full error message
                  ButtonComponent = withActionHandler(
                    SectionButton,
                    {
                      ...button,
                      type: BUTTON,
                    },
                    project,
                  );

                  return (
                    <ButtonComponent button={button} key={button.id || index} />
                  );
                } else if (button.link) {
                  let buttonConfig = set('type', LINK, button);
                  buttonConfig = set('buttonText', button.text, buttonConfig);

                  return (
                    <ActionButton
                      actionButton={buttonConfig}
                      /* This component is effectively deprecated and so doesn't really have a data type - but it could be user */
                      dataType={project.dataTypes.getByName(USER)!}
                      editorMode={editorMode}
                      index={index}
                      project={project}
                      key={buttonConfig.id || index}
                      record={undefined}
                      recordScope={{}}
                      onDeleteRecord={identity}
                    />
                  );
                }

                return (
                  <ButtonComponent button={button} key={button.id || index} />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  },
);

Title.defaultProps = {
  image: {
    hidden: true,
  },
  buttons: [],
  truncateTitle: true,
};

Title.displayName = 'Title';

export default withTheme(Title);
