import get from 'lodash/get';
import { USER } from '../constants/builtInDataTypes';
import { traversePermissionPath } from '../utils/permissions';
import BaseArrayTypeMap, { BaseJsonMaps, JsonMaps } from './BaseArrayTypeMap';
import type DataTypes from './DataTypes';
import FieldPermissions from './FieldPermissions';
import { FieldPermission, Permission } from './Permission';

class DataTypePermissions extends BaseArrayTypeMap<Permission> {
  dataTypeName: string;
  roleMap: Record<string | number, number[]>;
  rolePermissionDataTypes: Record<string | number, string[]>;

  constructor(permissions: number | Permission[], dataTypeName: string) {
    if (permissions instanceof DataTypePermissions) {
      //Will copy maps for permissions in constructor & reuse current instances of DataTypePermissions
      super(permissions);
      this.roleMap = permissions.roleMap;
    } else if (typeof permissions === 'number') {
      super(permissions);
      this.roleMap = {};
    } else if (Array.isArray(permissions)) {
      const permissionsWithFieldPermissions = permissions.map((permission) => ({
        ...permission,
        fieldPermissions: new FieldPermissions(
          permission.fieldPermissions || [],
        ),
      }));

      super(permissionsWithFieldPermissions);
      this.roleMap = {};
    } else {
      super();
      this.roleMap = {};
    }

    this.dataTypeName = dataTypeName;
    this.rolePermissionDataTypes = {};
    this.maps.roleMap = this.roleMap;
  }

  static fromJSON(
    dataTypeName: string,
    json: JsonMaps<
      Permission & {
        fieldPermissions?: JsonMaps<
          FieldPermission,
          { fieldMap: Record<string, number> }
        >;
      },
      {
        roleMap: Record<string | number, number[]>;
      }
    >,
  ) {
    const jsonMap = json;

    Object.keys(json.idMap).forEach((permissionKey) => {
      const permissionId = Number(permissionKey);
      const permission = jsonMap.idMap[permissionId];

      if (permission.fieldPermissions) {
        // This thinks it should be JsonMaps<FieldPermission> but it should be FieldPermissions
        (permission.fieldPermissions as FieldPermissions) =
          FieldPermissions.fromJSON(permission.fieldPermissions);
      }

      jsonMap.idMap[permissionId] = permission;
    });

    const permissions = DataTypePermissions._fromJSON(
      jsonMap as BaseJsonMaps<Permission>,
    ) as DataTypePermissions;

    permissions.dataTypeName = dataTypeName;

    return permissions;
  }

  _mapEntry(permission: Permission) {
    super._mapEntry(permission);

    if (this.idMap) {
      permission.roleIds.forEach((roleId) => {
        if (this.roleMap[roleId]) {
          this.roleMap[roleId] = [...this.roleMap[roleId], permission.id];
        } else {
          this.roleMap[roleId] = [permission.id];
        }
      });
    }
  }

  getByRoleId(roleId: string | number): Permission[] {
    if (!this.idMap) {
      this._mapEntries();
    }

    return this.getByIds(get(this.roleMap, roleId, []));
  }

  /**
   * Calculate the data types used in the permissions for a role with memoization to avoid recalculating
   * @param roleId
   * @param dataTypesWithRelations
   */
  getDataTypesUsedForRolePermissions(
    roleId: string | number,
    dataTypesWithRelations: DataTypes,
  ): string[] {
    if (this.rolePermissionDataTypes[roleId]) {
      return this.rolePermissionDataTypes[roleId];
    }

    const permissionDataTypes = new Set<string>();
    const rolePermissions = this.getByRoleId(roleId);

    for (const perm of rolePermissions) {
      // Ignore permissions that don't have a filter or have an empty filter
      if (!Array.isArray(perm.filter)) {
        continue;
      }

      for (const filterGroup of perm.filter) {
        if (!Array.isArray(filterGroup)) {
          continue;
        }

        for (const filter of filterGroup) {
          const filterFieldPath = filter?.field?.path;

          if (filterFieldPath) {
            const dataTypes = traversePermissionPath(
              filterFieldPath,
              dataTypesWithRelations,
              dataTypesWithRelations.getByNameOrThrow(this.dataTypeName),
            ).dataTypeNames;

            dataTypes.forEach((dtName) => permissionDataTypes.add(dtName));
          }

          permissionDataTypes.add(USER);

          const filterValuePath = filter?.value?.length
            ? filter.value[0].data?.path
            : null;

          if (filterValuePath) {
            const dataTypes = traversePermissionPath(
              filterValuePath,
              dataTypesWithRelations,
              dataTypesWithRelations.getByName(USER)!,
            ).dataTypeNames;
            dataTypes.forEach((dtName) => permissionDataTypes.add(dtName));
          }
        }
      }
    }

    const permissionDataTypesArray = Array.from(permissionDataTypes);
    this.rolePermissionDataTypes[roleId] = permissionDataTypesArray;

    return permissionDataTypesArray;
  }
}

export default DataTypePermissions;
