import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Notice, Popover } from '@noloco/components';
import { INFO, SECONDARY } from '@noloco/components/src/constants/variants';
import Avatar from '../../components/Avatar';
import { ENABLED, REQUIRED } from '../../constants/twoFactorAuth';
import {
  projectNameSelector,
  projectSettingsSelector,
} from '../../selectors/projectSelectors';
import { useAuth } from '../../utils/hooks/useAuth';
import useAuthWrapper from '../../utils/hooks/useAuthWrapper';
import useDarkMode from '../../utils/hooks/useDarkMode';
import useHasFeatureFlag, {
  TWO_FACTOR_AUTHENTICATION,
} from '../../utils/hooks/useHasFeatureFlag';
import useRouter from '../../utils/hooks/useRouter';
import useScopeUser from '../../utils/hooks/useScopeUser';
import { useServiceWorkerNotifications } from '../../utils/hooks/useServiceWorkerNotifications';
import { getText } from '../../utils/lang';
import { getFullName } from '../../utils/user';
import NotificationConsentModal from '../NotificationConsentModal';
import NotificationPreferencesModal from '../NotificationPreferencesModal';
import { TwoFactorAuthModal } from '../TwoFactorAuthModal';

export interface Props {
  children: React.ReactNode;
  disabled: boolean;
  placement?: 'top-start' | 'bottom-end';
  showProfile: boolean;
}

const LANG_KEY = 'auth';

const ProfilePopover = ({
  children,
  disabled,
  placement = 'top-start',
  showProfile,
}: Props) => {
  const { signOut } = useAuth();
  const [isDarkModeEnabled, _setDarkModeEnabled, clearDarkModeEnabled] =
    useDarkMode();
  const projectName = useSelector(projectNameSelector);
  const { push } = useRouter();
  const user = useScopeUser();
  const { isGhost } = useAuthWrapper();

  const fullName = useMemo(() => getFullName(user), [user]);

  const browserNotificationsEnabled =
    process.env.REACT_APP_SW_NOTIFICATIONS_DISABLED !== 'true';

  const { isSubscribed, subscribe, subscribing } =
    useServiceWorkerNotifications(projectName);
  const [isNotificationConsentModalOpen, setIsNotificationConsentModalOpen] =
    useState(false);
  const [isNotificationPreferencesOpen, setIsNotificationPreferencesOpen] =
    useState(false);
  const [isTwoFactorAuthModalOpen, setIsTwoFactorAuthModalOpen] =
    useState(false);

  const projectSettings = useSelector(projectSettingsSelector);

  const projectTwoFactorAuthSetting = useMemo(
    () => projectSettings?.auth?.twoFactorAuth,
    [projectSettings],
  );

  const isTwoFactorAuthEnabled =
    useHasFeatureFlag(TWO_FACTOR_AUTHENTICATION) &&
    (projectTwoFactorAuthSetting === REQUIRED ||
      projectTwoFactorAuthSetting === ENABLED);

  const onSignOut = useCallback(() => {
    signOut();
    clearDarkModeEnabled();
    push('/login');
  }, [push, clearDarkModeEnabled, signOut]);

  return (
    <>
      <Popover
        content={
          <div
            className={classNames('mt-2 flex max-w-xs flex-col', {
              dark: isDarkModeEnabled,
            })}
          >
            <div className="flex flex-col">
              <div className="flex px-6 py-3">
                <Avatar className="mx-auto mb-2" size={12} user={user} />
                <div className="ml-4 flex flex-col">
                  <p className="text-md text-gray-800 dark:text-gray-200">
                    {fullName}
                  </p>
                  <p className="text-sm text-gray-600 dark:text-gray-400">
                    {user.email}
                  </p>
                </div>
              </div>
              {showProfile && (
                <>
                  <hr />
                  <Link
                    className="py-2 text-center text-sm text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:text-gray-800"
                    to="/profile"
                  >
                    {getText(LANG_KEY, 'profile.view')}
                  </Link>
                </>
              )}
              <hr />
              <button
                className="py-2 text-center text-sm text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:text-gray-800"
                onClick={() => setIsNotificationPreferencesOpen(true)}
              >
                {getText('notificationPreferences.label')}
              </button>

              {isTwoFactorAuthEnabled && (
                <>
                  <hr />
                  <button
                    className="cursor-pointer px-2 py-2 text-sm text-gray-800 hover:bg-gray-200 disabled:opacity-50 dark:text-gray-200 dark:hover:text-gray-800"
                    onClick={() => setIsTwoFactorAuthModalOpen(true)}
                    disabled={isGhost}
                  >
                    {getText(LANG_KEY, 'twoFactorAuth.title')}
                  </button>
                </>
              )}

              <hr />
              <button
                className="mb-4 cursor-pointer py-2 text-sm text-gray-800 hover:bg-gray-200 dark:text-gray-200 dark:hover:text-gray-800"
                onClick={onSignOut}
              >
                {getText(LANG_KEY, 'signOut')}
              </button>
            </div>
          </div>
        }
        disabled={disabled}
        p={{ y: 0, x: 0 }}
        placement={placement}
      >
        {children}
      </Popover>
      <NotificationConsentModal
        loading={subscribing}
        onSubscribe={subscribe}
        open={isNotificationConsentModalOpen}
        setOpen={setIsNotificationConsentModalOpen}
      />
      {!isNotificationConsentModalOpen &&
        isNotificationPreferencesOpen &&
        !isTwoFactorAuthModalOpen && (
          <NotificationPreferencesModal
            onClose={() => setIsNotificationPreferencesOpen(false)}
          >
            {!isSubscribed && browserNotificationsEnabled && (
              <Notice
                title={getText('notificationPreferences.browserConsent.title')}
                subtitle={getText(
                  'notificationPreferences.browserConsent.subtitle',
                )}
                className="mb-4"
                type={INFO}
              >
                <Button
                  className="my-auto ml-4"
                  variant={SECONDARY}
                  onClick={() => setIsNotificationConsentModalOpen(true)}
                >
                  {getText('notificationPreferences.browserConsent.cta')}
                </Button>
              </Notice>
            )}
          </NotificationPreferencesModal>
        )}
      {isTwoFactorAuthModalOpen && isTwoFactorAuthEnabled && (
        <TwoFactorAuthModal
          open={isTwoFactorAuthModalOpen}
          onClose={() => setIsTwoFactorAuthModalOpen(false)}
          projectTwoFactorAuthSetting={projectTwoFactorAuthSetting}
          projectName={projectName}
        />
      )}
    </>
  );
};

export default ProfilePopover;
