import { Icon, IconGripVertical } from '@tabler/icons-react';
import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { SECTION_LIST_ITEM } from '../../constants/draggableItemTypes';
import { Element } from '../../models/Element';
import { getText } from '../../utils/lang';
import EditableLabel from './EditableLabel';

interface ContainerSectionListItemProps {
  activeSection?: string;
  container: string;
  handleDrop: (over?: boolean, container?: string, droppedOn?: string) => void;
  handleMouseOut: () => void;
  handleMouseOver: (sectionIndex?: number, sectionId?: string) => void;
  Icon: Icon;
  id: string;
  label: string;
  section: Element;
  sectionIndex: number;
  selectSection: (
    sectionIndex?: number,
    containerSectionId?: string,
    containerSectionType?: string,
  ) => void;
  type: string;
  updateProperty: (path: string[], value: any) => void;
}

const ContainerSectionListItem = ({
  activeSection,
  container,
  handleDrop,
  handleMouseOut,
  handleMouseOver,
  Icon,
  id,
  label,
  section,
  sectionIndex,
  selectSection,
  type,
  updateProperty,
}: ContainerSectionListItemProps) => {
  const [_, dragRef] = useDrag({ item: section, type: SECTION_LIST_ITEM });

  const [{ isOver: isAbove }, aboveDropRef] = useDrop({
    accept: SECTION_LIST_ITEM,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: () => handleDrop(true, container, id),
  });

  return (
    <>
      <div
        className={classNames('ml-8 mr-1 flex h-1 flex-grow rounded-full', {
          'bg-slate-600': isAbove,
        })}
        ref={aboveDropRef}
      />
      <div
        className={classNames(
          'group my-1 ml-8 mr-1 flex h-8 flex-grow cursor-pointer items-center rounded-lg p-2 hover:bg-slate-600',
          { 'bg-slate-900': activeSection === section.id },
        )}
        onClick={() => selectSection(sectionIndex, id, type)}
        onMouseOut={handleMouseOut}
        onMouseOver={() => handleMouseOver(sectionIndex, id)}
        ref={dragRef}
      >
        <button
          className="hidden cursor-move group-hover:flex"
          draggable={true}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <IconGripVertical className="mr-4" size={16} />
        </button>
        <Icon className="mr-4 flex group-hover:hidden" size={16} />
        <EditableLabel
          value={label}
          onUpdate={(value) => updateProperty(['name'], value)}
          placeholder={getText('elements', type, 'label')}
          active={activeSection === section.id}
          rightOffset="5"
        />
      </div>
    </>
  );
};

export default ContainerSectionListItem;
