import { useCallback, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { DELETE } from '../../constants/actionTypes';
import { DataType } from '../../models/DataTypes';
import { Project } from '../../models/Project';
import { BaseRecord } from '../../models/Record';
import { RUN_WORKFLOW, getMutationQueryString } from '../../queries/project';
import { copyTextToClipboard } from '../copyTextToClipboard';
import { getDataTypeByName } from '../data';
import getActionButtonFormFieldConfig from '../getActionButtonFormFieldConfig';
import getFormFieldRecordId from '../getFormFieldRecordId';
import { getText } from '../lang';
import { useErrorAlert, useGraphQlErrorAlert } from './useAlerts';
import useNavigateAction from './useNavigateAction';
import useRecordCommentMutations from './useRecordCommentMutations';

const deleteItem = async (
  dataType: any,
  dataTypes: any,
  record: any,
  action: any,
  client: any,
  projectName: any,
) => {
  const { field } = action;

  const selectedField = dataType.fields.getByName(field || 'id');

  let actionDataType = dataType;

  if (selectedField && selectedField.name !== 'id') {
    actionDataType = getDataTypeByName(dataTypes, selectedField.type);
  }

  const deleteQueryString = gql`
    ${getMutationQueryString(
      DELETE,
      actionDataType.name,
      actionDataType.fields,
      {
        id: true,
      },
    )}
  `;

  const recordIdPath =
    selectedField.name === 'id'
      ? [selectedField.name]
      : [selectedField.name, 'id'];
  const recordId = get(record, recordIdPath);

  if (!recordId) {
    throw new Error('No record to delete');
  }

  return client.mutate({
    mutation: deleteQueryString,
    variables: { id: recordId },
    context: {
      projectQuery: true,
      projectName: projectName,
    },
  });
};

interface Context {
  projectName: string;
  projectQuery: boolean;
  skipLambdas?: boolean;
}

const useActionsWithoutInputs = (
  actionsScope: any,
  dataType: DataType,
  onDeleteRecord: (
    record: BaseRecord,
    onNext: (actionResult?: any) => void,
  ) => void,
  onNext: (actionResult?: any) => void,
  onFinish: () => void,
  project: Project,
  record: BaseRecord | undefined,
) => {
  const errorAlert = useErrorAlert();
  const networkErrorAlert = useGraphQlErrorAlert();
  const apolloClient = useApolloClient();
  const onNavigateAction = useNavigateAction();

  const { createComment } = useRecordCommentMutations({ dataType, project });

  const onDelete = useCallback(
    (action: any, setIsLoading: any) => {
      setIsLoading(true);
      deleteItem(
        dataType,
        project.dataTypes,
        record,
        action,
        apolloClient,
        project.name,
      )
        .then(() => {
          if (!action.field && record) {
            // This means we've deleted the current record
            onDeleteRecord(record, onNext);
          } else {
            onNext();
          }
          setIsLoading(false);
        })
        .catch((e) => {
          networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
          onFinish();
        });
    },
    [
      apolloClient,
      dataType,
      networkErrorAlert,
      onDeleteRecord,
      onFinish,
      onNext,
      project.dataTypes,
      project.name,
      record,
    ],
  );

  const context = useMemo<Context>(
    () => ({ projectName: project.name, projectQuery: true }),
    [project.name],
  );

  const [runWorkflow] = useMutation(
    gql`
      ${RUN_WORKFLOW}
    `,
    { context },
  );

  const onNavigate = useCallback(
    (action: any) => {
      onNavigateAction(action, actionsScope, onNext);
    },
    [actionsScope, onNavigateAction, onNext],
  );

  const onCopy = (action: any, setIsLoading: any) => {
    setIsLoading(true);
    copyTextToClipboard(action.copy.value);
    setIsLoading(false);
    onNext();
  };

  const onRunWorkflow = useCallback(
    (action: any, setIsLoading: any) => {
      setIsLoading(true);
      const { selectedField } = getActionButtonFormFieldConfig(
        action.field,
        dataType,
        project.dataTypes,
      );

      if (!selectedField) {
        errorAlert(getText('elements.VIEW.actionButtons.invalid'));
        setIsLoading(false);
        onNext();
      }

      // @ts-expect-error TS(2345): Argument of type 'DataField | undefined' is not as... Remove this comment to see the full error message
      const recordId = getFormFieldRecordId(record, selectedField);

      if (!recordId) {
        errorAlert(getText('elements.VIEW.actionButtons.invalid'));
        setIsLoading(false);
        onNext();
      }

      if (selectedField && recordId) {
        runWorkflow({
          context: { ...context, skipLambdas: true },
          variables: {
            workflowId: action.workflow,
            recordId: recordId,
          },
        })
          .then(() => {
            setIsLoading(false);
            onNext();
          })
          .catch((e) => {
            setIsLoading(false);
            networkErrorAlert(getText('elements.VIEW.actionButtons.error'), e);
            onFinish();
          });
      }
    },
    [
      context,
      dataType,
      errorAlert,
      networkErrorAlert,
      onFinish,
      onNext,
      project.dataTypes,
      record,
      runWorkflow,
    ],
  );

  const onAddComment = useCallback(
    async (action: any, setIsLoading: (loading: boolean) => void) => {
      setIsLoading(true);

      const { body, note } = action?.addComment ?? {};

      if (record?.id && body) {
        await createComment(
          record.id,
          action?.addComment?.body,
          undefined,
          note,
        );
      }
      setIsLoading(false);
      onNext();
    },
    [createComment, onNext, record?.id],
  );

  return {
    onDelete,
    onNavigate,
    onRunWorkflow,
    onCopy,
    onAddComment,
  };
};

export default useActionsWithoutInputs;
