import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import SafeStorage from '../SafeStorage';

export interface ExpiringLocalStorage {
  hasKeyExpired: boolean;
  setKeyWithExpiry: (expiresIn: number) => void;
}

export const useExpiringLocalStorage = (
  localStorageKey: string,
  defaultExpiredValue: boolean,
): ExpiringLocalStorage => {
  const safeStorage = useMemo(() => new SafeStorage(), []);

  const hasKeyExpired = useMemo((): boolean => {
    try {
      const expiryISOString = safeStorage.get(localStorageKey);

      const expiry = DateTime.fromISO(expiryISOString);

      if (!expiry.isValid) {
        return defaultExpiredValue;
      }

      if (DateTime.now() > expiry) {
        safeStorage.remove(localStorageKey);

        return true;
      } else {
        return false;
      }
    } catch (e) {
      safeStorage._handleError(e);

      return defaultExpiredValue;
    }
  }, [localStorageKey, safeStorage, defaultExpiredValue]);

  const setKeyWithExpiry = useCallback(
    (expiresIn: number) => {
      const expiry = DateTime.now().plus({ days: expiresIn }).toISO();
      safeStorage.set(localStorageKey, expiry);
    },
    [localStorageKey, safeStorage],
  );

  return { hasKeyExpired, setKeyWithExpiry };
};
