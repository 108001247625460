import { useCallback, useMemo } from 'react';
import { MutationHookOptions, useMutation } from '@apollo/client';
import { ID } from '../../models/Element';
import {
  GENERATE_OTP_BACKUP_CODES_QUERY,
  GENERATE_OTP_QUERY,
  REMOVE_TWO_FACTOR_AUTH_MUTATION,
  buildVerifyOTPBackupCodeMutation,
  buildVerifyOTPMutation,
} from '../../queries/auth';

interface UseOTPAuthProps<AuthUser> {
  signOut?: () => Promise<void>;
  finishLogin?: (data: any) => AuthUser;
  projectName?: string;
  userQueryObject?: Record<string, any>;
}

interface UseOTPAuthReturn<AuthUser> {
  generateOTP: (secondFactorAuthToken?: string) => Promise<Record<string, any>>;
  verifyOTP: (otp: string, secondFactorAuthToken?: string) => Promise<AuthUser>;
  generateOTPBackupCodes: () => Promise<Record<string, any>>;
  verifyOTPBackupCode: (
    token: string,
    secondFactorAuthToken?: string,
  ) => Promise<AuthUser>;
  removeTwoFactorAuth: ({
    token,
    userId,
  }: {
    token?: string;
    userId?: ID;
  }) => Promise<Record<string, any>>;
}

export const useOTPAuth = <AuthUser>({
  signOut,
  finishLogin,
  projectName,
  userQueryObject,
}: UseOTPAuthProps<AuthUser>): UseOTPAuthReturn<AuthUser> => {
  const queryOptions: MutationHookOptions = useMemo(
    () => ({
      context: {
        projectQuery: true,
        projectName: projectName,
        authQuery: true,
      },
      errorPolicy: 'all',
      nextFetchPolicy: 'no-cache',
    }),
    [projectName],
  );

  const verifyOTPQuery = buildVerifyOTPMutation(userQueryObject);
  const verifyOTPBackupCodeQuery =
    buildVerifyOTPBackupCodeMutation(userQueryObject);

  const [generateOTPMutation] = useMutation(GENERATE_OTP_QUERY, queryOptions);
  const [verifyOTPMutation] = useMutation(verifyOTPQuery, queryOptions);
  const [generateOTPBackupCodesMutation] = useMutation(
    GENERATE_OTP_BACKUP_CODES_QUERY,
    queryOptions,
  );
  const [verifyOTPBackupCodeMutation] = useMutation(
    verifyOTPBackupCodeQuery,
    queryOptions,
  );

  const [removeTwoFactorAuthMutation] = useMutation(
    REMOVE_TWO_FACTOR_AUTH_MUTATION,
    queryOptions,
  );

  const generateOTP = useCallback(
    async (secondFactorAuthToken?: string) =>
      await generateOTPMutation({
        context: {
          ...queryOptions.context,
          secondFactorAuthToken,
        },
      }).then(({ data, errors }) => {
        if (errors && !data?.generateOTP) {
          throw errors;
        }

        return data.generateOTP;
      }),
    [queryOptions, generateOTPMutation],
  );

  const verifyOTP = useCallback(
    (token: string, secondFactorAuthToken?: string) => {
      if (!token) {
        throw new Error('OTP token is required');
      }

      return verifyOTPMutation({
        variables: { token },
        context: {
          ...queryOptions.context,
          secondFactorAuthToken,
        },
      }).then(({ data, errors }) => {
        if (errors && !data.verifyOTP) {
          throw errors;
        }

        if (signOut && finishLogin) {
          return signOut().then(() => finishLogin(data.verifyOTP));
        }

        return data.verifyOTP;
      });
    },
    [queryOptions, verifyOTPMutation, finishLogin, signOut],
  );

  const generateOTPBackupCodes = useCallback(
    () =>
      generateOTPBackupCodesMutation().then(({ data, errors }) => {
        if (errors && !data.generateOTPBackupCodes) {
          throw errors;
        }

        return data.generateOTPBackupCodes;
      }),
    [generateOTPBackupCodesMutation],
  );

  const verifyOTPBackupCode = useCallback(
    (code: string, secondFactorAuthToken?: string) =>
      verifyOTPBackupCodeMutation({
        variables: { code },
        context: {
          ...queryOptions.context,
          secondFactorAuthToken,
        },
      }).then(({ data, errors }) => {
        if (errors && !data.verifyOTPBackupCode) {
          throw errors;
        }

        if (signOut && finishLogin) {
          return signOut().then(() => finishLogin(data.verifyOTPBackupCode));
        }

        return data.verifyOTPBackupCode;
      }),
    [queryOptions, signOut, finishLogin, verifyOTPBackupCodeMutation],
  );

  const removeTwoFactorAuth = useCallback(
    ({ token, userId }: { token?: string; userId?: ID }) =>
      removeTwoFactorAuthMutation({
        variables: { token, userId },
      }).then(({ data, errors }) => {
        if (errors && !data.removeTwoFactorAuth) {
          throw errors;
        }

        return data.removeTwoFactorAuth;
      }),
    [removeTwoFactorAuthMutation],
  );

  return {
    generateOTP,
    verifyOTP,
    generateOTPBackupCodes,
    verifyOTPBackupCode,
    removeTwoFactorAuth,
  };
};
