import { forwardRef } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { ErrorText } from '@noloco/components';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import { validationBorder } from '@noloco/components/src/utils/validation';
import HelpText from './sections/HelpText';

interface Props {
  checked?: boolean;
  className?: string;
  loading?: boolean;
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  children?: React.ReactNode;
  rounded?: boolean;
}

const sizeHeightWidthMap = {
  [SM]: 'h-3 w-3',
  [MD]: 'h-5 w-5',
  [LG]: 'h-6 w-6',
  [XL]: 'h-8 w-8',
};

const Checkbox = forwardRef<any, Props>(
  (
    {
      className = '',
      children,
      checked,
      // @ts-expect-error TS(2339): Property 'elementId' does not exist on type 'Props... Remove this comment to see the full error message
      elementId,
      // @ts-expect-error TS(2339): Property 'size' does not exist on type 'Props'.
      size = MD,
      // @ts-expect-error TS(2339): Property 'validationError' does not exist on type ... Remove this comment to see the full error message
      validationError,
      // @ts-expect-error TS(2339): Property 'value' does not exist on type 'Props'.
      value,
      // @ts-expect-error TS(2339): Property 'readOnly' does not exist on type 'Props'... Remove this comment to see the full error message
      readOnly,
      // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'Props'... Remove this comment to see the full error message
      disabled,
      onClick,
      onChange,
      // @ts-expect-error TS(2339): Property 'tabIndex' does not exist on type 'Props'... Remove this comment to see the full error message
      tabIndex,
      // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'Props'.
      theme,
      // @ts-expect-error TS(2339): Property 'help' does not exist on type 'Props'.
      help,
      rounded = false,
      ...rest
    },
    ref,
  ) => (
    <>
      <Box
        is="label"
        className={classNames('checkbox', className)}
        ref={ref}
        onClick={onClick}
        {...rest}
      >
        <input
          type="checkbox"
          data-testid={elementId}
          id={`${elementId}-checkbox`}
          name={`${elementId}-checkbox`}
          disabled={disabled}
          readOnly={readOnly}
          tabIndex={tabIndex || undefined}
          checked={checked || value === true}
          value={value}
          className={classNames(
            `flex-shrink-0 select-none appearance-none border border-gray-300 bg-white dark:border-gray-700 dark:bg-gray-500 text-${theme.brandColors.primary} ${sizeHeightWidthMap[size]}`,
            {
              'cursor-not-allowed': disabled,
              'rounded-full': rounded,
              'rounded-md': size !== SM && !rounded,
              'rounded-sm': size === SM && !rounded,
            },
            validationBorder(validationError),
          )}
          onChange={onChange}
        />
        {(children || help) && (
          <div className="flex max-w-full flex-col">
            {children && (
              <span
                className={classNames('ml-4 max-w-full overflow-hidden', {
                  'cursor-not-allowed opacity-75': disabled,
                })}
              >
                {children}
              </span>
            )}
            {help && (
              <div className="ml-4 max-w-full">
                <HelpText>{help}</HelpText>
              </div>
            )}
          </div>
        )}
      </Box>
      {validationError && <ErrorText>{validationError}</ErrorText>}
    </>
  ),
);

export default withTheme(Checkbox);
