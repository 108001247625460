import React, { useCallback, useEffect, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { NumericFormat } from 'react-number-format';
import { validationBorder } from '../../utils';
import useLocale from '../../utils/hooks/useLocale';
import ErrorText from '../form/ErrorText';
import { TextInput } from './TextInput';

const FormattedNumberInput = ({
  id,
  is,
  autoFocus,
  className,
  decimalScale,
  validationError,
  value,
  onBlur,
  onFocus,
  onChange,
  prefix,
  suffix,
  style,
  styleObj,
  surface,
  thousandSeparator,
  ...rest
}: any) => {
  const locale = useLocale();
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleOnChange = useCallback(
    (nextValue: any) => {
      const changedValue = get(nextValue, 'floatValue', null);
      setLocalValue(changedValue);

      if (changedValue !== localValue) {
        if (onChange) {
          onChange(changedValue);
        }
      }
    },
    [localValue, onChange],
  );

  return (
    <>
      <NumericFormat
        id={id}
        autoFocus={autoFocus}
        className={classNames(className, validationBorder(validationError))}
        customInput={is}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        value={isNil(localValue) ? '' : localValue}
        displayType="input"
        onBlur={onBlur}
        onFocus={onFocus}
        onValueChange={handleOnChange}
        decimalSeparator={(locale as any).decimalSeparator}
        thousandSeparator={
          thousandSeparator ? (locale as any).thousandSeparator : false
        }
        style={style}
        styleObj={styleObj}
        surface={surface}
        prefix={prefix}
        suffix={suffix}
        {...rest}
      />
      {validationError && <ErrorText>{validationError}</ErrorText>}
    </>
  );
};

FormattedNumberInput.defaultProps = {
  is: withTheme(TextInput),
};

export default FormattedNumberInput;
