import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import upperFirst from 'lodash/upperFirst';
import shortId from 'shortid';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  CARDS,
  ROWS,
  TABLE,
} from '@noloco/core/src/constants/collectionLayouts';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import {
  DATE,
  DECIMAL,
  INTEGER,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  COLLECTION,
  DETAILS,
  HIGHLIGHTS,
  STAGES,
  VIEW,
} from '@noloco/core/src/constants/elements';
import { ASC } from '@noloco/core/src/constants/orderByDirections';
import { DATABASE } from '@noloco/core/src/constants/scopeTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import { filterDefaultFields } from '@noloco/core/src/utils/defaultFields';
import { getText } from '@noloco/core/src/utils/lang';
import { getPagesConfig } from '@noloco/core/src/utils/pages';
import { isMultiRelationship } from '@noloco/core/src/utils/relationships';
import { getDefaultPropsForSectionType } from '@noloco/core/src/utils/sections';

const layoutsToUse = [ROWS, CARDS, TABLE];

const iconsToUse = [
  'Atom',
  'Atom2',
  'Basket',
  'Book',
  'Bookmark',
  'Calendar',
  'ChartBar',
  'ChartDonut',
  'ClipboardList',
  'CloudUpload',
  'FileInvoice',
  'Flask',
  'Folder',
  'Home',
  'Lifebuoy',
  'Mail',
  'Notes',
  'Receipt',
  'Rocket',
];

export const getRandomPageIcon = () =>
  iconsToUse[Math.floor(Math.random() * iconsToUse.length)];

const LANG_KEY = 'elements.VIEW.generateLayout';

// @ts-expect-error TS(7023): 'getUniqueRoutePath' implicitly has return type 'a... Remove this comment to see the full error message
export const getUniqueRoutePath = (
  routePath: any,
  project: Project,
  iteration = 0,
) => {
  const formatedRoutePath = `${kebabCase(routePath).toLowerCase()}${
    iteration > 0 ? `-${iteration}` : ''
  }`;
  const { isV2, pagesPath } = getPagesConfig(
    project.elements,
    project.settings,
  );
  const pages = isV2 ? project.elements : get(project.elements, pagesPath);
  const hasConflict = pages.some(
    (page: any) => get(page, 'props.routePath') === formatedRoutePath,
  );

  return hasConflict
    ? getUniqueRoutePath(routePath, project, iteration + 1)
    : formatedRoutePath;
};

const MAX_DEFAULT_SECTION_FIELDS = 10;
const MAX_RELATED_COLLECTIONS = 4;

export const getFieldsForDetailsSection = (
  fields: any,
  limit = MAX_DEFAULT_SECTION_FIELDS,
  customFilter: (field: DataField) => boolean = () => true,
) =>
  fields
    .filter(filterDefaultFields)
    .filter(
      (field: any) =>
        !isMultiRelationship(field.relationship) &&
        customFilter(field) &&
        !field.hidden,
    )
    .slice(0, limit)
    // @ts-expect-error TS(7006): Parameter 'field' implicitly has an 'any' type.
    .map((field, idx) => ({
      name: field.name,
      label: { value: upperFirst(field.display) },
      ...(idx < 2 ? { columnWidth: 6 } : {}),
    }));

const fieldDataItem = (id: string, field: DataField) => ({
  id: `${id}:${VIEW}`,
  path: field.name,
  source: DATABASE,
  dataType: field.type,
  display: field.display,
});

export const generateViewFromDataSource = (
  displayName: string,
  dataType: DataType,
  project: Project,
  meta = {},
  sectionMeta = {},
  layout = TABLE,
  includeDefaultFields = false,
  layoutConfig: any = {},
) => {
  const fields = dataType.fields.filter(
    (field) =>
      (includeDefaultFields || filterDefaultFields(field)) && !field.internal,
  );
  const id = shortId.generate();

  const singleSelectField = dataType.fields.find(
    (field: any) => field.type === SINGLE_OPTION,
  );

  const highlightFields = dataType.fields
    .filter(
      (field: any) =>
        (field.type === DECIMAL ||
          field.type === INTEGER ||
          field.type === DATE) &&
        filterDefaultFields(field),
    )
    .slice(0, 2);

  const firstFileField = dataType.fields.find(
    (field: any) => field.type === FILE,
  );

  const firstRelatedField = dataType.fields.find(
    (field: any) =>
      field.relationship &&
      !isMultiRelationship(field.relationship) &&
      field.type !== FILE,
  );

  const firstRelatedFieldType =
    firstRelatedField && project.dataTypes.getByName(firstRelatedField.type);

  const title = layoutConfig.title || upperFirst(displayName);
  const newButtonLabel = layoutConfig.newButtonLabel || undefined;
  const newRecordPageTitle =
    layoutConfig.newRecordPageTitle ||
    getText({ dataType: upperFirst(dataType.display) }, LANG_KEY, 'new');
  const recordPageTitle =
    layoutConfig.recordPageTitle ||
    (dataType.primaryField?.name
      ? dataType.primaryField.name
      : get(
          fields.filter(
            (field: any) => field.type === TEXT || field.type === INTEGER,
          ),
          '0.name',
        ));

  const dateStartField = layoutConfig.startDate
    ? dataType.fields.getByName(layoutConfig.startDate)
    : undefined;
  const dateEndField = layoutConfig.endDate
    ? dataType.fields.getByName(layoutConfig.endDate)
    : undefined;

  const latitudeField = layoutConfig.latitude
    ? dataType.fields.getByName(layoutConfig.latitude)
    : undefined;
  const longitudeField = layoutConfig.longitude
    ? dataType.fields.getByName(layoutConfig.longitude)
    : undefined;

  const groupByField = layoutConfig.groupBy
    ? dataType.fields.getByName(layoutConfig.groupBy)
    : undefined;

  return {
    id,
    type: VIEW,
    props: {
      name: displayName,
      icon: { name: getRandomPageIcon() },
      routePath: getUniqueRoutePath(displayName, project),
      layout,
      dataList: {
        dataSource: INTERNAL,
        dataType: dataType.name,
        limit: [{ text: '30' }],
        showPagination: true,
      },
      ...(dateStartField
        ? { dateStart: fieldDataItem(id, dateStartField) }
        : {}),
      ...(dateEndField ? { dateEnd: fieldDataItem(id, dateEndField) } : {}),
      fields: fields.slice(0, 10).map((field: any) => ({
        name: field.name,
        label: { value: upperFirst(field.display) },
      })),
      groups: [
        ...(groupByField
          ? [
              {
                field: fieldDataItem(id, groupByField),
                id: shortId.generate(),
                sort: ASC,
              },
            ]
          : []),
      ],
      ...(latitudeField && longitudeField
        ? {
            map: {
              latitude: fieldDataItem(id, latitudeField),
              longitude: fieldDataItem(id, longitudeField),
            },
          }
        : {}),
      title: [{ text: title }],
      newButtonText: newButtonLabel ? [{ text: newButtonLabel }] : undefined,
      record: {
        title: recordPageTitle,
        sections: [
          ...(singleSelectField
            ? [
                {
                  id: shortId.generate(),
                  type: STAGES,
                  props: {
                    stages: {
                      id: `${id}:VIEW`,
                      path: singleSelectField.name,
                    },
                  },
                  meta: sectionMeta,
                },
              ]
            : []),
          ...(highlightFields.length > 0
            ? [
                {
                  id: shortId.generate(),
                  type: HIGHLIGHTS,
                  props: {
                    fields: getFieldsForDetailsSection(
                      highlightFields,
                      undefined,
                    ),
                  },
                  meta: sectionMeta,
                },
              ]
            : []),
          {
            id: shortId.generate(),
            type: DETAILS,
            props: {
              fields: getFieldsForDetailsSection(
                fields,
                undefined,
                (field: DataField) =>
                  field.type !== FILE &&
                  (!firstRelatedField || field.id !== firstRelatedField.id) &&
                  (!singleSelectField || field.id !== singleSelectField.id) &&
                  !highlightFields.includes(field),
              ),
            },
            meta: sectionMeta,
          },
          ...(firstFileField
            ? [
                {
                  id: shortId.generate(),
                  type: DETAILS,
                  props: {
                    fields: [
                      {
                        name: firstFileField.name,
                        label: { value: [], hidden: true },
                        columnWidth: 12,
                      },
                    ],
                    title: {
                      value: [{ text: firstFileField.display }],
                    },
                  },
                  meta: sectionMeta,
                },
              ]
            : []),
          ...(firstRelatedField && firstRelatedFieldType
            ? [
                {
                  id: shortId.generate(),
                  type: DETAILS,
                  props: {
                    fields: getFieldsForDetailsSection(
                      firstRelatedFieldType.fields,
                      4,
                      (field: DataField) => field.type !== FILE,
                    ),
                    rootField: firstRelatedField.name,
                    title: {
                      value: [{ text: firstRelatedField.display }],
                    },
                  },
                  meta: sectionMeta,
                },
              ]
            : []),
          ...fields
            .filter(
              (field: any) =>
                field.type !== FILE && isMultiRelationship(field.relationship),
            )
            .slice(0, MAX_RELATED_COLLECTIONS)
            .map((field: any, fieldIndex: any) => {
              const relatedType = project.dataTypes.getByName(field.type);

              if (relatedType) {
                const defaultProps = getDefaultPropsForSectionType(COLLECTION);

                return {
                  id: shortId.generate(),
                  type: COLLECTION,
                  props: {
                    layout: layoutsToUse[fieldIndex % layoutsToUse.length],
                    dataList: {
                      ...defaultProps.dataList,
                      dataSource: INTERNAL,
                      dataType: relatedType.name,
                      filter: {
                        id: `${id}:VIEW`,
                        dataSource: DATABASE,
                        dataType: relatedType.name,
                        path: field.name,
                      },
                    },
                    fields: relatedType.fields
                      .filter(filterDefaultFields)
                      .slice(0, 10)
                      .map((field: any) => ({
                        name: field.name,
                        label: { value: upperFirst(field.display) },
                      })),
                    title: [{ text: upperFirst(field.display) }],
                  },
                  meta: sectionMeta,
                };
              }

              return null;
            })
            .filter(Boolean),
        ],
        comments: {
          show: true,
        },
      },
      new: {
        title: [
          {
            text: newRecordPageTitle,
          },
        ],
        fields: fields
          .filter((field: any) => !field.readOnly)
          .slice(0, 10)
          .map((field: any) => ({
            name: field.name,
            label: upperFirst(field.display),
          })),
      },
    },
    meta,
  };
};
