import { useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { ShirtSize } from '@noloco/components/src/constants/tShirtSizes';
import {
  BOARD,
  CARDS,
  CHECKLIST,
  COLUMNS,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import '../../../utils/data';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDraggableCollectionGroups from '../../../utils/hooks/useDraggableCollectionGroups';
import { getText } from '../../../utils/lang';
import { COLLECTION_WRAPPER_STYLES } from '../Collection';
import DraggableRow from './DraggableRow';
import DroppableGroup from './DroppableBoardGroup';
import GroupHeader from './layouts/GroupHeader';

export const GROUP_WRAPPER_STYLES = {
  [ROWS]: (): string => 'flex flex-col w-full flex-shrink-0',
  [CARDS]: (): string =>
    'flex flex-col w-full col-span-3 md:col-span-1 flex-shrink-0',
  [COLUMNS]: (): string => 'flex flex-col w-full',
  [BOARD]: ({
    isCollapsed,
    shouldShowBoardSummary,
  }: {
    isCollapsed: boolean;
    shouldShowBoardSummary: boolean;
  }): string =>
    `flex flex-col h-full flex-shrink-0 ${
      isCollapsed
        ? shouldShowBoardSummary
          ? 'w-16'
          : 'w-12'
        : 'w-full max-w-xs'
    }`,
  [TABLE]: ({ isDarkModeEnabled }: { isDarkModeEnabled: boolean }): string =>
    `w-full divide-y  ${
      isDarkModeEnabled ? darkModeColors.divides.two : 'divide-gray-200'
    }`,
  [TABLE_FULL]: ({
    isDarkModeEnabled,
  }: {
    isDarkModeEnabled: boolean;
  }): string =>
    `w-full divide-y  ${
      isDarkModeEnabled ? darkModeColors.divides.two : 'divide-gray-200'
    }`,
  [SPLIT]: (): string => 'flex flex-col w-full flex-shrink-0',
};

interface RecordsWrapperStylesProps {
  cardSize?: ShirtSize;
  isDarkModeEnabled?: boolean;
}

const RECORDS_WRAPPER_STYLES: Record<
  string,
  (_props?: RecordsWrapperStylesProps) => string
> = {
  [ROWS]: ({ isDarkModeEnabled } = {}) =>
    `divide-y ${
      isDarkModeEnabled ? darkModeColors.divides.one : 'divide-gray-200'
    }`,
  [CARDS]: ({ cardSize } = {}) =>
    COLLECTION_WRAPPER_STYLES[CARDS]({ cardSize }),
  [COLUMNS]: () => COLLECTION_WRAPPER_STYLES[COLUMNS](),
  [BOARD]: () => 'mt-4 space-y-2 flex flex-col min-h-96 h-full overflow-auto',
  [TABLE]: () => '',
  [TABLE_FULL]: () => '',
  [SPLIT]: ({ isDarkModeEnabled } = {}) =>
    `divide-y ${
      isDarkModeEnabled ? darkModeColors.divides.one : 'divide-gray-200'
    }`,
};

RECORDS_WRAPPER_STYLES[CHECKLIST] = RECORDS_WRAPPER_STYLES[ROWS];

const BASE_EMPTY_STATE_STYLES = (isDarkModeEnabled = false) =>
  `p-2 text-xs rounded-lg text-center font-medium uppercase tracking-wider hover:shadow-xs border ${
    isDarkModeEnabled
      ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
      : 'bg-white text-gray-600'
  }`;

export const EMPTY_STATE_STYLES = {
  [ROWS]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider text-center p-6 ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
    }`,
  [CARDS]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(
      isDarkModeEnabled,
    )} col-span-3 md:col-span-1 py-5`,
  [COLUMNS]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(isDarkModeEnabled)} w-full py-5`,
  [BOARD]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(isDarkModeEnabled)} mb-8`,
  [TABLE]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600 '
    }`,
  [TABLE_FULL]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider ${
      isDarkModeEnabled ? darkModeColors.text.secondary : ' text-gray-600'
    }`,
  [SPLIT]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider text-center p-6 ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
    }`,
};

EMPTY_STATE_STYLES[CHECKLIST] = EMPTY_STATE_STYLES[ROWS];

const DraggableGroupView = ({
  bulkActionsEnabled,
  cardSize,
  className,
  customFilters,
  dataType,
  dataTypes,
  edges,
  elementId,
  enableDragAndDropEdit,
  fields,
  groupByFields,
  groupOptions,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  nodeQueryObject,
  Row,
  selectedRows,
  setSelectedRows,
  summary,
  theme,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const {
    canUpdateViaDrag,
    groupOpenStates,
    groupCollapsedStates,
    toggleGroupState,
    toggleGroupCollapsedState,
    handleCardDrop,
    isLimited,
    firstSummaryIndex,
    visibleGroups,
  } = useDraggableCollectionGroups({
    customFilters,
    dataType,
    edges,
    elementId,
    fields,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    nodeQueryObject,
    enableDragAndDropEdit,
  });

  const isGroupCollapsed = useCallback(
    (groupKey: any) => groupCollapsedStates[groupKey],
    [groupCollapsedStates],
  );

  const shouldShowBoardSummary = useCallback(
    (rows: any) =>
      layout === BOARD && summary && summary.field && rows.length > 0,
    [layout, summary],
  );

  return (
    <>
      {visibleGroups.map((group) => (
        <DroppableGroup
          className={classNames(
            className,
            GROUP_WRAPPER_STYLES[layout]({
              isCollapsed: isGroupCollapsed(group.key),
              isDarkModeEnabled,
              shouldShowBoardSummary: shouldShowBoardSummary(group.rows),
            }),
          )}
          dataTestid="collection-group"
          group={group}
          key={group.key}
          onDrop={handleCardDrop}
        >
          {({ isOver }: any) => (
            <>
              <GroupHeader
                bulkActionsEnabled={bulkActionsEnabled}
                dataType={dataType}
                dataTypes={dataTypes}
                fields={fields}
                firstSummaryIndex={firstSummaryIndex}
                group={group}
                isCollapsed={!!isGroupCollapsed(group.key)}
                isTable={false}
                layout={layout}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                summary={summary}
                toggleGroupCollapsedState={toggleGroupCollapsedState}
              />
              {!isGroupCollapsed(group.key) && (
                <div
                  className={classNames(
                    RECORDS_WRAPPER_STYLES[layout]({
                      ...(layout === CARDS && get(group, 'rows.length', 0) > 0
                        ? { cardSize }
                        : {}),
                      isDarkModeEnabled,
                    }),
                    {
                      [`ring ring-${theme.brandColorGroups.primary}-500`]:
                        isOver,
                      'rounded-lg':
                        isOver &&
                        (layout === CARDS ||
                          layout === BOARD ||
                          layout === COLUMNS),
                      'm-1':
                        isOver &&
                        (layout === COLUMNS ||
                          layout === ROWS ||
                          layout === SPLIT ||
                          layout === CHECKLIST),
                    },
                  )}
                >
                  {group.rows
                    ?.slice(
                      0,
                      !groupOpenStates[group.key] && isLimited
                        ? limitPerGroup
                        : undefined,
                    )
                    .map((edge) => (
                      <DraggableRow
                        canUpdateViaDrag={canUpdateViaDrag}
                        cardSize={cardSize}
                        edge={edge}
                        group={group}
                        isOver={isOver}
                        isTable={false}
                        key={edge.node.id}
                        layout={layout}
                        Row={Row}
                      />
                    ))}
                  {isLimited &&
                    group.rows &&
                    group.rows.length > limitPerGroup && (
                      <div className="sticky bottom-0 flex w-full">
                        <button
                          className="hover:shadow-xs mx-2 mb-4 w-full rounded-lg border bg-white p-2 text-center text-xs font-medium uppercase tracking-wider text-gray-600 shadow-lg hover:text-gray-800"
                          onClick={toggleGroupState(group.key)}
                        >
                          {getText(
                            'core.COLLECTION.groups.limit',
                            groupOpenStates[group.key] ? 'showLess' : 'showAll',
                          )}
                        </button>
                      </div>
                    )}
                  {group.rows?.length === 0 && (
                    <div
                      className={classNames(
                        EMPTY_STATE_STYLES[layout](isDarkModeEnabled),
                      )}
                    >
                      <span>{getText('core.COLLECTION.groups.empty')}</span>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </DroppableGroup>
      ))}
    </>
  );
};

export default withTheme(DraggableGroupView);
