import React, { forwardRef, memo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import YouTube from 'react-youtube';
import { extractVideoId } from './utils/videoUtils';

const urlPatterns = [
  '(https:\\/\\/www\\.)?youtube\\.com\\/watch\\?v=([a-zA-Z0-9_-]+)',
  '(https:\\/\\/)?youtu\\.be\\/([a-zA-Z0-9_-]+)',
  '(https:\\/\\/www\\.)?youtube\\.com\\/embed\\/([a-zA-Z0-9_-]+)',
];

const YoutubeVideoElement = memo(
  // @ts-expect-error TS(2339): Property 'autoPlay' does not exist on type '{}'.
  ({ autoPlay, showControls, startSeconds, videoId }) => (
    <>
      <YouTube
        className="absolute left-0 top-0 h-full w-full"
        videoId={extractVideoId(videoId, urlPatterns)}
        opts={{
          height: '100%',
          width: '100%',
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: autoPlay ? 1 : 0,
            controls: showControls ? 1 : 0,
            start: startSeconds,
          },
        }}
      />
    </>
  ),
);

interface YoutubeVideoProps {
  className?: string;
  autoPlay?: boolean;
  videoId: number | string;
  showControls?: boolean;
  startSeconds?: number;
}

const YoutubeVideo = forwardRef<any, YoutubeVideoProps>(
  (
    {
      className,
      autoPlay,
      // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'Youtube... Remove this comment to see the full error message
      onClick,
      showControls,
      startSeconds,
      // @ts-expect-error TS(2339): Property 'style' does not exist on type 'YoutubeVi... Remove this comment to see the full error message
      style,
      videoId,
    },
    ref,
  ) => (
    <Box ref={ref} className={className} style={style} onClick={onClick}>
      <div className="pb-fluid-video relative h-0">
        <YoutubeVideoElement
          // @ts-expect-error TS(2322): Type '{ autoPlay: boolean | undefined; showControl... Remove this comment to see the full error message
          autoPlay={autoPlay}
          showControls={showControls}
          startSeconds={startSeconds}
          videoId={extractVideoId(videoId.toString(), urlPatterns)}
        />
      </div>
    </Box>
  ),
);

YoutubeVideo.defaultProps = {
  autoPlay: false,
  // @ts-expect-error TS(2322): Type '{ autoPlay: false; children: undefined; clas... Remove this comment to see the full error message
  children: undefined,
  className: '',
  videoId: 'EngW7tLk6R8', // 'https://www.youtube.com/watch?v=EngW7tLk6R8'
  showControls: true,
  startSeconds: 0,
};

export default YoutubeVideo;
