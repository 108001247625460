import React, { forwardRef, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Loader } from '@noloco/components';
import {
  ElementRenderer,
  getElementPath,
} from '@noloco/ui/src/components/canvas/ProjectRenderer';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { CUSTOM_VISIBILITY_RULES } from '../constants/features';
import { DATABASE } from '../constants/scopeTypes';
import DataTypes, { DataType } from '../models/DataTypes';
import { Element, ViewTab } from '../models/Element';
import { Project } from '../models/Project';
import { BLANK_QUERY_STRING, getPageQueryString } from '../queries/project';
import { scopeSelector } from '../selectors/dataSelectors';
import {
  findDependentValues,
  formatValue,
  transformDepsToQueryObject,
} from '../utils/data';
import { updateScope } from '../utils/elementScope';
import { isElementVisible } from '../utils/elementVisibility';
import useAuthWrapper from '../utils/hooks/useAuthWrapper';
import usePagePathname from '../utils/hooks/usePagePathname';
import useRouter from '../utils/hooks/useRouter';
import { useInvalidateProjectData } from '../utils/hooks/useServerEvents';
import useSetDocumentTitle from '../utils/hooks/useSetDocumentTitle';
import useSetQuery from '../utils/hooks/useSetQuery';
import { getText } from '../utils/lang';
import { getPagePath, getPagesConfig } from '../utils/pages';
import { transformColumnarScope } from '../utils/scope';
import BlankPage from './sections/view/BlankPage';

export const getPageScope = (
  loading: boolean,
  pageData: any,
  dataType: DataType,
  dataTypes: DataTypes,
) => ({
  ...transformColumnarScope(pageData, dataType, dataTypes),
  loading,
});

export const getPageLoadingScope = (
  existingScope: Record<string, any>,
  elementId: string,
  loading: boolean,
) => ({
  ...existingScope,
  [elementId]: {
    loading,
  },
});

const QueryRouteResult = ({
  children,
  error,
  pageData,
  dataType,
  dataTypes,
  elementId,
  loading,
  scope,
  showLoading,
  refetch,
}: any) => {
  const pageScope = useMemo(
    () => pageData && getPageScope(false, pageData, dataType, dataTypes),
    [dataType, dataTypes, pageData],
  );
  const nextScope = useMemo(
    () => getPageLoadingScope(scope, elementId, loading),
    [elementId, loading, scope],
  );

  if (loading && showLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center py-24">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {(error || (!pageData && !loading)) && (
        <div className="absolute left-0 right-0 top-0 p-8">
          <div className="w-full rounded border border-red-300 bg-red-200 p-4 text-red-900">
            {error && <pre>{String(error)}</pre>}
            {!pageData && (
              <span>
                {getText({ dataType: dataType.display }, 'errors.notFound')}
              </span>
            )}
          </div>
        </div>
      )}
      {updateScope(
        children({ data: pageScope, loading, error, refetch }),
        nextScope,
        {
          loading,
        },
      )}
    </>
  );
};

interface QueryRouteProps {
  children?: React.ReactNode;
}

export const QueryRoute = forwardRef<unknown, QueryRouteProps>(
  (
    {
      children,
      // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
      dataType,
      // @ts-expect-error TS(2339): Property 'dataTypes' does not exist on type '{}'.
      dataTypes,
      // @ts-expect-error TS(2339): Property 'dataProperty' does not exist on type '{}... Remove this comment to see the full error message
      dataProperty,
      // @ts-expect-error TS(2339): Property 'dependencies' does not exist on type '{}... Remove this comment to see the full error message
      dependencies,
      // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
      element,
      // @ts-expect-error TS(2339): Property 'property' does not exist on type '{}'.
      property,
      // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
      project,
      // @ts-expect-error TS(2339): Property 'recordId' does not exist on type '{}'.
      recordId,
      // @ts-expect-error TS(2339): Property 'showLoading' does not exist on type '{}'... Remove this comment to see the full error message
      showLoading = true,
      // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
      scope,
    },
    ref,
  ) => {
    const { query } = useRouter();

    const pageTypeDeps = useMemo(
      () => dependencies.filter((dep: any) => dep.path),
      [dependencies],
    );

    const pageDataType = useMemo(
      () => project.dataTypes.getByName(dataType),
      [dataType, project.dataTypes],
    );
    const urlField = useMemo(
      () =>
        pageDataType && property && pageDataType.fields.getByName(dataProperty),
      [dataProperty, pageDataType, property],
    );

    const rawValue = recordId || query[property];

    const variables = useMemo(
      () => ({
        [dataProperty]: urlField
          ? // @ts-expect-error TS(2554): Expected 5-6 arguments, but got 2.
            formatValue(rawValue, urlField.type)
          : rawValue,
      }),
      [dataProperty, rawValue, urlField],
    );

    if (pageDataType && pageTypeDeps.length === 0) {
      pageDataType.fields
        .filter((f: any) => f.unique)
        .forEach((field: any) => {
          pageTypeDeps.push({
            path: field.name,
            source: DATABASE,
            type: field.type,
            id: element.id,
          });
        });
    }

    const gqlQueryString = useMemo(() => {
      const dataTypes = project.dataTypes;

      return pageDataType
        ? getPageQueryString(
            pageDataType.apiName,
            dataProperty
              ? { [dataProperty]: variables[dataProperty] }
              : undefined,
            transformDepsToQueryObject(pageDataType, dataTypes, pageTypeDeps),
          )
        : BLANK_QUERY_STRING;
    }, [
      dataProperty,
      pageDataType,
      pageTypeDeps,
      project.dataTypes,
      variables,
    ]);

    const queryObject = useMemo(
      () => ({
        id: element.id,
        variables,
        dataType,
        dataProperty,
        type: element.type,
        query: gqlQueryString,
      }),
      [
        dataProperty,
        dataType,
        element.id,
        element.type,
        gqlQueryString,
        variables,
      ],
    );
    useSetQuery(queryObject);
    const skip = useMemo(
      () =>
        !pageDataType || pageTypeDeps.length === 0 || rawValue === undefined,
      [pageDataType, pageTypeDeps.length, rawValue],
    );

    const { loading, error, data, refetch } = useQuery(
      gql`
        ${gqlQueryString}
      `,
      {
        skip,
        variables,
        errorPolicy: 'all',
        context: {
          projectQuery: true,
          projectName: project.name,
        },
      },
    );
    useInvalidateProjectData(refetch, {
      skip,
    });

    const pageData = useMemo(
      () => pageDataType && get(data, pageDataType.apiName),
      [data, pageDataType],
    );

    if (error) {
      console.log(error);
    }

    if (!pageDataType) {
      return (
        <div className="my-4 w-full rounded bg-red-500 p-4 text-white">
          {error?.message || getText({ dataType }, 'errors.page')}
        </div>
      );
    }

    return (
      <QueryRouteResult
        children={children}
        error={error}
        pageData={pageData}
        dataType={pageDataType}
        dataTypes={dataTypes}
        elementId={element.id}
        loading={loading}
        ref={ref}
        refetch={refetch}
        scope={scope}
        showLoading={showLoading}
      />
    );
  },
);

interface PageProps {
  auth?: boolean;
  children?: React.ReactNode;
  dataProperty?: string;
  dataType?: string;
  editorMode?: boolean;
  elementId: string;
  elementPath: (string | number)[];
  headerWidth?: number | string;
  parentPage: string | undefined | null;
  project: Project;
  routePath: string;
  sections?: Element[];
  subtitle?: string;
  tabs?: ViewTab[];
  title?: string;
  V2?: boolean;
}

const Page = forwardRef<React.ForwardedRef<HTMLDivElement>, PageProps>(
  (
    {
      auth = false,
      children,
      dataProperty,
      dataType,
      editorMode = false,
      elementId,
      elementPath,
      headerWidth,
      parentPage,
      project,
      routePath,
      sections = [],
      subtitle,
      tabs = [],
      title,
      V2 = false,
      ...rest
    },
    ref,
  ) => {
    const scope = useSelector(scopeSelector);
    const { fetchedUser, user } = useAuthWrapper();
    const element = get(project.elements, elementPath);
    const deps = useMemo(
      () => findDependentValues(element.id, element, project.dataTypes),
      [element, project.dataTypes],
    );

    const { path, property } = getPagePath(
      '/',
      routePath,
      dataType,
      dataProperty,
    );

    const { viewRoutePrefix } = usePagePathname(routePath, parentPage, project);

    const documentTitle = useMemo(() => {
      const { projectPages } = getPagesConfig(
        project.elements,
        project.settings,
      );
      const titleParts = [get(element, 'props.name')];

      const parentPageId = get(element, 'props.parentPage');
      const parentPage =
        parentPageId && projectPages.find((el: any) => el.id === parentPageId);

      if (parentPage) {
        titleParts.push(get(parentPage, 'props.name'));
      }

      return titleParts.join(' — ');
    }, [element, project]);

    useSetDocumentTitle(documentTitle);

    const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
    const subPages = useMemo(
      () =>
        get(element, 'props.SubPages', []).filter(
          (subPage: any) =>
            !fetchedUser ||
            isElementVisible(
              subPage,
              project,
              user,
              scope,
              editorMode,
              customRulesEnabled,
            ),
        ),
      [
        customRulesEnabled,
        editorMode,
        element,
        fetchedUser,
        project,
        scope,
        user,
      ],
    );

    if (property) {
      return (
        <Route {...rest} path={path}>
          <QueryRoute
            // @ts-expect-error TS(2322): Type '{ children: () => ReactNode; auth: boolean |... Remove this comment to see the full error message
            auth={auth}
            dataProperty={dataProperty}
            dataType={dataType}
            dataTypes={project.dataTypes}
            dependencies={deps}
            element={element}
            elementPath={elementPath}
            project={project}
            property={property}
            ref={ref}
            scope={scope}
          >
            {children}
          </QueryRoute>
        </Route>
      );
    }

    return (
      <Switch>
        {subPages.map((subPage: any, index: any) => (
          <Route key={subPage.id} path={`${path}/${subPage.props.routePath}`}>
            {V2 ? (
              <BlankPage
                editorMode={editorMode}
                elementId={subPage.id}
                elementPath={[...elementPath, 'props', 'SubPages', index]}
                project={project}
                scope={scope}
                sections={get(subPage, 'props.sections', [])}
                subtitle={subtitle}
                tabs={[]}
                title={title}
              />
            ) : (
              get(subPage, 'children', []).map(
                (subPageChild: any, childIndex: number) => (
                  <ElementRenderer
                    editorMode={editorMode}
                    element={subPageChild}
                    elementPath={getElementPath(
                      [...elementPath, 'props', 'SubPages', index, 'children'],
                      childIndex,
                    )}
                    index={childIndex}
                    key={subPageChild.id}
                    project={project}
                    scope={scope}
                  />
                ),
              )
            )}
          </Route>
        ))}
        <Route path={`${viewRoutePrefix}/:tab?`}>
          {V2 ? (
            <BlankPage
              editorMode={editorMode}
              elementId={elementId}
              elementPath={elementPath}
              headerWidth={headerWidth}
              project={project}
              routePathName={viewRoutePrefix}
              scope={scope}
              sections={sections}
              subtitle={subtitle}
              tabs={tabs}
              title={title}
            />
          ) : (
            React.Children.map(children, (child) =>
              // @ts-expect-error TS(2769): No overload matches this call.
              React.cloneElement(child, { scope, ref }),
            )
          )}
        </Route>
      </Switch>
    );
  },
);

export default Page;
