import { useCallback, useEffect, useState } from 'react';
import {
  ENTER,
  ESCAPE,
  KEY_DIGIT_1,
  KEY_DIGIT_2,
  KEY_DIGIT_3,
  KEY_DIGIT_4,
  KEY_DIGIT_5,
  KEY_DIGIT_6,
  KEY_DIGIT_7,
  KEY_DIGIT_8,
  KEY_DIGIT_9,
  KEY_K,
  KEY_N,
  KEY_S,
  KEY_SLASH,
  KEY_U,
  ShortcutKeyType,
} from '../../constants/shortcuts';
import useIsMacOs from './useIsMacOs';

const INPUT = 'INPUT';
const KEYS_ALLOWED_ON_INPUT_WITH_ALT = [
  KEY_DIGIT_1,
  KEY_DIGIT_2,
  KEY_DIGIT_3,
  KEY_DIGIT_4,
  KEY_DIGIT_5,
  KEY_DIGIT_6,
  KEY_DIGIT_7,
  KEY_DIGIT_8,
  KEY_DIGIT_9,
  KEY_N,
  KEY_S,
];
const KEYS_ALLOWED_ON_INPUT_WITH_CTRL = [KEY_K, KEY_U, KEY_SLASH];

const useOnKeyPress = (
  targetKey: ShortcutKeyType,
  onKeyDown: (event: React.KeyboardEvent) => void,
  {
    altKey = false,
    ctrlKey = false,
    enabled = true,
    onKeyUp,
    shiftKey = false,
    useKeyCode = false,
  }: {
    altKey?: boolean;
    ctrlKey?: boolean;
    enabled?: boolean;
    onKeyUp?: (event: React.KeyboardEvent) => void;
    shiftKey?: boolean;
    useKeyCode?: boolean;
  } = {},
) => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  const isMacOs = useIsMacOs();

  // If pressed key is our target key then set to true
  const downHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        (altKey || useKeyCode
          ? event.code === targetKey
          : event.key === targetKey) &&
        shiftKey === event.shiftKey &&
        altKey === event.altKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(true);

        if (onKeyDown) {
          const isInput = event.target.tagName === INPUT;

          if (
            !isInput ||
            (isInput &&
              ((ctrlKey &&
                KEYS_ALLOWED_ON_INPUT_WITH_CTRL.includes(targetKey)) ||
                (altKey &&
                  KEYS_ALLOWED_ON_INPUT_WITH_ALT.includes(targetKey)))) ||
            [ESCAPE, ENTER].includes(targetKey)
          ) {
            return onKeyDown(event);
          }
        }
      }
    },
    [
      altKey,
      ctrlKey,
      enabled,
      isMacOs,
      onKeyDown,
      shiftKey,
      targetKey,
      useKeyCode,
    ],
  );

  // If released key is our target key then set to false
  const upHandler = useCallback(
    (event: any) => {
      if (
        enabled &&
        event.key === targetKey &&
        shiftKey === event.shiftKey &&
        altKey === event.altKey &&
        ((!isMacOs && event.ctrlKey === ctrlKey) ||
          (isMacOs && event.metaKey === ctrlKey))
      ) {
        setKeyPressed(false);

        if (onKeyUp) {
          onKeyUp(event);
        }
      }
    },
    [enabled, targetKey, shiftKey, altKey, isMacOs, ctrlKey, onKeyUp],
  );

  // Add event listeners
  useEffect(() => {
    if (enabled) {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
    }

    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [enabled, upHandler, downHandler]);

  return keyPressed;
};

export default useOnKeyPress;
