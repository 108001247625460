import { useCallback } from 'react';
import { IconArrowsMaximize, IconX } from '@tabler/icons-react';
import { Project } from '../../../models/Project';
import { useBackLink } from '../../../utils/hooks/useBacklink';
import useRecordPreview from '../../../utils/hooks/useRecordPreview';
import useRouter from '../../../utils/hooks/useRouter';

interface RecordDrawerTitleButtonsProps {
  project: Project;
  recordViewLink: string;
  viewRoutePrefix: string | undefined;
}

const RecordDrawerTitleButtons = ({
  project,
  recordViewLink,
  viewRoutePrefix,
}: RecordDrawerTitleButtonsProps) => {
  const { push } = useRouter();
  const backLink = useBackLink(project);
  const [_, toggleRecordPreview] = useRecordPreview();
  const handleOpenRecordView = useCallback(
    () => push(`${viewRoutePrefix}${recordViewLink}`),
    [push, viewRoutePrefix, recordViewLink],
  );

  const handleDrawerClose = useCallback(() => {
    toggleRecordPreview();

    if (backLink?.to) {
      push(backLink.to);
    } else if (viewRoutePrefix) {
      push(viewRoutePrefix);
    }
  }, [push, viewRoutePrefix, backLink, toggleRecordPreview]);

  return (
    <div className="absolute right-2 top-0 flex h-full items-center space-x-2">
      <IconArrowsMaximize
        className="cursor-pointer text-gray-400 opacity-75 hover:opacity-100"
        onClick={handleOpenRecordView}
        size={16}
      />
      <IconX
        className="cursor-pointer text-gray-400 opacity-75 hover:opacity-100"
        onClick={handleDrawerClose}
        size={16}
      />
    </div>
  );
};

export default RecordDrawerTitleButtons;
