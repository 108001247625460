import React from 'react';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import { Surface } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';

interface Props {
  className?: string;
  children: any;
  surface?: Surface;
}

const SimpleTable = ({ className, children, surface = DARK }: Props) => (
  <SimpleBar
    className={classNames(
      className,
      'flex h-full max-h-full w-full flex-col overflow-x-auto overflow-y-auto',
    )}
    autoHide={false}
  >
    <table
      className={classNames('min-w-full divide-y', {
        'divide-gray-800': surface === DARK,
        'divide-gray-200': surface === LIGHT,
      })}
    >
      {children}
    </table>
  </SimpleBar>
);

export default SimpleTable;
