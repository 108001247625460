import { FC, ReactNode, memo, useMemo, useState } from 'react';
import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import { trackGuideLinkClick } from '@noloco/core/src/utils/analytics';

interface GuideProps {
  arcade?: string;
  children?: ReactNode;
  className?: string;
  hideIcon?: boolean;
  href?: string;
  iconOnly?: boolean;
  labelStyling?: string;
  offset?: [number, number];
  showTooltip?: boolean;
  tooltipLabel?: ReactNode;
  video?: string;
}

const Guide: FC<GuideProps> = memo(
  ({
    arcade,
    children,
    className,
    hideIcon = false,
    href,
    iconOnly = false,
    offset = [0, 16],
    showTooltip = false,
    tooltipLabel,
    video,
  }) => {
    const [loading, setLoading] = useState<boolean>(true);

    const iframeSource = useMemo(() => arcade || video, [arcade, video]);

    const showBuildModeSectionLabel = useMemo(
      () => !iconOnly && tooltipLabel && children,
      [iconOnly, tooltipLabel, children],
    );

    const linkContent = useMemo(
      () => (
        <a
          className={classNames(
            'flex cursor-pointer items-center text-blue-400 hover:text-blue-500',
            className,
          )}
          href={href}
          onClick={() => trackGuideLinkClick(href!)}
          target="_blank"
          rel="noreferrer"
        >
          {tooltipLabel ?? children}
        </a>
      ),
      [children, className, href, tooltipLabel],
    );

    const tooltipContent = useMemo(
      () => (
        <div
          className={classNames('flex flex-col', darkModeColors.text.primary, {
            'min-h-80 space-y-2': iframeSource,
          })}
        >
          {iframeSource ? (
            <>
              <span className="text-lg font-medium">{linkContent}</span>
              {loading && (
                <div className="flex items-center justify-center">
                  <Loader />
                </div>
              )}
              <iframe
                onLoad={() => setLoading(false)}
                allowFullScreen
                className="h-full w-full flex-grow rounded-lg"
                src={iframeSource}
                title="Guide"
              />
            </>
          ) : (
            <span className="text-base">{linkContent}</span>
          )}
        </div>
      ),
      [iframeSource, loading, linkContent],
    );

    if (!href) {
      return null;
    }

    return (
      <Tooltip
        content={tooltipContent}
        delayHide={100}
        disabled={!showTooltip}
        offset={offset}
        placement="left"
        showArrow={false}
        surface={DARK}
      >
        <div className="flex items-center">
          {showBuildModeSectionLabel && (
            <span className={classNames('font-medium', { 'mr-2': !hideIcon })}>
              {children}
            </span>
          )}
          <a
            className={classNames(
              'flex cursor-pointer items-center text-blue-400 hover:text-blue-500',
              className,
            )}
            href={href}
            onClick={() => trackGuideLinkClick(href)}
            target="_blank"
            rel="noreferrer"
          >
            {!iconOnly && !tooltipLabel && (
              <span className="mr-2 font-medium">{children}</span>
            )}
            {(!hideIcon || iconOnly) && (
              <IconInfoCircle className="mr-auto" size={16} />
            )}
          </a>
        </div>
      </Tooltip>
    );
  },
);

export default Guide;
