import React, { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  IconHistory,
  IconHistoryToggle,
  IconPlaneDeparture,
} from '@tabler/icons-react';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Loader, Modal, Popover, Tooltip } from '@noloco/components';
import { darkModeColors } from '@noloco/core/src/constants/darkModeColors';
import { ALT, ESCAPE, KEY_P } from '@noloco/core/src/constants/shortcuts';
import ShortcutKeys from '@noloco/core/src/elements/sections/view/ShortcutKeys';
import { BaseUser } from '@noloco/core/src/models/User';
import { PROJECT_HAS_UNPUBLISHED_CHANGES_QUERY } from '@noloco/core/src/queries/core';
import {
  setHasUnpublishedChanges,
  setProject,
  setPublishedVersion,
} from '@noloco/core/src/reducers/project';
import { dataTypesSelector } from '@noloco/core/src/selectors/projectSelectors';
import {
  PREVIOUS_VERSION_PUBLISHED,
  REVERTED_EDITOR_TO_PREVIOUS_VERSION,
  trackEvent,
} from '@noloco/core/src/utils/analytics';
import { useInfoAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getFullName } from '@noloco/core/src/utils/user';
import {
  PROJECT_QUERY,
  PROJECT_VERSION_HISTORY_QUERY,
  PUBLISH_PROJECT_BY_VERSION,
  REVERT_EDITOR_TO_PROJECT_VERSION,
} from '../queries/project';
import Guide from './Guide';
import { PublishPopover } from './PublishModal/PublishPopover';

interface PublishedByFragment {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface VersionHistoryEntryType {
  publishedAt: string;
  version: number;
  publishedBy: PublishedByFragment | null;
  publishMessage: string | null;
}

const VersionHistoryEntryList = ({
  projectName,
  currentPublishedProjectVersion,
  closeAll,
}: {
  projectName: string;
  currentPublishedProjectVersion: number;
  closeAll: () => void;
}) => {
  const { loading: versionHistoryLoading, data: versionHistoryEntries } =
    useCacheQuery(PROJECT_VERSION_HISTORY_QUERY, {
      variables: { projectName },
    });

  return !versionHistoryLoading ? (
    <div className="overflow-auto">
      <Guide href="https://guides.noloco.io/settings/publishing/app-version-history">
        {getText('toolbar.publish.versionHistory.guideLinkText')}
      </Guide>
      {versionHistoryEntries?.projectVersionHistory?.length === 0
        ? getText('toolbar.publish.versionHistory.emptyState')
        : versionHistoryEntries?.projectVersionHistory?.map(
            (vhed: VersionHistoryEntryType) => (
              <VersionHistoryEntry
                key={vhed.version}
                versionHistoryEntryData={vhed}
                projectName={projectName}
                currentPublishedVersion={currentPublishedProjectVersion}
                closeAll={closeAll}
              />
            ),
          )}
    </div>
  ) : (
    <div className="flex justify-center">
      <Loader size="lg" />
    </div>
  );
};

const VersionHistoryEntry = ({
  versionHistoryEntryData,
  projectName,
  currentPublishedVersion,
  closeAll,
}: {
  versionHistoryEntryData: VersionHistoryEntryType;
  projectName: string;
  currentPublishedVersion: number;
  closeAll: () => void;
}) => {
  const [isDarkModeEnabled] = useDarkMode();
  const dispatch = useDispatch();
  const publishedDate = DateTime.fromISO(versionHistoryEntryData.publishedAt);
  const relativeCalendar = publishedDate.toRelativeCalendar();
  const relativeTimeDisplay =
    relativeCalendar === 'today'
      ? `${publishedDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`
      : relativeCalendar;

  const [publishProjectByVersion] = useMutation(PUBLISH_PROJECT_BY_VERSION);

  const [revertEditorToProjectVersion] = useMutation(
    REVERT_EDITOR_TO_PROJECT_VERSION,
  );

  const infoAlert = useInfoAlert();

  const [isPublishPreviousVersionLoading, setIsPublishPreviousVersionLoading] =
    useState(false);

  const [
    isRevertEditorToPreviousVersionLoading,
    setIsRevertEditorToPreviousVersionLoading,
  ] = useState(false);

  const [fetchRevertedProject] = usePromiseQuery(PROJECT_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      projectId: projectName,
    },
  });

  const dataTypes = useSelector(dataTypesSelector);

  const hasPublishMessage = versionHistoryEntryData?.publishMessage !== null;

  const publishMessageDisplay = hasPublishMessage
    ? versionHistoryEntryData.publishMessage
    : getText(
        {
          versionNumber: versionHistoryEntryData.version,
        },
        'toolbar.publish.versionHistory.defaultPublishMessage',
      );

  const isCurrentPublishedVersion =
    versionHistoryEntryData.version === currentPublishedVersion;

  const publishVersionOnClick = useCallback(async () => {
    setIsPublishPreviousVersionLoading(true);
    await publishProjectByVersion({
      variables: {
        name: projectName,
        targetVersion: versionHistoryEntryData.version,
      },
    });
    dispatch(setPublishedVersion(versionHistoryEntryData.version));
    trackEvent(PREVIOUS_VERSION_PUBLISHED);
    setIsPublishPreviousVersionLoading(false);
    infoAlert(
      getText(
        { versionNumber: versionHistoryEntryData.version },
        'toolbar.publish.versionHistory.publishedVersionAlert',
      ),
    );
    closeAll();
  }, [
    closeAll,
    dispatch,
    infoAlert,
    projectName,
    publishProjectByVersion,
    versionHistoryEntryData.version,
  ]);

  const revertEditorOnClick = useCallback(async () => {
    setIsRevertEditorToPreviousVersionLoading(true);
    await revertEditorToProjectVersion({
      variables: {
        name: projectName,
        targetVersion: versionHistoryEntryData.version,
      },
    });
    fetchRevertedProject().then(({ data: revertedProjectData }) => {
      const newProject = revertedProjectData.project;
      newProject.dataTypes = dataTypes;
      dispatch(setProject(newProject));
    });
    trackEvent(REVERTED_EDITOR_TO_PREVIOUS_VERSION);
    setIsRevertEditorToPreviousVersionLoading(false);
    infoAlert(
      getText(
        { versionNumber: versionHistoryEntryData.version },
        'toolbar.publish.versionHistory.revertedEditorToVersionAlert',
      ),
    );
    closeAll();
  }, [
    closeAll,
    dataTypes,
    dispatch,
    fetchRevertedProject,
    infoAlert,
    projectName,
    revertEditorToProjectVersion,
    versionHistoryEntryData.version,
  ]);

  return (
    <div
      className={`mt-4 flex flex-col rounded-lg border-2 p-4 ${
        isDarkModeEnabled ? darkModeColors.borders.one : 'border-slate-300'
      }`}
    >
      <div className="flex w-full justify-between pb-3">
        <span
          className={`font-medium ${
            isDarkModeEnabled ? darkModeColors.text.primary : 'text-slate-700'
          }`}
        >
          {publishMessageDisplay}
        </span>
        <Popover
          placement="bottom-end"
          trigger="click"
          className={`overflow-hidden bg-white`}
          p={0}
          rounded="lg"
          shadow="lg"
          showArrow={false}
          closeOnClick={false}
          content={
            <div className="flex flex-col p-4">
              <button
                className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
                onClick={publishVersionOnClick}
              >
                <div className="flex items-center">
                  {isPublishPreviousVersionLoading ? (
                    <Loader size="sm" />
                  ) : (
                    <IconPlaneDeparture size={16} />
                  )}
                  <span className="ml-2">
                    {getText(
                      'toolbar.publish.versionHistory.publishThisVersion',
                    )}
                  </span>
                </div>
              </button>
              <button
                className="flex items-center rounded-lg px-4 py-3 text-left hover:bg-slate-100"
                onClick={revertEditorOnClick}
              >
                <div className="flex items-center">
                  {isRevertEditorToPreviousVersionLoading ? (
                    <Loader type="bars" size="sm" />
                  ) : (
                    <IconHistory size={16} />
                  )}
                  <span className="ml-2">
                    {getText(
                      'toolbar.publish.versionHistory.revertEditorToThisVersion',
                    )}
                  </span>
                </div>
              </button>
            </div>
          }
        >
          <span>...</span>
        </Popover>
      </div>
      <div className="flex w-full justify-between">
        <span className="text-sm">
          #{versionHistoryEntryData.version}
          <span className="ml-2">
            {versionHistoryEntryData.publishedBy
              ? getFullName(versionHistoryEntryData.publishedBy as BaseUser)
              : ''}
          </span>
        </span>
        <Tooltip
          content={
            <span
              className={`${
                isDarkModeEnabled ? darkModeColors.text.primary : ''
              }`}
            >
              {publishedDate.toLocaleString(DateTime.DATETIME_MED)}
            </span>
          }
        >
          <div className="flex">
            {isCurrentPublishedVersion ? (
              <Badge className="mr-2 mt-0.5" color="green">
                <span className="uppercase">
                  {getText(
                    'toolbar.publish.versionHistory.isCurrentVersionBadge',
                  )}
                </span>
              </Badge>
            ) : null}
            <span className={`text-sm ${isDarkModeEnabled}`}>
              {relativeTimeDisplay}
            </span>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

const PublishButton = ({
  hasUnpublishedChanges,
  projectName,
  currentPublishedProjectVersion,
  domains,
  publishedVersion,
}: any) => {
  const {
    query: { __publish },
    replaceQueryParams,
  } = useRouter();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false || __publish === 'true');
  const [versionHistoryModalIsOpen, setVersionHistoryModalIsOpen] =
    useState(false);

  const { data: projectData } = useQuery(
    gql`
      ${PROJECT_HAS_UNPUBLISHED_CHANGES_QUERY}
    `,
    {
      variables: {
        projectId: projectName,
      },
      skip: hasUnpublishedChanges !== null,
    },
  );

  useMemo(() => {
    if (
      hasUnpublishedChanges === null &&
      projectData?.project?.hasUnpublishedChanges !== undefined
    ) {
      dispatch(
        setHasUnpublishedChanges(projectData.project.hasUnpublishedChanges),
      );
    }
  }, [dispatch, hasUnpublishedChanges, projectData]);

  const [publishMessage, setPublishMessage] = useState('');

  const onOpenChange = useCallback(
    (nextIsOpen: any) => {
      if (!nextIsOpen && __publish) {
        replaceQueryParams({ __publish: undefined });
      }
      setIsOpen(nextIsOpen);
    },
    [__publish, replaceQueryParams],
  );

  const closeAll = () => {
    setVersionHistoryModalIsOpen(false);
    setIsOpen(false);
  };

  useOnKeyPress(ESCAPE, () => onOpenChange(false), { enabled: isOpen });

  useOnKeyPress(KEY_P, () => onOpenChange(!isOpen), {
    altKey: true,
    enabled: true,
  });

  return (
    <>
      <Tooltip
        disabled={isOpen}
        content={
          <div className="max-64 flex flex-col">
            <div className="flex items-center space-x-2">
              <span>{getText('leftSidebar.nav.publish')}</span>
              <ShortcutKeys keys={[ALT, KEY_P]} />
            </div>
            {hasUnpublishedChanges && (
              <span className="mt-2 flex items-center">
                <div className="mr-2 h-2 w-2 flex-shrink-0 rounded-full bg-pink-400" />
                <span>{getText('toolbar.publish.unpublishedChanges')}</span>
              </span>
            )}
          </div>
        }
        placement="right"
        bg="white"
      >
        <div className="flex w-full items-center justify-center">
          <PublishPopover
            dispatch={dispatch}
            domains={domains}
            hasUnpublishedChanges={hasUnpublishedChanges}
            isOpen={isOpen}
            __publish={__publish}
            publishedVersion={publishedVersion}
            publishMessage={publishMessage}
            projectName={projectName}
            setPublishMessage={setPublishMessage}
            setIsOpen={setIsOpen}
            onOpenChange={onOpenChange}
            isPublishComplete={false}
            setVersionHistoryModalIsOpen={setVersionHistoryModalIsOpen}
            versionHistoryModalIsOpen={versionHistoryModalIsOpen}
          />
        </div>
      </Tooltip>
      {versionHistoryModalIsOpen && (
        <Modal
          title={getText('toolbar.publish.versionHistory.listTitle')}
          icon={<IconHistoryToggle />}
          canCancel={false}
          canConfirm={true}
          closeOnOutsideClick={true}
          confirmText={getText(
            'toolbar.publish.versionHistory.modalConfirmButtonText',
          )}
          onConfirm={() => closeAll()}
        >
          <VersionHistoryEntryList
            projectName={projectName}
            currentPublishedProjectVersion={currentPublishedProjectVersion}
            closeAll={closeAll}
          />
        </Modal>
      )}
    </>
  );
};

PublishButton.defaultProps = {
  domains: [],
};

export default PublishButton;
