import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setRecordPreview } from '../../reducers/elements';
import { recordPreviewSelector } from '../../selectors/elementsSelectors';
import useHasFeatureFlag, { RECORD_STYLES } from './useHasFeatureFlag';

const useRecordPreview = (): [boolean, (preview?: boolean) => void] => {
  const dispatch = useDispatch();
  const recordPreview = useSelector(recordPreviewSelector);
  const isRecordStylesEnabled = useHasFeatureFlag(RECORD_STYLES);

  const toggleRecordPreview = useCallback(
    (preview?: boolean) =>
      dispatch(setRecordPreview(preview ?? !recordPreview)),
    [dispatch, recordPreview],
  );

  return [isRecordStylesEnabled && recordPreview, toggleRecordPreview];
};

export default useRecordPreview;
