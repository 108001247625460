import React, { forwardRef, memo, useCallback } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ElementComponent, {
  ElementProps,
} from '@noloco/core/src/components/canvas/Element';
import { VIEW } from '@noloco/core/src/constants/elements';
import elementsConfig from '@noloco/core/src/elements/elementConfig';
import { Element } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { setSelectedElement as dispatchSetSelectedElement } from '@noloco/core/src/reducers/elements';
import useHover from '@noloco/core/src/utils/hooks/useHover';
import ElementEditorWrapper from './ElementEditorWrapper';
import ElementHighlight from './ElementHighlight';

const ElementWrapper = memo(
  forwardRef<
    unknown,
    {
      element: Element;
      isSelected: boolean;
      elementPath: string[];
      setSelectedElement: (_elementPath: string[]) => void;
      project: Project;
    } & ElementProps
  >(
    (
      {
        element,
        isSelected,
        elementPath,
        setSelectedElement,
        project,
        ...props
      },
      ref,
    ) => {
      const {
        props: {
          className = undefined,
          onClick = undefined,
          onMouseOver = undefined,
          onMouseOut = undefined,
        } = {},
      } = element;
      const elementConfig = elementsConfig[element.type];
      const [elementRef, isHighlighted] = useHover(
        !elementConfig || elementConfig.hidden || isSelected,
        {
          stopPropagation: true,
        },
      );

      const handleOnClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement>) => {
          if (onClick) {
            onClick(event);
          }

          if (!elementConfig || !elementConfig.hidden) {
            event.stopPropagation();
            setSelectedElement(elementPath);
          }
        },
        [onClick, elementConfig, setSelectedElement, elementPath],
      );

      return (
        elementConfig && (
          <>
            {/* @ts-expect-error TS(2322): Type '{ children: Element; element: any; elementPa... Remove this comment to see the full error message */}
            <ElementEditorWrapper
              element={element}
              elementPath={elementPath}
              project={project}
              ref={ref}
            >
              <ElementComponent
                {...props}
                className={classNames(className)}
                editorMode={true}
                element={element}
                elementPath={elementPath}
                onClick={elementConfig.hidden ? undefined : handleOnClick}
                onMouseOver={onMouseOver}
                onMouseOut={onMouseOut}
                isSelected={isSelected}
                project={project}
                ref={elementRef}
                ChildWrapper={ConnectedElementWrapper}
              />
            </ElementEditorWrapper>
            {!elementConfig.hidden &&
              elementConfig.styleable &&
              element.type !== VIEW &&
              isHighlighted && (
                <ElementHighlight
                  project={project}
                  element={element}
                  elementConfig={elementConfig}
                  elementPath={elementPath}
                  selected={isSelected}
                />
              )}
          </>
        )
      );
    },
  ),
);

const mapStateToProps = (
  { elements: { selected } }: any,
  { elementPath }: any,
) => ({
  isSelected: selected && selected.join('.') === elementPath.join('.'),
});

const mapDispatch = {
  setSelectedElement: dispatchSetSelectedElement,
};

const ConnectedElementWrapper = connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(ElementWrapper);

export default ConnectedElementWrapper;
