import React, { useCallback, useMemo, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { Theme } from '../../models';
import { filterOptions } from '../select/OptionList';
import SelectBase, { SelectOption } from '../select/SelectBase';
import { TextArea } from './TextArea';
import { TextInput, TextInputProps } from './TextInput';

type Props = Omit<TextInputProps, 'onChange'> & {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  options: SelectOption[];
  theme: Theme;
  loadingOptions?: boolean;
  rows?: number;
  isMultiline?: boolean;
  isLoading?: boolean;
  setIsLoading?: (nextValue: boolean) => void;
};

const AutocompleteTextInput = ({
  disabled,
  readOnly,
  loadingOptions,
  rows = 1,
  isMultiline = false,
  isLoading,
  setIsLoading,
  onChange,
  options,
  surface,
  value,
  theme,
  placeholder,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const filteredOptions = useMemo(() => {
    if (!value) {
      return options;
    }

    return options.filter(filterOptions(value));
  }, [options, value]);

  const handleSelect = useCallback(
    (nextValue: any) => {
      if (onChange) {
        onChange({
          target: {
            value: nextValue,
          },
        } as React.ChangeEvent<HTMLInputElement>);
      }
      setIsOpen(false);
    },
    [onChange],
  );

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  const onBlur = () => {
    if (!isLoading) {
      setIsOpen(false);
    }

    if (setIsLoading && isLoading === false) {
      setIsLoading(false);
    }
  };

  const TextInputComponent = isMultiline ? TextArea : TextInput;

  return (
    <SelectBase
      className="w-full"
      disabled={readOnly || disabled}
      fillBackground={false}
      onChange={handleSelect}
      options={filteredOptions}
      loading={loadingOptions}
      surface={surface}
      showEmptyState={false}
      open={isOpen}
    >
      {() => (
        <TextInputComponent
          {...rest}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={() => setIsOpen(true)}
          readOnly={readOnly}
          rows={rows}
          value={value}
          placeholder={placeholder}
          onChange={handleTextChange}
          surface={surface}
          theme={theme}
        />
      )}
    </SelectBase>
  );
};

export default withTheme(AutocompleteTextInput) as React.FC<
  Omit<Props, 'theme'>
>;
