import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconCheck,
  IconCircleCheck,
  IconToggleLeft,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Loader, Switch } from '@noloco/components';
import { getText } from '@noloco/core/src/utils/lang';
import { forEachPromise } from '@noloco/core/src/utils/promises';
import {
  DISABLE_DATA_SOURCE_TYPES,
  SYNC_DATA_SOURCE_DATA,
} from '../../queries/project';
import { useRemoveDataType } from '../../utils/hooks/useUpdateDataTypes';

const LANG_KEY = 'data.dataSources.connect.selectDataTypes';

const DataSourceImportSelectDataTypes = ({
  dataSourceId,
  dataTypes,
  onFinish,
  project,
  inOnboarding = false,
  sourceType,
}: any) => {
  const removeDataType = useRemoveDataType();
  const [dataTypesToDisable, setDataTypesToDisable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const [disableDataSourceTypes] = useMutation(DISABLE_DATA_SOURCE_TYPES);
  const [syncDataSourceData] = useMutation(SYNC_DATA_SOURCE_DATA);

  const toggleAll = useCallback(() => {
    if (dataTypesToDisable.length === 0) {
      setDataTypesToDisable(dataTypes);
    } else {
      setDataTypesToDisable([]);
    }
  }, [dataTypesToDisable, dataTypes]);

  const onConfirm = useCallback(async () => {
    setLoading(true);

    const dataTypeIdsToDisable = dataTypesToDisable.map(({ id }) => id);

    await disableDataSourceTypes({
      variables: {
        dataTypeIds: dataTypeIdsToDisable,
        id: dataSourceId,
        projectName: project.name,
        type: sourceType,
      },
    });

    try {
      await forEachPromise(dataTypesToDisable, async (dataType: any) => {
        removeDataType({ dataType });
      });
    } catch (e) {
      console.error(e);
    }

    await syncDataSourceData({
      variables: {
        projectName: project.name,
        id: dataSourceId,
        isFirstSync: true,
      },
      context: {
        authQuery: true,
      },
    });

    setFinished(true);
    setLoading(false);

    return onFinish(
      // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      dataTypes.filter(({ id }: any) => !dataTypeIdsToDisable.includes(id)),
    );
  }, [
    dataSourceId,
    dataTypes,
    dataTypesToDisable,
    disableDataSourceTypes,
    onFinish,
    project.name,
    removeDataType,
    sourceType,
    syncDataSourceData,
  ]);

  return (
    <div
      className={classNames('flex w-full items-center rounded-lg border p-3', {
        'border-brand-light text-white': !inOnboarding,
        'border-gray-200 bg-white text-gray-700': inOnboarding,
      })}
    >
      <div className="h-full">
        <div className="my-2 flex w-16 flex-shrink-0 justify-center">
          {loading ? (
            <Loader className={inOnboarding ? 'text-gray-700' : 'text-white'} />
          ) : !finished ? (
            <IconToggleLeft
              className={inOnboarding ? 'text-gray-700' : 'text-white'}
              size={40}
            />
          ) : (
            <IconCircleCheck className="text-cyan-500" size={40} />
          )}
        </div>
      </div>
      <div className="ml-3 flex flex-col">
        <span className="text-lg font-medium tracking-wider">
          {getText(LANG_KEY, 'title')}
        </span>
        <span className="font-gray-200 mt-1 text-sm">
          {getText(LANG_KEY, 'subtitle')}
        </span>
        {!inOnboarding && (
          <button
            className="text-brand-lightest mb-1 mr-auto mt-6 text-xs hover:text-cyan-500"
            onClick={toggleAll}
          >
            {getText(LANG_KEY, 'toggleAll')}
          </button>
        )}
        <ul className="mb-8 mt-2 space-y-2">
          {dataTypes.map((dataType: any) => (
            <li className="flex" key={dataType.id}>
              <div className="my-0.5 flex">
                <Switch
                  className="mr-3"
                  disabled={loading || finished}
                  onChange={(value: any) => {
                    if (!value) {
                      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                      setDataTypesToDisable([...dataTypesToDisable, dataType]);
                    } else {
                      setDataTypesToDisable(
                        dataTypesToDisable.filter(
                          ({ id }) => dataType.id !== id,
                        ),
                      );
                    }
                  }}
                  value={
                    !dataTypesToDisable.find(({ id }) => id === dataType.id)
                  }
                />
              </div>
              <span className="flex text-base">{dataType.display}</span>
            </li>
          ))}
        </ul>
        <Button
          className="flex w-min items-center disabled:opacity-75"
          disabled={loading || finished}
          onClick={onConfirm}
        >
          <>
            <span>{getText(LANG_KEY, 'confirm')}</span>
            {loading ? (
              <Loader size="sm" className="ml-2" />
            ) : (
              <IconCheck size={16} className="ml-2 opacity-75" />
            )}
          </>
        </Button>
      </div>
    </div>
  );
};

export default DataSourceImportSelectDataTypes;
