import React, { useCallback, useMemo } from 'react';
import { IconPlus } from '@tabler/icons-react';
import get from 'lodash/get';
import { SelectInput, Switch } from '@noloco/components';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import BuildModeCustomFiltersEditor from '@noloco/core/src/components/buildMode/filters/BuildModeCustomFiltersEditor';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { getDataTypeByName } from '@noloco/core/src/utils/data';
import { getText } from '@noloco/core/src/utils/lang';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import { getDataCollectionOptionsOfType } from '@noloco/core/src/utils/renderedOptions';
import { withNullOption } from '@noloco/core/src/utils/settings';
import { DataTypeValue } from '../../canvas/DataTypeInput';
import CollectionSortInput from './CollectionSortInput';
import { generateAdditionalFilter } from './CustomFiltersEditor';
import ViewLinkEditor from './ViewLinkEditor';

const LANG_KEY = 'elements.VIEW';

const RelationalFieldFormFieldsEditor = ({
  config,
  dataType,
  disableAllowNewRecords,
  elementPath,
  field,
  index,
  project,
  recordScopeOptions,
  updateFields,
}: any) => {
  const allowNewRecords = get(config, ['allowNewRecords'], false);
  const onlyAllowNewRecords = get(config, ['onlyAllowNewRecords'], false);
  const newRecordForm = get(config, ['newRecordForm']);

  const isFile = useMemo(() => field.type === FILE, [field.type]);

  const isMultiRel = useMemo(
    () => !isFile && isMultiField(field),
    [field, isFile],
  );

  const fieldDataType =
    (field.relationship || field.relatedField) &&
    getDataTypeByName(project.dataTypes, field.type);

  const options = isMultiRel
    ? getDataCollectionOptionsOfType(project, elementPath, field.type)
    : [];

  const recordScopeOptionObject = useMemo(
    () =>
      fieldDataType
        ? [
            {
              label: getText(
                { datatype: fieldDataType.display },
                'elements.LIST.filterInput.record',
              ),
              options: recordScopeOptions,
            },
          ]
        : [],
    [fieldDataType, recordScopeOptions],
  );

  const customFilters = get(config, 'customFilters', []);

  const onAddNewCustomFilter = useCallback(
    () =>
      updateFields(
        [index, 'customFilters'],
        [...customFilters, generateAdditionalFilter()],
      ),
    [customFilters, index, updateFields],
  );

  const onUpdateCustomFilters = useCallback(
    (updatedCustomFilters: any) =>
      updateFields([index, 'customFilters'], updatedCustomFilters),
    [index, updateFields],
  );

  if (isFile) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-2">
      {!disableAllowNewRecords && (
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'new.allowNewRecords')}
          onChange={(value) => {
            updateFields([index, 'allowNewRecords'], value);

            if (!value) {
              updateFields([index, 'onlyAllowNewRecords'], value);
            }
          }}
          value={allowNewRecords}
        >
          <div className="p-2">
            <div className="flex items-center justify-between">
              <BuildModeLabel>
                {getText(LANG_KEY, 'new.onlyAllowNewRecords')}
              </BuildModeLabel>
              <Switch
                onChange={(value: boolean) =>
                  updateFields([index, 'onlyAllowNewRecords'], value)
                }
                size={SM}
                value={onlyAllowNewRecords}
              />
            </div>
            <ViewLinkEditor
              allowNone={false}
              dataType={fieldDataType}
              onChange={(nextNewRecordForm: any) => {
                updateFields([index, 'newRecordForm'], nextNewRecordForm);
              }}
              label={
                <span className="text-xs">
                  {getText(LANG_KEY, 'buttons.newButton.newLink')}
                </span>
              }
              project={project}
              value={newRecordForm}
            />
          </div>
        </BuildModeSwitchSection>
      )}
      <BuildModeLabel>{getText(LANG_KEY, 'new.optionsFilter')}</BuildModeLabel>
      <div className="w-full" id={`new-field-${field.name}-${dataType.name}`}>
        <SelectInput
          Button={DataTypeValue}
          className="w-full text-black"
          contained={true}
          shiftRight={true}
          onChange={(option: any) =>
            updateFields([index, 'collectionFilter'], option)
          }
          options={withNullOption(options, false)}
          usePortal={false}
          value={get(config, 'collectionFilter')}
        />
      </div>
      {fieldDataType && (
        <BuildModeCustomFiltersEditor
          additionalScopeItems={recordScopeOptionObject}
          customFilters={customFilters}
          elementPath={elementPath}
          onUpdateCustomFilters={onUpdateCustomFilters}
          project={project}
          selectedDataType={fieldDataType}
        />
      )}
      <button
        className="flex items-center space-x-2 py-2 text-slate-400 hover:text-slate-200"
        onClick={onAddNewCustomFilter}
      >
        <IconPlus size={16} />
        <span>{getText('elements.LIST.filterInput.new')}</span>
      </button>
      {fieldDataType && (
        <>
          <BuildModeLabel>{getText('elements.LIST.sort')}</BuildModeLabel>
          <CollectionSortInput
            dataType={fieldDataType}
            onChange={(newOrderBy: any) =>
              updateFields([index, 'orderBy'], newOrderBy)
            }
            placement="left"
            value={get(config, 'orderBy')}
          />
        </>
      )}
    </div>
  );
};

export default RelationalFieldFormFieldsEditor;
