import React, { memo, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ErrorBoundary } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Loader } from '@noloco/components';
import ProjectThemeProvider from '@noloco/components/src/utils/ProjectThemeProvider';
import PWAModals from '@noloco/core/src/elements/sections/view/PWAModals';
import { setBillingPlan } from '@noloco/core/src/reducers/billingPlan';
import { setProject } from '@noloco/core/src/reducers/project';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import {
  dataTypesSelector,
  projectNameSelector,
  projectSettingsSelector,
  projectUsersSelector,
} from '@noloco/core/src/selectors/projectSelectors';
import { analyticsIdentity } from '@noloco/core/src/utils/analytics';
import { isAuthPage } from '@noloco/core/src/utils/authUrls';
import { ProvideActionButtons } from '@noloco/core/src/utils/hooks/useActionButtons';
import useAppTheme from '@noloco/core/src/utils/hooks/useAppTheme';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useAuthWrapper, {
  AuthWrapperProvider,
} from '@noloco/core/src/utils/hooks/useAuthWrapper';
import useDarkMode from '@noloco/core/src/utils/hooks/useDarkMode';
import useUserPermissions from '@noloco/core/src/utils/hooks/useUserPermissions';
import { BILLING_PLAN_QUERY, PROJECT_QUERY } from '../queries/project';
import BuilderProjectErrorMessage from './BuilderProjectErrorMessage';
import ProjectBody from './ProjectBody';

const Project = memo(() => {
  const settings = useSelector(projectSettingsSelector);
  const dispatch = useDispatch();
  const billingPlan = useSelector(billingPlanSelector);
  const users = useSelector(projectUsersSelector);
  const projectName = useSelector(projectNameSelector);

  const { fetchedUser, user } = useAuth();

  const { fetchedUser: authWrapperUserFetched, loading: authWrapperLoading } =
    useAuthWrapper();

  const projectTheme = useAppTheme(settings);
  const [isDarkModeToggleEnabled] = useDarkMode();

  const { isBuilder: userIsBuilder, isDataAdmin: userIsDataAdmin } =
    useUserPermissions();

  const { data } = useQuery(PROJECT_QUERY, {
    skip: !user || !userIsBuilder || !!users,
    ssr: false,
    variables: {
      projectId: projectName,
    },
  });

  const { data: billingPlanData } = useQuery(BILLING_PLAN_QUERY, {
    context: {
      projectQuery: true,
      authQuery: true,
      projectName: projectName,
    },
    skip: !!billingPlan,
    ssr: false,
  });

  useEffect(() => {
    if (billingPlanData && billingPlanData.billingPlan && !billingPlan) {
      dispatch(setBillingPlan(billingPlanData.billingPlan));
    }
  }, [billingPlan, billingPlanData, dispatch]);

  useEffect(() => {
    if (data && data.project && !users) {
      dispatch(setProject(data.project));
    }
  }, [data, dispatch, users]);

  useEffect(() => {
    if (window.analytics) {
      analyticsIdentity({
        project: projectName,
      });
    }
  }, [projectName]);

  const { pathname } = useLocation();

  const isDarkModeEnabled = useMemo(
    () => isDarkModeToggleEnabled && !isAuthPage(pathname),
    [isDarkModeToggleEnabled, pathname],
  );

  if (!fetchedUser || (authWrapperLoading && !authWrapperUserFetched)) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
        <Loader size="lg" />
      </div>
    );
  }

  const showBuilderExp = userIsBuilder && !!users;

  return (
    <ProjectThemeProvider theme={projectTheme} darkMode={isDarkModeEnabled}>
      <div className="project-page bg-gray-lightest shadcn-body relative flex h-screen max-h-screen w-screen overflow-hidden">
        <ProvideActionButtons>
          <ProjectBody
            showBuilderExp={showBuilderExp}
            showDataTab={userIsDataAdmin}
            settings={settings}
            user={user!}
          />
          <PWAModals settings={settings} />
        </ProvideActionButtons>
      </div>
    </ProjectThemeProvider>
  );
});

Project.displayName = 'Project';

const ProjectWrapper = () => {
  const dataTypes = useSelector(dataTypesSelector);

  return (
    <AuthWrapperProvider dataTypes={dataTypes}>
      <ErrorBoundary fallback={() => <BuilderProjectErrorMessage />}>
        <Project />
      </ErrorBoundary>
    </AuthWrapperProvider>
  );
};

ProjectWrapper.displayName = 'ProjectWrapper';

export default ProjectWrapper;
