import React, { useCallback, useState } from 'react';
import { IconPencil } from '@tabler/icons-react';
import classNames from 'classnames';
import { XS } from '../../constants/tShirtSizes';
import { Loader } from '../loading';
import TextInput, { TextInputProps } from './TextInput';

type TogglableTextInputProps = TextInputProps & {
  loading?: boolean;
  onSave?: (value: string | number) => void;
};

const TogglableTextInput = ({
  className,
  loading,
  onSave,
  value,
  ...rest
}: TogglableTextInputProps) => {
  const [isEditing, setIsEditing] = useState(false);

  const onBlur = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = event.target.value;

      if (nextValue) {
        setIsEditing(false);

        if (onSave) {
          onSave(nextValue);
        }
      }
    },
    [onSave],
  );

  return (
    <div className={classNames('flex items-center space-x-2', className)}>
      {isEditing && (
        <TextInput {...rest} onBlur={onBlur} autoFocus={true} value={value} />
      )}
      {!isEditing && <span>{value}</span>}
      {!isEditing && !loading && (
        <button
          onClick={() => setIsEditing(true)}
          className="opacity-50 hover:opacity-100"
        >
          <IconPencil size={20} />
        </button>
      )}
      {!isEditing && loading && <Loader size={XS} />}
    </div>
  );
};

export default TogglableTextInput;
