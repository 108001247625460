import React, { forwardRef } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import {
  BELOW,
  BELOW_SOLID,
  CONNECTED,
  ErrorPosition,
  ICON_TOOLTIP,
  INLINE_TOOLTIP,
} from './errorPositions';

const errorStyles = {
  [BELOW_SOLID]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
    m: { t: 2 },
  },

  [CONNECTED]: {
    m: { t: 0 },
    roundedB: true,
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
  },

  [BELOW]: { m: { t: 0.5, l: 1 }, text: ['sm'], font: 'bold' },

  [INLINE_TOOLTIP]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
  },

  [ICON_TOOLTIP]: {
    rounded: 'lg',
    text: ['sm', 'white'],
    font: 'bold',
    p: { x: 4, y: 2 },
  },
};

interface ErrorTextProps {
  className?: string;
  children?: any;
  type?: ErrorPosition;
}

const ErrorText = forwardRef<any, ErrorTextProps>(
  ({ children, className = '', type = BELOW_SOLID, ...rest }, ref) => (
    <Box
      data-testid="error-text"
      {...rest}
      className={classNames(className, {
        'tooltip left bottom-0': type === INLINE_TOOLTIP,
        'bg-red-500 dark:bg-red-700': type !== BELOW,
        'text-red-600 dark:text-red-700': type === BELOW,
      })}
      {...errorStyles[type]}
      ref={ref}
    >
      {children}
    </Box>
  ),
);

export default ErrorText;
