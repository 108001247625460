import first from 'lodash/first';
import { ensureArray } from '../../utils/arrays';

export const cloneCustomRules = (customRules: any, idMap: any): any => {
  if (!customRules) {
    return undefined;
  }

  // @ts-expect-error TS(2488): Type '[string, any] | undefined' must have a '[Sym... Remove this comment to see the full error message
  const [sourceId, targetId] = first(Object.entries(idMap));

  return ensureArray(customRules).map((andConditions: any) =>
    ensureArray(andConditions)
      .filter((condition: any) => condition?.field)
      .map(({ field, operator, value }: any) => ({
        field: {
          ...field,
          id: field.id.replace(sourceId, targetId),
        },
        operator,
        value,
      })),
  );
};
