import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import { LeftEditorSectionOptions } from '../../constants/buildMode';
import { projectDataSelector } from '../../selectors/projectSelectors';
import { getPagesConfig } from '../../utils/pages';
import BuildModeEditorRouting from '../buildMode/BuildModeEditorRouting';
import BuildModeFormEditor from '../buildMode/form/BuildModeFormEditor';
import BuildModePageEditor from '../buildMode/page/BuildModePageEditor';
import BuildModeRecordEditor from '../buildMode/record/BuildModeRecordEditor';
import BuildModeViewEditor from '../buildMode/view/BuildModeViewEditor';

interface RightBuildModeEditorProps {
  leftEditor: LeftEditorSectionOptions | null;
}

const RightBuildModeEditor = ({ leftEditor }: RightBuildModeEditorProps) => {
  const project = useSelector(projectDataSelector);
  const { isV2, pagesPath } = useMemo(
    () => getPagesConfig(project.elements, project.settings),
    [project.elements, project.settings],
  );
  const portalPages = useMemo(
    () => (isV2 ? project.elements : get(project.elements, pagesPath, [])),
    [isV2, project.elements, pagesPath],
  );

  return (
    <div
      className={classNames(
        'max-w-84 relative h-full max-h-screen min-h-screen w-full select-none bg-slate-800 text-xs text-white sm:h-auto sm:min-h-0 sm:w-full sm:max-w-none',
      )}
    >
      <SimpleBar
        autoHide={true}
        className="flex h-full max-h-screen w-full flex-grow flex-col overflow-y-auto overflow-x-hidden pb-8"
      >
        <BuildModeEditorRouting
          FormEditor={BuildModeFormEditor}
          leftEditor={leftEditor}
          PageEditor={BuildModePageEditor}
          pages={portalPages}
          RecordEditor={BuildModeRecordEditor}
          ViewEditor={BuildModeViewEditor}
        />
      </SimpleBar>
    </div>
  );
};

export default RightBuildModeEditor;
