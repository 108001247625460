import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Theme, getColorShade } from '@noloco/components';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  ASC,
  DESC,
  OrderByDirection,
} from '../../../constants/orderByDirections';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import Checkbox from '../../Checkbox';

interface AdditionalElement {
  label: string;
  alignRight: boolean;
  isSortActive: boolean;
  onSort: (newSortDirection: any) => void;
}

interface ResizeHandleProps {
  index: number;
  onResize: (width: number, index: number) => void;
}

const ResizeHandle = ({ index, onResize }: ResizeHandleProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      setIsDragging(true);

      let lastX = e.pageX;
      const handleMouseMove = (moveEvent: MouseEvent) => {
        const diff = moveEvent.pageX - lastX;
        lastX = moveEvent.pageX;
        onResize(diff, index);
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [onResize, index],
  );

  return (
    <div
      className={classNames(
        'absolute -right-1 top-0 h-full w-2 cursor-col-resize hover:bg-gray-300',
        { 'bg-gray-300': isDragging },
      )}
      onMouseDown={handleMouseDown}
    />
  );
};

interface CollectionTableHeadProps {
  additionalElements: AdditionalElement[];
  allRowsSelected?: boolean;
  bulkActionsEnabled?: boolean;
  className: string;
  columnWidths: Record<number, number>;
  isSmScreen: boolean;
  maxStickyColumnIndex: number;
  orderBy: { field: string; direction: OrderByDirection };
  setColumnWidths: (
    columnWidths: (
      currentWidthState: Record<number, number>,
    ) => Record<number, number>,
  ) => void;
  setSelectAllRows?: (allRowsSelected: boolean) => void;
  sticky: boolean;
  theme: Theme;
  variables: any;
  resizeable?: boolean;
  parentRef?: React.RefObject<HTMLDivElement>;
}

const MIN_DEFAULT_WIDTH = 150;

const calculateContentBasedWidths = (
  columnWidths: Record<number, number>,
  parentWidth: number,
): Record<number, number> => {
  const totalColumns = Object.values(columnWidths).length;

  // If no columns, return empty object
  if (totalColumns === 0) {
    return {};
  }

  // Calculate total width of all columns
  const totalNaturalWidth = Object.values(columnWidths).reduce(
    (sum, width) => sum + width,
    0,
  );

  // Calculate the ratio to distribute parent width proportionally
  const ratio = parentWidth / totalNaturalWidth;

  // Create new widths object maintaining proportions but scaled to parent width
  const newWidths = Object.entries(columnWidths).reduce(
    (acc, [index, naturalWidth]) => ({
      ...acc,
      [index]: Math.max(Math.floor(naturalWidth * ratio), MIN_DEFAULT_WIDTH),
    }),
    {},
  );

  return newWidths;
};

const CollectionTableHead = memo(
  ({
    additionalElements,
    allRowsSelected = false,
    bulkActionsEnabled = false,
    className,
    columnWidths = {},
    isSmScreen,
    maxStickyColumnIndex,
    orderBy,
    setColumnWidths,
    setSelectAllRows,
    sticky,
    theme,
    variables,
    resizeable = true,
    parentRef,
  }: CollectionTableHeadProps) => {
    const primaryColor = theme.brandColors.primary;
    const [isDarkModeEnabled] = useDarkMode();

    const firstTimeResized = useRef(Object.entries(columnWidths).length === 0);

    const actuallyResizable = useMemo(
      () => resizeable && setColumnWidths,
      [resizeable, setColumnWidths],
    );

    const handleResize = useCallback(
      (diff: number, index: number) =>
        setColumnWidths((currentWidthState) => {
          const currentWidth = currentWidthState[index] || 0;
          const MIN_WIDTH = 50;

          // Check if resize would violate minimum width constraint
          const projectedWidth = currentWidth + diff;

          // If column would go below minimum, prevent resize
          if (projectedWidth < MIN_WIDTH) {
            return currentWidthState;
          }

          // Only update the width of the resized column
          return {
            ...currentWidthState,
            [index]: projectedWidth,
          };
        }),
      [setColumnWidths],
    );

    useEffect(() => {
      if (firstTimeResized.current) {
        firstTimeResized.current = false;

        if (parentRef?.current) {
          const parentWidth = parentRef.current.clientWidth;

          setColumnWidths((columnWidths) =>
            calculateContentBasedWidths(columnWidths, parentWidth),
          );
        }
      }
    }, [parentRef, additionalElements.length, setColumnWidths]);

    return (
      <thead
        className={classNames(
          className,
          {
            [`${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation1
                : 'bg-gray-200 bg-opacity-25'
            }`]: !sticky,
            [`sticky top-0 ${
              isDarkModeEnabled
                ? darkModeColors.surfaces.elevation2
                : 'bg-gray-100'
            } shadow-xs z-30`]: sticky,
          },
          `rounded-tl-lg rounded-tr-lg border-b text-left ${
            isDarkModeEnabled
              ? `${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
              : 'border-gray-200 text-gray-500'
          } w-full text-xs font-medium uppercase tracking-wider`,
        )}
      >
        <tr>
          {!variables.title.hidden && (
            <th>
              <div className="whitespace-nowrap py-2 pl-6 pr-3">
                {variables.title.label}
              </div>
            </th>
          )}
          {!variables.secondaryText.hidden && (
            <th>
              <div
                className={classNames('whitespace-nowrap px-3 py-2', {
                  'pl-6': variables.title.hidden,
                })}
              />
            </th>
          )}
          {bulkActionsEnabled && setSelectAllRows && (
            <th
              className={classNames(
                'flex h-10 w-10 items-center justify-center',
                {
                  'sticky left-0 z-30': !isNil(maxStickyColumnIndex),
                },
                isDarkModeEnabled
                  ? darkModeColors.surfaces.elevation2
                  : 'shadow-r bg-gray-100',
              )}
            >
              <Checkbox
                className="flex w-3"
                size="sm"
                value={allRowsSelected}
                onChange={({
                  target: { checked },
                }: {
                  target: { checked: boolean };
                }) => setSelectAllRows(checked)}
              />
            </th>
          )}
          {additionalElements &&
            additionalElements.map((elementConfig: any, index: any) => (
              <th
                className={classNames(
                  'relative',
                  {
                    [`${bulkActionsEnabled ? 'left-10' : 'left-0'} sticky z-20`]:
                      !isNil(maxStickyColumnIndex) &&
                      index <= maxStickyColumnIndex,
                    'table-fixed': true,
                  },
                  `${
                    isDarkModeEnabled
                      ? `${darkModeColors.surfaces.elevation2} border-r ${darkModeColors.borders.two}`
                      : 'shadow-r bg-gray-100'
                  }`,
                )}
                style={{
                  minWidth: columnWidths[index]
                    ? `${columnWidths[index]}px`
                    : undefined,
                  width:
                    actuallyResizable && index === additionalElements.length - 1
                      ? '100%'
                      : undefined,
                  maxWidth:
                    index === additionalElements.length - 1
                      ? columnWidths[index]
                        ? `${columnWidths[index]}px`
                        : undefined
                      : undefined,
                }}
                key={elementConfig.label}
                ref={
                  setColumnWidths
                    ? (el: HTMLTableCellElement) => {
                        if (el && columnWidths[index] === undefined) {
                          setColumnWidths(
                            (currentWidthState: Record<number, number>) => ({
                              ...currentWidthState,
                              [index]:
                                isSmScreen &&
                                !isNil(maxStickyColumnIndex) &&
                                index <= maxStickyColumnIndex
                                  ? Math.min(
                                      document.documentElement.clientWidth *
                                        0.4,
                                      el.clientWidth,
                                    )
                                  : index === additionalElements.length - 1
                                    ? MIN_DEFAULT_WIDTH
                                    : el.offsetWidth,
                            }),
                          );
                        }
                      }
                    : undefined
                }
              >
                <div
                  className={classNames(
                    'flex items-center px-3 py-2',
                    'min-w-0',
                    {
                      'justify-end': elementConfig.alignRight,
                      'pl-6':
                        index === 0 &&
                        variables.title.hidden &&
                        variables.secondaryText.hidden &&
                        !bulkActionsEnabled,
                    },
                  )}
                >
                  <span className="truncate">{elementConfig.label}</span>
                  {elementConfig.onSort && (
                    <div className="ml-3 flex flex-shrink-0 flex-col">
                      <button
                        className={classNames(
                          `hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === ASC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(ASC)}
                      >
                        <IconChevronUp size={13} strokeWidth={3} />
                      </button>
                      <button
                        className={classNames(
                          `-mt-1 hover:text-${getColorShade(
                            primaryColor,
                            500,
                          )} hover:opacity-100`,

                          elementConfig.isSortActive &&
                            orderBy &&
                            orderBy.direction === DESC
                            ? `text-${getColorShade(primaryColor, 500)}`
                            : 'opacity-50',
                        )}
                        onClick={() => elementConfig.onSort(DESC)}
                      >
                        <IconChevronDown size={13} strokeWidth={3} />
                      </button>
                    </div>
                  )}
                </div>
                {actuallyResizable && (
                  <ResizeHandle index={index} onResize={handleResize} />
                )}
              </th>
            ))}
        </tr>
      </thead>
    );
  },
);

export default withTheme(CollectionTableHead);
