import { Suspense, lazy, memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import colors from 'tailwindcss/colors';
import { Loader, RatingInput, Theme } from '@noloco/components';
import { Aggregation, COUNT, SUM } from '../constants/aggregationTypes';
import {
  AREA,
  BAR,
  ChartType,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '../constants/chartTypes';
import { ColorPalette } from '../constants/colorPalettes';
import {
  DATE,
  DECIMAL,
  DataFieldType,
  INTEGER,
  SINGLE_OPTION,
} from '../constants/dataTypes';
import {
  DAY as DAY_FORMAT,
  MONTH_SHORT as MONTH_FORMAT,
  YEAR as YEAR_FORMAT,
} from '../constants/dateFormatOptions';
import { RATING } from '../constants/fieldFormats';
import { DESC, OrderByDirection } from '../constants/orderByDirections';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import { AUTO, DAY, TimePeriod, WEEK } from '../constants/timePeriods';
import DataTypes, { DataType } from '../models/DataTypes';
import { DepValue, Element } from '../models/Element';
import { Project } from '../models/Project';
import { BaseRecord, RecordEdge, RecordValue } from '../models/Record';
import { aggregateNumericalData } from '../utils/aggregationDataTypes';
import { getChartColorPalette } from '../utils/chartColorPalette';
import {
  getFieldFromAxisValuePath,
  getFieldPathFromPath,
  isMetricChart,
} from '../utils/charts';
import { getColorByIndex } from '../utils/colors';
import { conditionsAreMet } from '../utils/data';
import { findPreviewFields } from '../utils/dataTypes';
import { getDateFromValue, getLabelFromDate } from '../utils/dates';
import { getFieldFromDependency } from '../utils/fields';
import { useFormatDateForChartData } from '../utils/hooks/useFormatDateForChartData';
import { Group } from './sections/Collection';
import GaugeChart from './sections/charts/GaugeChart';
import StatisticChart from './sections/charts/StatisticChart';
import { XYChartType } from './sections/charts/XYChart';
import {
  CellFills,
  ChartSeries,
  Datum,
  MAX_X_VALUES_CHART_TYPES,
  MetricData,
  SeriesData,
} from './sections/charts/chartTypes';
import { formatValue } from './sections/collections/FieldCell';

export const sampleData = [...Array(12)].map((__, index) => ({
  y: index * Math.ceil(Math.random() * 10),
  x: new Date(new Date().setMonth(index)).toISOString(),
  g: `Group ${index + 1}`,
}));

export const metricData = [...Array(12)].map((__, index) => ({
  x: index,
  g: `Group ${index + 1}`,
}));

export const sampleXValue = { id: 'sample', path: 'edges.x', dataType: DATE };
export const sampleXMetricValue = {
  id: 'sample',
  path: 'edges.x',
  dataType: INTEGER,
};
export const sampleYValue = {
  id: 'sample',
  path: 'edges.y',
  dataType: DECIMAL,
};

export const formatDatum = (type: any, value: any, timePeriod?: any) => {
  if (!value || value === 'Unset') {
    return value;
  }

  if (type === DATE) {
    const dateValue = getDateFromValue(value);

    if (dateValue && dateValue.isValid) {
      if (timePeriod) {
        const dateTimePeriod = getDateTimePeriod(timePeriod);

        return getLabelFromDate(dateValue.startOf(dateTimePeriod), timePeriod);
      }

      return dateValue.toJSDate();
    }

    return null;
  }

  // Check if the value is entirely numeric and parse it only if it's numeric
  if (/^-?\d+(\.\d+)?$/.test(value)) {
    const maybeNumber = parseFloat(value);

    if (!isNaN(maybeNumber)) {
      return maybeNumber;
    }
  }

  return value;
};

// @ts-expect-error TS(7031): Binding element 'xA' implicitly has an 'any' type.
const numericSort = ({ x: xA }, { x: xB }) => xA - xB;

export const getDateTimePeriod = (timePeriod?: TimePeriod) => {
  if (timePeriod === AUTO) {
    return DAY;
  }

  return timePeriod || DAY;
};

const aggregateSeries = (series: any, values: any, aggregation: any, x: any) =>
  series.reduce(
    // @ts-expect-error TS(7006): Parameter 'seriesAcc' implicitly has an 'any' type... Remove this comment to see the full error message
    (seriesAcc, chartSeries) => ({
      ...seriesAcc,
      [chartSeries.id]:
        aggregateNumericalData(
          values.map((value: any) => value[chartSeries.id]),
          aggregation,
        ) ?? null,
    }),
    { x },
  );

const transformRawMetricData = (
  data: { x: RecordValue }[],
  xAxisValue: DepValue,
  dataType: DataType,
  aggregation: Aggregation = SUM,
): MetricData => {
  let filteredData = data.map((item) => item.x).filter((x) => !isNil(x));
  const xAxisField = getFieldFromAxisValuePath(xAxisValue.path, dataType);

  if (xAxisField && xAxisField.type === DATE) {
    filteredData.map((x) => DateTime.fromISO(x as string).toMillis());
  }

  const metric = aggregateNumericalData(filteredData as number[], aggregation);

  return { x: metric };
};

const isValidMaxXValues = (
  maxXValues: any,
  chartType: ChartType,
  xAxisType: string | undefined,
) =>
  MAX_X_VALUES_CHART_TYPES.includes(chartType) &&
  xAxisType !== DATE &&
  maxXValues &&
  typeof maxXValues === 'number';

const groupByDate = (
  data: any[],
  timePeriod: TimePeriod,
  dateAccessor: (datum: any) => Date,
  transformDataValue: (date: DateTime) => DateTime = identity,
) => {
  const dateData = data.reduce(
    (commonDates: Record<number, Datum[]>, datum: Datum) => {
      const dateValue = transformDataValue(
        DateTime.fromJSDate(dateAccessor(datum)),
      );

      if (!dateValue.isValid) {
        return commonDates;
      }
      const dateTimePeriod = getDateTimePeriod(timePeriod);

      const dateInMillis = dateValue.startOf(dateTimePeriod).toMillis();

      if (commonDates[dateInMillis]) {
        return set(
          [dateInMillis, commonDates[dateInMillis].length],
          datum,
          commonDates,
        );
      }

      return set([dateInMillis], [datum], commonDates);
    },
    {},
  );

  return Object.entries(dateData).map(
    ([dateInMillis, values]: [string, any]) => ({
      dateInMillis,
      values,
    }),
  );
};

const transformRawData = (
  data: any,
  xAxisValue: any,
  dataType: any,
  dataTypes: any,
  timePeriod: any,
  aggregation: any,
  sortOptions: any,
  series: any,
  transformDataValue: (date: DateTime) => DateTime = identity,
): SeriesData => {
  const xAxisType = xAxisValue.dataType;

  if (xAxisType === DATE) {
    const dateData = groupByDate(
      data,
      timePeriod,
      (datum: any) => datum.x,
      transformDataValue,
    );

    return dateData
      .map(
        ({ dateInMillis, values }: { dateInMillis: string; values: any[] }) =>
          aggregateSeries(series, values, aggregation, Number(dateInMillis)),
      )
      .sort(numericSort)
      .map((datum) => ({
        ...datum,
        x: datum.x !== null ? DateTime.fromMillis(datum.x).toJSDate() : datum.x,
      }));
  }

  const groupedData = data.reduce((commonValues: any, datum: any) => {
    const keyStr =
      PRIMITIVE_DATA_TYPES.includes(xAxisType) || !xAxisValue.path
        ? String(datum.x)
        : get(datum, 'x.id');

    if (commonValues[keyStr]) {
      return set([keyStr, commonValues[keyStr].length], datum, commonValues);
    }

    return set([keyStr], [datum], commonValues);
  }, {});

  const reducedData = Object.entries(groupedData).map(([keyStr, values]) =>
    aggregateSeries(series, values, aggregation, keyStr),
  );

  if (sortOptions) {
    const valueIdMap = data.reduce((idMap: any, datum: any) => {
      const keyStr = get(datum, 'x.id');

      return set([keyStr], datum.x, idMap);
    }, {});

    return reducedData.sort(({ primary: primaryA }, { primary: primaryB }) => {
      const { direction, field } = sortOptions;
      const valueA = get(valueIdMap, [primaryA, field], null);
      const valueB = get(valueIdMap, [primaryB, field], null);

      if (!valueA && !valueB) {
        return 0;
      }

      if (!valueA && valueB) {
        return 1;
      }

      if (valueA && !valueB) {
        return -1;
      }

      if (!direction || direction === DESC) {
        return valueB > valueA ? 1 : -1;
      }

      return valueB > valueA ? -1 : 1;
    });
  }

  if (xAxisType === DECIMAL || xAxisType === INTEGER) {
    return reducedData
      .filter((datum) => datum.x !== 'null')
      .map((datum) => ({
        ...datum,
        x: parseFloat(datum.x),
      }))
      .sort(numericSort);
  }

  if (dataType && xAxisType === SINGLE_OPTION) {
    const xAxisFieldFromDependency = getFieldFromDependency(
      xAxisValue.path.split('.'),
      dataType,
      dataTypes,
    );
    const xAxisField = xAxisFieldFromDependency?.field;

    if (xAxisField && xAxisField.type === SINGLE_OPTION) {
      const optionOrderMap = xAxisField.options?.reduce((map, option) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        map[option.name] = option.order;

        return map;
      }, {});

      return reducedData.sort(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (valueA, valueB) => optionOrderMap[valueA.x] - optionOrderMap[valueB.x],
      );
    }
  }

  const firstSeries = first(series);
  const sortedData = reducedData.sort(
    ({ [(firstSeries as any).id]: yA }, { [(firstSeries as any).id]: yB }) =>
      yB - yA,
  );

  return sortedData;
};

const recordLabelFormatter = (
  axisValuePath: string,
  nodes: BaseRecord[],
  relatedDataType: DataType,
  id: string,
) => {
  const { textFields } = findPreviewFields(
    relatedDataType.fields,
    relatedDataType,
  );

  const idEdge = nodes.find(
    (node) =>
      (axisValuePath
        ? get(node, `${getFieldPathFromPath(axisValuePath)}.id`)
        : String(node.id)) === id,
  );

  if (textFields.length === 0 || !idEdge) {
    return id;
  }

  const fieldPath = axisValuePath
    ? `${getFieldPathFromPath(axisValuePath)}.`
    : '';

  return textFields
    .map((textField) => get(idEdge, `${fieldPath}${textField.name}`))
    .join(' ');
};

const periodsWithDays: TimePeriod[] = [AUTO, WEEK, DAY];

export const axisFormatter =
  (
    axisValue: DepValue,
    dataType: DataType,
    dataTypes: DataTypes,
    edges: BaseRecord[],
    timePeriod?: TimePeriod,
    useRawValue = false,
    transformDataValue: (date: DateTime) => DateTime = identity,
  ) =>
  (value: any) => {
    if (!axisValue || !axisValue.dataType) {
      return value;
    }

    const axisType = axisValue.dataType;

    if (dataType && axisType === SINGLE_OPTION) {
      const axisFieldFromDependency = getFieldFromDependency(
        axisValue.path.split('.'),
        dataType,
        dataTypes,
      );
      const axisField = axisFieldFromDependency?.field;

      if (axisField && axisField.type === SINGLE_OPTION) {
        const option = axisField.options?.find((op) => op.name === value);

        return option ? option.display : value;
      }
    }

    if (PRIMITIVE_DATA_TYPES.includes(axisType as DataFieldType)) {
      if (dataType) {
        const axisField = getFieldFromAxisValuePath(axisValue.path, dataType);

        if (axisField) {
          if (
            value !== null &&
            axisField.typeOptions?.format === RATING &&
            useRawValue
          ) {
            return (
              <RatingInput
                className="my-2"
                disabled={true}
                maxRating={get(axisField.typeOptions, 'max')}
                value={value}
              />
            );
          }

          if (axisField.type === DATE) {
            const jsDate = new Date(value);
            const dateTime = transformDataValue(DateTime.fromJSDate(jsDate));

            return getLabelFromDate(dateTime, timePeriod);
          }

          return value !== null
            ? formatValue(value, axisField, {
                format: undefined,
                dateFormat: `${
                  periodsWithDays.includes(timePeriod!) ? DAY_FORMAT : ''
                } ${MONTH_FORMAT} ${YEAR_FORMAT}`,
              })
            : null;
        }
      } else if (axisType === DATE) {
        const jsDate = new Date(value);
        const dateTime = transformDataValue(DateTime.fromJSDate(jsDate));

        return getLabelFromDate(dateTime, timePeriod);
      }

      return value;
    }

    const relatedDataType = dataTypes.getByName(axisType);

    if (!relatedDataType) {
      return value;
    }

    return recordLabelFormatter(axisValue.path, edges, relatedDataType, value);
  };

const LazyXYChart = lazy(() => import('./sections/charts/XYChart'));
const LazyPieChart = lazy(() => import('./sections/charts/PieChartWithLegend'));
const LazyFunnelChart = lazy(() => import('./sections/charts/FunnelChart'));
const LazyRadarChart = lazy(() => import('./sections/charts/RadarChart'));

const ChartLoader = ({ children }: { children: any }) => (
  <Suspense fallback={<Loader className="mx-auto py-16" />}>
    {children}
  </Suspense>
);

interface ChartDataProps {
  aggregation: Aggregation;
  chartId: string;
  chartType: ChartType;
  timePeriod: TimePeriod;
  dataType: DataType;
  dataTypes: DataTypes;
  EmptyState: any;
  element: Element;
  max: number | undefined;
  maxXValues: number | undefined;
  nodes: BaseRecord[];
  series: ChartSeries[];
  palette: ColorPalette | undefined;
  project: Project;
  sortOptions: {
    direction: OrderByDirection;
    field: string;
  } | null;
  scope: Record<string, any>;
  theme: Theme;
  useOptionColors: boolean;
  xAxisLabel: string | undefined;
  xAxisValue: DepValue;
  yAxisLabel: string | undefined;
  stackedGroups: Group[] | undefined;
}

export const ChartData = memo(
  ({
    aggregation,
    chartId,
    chartType,
    timePeriod,
    dataType,
    dataTypes,
    EmptyState,
    element,
    max,
    maxXValues,
    nodes,
    series = [],
    palette,
    project,
    sortOptions,
    scope,
    theme,
    useOptionColors,
    xAxisLabel,
    xAxisValue,
    yAxisLabel,
    stackedGroups,
  }: ChartDataProps) => {
    const xAxisType = xAxisValue.dataType;
    const transformDataValue = useFormatDateForChartData(
      xAxisValue,
      dataType,
      dataTypes,
    );
    const primaryAxisFormatter = useMemo(
      () =>
        axisFormatter(
          xAxisValue,
          dataType,
          dataTypes,
          nodes,
          timePeriod,
          false,
          transformDataValue,
        ),
      [xAxisValue, dataType, dataTypes, nodes, timePeriod, transformDataValue],
    );

    const formatSeriesValue = useCallback(
      (chartSeries: ChartSeries, value: number) =>
        axisFormatter(
          get(chartSeries, ['yAxisValue']),
          dataType,
          dataTypes,
          nodes,
          undefined,
          true,
        )(value),
      [dataType, dataTypes, nodes],
    );

    const secondaryAxisFormatter = useMemo(() => {
      if (aggregation === COUNT) {
        return identity;
      }

      return axisFormatter(
        get(series, [0, 'yAxisValue']),
        dataType,
        dataTypes,
        nodes,
      );
    }, [aggregation, series, dataType, dataTypes, nodes]);

    const xAxisField = useMemo(
      () => xAxisValue && getFieldFromAxisValuePath(xAxisValue.path, dataType),
      [dataType, xAxisValue],
    );

    const cellFillsMap: CellFills | null = useMemo(() => {
      if (useOptionColors && xAxisField && xAxisField.type === SINGLE_OPTION) {
        const nullColor = getColorByIndex(xAxisField.options!.length).split(
          '-',
        )[0];

        return xAxisField.options!.reduce(
          (mapAcc, option) => ({
            ...mapAcc,

            [option.name]:
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              colors[
                option.color || getColorByIndex(option.order).split('-')[0]
              ][400],
          }),
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          { null: colors[nullColor][400] },
        );
      }

      return null;
    }, [useOptionColors, xAxisField]);

    const fills = useMemo(
      () => getChartColorPalette(palette, theme),
      [palette, theme],
    );

    const groupsSeries: ChartSeries[] = useMemo(() => {
      if (chartType === STACKED_BAR && Array.isArray(stackedGroups)) {
        const yAxisValue = series[0].yAxisValue;

        return stackedGroups.map((group) => ({
          id: group.id,
          label: group.label,
          yAxisValue,
        })) as ChartSeries[];
      }

      return [];
    }, [chartType, stackedGroups, series]);

    const data = useMemo(() => {
      if (chartType === STACKED_BAR && Array.isArray(stackedGroups)) {
        const yAxisValue = series[0].yAxisValue;

        const rawData = stackedGroups.reduce((acc, group) => {
          (group.rows ?? []).forEach((row: RecordEdge) => {
            const conditions = series[0].conditions;

            if (conditions && conditions.length !== 0) {
              const conditionsMet = conditionsAreMet(
                conditions,
                { ...scope, [`${element.id}:VIEW`]: row.node },
                project,
              );

              if (!conditionsMet) {
                return acc;
              }
            }
            const x = formatDatum(
              xAxisValue.dataType,
              get(row.node, getFieldPathFromPath(xAxisValue.path || 'id'), 0),
            );

            const y = formatDatum(
              yAxisValue.dataType,
              get(row.node, getFieldPathFromPath(yAxisValue.path || 'id'), 0),
            );

            acc.push({ x, [group.id]: y, group: group.id });
          });

          return acc;
        }, [] as SeriesData);

        const transformedData = transformRawData(
          rawData,
          xAxisValue,
          dataType,
          dataTypes,
          timePeriod,
          aggregation,
          sortOptions,
          groupsSeries,
        );

        if (isValidMaxXValues(maxXValues, chartType, xAxisType)) {
          return transformedData.slice(0, maxXValues);
        }

        return transformedData;
      }

      const rawData = nodes.map((edge: any) =>
        series.reduce(
          (seriesAcc, chartSeries) => {
            if (chartSeries.conditions && chartSeries.conditions.length !== 0) {
              const conditionsMet = conditionsAreMet(
                chartSeries.conditions,
                { ...scope, [`${element.id}:VIEW`]: edge },
                project,
              );

              if (!conditionsMet) {
                return seriesAcc;
              }
            }

            return {
              ...seriesAcc,

              [chartSeries.id]: chartSeries.yAxisValue
                ? formatDatum(
                    chartSeries.yAxisValue.dataType,
                    get(
                      edge,
                      getFieldPathFromPath(chartSeries.yAxisValue.path),
                      0,
                    ),
                  )
                : null,
            };
          },
          {
            x: formatDatum(
              xAxisValue.dataType,
              get(edge, getFieldPathFromPath(xAxisValue.path || 'id'), 0),
            ),
          },
        ),
      );

      if (isMetricChart(chartType)) {
        return transformRawMetricData(
          rawData,
          xAxisValue,
          dataType,
          aggregation,
        );
      }

      const transformedData = transformRawData(
        rawData,
        xAxisValue,
        dataType,
        dataTypes,
        timePeriod,
        aggregation,
        sortOptions,
        series,
        transformDataValue,
      );

      if (isValidMaxXValues(maxXValues, chartType, xAxisType)) {
        return transformedData.slice(0, maxXValues);
      }

      return transformedData;
    }, [
      chartType,
      stackedGroups,
      nodes,
      xAxisValue,
      dataType,
      dataTypes,
      timePeriod,
      aggregation,
      sortOptions,
      series,
      transformDataValue,
      maxXValues,
      xAxisType,
      groupsSeries,
      scope,
      element.id,
      project,
    ]);

    const showEmptyState = useMemo(() => {
      if (!EmptyState) {
        return false;
      }

      return (
        !isMetricChart(chartType) && data && (data as SeriesData).length === 0
      );
    }, [EmptyState, chartType, data]);

    return (
      <div
        className={classNames(
          'relative w-full min-w-0',
          {
            'h-96': !isMetricChart(chartType),
            'flex h-full': isMetricChart(chartType),
            'items-end': chartType === STATISTIC,
            'items-center': chartType === GAUGE,
            'sm:overflow-hidden': chartType === GAUGE,
          },
          `chart-${chartId}`,
        )}
      >
        {showEmptyState && <EmptyState />}
        {!showEmptyState && (
          <>
            {[LINE, STACKED_BAR, BAR, AREA].includes(chartType) && (
              <ChartLoader>
                <LazyXYChart
                  cellFillsMap={cellFillsMap}
                  chartType={chartType as XYChartType}
                  data={data as SeriesData}
                  fills={fills}
                  formatSeriesValue={formatSeriesValue}
                  primaryAxisFormatter={primaryAxisFormatter}
                  secondaryAxisFormatter={secondaryAxisFormatter}
                  series={chartType === STACKED_BAR ? groupsSeries : series}
                  xAxisLabel={xAxisLabel}
                  xAxisType={xAxisType!}
                  yAxisLabel={yAxisLabel}
                />
              </ChartLoader>
            )}
            {chartType === PIE && (
              <ChartLoader>
                <LazyPieChart
                  cellFillsMap={cellFillsMap}
                  data={data as SeriesData}
                  fills={fills}
                  formatSeriesValue={formatSeriesValue}
                  primaryAxisFormatter={primaryAxisFormatter}
                  series={series}
                  chartId={chartId}
                />
              </ChartLoader>
            )}
            {chartType === FUNNEL && (
              <ChartLoader>
                <LazyFunnelChart
                  cellFillsMap={cellFillsMap}
                  data={data as SeriesData}
                  fills={fills}
                  primaryAxisFormatter={primaryAxisFormatter}
                  secondaryAxisFormatter={secondaryAxisFormatter}
                  series={series}
                  chartId={chartId}
                />
              </ChartLoader>
            )}
            {chartType === RADAR && (
              <ChartLoader>
                <LazyRadarChart
                  data={data as SeriesData}
                  fills={fills}
                  formatSeriesValue={formatSeriesValue}
                  primaryAxisFormatter={primaryAxisFormatter}
                  series={series}
                />
              </ChartLoader>
            )}
            {chartType === STATISTIC && (
              <StatisticChart
                data={data as MetricData}
                primaryAxisFormatter={primaryAxisFormatter}
              />
            )}
            {chartType === GAUGE && (
              <GaugeChart
                data={data as MetricData}
                fills={fills}
                max={max}
                primaryAxisFormatter={primaryAxisFormatter}
                xAxisLabel={xAxisLabel}
                xAxisField={xAxisField!}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

export default ChartData;
