import {
  AREA,
  BAR,
  LINE,
  PIE,
  STACKED_BAR,
} from '../../../constants/chartTypes';
import { ColorPalette } from '../../../constants/colorPalettes';
import { Condition, DepValue } from '../../../models/Element';

export const MAX_X_VALUES_CHART_TYPES = [BAR, AREA, STACKED_BAR, LINE, PIE];

export type Datum = { x: Date | number | string } & Record<string, number>;

export interface ChartSeries {
  id: string;
  label?: string;
  yAxisValue: DepValue;
  palette?: ColorPalette;
  conditions?: Condition[][];
}

export interface MetricData {
  x: number | null;
}

export type SeriesData = Datum[];

export type CellFills = Record<string, string> & { null: string };
