import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMutation, useSubscription } from '@apollo/client';
import { captureException, captureMessage } from '@sentry/react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import initial from 'lodash/initial';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { useDispatch, useSelector } from 'react-redux';
import { ReadyState } from 'react-use-websocket';
import shortid from 'shortid';
import { BaseModal, Loader } from '@noloco/components';
import { BASE_WS_URI } from '@noloco/core/src/constants/auth';
import { getJitteredPollInterval } from '@noloco/core/src/constants/polling';
import { Element, ElementPath, Section } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import {
  setSelectedElement,
  setSelectedSectionPath,
} from '@noloco/core/src/reducers/elements';
import {
  setHasUnpublishedChanges,
  updateProject as updateProjectState,
} from '@noloco/core/src/reducers/project';
import { editorModeSelector } from '@noloco/core/src/selectors/elementsSelectors';
import { projectDataSelector } from '@noloco/core/src/selectors/projectSelectors';
import { trackElementAdded } from '@noloco/core/src/utils/analytics';
import { useErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { useAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useUserPermissions from '@noloco/core/src/utils/hooks/useUserPermissions';
import useWebsocketWithHeartbeat from '@noloco/core/src/utils/hooks/useWebsocketWithHeartbeat';
import { getText } from '@noloco/core/src/utils/lang';
import { IS_PLAYGROUND } from '../../constants/env';
import { PROJECT_SUBSCRIPTION, UPDATE_PROJECT } from '../../queries/project';
import {
  cloneElement,
  generateNewElementFromNodeShape,
  getSelectedElementConfig,
} from '../elements';

export const UPDATE_DEBOUNCE_MS = 600;

const publishedUpdates: any = [];
const acknowledgedUpdates: any = [];

export const useSubscribeToProjectUpdates = () => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const projectName = project && project.name;
  const onSubscriptionData = useCallback(
    ({ subscriptionData }: any) => {
      if (subscriptionData && subscriptionData.data && !IS_PLAYGROUND) {
        const { path, value, id } = subscriptionData.data.projectUpdated;
        const formattedValue = value === null ? undefined : value;
        const updatedProject = set(path, formattedValue, project);

        if (
          !publishedUpdates.includes(id) &&
          !isEqual(updatedProject, project) &&
          id
        ) {
          dispatch(
            updateProjectState({
              value: formattedValue,
              path,
            }),
          );
        }
      }
    },
    [dispatch, project],
  );

  useSubscription(PROJECT_SUBSCRIPTION, {
    variables: { projectName: projectName },
    onSubscriptionData,
  });
};

interface ProjectUpdatesContextType {
  onUpdateProject: (
    path: ElementPath,
    value: any,
    config: Record<string, any>,
    project: Project,
  ) => void;
  isConnected: boolean;
  isLoading: boolean;
}

const projectUpdatesContext = createContext<ProjectUpdatesContextType>({
  onUpdateProject: () => undefined,
  isConnected: false,
  isLoading: false,
});

let updatesQueue: any = [];

const useProvideProjectUpdates = (projectName: any) => {
  const { token } = useAuth();
  const { isBuilder } = useUserPermissions();
  const errorAlert = useErrorAlert();
  const dispatch = useDispatch();
  const [pendingUpdates, setPendingUpdates] = useState([]);

  const onNewMessage = useCallback(
    (messageEvent: any) => {
      let data: any = null;

      try {
        data = JSON.parse(messageEvent.data);

        if (data.id && publishedUpdates.includes(data.id)) {
          acknowledgedUpdates.push(data.id);

          setPendingUpdates((currentUpdates) =>
            currentUpdates.filter((update) => (update as any).id !== data.id),
          );
        }

        if (data.error) {
          console.error('Webhook returned error', data.error);
          errorAlert(
            getText('project.errors.updateConnection.title'),
            data.error,
          );
        }

        if (data.hasUnpublishedChanges !== undefined) {
          dispatch(setHasUnpublishedChanges(data.hasUnpublishedChanges));
        }
      } catch (e) {
        console.error('Unable to parse payload', messageEvent.data);
        captureException(e);
      }
    },
    [dispatch, errorAlert],
  );

  const reconnectInterval = useMemo(
    () => getJitteredPollInterval(8000, 0.8),
    [],
  );

  const { readyState, sendJsonMessage } = useWebsocketWithHeartbeat(
    `${BASE_WS_URI}/project/${projectName}/update?authToken=${token}`,
    {
      heartBeatInterval: 30_000,
      onMessage: onNewMessage,
      share: true,
      shouldReconnect: () => true,
      reconnectAttempts: Infinity,
      reconnectInterval,
      retryOnError: true,
      onOpen: () => {
        console.info('[Project Update Webhook] Status: connected');
      },
      onClose: () => {
        console.info('[Project Update Webhook] Status: disconnected');
      },
      onError: (error) => {
        if (publishedUpdates.length > acknowledgedUpdates.length) {
          errorAlert(getText('project.errors.updateConnection.title'), {
            description: getText('project.errors.updateConnection.subtitle'),
          });
        }
        console.error('Error updating project via websocket', {
          error,
          publishedUpdates,
          acknowledgedUpdates,
        });
        captureMessage('Failed to update project via websocket');
      },
    },
    !!token && isBuilder,
  );

  const onUpdateProject = useCallback(
    (
      path: ElementPath,
      value: any,
      { skipHistory = false } = {},
      project: Project,
    ) => {
      if (path.join('.').startsWith('elements.props')) {
        const error = getText('project.errors.update');
        console.error(error);
        errorAlert(error);

        return Promise.reject({ error });
      }

      const update = { path, value, projectName };
      updatesQueue.push(update);
      updatesQueue = updatesQueue.slice(updatesQueue.indexOf(update) + 1);

      dispatch(updateProjectState({ ...update, skipHistory }));
      dispatch(setHasUnpublishedChanges(true));

      const stringPath = path.map((p: any) => String(p));
      const id = shortid.generate();
      const updateObject = {
        path: stringPath,
        value,
        id,
      };

      for (let i = 1; i < stringPath.length; i++) {
        const pathSegment = stringPath.slice(0, i);
        const pathValue = get(project, pathSegment);

        // If the current value of any part of the path (besides the last value)
        // is explicitly null then MongoDB fails to write to it, so instead we
        // use the value that Lodash sets when the path is split up
        if (pathValue === null) {
          updateObject.path = pathSegment;
          const updatedProject = set(path, value, project);
          updateObject.value = get(updatedProject, pathSegment);
          break;
        }
      }

      publishedUpdates.push(id);
      // @ts-expect-error TS(2345): Argument of type '(currentUpdates: never[]) => { p... Remove this comment to see the full error message
      setPendingUpdates((currentUpdates) => [...currentUpdates, updateObject]);

      try {
        return sendJsonMessage(updateObject);
      } catch (e) {
        captureException(e);
        console.error(e);
        errorAlert(getText('project.errors.update'));
      }
    },
    [dispatch, errorAlert, projectName, sendJsonMessage],
  );

  return {
    onUpdateProject,
    pendingUpdates,
    isConnected: readyState === ReadyState.OPEN,
    isLoading: pendingUpdates.length > 0,
  };
};

export type UpdatePropertyCallback = (path: ElementPath, value: any) => void;

export type UpdateProjectCallback = (
  path: ElementPath,
  value: any,
  config?: Record<string, any>,
) => any;

interface UpdateResult {
  isLoading: boolean;
}
export type UpdateProjectResult = [UpdateProjectCallback, UpdateResult];

export const useUpdateProject = (project: Project): UpdateProjectResult => {
  const { onUpdateProject, isConnected, isLoading } = useContext(
    projectUpdatesContext,
  );

  const [pendingUpdates, setPendingUpdates] = useState<any[]>([]);

  const handleUpdateProject = useCallback(
    (path: ElementPath, value: any, config: any) => {
      if (isConnected) {
        return onUpdateProject(path, value, config, project);
      } else {
        setPendingUpdates((prevPendingUpdates) => [
          ...prevPendingUpdates,
          { path, value, config },
        ]);
      }
    },
    [isConnected, onUpdateProject, project],
  );

  useEffect(() => {
    if (isConnected && pendingUpdates.length > 0) {
      setPendingUpdates([]);
      pendingUpdates.forEach(({ path, value, config }) =>
        handleUpdateProject(path, value, config),
      );
    }
  }, [handleUpdateProject, isConnected, pendingUpdates, pendingUpdates.length]);

  return [handleUpdateProject, { isLoading }];
};

export const ProvideProjectUpdates = ({ children, projectName }: any) => {
  const editorMode = useSelector(editorModeSelector);
  const projectUpdates = useProvideProjectUpdates(projectName);

  return (
    <projectUpdatesContext.Provider value={projectUpdates}>
      {editorMode && !projectUpdates.isConnected && (
        <BaseModal>
          <div className="flex flex-col space-y-4 bg-slate-800 py-10 text-slate-200">
            <span className="mx-auto">{getText('editor.connecting')}</span>
            <Loader className="mx-auto text-blue-400" />
          </div>
        </BaseModal>
      )}
      {children}
    </projectUpdatesContext.Provider>
  );
};

export const useUpdateElements = (project: Project): UpdateProjectResult => {
  const [updateProject, mutation] = useUpdateProject(project);
  const onUpdate = useCallback(
    (path: ElementPath, value: any, projectName: any) =>
      updateProject(['elements', ...path], value, projectName),
    [updateProject],
  );

  return [onUpdate, mutation];
};

export const useUpdateSpace = (project: Project): UpdateProjectResult => {
  const [updateProject, mutation] = useUpdateProject(project);
  const onUpdate = useCallback(
    (path: ElementPath, value: any) =>
      updateProject(['spaces', ...path], value),
    [updateProject],
  );

  return [onUpdate, mutation];
};

export const useRemoveSelected = (
  project: Project,
  selectedPath: ElementPath,
  postRemove = identity,
): [() => any, UpdateResult] => {
  const siblingsPath = initial(selectedPath);
  const dispatch = useDispatch();
  const [updateElements, mutation] = useUpdateElements(project);
  const removeSelected = useCallback(() => {
    const element = get(project.elements, selectedPath);

    if (element.static) {
      return null;
    }
    const selectedIndex = last(selectedPath);
    const siblings =
      siblingsPath.length === 0
        ? project.elements
        : get(project.elements, siblingsPath);

    if (siblings) {
      dispatch(setSelectedElement(initial(siblingsPath)));

      return updateElements(
        siblingsPath,
        postRemove(
          siblings.filter(
            // @ts-expect-error TS(2345): Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
            (__: any, index: any) => index !== parseInt(selectedIndex, 10),
          ),
          // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
          element,
        ),
      );
    }
  }, [
    project.elements,
    selectedPath,
    siblingsPath,
    dispatch,
    updateElements,
    postRemove,
  ]);

  return [removeSelected, mutation];
};

type AddChildResult = [
  (newChild: Element, newIndex?: number) => any,
  UpdateResult,
];

export const useAddChild = (
  project: any,
  parentPath: any = [],
  additionalPath: ElementPath = [],
): AddChildResult => {
  const [updateElements, mutation] = useUpdateElements(project);
  const addChild = useCallback(
    (newChild: any, newPageIndex: any) => {
      const selectedElement =
        parentPath.length > 0
          ? get(project.elements, parentPath)
          : project.elements;
      // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
      const isNodeProp = isNaN(last(parentPath)) || parentPath.length === 0;
      const newChildren = [
        ...((selectedElement &&
          (isNodeProp ? selectedElement : selectedElement.children)) ||
          []),
      ];

      if (newPageIndex !== undefined && newPageIndex >= 0) {
        newChildren.splice(newPageIndex, 0, newChild);
      } else {
        newChildren.push(newChild);
      }

      updateElements([...parentPath, ...additionalPath], newChildren);

      return newChild;
    },
    [additionalPath, parentPath, project.elements, updateElements],
  );

  return [addChild, mutation];
};

export const useAddSibling = (
  project: Project,
  path: ElementPath,
  additionalPath: ElementPath = [],
): AddChildResult => {
  const rootPath = initial(path);
  const parentPath =
    last(rootPath) === 'children' ? initial(rootPath) : rootPath;
  const isParentNodeProp = isNaN(last(parentPath) as number);

  return useAddChild(
    project,
    parentPath,
    isParentNodeProp ? [] : additionalPath,
  );
};

const swapArrayElements = (arr: any, indexA: any, indexB: any) => {
  const arrayClone = [...arr];
  const temp = arrayClone[indexA];
  arrayClone[indexA] = arrayClone[indexB];
  arrayClone[indexB] = temp;

  return arrayClone;
};

type ReorderElementsHook = [() => void, boolean];

export const useSwapElementAtIndex = (
  project: Project,
  path: ElementPath,
  newIndex: any,
): ReorderElementsHook => {
  const currentIndex = last(path);
  const rootPath = initial(path);
  const dispatch = useDispatch();
  const [updateElements] = useUpdateElements(project);
  const siblings =
    rootPath.length === 0 ? project.elements : get(project.elements, rootPath);

  const baseEnabled = newIndex !== false && newIndex >= 0;
  const enabled =
    baseEnabled &&
    (newIndex < (currentIndex as any)
      ? (currentIndex as any) > 0
      : (currentIndex as any) < siblings.length - 1);

  return [
    useCallback(() => {
      if (enabled) {
        const newSiblings = swapArrayElements(siblings, currentIndex, newIndex);
        dispatch(setSelectedElement([...rootPath, newIndex]));

        return updateElements(rootPath, newSiblings);
      }
    }, [
      enabled,
      siblings,
      currentIndex,
      newIndex,
      dispatch,
      rootPath,
      updateElements,
    ]),
    enabled,
  ];
};

export const useMoveElementUp = (
  project: Project,
  path: ElementPath,
): ReorderElementsHook => {
  const currentIndex = last(path);

  return useSwapElementAtIndex(project, path, (currentIndex as any) - 1);
};

export const useMoveElementDown = (
  project: Project,
  path: ElementPath,
): ReorderElementsHook => {
  const currentIndex = last(path);

  return useSwapElementAtIndex(project, path, (currentIndex as any) + 1);
};

export const useCloneElement = (
  project: Project,
  path: ElementPath,
): [(path?: ElementPath | null) => any, UpdateResult] => {
  const dispatch = useDispatch();
  const [addSibling, mutation] = useAddSibling(
    project,
    path,
    last(initial(path)) === 'children' ? ['children'] : [],
  );

  const onCloneElement = useCallback(
    (elementPath?: ElementPath | null) => {
      if (path.length > 1) {
        const selectedIndex = last(path) as number;
        const clonedSection = addSibling(
          cloneElement(get(project.elements, path)),
          selectedIndex + 1,
        );
        const clonedPath = [...initial(path), selectedIndex + 1];

        setTimeout(() => {
          if (elementPath) {
            dispatch(setSelectedSectionPath([selectedIndex + 1]));
            dispatch(setSelectedElement(elementPath));
          } else {
            dispatch(setSelectedElement(clonedPath));
          }
        }, 200);

        return { clonedPath, clonedSection };
      }

      return null;
    },
    [addSibling, dispatch, path, project.elements],
  );

  return [onCloneElement, mutation];
};

export const useOnCloneTab = (
  sections: Section[],
  updateSections: (sections: Section[]) => void,
) =>
  useCallback(
    (tabId: string | null, newTabId: string) => {
      if (!sections.length) {
        return;
      }

      const sectionsToProcess = sections.filter((section) =>
        tabId ? section.tab === tabId : !section.tab,
      );

      if (!sectionsToProcess.length) {
        return;
      }

      const containerMap = new Map<string, string>();

      const clonedSections = sectionsToProcess
        .map((section) => {
          const newSection = { ...section, tab: newTabId };

          if (!section.container) {
            const cloned = cloneElement(newSection);
            containerMap.set(section.id, cloned.id);

            return cloned;
          }

          const newContainer = containerMap.get(section.container!);

          if (!newContainer) {
            console.warn(`Container not found for section: ${section.id}`);

            return null;
          }

          return cloneElement(
            { ...newSection, container: newContainer },
            { [newContainer]: newContainer },
          );
        })
        .filter(Boolean) as Section[];

      updateSections([...sections, ...clonedSections]);
    },
    [sections, updateSections],
  );

export const useOnAddComponent = () => {
  const dispatch = useDispatch();

  return useCallback(
    (newElement: any, newPath: any) => {
      trackElementAdded(newElement.type, newElement.props.name);
      setTimeout(() => dispatch(setSelectedElement(newPath)), 100);
    },
    [dispatch],
  );
};

export const useAddComponent = (project: Project, path: ElementPath) => {
  // @ts-expect-error TS(2345): Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  const isNodeProp = isNaN(last(path));
  const selectedConfig = getSelectedElementConfig(project.elements, path);
  const additionalPath = !isNodeProp ? ['children'] : [];
  const [addSibling] = useAddSibling(project, path, additionalPath);
  const [addChild] = useAddChild(project, path, additionalPath);
  const onAddComponent = useOnAddComponent();

  return useCallback(
    (componentShape: any, name: string, callback: any) => {
      if (path && path.length > 0 && selectedConfig) {
        const newElement = generateNewElementFromNodeShape({
          ...componentShape,
          id: undefined,
          props: {
            ...(componentShape.props || {}),
            name,
          },
        });
        const parent = get(project.elements, path);
        let newPath = null;

        if (selectedConfig.canHaveChildren) {
          addChild(newElement);
          newPath = [
            ...path,
            ...(isNodeProp ? [] : ['children']),
            (isNodeProp
              ? parent
                ? parent.length
                : 0
              : parent.children.length) || 0,
          ];
        } else {
          const newIndex = (last as any)(path) + 1;
          addSibling(newElement, newIndex);
          newPath = [...initial(path), newIndex];
        }

        if (callback) {
          callback(newElement);
        }
        onAddComponent(newElement, newPath);
      }
    },
    [
      addChild,
      addSibling,
      isNodeProp,
      onAddComponent,
      path,
      project.elements,
      selectedConfig,
    ],
  );
};

export const useUpdateProperty = (
  elementPath: ElementPath,
  project: Project,
  elementState?: any,
): UpdateProjectResult => {
  const [updateElements, mutation] = useUpdateElements(project);

  const onUpdateProperty = useCallback(
    (propertyPath: ElementPath, value: any) =>
      updateElements(
        [
          ...elementPath,
          'props',
          ...(elementState ? [elementState] : []),
          ...propertyPath,
        ],
        value,
      ),
    [elementPath, elementState, updateElements],
  );

  return [onUpdateProperty, mutation];
};

export const useUpdateVisibilityRules = (
  elementPath: ElementPath,
  project: Project,
): UpdateProjectResult => {
  const [updateElements, mutation] = useUpdateElements(project);

  const onUpdateAction = useCallback(
    (propertyPath: ElementPath, value: any) =>
      updateElements(
        [...elementPath, 'visibilityRules', ...propertyPath],
        value,
      ),
    [elementPath, updateElements],
  );

  return [onUpdateAction, mutation];
};

export const useUpdateSettings = (project: Project): UpdateProjectResult => {
  const [updateProject, mutation] = useUpdateProject(project);
  const onUpdateSettings = useCallback(
    (propertyPath: ElementPath, value: any) =>
      updateProject(['settings', ...propertyPath], value),
    [updateProject],
  );

  return [onUpdateSettings, mutation];
};

export const useUpdateTheme = (project: Project): UpdateProjectResult => {
  const [updateProject, mutation] = useUpdateProject(project);
  const onUpdateTheme = useCallback(
    (propertyPath: ElementPath, value: any) =>
      updateProject(['settings', 'theme', ...propertyPath], value),
    [updateProject],
  );

  return [onUpdateTheme, mutation];
};

export const useUpdateText = (
  elementPath: any,
  project: Project,
): [(value: string) => any, UpdateResult] => {
  const [updateElements, mutation] = useUpdateElements(project);
  const onUpdateText = useCallback(
    (value: string) =>
      updateElements([...elementPath, 'children', 0, 'props', 'items'], value),
    [elementPath, updateElements],
  );

  return [onUpdateText, mutation];
};

export const useUpdateWorkflow = (
  workflowId: any,
  project: Project,
): UpdateProjectResult => {
  const [updateProject, mutation] = useUpdateProject(project);
  const onUpdateWorkflow = useCallback(
    (propertyPath: ElementPath, value: any) =>
      updateProject(['workflows', workflowId, ...propertyPath], value),
    [updateProject, workflowId],
  );

  return [onUpdateWorkflow, mutation];
};

export const useMutateProjectSettings = () => {
  const [updateProject, mutation] = useMutation(UPDATE_PROJECT);
  const onUpdateSettings = useCallback(
    (propertyPath: ElementPath, value: any, projectName: any) =>
      updateProject({
        variables: {
          name: projectName,
          path: ['settings', ...propertyPath].map(String),
          value,
        },
      }),
    [updateProject],
  );

  return [onUpdateSettings, mutation];
};

export const useMutateProjectElements = () => {
  const [updateProject, mutation] = useMutation(UPDATE_PROJECT);
  const onUpdateElements = useCallback(
    (propertyPath: ElementPath, value: any, projectName: any) =>
      updateProject({
        variables: {
          name: projectName,
          path: ['elements', ...propertyPath].map(String),
          value,
        },
      }),
    [updateProject],
  );

  return [onUpdateElements, mutation];
};
